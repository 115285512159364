import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Kanban,
  PackageDeal,
  SparePart,
  StandType,
  StorageCategories,
} from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  EMPTY_UICONTRACT,
  EXPERTISE_STAND_ID,
  nonDeleted,
  packageDealHelpers,
  sortingHelpers,
  URL_LIST_ELEMENTS_SEPARATOR,
} from '@stimcar/libs-base';
import { isTruthy, keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import { AttachmentsGallery, Button, GridCellBox } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { OperatorGeneralViewState, OperatorViewState } from '../typings/store.js';
import { KanbanMessageQuickviewComponent } from '../../../lib/bulmalegacy/components/KanbanMessageQuickviewComponent.js';
import { KanbanIdentityPictureAndGeneralInformationsComponent } from '../../../lib/components/identityPictureAndGeneralInfoDisplay/KanbanIdentityPictureAndGeneralInformationsComponent.js';
import {
  convertToPdfAction,
  importAttachmentsAction,
  loadAttachmentsGalleryAction,
  removePdfPageAction,
  useGetAllAttachmentFoldersForContractDocuments,
} from '../../utils/attachmentGalleryActions.js';
import { useComputeAttachmentUrl } from '../../utils/useComputeAttachmentUrl.js';
import { useGetContractByCode } from '../../utils/useGetContract.js';
import { logErrorIfSparePartsInExpertiseStand } from '../operatorLogUtils.js';
import { EMPTY_OPERATOR_GENERAL_VIEW } from '../typings/store.js';
import { ValidationCostComponent } from './expertiseValidation/ValidationCostComponent.js';
import { OperatorCountdown } from './OperatorCountdown.js';
import { OperatorWorkingArea } from './OperatorWorkingArea.js';

function getExpertiseStandRelatedPackageDeals(kanban: Kanban): readonly PackageDeal[] {
  const standRelatedPackageDeals = packageDealHelpers.getNotCancelledPackageDealsRelatedToStand(
    kanban.packageDeals,
    EXPERTISE_STAND_ID
  );
  const standRelatedPackageDealsWithoutExpPackageDeal = standRelatedPackageDeals.filter(
    ({ category }) => category !== 'EXP'
  );
  return standRelatedPackageDealsWithoutExpPackageDeal;
}

function getStandRelatedPackageDeals(kanban: Kanban, standId: string): readonly PackageDeal[] {
  if (standId === EXPERTISE_STAND_ID) {
    return getExpertiseStandRelatedPackageDeals(kanban);
  }
  return packageDealHelpers.getPackageDealsRelatedToStand(kanban.packageDeals, standId);
}

export async function updateGeneralOperatorViewAction(
  {
    actionDispatch,
    packageDealDescRepository,
    getGlobalState,
    loggerClient,
  }: ActionContext<Store, OperatorGeneralViewState>,
  kanban: Kanban,
  standId: string
): Promise<void> {
  const standRelatedPackageDeals = getStandRelatedPackageDeals(kanban, standId);

  const sparePartIdToPackageDealIdMap: Record<string, string> = {};
  const sparePartsMap: Record<string, SparePart[]> = {};
  packageDealHelpers.getAvailablePackageDeals(kanban.packageDeals).forEach((pck) => {
    pck.spareParts.filter(nonDeleted).forEach((sp) => {
      const category = pck.carElement?.category ?? 'MISC';
      const spareParts = sparePartsMap[category] ?? [];
      if (sp.standId === standId) {
        spareParts.push(sp);
        sparePartIdToPackageDealIdMap[sp.id] = pck.id;
        sparePartsMap[category] = spareParts;
      }
    });
  });

  const spareParts: SparePart[] = [];
  keysOf(sparePartsMap)
    .slice()
    .sort()
    .forEach((k) =>
      spareParts.push(
        ...(sparePartsMap[k] ?? []).sort(sortingHelpers.createSortByStringField('UP', 'label'))
      )
    );

  if (standId === EXPERTISE_STAND_ID && spareParts.length) {
    logErrorIfSparePartsInExpertiseStand(
      getGlobalState().session.infos!.company.id,
      kanban,
      standId,
      spareParts,
      loggerClient
    );
  }

  const sparePartsOrderValues: Record<string, boolean> = {};
  const sparePartsReceivedValues: Record<string, boolean> = {};
  spareParts.forEach((sp) => {
    sparePartsReceivedValues[sp.id] = isTruthy(sp.dateOfReception);
    sparePartsOrderValues[sp.id] = isTruthy(sp.dateOfOrder);
  });

  // Load the operationsDesc related to the operations on the kanban (using the last state, so we have to retrieve operations
  // through getState())
  const packageDealDescIds = new Set(
    standRelatedPackageDeals.map((pd): string => pd.packageDealDescId)
  );
  const packageDealDescs = await packageDealDescRepository.getEntities(...packageDealDescIds);

  actionDispatch.reduce((initial: OperatorGeneralViewState): OperatorGeneralViewState => {
    return {
      ...initial,
      standRelatedPackageDeals,
      packageDealDescs,
      sparePartsState: {
        ...initial.sparePartsState,
        spareParts,
        sparePartsOrderValues,
        sparePartsReceivedValues,
        sparePartIdToPackageDealIdMap,
      },
    };
  });
}

async function initOperatorViewFromKanban(
  { actionDispatch, getState }: ActionContext<Store, OperatorViewState>,
  standId: string
): Promise<void> {
  const { operatedKanban } = getState();

  if (operatedKanban === undefined) {
    return;
  }

  await actionDispatch
    .scopeProperty('generalOperatorState')
    .exec(updateGeneralOperatorViewAction, operatedKanban, standId);
}

interface CommentsAreaProps extends AppProps<Store> {
  readonly commentsNumber: number;
  readonly standId: string;
  readonly attachmentsVisible: boolean;
}

function CommentsArea({
  commentsNumber,
  standId,
  attachmentsVisible,
  $gs,
}: CommentsAreaProps): JSX.Element {
  const [t] = useTranslation('operators');
  const { $operatorView } = $gs;
  const { $generalOperatorState } = $operatorView;

  const stands = useGetState($gs.$siteConfiguration.$stands);

  const standType = useMemo((): StandType | undefined => {
    const theStand = nonnull(stands.find((s) => s.id === standId));
    return theStand.type;
  }, [standId, stands]);

  const openCloseMessageDrawerActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      actionDispatch.setValue(!getState());
    },
    [],
    $generalOperatorState.$showKanbanMessages
  );

  return (
    <>
      <div className="columns is-gap-medium is-multiline">
        <div className="column is-full">
          <Button
            additionalClass="is-fullwidth"
            iconId="comment-alt"
            label={t('comments', { value: commentsNumber })}
            tooltip={t('comments', { value: commentsNumber })}
            onClick={openCloseMessageDrawerActionCallback}
          />
        </div>
        {standId !== 'EXP' && (
          <div className="column is-full">
            <Button
              additionalClass="is-fullwidth"
              label={t('attachments')}
              tooltip={t('attachments')}
              iconId="file"
              // eslint-disable-next-line react-hooks/rules-of-hooks
              onClick={useSetCallback(
                $generalOperatorState.$attachmentsVisible,
                !attachmentsVisible
              )}
            />
          </div>
        )}
        {standType === 'expertiseValidation' && (
          <div className="column is-full">
            <ValidationCostComponent $gs={$gs} />
          </div>
        )}
      </div>
    </>
  );
}

interface OperatorGeneralComponentProps extends AppProps<Store> {
  readonly standId: string;
}

export function OperatorGeneralComponent({
  $gs,
  standId,
}: OperatorGeneralComponentProps): JSX.Element {
  const { $imageModal, $operatorView } = $gs;
  const { $generalOperatorState } = $operatorView;
  const { $attachmentsState } = $generalOperatorState;
  const browserInfosState = nonnull(useGetState($gs.$session.$infos));

  const operatedKanban = useGetState($operatorView.$operatedKanban);

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const asyncEffect = useActionCallback(
    async function _initOperatorViewFromKanban({ actionDispatch }) {
      await actionDispatch.exec(initOperatorViewFromKanban, standId);
    },
    [standId],
    $operatorView
  );

  const asyncCleanupEffect = useSetCallback($generalOperatorState, EMPTY_OPERATOR_GENERAL_VIEW);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();

    return (): void => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      asyncCleanupEffect();
    };
  }, [asyncCleanupEffect, asyncEffect]);

  const contract = useGetContractByCode($gs, operatedKanban?.contract.code) ?? EMPTY_UICONTRACT;
  const { memoDescs, attributeDescs } = contract;

  const documentsFolders = useGetAllAttachmentFoldersForContractDocuments(contract.documents);

  const attachmentsVisible = useGetState($operatorView.$generalOperatorState.$attachmentsVisible);

  const isOnline = useGetState($gs.$session.$isOnline);
  const importAttachmentsActionCallback = useActionCallback(importAttachmentsAction, [], $gs);
  const loadGalleryAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $attachmentsState
  );
  const loadPdfAttachmentContentActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $attachmentsState.$pdfCreationAndUploadModal
  );
  return (
    <>
      <div className="fixed-grid has-12-cols">
        <div className="grid">
          <GridCellBox colspan={8}>
            <KanbanIdentityPictureAndGeneralInformationsComponent
              kanban={nonnull(operatedKanban)}
              identityPictureToolkit={{
                $imageModal,
                computeUrlCallback: computeAttachmentUrl,
                isOnline,
              }}
              contract={contract}
            />
          </GridCellBox>
          <GridCellBox colspan={2}>
            <CommentsArea
              commentsNumber={nonnull(operatedKanban).messages.length ?? 0}
              standId={standId}
              attachmentsVisible={attachmentsVisible}
              $gs={$gs}
            />
          </GridCellBox>
          <GridCellBox colspan={2}>
            <OperatorCountdown
              kanban={nonnull(operatedKanban)}
              // Should not happen, only here because TypeScript can't understand that using  nonnull() method
              // on browserInfosState give us the confidence it will not be null
              postId={browserInfosState?.id ?? ''}
              $={$gs.$operatorView}
              $gs={$gs}
              standId={standId}
            />
          </GridCellBox>
        </div>
      </div>
      {attachmentsVisible && (
        <div className="box">
          <AttachmentsGallery
            category="kanban"
            folders={documentsFolders}
            objectId={nonnull(operatedKanban).id}
            $={$attachmentsState}
            $window={$gs.$window}
            uploadToolkit={{
              importAttachmentsActionCallback,
              convertToPdfToolkit: {
                loadPdfAttachmentContentActionCallback,
                convertToPdfHttpRequestAction: convertToPdfAction,
                clientSpecificFolderId: browserInfosState.label,
                removePdfPageHttpRequestAction: removePdfPageAction,
              },
            }}
            computeAttachmentUrl={computeAttachmentUrl}
            loadAttachmentsActionCallback={loadGalleryAttachmentsActionCallback}
            $imageModal={$imageModal}
            isOnline={isOnline}
          />
        </div>
      )}
      <OperatorWorkingArea
        standId={standId}
        memoDescs={memoDescs}
        attributeDescs={attributeDescs}
        $gs={$gs}
      />
      <KanbanMessageQuickviewComponent
        kanban={nonnull(operatedKanban)}
        $active={$generalOperatorState.$showKanbanMessages}
        $={$generalOperatorState.$addKanbanMessageInputState}
        isReadonly={!isOnline}
      />
    </>
  );
}
