import type { SparePartsProvider } from '@stimcar/libs-base';
import type { ActionCallbackFromFunction, BusinessObjectToFormData } from '@stimcar/libs-uikernel';
import type { FormWithValidationState, TableState } from '@stimcar/libs-uitoolkit';
import { TABLE_EMPTY_STATE } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';

export type OnSparePartProviderChangeActionCallback = ActionCallbackFromFunction<
  Store,
  (addedOrUpdated: readonly SparePartsProvider[], removedIds: readonly string[]) => void
>;

export type CreateSparePartsProviderData = BusinessObjectToFormData<SparePartsProvider>;

export const EMPTY_CREATE_SPAREPARTS_PROVIDER_DATA: CreateSparePartsProviderData = {
  id: '',
};

export type CreateSparePartsProviderDialogState =
  FormWithValidationState<CreateSparePartsProviderData> & {
    readonly active: boolean;
  };

export const EMPTY_CREATE_SPAREPARTS_PROVIDER_DIALOG_STATE: CreateSparePartsProviderDialogState = {
  active: false,
  formSubmitted: false,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  formData: {
    ...EMPTY_CREATE_SPAREPARTS_PROVIDER_DATA,
    warnings: {},
  },
};

export type DeleteSparePartsProviderDialogState = {
  readonly active: boolean;
  readonly providerId: string;
};

export const EMPTY_DELETE_SPAREPARTS_PROVIDER_DIALOG_STATE: DeleteSparePartsProviderDialogState = {
  active: false,
  providerId: '',
};

export type AdminSparePartsProvidersState = TableState<SparePartsProvider> & {
  readonly existingProviders: readonly SparePartsProvider[];
  readonly createSparePartsProviderDialogState: CreateSparePartsProviderDialogState;
  readonly deleteSparePartsProviderDialogState: DeleteSparePartsProviderDialogState;
};

export const EMPTY_ADMIN_SPAREPARTS_PROVIDERS_STATE: AdminSparePartsProvidersState = {
  ...TABLE_EMPTY_STATE,
  existingProviders: [],
  createSparePartsProviderDialogState: EMPTY_CREATE_SPAREPARTS_PROVIDER_DIALOG_STATE,
  deleteSparePartsProviderDialogState: EMPTY_DELETE_SPAREPARTS_PROVIDER_DIALOG_STATE,
};
