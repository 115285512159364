import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { globalHelpers } from '@stimcar/libs-base';
import { useArrayItemSelector, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { ShiftProgressDisplayState } from './typings/store.js';

type Props = {
  readonly $: StoreStateSelector<Store, ShiftProgressDisplayState>;
};

export function AheadBehindTargetDisplayMode({ $ }: Props): JSX.Element {
  const size = useGetState($.$size);
  const $currentShift = useArrayItemSelector($.$lastShifts, '3');
  const currentWorkload = useGetState($currentShift.$workload);
  const expectedWorkload = useGetState($.$expectedCurrentShiftWorkload);
  const delta = currentWorkload - expectedWorkload;

  const colorClassName = useMemo(() => {
    if (delta >= -0.1) {
      return 'has-text-success';
    }
    if (delta < 0 && Math.abs(delta / expectedWorkload) < 0.1 /* 10% */) {
      return 'has-text-warning';
    }
    return 'has-text-danger';
  }, [delta, expectedWorkload]);

  const faIcon = useMemo(() => {
    if (delta >= -0.1) {
      return 'rocket';
    }
    if (delta < 0 && Math.abs(delta / expectedWorkload) < 0.1 /* 10% */) {
      return 'cloud-sun-rain';
    }
    return 'cloud-showers-heavy';
  }, [delta, expectedWorkload]);

  return (
    <>
      <div>
        <span className={colorClassName} style={{ fontSize: `${120 * size}px`, lineHeight: '1em' }}>
          {`${delta >= 0 ? '+' : ''}${globalHelpers.roundTo(delta, 1)} `}
        </span>
        <FaIcon id={faIcon} additionalClass={colorClassName} size={50 * size} />
      </div>
      <div>
        <span className={colorClassName} style={{ fontSize: `${90 * size}px`, lineHeight: '1em' }}>
          {globalHelpers.roundTo(currentWorkload, 0)}
        </span>
        <span style={{ fontSize: `${90 * size}px`, lineHeight: '1em' }}>
          {` / ${globalHelpers.roundTo(expectedWorkload, 0)}`}
        </span>
      </div>
    </>
  );
}
