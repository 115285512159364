import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { ClickableIcon } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { Comment } from './CommentsComponent.js';
import type {
  EditCommentsModalDialogState,
  PackageDealComments,
  SparePartComments,
} from './typings/store.js';
import { CommentsComponent } from './CommentsComponent.js';
import { openEditCommentsModalAction } from './EditCommentsModalDialogComponent.js';

export type EditableCommentsComponentProps = {
  readonly kanbanId: string;
  /**
   * If editing a PackageDeal.
   */
  readonly packageDeal?: PackageDealComments;
  /**
   * If editing a SparePart.
   */
  readonly packageDealId?: string;
  /**
   * If editing a SparePart.
   */
  readonly sparePart?: SparePartComments;
  readonly packageDealComment?: string;
  readonly commentForStore: string;
  readonly commentForWorkshop: string;
  readonly commentForCustomer: string;
  readonly disabled?: boolean;
  readonly $: StoreStateSelector<Store, EditCommentsModalDialogState>;
};

/**
 * In order to edit comments, `EditCommentsModalDialogComponent` should be present in the DOM.
 */
export function EditableCommentsComponent({
  kanbanId,
  packageDeal,
  packageDealId,
  sparePart,
  packageDealComment,
  commentForStore,
  commentForWorkshop,
  commentForCustomer,
  disabled = false,
  $,
}: EditableCommentsComponentProps): JSX.Element {
  const [t] = useTranslation('share');

  const openModalActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openEditCommentsModalAction, {
        kanbanId,
        packageDeal,
        packageDealId,
        sparePart,
        commentForStore,
        commentForWorkshop,
        commentForCustomer,
      });
    },
    [
      kanbanId,
      packageDeal,
      packageDealId,
      sparePart,
      commentForStore,
      commentForWorkshop,
      commentForCustomer,
    ],
    $
  );

  const comments: readonly Comment[] = [
    { label: t('comments.fromPackageDeal'), value: packageDealComment },
    { label: t('comments.store'), value: commentForStore },
    { label: t('comments.workshop'), value: commentForWorkshop },
    { label: t('comments.customer'), value: commentForCustomer },
  ];

  return (
    <div className="columns">
      <div className="column">
        <CommentsComponent comments={comments} />
      </div>
      <div className="column is-narrow">
        <ClickableIcon
          id="ellipsis-h"
          clickHandler={openModalActionCallback}
          isDisabled={disabled}
        />
      </div>
    </div>
  );
}
