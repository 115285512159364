import type { JSX } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  KnownKeysOf,
  Site,
  SiteConfiguration,
  WorkshopStandImplantation,
} from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormFieldContentActions,
  FormFieldEntry,
} from '@stimcar/libs-uitoolkit';
import { CoreHttpErrorCodes } from '@stimcar/core-libs-common';
import {
  AvailablePermissionPaths,
  CoreBackendRoutes,
  enumerate,
  globalHelpers,
  Role,
  siteConfigurationToStandardNotation,
  urlHelpers,
} from '@stimcar/libs-base';
import { getHttpStatusCode, isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  Checkbox,
  FormButtons,
  InputFormField,
  SelectFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { RegisteredAppStore, RegisteredAppStoreState } from '../../state/typings/store.js';
import { useRoleEntries } from '../../../app/utils/useRoleEntries.js';
import { LoginComponent } from '../../../lib/components/login/LoginComponent.js';
import { userAvailableAccessPaths } from '../../../utils/generalUtils.js';
import { activateCurrentWindowSessionAction } from '../../actions/windowSessionActivation.js';
import { useHasModifyPermission } from '../../permissionHooks.js';
import type { RegisterForm, RegisterViewState } from './typings/store.js';
import { loginAction, redirectAfterLoginOrRegister } from './loginaction.js';
import { useGetImplantationIds } from './utils/useGetImplantationIds.js';
import { useGetWorkshopPostCategoriesAndUniqueIds } from './utils/useGetWorkshopPostIds.js';
import { useStandIds } from './utils/useStandIds.js';

function getStandIdForWorkshopOperator(
  configuration: SiteConfiguration,
  implantationId: string
): string | undefined {
  const foundStand = configuration?.stands.find((stand) => {
    if (isTruthy(stand.implantations)) {
      return stand.implantations
        .map((impl) => {
          return impl.id;
        })
        .includes(implantationId);
    }
    return undefined;
  });
  return isTruthyAndNotEmpty(foundStand?.id) ? foundStand?.id : undefined;
}

export async function loadAvailableSitesAction<IS_LIGHT_ENVIRONMENT extends boolean>({
  actionDispatch,
  getState,
  httpClient,
}: ActionContext<RegisteredAppStore<IS_LIGHT_ENVIRONMENT>, RegisterViewState>): Promise<void> {
  const { sites } = await httpClient.httpGetAsJson<{ sites: readonly Site<'concise'>[] }>(
    CoreBackendRoutes.GET_SITES
  );
  const siteIdIsSet = getState().formData.siteId !== '';
  actionDispatch.setProperty(
    'availableSites',
    sites.map((site) => ({
      ...site,
      configuration: siteConfigurationToStandardNotation(site.configuration),
    }))
  );
  // If there is only one site, and if the site id is not set, automatically
  // select the first site.
  if (sites.length === 1 && !siteIdIsSet) {
    actionDispatch.scopeProperty('formData').setProperty('siteId', sites[0].id);
  }
}

export async function registerBrowserAction<IS_LIGHT_ENVIRONMENT extends boolean>({
  actionDispatch,
  getState,
  httpClient,
  navigate,
}: ActionContext<RegisteredAppStore<IS_LIGHT_ENVIRONMENT>, RegisteredAppStoreState>) {
  const { registerView: register } = getState();
  const { formData: data } = register;
  const { siteId, standId, label, role, forceLabel } = data;

  // in case of a WorkshopOperator the standId is an implantation id so we have to get the actual standId
  let actualLabel: string;
  let actualStandId: string | undefined;
  let canLogWithPin = false;
  const site = register.availableSites.find((s: Site): boolean => s.id === siteId);
  switch (role) {
    case Role.WorkshopOperator:
      actualLabel = globalHelpers.computeQualifiedWorkshopPostId(standId, label);
      actualStandId = getStandIdForWorkshopOperator(nonnull(site).configuration, standId);
      canLogWithPin = true;
      break;
    default:
      actualLabel = label;
      actualStandId = isTruthyAndNotEmpty(standId) ? standId : undefined;
      break;
  }

  try {
    await httpClient.registerBrowser(
      siteId,
      role as KnownKeysOf<typeof Role>,
      actualStandId,
      actualLabel.trim(),
      canLogWithPin,
      forceLabel
    );
    // Activate the window session
    await actionDispatch.exec(activateCurrentWindowSessionAction);
    actionDispatch.applyPayload({
      registerView: {
        formData: {
          forceLabel: false,
        },
      },
    });
    redirectAfterLoginOrRegister(navigate, httpClient.getBrowserInfos());
  } catch (e) {
    // If the label is already in use
    if (
      e instanceof Error &&
      getHttpStatusCode(e) === CoreHttpErrorCodes.BROWSER_LABEL_ALREADY_IN_USE
    ) {
      actionDispatch.applyPayload({
        registerView: {
          formSubmitted: false,
          labelIsInUse: true,
          formData: {
            forceLabel: false,
          },
        },
      });
    } else {
      throw e;
    }
  }
}

const getMandatoryFields = ({
  role,
}: RegisterViewState['formData']): readonly (keyof RegisterForm)[] => {
  if (Role.WorkshopOperator === role) {
    return ['siteId', 'role', 'standId', 'label'];
  }
  return ['siteId', 'role', 'label'];
};

const checkFieldContentActions: CheckFormFieldContentActions<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  RegisteredAppStore<any>,
  RegisterViewState
> = {
  label: ({ formState, value, t }): string | undefined => {
    const { labelIsInUse, formData } = formState;
    const { forceLabel, role } = formData;
    if (labelIsInUse && !forceLabel) {
      return t('register.warnings.labelInUse');
    }
    if (role !== Role.WorkshopOperator) {
      const restrictedChars = urlHelpers.getURLParametersRestrictedCharacters(String(value));
      if (restrictedChars.length > 0) {
        return t('register.warnings.containsRestrictedChar', {
          restrictedChars: enumerate(restrictedChars, ', ', { prefix: '"', suffix: '"' }),
        });
      }
    }
    if (String(value).length < 3) {
      return t('register.warnings.labelMustContainAtLeast3Characters');
    }
    return undefined;
  },
};

function RegisterComponent<IS_LIGHT_ENVIRONMENT extends boolean>({
  $gs,
}: AppProps<RegisteredAppStore<IS_LIGHT_ENVIRONMENT>>): JSX.Element {
  const [t] = useTranslation(['registeredApp', 'globals']);
  /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
  const user = useGetState($gs.$session.$user);

  const submitValidDataAction = useActionCallback(registerBrowserAction, [], $gs);

  const [onFormSubmit, , $formDataWithGenericChangeTrigger] = useFormWithValidation<
    RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
    RegisterViewState
  >({
    $: $gs.$registerView,
    mandatoryFields: getMandatoryFields,
    checkFieldContentActions,
    checkFormConsistencyAction: undefined, // No specific form consistency check
    submitValidDataAction,
    t,
  });

  const onRegisterFormChangeHandlerActionCallback = useActionCallback(
    async function onFormChangeHandlerAction({
      actionDispatch,
      getState,
      httpClient,
    }: ActionContext<RegisteredAppStore<IS_LIGHT_ENVIRONMENT>, RegisterViewState>): Promise<void> {
      const { formData } = getState();
      const { siteId, label, standId, role } = formData;
      let actualLabel = '';
      if (role !== Role.WorkshopOperator) {
        actualLabel = label;
      } else {
        actualLabel = globalHelpers.computeQualifiedWorkshopPostId(standId, label);
      }
      try {
        actionDispatch.setProperty('labelIsInUse', false);
        if (siteId && actualLabel.trim()) {
          await httpClient.httpGet(
            CoreBackendRoutes.BROWSER_INFOS(siteId.trim(), actualLabel.trim())
          );
          // If no error is raised, it means that the label is in use
          actionDispatch.setProperty('labelIsInUse', true);
        }
      } catch (e) {
        // Only propagate the error if we're not in the case of the unknown
        // browser label (which is the case we expect here)
        if (
          !(e instanceof Error) ||
          getHttpStatusCode(e) !== CoreHttpErrorCodes.UNKNOWN_BROWSER_LABEL
        ) {
          throw e;
        }
      }
    },
    [],
    $gs.$registerView
  );

  const $formDataWithChangeTrigger = useSelectorWithChangeTrigger(
    $formDataWithGenericChangeTrigger,
    onRegisterFormChangeHandlerActionCallback
  );

  const onLabelChangeHandlerActionCallback = useActionCallback(
    function onLabelChangeHandlerAction({
      actionDispatch,
    }: ActionContext<RegisteredAppStore<IS_LIGHT_ENVIRONMENT>, RegisterViewState['formData']>) {
      actionDispatch.setProperty('forceLabel', false);
    },
    [],
    $formDataWithChangeTrigger
  );

  // Prepare rendering
  const availableSites = useGetState($gs.$registerView.$availableSites);
  const formWarning = useGetState($gs.$registerView.$formWarning);
  const labelIsInUse = useGetState($gs.$registerView.$labelIsInUse);
  const siteId = useGetState($formDataWithChangeTrigger.$siteId);
  const label = useGetState($formDataWithChangeTrigger.$label);
  const role = useGetState($formDataWithChangeTrigger.$role);
  const standId = useGetState($formDataWithChangeTrigger.$standId);

  const site = useMemo((): Site | undefined => {
    return availableSites.find((s: Site): boolean => s.id === siteId);
  }, [siteId, availableSites]);

  const asyncEffect = useActionCallback(loadAvailableSitesAction, [], $gs.$registerView);

  useEffect((): void => {
    // Load the available sites once we are connected
    if (user && availableSites.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      asyncEffect();
    }
  }, [user, availableSites, asyncEffect]);

  const sitesEntries = useMemo(
    (): FormFieldEntry<string>[] =>
      availableSites.map(({ name, id }): FormFieldEntry<string> => {
        return { id, label: name };
      }),
    [availableSites]
  );

  const canRegisterWorkshopPost = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.CAN_REGISTER_WORKSHOP_POST
  );

  const roleEntries = useRoleEntries(
    t,
    !canRegisterWorkshopPost ? Role.WorkshopOperator : undefined
  );

  const standIds = useStandIds($gs, role);
  const implantationIds = useGetImplantationIds(site?.configuration, role);
  const standIdsEntries = role !== Role.WorkshopOperator ? standIds : implantationIds;

  const workshopPostCategoriesAndIds = useGetWorkshopPostCategoriesAndUniqueIds(
    site?.configuration,
    standId
  );
  const workshopPostIdsFormField = useMemo(() => {
    return workshopPostCategoriesAndIds.map((w): FormFieldEntry<string> => {
      return {
        id: globalHelpers.computeWorkshopPostId(w.id, w.postName),
        label: globalHelpers.computeWorkshopPostId(w.id, w.postName),
      };
    });
  }, [workshopPostCategoriesAndIds]);

  const $labelWithChangeTrigger = useSelectorWithChangeTrigger(
    $formDataWithChangeTrigger.$label,
    onLabelChangeHandlerActionCallback
  );

  const onFormSubmitWithLoadingPageActionCallback = useActionCallback(
    async function onFormSubmitWithLoadingPageAction({
      actionDispatch,
      runWithProgressBar,
    }): Promise<void> {
      await runWithProgressBar(10, async (monitor) => {
        monitor.setLabel(nonnull<string>(t('load')));
        await actionDispatch.execCallback(onFormSubmit);
      });
    },
    [onFormSubmit, t],
    $gs
  );

  const isNotWorkshopOperatorRole = role !== Role.WorkshopOperator || siteId === '';

  const postLabel = useMemo((): string => {
    if (role === Role.WorkshopOperator) {
      let implantation: WorkshopStandImplantation | undefined;
      for (const stand of site?.configuration.stands ?? []) {
        if (isTruthy(implantation)) {
          break;
        }
        for (const impl of stand.implantations ?? []) {
          if (impl.id === standId) {
            implantation = impl;
            break;
          }
        }
      }
      if (isTruthy(implantation)) {
        const workshopPost = workshopPostIdsFormField.find((w) => w.id === label);
        if (isTruthy(workshopPost)) {
          return workshopPost.label;
        }
      }
    }
    return label;
  }, [label, role, site?.configuration.stands, standId, workshopPostIdsFormField]);

  const onSiteIdChangeHandlerActionCallback = useActionCallback(
    function onSiteIdChangeHandlerAction({
      actionDispatch,
    }: ActionContext<RegisteredAppStore<IS_LIGHT_ENVIRONMENT>, RegisterViewState['formData']>) {
      actionDispatch.setProperty('role', '');
      actionDispatch.setProperty('standId', '');
    },
    [],
    $formDataWithChangeTrigger
  );

  const $siteWithChangeTrigger = useSelectorWithChangeTrigger(
    $formDataWithChangeTrigger.$siteId,
    onSiteIdChangeHandlerActionCallback
  );

  const onRoleChangeHandlerCallback = useActionCallback(
    ({
      actionDispatch,
    }: ActionContext<RegisteredAppStore<IS_LIGHT_ENVIRONMENT>, RegisterViewState['formData']>) => {
      actionDispatch.setProperty('standId', '');
    },
    [],
    $formDataWithChangeTrigger
  );

  const $roleWithChangeTrigger = useSelectorWithChangeTrigger(
    $formDataWithChangeTrigger.$role,
    onRoleChangeHandlerCallback
  );

  return (
    <>
      <SelectFormField
        label={t('register.site')}
        entries={sitesEntries}
        $={$siteWithChangeTrigger}
        isEmptyValueAllowed
        isFullWidth
        sortEntries
      />
      <SelectFormField
        label={t('register.role')}
        entries={roleEntries}
        $={$roleWithChangeTrigger}
        isEmptyValueAllowed
        isFullWidth
        disabled={siteId === ''}
        sortEntries
      />
      {!isNotWorkshopOperatorRole && (
        <SelectFormField
          label={t('register.stand')}
          entries={standIdsEntries}
          $={$formDataWithChangeTrigger.$standId}
          isEmptyValueAllowed
          isFullWidth
          sortEntries
        />
      )}
      {workshopPostIdsFormField.length > 0 ? (
        <SelectFormField
          label={t('register.label')}
          entries={workshopPostIdsFormField}
          $={$labelWithChangeTrigger}
          isEmptyValueAllowed
          isFullWidth
          sortEntries
          disabled={isNotWorkshopOperatorRole}
        />
      ) : (
        <InputFormField label={t('register.label')} $={$labelWithChangeTrigger} />
      )}

      {labelIsInUse && (
        <>
          <Checkbox
            className="m-b-md"
            text={t('register.forceLabelText', { label: postLabel })}
            $={$formDataWithChangeTrigger.$forceLabel}
          />
        </>
      )}
      <FormButtons
        onSubmitButtonClicked={onFormSubmitWithLoadingPageActionCallback}
        formWarning={formWarning}
      />
    </>
  );
}

export function Register<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>({ $gs }: AppProps<SD>): JSX.Element {
  const [t] = useTranslation(['registeredApp', 'globals']);
  const user = useGetState($gs.$session.$user);
  const isAllowedToRegisterCurrentBrowser = useMemo(() => {
    if (!user) {
      return false;
    }
    const { hasCorporateAccess } = userAvailableAccessPaths(user);
    return hasCorporateAccess;
  }, [user]);

  const loginActionCallback = useActionCallback(loginAction, [], $gs.$loginView);
  return (
    <>
      <div className="columns">
        <div className="column is-one-third" />
        <div className="column is-one-third">
          <h1 className="title">{user ? t('configuration') : t('connect')}</h1>
          {isAllowedToRegisterCurrentBrowser ? (
            <RegisterComponent $gs={$gs} />
          ) : (
            <LoginComponent $={$gs.$loginView} loginActionCallback={loginActionCallback} />
          )}
        </div>
        <div className="column is-one-third" />
      </div>
    </>
  );
}
