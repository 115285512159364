import type { JSX } from 'react';
import React from 'react';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { DisplaySparePartComment } from '../../operators/components/spareparts/common/DisplaySparePartComment.js';

export type Comment = {
  readonly label: string;
  readonly value?: string;
};
export type CommentsComponentProps = {
  readonly comments: readonly Comment[];
};

export function CommentsComponent({ comments }: CommentsComponentProps): JSX.Element {
  const commentsElements = comments.map(({ label, value }) => (
    <li key={label}>
      {isTruthyAndNotEmpty(value) && <DisplaySparePartComment prefix={label} value={value} />}
    </li>
  ));

  return <ul>{commentsElements}</ul>;
}
