import type { JSX } from 'react';
import { type TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { CheckFormFieldContentActions } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, isValidEmailAddressStructure } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { InputFormField, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { SendInvoiceModalState } from './typings/store.js';
import { extractMessageFromError } from './invoicingUtils.js';
import { SEND_INVOICE_MODAL_EMPTY_STATE } from './typings/store.js';

export function openSendInvoiceModalAction(
  { actionDispatch }: ActionContext<Store, SendInvoiceModalState>,
  recipient: string,
  purchaseOrderId: string,
  invoiceReference: string,
  invoiceInfoId: string
): void {
  actionDispatch.setValue({
    ...SEND_INVOICE_MODAL_EMPTY_STATE,
    isActive: true,
    purchaseOrderId,
    invoiceReference,
    invoiceInfoId,
    formData: {
      recipient,
      warnings: {},
    },
  });
}

function closeModalAction({ actionDispatch }: ActionContext<Store, SendInvoiceModalState>) {
  actionDispatch.applyPayload({
    ...SEND_INVOICE_MODAL_EMPTY_STATE,
  });
}

const checkFieldContentActions: CheckFormFieldContentActions<Store, SendInvoiceModalState> = {
  recipient: ({ value, t }): string | undefined => {
    if (!isValidEmailAddressStructure(value)) {
      return t('tabs.invoice.paymentOrder.invalidEmail');
    }
    return undefined;
  },
};

async function sendInvoiceMailAction(
  {
    httpClient,
    getState,
    actionDispatch,
    globalActionDispatch,
  }: ActionContext<Store, SendInvoiceModalState>,
  kanbanId: string,
  recipient: string,
  t: TFunction
): Promise<void> {
  const { purchaseOrderId, invoiceInfoId } = getState();
  try {
    await httpClient.httpPostAsJSON(
      CoreBackendRoutes.SEND_INVOICE_MAIL(kanbanId, purchaseOrderId, invoiceInfoId),
      { recipient }
    );

    globalActionDispatch.scopeProperty('message').setValue({
      type: 'info',
      title: t('tabs.invoice.sendInvoice.dialogTitleSuccess'),
      content: t('tabs.invoice.sendInvoice.dialogSuccessMessage', {
        recipient,
      }),
    });
  } catch (e) {
    globalActionDispatch.scopeProperty('message').setValue({
      type: 'warning',
      title: t('tabs.invoice.errorTitle'),
      content: extractMessageFromError(e as Error),
    });
  }
  actionDispatch.setValue(SEND_INVOICE_MODAL_EMPTY_STATE);
}

type SendInvoiceModalProps = {
  readonly kanbanId: string;
  readonly $: StoreStateSelector<Store, SendInvoiceModalState>;
};

export function SendInvoiceModal({ kanbanId, $ }: SendInvoiceModalProps): JSX.Element {
  const [t] = useTranslation('details');
  const formWarning = useGetState($.$formWarning);
  const recipient = useGetState($.$formData.$recipient);
  const reference = useGetState($.$invoiceReference);

  const closeDialogCallback = useActionCallback(closeModalAction, [], $);
  const sendInvoiceActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(sendInvoiceMailAction, kanbanId, recipient, t);
    },
    [kanbanId, recipient, t],
    $
  );

  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation({
    $,
    mandatoryFields: ['recipient'],
    submitValidDataAction: sendInvoiceActionCallback,
    checkFieldContentActions,
    t,
  });
  return (
    <ModalCardDialog
      titleIconId="fa-solid fa-credit-card"
      title={t('tabs.invoice.sendInvoice.dialogTitle')}
      $active={$.$isActive}
      onOkClicked={onFormSubmit}
      onCancelClicked={closeDialogCallback}
      warning={formWarning}
    >
      <p>
        {t('tabs.invoice.sendInvoice.dialogLabelContent', {
          reference,
          recipient,
        })}
      </p>
      <div className="columns mt-1 mb-1">
        <div className="column">
          <InputFormField
            title={t('tabs.invoice.sendInvoice.recipient')}
            label={t('tabs.invoice.sendInvoice.recipient')}
            $={$formWithChangeTrigger.$recipient}
          />
        </div>
      </div>
    </ModalCardDialog>
  );
}
