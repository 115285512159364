import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from './FormField.js';
import type { SwitchProps } from './Switch.js';
import { FormField } from './FormField.js';
import { Switch } from './Switch.js';

export type SwitchFormFieldProps<SD extends AnyStoreDef> = SwitchProps<SD> &
  Omit<FormFieldProps, 'children' | 'warning' | 'noExclamationTriangleIfWarning'>;

export function SwitchFormField<SD extends AnyStoreDef>({
  label,
  $,
  horizontal,
  ...props
}: SwitchFormFieldProps<SD>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($);
  return (
    <FormField
      label={label}
      warning={dispatchWarning}
      noExclamationTriangleIfWarning
      horizontal={horizontal}
    >
      <Switch $={$} {...props} />
    </FormField>
  );
}
