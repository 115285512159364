import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  MarketplaceBuyOverview,
  MarketplaceOverviewRequest,
  MarketplacePackageDeal,
  MarketplaceSaleOverview,
  MarketplaceWorkOverview,
} from '@stimcar/core-libs-common';
import type { Kanban, PackageDeal } from '@stimcar/libs-base';
import type { MarketplaceMandate } from '@stimcar/libs-kernel';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import {
  ADMIN_PACKAGE_DEAL_CODE,
  compareStrings,
  CoreBackendRoutes,
  kanbanHelpers,
  MARKETPLACE_BUY_PURCHASE_ORDER,
  MARKETPLACE_DOC_TYPE_BUY,
  MARKETPLACE_DOC_TYPE_SALE,
  MARKETPLACE_DOC_TYPE_WORK,
  MARKETPLACE_SALE_PURCHASE_ORDER,
  MARKETPLACE_SELL_PACKAGE_DEAL_CODE,
  MKTP_PKG_CATEGORY_BUY_FIXED_FEES,
  MKTP_PKG_CATEGORY_REGISTRATION_FEES,
  MKTP_PKG_CATEGORY_SALE_FIXED_FEES,
  MKTP_PKG_DEAL_CODE_BUY_EXPERTISE,
  MKTP_PKG_DEAL_CODE_SALE_EXPERTISE,
  MKTP_PKG_DEAL_CODE_VARIABLE_FEES,
  MKTP_PKG_DEAL_VARIABLE_FEES_MAX_PRICE_VARIABLE,
  MKTP_PKG_DEAL_VARIABLE_FEES_MIN_PRICE_VARIABLE,
  MKTP_PKG_DEAL_VARIABLE_FEES_RATE_VARIABLE,
  nonDeleted,
  packageDealHelpers,
} from '@stimcar/libs-base';
import {
  applyPayload,
  computePayload,
  formatPrice,
  isTruthy,
  MKTP_MANDATE_BUY,
  MKTP_MANDATE_SALE,
} from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, DropdownButton, Input, ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { MarketplaceOverviewState } from './typings/store.js';
import { LabelWithPrice } from './LabelWithPrice.js';

const WORK_RECAP_IGNORED_PKG_DEAL_CODES: string[] = [
  ADMIN_PACKAGE_DEAL_CODE,
  MARKETPLACE_SELL_PACKAGE_DEAL_CODE,
  MKTP_PKG_DEAL_CODE_SALE_EXPERTISE,
  MKTP_PKG_DEAL_CODE_BUY_EXPERTISE,
];

type CanceledPackageDeals = {
  readonly buyRegistrationFeePackageDeals: readonly PackageDeal[];
  readonly buyRefurbishFeePackageDeals: readonly PackageDeal[];
  readonly buyFixedFeePackageDeals: readonly PackageDeal[];
};

type PackageDealsByFee = {
  readonly buyRegistrationFeePackageDeals: readonly PackageDeal[];
  readonly buyRefurbishFeePackageDeals: readonly PackageDeal[];
  readonly buyFixedFeePackageDeals: readonly PackageDeal[];
  readonly saleRefurbishFeePackageDeals: readonly PackageDeal[];
  readonly saleFixedFeePackageDeals: readonly PackageDeal[];
};

type TotalValuesByFee = {
  readonly buyRegistrationFeeTotalWithVAT: number;
  readonly buyRefurbishFeeTotalWithVAT: number;
  readonly buyFixedFeeTotalWithVAT: number;
  readonly saleRefurbishFeeTotalWithVAT: number;
  readonly saleFixedFeeTotalWithVAT: number;
};

type TotalValuesByCustomer = {
  readonly buyTotalFeeWithVAT: number;
  readonly saleTotalFeeWithVAT: number;
};

type OverviewPrices = {
  readonly buyerPriceWithVAT: number;
  readonly marketplacePriceWithVAT: number;
  readonly salePriceWithVAT: number;
  readonly sellerPriceWithVAT: number;
};

type CashSentinelValidation = {
  readonly sellerPriceWithVAT: number;
  readonly buyTotalFeeWithVAT: number;
  readonly saleTotalFeeWithVAT: number;
  readonly deposit: number;
  readonly totalCashSentinelWithVAT: number;
};

type VariableFeeConfiguration = {
  readonly minPriceWithVAT?: number | undefined;
  readonly maxPriceWithVAT?: number | undefined;
  readonly rate?: number | undefined;
};

function computePriceWithoutVAT(priceWithVAT: number, VATRate: number): number {
  return priceWithVAT / (1 + VATRate);
}

function adjustPriceAccordingToLimits(
  givenPrice: number,
  minPrice?: number,
  maxPrice?: number
): number {
  if (isTruthy(minPrice) && givenPrice < minPrice) {
    return minPrice;
  }
  if (isTruthy(maxPrice) && givenPrice > maxPrice) {
    return maxPrice;
  }
  return givenPrice;
}

function convertToMarketplacePackageDeal(pkgDeal: PackageDeal): MarketplacePackageDeal {
  return {
    category: pkgDeal.category,
    carElement: pkgDeal.carElement?.label ?? '',
    label: packageDealHelpers.getPackageDealDisplayedLabel(pkgDeal),
    VATRate: pkgDeal.ignoreVAT ? 0 : 20,
    priceWithVAT: packageDealHelpers.getPackageDealAndSparePartsPriceWithVAT(pkgDeal, 'all'),
  };
}

function availableAndNonDeleted(pkgDeal: PackageDeal): boolean {
  return pkgDeal.status === 'available' && nonDeleted(pkgDeal);
}

function getWorkPackageDeals(kanban: Kanban): readonly PackageDeal[] {
  return kanban.packageDeals
    .filter(
      ({ marketplaceBuyCategory, marketplaceSaleCategory }) =>
        !isTruthy(marketplaceBuyCategory) && !isTruthy(marketplaceSaleCategory)
    )
    .filter(availableAndNonDeleted)
    .filter(
      ({ code, category }) =>
        category !== 'EXP' && !WORK_RECAP_IGNORED_PKG_DEAL_CODES.includes(code)
    );
}

function getBuyRegistrationFeePackageDeals(
  kanban: Kanban,
  ignoreAvailableAndNonDeletedFilter: boolean = false
): readonly PackageDeal[] {
  return kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_BUY_PURCHASE_ORDER)
    .filter(
      ({ marketplaceBuyCategory }) => marketplaceBuyCategory === MKTP_PKG_CATEGORY_REGISTRATION_FEES
    )
    .filter((pkgDeal) => ignoreAvailableAndNonDeletedFilter || availableAndNonDeleted(pkgDeal));
}

function getBuyRefurbishFeePackageDeals(
  kanban: Kanban,
  ignoreAvailableAndNonDeletedFilter: boolean = false
): readonly PackageDeal[] {
  return kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_BUY_PURCHASE_ORDER)
    .filter(({ marketplaceBuyCategory }) => !isTruthy(marketplaceBuyCategory))
    .filter((pkgDeal) => ignoreAvailableAndNonDeletedFilter || availableAndNonDeleted(pkgDeal));
}

function getBuyFixedFeePackageDeals(
  kanban: Kanban,
  ignoreAvailableAndNonDeletedFilter: boolean = false
): readonly PackageDeal[] {
  return kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_BUY_PURCHASE_ORDER)
    .filter(
      ({ marketplaceBuyCategory }) => marketplaceBuyCategory === MKTP_PKG_CATEGORY_BUY_FIXED_FEES
    )
    .filter((pkgDeal) => ignoreAvailableAndNonDeletedFilter || availableAndNonDeleted(pkgDeal));
}

function getSaleRefurbishFeePackageDeals(kanban: Kanban): readonly PackageDeal[] {
  return kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_SALE_PURCHASE_ORDER)
    .filter(({ marketplaceSaleCategory }) => !isTruthy(marketplaceSaleCategory))
    .filter(availableAndNonDeleted);
}

function getSaleFixedFeePackageDeals(kanban: Kanban): readonly PackageDeal[] {
  return kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_SALE_PURCHASE_ORDER)
    .filter(
      ({ marketplaceSaleCategory }) => marketplaceSaleCategory === MKTP_PKG_CATEGORY_SALE_FIXED_FEES
    )
    .filter(availableAndNonDeleted);
}

function isCanceledPackageDeal(
  pkgDeal: PackageDeal,
  canceledPackageDealIds: readonly string[]
): boolean {
  return (
    nonDeleted(pkgDeal) &&
    pkgDeal.status === 'canceled' &&
    canceledPackageDealIds.includes(pkgDeal.id)
  );
}

function getCanceledBuyRegistrationFeePackageDeals(
  kanban: Kanban,
  canceledPackageDealIds: readonly string[]
): readonly PackageDeal[] {
  return getBuyRegistrationFeePackageDeals(kanban, true).filter((pkgDeal) =>
    isCanceledPackageDeal(pkgDeal, canceledPackageDealIds)
  );
}

function getCanceledBuyRefurbishFeePackageDeals(
  kanban: Kanban,
  canceledPackageDealIds: readonly string[]
): readonly PackageDeal[] {
  return getBuyRefurbishFeePackageDeals(kanban, true).filter((pkgDeal) =>
    isCanceledPackageDeal(pkgDeal, canceledPackageDealIds)
  );
}

function getCanceledBuyFixedFeePackageDeals(
  kanban: Kanban,
  canceledPackageDealIds: readonly string[]
): readonly PackageDeal[] {
  return getBuyFixedFeePackageDeals(kanban, true).filter((pkgDeal) =>
    isCanceledPackageDeal(pkgDeal, canceledPackageDealIds)
  );
}

function computeCanceledPackageDeals(
  kanban: Kanban,
  canceledPackageDealIds: readonly string[]
): CanceledPackageDeals {
  const buyRegistrationFeePackageDeals = getCanceledBuyRegistrationFeePackageDeals(
    kanban,
    canceledPackageDealIds
  );
  const buyRefurbishFeePackageDeals = getCanceledBuyRefurbishFeePackageDeals(
    kanban,
    canceledPackageDealIds
  );
  const buyFixedFeePackageDeals = getCanceledBuyFixedFeePackageDeals(
    kanban,
    canceledPackageDealIds
  );

  return {
    buyRegistrationFeePackageDeals,
    buyRefurbishFeePackageDeals,
    buyFixedFeePackageDeals,
  };
}

function getVariableFeeConfigurationFromPackageDeal(
  variableFeePackageDeal: PackageDeal
): VariableFeeConfiguration {
  const minPriceVariable =
    variableFeePackageDeal?.variables[MKTP_PKG_DEAL_VARIABLE_FEES_MIN_PRICE_VARIABLE]?.value;
  const maxPriceVariable =
    variableFeePackageDeal?.variables[MKTP_PKG_DEAL_VARIABLE_FEES_MAX_PRICE_VARIABLE]?.value;
  const rateVariable =
    variableFeePackageDeal?.variables[MKTP_PKG_DEAL_VARIABLE_FEES_RATE_VARIABLE]?.value;

  return {
    minPriceWithVAT: isTruthy(minPriceVariable) ? Number(minPriceVariable) : undefined,
    maxPriceWithVAT: isTruthy(maxPriceVariable) ? Number(maxPriceVariable) : undefined,
    rate: isTruthy(rateVariable) ? Number(rateVariable) : undefined,
  };
}

/**
 * If no rate is provided, returns 0.
 * Otherwise, returns the computed price, taking into account limits if they have been specified
 */
function computeVariableFeePriceWithVATForSaleMandate(
  inputPriceWithVAT: number,
  { minPriceWithVAT, maxPriceWithVAT, rate }: VariableFeeConfiguration
): number {
  if (!isTruthy(rate)) {
    return 0;
  }

  const priceWithVAT = inputPriceWithVAT * rate;
  return adjustPriceAccordingToLimits(priceWithVAT, minPriceWithVAT, maxPriceWithVAT);
}

/**
 * If no rate is provided, returns 0.
 * Otherwise, returns the computed price, taking into account limits if they have been specified
 */
function computeVariableFeePriceWithVATForBuyMandate(
  inputPriceWithVAT: number,
  { minPriceWithVAT, maxPriceWithVAT, rate }: VariableFeeConfiguration,
  { buyFixedFeePackageDeals }: PackageDealsByFee
): number {
  if (!isTruthy(rate)) {
    return 0;
  }

  const buyFixedFeePackageDealsWithoutVariableFee = buyFixedFeePackageDeals.filter(
    ({ code }) => code !== MKTP_PKG_DEAL_CODE_VARIABLE_FEES
  );
  const buyFixedFeeTotalWithoutVariableFee =
    packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithVAT(
      buyFixedFeePackageDealsWithoutVariableFee,
      'all'
    );

  const marketplacePriceWithVATWithoutVariableFee =
    inputPriceWithVAT + buyFixedFeeTotalWithoutVariableFee;
  const marketplacePriceWithVAT = marketplacePriceWithVATWithoutVariableFee / (1 - rate);
  const variableFeePriceWithVAT =
    marketplacePriceWithVAT - marketplacePriceWithVATWithoutVariableFee;
  return adjustPriceAccordingToLimits(variableFeePriceWithVAT, minPriceWithVAT, maxPriceWithVAT);
}

function computeVariableFeePriceWithVAT(
  marketplaceMandate: MarketplaceMandate,
  variableFeeConfiguration: VariableFeeConfiguration,
  packageDealsByFee: PackageDealsByFee,
  inputPriceWithVAT: number
): number {
  switch (marketplaceMandate) {
    case MKTP_MANDATE_SALE:
      return computeVariableFeePriceWithVATForSaleMandate(
        inputPriceWithVAT,
        variableFeeConfiguration
      );
    case MKTP_MANDATE_BUY:
      return computeVariableFeePriceWithVATForBuyMandate(
        inputPriceWithVAT,
        variableFeeConfiguration,
        packageDealsByFee
      );
    default:
      return 0;
  }
}

function computeBuyVariableFeeWithVAT(
  kanban: Kanban,
  packageDealsByFee: PackageDealsByFee,
  inputPriceWithVAT: number
): number {
  const variableFeePackageDeal = kanban.packageDeals
    .filter(nonDeleted)
    .find(({ code }) => code === MKTP_PKG_DEAL_CODE_VARIABLE_FEES);

  if (!isTruthy(variableFeePackageDeal)) {
    return 0;
  }
  const marketplaceMandate = kanbanHelpers.getMarketplaceMandate(kanban);
  const variableFeeConfiguration =
    getVariableFeeConfigurationFromPackageDeal(variableFeePackageDeal);
  return computeVariableFeePriceWithVAT(
    marketplaceMandate,
    variableFeeConfiguration,
    packageDealsByFee,
    inputPriceWithVAT
  );
}

function computePackageDealsByFee(kanban: Kanban): PackageDealsByFee {
  const buyRegistrationFeePackageDeals = getBuyRegistrationFeePackageDeals(kanban);
  const buyRefurbishFeePackageDeals = getBuyRefurbishFeePackageDeals(kanban);
  const buyFixedFeePackageDeals = getBuyFixedFeePackageDeals(kanban);
  const saleRefurbishFeePackageDeals = getSaleRefurbishFeePackageDeals(kanban);
  const saleFixedFeePackageDeals = getSaleFixedFeePackageDeals(kanban);

  return {
    buyRegistrationFeePackageDeals,
    buyRefurbishFeePackageDeals,
    buyFixedFeePackageDeals,
    saleRefurbishFeePackageDeals,
    saleFixedFeePackageDeals,
  };
}

function computeTotalValuesByFee({
  buyRegistrationFeePackageDeals,
  buyRefurbishFeePackageDeals,
  buyFixedFeePackageDeals,
  saleRefurbishFeePackageDeals,
  saleFixedFeePackageDeals,
}: PackageDealsByFee): TotalValuesByFee {
  const buyRegistrationFeeTotalWithVAT =
    packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithVAT(
      buyRegistrationFeePackageDeals,
      'all'
    );
  const buyRefurbishFeeTotalWithVAT =
    packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithVAT(
      buyRefurbishFeePackageDeals,
      'all'
    );
  const buyFixedFeeTotalWithVAT = packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithVAT(
    buyFixedFeePackageDeals,
    'all'
  );

  const saleRefurbishFeeTotalWithVAT =
    packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithVAT(
      saleRefurbishFeePackageDeals,
      'all'
    );
  const saleFixedFeeTotalWithVAT = packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithVAT(
    saleFixedFeePackageDeals,
    'all'
  );

  return {
    buyRegistrationFeeTotalWithVAT,
    buyRefurbishFeeTotalWithVAT,
    buyFixedFeeTotalWithVAT,
    saleRefurbishFeeTotalWithVAT,
    saleFixedFeeTotalWithVAT,
  };
}

function computeTotalValuesByCustomer({
  buyRegistrationFeeTotalWithVAT,
  buyRefurbishFeeTotalWithVAT,
  buyFixedFeeTotalWithVAT,
  saleRefurbishFeeTotalWithVAT,
  saleFixedFeeTotalWithVAT,
}: TotalValuesByFee): TotalValuesByCustomer {
  return {
    buyTotalFeeWithVAT:
      buyRegistrationFeeTotalWithVAT + buyRefurbishFeeTotalWithVAT + buyFixedFeeTotalWithVAT,
    saleTotalFeeWithVAT: saleRefurbishFeeTotalWithVAT + saleFixedFeeTotalWithVAT,
  };
}

function computeOverviewPricesUsingMarketplacePrice(
  inputPriceWithVAT: number,
  negotiationWithVAT: number,
  canceledPackageDeals: CanceledPackageDeals,
  {
    buyRegistrationFeeTotalWithVAT,
    buyRefurbishFeeTotalWithVAT,
    buyFixedFeeTotalWithVAT,
    saleRefurbishFeeTotalWithVAT,
    saleFixedFeeTotalWithVAT,
  }: TotalValuesByFee
): OverviewPrices {
  const marketplacePriceWithVAT =
    inputPriceWithVAT -
    negotiationWithVAT -
    packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithVAT(
      canceledPackageDeals.buyFixedFeePackageDeals,
      'all'
    );
  const buyerPriceWithVAT =
    marketplacePriceWithVAT + buyRefurbishFeeTotalWithVAT + buyRegistrationFeeTotalWithVAT;
  const salePriceWithVAT = marketplacePriceWithVAT - buyFixedFeeTotalWithVAT;
  const sellerPriceWithVAT =
    salePriceWithVAT - saleRefurbishFeeTotalWithVAT - saleFixedFeeTotalWithVAT;

  return {
    buyerPriceWithVAT,
    marketplacePriceWithVAT,
    salePriceWithVAT,
    sellerPriceWithVAT,
  };
}

function computeOverviewPricesUsingSalePrice(
  inputPriceWithVAT: number,
  negotiationWithVAT: number,
  {
    buyRegistrationFeeTotalWithVAT,
    buyRefurbishFeeTotalWithVAT,
    buyFixedFeeTotalWithVAT,
    saleRefurbishFeeTotalWithVAT,
    saleFixedFeeTotalWithVAT,
  }: TotalValuesByFee
): OverviewPrices {
  const salePriceWithVAT = inputPriceWithVAT - negotiationWithVAT;
  const sellerPriceWithVAT =
    salePriceWithVAT - saleRefurbishFeeTotalWithVAT - saleFixedFeeTotalWithVAT;
  const marketplacePriceWithVAT = salePriceWithVAT + buyFixedFeeTotalWithVAT;
  const buyerPriceWithVAT =
    marketplacePriceWithVAT + buyRefurbishFeeTotalWithVAT + buyRegistrationFeeTotalWithVAT;

  return {
    buyerPriceWithVAT,
    marketplacePriceWithVAT,
    salePriceWithVAT,
    sellerPriceWithVAT,
  };
}

function computeOverviewPrices(
  kanban: Kanban,
  inputPriceWithVAT: number,
  negotiationWithVAT: number,
  canceledPackageDeals: CanceledPackageDeals,
  totalValuesByFee: TotalValuesByFee
): OverviewPrices {
  const marketplaceMandate = kanbanHelpers.getMarketplaceMandate(kanban);
  switch (marketplaceMandate) {
    case MKTP_MANDATE_BUY:
      return computeOverviewPricesUsingSalePrice(
        inputPriceWithVAT,
        negotiationWithVAT,
        totalValuesByFee
      );
    case MKTP_MANDATE_SALE:
      return computeOverviewPricesUsingMarketplacePrice(
        inputPriceWithVAT,
        negotiationWithVAT,
        canceledPackageDeals,
        totalValuesByFee
      );
    default:
      return {
        buyerPriceWithVAT: 0,
        marketplacePriceWithVAT: 0,
        salePriceWithVAT: 0,
        sellerPriceWithVAT: 0,
      };
  }
}

function computeCashSentinelValidation(
  sellerPriceWithVAT: number,
  buyTotalFeeWithVAT: number,
  saleTotalFeeWithVAT: number,
  deposit: number
): CashSentinelValidation {
  const totalCashSentinelWithVAT =
    sellerPriceWithVAT + buyTotalFeeWithVAT + saleTotalFeeWithVAT - deposit;
  return {
    sellerPriceWithVAT,
    buyTotalFeeWithVAT,
    saleTotalFeeWithVAT,
    deposit,
    totalCashSentinelWithVAT,
  };
}

function computeMarketplaceSaleOverview(
  salePriceWithVAT: number,
  fixedFeePackageDeals: readonly PackageDeal[],
  refurbishFeePackageDeals: readonly PackageDeal[]
): MarketplaceSaleOverview {
  return {
    salePriceWithVAT,
    fixedFeePackageDeals: fixedFeePackageDeals.map((pkgDeal) =>
      convertToMarketplacePackageDeal(pkgDeal)
    ),
    refurbishFeePackageDeals: refurbishFeePackageDeals.map((pkgDeal) =>
      convertToMarketplacePackageDeal(pkgDeal)
    ),
  };
}

function computeMarketplaceBuyOverview(
  salePriceWithVAT: number,
  deposit: number,
  registrationFeePackageDeals: readonly PackageDeal[],
  fixedFeePackageDeals: readonly PackageDeal[],
  refurbishFeePackageDeals: readonly PackageDeal[]
): MarketplaceBuyOverview {
  return {
    salePriceWithVAT,
    deposit,
    registrationFeePackageDeals: registrationFeePackageDeals.map((pkgDeal) =>
      convertToMarketplacePackageDeal(pkgDeal)
    ),
    fixedFeePackageDeals: fixedFeePackageDeals.map((pkgDeal) =>
      convertToMarketplacePackageDeal(pkgDeal)
    ),
    refurbishFeePackageDeals: refurbishFeePackageDeals.map((pkgDeal) =>
      convertToMarketplacePackageDeal(pkgDeal)
    ),
  };
}

function computeMarketplaceWorkOverview(kanban: Kanban): MarketplaceWorkOverview {
  const workPackageDeals = getWorkPackageDeals(kanban).map((pkgDeal) =>
    convertToMarketplacePackageDeal(pkgDeal)
  );
  return {
    packageDeals: workPackageDeals,
  };
}

export interface MarketplaceOverviewProps {
  readonly $: StoreStateSelector<Store, MarketplaceOverviewState>;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
}

export function MarketplaceOverview({
  $,
  $gs,
  $selectedKanban,
}: MarketplaceOverviewProps): JSX.Element {
  const [t] = useTranslation('details');
  const isOnline = useGetState($gs.$session.$isOnline);
  const kanban = useGetState($selectedKanban);
  const kanbanId = useGetState($selectedKanban.$id);
  const inputPriceAsText = useGetState($.$inputPrice);
  const negotiationAsText = useGetState($.$negotiation);
  const depositAsText = useGetState($.$deposit);
  const canceledPackageDealIds = useGetState($.$canceledPackageDealIds);

  const inputPriceWithVAT = useMemo(() => Number.parseFloat(inputPriceAsText), [inputPriceAsText]);
  const negotiationWithVAT = useMemo(
    () => Number.parseFloat(negotiationAsText),
    [negotiationAsText]
  );
  const deposit = useMemo(() => Number.parseFloat(depositAsText), [depositAsText]);

  const isMarketplaceBuyKanban = useMemo(() => {
    if (kanbanHelpers.isMarketplaceKanban(kanban)) {
      const marketplaceMandate = kanbanHelpers.getMarketplaceMandate(kanban);
      return marketplaceMandate === MKTP_MANDATE_BUY;
    }
    return false;
  }, [kanban]);

  const canceledPackageDeals: CanceledPackageDeals = useMemo(
    () => computeCanceledPackageDeals(kanban, canceledPackageDealIds),
    [kanban, canceledPackageDealIds]
  );

  const packageDealsByFee: PackageDealsByFee = useMemo(
    () => computePackageDealsByFee(kanban),
    [kanban]
  );
  const totalValuesByFee: TotalValuesByFee = useMemo(
    () => computeTotalValuesByFee(packageDealsByFee),
    [packageDealsByFee]
  );
  const totalValuesByCustomer: TotalValuesByCustomer = useMemo(
    () => computeTotalValuesByCustomer(totalValuesByFee),
    [totalValuesByFee]
  );
  const overviewPrices: OverviewPrices = useMemo(
    () =>
      computeOverviewPrices(
        kanban,
        inputPriceWithVAT,
        negotiationWithVAT,
        canceledPackageDeals,
        totalValuesByFee
      ),
    [kanban, totalValuesByFee, inputPriceWithVAT, negotiationWithVAT, canceledPackageDeals]
  );
  const cashSentinelValidation: CashSentinelValidation = useMemo(
    () =>
      computeCashSentinelValidation(
        overviewPrices.sellerPriceWithVAT,
        totalValuesByCustomer.buyTotalFeeWithVAT,
        totalValuesByCustomer.saleTotalFeeWithVAT,
        deposit
      ),
    [overviewPrices, totalValuesByCustomer, deposit]
  );
  const currentComputedBuyVariableFeeWithVAT = useMemo(
    () => computeBuyVariableFeeWithVAT(kanban, packageDealsByFee, inputPriceWithVAT),
    [kanban, packageDealsByFee, inputPriceWithVAT]
  );

  const saveOverviewValuesToKanban = useActionCallback(
    async ({ actionDispatch, kanbanRepository, getState }): Promise<void> => {
      const { inputPrice, negotiation, deposit } = getState();
      const updatedKanban = applyPayload(kanban, {
        marketplaceInfos: {
          inputPrice: Number.parseFloat(inputPrice),
          negotiation: Number.parseFloat(negotiation),
          deposit: Number.parseFloat(deposit),
        },
      });
      // Update the kanban in the repository if needed
      const payload = computePayload(kanban, updatedKanban);
      if (payload !== undefined) {
        await kanbanRepository.updateEntityFromPayload({ entityId: kanbanId, payload });
      }
      actionDispatch.scopeProperty('saveConfirmationDialog').setProperty('active', false);
    },
    [kanbanId, kanban],
    $
  );

  const applyVariableFeeValueToKanban = useActionCallback(
    async ({ actionDispatch, kanbanRepository }): Promise<void> => {
      await actionDispatch.execCallback(saveOverviewValuesToKanban);

      const variableFeePkgDeal = kanban.packageDeals
        .filter(nonDeleted)
        .find(({ code }) => code === MKTP_PKG_DEAL_CODE_VARIABLE_FEES);
      if (!isTruthy(variableFeePkgDeal)) {
        throw Error(t('tabs.marketplace.sellProcess.missingVariableFeePackageDealError'));
      }

      const updatedKanban = applyPayload(kanban, {
        packageDeals: [
          {
            id: variableFeePkgDeal.id,
            price: computePriceWithoutVAT(currentComputedBuyVariableFeeWithVAT, 0.2), // Set price without VAT
          },
        ],
      });
      // Update the kanban in the repository if needed
      const payload = computePayload(kanban, updatedKanban);
      if (payload !== undefined) {
        await kanbanRepository.updateEntityFromPayload({ entityId: kanbanId, payload });
      }
      actionDispatch.scopeProperty('variableFeeConfirmationDialog').setProperty('active', false);
    },
    [t, kanbanId, kanban, currentComputedBuyVariableFeeWithVAT, saveOverviewValuesToKanban],
    $
  );

  const marketplaceBuyOverviewActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      await actionDispatch.execCallback(saveOverviewValuesToKanban);
      const buyOverview: MarketplaceBuyOverview = computeMarketplaceBuyOverview(
        overviewPrices.salePriceWithVAT,
        deposit,
        packageDealsByFee.buyRegistrationFeePackageDeals,
        packageDealsByFee.buyFixedFeePackageDeals,
        packageDealsByFee.buyRefurbishFeePackageDeals
      );
      await httpClient.httpPostAsJSON<MarketplaceOverviewRequest, null>(
        CoreBackendRoutes.CREATE_MARKETPLACE_OVERVIEW_DOC(kanbanId, MARKETPLACE_DOC_TYPE_BUY),
        { buyOverview },
        'POST'
      );
      actionDispatch.setProperty('isDropdownButtonActive', false);
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', true);
    },
    [kanbanId, deposit, packageDealsByFee, overviewPrices, saveOverviewValuesToKanban],
    $
  );

  const marketplaceSaleOverviewActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      await actionDispatch.execCallback(saveOverviewValuesToKanban);
      const saleOverview: MarketplaceSaleOverview = computeMarketplaceSaleOverview(
        overviewPrices.salePriceWithVAT,
        packageDealsByFee.saleFixedFeePackageDeals,
        packageDealsByFee.saleRefurbishFeePackageDeals
      );
      await httpClient.httpPostAsJSON<MarketplaceOverviewRequest, null>(
        CoreBackendRoutes.CREATE_MARKETPLACE_OVERVIEW_DOC(kanbanId, MARKETPLACE_DOC_TYPE_SALE),
        { saleOverview },
        'POST'
      );
      actionDispatch.setProperty('isDropdownButtonActive', false);
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', true);
    },
    [kanbanId, packageDealsByFee, overviewPrices, saveOverviewValuesToKanban],
    $
  );

  const marketplaceWorkOverviewActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      await actionDispatch.execCallback(saveOverviewValuesToKanban);
      const workOverview: MarketplaceWorkOverview = computeMarketplaceWorkOverview(kanban);
      await httpClient.httpPostAsJSON<MarketplaceOverviewRequest, null>(
        CoreBackendRoutes.CREATE_MARKETPLACE_OVERVIEW_DOC(kanbanId, MARKETPLACE_DOC_TYPE_WORK),
        { workOverview },
        'POST'
      );
      actionDispatch.setProperty('isDropdownButtonActive', false);
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', true);
    },
    [kanbanId, kanban, saveOverviewValuesToKanban],
    $
  );

  const openSaveConfirmationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('saveConfirmationDialog').setProperty('active', true);
    },
    [],
    $
  );

  const openVariableFeeConfirmationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('variableFeeConfirmationDialog').setProperty('active', true);
    },
    [],
    $
  );

  const closeOverviewGenerationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', false);
    },
    [],
    $
  );

  const closeVariableFeeConfirmationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('variableFeeConfirmationDialog').setProperty('active', false);
    },
    [],
    $
  );

  const loadValuesFromKanbanAsyncEffect = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.applyPayload({
        inputPrice: String(kanban?.marketplaceInfos?.inputPrice ?? 0),
        negotiation: String(kanban?.marketplaceInfos?.negotiation ?? 0),
        deposit: String(kanban?.marketplaceInfos?.deposit ?? 0),
        canceledPackageDealIds: kanban?.marketplaceInfos?.canceledPackageDealIds ?? [],
      });
    },
    [kanban],
    $
  );

  const canceledPackageDealAmountWithVATAsText = useMemo(() => {
    const canceledBuyRegistrationFeePackageDealsAmountWithVAT =
      packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithVAT(
        canceledPackageDeals.buyRegistrationFeePackageDeals,
        'all'
      );
    const canceledBuyRefurbishFeePackageDealsAmountWithVAT =
      packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithVAT(
        canceledPackageDeals.buyRefurbishFeePackageDeals,
        'all'
      );
    const canceledBuyFixedFeePackageDealsAmountWithVAT =
      packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithVAT(
        canceledPackageDeals.buyFixedFeePackageDeals,
        'all'
      );
    const totalCanceledPackageDealsAmountWithVAT =
      canceledBuyRegistrationFeePackageDealsAmountWithVAT +
      canceledBuyRefurbishFeePackageDealsAmountWithVAT +
      canceledBuyFixedFeePackageDealsAmountWithVAT;
    return String(totalCanceledPackageDealsAmountWithVAT);
  }, [canceledPackageDeals]);

  const buyerOptionalFeePackageDeals: readonly PackageDeal[] = useMemo(
    () =>
      [
        ...packageDealsByFee.buyRegistrationFeePackageDeals,
        ...packageDealsByFee.buyRefurbishFeePackageDeals,
        ...canceledPackageDeals.buyRegistrationFeePackageDeals,
        ...canceledPackageDeals.buyRefurbishFeePackageDeals,
      ].sort((pkgDeal1, pkgDeal2) => compareStrings(pkgDeal1.code, pkgDeal2.code, 'UP')),
    [packageDealsByFee, canceledPackageDeals]
  );
  const buyerFixedFeePackageDeals: readonly PackageDeal[] = useMemo(
    () =>
      [
        ...packageDealsByFee.buyFixedFeePackageDeals,
        ...canceledPackageDeals.buyFixedFeePackageDeals,
      ].sort((pkgDeal1, pkgDeal2) => compareStrings(pkgDeal1.code, pkgDeal2.code, 'UP')),
    [packageDealsByFee, canceledPackageDeals]
  );
  const salePackageDeals: readonly PackageDeal[] = useMemo(
    () =>
      [
        ...packageDealsByFee.saleRefurbishFeePackageDeals,
        ...packageDealsByFee.saleFixedFeePackageDeals,
      ].sort((pkgDeal1, pkgDeal2) => compareStrings(pkgDeal1.code, pkgDeal2.code, 'UP')),
    [packageDealsByFee]
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadValuesFromKanbanAsyncEffect();
  }, [kanban, loadValuesFromKanbanAsyncEffect]);

  return (
    <>
      <ModalCardDialog
        $active={$.$overviewButtonDialog.$active}
        title={t('tabs.marketplace.overview.modal.generationModalTitle')}
        onOkClicked={closeOverviewGenerationModalActionCallback}
        dontShowCancelButton
      >
        <p>{t('tabs.marketplace.overview.modal.generationModalMessage')}</p>
      </ModalCardDialog>
      <ModalCardDialog
        $active={$.$saveConfirmationDialog.$active}
        title={t('tabs.marketplace.overview.modal.saveConfirmationModalTitle')}
        onOkClicked={saveOverviewValuesToKanban}
        dontShowCancelButton
      >
        <p>{t('tabs.marketplace.overview.modal.saveConfirmationModalMessage')}</p>
      </ModalCardDialog>
      <ModalCardDialog
        $active={$.$variableFeeConfirmationDialog.$active}
        title={t('tabs.marketplace.overview.modal.variableFeeConfirmationModalTitle')}
        onOkClicked={applyVariableFeeValueToKanban}
        onCancelClicked={closeVariableFeeConfirmationModalActionCallback}
      >
        <p>
          {t('tabs.marketplace.overview.modal.variableFeeConfirmationModalMessage', {
            buyVariableFeeWithVAT: formatPrice(currentComputedBuyVariableFeeWithVAT, 2),
          })}
        </p>
      </ModalCardDialog>
      <div className="m-2">
        <div className="field is-grouped is-flex is-align-items-flex-end">
          <p className="control">
            <div className="has-text-weight-bold">
              {isMarketplaceBuyKanban
                ? t('tabs.marketplace.overview.salePrice')
                : t('tabs.marketplace.overview.marketplacePrice')}
            </div>
            <Input $={$.$inputPrice} type="number" className="has-text-right" />
          </p>
          <p className="control">
            <div className="has-text-weight-bold">{t('tabs.marketplace.overview.negotiation')}</div>
            <Input $={$.$negotiation} type="number" className="has-text-right" />
          </p>
          <p className="control">
            <div className="has-text-weight-bold">
              {t('tabs.marketplace.overview.canceledPackageDealsAmount')}
            </div>
            <input
              className="input has-text-right"
              readOnly
              disabled
              value={canceledPackageDealAmountWithVATAsText}
            />
          </p>
          <p className="control">
            <Button
              iconId="save"
              label={t('tabs.marketplace.saveButton')}
              onClick={openSaveConfirmationModalActionCallback}
              additionalClass="is-primary"
              disabled={!isOnline}
            />
          </p>
          <p className="control">
            <Button
              iconId="dollar-sign"
              label={t('tabs.marketplace.variableFeeButton')}
              onClick={openVariableFeeConfirmationModalActionCallback}
              additionalClass="is-primary"
              disabled={!isOnline}
            />
          </p>
          <p className="control">
            <DropdownButton
              iconId="file"
              disabled={!isOnline}
              additionalClass="is-primary"
              $active={$.$isDropdownButtonActive}
              label={t('tabs.marketplace.generateDocButton')}
            >
              <>
                <button
                  type="button"
                  className="dropdown-item has-text-left"
                  onClick={marketplaceBuyOverviewActionCallback}
                >
                  {t('tabs.marketplace.buyOverviewButton')}
                </button>
                <button
                  type="button"
                  className="dropdown-item has-text-left"
                  onClick={marketplaceSaleOverviewActionCallback}
                >
                  {t('tabs.marketplace.saleOverviewButton')}
                </button>
                <button
                  type="button"
                  className="dropdown-item has-text-left"
                  onClick={marketplaceWorkOverviewActionCallback}
                >
                  {t('tabs.marketplace.workOverviewButton')}
                </button>
              </>
            </DropdownButton>
          </p>
        </div>
      </div>
      <table className="table is-striped is-bordered is-hoverable is-fullwidth mx-2" role="none">
        <thead>
          <tr>
            <th colSpan={3} className="has-text-centered">
              {t('tabs.marketplace.overview.tableTitle')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key="buyRefurbishFeeRow">
            <td className="is-size-6 has-text-weight-bold">
              {t('tabs.marketplace.overview.buyRefurbishFee.title')}
            </td>
            <td className="has-text-centered">
              {formatPrice(overviewPrices.buyerPriceWithVAT, 2)}
            </td>
            <td>
              <details>
                <summary className="has-text-weight-bold">
                  {`${t('tabs.marketplace.overview.buyRefurbishFee.description')} - ${formatPrice(totalValuesByFee.buyRegistrationFeeTotalWithVAT + totalValuesByFee.buyRefurbishFeeTotalWithVAT, 2)}`}
                </summary>
                {buyerOptionalFeePackageDeals.map((pkgDeal) => (
                  <LabelWithPrice
                    isCancelable
                    id={pkgDeal.id}
                    key={pkgDeal.id}
                    kanban={kanban}
                    $canceledPackageDealIds={$.$canceledPackageDealIds}
                    label={packageDealHelpers.getPackageDealDisplayedLabel(pkgDeal)}
                    price={packageDealHelpers.getPackageDealAndSparePartsPriceWithVAT(
                      pkgDeal,
                      'all'
                    )}
                  />
                ))}
              </details>
            </td>
          </tr>
          <tr key="buyFixedFeeRow">
            <td className="is-size-6 has-text-weight-bold">
              {t('tabs.marketplace.overview.buyFixedFee.title')}
            </td>
            <td className="has-text-centered">
              {formatPrice(overviewPrices.marketplacePriceWithVAT, 2)}
            </td>
            <td>
              <details>
                <summary className="has-text-weight-bold">
                  {`${t('tabs.marketplace.overview.buyFixedFee.description')} - ${formatPrice(totalValuesByFee.buyFixedFeeTotalWithVAT, 2)}`}
                </summary>
                {buyerFixedFeePackageDeals.map((pkgDeal) => (
                  <LabelWithPrice
                    isCancelable
                    id={pkgDeal.id}
                    key={pkgDeal.id}
                    kanban={kanban}
                    $canceledPackageDealIds={$.$canceledPackageDealIds}
                    label={packageDealHelpers.getPackageDealDisplayedLabel(pkgDeal)}
                    price={packageDealHelpers.getPackageDealAndSparePartsPriceWithVAT(
                      pkgDeal,
                      'all'
                    )}
                  />
                ))}
              </details>
            </td>
          </tr>
          <tr key="saleRow">
            <td className="is-size-6 has-text-weight-bold">
              {t('tabs.marketplace.overview.saleFee.title')}
            </td>
            <td className="has-text-centered">{formatPrice(overviewPrices.salePriceWithVAT, 2)}</td>
            <td>
              <details>
                <summary className="has-text-weight-bold">
                  {`${t('tabs.marketplace.overview.saleFee.description')} - ${formatPrice(totalValuesByCustomer.saleTotalFeeWithVAT, 2)}`}
                </summary>
                {salePackageDeals.map((pkgDeal) => (
                  <LabelWithPrice
                    id={pkgDeal.id}
                    key={pkgDeal.id}
                    kanban={kanban}
                    $canceledPackageDealIds={$.$canceledPackageDealIds}
                    label={packageDealHelpers.getPackageDealDisplayedLabel(pkgDeal)}
                    price={packageDealHelpers.getPackageDealAndSparePartsPriceWithVAT(
                      pkgDeal,
                      'all'
                    )}
                  />
                ))}
              </details>
            </td>
          </tr>
          <tr key="saleTotalRow">
            <td className="is-size-6 has-text-weight-bold">
              {t('tabs.marketplace.overview.saleTotal.title')}
            </td>
            <td className="has-text-centered">
              {formatPrice(overviewPrices.sellerPriceWithVAT, 2)}
            </td>
            <td aria-label="empty" />
          </tr>
        </tbody>
      </table>
      <table className="table is-striped is-bordered is-hoverable is-fullwidth mx-2">
        <thead>
          <tr>
            <th colSpan={2} className="has-text-centered">
              {t('tabs.marketplace.overview.validation.title')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key="saleTotalRow">
            <td>{t('tabs.marketplace.overview.validation.sellerPrice')}</td>
            <td className="has-text-right">
              {formatPrice(cashSentinelValidation.sellerPriceWithVAT, 2)}
            </td>
          </tr>
          <tr key="buyFeeRow">
            <td>{t('tabs.marketplace.overview.validation.buyFee')}</td>
            <td className="has-text-right">
              {formatPrice(cashSentinelValidation.buyTotalFeeWithVAT, 2)}
            </td>
          </tr>
          <tr key="saleFeeRow">
            <td>{t('tabs.marketplace.overview.validation.saleFee')}</td>
            <td className="has-text-right">
              {formatPrice(cashSentinelValidation.saleTotalFeeWithVAT, 2)}
            </td>
          </tr>
          <tr key="depositRow">
            <td>{t('tabs.marketplace.overview.validation.deposit')}</td>
            <td className="has-text-right">
              <Input $={$.$deposit} type="number" className="has-text-right" />
            </td>
          </tr>
          <tr key="totalCSRow" className="is-size-6 has-text-weight-bold">
            <td>{t('tabs.marketplace.overview.validation.totalCashSentinel')}</td>
            <td className="has-text-right">
              {formatPrice(cashSentinelValidation.totalCashSentinelWithVAT, 2)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
