/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import type { StorageCategories } from '@stimcar/libs-base';
import type {
  ActionCallbackFromFunction,
  ActionContext,
  AnyStoreDef,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import {
  KANBAN_IDENTITY_PICTURE_CATEGORY,
  KANBAN_IDENTITY_PICTURE_FOLDER,
  KANBAN_IDENTITY_PICTURE_NAME,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { imageHelpers } from '../../../utils/imageHelpers.js';
import { LoadingObject } from '../misc/index.js';
import type { OnRemoveActionCallback } from './AttachmentThumbnail.js';
import type { ComputeAttachmentUrlCallback } from './typings/attachment.js';
import type { ImageModalState, PictureEditionToolkitState } from './typings/store.js';
import { IncorrectAttachmentOrientationDialog } from './IncorrectAttachmentOrientationDialog.js';

const THUMBNAIL_IMAGE_WIDTH = 256;
const IMAGE_RATIO = 0.75;
const THUMBNAIL_DISPLAY_WIDTH = 50;

function loadFileAndStoreInStateAction<SD extends AnyStoreDef>(
  { actionDispatch }: ActionContext<SD, PictureEditionToolkitState>,
  file: File
): void {
  actionDispatch.scopeProperty('base64').setValue(URL.createObjectURL(file));
}

// eslint-disable-next-line @typescript-eslint/require-await
async function showImageDialogAction<SD extends AnyStoreDef>(
  { actionDispatch }: ActionContext<SD, ImageModalState>,
  isOnline: boolean,
  modalImageUrl: string | undefined,
  base64: string | undefined
): Promise<void> {
  actionDispatch.reduce((initial: ImageModalState) => {
    const imagesOrUrls: string[] = [];
    if (isOnline && isTruthyAndNotEmpty(modalImageUrl)) {
      imagesOrUrls.push(modalImageUrl);
    } else if (isTruthyAndNotEmpty(base64)) {
      imagesOrUrls.push(base64);
    }
    return {
      ...initial,
      active: true,
      imagesUrls: imagesOrUrls,
      imageIndex: 0,
    };
  });
}

export type UpdateKanbanIdentityPictureCallback<SD extends AnyStoreDef> =
  ActionCallbackFromFunction<
    SD,
    (
      category: StorageCategories,
      objectId: string,
      folder: string,
      file: File,
      callback?: (fileNamesMap: Record<string, string>) => Promise<void>
    ) => Promise<void>
  >;

export interface PictureEditionToolkit<SD extends AnyStoreDef> {
  readonly updateKanbanIdentityPictureAction?: UpdateKanbanIdentityPictureCallback<SD>;
  readonly onRemoveCallback?: OnRemoveActionCallback<SD>;
  readonly $: StoreStateSelector<SD, PictureEditionToolkitState>;
}

interface BaseKanbanIdentityPictureComponentProps<SD extends AnyStoreDef> {
  /**
   * If we don't have a kanban ID, then we are in kanban creation case, the image will be loaded locally, then sended to the server
   * when the kanban is actually created
   */
  readonly kanbanId?: string;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly thumbnailDisplayWidth?: number;
  readonly isOnline: boolean;
}

interface KanbanIdentityPictureComponentProps<SD extends AnyStoreDef>
  extends BaseKanbanIdentityPictureComponentProps<SD> {
  readonly thumbnailDisplayWidth?: number;
  readonly pictureEditionToolkit?: PictureEditionToolkit<SD>;
}

export function KanbanIdentityPictureComponent<SD extends AnyStoreDef>({
  kanbanId,
  pictureEditionToolkit,
  $imageModal,
  computeAttachmentUrl,
  thumbnailDisplayWidth,
  isOnline,
}: KanbanIdentityPictureComponentProps<SD>): JSX.Element {
  return (
    <>
      {isTruthy(pictureEditionToolkit) && isOnline ? (
        <ReadWriteKanbanIdentityMarkup
          kanbanId={kanbanId}
          computeAttachmentUrl={computeAttachmentUrl}
          $imageModal={$imageModal}
          isOnline={isOnline}
          toolkit={pictureEditionToolkit}
          thumbnailDisplayWidth={thumbnailDisplayWidth}
        />
      ) : (
        <ReadOnlyKanbanIdentityMarkup
          kanbanId={kanbanId}
          computeAttachmentUrl={computeAttachmentUrl}
          $imageModal={$imageModal}
          isOnline={isOnline}
          thumbnailDisplayWidth={thumbnailDisplayWidth}
        />
      )}
    </>
  );
}

interface AbstractKanbanIdentityMarkupProps<SD extends AnyStoreDef>
  extends ReadOnlyKanbanIdentityMarkupProps<SD> {
  readonly imageAsBase64?: string | undefined;
  readonly onFileSelectionCallback?: (file: File) => Promise<void>;
  readonly onRemoveCallback?: () => void | Promise<void>;
}

function AbstractKanbanIdentityMarkup<SD extends AnyStoreDef>({
  kanbanId,
  computeAttachmentUrl,
  thumbnailDisplayWidth,
  onRemoveCallback,
  onFileSelectionCallback,
  $imageModal,
  isOnline,
  imageAsBase64,
}: AbstractKanbanIdentityMarkupProps<SD>): JSX.Element {
  const thumbnailImageUrl = kanbanId
    ? computeAttachmentUrl(
        KANBAN_IDENTITY_PICTURE_CATEGORY,
        KANBAN_IDENTITY_PICTURE_FOLDER,
        KANBAN_IDENTITY_PICTURE_NAME,
        kanbanId,
        {
          mode: 'cover',
          size: `${THUMBNAIL_IMAGE_WIDTH}x${THUMBNAIL_IMAGE_WIDTH * IMAGE_RATIO}`,
        }
      )
    : '';
  const modalImageUrl = kanbanId
    ? computeAttachmentUrl(
        KANBAN_IDENTITY_PICTURE_CATEGORY,
        KANBAN_IDENTITY_PICTURE_FOLDER,
        KANBAN_IDENTITY_PICTURE_NAME,
        kanbanId
      )
    : undefined;

  const showImageDialog = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(showImageDialogAction, isOnline, modalImageUrl, imageAsBase64);
    },
    [imageAsBase64, isOnline, modalImageUrl],
    $imageModal
  );

  return (
    <>
      <div className="card">
        <div className="card-image">
          <figure className="image">
            <LoadingObject
              src={!imageAsBase64 ? thumbnailImageUrl : imageAsBase64}
              errorIcon="car"
              onRemoveCallback={onRemoveCallback}
              onFileSelectionCallback={onFileSelectionCallback}
              onClickCallback={showImageDialog}
              width={thumbnailDisplayWidth || THUMBNAIL_DISPLAY_WIDTH}
              height={
                thumbnailDisplayWidth
                  ? thumbnailDisplayWidth * IMAGE_RATIO
                  : THUMBNAIL_DISPLAY_WIDTH * IMAGE_RATIO
              }
              objectFit="cover"
              fileSelectionAccept="image/*"
            />
          </figure>
        </div>
      </div>
    </>
  );
}

interface ReadOnlyKanbanIdentityMarkupProps<SD extends AnyStoreDef> {
  readonly kanbanId?: string;
  readonly thumbnailDisplayWidth?: number;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly isOnline: boolean;
}

function ReadOnlyKanbanIdentityMarkup<SD extends AnyStoreDef>({
  kanbanId,
  thumbnailDisplayWidth,
  computeAttachmentUrl,
  $imageModal,
  isOnline,
}: ReadOnlyKanbanIdentityMarkupProps<SD>): JSX.Element {
  return (
    <AbstractKanbanIdentityMarkup
      kanbanId={kanbanId}
      thumbnailDisplayWidth={thumbnailDisplayWidth}
      computeAttachmentUrl={computeAttachmentUrl}
      $imageModal={$imageModal}
      isOnline={isOnline}
    />
  );
}

interface ReadWriteKanbanIdentityMarkupProps<SD extends AnyStoreDef>
  extends ReadOnlyKanbanIdentityMarkupProps<SD> {
  readonly toolkit: PictureEditionToolkit<SD>;
  readonly isOnline: boolean;
}

function ReadWriteKanbanIdentityMarkup<SD extends AnyStoreDef>({
  kanbanId,
  thumbnailDisplayWidth,
  toolkit,
  isOnline,
  computeAttachmentUrl,
  $imageModal,
}: ReadWriteKanbanIdentityMarkupProps<SD>): JSX.Element {
  const { onRemoveCallback, $, updateKanbanIdentityPictureAction } = toolkit;
  const isIncorrectOrientationModalOpen = useGetState($.$isIncorrectOrientationModalOpen);
  const base64 = useGetState($.$base64);
  const isImageInIncorrectOrientation = useGetState($.$hasIncorrectOrientation);

  const importDocumentActionCallback = useActionCallback(
    async ({ actionDispatch }, theFile: File): Promise<void> => {
      const orientation = await imageHelpers.getOrientation(theFile);
      switch (orientation) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 'horizontal': {
          const newFile = new File([theFile], KANBAN_IDENTITY_PICTURE_NAME, {
            type: theFile.type,
            lastModified: theFile.lastModified,
          });
          if (updateKanbanIdentityPictureAction && isTruthyAndNotEmpty(kanbanId)) {
            await actionDispatch.execCallback(
              updateKanbanIdentityPictureAction,
              KANBAN_IDENTITY_PICTURE_CATEGORY,
              kanbanId,
              KANBAN_IDENTITY_PICTURE_FOLDER,
              newFile
            );
          }
          await actionDispatch.exec(loadFileAndStoreInStateAction, theFile);
          break;
        }
        default:
          await actionDispatch.exec(loadFileAndStoreInStateAction, theFile);
          actionDispatch.applyPayload({
            hasIncorrectOrientation: true,
            isIncorrectOrientationModalOpen: true,
          });
          break;
      }
    },
    [kanbanId, updateKanbanIdentityPictureAction],
    $
  );

  const onRemoveClickedCallback = useCallback(async (): Promise<void> => {
    if (onRemoveCallback) {
      await onRemoveCallback(
        KANBAN_IDENTITY_PICTURE_FOLDER,
        KANBAN_IDENTITY_PICTURE_NAME,
        kanbanId ?? ''
      );
    }
  }, [onRemoveCallback, kanbanId]);

  const showRemoveTooling = isTruthy(onRemoveCallback) && isOnline;

  return (
    <>
      <AbstractKanbanIdentityMarkup
        kanbanId={kanbanId}
        thumbnailDisplayWidth={thumbnailDisplayWidth}
        imageAsBase64={!isImageInIncorrectOrientation ? base64 : undefined}
        onFileSelectionCallback={isOnline ? importDocumentActionCallback : undefined}
        onRemoveCallback={showRemoveTooling ? onRemoveClickedCallback : undefined}
        computeAttachmentUrl={computeAttachmentUrl}
        $imageModal={$imageModal}
        isOnline={isOnline}
      />
      {isIncorrectOrientationModalOpen && <IncorrectAttachmentOrientationDialog $={$} />}
    </>
  );
}
