import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import { Button } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { RadioButtonsFormField } from '../../../lib/bulma/form/RadioButtonsFormField.js';
import type { KanbansBoardDisplayMode, KanbansBoardDisplayState } from './typings/store.js';
import { KANBANS_BOARD_DISPLAY_MODES } from './typings/store.js';

const SIZES = [1, 2, 3, 4, 5, 6, 7, 8, 9];

function persistDisplayStateAction({ getState }: ActionContext<Store, KanbansBoardDisplayState>) {
  const { active, size, mode } = getState();
  localStorage.setItem(
    LocalStorageKeys.KANBANS_BOARD_DISPLAY,
    JSON.stringify({ active, size, mode })
  );
}

interface KanbansBoardDisplayConfigurationProps {
  readonly $kanbansBoardDisplay: StoreStateSelector<Store, KanbansBoardDisplayState>;
}

export function KanbansBoardDisplayConfiguration({
  $kanbansBoardDisplay,
}: KanbansBoardDisplayConfigurationProps): JSX.Element {
  const [t] = useTranslation('display');

  const persistStateActionCallback = useActionCallback(
    persistDisplayStateAction,
    [],
    $kanbansBoardDisplay
  );

  const $kanbansBoardDisplayWithChangeTrigger = useSelectorWithChangeTrigger(
    $kanbansBoardDisplay,
    persistStateActionCallback
  );

  const diplayModesEntries: readonly FormFieldEntry<KanbansBoardDisplayMode>[] = useMemo(
    () =>
      KANBANS_BOARD_DISPLAY_MODES.map((mode) => ({
        id: mode,
        label: t(`kanbansBoard.mode.${mode}`),
      })),
    [t]
  );

  const onToggleShowHideActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      const active = getState();
      actionDispatch.setValue(!active);
    },
    [],
    $kanbansBoardDisplayWithChangeTrigger.$active
  );

  const active = useGetState($kanbansBoardDisplayWithChangeTrigger.$active);

  return (
    <>
      <Button onClick={onToggleShowHideActionCallback} iconId="gear" size="small" />
      {active && (
        <>
          <RadioButtonsFormField
            label={t('kanbansBoard.size')}
            $={$kanbansBoardDisplayWithChangeTrigger.$size}
            id="size"
            entries={SIZES}
            radioGroupLayout="vertical"
          />
          <RadioButtonsFormField
            label={t('kanbansBoard.mode.label')}
            $={$kanbansBoardDisplayWithChangeTrigger.$mode}
            id="mode"
            entries={diplayModesEntries}
            radioGroupLayout="vertical"
          />
        </>
      )}
    </>
  );
}
