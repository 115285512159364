import React from 'react';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { FaIcon } from '../../elements/FaIcon.js';

interface Props {
  readonly warning: string | undefined;
  readonly isNarrow?: boolean;
}

export function FormWarning({ warning, isNarrow = false }: Props): JSX.Element {
  return (
    <>
      {isTruthyAndNotEmpty(warning) && (
        <div className={`column ${isNarrow ? 'is-narrow' : ''}`}>
          <div className="field">
            <p className="help is-primary">
              <FaIcon id="exclamation-triangle" label={warning} tooltip={warning} />
            </p>
          </div>
        </div>
      )}
    </>
  );
}
