/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { useDeactivateOnClickOutside } from '../../hooks/useDeactivateOnClickOutside.js';
import { FaIcon } from './FaIcon.js';

interface SplitButtonDropdownActionEntry {
  readonly type: 'entry';
  readonly label: string;
  readonly handler: () => void | Promise<void>;
  readonly disabled: boolean;
  readonly iconId?: string;
  readonly tooltip?: string;
}

interface SplitButtonDropdownSeparator {
  readonly type: 'separator';
}

export type SplitButtonDropdownEntry =
  | SplitButtonDropdownActionEntry
  | SplitButtonDropdownSeparator;

interface Props<SD extends AnyStoreDef> {
  readonly isPrimary?: boolean;
  readonly entries: readonly SplitButtonDropdownEntry[];
  readonly disabled?: boolean;
  readonly isUpMenu?: boolean;
  readonly isFullwidth?: boolean;
  readonly $: StoreStateSelector<SD, boolean>;
}

export function SplitButtonDropdown<SD extends AnyStoreDef>({
  isPrimary = false,
  entries,
  isUpMenu = false,
  isFullwidth = false,
  disabled = false,
  $,
}: Props<SD>): JSX.Element {
  const [t] = useTranslation('custom');
  const isMenuOpen = useGetState($);

  const dropDownButtonRef = useRef<HTMLButtonElement | null>(null);
  useDeactivateOnClickOutside({
    domElementRef: dropDownButtonRef,
    $active: $,
    deactivateOnInternalClick: false,
  });

  const clickHandlerActionCallback = useActionCallback(
    ({ actionDispatch, getState }): void => {
      actionDispatch.setValue(!getState());
    },
    [],
    $
  );

  const [mainEntry, menuEntries] = useMemo(() => {
    // Filter separators (only keep actions)
    const actions: readonly SplitButtonDropdownActionEntry[] = entries.filter(
      ({ type }) => type !== 'separator'
    ) as readonly SplitButtonDropdownActionEntry[];
    // Retrieve first entry
    const firstActionEntry = actions[0];
    // Retrieve other entries
    const otherEntries =
      firstActionEntry === undefined ? [] : entries.slice(entries.indexOf(firstActionEntry) + 1);
    return [firstActionEntry, otherEntries];
  }, [entries]);

  const mainLabel = mainEntry?.label ?? t('splitButtondropdown.noActions');

  return (
    <div
      className={`dropdown is-active ${isUpMenu ? 'is-up' : ''}`}
      style={isFullwidth ? { display: 'flex' } : {}}
    >
      <div
        className="dropdown-trigger buttons has-addons"
        style={{
          marginBottom: 0,
          display: isFullwidth ? 'flex' : undefined,
          width: isFullwidth ? '100%' : undefined,
        }}
      >
        <button
          type="button"
          className={`button ${isPrimary ? 'is-primary' : ''}`}
          title={mainEntry?.tooltip ? mainEntry?.tooltip : mainLabel}
          onClick={mainEntry?.handler}
          disabled={disabled || !isTruthy(mainEntry) || mainEntry.disabled}
          style={isFullwidth ? { flex: 1 } : {}}
        >
          {mainEntry?.iconId ? (
            <FaIcon id={mainEntry?.iconId} label={mainLabel} />
          ) : (
            <span>{mainLabel}</span>
          )}
        </button>
        <button
          type="button"
          className={`button ${isPrimary ? 'is-primary' : ''}`}
          onClick={clickHandlerActionCallback}
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          style={isPrimary ? { borderLeftColor: 'initial' } : {}}
          title="Afficher les autres actions disponibles"
          disabled={disabled || menuEntries.length === 0}
          ref={dropDownButtonRef}
        >
          <span className="icon is-small">
            <i className={`fas fa-angle-${isUpMenu ? 'up' : 'down'}`} aria-hidden="true" />
          </span>
        </button>
      </div>
      {isMenuOpen && (
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content has-text-left">
            {menuEntries.map((e, index): JSX.Element => {
              if (e.type === 'entry') {
                return (
                  <DropdownItemButton key={e.label} entry={e} elementClassName="dropdown-item" />
                );
              }
              // eslint-disable-next-line react/no-array-index-key
              return <hr key={index} className="dropdown-divider" />;
            })}
          </div>
        </div>
      )}
    </div>
  );
}

interface DropdownItemButtonProps {
  readonly entry: SplitButtonDropdownActionEntry;
  readonly elementClassName?: string;
}

function DropdownItemButton({ entry, elementClassName }: DropdownItemButtonProps): JSX.Element {
  const doHandleInteraction = async (event: React.SyntheticEvent): Promise<void> => {
    if (!entry.disabled) {
      await entry.handler();
    } else {
      event.nativeEvent.stopImmediatePropagation();
    }
  };

  const onKeyDownHandler = async (event: React.KeyboardEvent): Promise<void> => {
    if (event.keyCode === 13) {
      await doHandleInteraction(event);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={elementClassName ?? ''}
      role="button"
      tabIndex={entry.disabled ? -1 : 0}
      title={entry.tooltip}
      style={
        entry.disabled
          ? {
              cursor: 'not-allowed',
              opacity: 0.5,
            }
          : {}
      }
      onKeyDown={onKeyDownHandler}
      onClick={doHandleInteraction}
    >
      {entry.iconId ? (
        <FaIcon id={entry?.iconId} label={entry.label} />
      ) : (
        <span>{entry.label}</span>
      )}
    </a>
  );
}
