import type { TFunction } from 'i18next';
import type { Kanban, PackageDeal, SparePart, SparePartManagementType } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import {
  kanbanHelpers,
  nonDeleted,
  packageDealHelpers,
  sparePartHelpers,
} from '@stimcar/libs-base';
import {
  computePayload,
  isTruthy,
  isTruthyAndNotEmpty,
  keysOf,
  nonnull,
} from '@stimcar/libs-kernel';
import type { Store } from '../../../state/typings/store.js';
import type { OperatorViewState, SparePartReferenceFormData } from '../../typings/store.js';
import type {
  OperatorCustomActionToPerformOnKanban,
  OperatorCustomCheckForConsistencyWarningAction,
  OperatorCustomRaiseWarningBeforeTerminateAction,
  OperatorCustomTerminateAction,
} from '../OperatorCountdown.js';
import {
  clearOperatorViewAndNavigateToSelectionAction,
  closeKanbanHandleOnPost,
} from '../../../utils/operatorUtils.js';

const convertSparePartToSparePartReferenceData = (
  sparePart: SparePart,
  packageDeal: PackageDeal
): SparePartReferenceFormData => {
  return {
    ...sparePart,
    packageDealLabel: packageDeal.label,
    packageDealAttachments: packageDeal.attachments?.filter(nonDeleted) ?? [],
    packageDealId: packageDeal.id,
    carElement: packageDeal.carElement,
    price: String(sparePart.price),
    providerUnitPrice: String(sparePart.providerUnitPrice),
    quantity: String(sparePart.quantity),
    label: packageDealHelpers.getSparePartDisplayedLabel(sparePart, packageDeal),
    provider: sparePart.provider ?? '',
    isReferenced:
      sparePart.dateOfReference !== null || sparePart.managementType === 'fullyManagedByCustomer',
    warnings: {},
  };
};

export const convertSparePartsToSparePartReferenceDatas = (
  spareParts: readonly SparePart[],
  packageDeals: readonly PackageDeal[]
): readonly SparePartReferenceFormData[] => {
  return spareParts.map((sp): SparePartReferenceFormData => {
    const pd = sparePartHelpers.getPackageDealThatHasGivenSparePart(packageDeals, sp.id);
    return convertSparePartToSparePartReferenceData(sp, nonnull(pd));
  });
};

export function convertToNumber(str: string): number {
  const newStr = str.replace(/,/g, '.').trim();
  return newStr.length === 0 ? NaN : Number(newStr);
}

const convertSparePartReferenceDataToSparePart = (
  sparePartWithPackageDealData: SparePartReferenceFormData
): SparePart => {
  const {
    id,
    deleted,
    label,
    price,
    managementType,
    comment,
    quantity,
    standId,
    dateOfReception,
    provider,
    providerUnitPrice,
    priceIsOverridden,
    dateOfOrder,
    commentForCustomer,
    dateOfReference,
    estimatedDateOfReception,
    commentForWorkshop,
  } = sparePartWithPackageDealData;
  let actualProvider: string | null = provider;
  if (managementType !== 'fullyManagedByStimcar') {
    actualProvider = null;
  } else if (!isTruthyAndNotEmpty(provider)) {
    actualProvider = null;
  }
  return {
    id,
    deleted,
    label,
    price: Number(price),
    managementType: managementType as SparePartManagementType,
    comment,
    quantity: Number(quantity),
    standId,
    dateOfReception,
    provider: actualProvider,
    providerUnitPrice: Number(providerUnitPrice),
    priceIsOverridden,
    dateOfOrder,
    commentForCustomer,
    dateOfReference,
    estimatedDateOfReception,
    commentForWorkshop,
  };
};

export function getSparePartAsReferenceData(kanban: Kanban) {
  const availableOrRecommendedPackageDeals =
    packageDealHelpers.getAvailableOrRecommendedPackageDeals(kanban.packageDeals, true);
  const { activeSpareParts } = sparePartHelpers.getActiveAndDeletedSparePartsFromPackageDeals(
    availableOrRecommendedPackageDeals
  );
  const sparePartsWithPackageDealCodeFromKanban = convertSparePartsToSparePartReferenceDatas(
    activeSpareParts,
    availableOrRecommendedPackageDeals
  );
  return sparePartsWithPackageDealCodeFromKanban;
}

export const computeHasLocalSparePartsChanges = (
  operatorViewState: OperatorViewState,
  initialKanban: Kanban | undefined
): boolean => {
  const { sparePartsUnderModification } = operatorViewState.sparePartsReferenceState;
  if (sparePartsUnderModification.length === 0) {
    return false;
  }
  const thePayload = computePayload(
    getSparePartAsReferenceData(nonnull(initialKanban)),
    sparePartsUnderModification
  );
  return thePayload !== undefined && thePayload.length > 0;
};

export const checkSparePartConsistencyAndRaiseWarningPerField = (
  sparePart: SparePartReferenceFormData,
  t: TFunction
): Record<string, string> => {
  const warnings: Record<string, string> = {};
  const providerUnitPrice = convertToNumber(sparePart.providerUnitPrice);
  const quantity = convertToNumber(sparePart.quantity);
  const price = convertToNumber(sparePart.price);
  if (providerUnitPrice < 0) {
    warnings.providerUnitPrice = t('sparePartsReference.warning.notPositive');
  }
  if (quantity <= 0) {
    warnings.quantity = t('sparePartsReference.warning.notStrictlyPositive');
  }
  if (price < 0) {
    warnings.price = t('sparePartsReference.warning.notPositive');
  } else {
    const buyingPrice = providerUnitPrice * quantity;
    if (price < buyingPrice) {
      warnings.price = t('sparePartsReference.warning.priceSmallerThanProviderUnitPrice');
    }
  }
  return warnings;
};

export const sparePartsReferenceCheckForConsistencyWarning: OperatorCustomCheckForConsistencyWarningAction =
  (operatorViewState: OperatorViewState, t: TFunction): string | undefined => {
    const { sparePartsUnderModification } = operatorViewState.sparePartsReferenceState;
    if (!isTruthy(sparePartsUnderModification)) {
      return undefined;
    }
    for (const sp of sparePartsUnderModification.filter((s) => !s.deleted)) {
      const warningsPerField = checkSparePartConsistencyAndRaiseWarningPerField(sp, t);
      if (keysOf(warningsPerField).length > 0) {
        return t('sparePartsReference.warning.sparePartNotConsistent', { spLabel: sp.label });
      }
    }
    return undefined;
  };

export const applySparePartsModificationsToKanban: OperatorCustomActionToPerformOnKanban = (
  { getState }: ActionContext<Store, OperatorViewState>,
  kanban: Kanban
): Kanban => {
  const operatorViewState = getState();
  const { sparePartsUnderModification } = operatorViewState.sparePartsReferenceState;
  if (!isTruthy(sparePartsUnderModification)) {
    return kanban;
  }
  const sparePartsPerPackageDealId: Record<string, SparePart[]> = {};
  sparePartsUnderModification.forEach((s) => {
    if (!isTruthy(sparePartsPerPackageDealId[s.packageDealId])) {
      sparePartsPerPackageDealId[s.packageDealId] = [];
    }
    const theSparePart: SparePart = convertSparePartReferenceDataToSparePart(s);
    sparePartsPerPackageDealId[s.packageDealId].push(theSparePart);
  });

  let updatedPackageDeals = kanban.packageDeals;
  keysOf(sparePartsPerPackageDealId).forEach((pddId) => {
    updatedPackageDeals = updatedPackageDeals.map((p): PackageDeal => {
      if (p.id === pddId) {
        const activeSpareParts = sparePartsPerPackageDealId[pddId].filter(nonDeleted);
        const activeIds = activeSpareParts.map((sp) => sp.id);
        const existingSparePartsOnPddToDelete = p.spareParts
          .filter((sp) => !activeIds.includes(sp.id))
          .map((sp): SparePart => {
            return {
              ...sp,
              deleted: true,
            };
          });
        return {
          ...p,
          spareParts: [...activeSpareParts, ...existingSparePartsOnPddToDelete],
        };
      }
      return p;
    });
  });
  const newKanban: Kanban = {
    ...kanban,
    packageDeals: updatedPackageDeals,
  };
  return newKanban;
};

export const sparePartsReferenceRaiseWarningBeforeTerminate: OperatorCustomRaiseWarningBeforeTerminateAction =
  (
    ctx: ActionContext<Store, OperatorViewState>,
    kanban: Kanban,
    t: TFunction
  ): string | undefined => {
    const activeSpareParts = packageDealHelpers
      .getAvailableOrRecommendedPackageDeals(kanban.packageDeals, false)
      .map((pck): SparePart[] => pck.spareParts.filter(nonDeleted))
      .reduce((acc, val) => acc.concat(val), []);
    for (const sp of activeSpareParts) {
      if (sparePartHelpers.mandatoryReferenceNotDone(sp)) {
        return t('sparePartsReference.warning.notAllSparePartsAreReferenced');
      }
    }
    return undefined;
  };

export const sparePartsReferenceTerminateAction: OperatorCustomTerminateAction = async (
  ctx: ActionContext<Store, OperatorViewState>,
  kanban: Kanban,
  standId: string
) => {
  const { actionDispatch, kanbanRepository, getGlobalState } = ctx;
  const { session } = getGlobalState();
  const updatedKanban = kanbanHelpers.finishSparePartsReferenceOperation(
    kanban,
    session.user!.login
  );
  const stoppedKanban = closeKanbanHandleOnPost(
    nonnull(session.infos?.id),
    updatedKanban,
    session.user?.permissions ?? {},
    standId,
    session.user?.login ?? null
  );
  await kanbanRepository.updateEntity(stoppedKanban);
  await actionDispatch.exec(clearOperatorViewAndNavigateToSelectionAction);
};

export function recomputeSparePartForNewManagementType(
  sparePart: SparePartReferenceFormData
): SparePartReferenceFormData {
  switch (sparePart.managementType as SparePartManagementType) {
    case 'fullyManagedByStimcar':
      return {
        ...sparePart,
        provider: '',
        dateOfReference: null,
        isReferenced: true,
      };
    case 'orderedByStimcarFromCustomersCatalog':
    case 'fullyManagedByCustomer':
      return {
        ...sparePart,
        providerUnitPrice: '0',
        priceIsOverridden: false,
        price: '0',
        provider: '',
        dateOfReference:
          sparePart.managementType === 'fullyManagedByCustomer' ? null : sparePart.dateOfReference,
      };

    default:
      throw Error(`Unknown SparePartManagementType: ${sparePart.managementType}`);
  }
}

export function onSparePartReferenceFormDataChangeAction(
  { actionDispatch, getState, getGlobalState }: ActionContext<Store, SparePartReferenceFormData>,
  t: TFunction
): void {
  const { operatedKanban } = getGlobalState().operatorView;

  if (isTruthy(operatedKanban)) {
    let updatedSp = getState();
    // Update price
    if (!updatedSp.priceIsOverridden) {
      const { quantity, providerUnitPrice } = updatedSp;
      const quantityAsNumber = convertToNumber(quantity);
      const providerUnitPriceAsNumber = convertToNumber(providerUnitPrice);
      if (Number.isNaN(quantityAsNumber) || Number.isNaN(providerUnitPriceAsNumber)) {
        updatedSp = {
          ...updatedSp,
          price: '',
        };
      } else {
        const { sparePartMarginPercentage } = operatedKanban.contract.configuration;
        updatedSp = {
          ...updatedSp,
          price: String(
            sparePartHelpers.computeInvoicedPrice(
              providerUnitPriceAsNumber,
              quantityAsNumber,
              sparePartMarginPercentage
            )
          ),
        };
      }
    }
    // IsReferenced ?
    let isReferenced = false;
    switch (updatedSp.managementType as SparePartManagementType) {
      case 'fullyManagedByStimcar':
        isReferenced =
          isTruthyAndNotEmpty(updatedSp.label) &&
          isTruthyAndNotEmpty(updatedSp.provider) &&
          Number(updatedSp.price) > 0;
        break;
      case 'fullyManagedByCustomer':
        isReferenced = true;
        break;
      case 'orderedByStimcarFromCustomersCatalog':
        isReferenced = isTruthyAndNotEmpty(updatedSp.label);
        break;
      default:
        throw new Error(`Unknown management type : ${updatedSp.managementType}`);
    }
    updatedSp = {
      ...updatedSp,
      isReferenced,
      dateOfReference: !isReferenced ? null : updatedSp.dateOfReference || Date.now(),
    };
    const payload = computePayload(getState(), updatedSp);
    if (payload !== undefined) {
      actionDispatch.applyPayload(payload);
    }
    // Append warnings
    const warnings = checkSparePartConsistencyAndRaiseWarningPerField(updatedSp, t);
    actionDispatch.setProperty('warnings', warnings);
  }
}
