import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { LicensePosition } from '@stimcar/core-libs-common';
import type { ActionCallbackFromFunction, StoreStateSelector } from '@stimcar/libs-uikernel';
import { keysOf } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { KanbanListWithSearchBaseStoreDef } from '../KanbanListWithSearch.js';
import type { KanbanListWithSearchState } from '../typings/store.js';

export type RecognizedLicenseSVGRectangleStyle = 'default' | 'unknown' | 'selected';

type RecognizedLicenseSVGRectangleProps<SD extends KanbanListWithSearchBaseStoreDef> = Pick<
  Props<SD>,
  '$' | 'computeStyle' | 'onClickActionCallback'
> &
  LicensePosition & {
    readonly license: string;
  };

type StrokeStyle = {
  readonly color: string;
  readonly width: number;
};

export function RecognizedLicenseSVGRectangle<SD extends KanbanListWithSearchBaseStoreDef>({
  $,
  license: recognizedLicense,
  onClickActionCallback,
  computeStyle,
  ...box
}: RecognizedLicenseSVGRectangleProps<SD>): JSX.Element {
  const onClickOnGivenLicenseActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      if (onClickActionCallback) {
        await actionDispatch.execCallback(onClickActionCallback, recognizedLicense);
      }
    },
    [onClickActionCallback, recognizedLicense],
    $
  );

  const strokeStyle = useMemo((): StrokeStyle => {
    const style = computeStyle(recognizedLicense);
    switch (style) {
      case 'selected':
        return {
          color: 'yellow',
          width: 4,
        };
      case 'unknown':
        return {
          color: 'red',
          width: 4,
        };
      case 'default':
      default:
        return {
          color: 'orange',
          width: 2,
        };
    }
  }, [computeStyle, recognizedLicense]);
  return (
    <rect
      {...box}
      stroke={strokeStyle.color}
      strokeWidth={strokeStyle.width}
      fill={strokeStyle.color}
      fillOpacity="0.3"
      onClick={onClickActionCallback ? onClickOnGivenLicenseActionCallback : undefined}
    />
  );
}

type Props<SD extends KanbanListWithSearchBaseStoreDef> = {
  readonly $: StoreStateSelector<SD, KanbanListWithSearchState>;
  readonly computeStyle: (license: string) => RecognizedLicenseSVGRectangleStyle;
  readonly onClickActionCallback?: ActionCallbackFromFunction<
    SD,
    (license: string) => Promise<void> | void
  >;
};

export function RecognizedLicensesSVGImage<SD extends KanbanListWithSearchBaseStoreDef>({
  $,
  computeStyle,
  onClickActionCallback,
}: Props<SD>): JSX.Element {
  const searchByImageState = useGetState($.$searchByImage);

  /**
   * In order to optimize the space that is used in the UI, we crop the image
   * by letting 1/6 of the height below the recognized licenses and 1/4 oh
   * the image height above.
   *
   * That way, we increase the chance to get the whole cars in the picture :
   * usually, the main part of the car is above the license plate.
   *   _________
   *  /         \__   1/4 of the car above the plate
   * |_   ____   __\
   *   \_/    \_/     1/6 of the car below the plate
   */
  const [viewPortY, viewPortHeight] = useMemo(() => {
    if (!searchByImageState) {
      return [0, 0, 0, 0];
    }
    let minY = searchByImageState.imageHeight;
    let maxY = 0;
    keysOf(searchByImageState.licenses).forEach((license) => {
      const { y, height } = searchByImageState.licenses[license];
      minY = Math.min(minY, y);
      maxY = Math.max(maxY, y + height);
    });
    const viewBoxLowerY = Math.max(0, minY - searchByImageState.imageHeight / 4);
    const viewBoxUpperY = Math.min(
      searchByImageState.imageHeight,
      maxY + searchByImageState.imageHeight / 6
    );
    return [viewBoxLowerY, viewBoxUpperY - viewBoxLowerY];
  }, [searchByImageState]);

  return (
    <>
      {searchByImageState && (
        <svg viewBox={`0 ${viewPortY} ${searchByImageState.imageWidth} ${viewPortHeight}`}>
          <image href={searchByImageState.imageBlobUrl} x="0" y="0" />
          {keysOf(searchByImageState.licenses).map((recognizedLicense) => {
            const position = searchByImageState.licenses[recognizedLicense];
            return (
              /* Draw a clickable rectangle around the license */
              <RecognizedLicenseSVGRectangle
                key={recognizedLicense}
                {...position}
                $={$}
                computeStyle={computeStyle}
                license={recognizedLicense}
                onClickActionCallback={onClickActionCallback}
              />
            );
          })}
        </svg>
      )}
    </>
  );
}
