import type { TFunction } from 'i18next';
import type { BrowserRegistrationStatus } from '@stimcar/core-libs-repository';
import type { ActionContext } from '@stimcar/libs-uikernel';
import { Logger } from '@stimcar/libs-kernel';
import { EMPTY_LOGIN_MODAL_DIALOG_STATE } from '@stimcar/libs-uitoolkit';
import type { RegisterViewState } from './components/authentication/typings/store.js';
import type { RegisteredAppStore, RegisteredAppStoreState } from './state/typings/store.js';
import { EMPTY_REGISTER_VIEW_STATE } from './components/authentication/typings/store.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export function updateOnlineStatusAction<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>({ getState, actionDispatch }: ActionContext<SD, RegisteredAppStoreState>, online: boolean) {
  if (online !== getState().session.isOnline) {
    log.info('Network becomes', online ? 'online' : 'offline');
    actionDispatch.scopeProperty('session').setProperty('isOnline', online);
  }
}

export function userDisconnectedListenerAction<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>({ getState, actionDispatch, getGlobalState }: ActionContext<SD, RegisteredAppStoreState>) {
  if (getGlobalState().session.user && !getState().loginView.loginModalActive) {
    actionDispatch.setProperty('loginView', {
      ...EMPTY_LOGIN_MODAL_DIALOG_STATE,
      loginModalActive: true,
      fixedLogin: true,
      formData: {
        // Predefined username
        login: getGlobalState().session.user?.login ?? '',
        password: '',
        warnings: {},
      },
    });
  }
  // Remove connected user
  actionDispatch.scopeProperty('session').setProperty('user', undefined);
}

export async function registerBrowserRegistrationListenerAction<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>(
  { httpClient, actionDispatch }: ActionContext<SD, RegisteredAppStoreState>,
  becomes: BrowserRegistrationStatus,
  t: TFunction
): Promise<void> {
  if (becomes === 'unregistered') {
    await httpClient.logout();
    actionDispatch.reduce((initial) => {
      // Populate registration UI state because the user will be redirected to
      // the registration UI
      const { infos } = initial.session;
      let register: RegisterViewState | undefined;
      if (infos) {
        register = {
          ...initial.registerView,
          formData: {
            ...initial.registerView.formData,
            siteId: infos.site.id,
            role: infos.role,
            standId: !infos.standId ? '' : infos.standId,
            label: infos.label,
            forceLabel: false,
          },
        };
      }
      const disconnectMessage = t('refitit:disconnectMessage');
      return {
        ...initial,
        session: {
          ...initial.session,
          isRegistered: false,
          infos: undefined,
        },
        registerView: !register ? EMPTY_REGISTER_VIEW_STATE : register,
        message: disconnectMessage,
      };
    });
  }
}
