import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  CarElement,
  CoreFields,
  Kanban,
  PackageDeal,
  PackageDealDesc,
} from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  kanbanHelpers,
  packageDealHelpers,
  SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID,
} from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Tabs } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { OperatorViewState } from '../../typings/store.js';
import { KanbanMessagesBoxComponent } from '../../../../lib/bulmalegacy/components/KanbanMessageBoxComponent.js';
import { PackageDealDetailsComponent } from './PackageDealDetailsComponent.js';

async function submitKanbanMessageModification({
  getState,
  getGlobalState,
  kanbanRepository,
}: ActionContext<Store, OperatorViewState>): Promise<void> {
  const { operatedKanban, expertOperatorState } = getState();
  const { login } = nonnull(getGlobalState().session.user);

  if (!isTruthy(operatedKanban)) {
    return;
  }

  const newKanban: Kanban = {
    ...operatedKanban,
    messages: [
      ...operatedKanban.messages,
      kanbanHelpers.createKanbanComment(
        kanbanRepository.createId(),
        login,
        expertOperatorState.detailsAndMessagesComponentState.kanbanMessageInput
      ),
    ],
  };

  await kanbanRepository.updateEntity(newKanban);
}

interface Props extends AppProps<Store> {
  readonly entity: PackageDeal | PackageDealDesc | undefined;
  readonly carElement: CoreFields<CarElement> | undefined;
}

export function ExpertPackageDealDescDetailsAndMessagesPanelComponent({
  entity,
  carElement,
  $gs,
}: Props): JSX.Element {
  const [t] = useTranslation('operators');
  const { $operatorView } = $gs;
  const { $expertOperatorState } = $operatorView;
  const { $detailsAndMessagesComponentState } = $expertOperatorState;

  const operatedKanban = useGetState($operatorView.$operatedKanban);

  const packageDeals = useGetState(
    $expertOperatorState.$packageDealListComponentState.$packageDeals
  );

  const globalVariableValues = useMemo(() => {
    const tags = entity?.tags ?? [];
    return packageDealHelpers.computeGlobalVariableValuesForGivenTags(
      {
        ...nonnull(operatedKanban),
        packageDeals,
      },
      tags,
      true
    );
  }, [entity?.tags, operatedKanban, packageDeals]);

  const displayOperationDetailsOrPlaceholder = (): JSX.Element => {
    if (entity) {
      return (
        <PackageDealDetailsComponent
          kanbanId={nonnull(operatedKanban).id}
          $attachmentsState={$detailsAndMessagesComponentState}
          globalVariablesValues={globalVariableValues}
          entity={entity}
          carElement={carElement}
          roundPriceTo={nonnull(operatedKanban).contract.configuration.roundPriceTo}
          $gs={$gs}
        />
      );
    }
    return (
      <div className="has-text-centered">
        {t('expertiseView.packageDealDetails.noPackageDealSelected')}
      </div>
    );
  };

  const submitKanbanMessageModificationCallback = useActionCallback(
    submitKanbanMessageModification,
    [],
    $operatorView
  );

  const selectPackageDealOrMessagesPanelTab = useGetState(
    $detailsAndMessagesComponentState.$selectPackageDealOrMessagesPanelTab
  );

  const displaySelectedTab = (): JSX.Element => {
    switch (selectPackageDealOrMessagesPanelTab) {
      case 'packageDeal':
        return displayOperationDetailsOrPlaceholder();
      case 'messages':
        return (
          <KanbanMessagesBoxComponent
            kanban={nonnull(operatedKanban)}
            onSubmitButtonClicked={submitKanbanMessageModificationCallback}
            $={$detailsAndMessagesComponentState.$kanbanMessageInput}
          />
        );
      default:
        return <>{t('expertiseView.tabError')}</>;
    }
  };

  const tabDefs = useMemo(() => {
    const hasPendingRequestMessage =
      (operatedKanban?.messages ?? []).filter((m) => m.type === 'request' && m.status === 'pending')
        .length > 0;
    return {
      packageDeal: t('expertiseView.details'),
      messages: {
        label: t('expertiseView.kanbanMessages', {
          value: nonnull(operatedKanban).messages.length,
        }),
        icon: hasPendingRequestMessage
          ? {
              id: SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID,
              colorClass: 'has-text-warning',
            }
          : undefined,
      },
    };
  }, [operatedKanban, t]);

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/anchor-is-valid */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <>
      <Tabs
        labels={tabDefs}
        $selectedTab={$detailsAndMessagesComponentState.$selectPackageDealOrMessagesPanelTab}
        isCentered
        className="narrow-bottom-margin"
      />
      <div>{displaySelectedTab()}</div>
    </>
  );
}
