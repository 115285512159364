import React from 'react';
import { useTranslation } from 'react-i18next';
import type { AnyStoreDef, NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useGetState } from '@stimcar/libs-uikernel';
import { FormButtons } from '../../bulma/index.js';
import { useFormWithValidation } from '../../hooks/index.js';
import type { LoginViewState } from './typings/store.js';
import { LOGIN_MANDATORY_FIELDS, LoginFields } from './LoginFields.js';

interface Props<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, LoginViewState>;
  readonly loginActionCallback: NoArgActionCallback<SD>;
}

export function LoginComponent<SD extends AnyStoreDef>({
  $,
  loginActionCallback,
}: Props<SD>): JSX.Element {
  const [t] = useTranslation('custom');

  const [onLoginFormSubmitCallback, , $formDataWithChangeTrigger] = useFormWithValidation({
    $,
    mandatoryFields: LOGIN_MANDATORY_FIELDS,
    checkFieldContentActions: undefined, // No field content check
    checkFormConsistencyAction: undefined, // No specific form consistency check
    submitValidDataAction: loginActionCallback,
    t,
  });

  return (
    <>
      <LoginFields
        loginIsFixed={false}
        $login={$formDataWithChangeTrigger.$login}
        $password={$formDataWithChangeTrigger.$password}
        onEnterKeyPressHandler={onLoginFormSubmitCallback}
      />
      <FormButtons
        onSubmitButtonClicked={onLoginFormSubmitCallback}
        submitLabel={t('login.connect')}
        formWarning={useGetState($.$formWarning)}
        neverDisableSubmitButton
      />
    </>
  );
}
