import type { KanbanSummary } from '@stimcar/libs-base';
import type {
  ComponentWithMountTracking,
  KanbanListWithSearchState,
} from '@stimcar/libs-uitoolkit';
import type { KanbanDetailsState } from '../../details/typings/store.js';
import { EMPTY_KANBAN_DETAILS_STATE } from '../../details/typings/store.js';

export type ArchivesViewState = KanbanListWithSearchState &
  ComponentWithMountTracking & {
    readonly filteredKanbans: readonly KanbanSummary[];
    readonly totalFound: number;
    readonly pageCount: number;
    readonly activePage: number;
    readonly searchShouldBeRerun: boolean;
    readonly detailsState: KanbanDetailsState;
  };

export const ARCHIVES_VIEW_EMPTY_STATE: ArchivesViewState = {
  componentIsMounted: false,
  filteredKanbans: [],
  totalFound: 0,
  activePage: 0,
  pageCount: 0,
  searchText: '',
  searchShouldBeRerun: false,
  detailsState: EMPTY_KANBAN_DETAILS_STATE,
};
