import React from 'react';
import type { ObjectStateType, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useStateIsDefined } from '@stimcar/libs-uikernel';
import { ModalMessageDialogWithoutDispatch } from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import type { MobileDetailsSubPartState } from './typings/store.js';

interface MobileDetailsModalMessageDialogProps<T extends ObjectStateType> {
  readonly $: StoreStateSelector<Store, MobileDetailsSubPartState<T>>;
  readonly children: JSX.Element;
}

export function MobileDetailsModalMessageDialog<T extends ObjectStateType>({
  $,
  children,
}: MobileDetailsModalMessageDialogProps<T>): JSX.Element {
  const isActive = useStateIsDefined($.$showDetailsFor);
  const closeModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.setProperty('showDetailsFor', undefined);
    },
    [],
    $
  );
  return (
    <ModalMessageDialogWithoutDispatch isActive={isActive} closeHandler={closeModalActionCallback}>
      {children}
    </ModalMessageDialogWithoutDispatch>
  );
}
