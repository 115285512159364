import type { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { OperationDesc, PackageDealVariableDefinition } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { ActionContext, NoArgActionCallback } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
  CheckFormFieldInput,
  HorizontalFormFieldProps,
} from '@stimcar/libs-uitoolkit';
import {
  enumerate,
  forEachRecordValues,
  nonDeleted,
  PACKAGE_DEAL_DESC_TEMPLATE_LABEL_SUFFIX_AND_PREFIX,
  packageDealDescHelpers,
} from '@stimcar/libs-base';
import { applyPayload, computePayload } from '@stimcar/libs-kernel';
import { useActionCallback, useFormFieldWarning, useGetState } from '@stimcar/libs-uikernel';
import {
  FormField,
  Input,
  ModalCardDialog,
  SelectFormField,
  TextArea,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type {
  EditOperationDescModalState,
  EditPackageDealDescModalState,
  OperationDescForm,
  OperationDescFormData,
} from '../typings/store.js';
import { validateExpression } from '../adminPackageDealDescUtils.js';
import {
  EDIT_OPERATION_DESC_MODAL_EMPTY_STATE,
  EMPTY_OPERATION_DESC_FORM,
} from '../typings/store.js';

export function convertOperationDescToFormData(operation: OperationDesc): OperationDescForm {
  const { label, standId, workloadExpression } = operation;
  return {
    label,
    standId,
    workloadExpression:
      packageDealDescHelpers.convertPackageDealRelatedExpressionToDisplayedString(
        workloadExpression
      ),
    warnings: {},
  };
}

function computeOperationDescFormDataPayload(
  initialOperationDesc: OperationDesc | undefined,
  operationDesc: OperationDescForm
): undefined | DeepPartial<OperationDescForm> {
  return initialOperationDesc === undefined
    ? operationDesc
    : computePayload(convertOperationDescToFormData(initialOperationDesc), operationDesc);
}

export function operationDescUpdateNotificationInEditDialogAction(
  { getState, actionDispatch }: ActionContext<Store, EditOperationDescModalState>,
  updated: OperationDesc
): void {
  const { active, initialOperationDesc, formData } = getState();
  if (active && initialOperationDesc && initialOperationDesc.id === updated.id) {
    actionDispatch.setProperty('initialOperationDesc', updated);
    const updatedToFormData = convertOperationDescToFormData(updated);
    const payload = computeOperationDescFormDataPayload(initialOperationDesc, formData);
    const newFormData = payload ? applyPayload(updatedToFormData, payload) : updatedToFormData;
    actionDispatch.setProperty('formData', newFormData);
  }
}

export function openOperationDescModalAction(
  { actionDispatch }: ActionContext<Store, EditPackageDealDescModalState>,
  operationDesc?: OperationDesc
): void {
  actionDispatch.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial: EditPackageDealDescModalState): EditPackageDealDescModalState => {
      return {
        ...initial,
        editOperationDescModalState: {
          ...EDIT_OPERATION_DESC_MODAL_EMPTY_STATE,
          initialOperationDesc: operationDesc,
          formData: operationDesc
            ? convertOperationDescToFormData(operationDesc)
            : EMPTY_OPERATION_DESC_FORM,
          active: true,
        },
      };
    }
  );
}

// The return type is specified on the returned function and will be to complexe to write here
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function isUniqueOperationDescCreator(operationDescs: readonly OperationDesc[], t: TFunction) {
  const activeOperationDescs = operationDescs.filter(nonDeleted);
  return function isUniqueOperationDesc<K extends keyof OperationDescFormData>(
    key: 'label'
  ): (ctx: CheckFormFieldInput<Store, EditOperationDescModalState, K>) => string | undefined {
    return ({
      formState,
    }: CheckFormFieldInput<Store, EditOperationDescModalState, K>): string | undefined => {
      const { initialOperationDesc, formData } = formState;
      let isUnique = true;
      for (const od of activeOperationDescs) {
        if (formData.label === od.label) {
          if (initialOperationDesc === undefined || initialOperationDesc.id !== od.id) {
            isUnique = false;
          }
        }
      }
      return !isUnique ? t(`operationDescModal.form.warnings.${key}AlreadyUsed`) : undefined;
    };
  };
}

function checkFieldContentActionsCreator(
  operationDescs: readonly OperationDesc[],
  t: TFunction,
  localVariables: Record<string, PackageDealVariableDefinition | null>,
  tags: readonly string[]
): CheckFormFieldContentActions<Store, EditOperationDescModalState> {
  const isUniqueOperationDesc = isUniqueOperationDescCreator(operationDescs, t);
  const checkFieldContentActions: CheckFormFieldContentActions<Store, EditOperationDescModalState> =
    {
      label: isUniqueOperationDesc('label'),
      workloadExpression: ({ value }) => {
        return validateExpression(t, value, localVariables, tags);
      },
    };
  return checkFieldContentActions;
}

function saveOperationDescAction({
  getState,
  actionDispatch,
  httpClient,
}: ActionContext<Store, EditPackageDealDescModalState>) {
  const { operationDescs } = getState().formData;
  const { initialOperationDesc, formData } = getState().editOperationDescModalState;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { workloadExpression, warnings, label, standId } = formData;
  const maxIndex = operationDescs.filter(nonDeleted).length;

  // If we find a deleted OD with the same name, reuse it
  const deletedOD = operationDescs.find((od) => od.label === label && od.deleted);
  let id: string;
  if (initialOperationDesc) {
    id = initialOperationDesc.id;
  } else if (deletedOD) {
    id = deletedOD.id;
  } else {
    id = httpClient.getBrowserSequence().next();
  }

  const newOperationDesc: OperationDesc = {
    label,
    standId,
    workloadExpression:
      packageDealDescHelpers.convertPackageDealRelatedExpressionToStoredString(workloadExpression),
    orderIndex: initialOperationDesc ? initialOperationDesc.orderIndex : maxIndex,
    id,
    attributes: initialOperationDesc ? initialOperationDesc.attributes : {},
  };

  let updated = false;
  const newOperationDescs = operationDescs.map((od): OperationDesc => {
    if (od.id === newOperationDesc.id) {
      updated = true;
      return newOperationDesc;
    }
    return od;
  });
  if (!updated) {
    newOperationDescs.push(newOperationDesc);
  }

  actionDispatch.reduce((initial) => {
    return {
      ...initial,
      formData: {
        ...initial.formData,
        operationDescs: newOperationDescs,
      },
      editOperationDescModalState: {
        ...initial.editOperationDescModalState,
        active: false,
      },
    };
  });
}

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  EditOperationDescModalState
> = ({ formState, t }): string | undefined => {
  const { initialOperationDesc, formData } = formState;
  if (initialOperationDesc) {
    const payload = computeOperationDescFormDataPayload(initialOperationDesc, formData);
    const updated = payload !== undefined && Reflect.ownKeys(payload).length !== 0;
    if (!updated) {
      return t('globals:warnings.noChange');
    }
  }
  return undefined;
};

const mandatoryFields: (keyof OperationDescFormData)[] = ['label', 'standId', 'workloadExpression'];

interface Props extends AppProps<Store> {
  readonly runValidationGlobalCallback: NoArgActionCallback<Store>;
  readonly localVariables: Record<string, PackageDealVariableDefinition | null>;
  readonly tags: readonly string[];
}

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  bodyFlexGrow: 5,
  labelFlexGrow: 2,
};

export function EditOperationDescModal({
  $gs,
  runValidationGlobalCallback,
  localVariables,
  tags,
}: Props): JSX.Element {
  const [t] = useTranslation(['packageDealDescs', 'globals']);
  const { $editPackageDealDescModal } = $gs.$adminView.$adminPackageDealDescs;
  const $ = $editPackageDealDescModal.$editOperationDescModalState;

  const stands = useGetState($gs.$siteConfiguration.$stands);

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(saveOperationDescAction);
      await actionDispatch.execCallback(runValidationGlobalCallback);
    },
    [runValidationGlobalCallback],
    $editPackageDealDescModal
  );

  const operationDescs = useGetState($editPackageDealDescModal.$formData.$operationDescs);

  const checkFieldContentActions = useMemo(
    () => checkFieldContentActionsCreator(operationDescs, t, localVariables, tags),
    [operationDescs, t, localVariables, tags]
  );

  const initialOperationDesc = useGetState($.$initialOperationDesc);

  const isCreateMode = useMemo(
    (): boolean => initialOperationDesc === undefined,
    [initialOperationDesc]
  );

  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation<
    Store,
    EditOperationDescModalState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions,
    checkFormConsistencyAction,
    submitValidDataAction,
    t,
  });

  const standIds = useMemo((): string[] => stands.map(({ id }): string => id), [stands]);

  const availableInternalVariableNames = useMemo(() => {
    const variableNames: string[] = [];
    forEachRecordValues(localVariables, (value, key) => {
      if (value !== null) {
        variableNames.push(key);
      }
    });
    return variableNames;
  }, [localVariables]);

  const availableVariableNames = useMemo(() => {
    const allVariableNames = packageDealDescHelpers
      .getGlobalPackageDealDescVariables(tags)
      .map((v) => v.label);
    forEachRecordValues(localVariables, (v, k) => allVariableNames.push(k));
    return allVariableNames;
  }, [localVariables, tags]);

  const formWarning = useGetState($.$formWarning);

  return (
    <ModalCardDialog
      title={isCreateMode ? t('operationDescModal.titleNew') : t('operationDescModal.titleUpdate')}
      $active={$.$active}
      okLabel={
        isCreateMode
          ? t('operationDescModal.form.newButton')
          : t('operationDescModal.form.updateButton')
      }
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <FormField
        label={t('operationDescModal.form.label')}
        horizontal={HORIZONTAL_PROPS}
        warning={useFormFieldWarning($formWithChangeTrigger.$label)}
      >
        <Input $={$formWithChangeTrigger.$label} placeholder={t('operationDescModal.form.label')} />
        {availableInternalVariableNames.length > 0 && (
          <p className="help">
            {t('operationDescModal.form.availableVariables', {
              value: enumerate(
                availableInternalVariableNames,
                ', ',
                PACKAGE_DEAL_DESC_TEMPLATE_LABEL_SUFFIX_AND_PREFIX
              ),
            })}
          </p>
        )}
      </FormField>
      <SelectFormField
        label={t('operationDescModal.form.stand')}
        entries={standIds}
        $={$formWithChangeTrigger.$standId}
        isEmptyValueAllowed
        isFullWidth
        horizontal={HORIZONTAL_PROPS}
      />
      <FormField
        label={t('operationDescModal.form.durationExpression')}
        warning={useFormFieldWarning($formWithChangeTrigger.$workloadExpression)}
        horizontal={HORIZONTAL_PROPS}
      >
        <TextArea
          $={$formWithChangeTrigger.$workloadExpression}
          placeholder={t('operationDescModal.form.durationExpressionPlaceholder')}
        />
        <p className="help">
          {t('packageDealDescModal.form.availableVariables', {
            value: enumerate(availableVariableNames),
          })}
        </p>
      </FormField>
    </ModalCardDialog>
  );
}
