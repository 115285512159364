import type { CoreFields, Kanban } from '@stimcar/libs-base';
import { compareNumbers, kanbanHelpers, KanbanPriorityLevel } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';

export type KanbanWithHandlingsComparisonFunction = (
  a: CoreFields<Kanban> | undefined,
  b: CoreFields<Kanban> | undefined
) => number;

function getPriorityIndex(level: KanbanPriorityLevel | undefined): number {
  switch (level) {
    case KanbanPriorityLevel.dueDateIsPast:
      return 0;
    case KanbanPriorityLevel.dueDateIsWithinThreshold:
      return 1;
    default:
      return 2;
  }
}

function getKanbanPriorityLevel(
  kanban: CoreFields<Kanban>,
  dueDateThreshold: number
): KanbanPriorityLevel | undefined {
  return kanbanHelpers.getKanbanPriorityLevelForKanbansDueDate(
    kanban.dueDate,
    kanban.refitEndDate,
    dueDateThreshold
  );
}

export function getKanbansOnWaitingLineComparisonFunction(
  standId: string,
  qualifiedPostId: string,
  dueDateThreshold: number
): KanbanWithHandlingsComparisonFunction {
  return function sort(
    k1: CoreFields<Kanban> | undefined,
    k2: CoreFields<Kanban> | undefined
  ): number {
    if (!isTruthy(k1) && !isTruthy(k2)) {
      return 0;
    }
    if (isTruthy(k1) && !isTruthy(k2)) {
      return -1;
    }
    if (!isTruthy(k1) && isTruthy(k2)) {
      return 1;
    }

    // First, let's sort "red" and "yellow" kanbans as first
    const priority1 = getPriorityIndex(getKanbanPriorityLevel(nonnull(k1), dueDateThreshold));
    const priority2 = getPriorityIndex(getKanbanPriorityLevel(nonnull(k2), dueDateThreshold));
    if (priority1 !== priority2) {
      return priority1 - priority2;
    }

    // When priority is equivalent, let's sort using time on waiting line
    const { handlings: handlings1 } = nonnull(k1);
    const standHandlings1 = handlings1.filter(
      (h) => standId === h.standId && qualifiedPostId === h.postId && !h.endDate
    );
    const startDate1 = standHandlings1.map((h): number => h.startDate).sort()[0];

    const { handlings: handlings2 } = nonnull(k2);
    const standHandlings2 = handlings2.filter(
      (h) => standId === h.standId && qualifiedPostId === h.postId && !h.endDate
    );
    const startDate2 = standHandlings2.map((h): number => h.startDate).sort()[0];

    return compareNumbers(startDate1, startDate2, 'DOWN');
  };
}
