import type { Node } from 'acorn';
import { Parser } from 'acorn';
import { recursive as recursiveAcornWalk, simple as simpleAcornWalk } from 'acorn-walk';
import type { Labelled } from '@stimcar/libs-kernel';
import { isTruthy, isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import type {
  CarElement,
  CarViewCategory,
  PddOrODExpressionType,
} from '../../model/typings/configuration.js';
import type { Kanban } from '../../model/typings/kanban.js';
import type {
  PackageDealBooleanVariableBaseType,
  PackageDealCategory,
  PackageDealDesc,
  PackageDealNumericVariableBaseType,
  PackageDealTextualVariableBaseType,
  PackageDealVariable,
  PackageDealVariableDefinition,
} from '../../model/typings/packageDealDesc.js';
import type { CoreFields } from '../../model/typings/repository.js';
import {
  CONTRACT_GLOBAL_VARIABLE_NAME,
  CUSTOMER_GLOBAL_VARIABLE_NAME,
  HOURLY_RATE_GLOBAL_VARIABLE_NAME,
  MILEAGE_GLOBAL_VARIABLE_NAME,
  TAG_GLOBAL_VARIABLE_NAME_PREFIX,
} from '../../model/globalConstants.js';
import { enumerate, forEachRecordValues, nonDeleted } from '../misc.js';
import { toUpperFirst } from '../stringHelpers.js';

export const PACKAGE_DEAL_DESC_TEMPLATE_LABEL_SUFFIX_AND_PREFIX = {
  prefix: '{',
  suffix: '}',
  undefinedValue: '__',
};

export type PackageDealDescIssueCode =
  | 'unknownPackageDealDesc'
  | 'hasMultipleCarElements'
  | 'hasMultipleValuedVariablesWithoutDefault';

export type PackageDealDescValidationResult = {
  readonly code: string;
  readonly isValid: boolean;
  readonly issue?: PackageDealDescIssueCode;
};

function hasPackageDealAnyCarElementIncludedInCategory(
  pdd: PackageDealDesc,
  carElements: readonly CarElement[],
  category: CarViewCategory
): boolean {
  for (const id of pdd.carElementIds) {
    const carElement = carElements.find((ce) => ce.id === id);
    if (carElement?.category === category) {
      return true;
    }
  }
  return false;
}

function findCarViewCategoriesFromCarElements(
  pdd: PackageDealDesc,
  carElements: readonly CarElement[]
): readonly CarViewCategory[] {
  const set = new Set<CarViewCategory>();
  pdd.carElementIds.forEach((id) => {
    const carElement = carElements.find((ce) => ce.id === id);
    if (carElement) {
      set.add(carElement.category);
    }
  });
  return Array.from(set);
}

type GlobalVariable = {
  label: string;
  computation: (kanban: CoreFields<Kanban>) => number | string;
};

function getGlobalPackageDealDescVariables(tags: readonly string[]): readonly GlobalVariable[] {
  const globalVariables: GlobalVariable[] = [
    {
      label: MILEAGE_GLOBAL_VARIABLE_NAME,
      computation: (kanban: CoreFields<Kanban>): number => kanban.infos.mileage,
    },
    {
      label: CONTRACT_GLOBAL_VARIABLE_NAME,
      computation: (kanban: CoreFields<Kanban>): string => kanban.contract.code,
    },
    {
      label: CUSTOMER_GLOBAL_VARIABLE_NAME,
      computation: (kanban: CoreFields<Kanban>): string => kanban.customer.shortName,
    },
    {
      label: HOURLY_RATE_GLOBAL_VARIABLE_NAME,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      computation: (kanban: CoreFields<Kanban>): number => kanban.contract.configuration.hourlyRate,
    },
  ];

  tags.forEach((t) => {
    if (isTruthyAndNotEmpty(t)) {
      globalVariables.push({
        label: TAG_GLOBAL_VARIABLE_NAME_PREFIX + toUpperFirst(t),
        computation: (kanban: CoreFields<Kanban>): number => {
          let count = 0;
          kanban.packageDeals.filter(nonDeleted).forEach((pd) => {
            if (pd.tags.includes(t) && pd.status === 'available') {
              count += 1;
            }
          });

          return count;
        },
      });
    }
  });

  return globalVariables;
}

function parseExpressionString(expression: string): Node {
  return Parser.parse(expression, {
    ecmaVersion: 6,
    sourceType: 'script',
    allowReturnOutsideFunction: true,
  });
}

function getAllVariablesInExpression(expression: string): readonly string[] {
  const variables: Set<string> = new Set();
  const tree = parseExpressionString(expression);
  simpleAcornWalk(tree, {
    Identifier(node) {
      // @ts-ignore If the node is an Identifier, it as a name property, but acorn types don't handle this
      variables.add(nonnull(node.name));
    },
  });

  return [...variables];
}

function sanitizeStringBeforeJSONConversion(value: string): string {
  let sanitizedValue = value;
  let startWithIncorrectCharacter = true;
  let endWithIncorrectCharacter = true;
  while (startWithIncorrectCharacter || endWithIncorrectCharacter) {
    sanitizedValue = sanitizedValue.trim();
    if (sanitizedValue.startsWith(',')) {
      sanitizedValue = sanitizedValue.substring(1);
    } else {
      startWithIncorrectCharacter = false;
    }
    if (sanitizedValue.endsWith(',')) {
      sanitizedValue = sanitizedValue.substring(0, sanitizedValue.length - 1);
    } else {
      endWithIncorrectCharacter = false;
    }
  }

  return sanitizedValue.trim();
}

function convertPackageDealRelatedExpressionStringToType(
  declaration: string
): PddOrODExpressionType {
  try {
    return JSON.parse(`{${sanitizeStringBeforeJSONConversion(declaration)}}`);
  } catch {
    return JSON.parse(`{"*": "${declaration}"}`);
  }
}

function doConvertPackageDealRelatedExpressionToString(
  declaration: string,
  withIndentation: boolean
): string {
  try {
    const declarationObject = JSON.parse(`{${declaration}}`);
    if (withIndentation) {
      const elements = JSON.stringify(declarationObject, null, 2).split('\n');
      elements.shift();
      elements.pop();
      return elements.map((v): string => v.substring(2).trim()).join('\n');
    }
    const text = JSON.stringify(declarationObject);
    return text.substring(1, text.length - 1);
  } catch {
    return `${declaration}`.trim();
  }
}

function convertPackageDealRelatedExpressionToDisplayedString(declaration: string): string {
  return doConvertPackageDealRelatedExpressionToString(declaration, true);
}

function convertPackageDealRelatedExpressionToStoredString(declaration: string): string {
  return doConvertPackageDealRelatedExpressionToString(declaration, false);
}

function internalIsInteractiveExpression(expression: PddOrODExpressionType): boolean {
  let isInteractive = false;
  for (const key of keysOf(expression)) {
    if (key !== '*') {
      isInteractive = getAllVariablesInExpression(key as string).length > 0;
      if (isInteractive) {
        return isInteractive;
      }
    }
    const value = expression[key];
    if (typeof value === 'string') {
      isInteractive = getAllVariablesInExpression(value).length > 0;
    } else {
      isInteractive = internalIsInteractiveExpression(value);
    }
    if (isInteractive) {
      return isInteractive;
    }
  }
  return isInteractive;
}

function isInteractiveExpression(expression: string): boolean {
  const expressionObject = convertPackageDealRelatedExpressionStringToType(expression);
  return internalIsInteractiveExpression(expressionObject);
}

function getVariableDescDeclarationJSONCompatibleString(declaration: string): string {
  return `{${sanitizeStringBeforeJSONConversion(declaration)}}`;
}

/**
 * Convert the given string to a Record<string, VariableDefinition>.
 * Can throw an error if called with an incorrect declaration. It should be called after validating the delcaration
 * with validatePackageDealDescVariableDeclarations
 *
 * @param declaration
 */
function convertVariableDescStringToType(
  declaration: string
): Record<string, PackageDealVariableDefinition> {
  const variablesObjectString = getVariableDescDeclarationJSONCompatibleString(declaration);
  return JSON.parse(variablesObjectString);
}

/**
 * Convert the given string to a Record<string, VariableDefinition>.
 * Can throw an error if called with an incorrect declaration. It should be called after validating the delcaration
 * with validatePackageDealDescVariableDeclarations
 *
 * @param declaration
 */
function convertVariableDescStringToTypeAndTestKeyDuplicate(
  declaration: string
): Record<string, PackageDealVariableDefinition> | 'duplicateIds' {
  const variablesObjectString = getVariableDescDeclarationJSONCompatibleString(declaration);
  const matchSpacesAndLineEndingRegExp = / |\t|\n|\r\n|\n\r|\r/gm;
  const variablesObject = convertVariableDescStringToType(declaration);
  // We have no other ways to detect duplicate keys in JSON unless adding new dependencies for streaming parser that allow
  // to test each key as we handle it
  if (
    JSON.stringify(variablesObject).replace(matchSpacesAndLineEndingRegExp, '') !==
    variablesObjectString.replace(matchSpacesAndLineEndingRegExp, '')
  ) {
    return 'duplicateIds';
  }
  return variablesObject;
}

/**
 * Convert the given variableDesc definition to the user displayed string. (Which is basically the stringified version of
 * the declaration without enclosing {})
 *
 * @param definition
 */
function convertVariableDescTypeToDisplayedString(
  definition: Record<string, PackageDealVariableDefinition | null>
): string {
  let variableDescsString = '';
  const definitionNumber = keysOf(definition).length;
  forEachRecordValues(definition, (def, key, index) => {
    if (!isTruthy(def)) {
      return;
    }
    variableDescsString += `"${key}": ${JSON.stringify(def, undefined, 2)}`;

    if (index < definitionNumber - 1) {
      variableDescsString += ',\n';
    }
  });
  return variableDescsString;
}

function variableIdIsJSCorrect(id: string): boolean {
  return id.replace(/[a-z]+[a-z, 0-9]*/gi, '').length === 0;
}

function hasCorrectVariableAvailableValues(
  availableValues: readonly (string | number)[],
  availableValuesType: 'number' | 'string'
): boolean {
  return availableValues.reduce((acc: boolean, v: unknown) => {
    // eslint-disable-next-line valid-typeof
    return acc && typeof v === availableValuesType;
  }, true);
}

type VariableValidationReturnCodes =
  | 'empty'
  | 'ok'
  | 'incorrectStructure'
  | 'incorrectSyntax'
  | 'incorrectId'
  | 'overrideGlobal'
  | 'incorrectValuesType'
  | 'incorrectDefaultValue'
  | 'duplicateIds';

function validatePackageDealDescVariableDeclarations(
  declaration: string,
  tags: readonly string[]
): VariableValidationReturnCodes {
  if (!isTruthyAndNotEmpty(declaration)) {
    return 'empty';
  }

  const globalVariableNames = getGlobalPackageDealDescVariables(tags).map((v): string => v.label);
  try {
    const variableDescs = convertVariableDescStringToTypeAndTestKeyDuplicate(declaration);
    if (variableDescs === 'duplicateIds') {
      return variableDescs;
    }

    const variableDescIds = keysOf(variableDescs);
    for (const key of variableDescIds) {
      if (!variableIdIsJSCorrect(key)) {
        return 'incorrectId';
      }
      const desc = variableDescs[key];

      let correctFieldsDependingOnType = false;
      let correctTypeForAvailableValues = false;
      switch (desc.type) {
        case 'numeric':
          if (isTruthy(desc.availableValues)) {
            correctFieldsDependingOnType = true;
            correctTypeForAvailableValues = hasCorrectVariableAvailableValues(
              desc.availableValues,
              'number'
            );
          }
          break;
        case 'text':
          if (isTruthy(desc.availableValues)) {
            correctFieldsDependingOnType = true;
            correctTypeForAvailableValues = hasCorrectVariableAvailableValues(
              desc.availableValues,
              'string'
            );
          }
          break;
        case 'boolean':
          // @ts-ignore We want to check that this field is not present
          correctFieldsDependingOnType = !isTruthy(desc.availableValues);
          correctTypeForAvailableValues = true;
          break;
        default:
          break;
      }

      if (!correctFieldsDependingOnType) {
        return 'incorrectStructure';
      }
      if (!correctTypeForAvailableValues) {
        return 'incorrectValuesType';
      }
      if (globalVariableNames.includes(key)) {
        return 'overrideGlobal';
      }

      if (isTruthy(desc.defaultValue)) {
        switch (desc.type) {
          case 'numeric':
            if (
              typeof desc.defaultValue !== 'number' ||
              !desc.availableValues.includes(desc.defaultValue)
            ) {
              return 'incorrectDefaultValue';
            }
            break;
          case 'text':
            if (
              typeof desc.defaultValue !== 'string' ||
              !desc.availableValues.includes(desc.defaultValue)
            ) {
              return 'incorrectDefaultValue';
            }
            break;
          case 'boolean':
            if (typeof desc.defaultValue !== 'boolean') {
              return 'incorrectDefaultValue';
            }
            break;
          default:
            break;
        }
      }
    }
  } catch {
    return 'incorrectSyntax';
  }
  return 'ok';
}

type ConditionSpecificReturnCodes =
  | 'empty'
  | 'ok'
  | 'unknownVariables'
  | 'incorrectCondition'
  | 'forbiddenStatement'
  | 'numberInputWithCommaSeparator';
type ExpressionReturnCodes =
  | 'empty'
  | 'ok'
  | 'unknownVariables'
  | 'forbiddenStatement'
  | 'missingDefaultCase'
  | 'incorrectExpression'
  | 'incorrectCondition'
  | 'numberInputWithCommaSeparator';

interface ValidationReturnValue {
  readonly code: ExpressionReturnCodes;
  readonly value?: string;
}

interface ConditionValidationReturnValue {
  readonly code: ConditionSpecificReturnCodes;
  readonly value?: string;
}

function internalGetNoUnknownVariables(
  expression: string,
  localVariables: Record<string, PackageDealVariableDefinition>,
  tags: readonly string[]
): readonly string[] {
  const allVariableNames = getGlobalPackageDealDescVariables(tags).map((v): string => v.label);
  forEachRecordValues(localVariables, (v, k) => allVariableNames.push(k));
  return getAllVariablesInExpression(expression).filter((v) => !allVariableNames.includes(v));
}

const arithmeticOperators = ['+', '-', '*', '/'];
const relationalAndLogicalOperators = ['&&', '||', '<', '>', '<=', '>=', '===', '=='];

function expressionTreeReturnsTheGivenType(
  tree: Node,
  variables: Record<string, PackageDealVariableDefinition>,
  type: 'numeric' | 'boolean'
): boolean {
  let returnsTheCorrectType = false;
  // Only look for the upper operand or the type of the unique variable or literal to infer the return type
  recursiveAcornWalk(
    tree,
    {},
    {
      // With recursive tree walker, each function is responsible to continue the recursion
      // with the third parameter. Here we want to infer the return type with the root expression, so stop the recursion
      BinaryExpression(node) {
        // @ts-ignore operator property exist on BinaryExpression nodes
        const { operator } = node;
        if (
          (type === 'numeric' && arithmeticOperators.includes(operator)) ||
          (type === 'boolean' && relationalAndLogicalOperators.includes(operator))
        ) {
          returnsTheCorrectType = true;
        }
      },
      LogicalExpression(node) {
        // @ts-ignore operator property exist on LogicalExpression nodes
        const { operator } = node;
        if (type === 'boolean' && relationalAndLogicalOperators.includes(operator)) {
          returnsTheCorrectType = true;
        }
      },
      Identifier(node) {
        // @ts-ignore name property exist on Identifier nodes
        const variable = variables[node.name];
        if (isTruthy(variable)) {
          returnsTheCorrectType = variable.type === type;
        }
      },
      Literal(node) {
        // @ts-ignore value property exist on Literal nodes
        const { value } = node;
        if (
          (typeof value === 'boolean' && type === 'boolean') ||
          (typeof value === 'number' && type === 'numeric')
        ) {
          returnsTheCorrectType = true;
        }
      },
    }
  );
  return returnsTheCorrectType;
}

function expressionTreeContainsForbiddenStatements(tree: Node): 'none' | 'if' | 'while' | 'for' {
  let containsForbiddenStatements: 'none' | 'if' | 'while' | 'for' = 'none';
  // Only look for the upper operand or the type of the unique variable or literal to infer the return type
  recursiveAcornWalk(
    tree,
    {},
    {
      // With recursive tree walker, each function is responsible to continue the recursion
      // with the third parameter. Here we want to prevent the usage of if, for and while structures in the expressions
      IfStatement() {
        containsForbiddenStatements = 'if';
      },
      WhileStatement() {
        containsForbiddenStatements = 'while';
      },
      ForStatement() {
        containsForbiddenStatements = 'for';
      },
      ForOfStatement() {
        containsForbiddenStatements = 'for';
      },
      ForInStatement() {
        containsForbiddenStatements = 'for';
      },
    }
  );
  return containsForbiddenStatements;
}

const PATTERN_NUMBER_WITH_COMMA = /([0-9]*),([0-9]*)/;
function containsNumberWithComma(expression: string): boolean {
  const match = expression.match(PATTERN_NUMBER_WITH_COMMA);
  if (isTruthy(match) && match.length === 3) {
    const intPart = match[1];
    const decPart = match[2];
    return isTruthyAndNotEmpty(intPart) || isTruthyAndNotEmpty(decPart);
  }
  return false;
}

function internalDoValidatePackageDealRelatedExpressionCondition(
  condition: string,
  localVariables: Record<string, PackageDealVariableDefinition>,
  tags: readonly string[]
): ConditionValidationReturnValue {
  if (isTruthyAndNotEmpty(condition)) {
    if (condition === '*') {
      return { code: 'ok' };
    }

    if (containsNumberWithComma(condition)) {
      return { code: 'numberInputWithCommaSeparator' };
    }

    const unknownVariables = internalGetNoUnknownVariables(condition, localVariables, tags);
    if (unknownVariables.length === 0) {
      const tree = parseExpressionString(condition);
      const forbiddenStatement = expressionTreeContainsForbiddenStatements(tree);
      if (forbiddenStatement !== 'none') {
        return { code: 'forbiddenStatement', value: forbiddenStatement };
      }
      if (expressionTreeReturnsTheGivenType(tree, localVariables, 'boolean')) {
        return { code: 'ok' };
      }
      return { code: 'incorrectCondition', value: condition };
    }
    return { code: 'unknownVariables', value: enumerate(unknownVariables) };
  }
  return { code: 'empty' };
}

function internalDoValidatePackageDealRelatedExpression(
  expression: PddOrODExpressionType | string,
  localVariables: Record<string, PackageDealVariableDefinition>,
  tags: readonly string[]
): ValidationReturnValue {
  if (typeof expression === 'string') {
    if (isTruthyAndNotEmpty(expression)) {
      if (containsNumberWithComma(expression)) {
        return { code: 'numberInputWithCommaSeparator' };
      }

      const unknownVariables = internalGetNoUnknownVariables(expression, localVariables, tags);
      if (unknownVariables.length === 0) {
        const tree = parseExpressionString(expression);
        const forbiddenStatement = expressionTreeContainsForbiddenStatements(tree);
        if (forbiddenStatement !== 'none') {
          return { code: 'forbiddenStatement', value: forbiddenStatement };
        }
        if (expressionTreeReturnsTheGivenType(tree, localVariables, 'numeric')) {
          return { code: 'ok' };
        }
        return { code: 'incorrectExpression', value: expression };
      }
      return { code: 'unknownVariables', value: enumerate(unknownVariables) };
    }
    return { code: 'empty' };
  }
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return doValidatePackageDealRelatedExpressionDeclaration(expression, localVariables, tags);
}

function doValidatePackageDealRelatedExpressionDeclaration(
  expression: PddOrODExpressionType,
  localVariables: Record<string, PackageDealVariableDefinition>,
  tags: readonly string[]
): ValidationReturnValue {
  for (const condition of keysOf(expression)) {
    const conditionResult = internalDoValidatePackageDealRelatedExpressionCondition(
      condition as string,
      localVariables,
      tags
    );
    if (conditionResult.code !== 'ok') {
      return conditionResult;
    }
    const desc = expression[condition];
    const result = internalDoValidatePackageDealRelatedExpression(desc, localVariables, tags);
    if (result.code !== 'ok') {
      if (result.code === 'empty') {
        return {
          ...result,
          value: String(condition),
        };
      }
      return result;
    }
  }
  return { code: 'ok' };
}

function validatePackageDealRelatedExpressionDeclaration(
  declaration: string,
  localVariables: Record<string, PackageDealVariableDefinition | null>,
  tags: readonly string[]
): ValidationReturnValue {
  const expression = convertPackageDealRelatedExpressionStringToType(declaration);
  if (keysOf(expression).length === 0) {
    return { code: 'empty' };
  }
  const conditions = keysOf(expression);
  if (!conditions.includes('*')) {
    return { code: 'missingDefaultCase' };
  }

  const nonNullLocalVariables: Record<string, PackageDealVariableDefinition> = {};
  forEachRecordValues(localVariables, (variableDesc, key) => {
    if (isTruthy(variableDesc)) {
      nonNullLocalVariables[key] = variableDesc;
    }
  });

  return doValidatePackageDealRelatedExpressionDeclaration(expression, nonNullLocalVariables, tags);
}

function getPackageDealDescLabelWithVariableValuePlaceholder(pdd: Labelled): string {
  const { prefix, suffix, undefinedValue } = PACKAGE_DEAL_DESC_TEMPLATE_LABEL_SUFFIX_AND_PREFIX;
  const regexp = new RegExp(`${prefix}[a-z0-9]*${suffix}`, 'gi');
  return pdd.label.replace(regexp, undefinedValue);
}

function isExpertiseCategory(category: PackageDealCategory) {
  return category === 'EXP';
}

function getExpertisePackageDealDescWithoutRaisingAnError(
  packageDealsDescs: readonly PackageDealDesc[]
): PackageDealDesc | 'NO_EXPERTISE_PACKAGE_DEAL' | 'MORE_THAN_ONE_EXPERTISE_PACKAGE_DEAL' {
  const expertisePackageDealDescs = packageDealsDescs.filter(({ category }) =>
    isExpertiseCategory(category)
  );
  switch (expertisePackageDealDescs.length) {
    case 0:
      return 'NO_EXPERTISE_PACKAGE_DEAL';
    case 1:
      return expertisePackageDealDescs[0];
    default:
      return 'MORE_THAN_ONE_EXPERTISE_PACKAGE_DEAL';
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isNumericVariable(a: any): a is PackageDealNumericVariableBaseType {
  return a.type === 'numeric';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTextualVariable(a: any): a is PackageDealTextualVariableBaseType {
  return a.type === 'text';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isBooleanVariable(a: any): a is PackageDealBooleanVariableBaseType {
  return a.type === 'boolean';
}

function getPackageDealVariablesFromVariablesDescs(
  variableDescs: PackageDealDesc['variableDescs']
): Record<string, PackageDealVariable> {
  const variables: Record<string, PackageDealVariable> = {};
  forEachRecordValues(variableDescs, (variableDesc, key) => {
    if (isTruthy(variableDesc)) {
      const { defaultValue, ...rest } = variableDesc;
      if (isBooleanVariable(variableDesc)) {
        const value = defaultValue !== null && defaultValue !== undefined ? defaultValue : false;
        if (typeof value !== 'boolean') {
          throw Error(
            `The type of the variable value is incompatible with the variable definition type ${variableDesc.type}`
          );
        }
        // @ts-ignore
        variables[key] = {
          ...rest,
          value,
        };
      } else {
        const value = isTruthy(defaultValue) ? defaultValue : variableDesc.availableValues[0];
        if (!isTruthy(value)) {
          throw Error('All PackageDealDescs variable must have a value');
        }
        const variableTypeError = `The type of the variable value is incompatible with the variable definition type ${variableDesc.type}`;
        switch (variableDesc.type) {
          case 'numeric':
            if (typeof value !== 'number') {
              throw Error(variableTypeError);
            }
            break;
          case 'text':
            if (typeof value !== 'string') {
              throw Error(variableTypeError);
            }
            break;
          default:
            throw Error(variableTypeError);
        }
        // @ts-ignore
        variables[key] = {
          ...rest,
          value,
        };
      }
    }
  });
  return variables;
}

function valuatePackageDealVariables(
  variables: Record<string, PackageDealVariable>,
  variableValues: Record<string, string | number | boolean>
): Record<string, PackageDealVariable> {
  return Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      { ...value, value: variableValues[key] } as PackageDealVariable,
    ])
  );
}

export const packageDealDescHelpers = {
  hasPackageDealAnyCarElementIncludedInCategory,
  findCarViewCategoriesFromCarElements,
  getGlobalPackageDealDescVariables,
  convertPackageDealRelatedExpressionToDisplayedString,
  validatePackageDealRelatedExpressionDeclaration,
  convertPackageDealRelatedExpressionToStoredString,
  convertPackageDealRelatedExpressionStringToType,
  validatePackageDealDescVariableDeclarations,
  convertVariableDescStringToType,
  convertVariableDescTypeToDisplayedString,
  isInteractiveExpression,
  getPackageDealDescLabelWithVariableValuePlaceholder,
  isExpertiseCategory,
  getExpertisePackageDealDescWithoutRaisingAnError,
  getPackageDealVariablesFromVariablesDescs,
  valuatePackageDealVariables,
};
