import i18next from 'i18next';
import { useMemo } from 'react';
import type { DashboardFilter, Kanban, WithProgress } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../state/typings/store.js';
import { DEFAULT_DASHBOARD_FILTER_ID } from '../../display/typings/store.js';

export const useGetDashboardFilters = (
  $gs: GlobalStoreStateSelector<Store>
): readonly DashboardFilter[] => {
  const boardFiltersFromConfig: readonly DashboardFilter[] | undefined = useGetState(
    $gs.$siteConfiguration.$displayConfiguration.$dashboardFilters
  );

  // Add a default filter option when at least one filter is defined in site configuration
  const boardFilters = useMemo(
    () =>
      isTruthy(boardFiltersFromConfig)
        ? [
            {
              id: DEFAULT_DASHBOARD_FILTER_ID,
              label: i18next.t('display:dashboardDisplay.filterAllLabel'),
              filterFunction: '',
            },
            ...boardFiltersFromConfig,
          ]
        : [],
    [boardFiltersFromConfig]
  );
  return boardFilters;
};

export type DashboardFilterFunction = (
  kanbans: readonly WithProgress<Kanban>[]
) => readonly WithProgress<Kanban>[];
