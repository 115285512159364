import type { TFunction } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { User } from '@stimcar/libs-base';
import type { ActionContext, ReadOnlyActionContext } from '@stimcar/libs-uikernel';
import type { AppProps, ColumnDesc } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  AvailablePermissionPaths,
  CoreBackendRoutes,
  enumerate,
  mergeArrayItems,
  SUPER_ADMIN_PROFILE_NAME,
} from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import {
  applyAndFilterTableItemsAction,
  Button,
  DisplayContentOrPlaceholder,
  Table,
  useGetDefaultTableToolbarConf,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import {
  EMPTY_EDIT_PASSWORD_DATA,
  EMPTY_EDIT_PASSWORD_DIALOG_STATE,
} from '../../../registeredapp/components/authentication/typings/store.js';
import { useHasModifyPermission } from '../../../registeredapp/permissionHooks.js';
import { UpdatePasswordModal } from '../../UpdatePasswordModal.js';
import type { AdminUIUser, AdminUsersState } from './typings/store.js';
import {
  EMPTY_CREATE_USER_DIALOG_STATE,
  EMPTY_EDIT_USER_INFOS_DIALOG_STATE,
  EMPTY_USERS_ADMIN_STATE,
} from './typings/store.js';
import { EditUserInfosModal } from './UpdateUserInfosModal.js';
import { UserCreationModal } from './UserCreationModal.js';

function openCreateUserModalAction({
  actionDispatch,
  getGlobalState,
}: ActionContext<Store, AdminUsersState>) {
  const { subcontractors } = getGlobalState().siteConfiguration;
  actionDispatch.reduce((initial: AdminUsersState) => {
    return {
      ...initial,
      createUserDialogState: {
        ...EMPTY_CREATE_USER_DIALOG_STATE,
        subcontractors,
        active: true,
      },
    };
  });
}

function openUpdatePasswordModalAction(
  { actionDispatch }: ActionContext<Store, AdminUsersState>,
  user: AdminUIUser
) {
  actionDispatch.reduce((initial: AdminUsersState): AdminUsersState => {
    return {
      ...initial,
      updatePasswordDialogState: {
        ...EMPTY_EDIT_PASSWORD_DIALOG_STATE,
        active: true,
        userLogin: user.id,
        formData: {
          ...EMPTY_EDIT_PASSWORD_DATA,
        },
      },
    };
  });
}

function openEditUserInfosModalAction(
  { actionDispatch, getGlobalState }: ActionContext<Store, AdminUsersState>,
  user: AdminUIUser
): void {
  const { subcontractors } = getGlobalState().siteConfiguration;
  actionDispatch.reduce((initial: AdminUsersState): AdminUsersState => {
    return {
      ...initial,
      editUserInfosDialogState: {
        ...EMPTY_EDIT_USER_INFOS_DIALOG_STATE,
        active: true,
        initialUser: user,
        subcontractors,
        formData: {
          login: user.id,
          editedFirstName: user.firstName,
          editedLastName: user.lastName,
          editedActive: user.active,
          selectedProfiles: user.profiles.names ?? [],
          editedIsSubcontractor: user.isSubcontractor,
          editedFirm: user.firm,
          warnings: {},
        },
      },
    };
  });
}

const computeColumnDescs = (
  t: TFunction,
  isEditionForbidden: boolean,
  isSuperAdmin: boolean
): readonly ColumnDesc<Store, AdminUsersState, AdminUIUser>[] => {
  function isEditionButtonsInactive(user: AdminUIUser | undefined): boolean {
    return (
      isEditionForbidden ||
      // User without superAdmin permission cannot modify users who have it
      (!isSuperAdmin && (user?.profiles.names ?? []).includes(SUPER_ADMIN_PROFILE_NAME))
    );
  }
  return [
    {
      columnLabel: t('usersAdmin:login'),
      columnType: 'display',
      id: 'login',
      propertyType: 'string',
      getPropertyValue: (u): string => u.id,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('usersAdmin:firstName'),
      columnType: 'display',
      id: 'firstName',
      propertyType: 'string',
      getPropertyValue: (u): string => u.firstName,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('usersAdmin:lastName'),
      columnType: 'display',
      id: 'lastName',
      propertyType: 'string',
      getPropertyValue: (u): string => u.lastName,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('usersAdmin:isSubcontractor'),
      columnType: 'display',
      id: 'isSubcontractor',
      propertyType: 'boolean',
      getPropertyValue: (u): boolean => u.isSubcontractor,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('usersAdmin:firm'),
      columnType: 'display',
      id: 'firm',
      propertyType: 'string',
      getPropertyValue: (u): string => u.firm,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('usersAdmin:profiles'),
      columnType: 'display',
      id: 'profiles',
      propertyType: 'string',
      getPropertyValue: (u): string => {
        if (u.profiles && u.profiles.names) {
          return enumerate(u.profiles.names);
        }
        return '';
      },
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('usersAdmin:activeUser'),
      columnType: 'display',
      id: 'activeUser',
      propertyType: 'boolean',
      getPropertyValue: (u): boolean => u.active,
      isDisplayedByDefault: true,
    },
    {
      columnType: 'action',
      id: 'editUserInfos',
      columnLabel: t('usersAdmin:editUserInfos'),
      columnIcon: {
        iconId: '',
        showText: false,
      },
      disabled: (u): boolean => {
        return isEditionButtonsInactive(u);
      },
      getCellIconId: (): string => {
        return 'edit';
      },
      cellTooltip: t('usersAdmin:editUserInfos'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      action: async (dispatch, user): Promise<void> => {
        await dispatch.exec(openEditUserInfosModalAction, user);
      },
      isDisplayedByDefault: true,
    },
    {
      columnType: 'action',
      id: 'editPassword',
      columnLabel: t('usersAdmin:editPassword'),
      columnIcon: {
        iconId: '',
        showText: false,
      },
      disabled: (u): boolean => {
        return isEditionButtonsInactive(u);
      },
      getCellIconId: (): string => {
        return 'key';
      },
      cellTooltip: t('usersAdmin:editPassword'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      action: async (dispatch, user): Promise<void> => {
        await dispatch.exec(openUpdatePasswordModalAction, user);
      },
      isDisplayedByDefault: true,
    },
  ];
};

async function preInitializeAdminUsersState({
  actionDispatch,
  httpClient,
  getGlobalState,
}: ActionContext<Store, AdminUsersState>): Promise<void> {
  const { isSuperAdmin } = nonnull(getGlobalState().session.user);

  let availableProfiles = await httpClient.httpGetAsJson<readonly string[]>(
    CoreBackendRoutes.GET_ALL_AVAILABLE_USER_PROFILES
  );
  if (!isSuperAdmin) {
    availableProfiles = availableProfiles.filter((p) => p !== SUPER_ADMIN_PROFILE_NAME);
  }
  actionDispatch.setProperty('availableProfiles', availableProfiles);

  // Add a default sort option (sort by code)
  actionDispatch
    .scopeProperty('sortsMenuState')
    .scopeProperty('sorts')
    .setValue([
      {
        id: 'activeUser',
        direction: 'UP',
      },
      {
        id: 'login',
        direction: 'UP',
      },
    ]);
}

const tableContentProvider = async (
  ctx: ReadOnlyActionContext<Store, AdminUsersState>
): Promise<readonly AdminUIUser[]> => {
  const users = await ctx.httpClient.httpGetAsJson<readonly User[]>(CoreBackendRoutes.GET_USERS);
  return users.map(({ login, ...user }) => ({
    ...user,
    id: login,
  }));
};

export function UsersAdmin({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('usersAdmin');

  const $ = $gs.$adminView.$adminUsers;
  const { $updatePasswordDialogState, $createUserDialogState, $editUserInfosDialogState } = $;

  const isEditionForbidden = !useHasModifyPermission($gs, AvailablePermissionPaths.USERS_VIEW);

  const isOnline = useGetState($gs.$session.$isOnline);
  const isSuperAdmin = useGetState($gs.$session.$user.asDefined().$isSuperAdmin);

  const columnDescs = useMemo(() => {
    return computeColumnDescs(t, isEditionForbidden, isSuperAdmin);
  }, [t, isEditionForbidden, isSuperAdmin]);

  const onUserChangeActionCallback = useActionCallback(
    function _onUserChangeActionCallback(
      ctx,
      addedOrUpdated: readonly AdminUIUser[],
      removedLogins: readonly string[]
    ) {
      const { getState } = ctx;
      // Merge actual items (the same way as SSE updates for repository entities)
      const { items } = getState();

      const newItems = mergeArrayItems(items, addedOrUpdated, removedLogins);
      applyAndFilterTableItemsAction(ctx, newItems, columnDescs);
    },
    [columnDescs],
    $
  );

  const asyncCleanupEffect = useSetCallback($, EMPTY_USERS_ADMIN_STATE);

  useEffect((): (() => void) => {
    return (): void => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      asyncCleanupEffect();
    };
  }, [asyncCleanupEffect]);

  const availableProfiles = useGetState($.$availableProfiles);

  const showCreate = useActionCallback(openCreateUserModalAction, [], $);

  const toolbarConf = useGetDefaultTableToolbarConf(
    LocalStorageKeys.ADMIN_USERS_COLUMNS_PREFERENCE,
    t('csvDownloadBaseFileName')
  );

  return (
    <DisplayContentOrPlaceholder
      displayCondition={isOnline}
      placeholder={t('warning.notAvailableIfOffline')}
    >
      <>
        <div className="level">
          <div className="level-left">
            <div>
              <p className="title is-2">{t('title')}</p>
              <p className="subtitle is-4">{t('subTitle')}</p>
            </div>
          </div>
          <div className="level-right">
            <div className="buttons">
              <Button
                label={t('newUser')}
                additionalClass="button"
                onClick={showCreate}
                tooltip={t('createNewUserTooltip')}
              />
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Table
              $={$}
              contentProvider={tableContentProvider}
              preInitActionCallback={useActionCallback(preInitializeAdminUsersState, [], $)}
              columnDescs={columnDescs}
              isOnline={isOnline}
              isScrollable
              isTruncable
              toolbar={toolbarConf}
              tableClassName="table is-bordered is-narrow is-hoverable is-fullwidth"
            />
          </div>
        </div>
        <UpdatePasswordModal $={$updatePasswordDialogState} $gs={$gs} updateFromAdminView />
        <EditUserInfosModal
          $={$editUserInfosDialogState}
          availableProfiles={availableProfiles}
          onUserChangeActionCallback={onUserChangeActionCallback}
        />
        <UserCreationModal
          $={$createUserDialogState}
          availableProfiles={availableProfiles}
          onUserChangeActionCallback={onUserChangeActionCallback}
        />
      </>
    </DisplayContentOrPlaceholder>
  );
}
