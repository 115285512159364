/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { marked } from 'marked';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import type { FaIconSize } from '../../bulma/elements/FaIcon.js';
import { isAndroidDevice } from '../../../utils/deviceHelpers.js';
import { DeleteClickableIcon } from '../../bulma/elements/DeleteClickableIcon.js';
import { FaIcon } from '../../bulma/elements/FaIcon.js';
import { FILE_ACCEPT, loadFile } from '../attachments/ImportButton.js';

export type LoadedStatus = 'loading' | 'loaded' | 'no-src' | 'http-error' | 'network-error';

type AttachemntType = 'video' | 'image' | 'markdown' | 'text' | 'pdf' | 'unknown';

function isText(blobType: string | undefined) {
  return isTruthyAndNotEmpty(blobType) && blobType.startsWith('text/');
}

function toAttachmentType(
  blobType: string | undefined,
  filename: string | undefined
): AttachemntType {
  if (isTruthyAndNotEmpty(blobType) && blobType.startsWith('image/')) {
    return 'image';
  }
  if (isTruthyAndNotEmpty(blobType) && blobType.startsWith('video/')) {
    return 'video';
  }
  if (
    isTruthyAndNotEmpty(blobType) &&
    (blobType.startsWith('text/markdown') ||
      (blobType.startsWith('text/plan') &&
        filename !== undefined &&
        filename.toLocaleLowerCase().endsWith('.md')))
  ) {
    return 'markdown';
  }
  if (isText(blobType)) {
    return 'text';
  }
  if (isTruthyAndNotEmpty(blobType) && blobType.startsWith('application/pdf')) {
    return 'pdf';
  }
  return 'unknown';
}

/** Helpers components */

interface MonospaceTextProps {
  readonly text?: string;
}

function MonospaceText({ text }: MonospaceTextProps): JSX.Element {
  return (
    <div className="has-text-left is-family-monospace" style={{ whiteSpace: 'pre-wrap' }}>
      {text}
    </div>
  );
}

interface BoxWithTitleProps {
  readonly title?: string;
  readonly children: React.ReactNode;
}

function BoxWithTitle({ children, title }: BoxWithTitleProps): JSX.Element {
  return (
    <div className="container is-max-widescreen">
      <article className="message is-primary">
        <div className="message-header">
          <p>{title}</p>
        </div>
        <div className="message-body has-text-left">{children}</div>
      </article>
    </div>
  );
}

interface ClickableProps {
  readonly onClickCallback?: () => Promise<void> | void;
  readonly children: React.ReactNode;
}

function MakeClickableIfNeeded({ onClickCallback, children }: ClickableProps): JSX.Element {
  return (
    <>
      {onClickCallback ? (
        <a role="button" onClick={onClickCallback}>
          {children}
        </a>
      ) : (
        children
      )}
    </>
  );
}

/** Renderers components */

interface ImageRendererProps
  extends Pick<LoadingObjectProps, 'width' | 'height' | 'title' | 'objectFit'> {
  readonly objectURL?: string;
  readonly filename?: string;
}

function ImageRenderer({
  width,
  height,
  title,
  objectURL,
  filename,
  objectFit,
}: ImageRendererProps): JSX.Element {
  return (
    <img
      src={objectURL}
      alt={!title ? filename : title}
      title={!title ? filename : title}
      style={{ maxWidth: width, maxHeight: height, objectFit }}
    />
  );
}

interface VideoRendererProps extends Pick<LoadingObjectProps, 'width' | 'height' | 'objectFit'> {
  readonly objectURL?: string;
}

function VideoRenderer({ width, height, objectURL, objectFit }: VideoRendererProps): JSX.Element {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video controls autoPlay style={{ maxWidth: width, maxHeight: height, objectFit }}>
      <source src={objectURL} type="video/mp4" />
      Sorry, your browser does not support embedded videos.
    </video>
  );
}

interface MarkdownRendererProps {
  readonly filename?: string;
  readonly source: string;
}

function MarkdownRenderer({ source, filename }: MarkdownRendererProps): JSX.Element {
  const [t] = useTranslation('custom');
  const [mode, setMode] = useState<'source' | 'html'>('html');
  const switchToHtml = useCallback(() => setMode('html'), []);
  const switchToMarkdown = useCallback(() => setMode('source'), []);
  const markdownAsHTML = useMemo(() => marked(source, { async: false }), [source]);
  return (
    <BoxWithTitle title={filename}>
      <>
        <div className="columns">
          <div className="column is-narrow">
            <input
              id="html"
              type="radio"
              radioGroup="mode"
              onChange={switchToHtml}
              checked={mode === 'html'}
            />
            <label htmlFor="html">{` ${t('loadingObject.markdown.html')}`}</label>
          </div>
          <div className="column">
            <input
              id="source"
              type="radio"
              radioGroup="mode"
              onChange={switchToMarkdown}
              checked={mode === 'source'}
            />
            <label htmlFor="source">{` ${t('loadingObject.markdown.source')}`}</label>
          </div>
        </div>
        {mode === 'source' && <MonospaceText text={source} />}
        {mode === 'html' && isTruthyAndNotEmpty(markdownAsHTML) && (
          <>
            {/* eslint-disable react/no-danger */}
            <div className="content" dangerouslySetInnerHTML={{ __html: markdownAsHTML }} />
            {/* eslint-enable react/no-danger */}
          </>
        )}
      </>
    </BoxWithTitle>
  );
}

interface TextRendererProps extends MonospaceTextProps {
  readonly filename?: string;
}

function TextRenderer({ text, filename }: TextRendererProps): JSX.Element {
  return (
    <BoxWithTitle title={filename}>
      <MonospaceText text={text} />
    </BoxWithTitle>
  );
}

export type LoadingObjectProps = {
  readonly src?: string;
  readonly errorIcon?: string;
  readonly title?: string;
  readonly width?: number;
  readonly height?: number;
  readonly textAlign?: React.CSSProperties['textAlign'];
  /* Used if the object is an image */
  readonly objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  readonly onClickCallback?: () => Promise<void> | void;
  readonly onFileSelectionCallback?: (file: File) => Promise<void> | void;
  /* Equivalent to https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept */
  readonly fileSelectionAccept?: string;
  readonly onRemoveCallback?: () => Promise<void> | void;
  readonly transparentBackground?: boolean;
};

export function LoadingObject({
  src,
  errorIcon,
  title,
  onClickCallback,
  onFileSelectionCallback,
  onRemoveCallback,
  width,
  height,
  objectFit,
  fileSelectionAccept,
  transparentBackground = false,
  textAlign = 'center',
}: LoadingObjectProps): JSX.Element {
  const [t] = useTranslation('custom');
  const [status, setStatus] = useState<LoadedStatus>('loading');
  const [objectURL, setObjectURL] = useState<string | undefined>(undefined);
  const [attachmentType, setAttachmentType] = useState<string | undefined>(undefined);
  const [contentAsText, setContentAsText] = useState<string | undefined>(undefined);

  const filename = src?.replace(/.*\//, '').replace(/\?.*$/, '');
  const isOnAndroid = isAndroidDevice();

  useEffect((): (() => void) => {
    // Keep track of the object URL in order to be able to revoke it
    // once the component is unmounted
    let theObjectURL: string | undefined;
    const asyncEffect = async (): Promise<void> => {
      setStatus('loading');
      try {
        if (!isTruthyAndNotEmpty(src)) {
          setStatus('no-src');
        } else {
          const response = await fetch(src);
          if (response.status !== 200) {
            setStatus('http-error');
          } else {
            const blob = await response.blob();
            theObjectURL = URL.createObjectURL(blob);
            const attachmentType = toAttachmentType(blob.type, filename);
            setAttachmentType(attachmentType);

            if (isText(blob.type)) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              blob.text().then((value) => {
                setContentAsText(value);
              });
            }
            setObjectURL(theObjectURL);
            setStatus('loaded');
          }
        }
      } catch {
        setStatus('network-error');
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
    return (): void => {
      if (theObjectURL) {
        URL.revokeObjectURL(theObjectURL);
      }
    };
  }, [filename, src]);

  let faSize: FaIconSize = 'large-3x';
  let minHeight = 70;
  if ((width && width < 128) || (height && height < 128)) {
    faSize = 'medium-2x';
    minHeight = 50;
  }
  if ((width && width < 64) || (height && height < 64)) {
    faSize = 'standard-lg';
    minHeight = 30;
  }

  return (
    <div
      style={{
        textAlign,
        ...(width ? { width } : {}),
        // Ensure the content is vertically aligned
        ...(height ? { height, lineHeight: `calc(${height}px + 15px)` } : {}),
        // If the image has a size, render a grayed rectangle
        ...(width && height && !transparentBackground ? { backgroundColor: '#CCCCCC' } : {}),
        minHeight,
      }}
    >
      {status === 'loading' && <FaIcon id="pulse fa-spinner" size={faSize} />}
      {(status === 'no-src' || status === 'http-error') &&
        (isTruthy(onFileSelectionCallback) ? (
          <ImportButtonInLoadingObject
            onFileReadyCallback={onFileSelectionCallback}
            fileAccept={fileSelectionAccept}
            size={
              /* eslint-disable no-nested-ternary */
              width !== undefined && height !== undefined
                ? Math.min(width, height)
                : typeof width === 'number'
                  ? width
                  : minHeight
              /* eslint-enable no-nested-ternary */
            }
            tooltip={t('loadingObject.importAndDisplayButtonTooltip')}
            iconId={isTruthy(errorIcon) ? errorIcon : 'eye-slash'}
          />
        ) : (
          <FaIcon id={isTruthy(errorIcon) ? errorIcon : 'eye-slash'} size={faSize} />
        ))}
      {status === 'network-error' && !isTruthy(onFileSelectionCallback) && (
        <FaIcon id="wifi" size={faSize} iconColor="darkred" />
      )}
      {status === 'loaded' && (
        <>
          <MakeClickableIfNeeded onClickCallback={onClickCallback}>
            {attachmentType === 'image' && (
              <ImageRenderer
                filename={filename}
                objectURL={objectURL}
                title={title}
                height={height}
                width={width}
                objectFit={objectFit}
              />
            )}
            {attachmentType === 'video' && (
              <VideoRenderer
                objectURL={objectURL}
                height={height}
                width={width}
                objectFit={objectFit}
              />
            )}
            {attachmentType === 'markdown' && contentAsText && (
              <MarkdownRenderer source={contentAsText} filename={filename} />
            )}
            {attachmentType === 'text' && <TextRenderer text={contentAsText} filename={filename} />}
            {attachmentType === 'pdf' && objectURL && (
              <iframe
                src={
                  isOnAndroid
                    ? `/pdf.js/web/viewer.html?file=${encodeURIComponent(objectURL)}`
                    : objectURL
                }
                title={title}
                style={{ width: window.innerWidth - 60, height: window.innerHeight - 40 }}
              />
            )}
            {attachmentType === 'unknown' && (
              <TextRenderer text={t('loadingObject.unknownFileFormat')} filename={filename} />
            )}
          </MakeClickableIfNeeded>
          {onRemoveCallback && (
            <DeleteClickableIcon
              handler={onRemoveCallback}
              customStyle={{ position: 'absolute', top: 2, right: 2 }}
              isSmall
            />
          )}
        </>
      )}
    </div>
  );
}

interface ImportButtonInLoadingObjectProps {
  readonly tooltip: string;
  readonly iconId?: string;
  readonly size?: number;
  readonly onFileReadyCallback: (file: File) => Promise<void> | void;
  readonly fileAccept?: string;
}

function ImportButtonInLoadingObject({
  onFileReadyCallback,
  tooltip,
  size,
  iconId,
  fileAccept = FILE_ACCEPT,
}: ImportButtonInLoadingObjectProps): JSX.Element {
  const onImportAttachmentCallback = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const file = nonnull(nonnull(event.target).files)[0];
      await loadFile(file);
      await onFileReadyCallback(file);
    },
    [onFileReadyCallback]
  );

  return (
    <div>
      <label htmlFor="file-input" title={tooltip}>
        <i
          className={`fas fa-${iconId}`}
          style={{
            width: size,
            fontSize: size,
            cursor: 'pointer',
          }}
        />
      </label>

      <input
        id="file-input"
        style={{
          display: 'none',
        }}
        type="file"
        value=""
        accept={fileAccept}
        name="resume"
        onChange={onImportAttachmentCallback}
      />
    </div>
  );
}
