import React, { useMemo } from 'react';
import { isTruthy } from '@stimcar/libs-kernel';
import { ScrollableContainer } from '../../bulma/index.js';
import { useRemainingVerticalSpace } from '../../hooks/index.js';

interface Props {
  readonly children: JSX.Element;
  readonly displayCondition: boolean;
  readonly placeholder: string;
  readonly isScrollable?: boolean;
  readonly scrollableProps?: {
    readonly sizeInPx?: number;
    readonly bottomPlaceholderSize?: number;
  };
}

export function DisplayContentOrPlaceholder({
  children,
  displayCondition,
  placeholder,
  isScrollable = false,
  scrollableProps,
}: Props): JSX.Element {
  if (displayCondition) {
    return children;
  }

  return (
    <>
      {isScrollable ? (
        <DisplayScrollableContentOrPlaceholder
          scrollableProps={scrollableProps}
          placeholder={placeholder}
        />
      ) : (
        <div className="p-t-sm">
          <div className="has-text-centered">
            <b>{placeholder}</b>
          </div>
        </div>
      )}
    </>
  );
}

interface DisplayScrollableContentOrPlaceholderProps {
  readonly placeholder: string;
  readonly scrollableProps?: {
    readonly sizeInPx?: number;
    readonly bottomPlaceholderSize?: number;
  };
}

function DisplayScrollableContentOrPlaceholder({
  placeholder,
  scrollableProps,
}: DisplayScrollableContentOrPlaceholderProps): JSX.Element {
  const [listSize, measuredRef] = useRemainingVerticalSpace(scrollableProps?.bottomPlaceholderSize);
  const computedSize = useMemo(() => {
    if (!isTruthy(scrollableProps?.sizeInPx)) {
      return `${listSize}PX`;
    }
    return `${scrollableProps?.sizeInPx}PX`;
  }, [scrollableProps, listSize]);

  return (
    <div ref={measuredRef}>
      <ScrollableContainer height={computedSize} className="p-t-sm">
        <div className="has-text-centered">
          <b>{placeholder}</b>
        </div>
      </ScrollableContainer>
    </div>
  );
}
