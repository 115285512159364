import type {
  Kanban,
  KanbanColorationCharter,
  KanbansBoardBandwidthConfiguration,
  KanbansBoardConfiguration,
  KanbansBoardLaneConfiguration,
  Stand,
  WithProgress,
} from '@stimcar/libs-base';
import { kanbanHelpers, mapRecordValues, sortingHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import type { StandDisplayState } from '../../typings/store.js';
import type {
  GridData,
  KanbansAllocation,
  KanbansAllocationByLaneAndBandwidth,
  KanbansBoardCellData,
  KanbansBoardCellDimensions,
  KanbansBoardCellSlot,
  RepairOrderForKanban,
  RepairOrdersByLaneAndBandwidth,
} from '../typings/store.js';
import { SlotType } from '../typings/store.js';

function getRepairOrdersByBandwidths(
  laneId: string,
  bandwidths: readonly KanbansBoardBandwidthConfiguration[],
  repairOrders: readonly RepairOrderForKanban[]
): readonly RepairOrdersByLaneAndBandwidth[] {
  // Let's filter repair orders by bandwidths
  const ordersByBandwidth = bandwidths.map<RepairOrdersByLaneAndBandwidth>(
    ({ id: bandwidthId, contracts }) => {
      const repairOrdersForAssociatedContracts = repairOrders.filter(({ contract }) =>
        (contracts ?? []).some((value) => value.toLowerCase() === contract.toLowerCase())
      );

      return {
        laneId,
        bandwidthId,
        repairOrders: repairOrdersForAssociatedContracts,
      };
    }
  );

  // After this first step, the repair orders were divided by bandwidths
  // However, when a bandwidth has no associated contracts, no repair order has been associated to this bandwidth.
  // Therefore, we must now associate all non-associated repair orders with such a bandwidth
  const bandwidthWithNoContracts = bandwidths.find(
    ({ contracts }) => (contracts ?? []).length === 0
  );
  if (isTruthy(bandwidthWithNoContracts)) {
    const allAssociatedOrders = ordersByBandwidth.flatMap(({ repairOrders }) => repairOrders);
    const allNonAssociatedOrders = repairOrders.filter(
      (repairOrder) => !allAssociatedOrders.includes(repairOrder)
    );
    return ordersByBandwidth.map((item) => {
      if (item.laneId === laneId && item.bandwidthId === bandwidthWithNoContracts.id) {
        return {
          ...item,
          repairOrders: allNonAssociatedOrders,
        };
      }
      return item;
    });
  }

  // No bandwidth with null fonction, nothing special needs to be done
  return ordersByBandwidth;
}

function sortRepairsOrders(
  repairsOrders: readonly WithProgress<Kanban>[],
  dueDateThreshold: number
): readonly WithProgress<Kanban>[] {
  return repairsOrders
    .slice()
    .sort((repairOrder1: WithProgress<Kanban>, repairOrder2: WithProgress<Kanban>): number => {
      return sortingHelpers.compareRepairOrdersByDueDateThenAge(
        repairOrder1,
        repairOrder2,
        dueDateThreshold
      );
    });
}

/**
 * If a repair order is expected on several lanes we want to keep this repair order only for the first lane
 * The order for lanes is the one provided by the lanes variable
 * @param lanes
 * @param standsDisplayState
 * @returns all repair orders per lane, order is as follow :
 *          repair orders that should be allocated first are at the beginning of the array
 */
function getRepairOrdersPerLane(
  lanes: readonly KanbansBoardLaneConfiguration[],
  standsDisplayState: Record<string, StandDisplayState>,
  kanbanAgeRangeForColorCharter: KanbanColorationCharter,
  standsIcons: Record<string, string>
): Record<string, readonly RepairOrderForKanban[]> {
  const seenRepairOrderIds = new Set<string>();

  return lanes.reduce<Record<string, readonly RepairOrderForKanban[]>>((acc, lane) => {
    const { id: laneId, standsIds } = lane;

    const repairOrders = mapRecordValues(
      standsDisplayState,
      ({ kanbans: repairOrders }, standId) => {
        if (standsIds.includes(standId)) {
          // Keep the repair order and add it to the already seen repair orders
          const filteredRepairOrders = repairOrders.filter(({ id }) => !seenRepairOrderIds.has(id));
          const repairOrdersIds = repairOrders.map(({ id }) => id);
          repairOrdersIds.forEach((id) => seenRepairOrderIds.add(id));

          // Sort the repair orders
          const filteredAndSortedRepairOrders: readonly RepairOrderForKanban[] = sortRepairsOrders(
            filteredRepairOrders,
            kanbanAgeRangeForColorCharter.dueDateThreshold
          ).map((repairOrder) => {
            const { id, infos, contract } = repairOrder;
            return {
              id,
              standId,
              license: infos.license,
              contract: contract.code,
              priorityLevel: kanbanHelpers.computePriorityLevel(
                repairOrder,
                kanbanAgeRangeForColorCharter
              ),
              standIconToDisplay: standsIcons[standId],
            };
          });
          return filteredAndSortedRepairOrders;
        }
        return [];
      }
    ).flat();

    // sort by stand
    const sortedByStandsRepairOrders = repairOrders.sort(
      ({ standId: standId1 }, { standId: standId2 }) =>
        standsIds.indexOf(standId1) - standsIds.indexOf(standId2)
    );

    return {
      ...acc,
      [laneId]: sortedByStandsRepairOrders.reverse(), // we want first to allocate at first places
    };
  }, {});
}

function getRepairOrdersByLanesAndBandwidths(
  standsDisplayState: Record<string, StandDisplayState>,
  kanbansBoardConfiguration: KanbansBoardConfiguration,
  kanbanAgeRangeForColorCharter: KanbanColorationCharter,
  standsDefinition: readonly Stand<'standard'>[]
): readonly RepairOrdersByLaneAndBandwidth[] {
  const { lanes, bandwidths } = kanbansBoardConfiguration;

  const standsIcons: Record<string, string> = standsDefinition.reduce<Record<string, string>>(
    (acc, { id, iconClass }) => {
      acc[id] = iconClass;
      return acc;
    },
    {}
  );

  const repairOrdersByLane = getRepairOrdersPerLane(
    lanes,
    standsDisplayState,
    kanbanAgeRangeForColorCharter,
    standsIcons
  );

  const repairOrdersByLaneAndBandwidth = mapRecordValues(
    repairOrdersByLane,
    (repairOrders, laneId) => {
      return getRepairOrdersByBandwidths(laneId, bandwidths, repairOrders);
    }
  );
  return repairOrdersByLaneAndBandwidth.flat();
}

function allocateKanbans(
  repairOrders: readonly RepairOrderForKanban[],
  totalKanbansCount: number
): KanbansAllocation {
  // Allocated repair orders are limited by the total number of kanbans
  const allocatedRepairOrders = repairOrders.slice(0, totalKanbansCount);
  const availableKanbansCount = Math.max(0, totalKanbansCount - allocatedRepairOrders.length);
  const nonAllocatedRepairOrders = repairOrders.slice(allocatedRepairOrders.length);

  return {
    allocatedRepairOrders,
    availableKanbansCount,
    nonAllocatedRepairOrders,
  };
}

function getKanbansAllocationForLaneAndBandwidth(
  laneId: string,
  bandwidth: KanbansBoardBandwidthConfiguration,
  repairOrders: readonly RepairOrderForKanban[]
): KanbansAllocationByLaneAndBandwidth {
  const { id: bandwidthId, repairOrdersPerDay, leadTimesPerLane } = bandwidth;

  const leadTimeForCurrentLane = leadTimesPerLane[laneId];
  const totalKanbansCountForCurrentStand = computeAvailableKanbansCount(
    repairOrdersPerDay,
    leadTimeForCurrentLane
  );

  const allocatedKanbans = allocateKanbans(repairOrders, totalKanbansCountForCurrentStand);

  return {
    ...allocatedKanbans,
    laneId,
    bandwidthId,
    repairOrdersPerDay,
    leadTime: leadTimeForCurrentLane,
  };
}

function getKanbansAllocationByLanesForBandwidth(
  reversedLanes: KanbansBoardLaneConfiguration[],
  bandwidth: KanbansBoardBandwidthConfiguration,
  repairOrdersByLaneAndBandwidth: readonly RepairOrdersByLaneAndBandwidth[]
): readonly KanbansAllocationByLaneAndBandwidth[] {
  const repairOrdersByLane = repairOrdersByLaneAndBandwidth.filter(
    ({ bandwidthId }) => bandwidthId === bandwidth.id
  );

  let repairOrdersWaitingForAllocation: RepairOrderForKanban[] = [];
  return reversedLanes.map((lane) => {
    const repairOrdersOnLane = repairOrdersByLane
      .filter(({ laneId }) => laneId === lane.id)
      .flatMap(({ repairOrders }) => repairOrders);
    // We add the repair orders for this lane after the ones that remains to be allocated
    const repairOrders = [...repairOrdersWaitingForAllocation, ...repairOrdersOnLane];

    const allocation = getKanbansAllocationForLaneAndBandwidth(lane.id, bandwidth, repairOrders);

    repairOrdersWaitingForAllocation = allocation.nonAllocatedRepairOrders.slice();

    return allocation;
  });
}

function getKanbansAllocationByLanesAndBandwidths(
  { lanes, bandwidths }: KanbansBoardConfiguration,
  repairOrdersByLaneAndBandwidth: readonly RepairOrdersByLaneAndBandwidth[]
): readonly KanbansAllocationByLaneAndBandwidth[] {
  // We reverse the lanes because we want to fill the kanbans starting from the end
  const reversedLanes = lanes.slice().reverse();

  return bandwidths.flatMap((bandwidth) =>
    getKanbansAllocationByLanesForBandwidth(
      reversedLanes,
      bandwidth,
      repairOrdersByLaneAndBandwidth
    )
  );
}

function computeAvailableKanbansCount(repairOrdersPerDay: number, leadTime: number) {
  return Math.round(leadTime * repairOrdersPerDay);
}

function computeNbRowsForRepairOrdersPerDay(repairOrdersPerDay: number) {
  return Math.round(repairOrdersPerDay);
}

function computeKanbansBoardCellDimensions(
  repairOrdersPerDay: number,
  leadTime: number
): KanbansBoardCellDimensions {
  const totalKanbansCount = computeAvailableKanbansCount(repairOrdersPerDay, leadTime);
  const nbRows = computeNbRowsForRepairOrdersPerDay(repairOrdersPerDay);
  const nbCols = Math.ceil(totalKanbansCount / nbRows);
  return {
    nbRows,
    nbCols,
  };
}

function getSlotForRepairOrder(repairOrder: RepairOrderForKanban): KanbansBoardCellSlot {
  return {
    type: SlotType.repairOrder,
    key: repairOrder.id,
    repairOrder,
  };
}

function getAvailableOrDisabledSlot(
  slotType: SlotType.available | SlotType.disabled,
  index: number
): KanbansBoardCellSlot {
  return {
    type: slotType,
    key: `${slotType}${index}`,
  };
}

function getArrayOfAvailableOrDisabledSlots(
  nbOfSlots: number,
  slotType: SlotType.available | SlotType.disabled
): readonly KanbansBoardCellSlot[] {
  return Array(nbOfSlots)
    .fill(null)
    .map((_, index) => getAvailableOrDisabledSlot(slotType, index));
}

// TODO ne plus renvoyer la propriété dimensions devenue inutile
function computeKanbansBoardCellData(
  kanbansAllocation: KanbansAllocationByLaneAndBandwidth,
  maxNbCols: number
): KanbansBoardCellData {
  const { repairOrdersPerDay, leadTime, allocatedRepairOrders, availableKanbansCount } =
    kanbansAllocation;
  const { nbRows, nbCols } = computeKanbansBoardCellDimensions(repairOrdersPerDay, leadTime);
  const nbColsToBeUsed = Math.max(maxNbCols, nbCols);

  const nbAllSlots = nbRows * nbColsToBeUsed;
  const nbDisabledSlots = nbAllSlots - allocatedRepairOrders.length - availableKanbansCount;

  // We return a list of slots
  const slots: readonly KanbansBoardCellSlot[] = [
    ...allocatedRepairOrders.map(getSlotForRepairOrder),
    ...getArrayOfAvailableOrDisabledSlots(availableKanbansCount, SlotType.available),
    ...getArrayOfAvailableOrDisabledSlots(nbDisabledSlots, SlotType.disabled),
  ];

  return {
    dimensions: { nbRows, nbCols: nbColsToBeUsed },
    slots: slots.slice().reverse(),
  };
}

function computeNewIndex(index: number, nbRows: number, nbCols: number): number {
  return (index % nbCols) * nbRows + Math.floor(index / nbCols);
}

function sortSlotsForGridDisplay(
  slots: readonly KanbansBoardCellSlot[],
  nbRows: number,
  nbCols: number
): readonly KanbansBoardCellSlot[] {
  return slots.map((_, index) => {
    const newIndex = computeNewIndex(index, nbRows, nbCols);
    return slots[newIndex];
  });
}

/**
 * Generates style with gridRow and gridColumn CSS properties
 * Indices for row and column start with 0 (CSS values start with 1)
 */
function grid({ row, nbRows, col, nbCols }: GridData) {
  const reindexedRow = row + 1;
  const reindexedCol = col + 1;
  const gridRow = isTruthy(nbRows) ? `${reindexedRow} / ${reindexedRow + nbRows}` : reindexedRow;
  const gridColumn = isTruthy(nbCols) ? `${reindexedCol} / ${reindexedCol + nbCols}` : reindexedCol;
  return {
    gridRow,
    gridColumn,
  };
}

function sizeInPx(defaultPixels: number, size: number) {
  return `${defaultPixels * size}px`;
}

/**
 * Generates fontSize property to be used in style attribute
 */
function fontSize(defaultPixels: number, size: number) {
  return { fontSize: sizeInPx(defaultPixels, size) };
}

export const kanbansBoardHelpers = {
  getRepairOrdersByLanesAndBandwidths,
  getKanbansAllocationByLanesAndBandwidths,
  computeAvailableKanbansCount,
  computeKanbansBoardCellData,
  sortSlotsForGridDisplay,
  computeNbRowsForRepairOrdersPerDay,
  computeKanbansBoardCellDimensions,
  grid,
  sizeInPx,
  fontSize,
};
