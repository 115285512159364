import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ShiftsConfiguration, SiteConfiguration } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormFieldContentActions,
  HorizontalFormFieldProps,
} from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { computePayload } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { InputFormField, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type {
  AdminShiftParametersConfigurationDialogState,
  AdminShiftParametersConfigurationFormState,
} from './typings/store.js';
import { EMPTY_ADMIN_SHIFT_PARAMETERS_CONFIGURATION_DIALOG_STATE } from './typings/store.js';

export function openShiftParametersDialogAction({
  actionDispatch,
  getGlobalState,
}: ActionContext<Store, AdminShiftParametersConfigurationDialogState>) {
  const { workshop, expertise } = getGlobalState().siteConfiguration.shiftsConfiguration;
  actionDispatch.setValue(EMPTY_ADMIN_SHIFT_PARAMETERS_CONFIGURATION_DIALOG_STATE);
  actionDispatch.setProperty('active', true);
  actionDispatch.applyPayload({
    formData: {
      workshopStartTime: `${workshop.startHour}:${String(workshop.startMinute).padStart(2, '0')}`,
      workshopExpectedWorkloadByCollaborator: String(workshop.expectedWorkloadByCollaborator),
      workshopShift1CollaboratorsCount: String(workshop.collaboratorsCount.shift1),
      workshopShift2CollaboratorsCount: String(workshop.collaboratorsCount.shift2),
      workshopShift3CollaboratorsCount: String(workshop.collaboratorsCount.shift3),
      expertiseStartTime: `${expertise.startHour}:${String(expertise.startMinute).padStart(2, '0')}`,
      expertiseExpectedWorkloadByCollaborator: String(expertise.expectedWorkloadByCollaborator),
      expertiseShift1CollaboratorsCount: String(expertise.collaboratorsCount.shift1),
      expertiseShift2CollaboratorsCount: String(expertise.collaboratorsCount.shift2),
      expertiseShift3CollaboratorsCount: String(expertise.collaboratorsCount.shift3),
    },
  });
  actionDispatch.setProperty('active', true);
}

const mandatoryFields: (keyof AdminShiftParametersConfigurationFormState)[] = [
  'workshopStartTime',
  'workshopExpectedWorkloadByCollaborator',
  'workshopShift1CollaboratorsCount',
  'workshopShift2CollaboratorsCount',
  'workshopShift3CollaboratorsCount',
  'expertiseStartTime',
  'expertiseExpectedWorkloadByCollaborator',
  'expertiseShift1CollaboratorsCount',
  'expertiseShift2CollaboratorsCount',
  'expertiseShift3CollaboratorsCount',
];

function parseStartTime(startTime: string): [number, number] {
  const [hoursStr, minutesStr] = startTime.trim().split(':');
  const hours = Number.parseInt(hoursStr, 10);
  const minutes = Number.parseInt(minutesStr, 10);
  return [hours, minutes];
}

function checkStartTime(value: string, t: TFunction): string | undefined {
  const trimed = value.trim();
  if (!trimed.match(/^[0-9]?[0-9]:[0-9][0-9]$/)) {
    return t('form.startTimeWarnings.wrongFormat');
  }
  const [hours, minutes] = parseStartTime(trimed);
  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    return t('form.startTimeWarnings.invalidTime');
  }
  return undefined;
}

const checkFieldContentActions: CheckFormFieldContentActions<
  Store,
  AdminShiftParametersConfigurationDialogState
> = {
  workshopStartTime: ({ value, t }) => checkStartTime(value, t),
  expertiseStartTime: ({ value, t }) => checkStartTime(value, t),
};

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  labelFlexGrow: 2,
  bodyFlexGrow: 3,
};

type Props = AppProps<Store>;

export function ShiftProgressDisplayConfigurationDialog({ $gs }: Props): JSX.Element {
  const [t] = useTranslation('adminShiftParameters');
  const { $adminShiftProgressDisplay } = $gs.$adminView;

  const submitValidDataActionCallback = useActionCallback(
    async function submitValidDataAction({ actionDispatch, getState, getGlobalState, httpClient }) {
      // Retrieve workshop configuration
      const {
        workshopStartTime,
        workshopExpectedWorkloadByCollaborator,
        workshopShift1CollaboratorsCount,
        workshopShift2CollaboratorsCount,
        workshopShift3CollaboratorsCount,
      } = getState().formData;
      const [workshopStartHour, workshopStartMinute] = parseStartTime(workshopStartTime);
      const workshopShiftConfiguration: ShiftsConfiguration = {
        startHour: workshopStartHour,
        startMinute: workshopStartMinute,
        collaboratorsCount: {
          shift1: Number.parseFloat(workshopShift1CollaboratorsCount),
          shift2: Number.parseFloat(workshopShift2CollaboratorsCount),
          shift3: Number.parseFloat(workshopShift3CollaboratorsCount),
        },
        expectedWorkloadByCollaborator: Number.parseFloat(workshopExpectedWorkloadByCollaborator),
      };
      // Retrieve expertise configuration
      const {
        expertiseStartTime,
        expertiseExpectedWorkloadByCollaborator,
        expertiseShift1CollaboratorsCount,
        expertiseShift2CollaboratorsCount,
        expertiseShift3CollaboratorsCount,
      } = getState().formData;
      const [expertiseStartHour, expertiseStartMinute] = parseStartTime(expertiseStartTime);
      const expertiseShiftConfiguration: ShiftsConfiguration = {
        startHour: expertiseStartHour,
        startMinute: expertiseStartMinute,
        collaboratorsCount: {
          shift1: Number.parseFloat(expertiseShift1CollaboratorsCount),
          shift2: Number.parseFloat(expertiseShift2CollaboratorsCount),
          shift3: Number.parseFloat(expertiseShift3CollaboratorsCount),
        },
        expectedWorkloadByCollaborator: Number.parseFloat(expertiseExpectedWorkloadByCollaborator),
      };
      // Compute configuration update payload
      const newShiftsConfiguration: SiteConfiguration['shiftsConfiguration'] = {
        workshop: workshopShiftConfiguration,
        expertise: expertiseShiftConfiguration,
      };
      const currentShiftConfiguration = getGlobalState().siteConfiguration.shiftsConfiguration;
      const payload = computePayload(currentShiftConfiguration, newShiftsConfiguration);
      if (payload !== undefined) {
        // Update configuration if needed
        await httpClient.httpPostAsJSON(
          CoreBackendRoutes.SITE_SHIFT_CONFIGURATION,
          newShiftsConfiguration
        );
      }
      // Close dialog
      actionDispatch.setProperty('active', false);
    },
    [],
    $adminShiftProgressDisplay
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    AdminShiftParametersConfigurationDialogState
  >({
    $: $adminShiftProgressDisplay,
    mandatoryFields,
    checkFieldContentActions,
    checkFormConsistencyAction: undefined,
    submitValidDataAction: submitValidDataActionCallback,
    t,
  });
  return (
    <ModalCardDialog
      $active={$adminShiftProgressDisplay.$active}
      title={t('modalTitle')}
      onOkClicked={onFormSubmit}
      size="max-desktop"
    >
      <div className="columns">
        <div className="column has-text-centered">
          <p className="title is-5">{t('form.workshopColumn')}</p>
        </div>
        <div className="column has-text-centered">
          <p className="title is-5">{t('form.expertiseColumn')}</p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <InputFormField
            $={$formDataWithChangeTrigger.$workshopStartTime}
            label={t('form.startTime')}
            placeholder={t('form.startTimePlaceholder')}
            horizontal={HORIZONTAL_PROPS}
            readOnly
            disabled
          />
          <InputFormField
            $={$formDataWithChangeTrigger.$workshopShift1CollaboratorsCount}
            label={t('form.shift1CollaboratorsCount')}
            type="number"
            horizontal={HORIZONTAL_PROPS}
          />
          <InputFormField
            $={$formDataWithChangeTrigger.$workshopShift2CollaboratorsCount}
            label={t('form.shift2CollaboratorsCount')}
            type="number"
            horizontal={HORIZONTAL_PROPS}
          />
          <InputFormField
            $={$formDataWithChangeTrigger.$workshopShift3CollaboratorsCount}
            label={t('form.shift3CollaboratorsCount')}
            type="number"
            horizontal={HORIZONTAL_PROPS}
          />
          <InputFormField
            $={$formDataWithChangeTrigger.$workshopExpectedWorkloadByCollaborator}
            label={t('form.expectedWorkloadByCollaborator')}
            type="number"
            horizontal={HORIZONTAL_PROPS}
          />
        </div>
        <div className="column">
          <InputFormField
            $={$formDataWithChangeTrigger.$expertiseStartTime}
            label={t('form.startTime')}
            placeholder={t('form.startTimePlaceholder')}
            horizontal={HORIZONTAL_PROPS}
            readOnly
            disabled
          />
          <InputFormField
            $={$formDataWithChangeTrigger.$expertiseShift1CollaboratorsCount}
            label={t('form.shift1CollaboratorsCount')}
            type="number"
            horizontal={HORIZONTAL_PROPS}
          />
          <InputFormField
            $={$formDataWithChangeTrigger.$expertiseShift2CollaboratorsCount}
            label={t('form.shift2CollaboratorsCount')}
            type="number"
            horizontal={HORIZONTAL_PROPS}
          />
          <InputFormField
            $={$formDataWithChangeTrigger.$expertiseShift3CollaboratorsCount}
            label={t('form.shift3CollaboratorsCount')}
            type="number"
            horizontal={HORIZONTAL_PROPS}
          />
          <InputFormField
            $={$formDataWithChangeTrigger.$expertiseExpectedWorkloadByCollaborator}
            label={t('form.expectedWorkloadByCollaborator')}
            type="number"
            horizontal={HORIZONTAL_PROPS}
          />
        </div>
      </div>
    </ModalCardDialog>
  );
}
