import type { TFunction } from 'i18next';
import i18next from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AirtableConnectorArgs, DAY_OF_WEEK, ScheduledTask } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps, ColumnDesc } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  ALL_DAYS_OF_WEEK_ALIAS,
  AvailablePermissionPaths,
  CoreBackendRoutes,
  enumerate,
  mergeArrayItems,
  scheduledTaskHelpers,
  workflowHelpers,
} from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import {
  applyAndFilterTableItemsAction,
  Button,
  DisplayContentOrPlaceholder,
  Table,
  useGetDefaultTableToolbarConf,
} from '@stimcar/libs-uitoolkit';
import type { Store, StoreActionContext } from '../../state/typings/store.js';
import { useHasModifyPermission } from '../../../registeredapp/permissionHooks.js';
import type { AdminScheduledTasksState, CustomerSummary } from './typings/store.js';
import {
  getForUpdateAirTableImportFormData,
  getForUpdateCarsExpectedAtStandMailFormData,
  getForUpdateCustomersBasedMailFormData,
  getForUpdateDailyProdMailFormData,
  getForUpdateInvoiceMailFormData,
  getForUpdateSparePartsMailFormData,
  getForUpdateVOLeanImportFormData,
  getHoursOrMinutesAsStringArray,
} from './adminScheduledTasksUtils.js';
import { CreateScheduledTaskModal } from './CreateScheduledTaskModal.js';
import { DeleteScheduledTaskModal } from './DeleteScheduledTaskModal.js';
import {
  EMPTY_ADMIN_SCHEDULED_TASKS_STATE,
  EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE,
  EMPTY_CREATE_SCHEDULED_TASK_DIALOG_STATE,
  EMPTY_CUSTOM_COLUMN_DIALOG_STATE,
  EMPTY_UPDATE_SCHEDULED_TASK_DIALOG_STATE,
} from './typings/store.js';
import { UpdateScheduledTaskModal } from './UpdateScheduledTaskModal.js';

async function openCreateCronTaskModalAction({
  actionDispatch,
  getGlobalState,
  customerRepository,
}: ActionContext<Store, AdminScheduledTasksState>): Promise<void> {
  const { site } = nonnull(getGlobalState().session.infos);
  const { siteConfiguration } = getGlobalState();
  const workflow = nonnull(siteConfiguration.workflows[0]);
  const linearWorkflow = workflowHelpers.linearize(workflow.definition);
  const allWorkflowIds = siteConfiguration.workflows.map((w) => w.id);
  const allCustomers = await customerRepository.getAllEntities();
  const allCustomerSummaries = allCustomers.map(({ id, shortName, contract }): CustomerSummary => {
    return {
      id,
      shortName,
      contract,
    };
  });
  actionDispatch.reduce((initial: AdminScheduledTasksState): AdminScheduledTasksState => {
    return {
      ...initial,
      createScheduledTaskDialogState: {
        ...EMPTY_CREATE_SCHEDULED_TASK_DIALOG_STATE,
        siteId: site.id,
        allCustomerSummaries,
        allStandIds: linearWorkflow,
        allWorkflowIds,
        customColumnDialogState: {
          ...EMPTY_CUSTOM_COLUMN_DIALOG_STATE,
        },
        documentColumnsToDownloadState: {
          selectedColumnId: '',
          columnsToDestinations: [],
          columnsToDestinationsDialogState: EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE,
        },
        textToDocumentColumnsToDownloadState: {
          selectedColumnId: '',
          columnsToDestinations: [],
          columnsToDestinationsDialogState: EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE,
        },
        textColumnsToAttributesState: {
          selectedColumnId: '',
          columnsToDestinations: [],
          columnsToDestinationsDialogState: EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE,
        },
        active: true,
      },
    };
  });
}

async function openEditCronTaskModalAction(
  {
    actionDispatch,
    customerRepository,
    getGlobalState,
  }: ActionContext<Store, AdminScheduledTasksState>,
  task: ScheduledTask
): Promise<void> {
  const { type: cronType } = task;
  const allCustomers = await customerRepository.getAllEntities();
  const allCustomerSummaries = allCustomers.map(({ id, shortName, contract }): CustomerSummary => {
    return {
      id,
      shortName,
      contract,
    };
  });
  const { siteConfiguration } = getGlobalState();
  const allWorkflowIds = siteConfiguration.workflows.map((w) => w.id);
  const allStandIds = siteConfiguration.stands.map((s) => s.id);
  switch (cronType) {
    case 'voLeanImport': {
      actionDispatch.reduce((initial: AdminScheduledTasksState) => {
        return {
          ...initial,
          updateScheduledTaskDialogState: {
            ...EMPTY_UPDATE_SCHEDULED_TASK_DIALOG_STATE,
            formData: getForUpdateVOLeanImportFormData(task),
            scheduledTaskId: task.id,
            type: cronType,
            active: true,
            allCustomerSummaries,
            allWorkflowIds,
          },
        };
      });
      break;
    }
    case 'airtableImport': {
      actionDispatch.reduce((initial: AdminScheduledTasksState): AdminScheduledTasksState => {
        const airtableConnectorArgs = task.scheduledTaskArgs as AirtableConnectorArgs;
        const { packageDealColumnsToHandle } = airtableConnectorArgs;
        return {
          ...initial,
          updateScheduledTaskDialogState: {
            ...EMPTY_UPDATE_SCHEDULED_TASK_DIALOG_STATE,
            formData: getForUpdateAirTableImportFormData(task),
            scheduledTaskId: task.id,
            type: cronType,
            active: true,
            allCustomerSummaries,
            allWorkflowIds,
            entityPresence: false,
            selectedPackageDealColumnToHandleId: '',
            customColumnDialogState: {
              ...EMPTY_CUSTOM_COLUMN_DIALOG_STATE,
              customColumns: packageDealColumnsToHandle ?? [],
            },
            documentColumnsToDownloadState: {
              selectedColumnId: '',
              columnsToDestinations: airtableConnectorArgs.documentColumnsToDownload ?? [],
              columnsToDestinationsDialogState: EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE,
            },
            textToDocumentColumnsToDownloadState: {
              selectedColumnId: '',
              columnsToDestinations: airtableConnectorArgs.textToDocumentColumnsToDownload ?? [],
              columnsToDestinationsDialogState: EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE,
            },
            textColumnsToAttributesState: {
              selectedColumnId: '',
              columnsToDestinations: airtableConnectorArgs.textColumnsToAttributes ?? [],
              columnsToDestinationsDialogState: EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE,
            },
          },
        };
      });
      break;
    }
    case 'invoiceMail': {
      actionDispatch.reduce((initial: AdminScheduledTasksState): AdminScheduledTasksState => {
        return {
          ...initial,
          updateScheduledTaskDialogState: {
            ...EMPTY_UPDATE_SCHEDULED_TASK_DIALOG_STATE,
            formData: getForUpdateInvoiceMailFormData(task),
            scheduledTaskId: task.id,
            type: cronType,
            active: true,
            allCustomerSummaries,
          },
        };
      });
      break;
    }
    case 'carsexpectedatstandmail': {
      actionDispatch.reduce((initial: AdminScheduledTasksState): AdminScheduledTasksState => {
        return {
          ...initial,
          updateScheduledTaskDialogState: {
            ...EMPTY_UPDATE_SCHEDULED_TASK_DIALOG_STATE,
            formData: getForUpdateCarsExpectedAtStandMailFormData(task),
            scheduledTaskId: task.id,
            type: cronType,
            active: true,
            allCustomerSummaries,
            allStandIds,
          },
        };
      });
      break;
    }
    case 'ctmail': {
      actionDispatch.reduce((initial: AdminScheduledTasksState): AdminScheduledTasksState => {
        return {
          ...initial,
          updateScheduledTaskDialogState: {
            ...EMPTY_UPDATE_SCHEDULED_TASK_DIALOG_STATE,
            formData: getForUpdateCustomersBasedMailFormData(task),
            scheduledTaskId: task.id,
            type: cronType,
            active: true,
            allCustomerSummaries,
          },
        };
      });
      break;
    }
    case 'dailyprodmail': {
      actionDispatch.reduce((initial: AdminScheduledTasksState): AdminScheduledTasksState => {
        return {
          ...initial,
          updateScheduledTaskDialogState: {
            ...EMPTY_UPDATE_SCHEDULED_TASK_DIALOG_STATE,
            formData: getForUpdateDailyProdMailFormData(task),
            scheduledTaskId: task.id,
            allStandIds,
            type: cronType,
            active: true,
            allCustomerSummaries,
          },
        };
      });
      break;
    }
    case 'sparepartsmail': {
      actionDispatch.reduce((initial: AdminScheduledTasksState): AdminScheduledTasksState => {
        return {
          ...initial,
          updateScheduledTaskDialogState: {
            ...EMPTY_UPDATE_SCHEDULED_TASK_DIALOG_STATE,
            formData: getForUpdateSparePartsMailFormData(task),
            scheduledTaskId: task.id,
            type: cronType,
            active: true,
            allCustomerSummaries,
          },
        };
      });
      break;
    }
    default:
      throw Error('Unsupported cronTask type for edition');
  }
}

function openDeleteCronTaskModalAction(
  { actionDispatch }: ActionContext<Store, AdminScheduledTasksState>,
  task: ScheduledTask
): void {
  actionDispatch.reduce((initial: AdminScheduledTasksState) => {
    return {
      ...initial,
      deleteScheduledTaskDialogState: {
        scheduledTaskId: task.id,
        scheduledTaskTaskLabel: task.label,
        active: true,
      },
    };
  });
}

function isScheduledOnTheGivenDay(
  scheduledWeekDays: readonly DAY_OF_WEEK[] | undefined,
  theGivenDay: DAY_OF_WEEK
): boolean {
  let result = false;
  if (!isTruthy(scheduledWeekDays)) {
    result = true;
  }
  if (isTruthy(scheduledWeekDays) && isTruthy((scheduledWeekDays as DAY_OF_WEEK[]).length)) {
    result = (scheduledWeekDays as DAY_OF_WEEK[]).includes(theGivenDay);
  }
  return result;
}

function getLocalizedTaskType(task: ScheduledTask): string {
  if (task && task.type) {
    return i18next.t(`adminScheduledTasks:cronTypes.${task.type}`);
  }
  return '';
}

function computeColumnDescs(
  t: TFunction,
  isEditionForbidden: boolean
): readonly ColumnDesc<Store, AdminScheduledTasksState, ScheduledTask>[] {
  return [
    {
      columnLabel: t('adminScheduledTasks:label'),
      columnType: 'display',
      id: 'label',
      propertyType: 'string',
      getPropertyValue: (task): string => task.label,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('adminScheduledTasks:type'),
      columnType: 'display',
      id: 'type',
      propertyType: 'string',
      getPropertyValue: (task): string => getLocalizedTaskType(task),
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('adminScheduledTasks:schedule.days'),
      columnType: 'display',
      id: 'days',
      propertyType: 'string',
      getPropertyValue: (task): string => {
        return enumerate(
          scheduledTaskHelpers
            .getActualDaysOfWeek(ALL_DAYS_OF_WEEK_ALIAS)
            .filter((day) => isScheduledOnTheGivenDay(task.schedule.weekday, day))
            .map((scheduledDay) => t(`adminScheduledTasks:schedule.days.${scheduledDay}`))
        );
      },
      getDisplayedValue: (task): JSX.Element => {
        return (
          <>
            {scheduledTaskHelpers
              .getActualDaysOfWeek(ALL_DAYS_OF_WEEK_ALIAS)
              .map((day): JSX.Element => {
                return (
                  <span
                    key={day}
                    style={
                      isScheduledOnTheGivenDay(task.schedule.weekday, day)
                        ? {
                            fontWeight: 'bold',
                            color: '#000000',
                          }
                        : { color: '#D3D3D3' }
                    }
                  >
                    {t(`adminScheduledTasks:schedule.day.${day}`)}
                  </span>
                );
              })}
          </>
        );
      },
      getDisplayedClassName: (): string => 'is-family-monospace',
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('adminScheduledTasks:schedule.hours'),
      columnType: 'display',
      id: 'hours',
      propertyType: 'string',
      isNotSortable: true,
      getPropertyValue: (task): string => getHoursOrMinutesAsStringArray(task.schedule.hour, t),
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('adminScheduledTasks:schedule.minutes'),
      columnType: 'display',
      id: 'arguments',
      propertyType: 'string',
      isNotSortable: true,
      getPropertyValue: (task): string => getHoursOrMinutesAsStringArray(task.schedule.min, t),
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('adminScheduledTasks:active'),
      columnType: 'display',
      id: 'active',
      propertyType: 'boolean',
      columnStyle: { width: '7em' },
      getPropertyValue: (task): boolean => task.active,
      isDisplayedByDefault: true,
    },
    {
      columnType: 'action',
      id: 'editCronTask',
      columnLabel: i18next.t('adminScheduledTasks:editCronTask'),
      columnIcon: {
        iconId: '',
        showText: false,
      },
      getCellIconId: (): string => {
        return 'edit';
      },
      cellTooltip: i18next.t('adminScheduledTasks:editCronTask'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      action: async (dispatch, task): Promise<void> => {
        await dispatch.exec(openEditCronTaskModalAction, task);
      },
      isDisplayedByDefault: true,
    },
    {
      columnType: 'action',
      id: 'deleteCronTask',
      columnLabel: i18next.t('adminScheduledTasks:deleteCronTask'),
      disabled: (): boolean => isEditionForbidden,
      columnIcon: {
        iconId: '',
        showText: false,
      },
      getCellIconId: (): string => {
        return 'trash';
      },
      cellTooltip: i18next.t('adminScheduledTasks:deleteCronTask'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      action: async (dispatch, task): Promise<void> => {
        await dispatch.exec(openDeleteCronTaskModalAction, task);
      },
      isDisplayedByDefault: true,
    },
  ];
}

function preInitializeAdminScheduledTasksState({
  actionDispatch,
}: ActionContext<Store, AdminScheduledTasksState>) {
  // Add a default sort option (sort by code)
  actionDispatch
    .scopeProperty('sortsMenuState')
    .scopeProperty('sorts')
    .setValue([
      { id: 'active', direction: 'UP' },
      { id: 'type', direction: 'UP' },
      { id: 'label', direction: 'UP' },
    ]);
}

async function tableContentProvider(ctx: StoreActionContext): Promise<readonly ScheduledTask[]> {
  return await ctx.httpClient.httpGetAsJson<readonly ScheduledTask[]>(
    CoreBackendRoutes.GET_ALL_SCHEDULED_TASKS
  );
}

export function AdminScheduledTasks({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const $ = $gs.$adminView.$adminScheduledTasks;

  const isEditionForbidden = !useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.SCHEDULED_TASKS_VIEW
  );

  const isOnline = useGetState($gs.$session.$isOnline);

  const columnDescs = useMemo(() => {
    return computeColumnDescs(t, isEditionForbidden);
  }, [t, isEditionForbidden]);

  const onScheduledTaskChangeActionCallback = useActionCallback(
    function _onScheduledTaskChangeActionCallback(
      ctx,
      addedOrUpdated: readonly ScheduledTask[],
      removedIds: readonly string[]
    ) {
      const { getState } = ctx;
      // Merge actual items (the same way as SSE updates for repository entities)
      const { items } = getState();

      const newItems = mergeArrayItems(items, addedOrUpdated, removedIds);
      applyAndFilterTableItemsAction(ctx, newItems, columnDescs);
    },
    [columnDescs],
    $
  );

  const asyncCleanupEffect = useSetCallback($, EMPTY_ADMIN_SCHEDULED_TASKS_STATE);

  useEffect((): (() => void) => {
    return (): void => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      asyncCleanupEffect();
    };
  }, [asyncCleanupEffect]);

  const showCreate = useActionCallback(openCreateCronTaskModalAction, [], $);

  const toobarConf = useGetDefaultTableToolbarConf(
    LocalStorageKeys.ADMIN_SCHEDULED_TASKS_COLUMNS_PREFERENCE,
    t('csvDownloadBaseFileName')
  );

  return (
    <DisplayContentOrPlaceholder
      displayCondition={isOnline}
      placeholder={t('warning.notAvailableIfOffline')}
    >
      <>
        <div className="level">
          <div className="level-left">
            <div>
              <p className="title is-2">{t('title')}</p>
              <p className="subtitle is-4">{t('subTitle')}</p>
            </div>
          </div>
          <div className="level-right">
            <div className="buttons">
              <Button
                label={t('createCronTask')}
                additionalClass="button"
                onClick={showCreate}
                tooltip={t('createCronTask')}
                disabled={isEditionForbidden}
              />
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Table
              $={$}
              columnDescs={columnDescs}
              contentProvider={tableContentProvider}
              preInitActionCallback={useActionCallback(
                preInitializeAdminScheduledTasksState,
                [],
                $
              )}
              noContentPlaceholder={t('warning.noTask')}
              isScrollable
              isTruncable
              tableClassName="table is-bordered is-narrow is-hoverable is-fullwidth"
              toolbar={toobarConf}
            />
          </div>
        </div>
        <UpdateScheduledTaskModal
          $={$}
          $gs={$gs}
          horizontalFormFields
          onScheduledTaskChangeActionCallback={onScheduledTaskChangeActionCallback}
          isEditionForbidden={isEditionForbidden}
        />
        <CreateScheduledTaskModal
          $={$}
          $gs={$gs}
          horizontalFormFields
          onScheduledTaskChangeActionCallback={onScheduledTaskChangeActionCallback}
        />
        <DeleteScheduledTaskModal
          $={$}
          onScheduledTaskChangeActionCallback={onScheduledTaskChangeActionCallback}
        />
      </>
    </DisplayContentOrPlaceholder>
  );
}
