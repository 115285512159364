import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { CheckFormFieldContentActions } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, isValidEmailAddressStructure } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { InputFormField, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { PaymentOrderModalState } from './typings/store.js';
import { extractMessageFromError } from './invoicingUtils.js';
import { EMPTY_PAYMENT_ORDER_MODAL_STATE } from './typings/store.js';

export function openPaymentOrderModalAction(
  { actionDispatch }: ActionContext<Store, PaymentOrderModalState>,
  purchaseOrderId: string,
  recipient: string,
  purchaseOrderLabel: string,
  amount: number,
  amountWithVAT: number,
  invoiceInfoId: string
): void {
  actionDispatch.setValue({
    ...EMPTY_PAYMENT_ORDER_MODAL_STATE,
    isActive: true,
    purchaseOrderId,
    purchaseOrderLabel,
    amount,
    amountWithVAT,
    invoiceInfoId,
    formData: {
      recipient,
      warnings: {},
    },
  });
}

function closeModalAction({ actionDispatch }: ActionContext<Store, PaymentOrderModalState>) {
  actionDispatch.applyPayload({
    ...EMPTY_PAYMENT_ORDER_MODAL_STATE,
  });
}

const checkFieldContentActions: CheckFormFieldContentActions<Store, PaymentOrderModalState> = {
  recipient: ({ value, t }): string | undefined => {
    if (!isValidEmailAddressStructure(value)) {
      return t('tabs.invoice.paymentOrder.invalidEmail');
    }
    return undefined;
  },
};

async function createPaymentOrderAction(
  {
    httpClient,
    getState,
    actionDispatch,
    globalActionDispatch,
  }: ActionContext<Store, PaymentOrderModalState>,
  kanbanId: string,
  recipient: string,
  t: TFunction
): Promise<void> {
  const { purchaseOrderId, amountWithVAT, invoiceInfoId } = getState();
  try {
    await httpClient.httpPostAsJSON(
      CoreBackendRoutes.CREATE_PAYMENT_ORDER(kanbanId, purchaseOrderId, 'MAIL', invoiceInfoId),
      { recipient }
    );

    globalActionDispatch.scopeProperty('message').setValue({
      type: 'info',
      title: t('tabs.invoice.paymentOrder.dialogTitleSuccess'),
      content: t('tabs.invoice.paymentOrder.dialogSuccessMessage', {
        amountWithVAT: amountWithVAT.toFixed(2).replace('.', ','),
        purchaseOrderId,
        recipient,
      }),
    });
  } catch (e) {
    globalActionDispatch.scopeProperty('message').setValue({
      type: 'warning',
      title: t('tabs.invoice.errorTitle'),
      content: extractMessageFromError(e as Error),
    });
  }
  return actionDispatch.setValue(EMPTY_PAYMENT_ORDER_MODAL_STATE);
}

type PaymentOrderModalProps = {
  readonly kanbanId: string;
  readonly $: StoreStateSelector<Store, PaymentOrderModalState>;
};

export function PaymentOrderModal({ kanbanId, $ }: PaymentOrderModalProps): JSX.Element {
  const [t] = useTranslation('details');
  const formWarning = useGetState($.$formWarning);

  const amount = useGetState($.$amount);
  const amountWithVAT = useGetState($.$amountWithVAT);
  const purchaseOrderLabel = useGetState($.$purchaseOrderLabel);
  const recipient = useGetState($.$formData.$recipient);

  const closeDialogCallback = useActionCallback(closeModalAction, [], $);
  const createPaymentOrderActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(createPaymentOrderAction, kanbanId, recipient, t);
    },
    [kanbanId, recipient, t],
    $
  );

  const purchaseOrderTitle = useMemo((): string => {
    return isTruthyAndNotEmpty(purchaseOrderLabel)
      ? purchaseOrderLabel
      : t('tabs.invoice.paymentOrder.noPurchaseNumber');
  }, [purchaseOrderLabel, t]);

  const fullPurchaseOrderTitle = useMemo(
    () =>
      t('tabs.invoice.paymentOrder.purchaseOrderTitle', {
        purchaseOrder: purchaseOrderTitle,
        invoiceAmountWithoutVAT: isTruthy(amount) ? amount.toFixed(2) : 'N/A',
        invoiceAmountWithVAT: isTruthy(amountWithVAT) ? amountWithVAT.toFixed(2) : 'N/A',
      }),
    [purchaseOrderTitle, amount, amountWithVAT, t]
  );

  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation({
    $,
    mandatoryFields: ['recipient'],
    checkFieldContentActions,
    submitValidDataAction: createPaymentOrderActionCallback,
    t,
  });
  return (
    <ModalCardDialog
      titleIconId="fa-solid fa-credit-card"
      title={t('tabs.invoice.paymentOrder.dialogTitle')}
      $active={$.$isActive}
      okLabel={t('tabs.invoice.paymentOrder.dialogButton')}
      onOkClicked={onFormSubmit}
      onCancelClicked={closeDialogCallback}
      warning={formWarning}
    >
      <label className="is-size-5 label" title={fullPurchaseOrderTitle}>
        {fullPurchaseOrderTitle}
      </label>
      <div className="columns mt-1 mb-1">
        <div className="column">
          <InputFormField
            title={t('tabs.invoice.paymentOrder.customerInfos')}
            label={t('tabs.invoice.paymentOrder.customerInfos')}
            $={$formWithChangeTrigger.$recipient}
          />
        </div>
      </div>
    </ModalCardDialog>
  );
}
