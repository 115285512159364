import type { JSX } from 'react';
import i18n from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ContractOrWorkflowIssue } from '@stimcar/core-libs-common';
import type {
  AttributeType,
  CarElement,
  Civility,
  PackageDeal,
  PackageDealDesc,
  PackageDealDescIssues,
  PurchaseOrder,
  Sequence,
  SparePartManagementType,
} from '@stimcar/libs-base';
import type { MarketplaceCategory, MarketplaceMandate } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { validateContractAndWorkflow } from '@stimcar/core-libs-common';
import { PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX } from '@stimcar/core-libs-repository';
import {
  arrayToMap,
  contractHelpers,
  EMPTY_KANBAN,
  enumerate,
  MARKETPLACE_BUY_PURCHASE_ORDER,
  MARKETPLACE_SALE_PURCHASE_ORDER,
  packageDealCreation,
  packageDealHelpers,
  purchaseOrderHelpers,
} from '@stimcar/libs-base';
import {
  isTruthy,
  MKTP_MANDATE_BUY,
  MKTP_MANDATE_SALE,
  MKTP_PKG_CATEGORY_NA,
  nonnull,
} from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../state/typings/store.js';
import type { CreateKanbanViewState, NewKanbanFormData } from '../typings/store.js';
import { EditGeneralInformationsForm } from '../../utils/generalInformations/EditGeneralInformationsForm.js';
import { useGetContractCodes } from '../../utils/useGetContract.js';

export function onSelectedContractChangeAction({
  getState,
  actionDispatch,
}: ActionContext<Store, CreateKanbanViewState>): void {
  const { attributesState, contract, isPredefinedCustomer } = getState();
  // Contract has changed, customer must be reset
  if (isPredefinedCustomer) {
    actionDispatch.applyPayload({
      isPredefinedCustomer: false,
      formData: {
        company: '',
        civility: 'notset',
        firstName: '',
        lastName: '',
        street: '',
        zipCode: '',
        city: '',
        email: '',
        phone: '',
        invoiceId: '0',
      },
    });
  }

  // Create attributes for contract
  const newAttributes: Record<string, AttributeType> = {};
  if (isTruthy(contract)) {
    contract.attributeDescs.forEach((ad) => {
      if (isTruthy(attributesState.attributes[ad.id])) {
        newAttributes[ad.id] = attributesState.attributes[ad.id];
      }
    });
  }
  actionDispatch.reduce((initial) => {
    return {
      ...initial,
      attributesState: {
        ...initial.attributesState,
        attributes: newAttributes,
        contractAttributeDescs: contract?.attributeDescs ?? [],
      },
    };
  });
}

function computeWorkflowWarning(
  validationIssue: ContractOrWorkflowIssue,
  contractCode: string,
  workflowId: string
): string | undefined {
  switch (validationIssue) {
    case 'unknownWorkflowId':
      return i18n.t('creation:unknownWorkflow');
    case 'unknownPkgDealCodesForKanban':
    case 'unknownPkgDealCodesForPurchaseOrder':
      return i18n.t('creation:workflowNotDefinedForContract', {
        contract: contractCode,
        workflow: workflowId,
      });
    default:
      return undefined;
  }
}

function computeContractWarning(
  validationIssue: ContractOrWorkflowIssue,
  contractCode: string
): string | undefined {
  if (validationIssue === 'unknownContract') {
    return i18n.t('creation:unknownContract', { contractCode });
  }
  return undefined;
}

function computeFormWarning({
  multipleCarElementsPddCodes,
  multipleValuesVariablesWithoutDefaultPddCodes,
}: PackageDealDescIssues): string | undefined {
  if (multipleCarElementsPddCodes.size > 0) {
    return i18n.t('creation:multipleCarElemError', {
      codes: enumerate([...multipleCarElementsPddCodes]),
    });
  }
  if (multipleValuesVariablesWithoutDefaultPddCodes.size > 0) {
    return i18n.t('creation:multipleDurationsError', {
      codes: enumerate([...multipleValuesVariablesWithoutDefaultPddCodes]),
    });
  }
  return undefined;
}

type MarketplacePurchaseOrderIds = {
  readonly buyPurchaseOrderId: string;
  readonly salePurchaseOrderId: string;
};

function getMarketplacePurchaseOrders(
  purchaseOrders: readonly PurchaseOrder[]
): MarketplacePurchaseOrderIds {
  const buyPurchaseOrderId = purchaseOrders.find(
    ({ purchaseNumber }) => purchaseNumber === MARKETPLACE_BUY_PURCHASE_ORDER
  )?.id;
  const salePurchaseOrderId = purchaseOrders.find(
    ({ purchaseNumber }) => purchaseNumber === MARKETPLACE_SALE_PURCHASE_ORDER
  )?.id;

  if (!isTruthy(buyPurchaseOrderId) || !isTruthy(salePurchaseOrderId)) {
    throw new Error('Can not retrieve marketplace purchase order');
  }
  return {
    buyPurchaseOrderId,
    salePurchaseOrderId,
  };
}

function getPackageDealDescCategory(
  packageDealDesc: PackageDealDesc,
  givenMarketplaceMandate: MarketplaceMandate
): MarketplaceCategory {
  if (givenMarketplaceMandate === MKTP_MANDATE_BUY) {
    return packageDealDesc.marketplaceBuyCategory;
  }
  if (givenMarketplaceMandate === MKTP_MANDATE_SALE) {
    return packageDealDesc.marketplaceSaleCategory;
  }
  return MKTP_PKG_CATEGORY_NA;
}

function getCorrespondingPurchaseOrderId(
  packageDealDesc: PackageDealDesc,
  givenMarketplaceMandate: MarketplaceMandate,
  marketplacePurchaseOrderIds: MarketplacePurchaseOrderIds
): string | undefined {
  const marketplaceCategory = getPackageDealDescCategory(packageDealDesc, givenMarketplaceMandate);
  switch (marketplaceCategory) {
    case 'REGISTRATION_FEES':
    case 'BUY_FIXED_FEES':
      return marketplacePurchaseOrderIds.buyPurchaseOrderId;
    case 'SALE_FIXED_FEES':
      return marketplacePurchaseOrderIds.salePurchaseOrderId;
    case 'NA':
    default:
      return undefined;
  }
}

function getCorrespondingCarElementIfUnique(
  packageDealDesc: PackageDealDesc,
  carElementsById: Map<string, CarElement>
): CarElement | undefined {
  if (packageDealDesc.carElementIds.length === 1) {
    return carElementsById.get(packageDealDesc.carElementIds[0]);
  }
  return undefined;
}

function createPackageDealsForMarketplace(
  givenMarketplaceMandate: MarketplaceMandate,
  packageDealDescs: readonly PackageDealDesc[],
  carElementsById: Map<string, CarElement>,
  purchaseOrders: readonly PurchaseOrder[],
  sparePartManagementType: SparePartManagementType,
  roundPriceTo: number,
  sequence: Sequence
): readonly PackageDeal[] {
  const marketplacePurchaseOrderIds = getMarketplacePurchaseOrders(purchaseOrders);
  return packageDealDescs
    .filter(
      (pdd) =>
        pdd.isMarketplace &&
        getPackageDealDescCategory(pdd, givenMarketplaceMandate) !== MKTP_PKG_CATEGORY_NA
    )
    .map((pdd) => {
      const correspondingPurchaseOrderId = getCorrespondingPurchaseOrderId(
        pdd,
        givenMarketplaceMandate,
        marketplacePurchaseOrderIds
      );
      const selectedCarElement = getCorrespondingCarElementIfUnique(pdd, carElementsById);
      return packageDealHelpers.createPackageDealFromPackageDealDesc(
        sequence,
        pdd,
        selectedCarElement,
        sparePartManagementType,
        {
          localVariableValues: null,
          globalVariableValues: null,
          packageDealStatus: 'available',
          roundPriceTo,
          purchaseOrderId: correspondingPurchaseOrderId,
        }
      );
    });
}

function addPurchaseOrdersForMarketplaceIfAbsent(
  existingPurchaseOrders: readonly PurchaseOrder[],
  sequence: Sequence
): readonly PurchaseOrder[] {
  const updatedPurchaseOrders = [...existingPurchaseOrders];

  const addPurchaseOrderIfAbsent = (purchaseNumber: string) => {
    if (!updatedPurchaseOrders.some((po) => po.purchaseNumber === purchaseNumber)) {
      const newPurchaseOrder = {
        id: sequence.next(),
        invoiceInfos: [],
        label: null,
        purchaseNumber,
      };
      updatedPurchaseOrders.push(newPurchaseOrder);
    }
  };

  addPurchaseOrderIfAbsent(MARKETPLACE_BUY_PURCHASE_ORDER);
  addPurchaseOrderIfAbsent(MARKETPLACE_SALE_PURCHASE_ORDER);

  return updatedPurchaseOrders;
}

/**
 * Executed when the user change the selected contract code in the UI.
 * It updates the automatic packageDeals and update the contract object stored in the state
 */
export async function selectedTypeOrContractOrWorkflowChangeAction({
  getState,
  actionDispatch,
  getGlobalState,
  httpClient,
  packageDealDescRepository,
  carElementRepository,
}: ActionContext<Store, CreateKanbanViewState>): Promise<void> {
  const sequence = httpClient.getBrowserSequence();
  const carElements = await carElementRepository.getAllEntities();
  const carElementsById = arrayToMap(carElements, ({ id }) => id);
  const { siteConfiguration, contracts } = getGlobalState();
  const {
    purchaseOrders: formPurchaseOrders,
    contract: stateContract,
    packageDeals: formPackageDeals,
  } = getState();
  const {
    workflowId,
    contractCode,
    brand,
    color,
    dateOfRegistration,
    license,
    mileage,
    model,
    motor,
    vin,
    city,
    civility,
    type,
    zipCode,
    company,
    email,
    firstName,
    invoiceId,
    ignoreVAT,
    lastName,
    phone,
    shortName,
    street,
    kanbanType,
    marketplaceMandate,
  } = getState().formData;

  let contract = stateContract;

  if (!isTruthy(stateContract) || stateContract.code !== contractCode) {
    contract = contracts.find((c) => c.code === contractCode);
  }
  if (!isTruthy(contract)) {
    // Reset data (contract is mandatory) and return
    actionDispatch.reduce((initial) => {
      return {
        ...initial,
        packageDeals: [],
        contract: undefined,
        packageDealDescs: [],
        formWarning: undefined,
        formData: {
          ...initial.formData,
          warnings: {},
        },
      };
    });
    return;
  }

  const packageDealDescs = await packageDealDescRepository.getEntitiesFromIndex(
    PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
    contract.packageDealDatabase
  );

  const initialPurchaseOrders =
    kanbanType === 'marketplace'
      ? addPurchaseOrdersForMarketplaceIfAbsent(formPurchaseOrders, sequence)
      : formPurchaseOrders;

  const initialPackageDeals =
    kanbanType === 'marketplace'
      ? createPackageDealsForMarketplace(
          marketplaceMandate,
          packageDealDescs,
          carElementsById,
          initialPurchaseOrders,
          contract.sparePartManagementType,
          contract.roundPriceTo,
          sequence
        )
      : [];

  const initialKanban = {
    ...EMPTY_KANBAN,
    infos: {
      brand,
      color,
      dateOfRegistration,
      license,
      mileage: Number.parseInt(mileage, 10),
      model,
      motor,
      vin,
    },
    purchaseOrders: initialPurchaseOrders,
    packageDeals: initialPackageDeals,
    workflowId,
    contract: contractHelpers.getKanbanContractFromUIContract(contract),
    customer: {
      city,
      civility: civility as Civility,
      company,
      email,
      firstName,
      individual: type === 'individual',
      invoiceId: Number.parseInt(invoiceId, 10),
      ignoreVAT,
      lastName,
      zipCode,
      phone,
      shortName,
      street,
    },
  };

  const validationIssue = validateContractAndWorkflow(contract, workflowId, siteConfiguration);

  if (isTruthy(validationIssue)) {
    const contractWarning = computeContractWarning(validationIssue, contractCode);
    const workflowWarning = computeWorkflowWarning(validationIssue, contractCode, workflowId);

    actionDispatch.reduce((initial) => ({
      ...initial,
      packageDeals: initialPackageDeals,
      purchaseOrders: initialPurchaseOrders,
      contract,
      packageDealDescs: filteredPDDs,
      formData: {
        ...initial.formData,
        warnings: {
          ...initial.formData.warnings,
          contract: contractWarning,
          workflowId: workflowWarning,
        },
      },
    }));
    return;
  }

  const { packageDeals, issues } =
    packageDealCreation.createDefaultPackageDealsForContractAndWorkflow(
      sequence,
      initialKanban,
      siteConfiguration,
      packageDealDescs,
      carElementsById,
      nonnull(contract),
      workflowId,
      'fr' // Only french is supported so far
    );

  const formWarning = computeFormWarning(issues);
  const filteredPDDs = packageDealDescs.filter(
    ({ id }) => packageDeals.find(({ packageDealDescId }) => packageDealDescId === id) !== undefined
  );

  // Avoid loss of user actions on the package deal list (deletion or price modification)
  const packageDealsWithUserActions = packageDeals.map((packageDeal) => {
    const packageDealWithUserActions = formPackageDeals.find(
      ({ code, purchaseOrderId }) =>
        packageDeal.code === code && packageDeal.purchaseOrderId === purchaseOrderId
    );
    if (packageDealWithUserActions) {
      return {
        ...packageDeal,
        deleted: packageDealWithUserActions.deleted,
        price: packageDealWithUserActions.price,
        priceIsOverridden: packageDealWithUserActions.priceIsOverridden,
      };
    }
    return packageDeal;
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial) => {
    return {
      ...initial,
      purchaseOrders: initialPurchaseOrders,
      packageDeals: packageDealsWithUserActions,
      contract,
      packageDealDescs: filteredPDDs,
      formWarning,
      formData: {
        ...initial.formData,
        warnings: {
          ...initial.formData.warnings,
        },
      },
    };
  });
}

export function purchaseOrderChangeAction({
  actionDispatch,
  httpClient,
  getState,
}: ActionContext<Store, CreateKanbanViewState>): void {
  const sequence = httpClient.getBrowserSequence();
  const { purchaseOrders: originalPurchaseOrder, formData } = getState();

  const newPurchaseOrders = purchaseOrderHelpers.getPurchaseOrdersFromString(
    formData.purchaseOrders,
    originalPurchaseOrder,
    sequence
  );
  actionDispatch.setProperty('purchaseOrders', newPurchaseOrders);
}

interface Props extends AppProps<Store> {
  readonly $formData: StoreStateSelector<Store, NewKanbanFormData>;
  readonly $: StoreStateSelector<Store, CreateKanbanViewState>;
}

export function CreationGeneralInformationsComponent({ $formData, $gs, $ }: Props): JSX.Element {
  const [t] = useTranslation('creation');

  const contractCodes = useGetContractCodes($gs);
  const workflows = useGetState($gs.$siteConfiguration.$workflows);

  const selectedTypeOrContractOrWorkflowChangeGlobalActionCallback = useActionCallback(
    selectedTypeOrContractOrWorkflowChangeAction,
    [selectedTypeOrContractOrWorkflowChangeAction],
    $
  );

  const purchaseOrderChangeGlobalActionCallback = useActionCallback(
    purchaseOrderChangeAction,
    [purchaseOrderChangeAction],
    $
  );

  const selectedContractChangeGlobalActionCallback = useActionCallback(
    onSelectedContractChangeAction,
    [onSelectedContractChangeAction],
    $
  );

  return (
    <div className="box">
      <p className="title">{t('kanbanInfos')}</p>
      <div className="columns">
        <EditGeneralInformationsForm
          $creationView={$gs.$creationView}
          $formData={$formData}
          $imageModal={$gs.$imageModal}
          $sessionIsOnline={$gs.$session.$isOnline}
          $sessionToken={$gs.$session.$infos.asDefined().$sessionToken}
          $windowWidth={$gs.$window.$width}
          contractCodes={contractCodes}
          workflows={workflows}
          selectedTypeOrContractOrWorkflowChangeGlobalActionCallback={
            selectedTypeOrContractOrWorkflowChangeGlobalActionCallback
          }
          selectedContractChangeGlobalActionCallback={selectedContractChangeGlobalActionCallback}
          purchaseOrderChangeGlobalActionCallback={purchaseOrderChangeGlobalActionCallback}
          allowDateOfRegistrationReset
        />
      </div>
    </div>
  );
}
