/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from '../FormField.js';
import { FormField } from '../FormField.js';
import type { ReactSelectProps } from './ReactSelect.js';
import { ReactSelect } from './ReactSelect.js';

export type ReactSelectFormFieldProps<SD extends AnyStoreDef, T extends string | number> = Omit<
  FormFieldProps,
  'type' | 'children' | 'warning'
> &
  ReactSelectProps<SD, T>;

export function ReactSelectFormField<SD extends AnyStoreDef, T extends string | number>({
  label,
  $,
  noExclamationTriangleIfWarning,
  suggestions,
  creation,
  isClearable,
  placeholder,
  hideDropdownIndicator,
  formatOptionLabel,
  isDisabled,
  horizontal,
}: ReactSelectFormFieldProps<SD, T>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($);
  return (
    <>
      <FormField
        label={label}
        warning={dispatchWarning}
        horizontal={horizontal}
        noExclamationTriangleIfWarning={noExclamationTriangleIfWarning}
      >
        <ReactSelect
          creation={creation}
          suggestions={suggestions}
          $={$}
          isClearable={isClearable}
          formatOptionLabel={formatOptionLabel}
          placeholder={placeholder}
          hideDropdownIndicator={hideDropdownIndicator}
          isDisabled={isDisabled}
        />
      </FormField>
    </>
  );
}
