import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Attachment,
  AttachmentDesc,
  SharedKanban,
  SharedUIContract,
  ShareUpdatePackageDealExpressionsResults,
  Site,
} from '@stimcar/libs-base';
import type { ActionContext, ActionDispatch, StoreStateSelector } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  ComputeAttachmentUrlCallback,
  EstimateViewState,
} from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  CoreBackendRoutes,
  electronicSignatureHelpers,
  expertiseHelpers,
  workflowHelpers,
} from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  Button,
  downloadAndSave,
  EMPTY_CHANGE_PACKAGE_DEAL_LINKED_PURCHASE_ORDER_MODAL_STATE,
  EstimateView,
  initializeSelectedPurchaseOrderTabAction,
  RawSwitch,
} from '@stimcar/libs-uitoolkit';
import type {
  KanbanShareEstimateTabState,
  SharePreferencesType,
  ShareStore,
} from '../state/typings/store.js';
import { SHARE_PREFERENCE_EMPTY_VALUE } from '../state/typings/store.js';
import {
  EstimateMailPreferencesModal,
  updateSharePreferences,
} from './EstimateMailPreferencesModal.js';
import { ExpertiseValidationSecondFactorMailInputAndModalComponent } from './SecondFactorComponents.js';

export async function initializeKanbanShareEstimateTab(
  {
    actionDispatch,
    keyValueStorage,
    httpClient,
  }: ActionContext<ShareStore, KanbanShareEstimateTabState>,
  shareId: string,
  kanban: SharedKanban
): Promise<void> {
  const sharePreferences = keyValueStorage.getObjectItem<SharePreferencesType>(
    LocalStorageKeys.SHARE_PREFERENCES
  );

  const selectedPackageDealIds =
    expertiseHelpers.deduceAvailablePackageDealsIdsFromActualStatusOrExpertRecommendation(
      kanban.packageDeals
    );
  const { updatedPackageDealsInformations, notReferencedSparePartIdsFromAvailablePackageDeals } =
    await httpClient.httpPostAsJSON<
      { selectedPackageDealIds: string[] },
      ShareUpdatePackageDealExpressionsResults
    >(CoreBackendRoutes.CUSTOMER_SIDE_UPDATE_PACKAGE_DEAL_EXPRESSIONS_COMPUTATIONS(shareId), {
      selectedPackageDealIds,
    });

  actionDispatch.reduce((initial): KanbanShareEstimateTabState => {
    return {
      ...initial,
      showPrice: isTruthy(sharePreferences)
        ? sharePreferences.estimate.showPrice
        : initial.showPrice,
      updatedPackageDealsInformations,
      notReferencedSparePartIdsFromAvailablePackageDeals,
      secondFactor: {
        ...initial.secondFactor,
        validationEmail: isTruthy(sharePreferences)
          ? sharePreferences.email
          : initial.secondFactor.validationEmail,
      },
    };
  });
  await (actionDispatch as ActionDispatch<ShareStore, EstimateViewState>).exec(
    initializeSelectedPurchaseOrderTabAction,
    kanban.purchaseOrders
  );
}

interface Props extends AppProps<ShareStore> {
  readonly shareId: string;
  readonly site: Site;
  readonly kanban: SharedKanban;
  readonly contract: SharedUIContract;
  readonly $: StoreStateSelector<ShareStore, KanbanShareEstimateTabState>;
  readonly computeAttachmentUrlCallback: ComputeAttachmentUrlCallback;
}

export function KanbanShareEstimateTab({
  $gs,
  shareId,
  site,
  kanban,
  contract,
  $,
  computeAttachmentUrlCallback,
}: Props): JSX.Element {
  const [t] = useTranslation('share');
  const { $imageModal } = $gs;
  const { $secondFactor, $mailPreferences } = $;

  const loadEstimateAttachmentsActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      const attachments = await httpClient.httpGetAsJson<readonly AttachmentDesc[]>(
        CoreBackendRoutes.SHARED_ATTACHMENT_FOLDER(shareId, 'kanban', 'expertise')
      );
      actionDispatch.setProperty(
        'availableAttachments',
        attachments.map(({ name }): Attachment => {
          return { id: name, folder: 'expertise', name };
        })
      );
    },
    [shareId],
    $
  );

  const downloadAttachmentsZipCallback = useActionCallback(
    async ({ httpClient }) =>
      await downloadAndSave(
        httpClient,
        CoreBackendRoutes.SHARED_ZIPPED_ATTACHMENT_FOLDER(shareId, 'kanban', 'expertise'),
        'download.zip'
      ),
    [shareId],
    $
  );

  const impossibleSignatureMessage = useMemo(() => {
    const { canBeSigned, reasonIfNot } = electronicSignatureHelpers.hasADocumentToSignByCustomer(
      kanban,
      site.configuration,
      'VEXP'
    );
    if (canBeSigned) {
      return undefined;
    }
    switch (reasonIfNot) {
      case 'isCurrentlyHandled':
        return t('status.kanbanCurrentlyHandled');
      case 'notReadyForSignature':
        return t('status.notReadyToEngage');
      case 'signatureAlreadyDone':
        return t('status.kanbanAlreadyEngaged');
      default:
        throw Error(`Unknown status ${reasonIfNot}`);
    }
  }, [kanban, site.configuration, t]);

  const showPriceToggleActionCallback = useActionCallback(
    ({ actionDispatch, getState, keyValueStorage }) => {
      const { showPrice } = getState();
      actionDispatch.applyPayload({ showPrice: !showPrice });
      updateSharePreferences(keyValueStorage, { estimate: { showPrice: !showPrice } });
    },
    [],
    $
  );

  const showPrice = useGetState($.$showPrice);

  const toolbarContributions = [
    <RawSwitch
      toggleAction={showPriceToggleActionCallback}
      isChecked={showPrice}
      switchId="estimateMode"
      text={t('estimateTab.showPriceToggle')}
    />,
  ];

  const openPreferencesModalCallback = useActionCallback(
    ({ actionDispatch, keyValueStorage }) => {
      const { estimate } =
        keyValueStorage.getObjectItem<SharePreferencesType>(LocalStorageKeys.SHARE_PREFERENCES) ??
        SHARE_PREFERENCE_EMPTY_VALUE;
      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          mailPreferences: {
            ...initial.mailPreferences,
            isActive: true,
            ccEmails: estimate.ccEmails,
          },
        };
      });
    },
    [],
    $
  );

  const togglePackageDealStatusCallback = useActionCallback(
    async ({ actionDispatch, getState, httpClient }, packageDealId: string) => {
      const { updatedPackageDealsInformations } = getState();
      const selectedPackageDealIds = updatedPackageDealsInformations
        .filter(({ id, status }) => {
          if (id === packageDealId) {
            return status === 'canceled';
          }
          return status === 'available';
        })
        .map(({ id }) => id);
      const {
        updatedPackageDealsInformations: updatedPackageDeals,
        notReferencedSparePartIdsFromAvailablePackageDeals,
      } = await httpClient.httpPostAsJSON<
        { selectedPackageDealIds: string[] },
        ShareUpdatePackageDealExpressionsResults
      >(CoreBackendRoutes.CUSTOMER_SIDE_UPDATE_PACKAGE_DEAL_EXPRESSIONS_COMPUTATIONS(shareId), {
        selectedPackageDealIds,
      });

      const updatedPackageDealsWithCurrentPurchaseOrderId = updatedPackageDeals.map(
        (packageDeal) => {
          const currentPurchaseOrderId = updatedPackageDealsInformations.find(
            ({ id }) => id === packageDeal.id
          )?.purchaseOrderId;
          return {
            ...packageDeal,
            purchaseOrderId: currentPurchaseOrderId ?? packageDeal.purchaseOrderId,
          };
        }
      );

      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          updatedPackageDealsInformations: updatedPackageDealsWithCurrentPurchaseOrderId,
          notReferencedSparePartIdsFromAvailablePackageDeals,
        };
      });
    },
    [shareId],
    $
  );

  const changePackageDealLinkedPurchaseOrderCallback = useActionCallback(
    ({ actionDispatch, getState }, packageDealId: string, purchaseOrderId: string) => {
      const { updatedPackageDealsInformations } = getState();
      const updatedPackageDeals = updatedPackageDealsInformations.map((packageDeal) =>
        packageDeal.id === packageDealId ? { ...packageDeal, purchaseOrderId } : packageDeal
      );
      actionDispatch.reduce((initial) => ({
        ...initial,
        updatedPackageDealsInformations: updatedPackageDeals,
        changePackageDealLinkedPurchaseOrderModalState:
          EMPTY_CHANGE_PACKAGE_DEAL_LINKED_PURCHASE_ORDER_MODAL_STATE,
      }));
    },
    [],
    $
  ) as (packageDealId: string, purchaseOrderId: string) => void;

  const unchangeablePackageDealCodes = useMemo(() => {
    return expertiseHelpers.unremovableByCustomerPackageDealCodes();
  }, []);

  const expertiseStandIds = useMemo(() => {
    const expertiseStandPerWorkflow = workflowHelpers.getExpertiseStandsByWorkflowId(
      site.configuration
    );
    return expertiseStandPerWorkflow[kanban.workflowId];
  }, [kanban.workflowId, site.configuration]);

  const updatedPackageDealsInformations = useGetState($.$updatedPackageDealsInformations);
  const notReferencedSparePartIdsFromAvailablePackageDeals = useGetState(
    $.$notReferencedSparePartIdsFromAvailablePackageDeals
  );

  return (
    <>
      <EstimateView
        $={$}
        kanban={kanban}
        address={site.configuration.infos.address}
        companyName={site.configuration.infos.companyName}
        logoUrl={site.configuration.infos.logoUrl}
        computeAttachmentUrl={computeAttachmentUrlCallback}
        $imageModal={$imageModal}
        loadAvailableAttachmentsEffect={loadEstimateAttachmentsActionCallback}
        downloadAttachmentsZipCallback={downloadAttachmentsZipCallback}
        toolbarContributions={toolbarContributions}
        bottomPlaceholderSize={30}
        dontShowLocalEstimateId
        showPrices={showPrice}
        attributeDescs={contract.attributeDescs}
        estimateMention={contract.estimateMention}
        memoDescs={contract.memoDescs}
        expertiseValidationToolkit={
          impossibleSignatureMessage
            ? undefined
            : {
                packageDealsUpdatedInformations: updatedPackageDealsInformations,
                togglePackageDealStatus: togglePackageDealStatusCallback,
                changePackageDealLinkedPurchaseOrder: changePackageDealLinkedPurchaseOrderCallback,
                unchangeablePackageDealCodes,
                expertiseStandIds,
                notReferencedSparePartIdsFromAvailablePackageDeals,
              }
        }
      />
      <div className="columns m-t-sm no-printing is-vcentered">
        <div className="column">
          <ExpertiseValidationSecondFactorMailInputAndModalComponent
            $={$secondFactor}
            kanban={kanban}
            shareId={shareId}
            businessMessage={impossibleSignatureMessage}
            updatedPackageDealsInformations={updatedPackageDealsInformations}
          />
        </div>
        <div className="column is-narrow">
          <Button
            label={t('estimateTab.mailPreferencesModal.openButton')}
            onClick={openPreferencesModalCallback}
            additionalClass="is-primary"
            isFullWidth
          />
        </div>
      </div>
      <EstimateMailPreferencesModal $={$mailPreferences} />
    </>
  );
}
