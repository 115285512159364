import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AuthenticationTypes } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
  FormFieldEntry,
  HorizontalFormFieldProps,
} from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, userHelpers } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  InputFormField,
  InputWithNumericKeyboardFormField,
  ModalCardDialog,
  ReadOnlyInputFormField,
  SelectFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type {
  UpdatePasswordData,
  UpdatePasswordDialogState,
} from '../registeredapp/components/authentication/typings/store.js';
import { EMPTY_EDIT_PASSWORD_DIALOG_STATE } from '../registeredapp/components/authentication/typings/store.js';
import type { Store } from './state/typings/store.js';
import { useIsWorkshopLogin } from './utils/useIsWorkshopLogin.js';

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  UpdatePasswordDialogState
  // eslint-disable-next-line @typescript-eslint/require-await
> = async ({ formState, t }): Promise<string | undefined> => {
  const { newPassword, repeatedNewPassword } = formState.formData;
  if (newPassword !== repeatedNewPassword) {
    return t('users.warning.passwordNotCorrectlyRepeated');
  }
  return undefined;
};

const checkFieldContentActions: CheckFormFieldContentActions<Store, UpdatePasswordDialogState> = {
  newPassword: ({ value, t, formState }): string | undefined => {
    const { updatedAuthenticationType } = formState.formData;
    if (updatedAuthenticationType === 'pin') {
      if (!userHelpers.isCorrectPINCode(value)) {
        return t('users.warning.incorrectPIN');
      }
    }
    return undefined;
  },
};

async function updatePasswordFromAdminAction({
  getState,
  httpClient,
  actionDispatch,
}: ActionContext<Store, UpdatePasswordDialogState>): Promise<void> {
  const { formData, userLogin } = getState();
  const { newPassword, updatedAuthenticationType } = formData;
  await httpClient.httpPostAsJSON(CoreBackendRoutes.UPDATE_PASSWORD(userLogin), {
    newPassword,
    authenticationType: updatedAuthenticationType,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial: UpdatePasswordDialogState) => {
    return {
      ...EMPTY_EDIT_PASSWORD_DIALOG_STATE,
    };
  });
}

async function updateOwnPasswordAction({
  getState,
  httpClient,
  actionDispatch,
}: ActionContext<Store, UpdatePasswordDialogState>): Promise<void> {
  const { formData } = getState();
  const { newPassword, existingPassword, updatedAuthenticationType } = formData;

  await httpClient.updatePassword(existingPassword, newPassword, updatedAuthenticationType);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial: UpdatePasswordDialogState) => {
    return {
      ...EMPTY_EDIT_PASSWORD_DIALOG_STATE,
    };
  });
}

const ADMIN_MANDATORY_FIELDS: (keyof UpdatePasswordData)[] = ['newPassword', 'repeatedNewPassword'];
const NON_ADMIN_MANDATORY_FIELDS: (keyof UpdatePasswordData)[] = [
  'newPassword',
  'repeatedNewPassword',
  'existingPassword',
];

export interface UpdatePasswordModalProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, UpdatePasswordDialogState>;
  readonly updateFromAdminView?: boolean;
}

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  labelFlexGrow: 1,
  bodyFlexGrow: 1,
};

export function UpdatePasswordModal({
  $gs,
  $,
  updateFromAdminView = false,
}: UpdatePasswordModalProps): JSX.Element {
  const [t] = useTranslation('usersAdmin');

  const isWorkshopLogin = useIsWorkshopLogin($gs);

  const formWarning = useGetState($.$formWarning);
  const userLogin = useGetState($.$userLogin);
  const submitValidDataAction = useActionCallback(updatePasswordFromAdminAction, [], $);
  const submitOwnValidDataAction = useActionCallback(updateOwnPasswordAction, [], $);

  const asyncEffect = useActionCallback(
    ({ actionDispatch }) => {
      if (isWorkshopLogin) {
        actionDispatch.scopeProperty('formData').setProperty('updatedAuthenticationType', 'pin');
      }
    },
    [isWorkshopLogin],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    UpdatePasswordDialogState
  >({
    $,
    mandatoryFields: updateFromAdminView ? ADMIN_MANDATORY_FIELDS : NON_ADMIN_MANDATORY_FIELDS,
    checkFieldContentActions,
    checkFormConsistencyAction,
    submitValidDataAction: updateFromAdminView ? submitValidDataAction : submitOwnValidDataAction,
    t,
  });

  const authenticationFormFields = useMemo((): FormFieldEntry<AuthenticationTypes>[] => {
    return [
      {
        label: t('users.updatePassword.passwordAuthenticationType'),
        id: 'password',
      },
      {
        label: t('users.updatePassword.pinAuthenticationType'),
        id: 'pin',
      },
    ];
  }, [t]);

  const updatedAuthenticationType = useGetState(
    $formDataWithChangeTrigger.$updatedAuthenticationType
  );
  const isPinUpdate = updatedAuthenticationType === 'pin';

  const isOnline = useGetState($gs.$session.$isOnline);

  return (
    <ModalCardDialog
      $active={$.$active}
      titleIconId="exclamation-triangle"
      title={t('users.updatePassword.title')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
      isReadonly={!isOnline}
    >
      <ReadOnlyInputFormField
        label={t('users.updatePassword.login')}
        value={userLogin}
        horizontal={HORIZONTAL_PROPS}
      />
      {!isWorkshopLogin ? (
        <>
          <SelectFormField
            label={t('users.updatePassword.authenticationTypeLabel')}
            entries={authenticationFormFields}
            $={$formDataWithChangeTrigger.$updatedAuthenticationType}
            horizontal={HORIZONTAL_PROPS}
          />
          <UsersWithkeyboardFormFields
            $existingPassword={$formDataWithChangeTrigger.$existingPassword}
            $newPassword={$formDataWithChangeTrigger.$newPassword}
            $repeatedNewPassword={$formDataWithChangeTrigger.$repeatedNewPassword}
            isPinUpdate={isPinUpdate}
            updateFromAdminView={updateFromAdminView}
            horizontalFormFields={HORIZONTAL_PROPS}
          />
        </>
      ) : (
        <WorkshopUsersFormFields
          $existingPassword={$formDataWithChangeTrigger.$existingPassword}
          $newPassword={$formDataWithChangeTrigger.$newPassword}
          $repeatedNewPassword={$formDataWithChangeTrigger.$repeatedNewPassword}
          isPinUpdate={isPinUpdate}
          updateFromAdminView={updateFromAdminView}
          horizontalFormFields={HORIZONTAL_PROPS}
        />
      )}
    </ModalCardDialog>
  );
}

interface UsersFormFieldsProps {
  readonly updateFromAdminView: boolean;
  readonly isPinUpdate: boolean;
  readonly $existingPassword: StoreStateSelector<Store, string>;
  readonly $newPassword: StoreStateSelector<Store, string>;
  readonly $repeatedNewPassword: StoreStateSelector<Store, string>;
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}

function WorkshopUsersFormFields({
  updateFromAdminView,
  $existingPassword,
  $newPassword,
  $repeatedNewPassword,
  horizontalFormFields,
}: UsersFormFieldsProps): JSX.Element {
  const [t] = useTranslation('usersAdmin');

  return (
    <>
      {!updateFromAdminView && (
        <InputWithNumericKeyboardFormField
          type="password"
          label={t('users.updatePassword.existingPIN')}
          placeholder={t('users.updatePassword.existingPIN')}
          $={$existingPassword}
          isKeyboardOnOneLine
          horizontal={horizontalFormFields}
        />
      )}
      <InputWithNumericKeyboardFormField
        type="password"
        label={t('users.updatePassword.newPIN')}
        placeholder={t('users.updatePassword.newPIN')}
        $={$newPassword}
        isKeyboardOnOneLine
        horizontal={horizontalFormFields}
      />
      <InputWithNumericKeyboardFormField
        type="password"
        label={t('users.updatePassword.confirmNewPIN')}
        placeholder={t('users.updatePassword.confirmNewPIN')}
        $={$repeatedNewPassword}
        isKeyboardOnOneLine
        horizontal={horizontalFormFields}
      />
    </>
  );
}

function UsersWithkeyboardFormFields({
  updateFromAdminView,
  isPinUpdate,
  $existingPassword,
  $newPassword,
  $repeatedNewPassword,
  horizontalFormFields,
}: UsersFormFieldsProps): JSX.Element {
  const [t] = useTranslation('usersAdmin');
  return (
    <>
      {!updateFromAdminView && (
        <InputFormField
          type="password"
          label={
            isPinUpdate
              ? t('users.updatePassword.existingPIN')
              : t('users.updatePassword.existingPassword')
          }
          placeholder={
            isPinUpdate
              ? t('users.updatePassword.existingPIN')
              : t('users.updatePassword.existingPassword')
          }
          $={$existingPassword}
          horizontal={horizontalFormFields}
        />
      )}
      <InputFormField
        type="password"
        label={
          isPinUpdate ? t('users.updatePassword.newPIN') : t('users.updatePassword.newPassword')
        }
        placeholder={
          isPinUpdate ? t('users.updatePassword.newPIN') : t('users.updatePassword.newPassword')
        }
        $={$newPassword}
        horizontal={horizontalFormFields}
      />
      <InputFormField
        type="password"
        label={
          isPinUpdate
            ? t('users.updatePassword.confirmNewPIN')
            : t('users.updatePassword.confirmNewPassword')
        }
        placeholder={
          isPinUpdate
            ? t('users.updatePassword.confirmNewPIN')
            : t('users.updatePassword.confirmNewPassword')
        }
        $={$repeatedNewPassword}
        horizontal={horizontalFormFields}
      />
    </>
  );
}
