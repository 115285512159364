import type { Kanban, WithProgress } from '@stimcar/libs-base';
import type {
  ExpandedKanbanStatuses,
  PdfCreationAndUploadModalState,
  TableState,
} from '@stimcar/libs-uitoolkit';
import {
  PDF_CREATION_AND_UPLOAD_MODAL_EMPTY_STATE,
  TABLE_EMPTY_STATE,
} from '@stimcar/libs-uitoolkit';

export type SelectKanbanState = TableState<WithProgress<Kanban>> & {
  readonly handleKanbanMenuActive: boolean;
  readonly selectedStands: readonly string[];
  readonly pdfCreationAndUploadModal: PdfCreationAndUploadModalState;
  readonly expandedKanbanStatuses: ExpandedKanbanStatuses;
};

export const SELECT_KANBAN_EMPTY_STATE: SelectKanbanState = {
  ...TABLE_EMPTY_STATE,
  handleKanbanMenuActive: false,
  selectedStands: [],
  pdfCreationAndUploadModal: PDF_CREATION_AND_UPLOAD_MODAL_EMPTY_STATE,
  expandedKanbanStatuses: {},
};
