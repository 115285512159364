import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { ActionCallbackFromFunction } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { KanbanListWithSearch } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { ARCHIVE_FULL_PATH, computeArchiveDetailsPath } from '../../coreConstants.js';
import { KanbanDetails } from '../../details/KanbanDetails.js';
import { useSearchArchivesWarning } from './useSearchArchivesWarning.js';

interface Props extends AppProps<Store> {
  readonly runSearchActionCallback: ActionCallbackFromFunction<
    Store,
    (page: number) => Promise<void>
  >;
}

export function MobileArchivesViewComponent({ $gs, runSearchActionCallback }: Props): JSX.Element {
  const [t] = useTranslation('archives');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { id } = useParams<any>();
  const $ = $gs.$archivesView;

  const setSelectedKanban = useActionCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async ({ navigate }, kanbanId: string): Promise<void> => {
      navigate(computeArchiveDetailsPath(kanbanId));
    },
    [],
    $
  );

  const goToDetailsCallback = useActionCallback(
    ({ getState, navigate }) => {
      const { selectedKanban } = getState();
      if (isTruthy(selectedKanban)) {
        navigate(computeArchiveDetailsPath(selectedKanban.id));
      }
    },
    [],
    $.$detailsState
  );

  const goToListCallback = useActionCallback(
    ({ navigate }) => {
      navigate(ARCHIVE_FULL_PATH);
    },
    [],
    $
  );

  const isOnline = useGetState($gs.$session.$isOnline);
  const kanbanColorationCharter = useGetState(
    $gs.$siteConfiguration.$displayConfiguration.$kanbanColorationCharter
  );
  const searchText = useGetState($gs.$archivesView.$searchText);
  const filteredKanbans = useGetState($gs.$archivesView.$filteredKanbans);
  const searchShouldBeRerun = useGetState($gs.$archivesView.$searchShouldBeRerun);
  const pageCount = useGetState($gs.$archivesView.$pageCount);
  const totalFound = useGetState($gs.$archivesView.$totalFound);
  const activePage = useGetState($gs.$archivesView.$activePage);
  const warning = useSearchArchivesWarning(t, searchText, isOnline);

  const selectedKanban = useGetState($gs.$archivesView.$detailsState.$selectedKanban);

  return (
    <>
      <div className="tabs is-centered">
        <ul>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <li className={isTruthyAndNotEmpty(id) ? '' : 'is-active'} onClick={goToListCallback}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>{t('mobile.list')}</a>
          </li>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <li className={isTruthyAndNotEmpty(id) ? 'is-active' : ''} onClick={goToDetailsCallback}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
              {isTruthy(selectedKanban) ? selectedKanban.infos.license : t('mobile.emptyDetails')}
            </a>
          </li>
        </ul>
      </div>
      {isTruthyAndNotEmpty(id) ? (
        <KanbanDetails $={$gs.$archivesView.$detailsState} $gs={$gs} addKanbanInNavbar={false} />
      ) : (
        <KanbanListWithSearch
          kanbans={filteredKanbans}
          $searchText={$.$searchText}
          runSearchActionCallback={runSearchActionCallback}
          pageCount={pageCount}
          totalFound={totalFound}
          activePage={activePage}
          maxPageButtons={7}
          searchShouldBeRerun={searchShouldBeRerun}
          selectKanban={setSelectedKanban}
          warning={warning}
          kanbanColorationCharter={kanbanColorationCharter}
          selectedKanbanId={selectedKanban?.id}
        />
      )}
    </>
  );
}
