import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { KanbanRepository } from '@stimcar/core-libs-repository';
import type { Kanban, RepositoryEntityPayload } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { compareStrings, nonDeleted } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { PackageDealCodeAndLabel, PurchaseOrderDeletionModalState } from './typings/store.js';

async function getAllocatedPackageDeals(
  kanbanRepository: KanbanRepository,
  givenKanbanId: string,
  givenPurchaseOrderId: string
): Promise<readonly PackageDealCodeAndLabel[]> {
  const currentKanban = await kanbanRepository.getEntity(givenKanbanId);
  return currentKanban.packageDeals
    .filter(nonDeleted)
    .filter(({ purchaseOrderId }) => purchaseOrderId === givenPurchaseOrderId)
    .map(({ id, code, label }) => ({
      id,
      code,
      label,
    }));
}

function computeDeletionPayload(
  kanbanId: string,
  purchaseOrderId: string,
  allocatedPackageDeals: readonly PackageDealCodeAndLabel[]
): RepositoryEntityPayload<Kanban> {
  const purchaseOrderChange: DeepPartial<Kanban> = {
    purchaseOrders: [
      {
        id: purchaseOrderId,
        deleted: true,
      },
    ],
  };

  if (allocatedPackageDeals.length > 0) {
    return {
      entityId: kanbanId,
      payload: {
        ...purchaseOrderChange,
        packageDeals: allocatedPackageDeals.map(({ id }) => ({
          id,
          deleted: true,
        })),
      },
    };
  }
  return {
    entityId: kanbanId,
    payload: {
      ...purchaseOrderChange,
    },
  };
}

export async function openPurchaseOrderDeletionModalAction(
  { actionDispatch, kanbanRepository }: ActionContext<Store, PurchaseOrderDeletionModalState>,
  givenKanbanId: string,
  givenPurchaseOrderId: string
): Promise<void> {
  const allocatedPackageDeals = await getAllocatedPackageDeals(
    kanbanRepository,
    givenKanbanId,
    givenPurchaseOrderId
  );
  actionDispatch.setValue({
    isActive: true,
    purchaseOrderId: givenPurchaseOrderId,
    allocatedPackageDeals,
  });
}

type PurchaseOrderDeleteModalProps = {
  readonly $: StoreStateSelector<Store, PurchaseOrderDeletionModalState>;
  readonly kanbanId: string;
};

export function PurchaseOrderDeleteModal({
  $,
  kanbanId,
}: PurchaseOrderDeleteModalProps): JSX.Element {
  const [t] = useTranslation('details');
  const allocatedPackageDeals = useGetState($.$allocatedPackageDeals);
  const purchaseOrderId = useGetState($.$purchaseOrderId);

  const purchaseOrderDeleteSubmitActionCallback = useActionCallback(
    async ({ actionDispatch, kanbanRepository }) => {
      const allocatedPackageDeals = await getAllocatedPackageDeals(
        kanbanRepository,
        kanbanId,
        purchaseOrderId
      );

      const payload = computeDeletionPayload(kanbanId, purchaseOrderId, allocatedPackageDeals);
      await kanbanRepository.updateEntityFromPayload(payload);

      // Hide modal
      actionDispatch.setProperty('isActive', false);
    },
    [kanbanId, purchaseOrderId],
    $
  );

  const sortedAllocatedPackageDeals = useMemo(
    () =>
      [...allocatedPackageDeals].sort((pkg1, pkg2) => compareStrings(pkg1.code, pkg2.code, 'UP')),
    [allocatedPackageDeals]
  );

  return (
    <ModalCardDialog
      title={t('tabs.invoice.purchaseOrderDeletionModal.title')}
      $active={$.$isActive}
      okLabel={t('tabs.invoice.refundInvoice.okButtonLabel')}
      onOkClicked={purchaseOrderDeleteSubmitActionCallback}
    >
      {sortedAllocatedPackageDeals.length > 0 ? (
        <>
          <div>{t('tabs.invoice.purchaseOrderDeletionModal.packageDealDeletionWarning')}</div>
          <div>
            {sortedAllocatedPackageDeals.map(({ code, label }) => (
              <>
                {t('tabs.invoice.purchaseOrderDeletionModal.allocatedPackageDealLine', {
                  code,
                  label,
                })}
                <br />
              </>
            ))}
          </div>
          <br />
          <div>{t('tabs.invoice.purchaseOrderDeletionModal.purchaseOrderDeletionMessage')}</div>
        </>
      ) : (
        t('tabs.invoice.purchaseOrderDeletionModal.purchaseOrderDeletionMessage')
      )}
    </ModalCardDialog>
  );
}
