import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { kanbanHelpers, packageDealHelpers } from '@stimcar/libs-base';
import { keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, ModalCardDialog, ShowEstimateButton } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type {
  OperatorExpertViewValidationErrors,
  OperatorViewState,
  WorkStatus,
} from '../../typings/store.js';
import {
  clearOperatorViewAndNavigateToSelectionAction,
  closeKanbanHandleOnPost,
  pauseWorkAction,
  startWorkAction,
  unpauseWorkAction,
} from '../../../utils/operatorUtils.js';
import { useGetContractByCode } from '../../../utils/useGetContract.js';
import { addValidationOperation } from '../../expertiseUtils.js';

async function finishWithoutValidation(
  {
    actionDispatch,
    httpClient,
    kanbanRepository,
    getGlobalState,
    getState,
  }: ActionContext<Store, OperatorViewState>,
  kanban: Kanban,
  standId: string
): Promise<void> {
  const { siteConfiguration, session } = getGlobalState();
  let kanbanWithValidationAndReferenceOperation = addValidationOperation(
    httpClient.getBrowserSequence(),
    kanban,
    siteConfiguration
  );

  const { initialKanban } = getState().expertOperatorState;
  const initialPds = initialKanban.packageDeals ?? [];
  const newlyAvailablePds = packageDealHelpers.findNewPackageDealsWithStatus(
    initialPds,
    kanbanWithValidationAndReferenceOperation.packageDeals,
    'available'
  );
  const noSparePartReferenceOperationAtAll =
    !packageDealHelpers.isThereASparePartsReferenceOperation(kanban.packageDeals);
  const referenceNeeded = packageDealHelpers.doPdsContainSparePartsToReferenced(
    [...initialPds, ...newlyAvailablePds],
    true
  );
  if (noSparePartReferenceOperationAtAll || referenceNeeded) {
    kanbanWithValidationAndReferenceOperation = {
      ...kanbanWithValidationAndReferenceOperation,
      packageDeals: packageDealHelpers.addSparePartReferenceOperationToExpertise(
        httpClient.getBrowserSequence(),
        kanbanWithValidationAndReferenceOperation.packageDeals,
        siteConfiguration,
        !referenceNeeded,
        session.user?.login ?? null
      ),
    };
  }
  const stoppedKanban = closeKanbanHandleOnPost(
    nonnull(session.infos?.id),
    kanbanWithValidationAndReferenceOperation,
    session.user?.permissions ?? {},
    standId,
    session.user?.login ?? null
  );
  await kanbanRepository.updateEntity(stoppedKanban);
  await actionDispatch.exec(clearOperatorViewAndNavigateToSelectionAction);
}

interface Props extends AppProps<Store> {
  readonly kanban: Kanban;
  readonly hasLocalChanges: boolean;
  readonly postId: string;
  readonly getValidationErrors: (k: Kanban) => OperatorExpertViewValidationErrors;
  readonly standId: string;
  readonly showEstimateCallback: () => Promise<void> | void;
  readonly $: StoreStateSelector<Store, OperatorViewState>;
}

export function ExpertComponentButtons({
  $,
  $gs,
  kanban,
  hasLocalChanges,
  postId,
  getValidationErrors,
  standId,
  showEstimateCallback,
}: Props): JSX.Element {
  const [t] = useTranslation('operators');

  const { $expertOperatorState } = $;

  const finishClickHandler = useActionCallback(
    async ({ actionDispatch }) => {
      const validationErrors = getValidationErrors(kanban);
      const numberOfErrors = Object.values(validationErrors).flat().length;
      if (numberOfErrors > 0) {
        actionDispatch.scopeProperty('expertOperatorState').reduce((initial) => {
          return {
            ...initial,
            validationErrors,
            showValidationErrorsModal: true,
          };
        });
      } else {
        await actionDispatch.exec(finishWithoutValidation, kanban, standId);
      }
    },
    [getValidationErrors, kanban, standId],
    $
  );

  const contract = useGetContractByCode($gs, kanban.contract.code);

  const displayContractHintsClickHandler = useActionCallback(
    ({ globalActionDispatch }) => {
      if (contract) {
        globalActionDispatch.setProperty('message', contract?.expertHints);
      }
    },
    [contract],
    $
  );

  const workStatus = useGetState($.$workStatus);

  const isStartButtonDisabled = useMemo((): boolean => {
    if (
      workStatus === 'finished' &&
      kanbanHelpers.getOpenOperatorHandleForPost(kanban, postId) === undefined
    ) {
      return true;
    }
    return false;
  }, [workStatus, kanban, postId]);

  const isFinishButtonDisabled = useMemo((): boolean => {
    if (kanban === undefined) {
      return true;
    }
    if (
      isStartButtonDisabled ||
      (workStatus !== 'finished' &&
        kanbanHelpers.getOpenOperatorHandleForPost(kanban, postId) === undefined)
    ) {
      return true;
    }
    return false;
  }, [workStatus, kanban, postId, isStartButtonDisabled]);

  const [finishButtonLabel, finishButtonTooltip] = useMemo((): string[] => {
    if (hasLocalChanges) {
      return [t('timer.saveAndFinish'), t('timer.saveAndFinishTooltip')];
    }
    return [t('timer.finish'), t('timer.finishTooltip')];
  }, [hasLocalChanges, t]);

  const validationErrors = useGetState($.$expertOperatorState.$validationErrors);
  return (
    <>
      <div className="columns is-gap-medium is-multiline">
        <div className="column is-half">
          <ShowEstimateButton onClick={showEstimateCallback} />
        </div>
        <div className="column is-half">
          <ExpertStartButton
            hasLocalChanges={hasLocalChanges}
            isButtonDisabled={isStartButtonDisabled}
            kanban={kanban}
            standId={standId}
            $={$}
            workStatus={workStatus}
          />
        </div>
        <div className="column is-half">
          <ExpertInterruptButton
            hasLocalChanges={hasLocalChanges}
            isButtonDisabled={isFinishButtonDisabled}
            kanban={kanban}
            $={$}
            standId={standId}
          />
        </div>
        <div className="column is-half">
          <Button
            tooltip={finishButtonTooltip}
            onClick={finishClickHandler}
            disabled={isFinishButtonDisabled}
            label={finishButtonLabel}
            iconId="check"
            isFullWidth
          />
        </div>
        {contract && (
          <div className="column">
            <Button
              isFullWidth
              iconId="file-lines"
              label={t('timer.contractHints')}
              tooltip={t('timer.contractHints')}
              onClick={displayContractHintsClickHandler}
            />
          </div>
        )}
      </div>
      <ModalCardDialog
        $active={$expertOperatorState.$showValidationErrorsModal}
        title={t('timer.validationErrorModalTitle')}
      >
        <p>{t('timer.validationErrorModalMessage')}</p>
        {keysOf(validationErrors).map(
          (key) =>
            validationErrors[key].length > 0 && (
              <Fragment key={key}>
                <p className="is-underlined">{t(`timer.validationErrorCategoryTitles.${key}`)}</p>
                <ul>
                  {validationErrors[key].map(
                    (error): JSX.Element => (
                      <li key={error}>{error}</li>
                    )
                  )}
                </ul>
              </Fragment>
            )
        )}
      </ModalCardDialog>
    </>
  );
}

interface StartButtonProps {
  readonly workStatus: WorkStatus;
  readonly kanban: Kanban;
  readonly hasLocalChanges: boolean;
  readonly isButtonDisabled: boolean;
  readonly standId: string;
  readonly $: StoreStateSelector<Store, OperatorViewState>;
}

export function ExpertStartButton({
  workStatus,
  hasLocalChanges,
  kanban,
  isButtonDisabled,
  standId,
  $,
}: StartButtonProps): JSX.Element {
  const [t] = useTranslation('operators');

  const startPauseUnpauseClickHandler = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, OperatorViewState>) => {
      if (workStatus === 'unstarted') {
        await actionDispatch.exec(startWorkAction, kanban, standId);
      } else if (workStatus === 'started') {
        await actionDispatch.exec(pauseWorkAction, kanban);
      } else if (workStatus === 'paused') {
        await actionDispatch.exec(unpauseWorkAction, kanban);
      }
    },
    [kanban, workStatus, standId],
    $
  );

  const [startButtonLabel, startButtonTooltip, startButtonIconId] = useMemo((): string[] => {
    switch (workStatus) {
      case 'paused':
        return [t('timer.unpause'), t('timer.unpauseTooltip'), 'play'];
      case 'started':
        if (hasLocalChanges) {
          return [t('timer.saveAndPause'), t('timer.saveAndPauseTooltip'), 'pause'];
        }
        return [t('timer.pause'), t('timer.pauseTooltip'), 'pause'];
      default:
        return [t('timer.start'), t('timer.startTooltip'), 'play'];
    }
  }, [workStatus, hasLocalChanges, t]);

  return (
    <Button
      tooltip={startButtonTooltip}
      label={startButtonLabel}
      onClick={startPauseUnpauseClickHandler}
      disabled={isButtonDisabled}
      iconId={startButtonIconId}
      isFullWidth
    />
  );
}

interface InterruptButtonProps {
  readonly kanban: Kanban;
  readonly hasLocalChanges: boolean;
  readonly isButtonDisabled: boolean;
  readonly $: StoreStateSelector<Store, OperatorViewState>;
  readonly standId: string;
}

export function ExpertInterruptButton({
  hasLocalChanges,
  kanban,
  isButtonDisabled,
  $,
}: InterruptButtonProps): JSX.Element {
  const [t] = useTranslation('operators');

  const interruptClickHandler = useActionCallback(
    async ({
      actionDispatch,
      getGlobalState,
      kanbanRepository,
    }: ActionContext<Store, OperatorViewState>) => {
      const { session } = getGlobalState();
      const interruptedKanban = closeKanbanHandleOnPost(
        nonnull(session.infos?.id),
        kanban,
        // No operations will be finished for interrupt
        {},
        undefined,
        session.user?.login ?? null
      );

      await kanbanRepository.updateEntity(interruptedKanban);
      await actionDispatch.exec(clearOperatorViewAndNavigateToSelectionAction);
    },
    [kanban],
    $
  );

  const [interrupt, interruptTooltip] = useMemo((): string[] => {
    if (hasLocalChanges) {
      return [t('timer.saveAndInterrupt'), t('timer.saveAndInterruptTooltip')];
    }
    return [t('timer.interrupt'), t('timer.interrupt')];
  }, [hasLocalChanges, t]);

  return (
    <Button
      tooltip={interruptTooltip}
      onClick={interruptClickHandler}
      disabled={isButtonDisabled}
      label={interrupt}
      iconId="stop"
      isFullWidth
    />
  );
}
