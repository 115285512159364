/* eslint-disable jsx-a11y/control-has-associated-label */
import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, SparePartManagementType } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, ImageModalState, SimpleOption } from '@stimcar/libs-uitoolkit';
import {
  AvailablePermissionPaths,
  filterReject,
  nonDeleted,
  packageDealHelpers,
  sortingHelpers,
  SPARE_PART_MANAGEMENT_TYPES,
} from '@stimcar/libs-base';
import {
  applyAsyncCallbackSequentially,
  isTruthy,
  isTruthyAndNotEmpty,
  nonnull,
} from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useArrayItemSelector,
  useFormFieldWarning,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  Button,
  Checkbox,
  Input,
  IsTrue,
  ModalCardDialog,
  ReactSelect,
  TableActionCell,
  ToggleNestedButton,
  WithWarning,
} from '@stimcar/libs-uitoolkit';
import type { ComputeAttachmentUrlCallback } from '../../../../lib/components/attachments/typings/attachment.js';
import type { AfterCommentUpdateActionCallback } from '../../../commons/comments/actions/commentsActions.js';
import type { CommentsChangedActionCallback } from '../../../commons/comments/EditCommentsModalDialogComponent.js';
import type { CommentsFormData } from '../../../commons/comments/typings/store.js';
import type { Store } from '../../../state/typings/store.js';
import type {
  OperatorViewState,
  SparePartReferenceFormData,
  SparePartsReferenceState,
  WorkStatus,
} from '../../typings/store.js';
import { ColumnedCarviewAndAttachmentThumbnailsDisplayer } from '../../../../lib/components/photosdisplayer/ColumnedCarviewAndAttachmentThumbnailsDisplayer.js';
import { useHasModifyPermission } from '../../../../registeredapp/permissionHooks.js';
import { onCommentsChangedAction } from '../../../commons/comments/actions/commentsActions.js';
import { EditableCommentsComponent } from '../../../commons/comments/EditableCommentsComponent.js';
import { EditCommentsModalDialogComponent } from '../../../commons/comments/EditCommentsModalDialogComponent.js';
import { useComputeAttachmentUrl } from '../../../utils/useComputeAttachmentUrl.js';
import {
  EMPTY_ADD_SPARE_PART_TO_PACKAGE_DEAL_DIALOG_STATE,
  EMPTY_CHANGE_MANAGEMENT_TYPE_FOR_ALL_DIALOG_STATE,
  EMPTY_SPARE_PARTS_REFERENCE_STATE,
} from '../../typings/store.js';
import { AddSparePartToPackageDealModal } from './AddSparePartToPackageDealModal.js';
import { useSparePartProviderIdOptions } from './common/useSparePartProviderIdOptions.js';
import {
  getSparePartAsReferenceData,
  onSparePartReferenceFormDataChangeAction,
  recomputeSparePartForNewManagementType,
} from './sparePartsReferenceUtils.js';

function openAddSparePartToPackageDealModalAction(
  { actionDispatch }: ActionContext<Store, SparePartsReferenceState>,
  kanban: Kanban | undefined
): void {
  actionDispatch.applyPayload({
    addSparePartToPackageDealDialogState: {
      ...EMPTY_ADD_SPARE_PART_TO_PACKAGE_DEAL_DIALOG_STATE,
      packageDeals: kanban?.packageDeals ?? [],
      active: true,
    },
  });
}

function deleteSparePartFromPackageDealAction(
  { actionDispatch }: ActionContext<Store, SparePartsReferenceState>,
  sparePartIdToDelete: string
): void {
  actionDispatch.applyPayload({
    sparePartsUnderModification: [
      {
        id: sparePartIdToDelete,
        deleted: true,
      },
    ],
  });
}

function openChangeManagementTypeModalAction(
  { actionDispatch }: ActionContext<Store, SparePartsReferenceState>,
  targetType: SparePartManagementType
): void {
  actionDispatch.applyPayload({
    changeManagementTypeForAllDialogState: {
      active: true,
      targetType,
    },
  });
}

function changeManagementTypeAction({
  actionDispatch,
  getState,
}: ActionContext<Store, SparePartsReferenceState>): void {
  const { changeManagementTypeForAllDialogState, sparePartsUnderModification } = getState();
  actionDispatch.applyPayload({
    sparePartsUnderModification: sparePartsUnderModification.filter(nonDeleted).map((sp) => {
      const targetType = nonnull(changeManagementTypeForAllDialogState.targetType);
      return recomputeSparePartForNewManagementType({ ...sp, managementType: targetType });
    }),
    changeManagementTypeForAllDialogState: EMPTY_CHANGE_MANAGEMENT_TYPE_FOR_ALL_DIALOG_STATE,
  });
}

export async function initializeSparePartsReferenceState(
  { actionDispatch, getState }: ActionContext<Store, OperatorViewState>,
  t: TFunction
) {
  const kanban = getState().operatedKanban;

  if (isTruthy(kanban)) {
    const sparePartReferenceData = getSparePartAsReferenceData(kanban);
    actionDispatch.applyPayload({
      sparePartsReferenceState: {
        sparePartsUnderModification: sparePartReferenceData,
      },
    });
    // Update isReferenced booleans & form validation warnings
    await applyAsyncCallbackSequentially(sparePartReferenceData, async (sp) => {
      const itemActionDispatch = actionDispatch
        .scopeProperty('sparePartsReferenceState')
        .scopeProperty('sparePartsUnderModification')
        .scopeArrayItem(sp.id);
      await itemActionDispatch.exec(onSparePartReferenceFormDataChangeAction, t);
    });
  }
}

/**
 * Checks if a spare part is disabled in the provided context.
 * This context being the kind of parameters we need to take into account
 * @param sparePart Spare part to test
 * @param disabledIfManagedByCustomer true if part should be disabled when managed by customer
 * @param disabledIfOrderedByStimcarFromCustomersCatalog true if part should be disabled when Stimcar is ordering for the customer
 * @param disabledIfManagedByStimcar true if part should be disabled when Stimcar does the all ordering process
 */
function isSparePartDisabled(
  sparePart: SparePartReferenceFormData,
  disabledIfManagedByCustomer: boolean,
  disabledIfOrderedByStimcarFromCustomersCatalog: boolean,
  disabledIfManagedByStimcar: boolean
): boolean {
  return (
    (disabledIfManagedByCustomer && sparePart.managementType === 'fullyManagedByCustomer') ||
    (disabledIfOrderedByStimcarFromCustomersCatalog &&
      sparePart.managementType === 'orderedByStimcarFromCustomersCatalog') ||
    (disabledIfManagedByStimcar &&
      sparePart.managementType === 'fullyManagedByStimcar' &&
      !isTruthyAndNotEmpty(sparePart.provider))
  );
}

export function SparePartsReferenceComponent({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('operators');

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);
  const { $operatorView, $editCommentsModalState } = $gs;
  const $ = $operatorView.$sparePartsReferenceState;
  const { $commentModal, $changeManagementTypeForAllDialogState } = $;

  const canOverrideSparepartPrice = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.CAN_OVERRIDE_SPAREPART_PRICE
  );

  const asyncEffect = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(initializeSparePartsReferenceState, t);
    },
    [t],
    $operatorView
  );

  const asyncCleanEffect = useActionCallback(
    function cleanSparePartsReferenceComponent({ actionDispatch }) {
      actionDispatch
        .scopeProperty('sparePartsReferenceState')
        .setValue(EMPTY_SPARE_PARTS_REFERENCE_STATE);
    },
    [],
    $operatorView
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      asyncCleanEffect();
    };
  }, [asyncCleanEffect, asyncEffect]);

  const operatedKanban = useGetState($operatorView.$operatedKanban);
  const workStatus = useGetState($operatorView.$workStatus);
  const spareParts = useGetState($.$sparePartsUnderModification);

  const activeSparePartsUnderModification = useMemo(
    () => spareParts.filter(nonDeleted),
    [spareParts]
  );

  const sortedAvailableOrRecommendedPackageDealsSuggestions = useMemo(() => {
    const { filtered, rejected } = filterReject(
      (operatedKanban?.packageDeals ?? []).filter((pck) =>
        packageDealHelpers.isPackageDealAvailable(pck, true)
      ),
      (p) => isTruthy(p.carElement)
    );
    const sortedPdsWithCarElement = filtered
      .slice()
      .sort(sortingHelpers.createSortByStringField('UP', 'label'))
      .sort(sortingHelpers.createSortPackageDealsByCarElementLabelFunction('UP'))
      .map((p) => {
        return {
          value: p.id,
          label: `${p.carElement?.label ? `${p.carElement.label} > ` : ''}${p.label}`,
        };
      });
    const sortedPdsWithoutCarElement = rejected
      .slice()
      .sort(sortingHelpers.createSortByStringField('UP', 'label'))
      .map((p) => {
        return {
          value: p.id,
          label: `${p.carElement?.label ? `${p.carElement.label} > ` : ''}${p.label}`,
        };
      });
    return [...sortedPdsWithCarElement, ...sortedPdsWithoutCarElement];
  }, [operatedKanban]);

  const changeManagementTypeForAllHandler = useActionCallback(changeManagementTypeAction, [], $);

  const sparePartManagementTypeSuggestions = useMemo((): readonly {
    value: string;
    label: string;
  }[] => {
    return SPARE_PART_MANAGEMENT_TYPES.map((type) => {
      return {
        value: type,
        label: t(`sparePartsReference.sparePartManagementTypeLabel.${type}`),
      };
    });
  }, [t]);

  const atLeastOneEnabledPartCheckbox = useMemo((): boolean => {
    return activeSparePartsUnderModification.some(
      (sparePart) => !isSparePartDisabled(sparePart, true, false, true)
    );
  }, [activeSparePartsUnderModification]);

  const areAllCheckablePartsChecked = useMemo((): boolean => {
    return activeSparePartsUnderModification.every((sparePart) => {
      const isDisabled = isSparePartDisabled(sparePart, true, false, true);
      // A disabled part does not count into the checkable parts
      if (isDisabled) {
        return true;
      }
      return (
        sparePart.managementType === 'fullyManagedByCustomer' || isTruthy(sparePart.dateOfReference)
      );
    });
  }, [activeSparePartsUnderModification]);

  const updateSparePartCommentsActionCallback: AfterCommentUpdateActionCallback<
    readonly SparePartReferenceFormData[]
  > = useActionCallback(
    ({ actionDispatch }, _, sparePartComments, data: CommentsFormData) => {
      actionDispatch.applyPayload([
        {
          id: sparePartComments.id,
          comment: data.commentForStore ?? '',
          commentForWorkshop: data.commentForWorkshop ?? '',
          commentForCustomer: data.commentForCustomer ?? '',
        },
      ]);
    },
    [],
    $.$sparePartsUnderModification
  );

  const onCommentsChangedActionCallback: CommentsChangedActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(onCommentsChangedAction, updateSparePartCommentsActionCallback);
    },
    [updateSparePartCommentsActionCallback],
    $editCommentsModalState
  );

  const targetType = useGetState($changeManagementTypeForAllDialogState.$targetType);
  return (
    <>
      <SparePartsReferenceButtons
        t={t}
        operatedKanban={operatedKanban}
        $={$}
        workStatus={workStatus}
        noAvailablePackageDeal={sortedAvailableOrRecommendedPackageDealsSuggestions.length === 0}
      />
      {activeSparePartsUnderModification.length > 0 ? (
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th style={{ width: '2.25em', textAlign: 'right' }}>
                <input
                  type="checkbox"
                  checked={atLeastOneEnabledPartCheckbox && areAllCheckablePartsChecked}
                  disabled
                />
              </th>
              <th>{t('sparePartsReference.carElement')}</th>
              <th>{t('sparePartsReference.packageDealCode')}</th>
              <th>{t('sparePartsReference.label')}</th>
              <th style={{ width: '11em' }}>{t('sparePartsReference.provider')}</th>
              <th style={{ width: '5em' }}>{t('sparePartsReference.quantity')}</th>
              <th style={{ width: '7em' }}>{t('sparePartsReference.providerUnitPrice')}</th>
              <th style={{ width: '7em' }}>{t('sparePartsReference.price')}</th>
              <th
                style={{ width: '2.25em' }}
                title={t('sparePartsReference.enablePriceEditionTooltip')}
              >
                {t('sparePartsReference.enablePriceEdition')}
              </th>
              <th>{t('sparePartsReference.comments')}</th>
              <th style={{ width: '11em' }}>{t('sparePartsReference.sparePartManagementType')}</th>
              <th style={{ width: '2.25em' }}>
                <span title={t('sparePartsReference.delete')} />
              </th>
            </tr>
          </thead>
          <tbody>
            {activeSparePartsUnderModification.map((sp) => (
              <SparePartReferenceRow
                key={sp.id}
                disableInput={workStatus !== 'started'}
                canOverrideSparepartPrice={canOverrideSparepartPrice}
                sparePartId={sp.id}
                packageDealId={sp.packageDealId}
                $={$}
                $gs={$gs}
                kanbanId={nonnull(operatedKanban).id}
                computeAttachmentUrl={computeAttachmentUrl}
                $imageModal={$gs.$imageModal}
                $expandedSectionIds={$.$expandedSectionIds}
                $commentModal={$commentModal}
                sparePartManagementTypeSuggestions={sparePartManagementTypeSuggestions}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <p className="has-text-centered">{t('sparePartsReference.noSparePartsToDisplay')}</p>
      )}
      <IsTrue $={$.$addSparePartToPackageDealDialogState.$active}>
        <AddSparePartToPackageDealModal
          $={$}
          sortedPackageDealsSuggestions={sortedAvailableOrRecommendedPackageDealsSuggestions}
        />
      </IsTrue>
      <EditCommentsModalDialogComponent
        $={$editCommentsModalState}
        onCommentsChangedActionCallback={onCommentsChangedActionCallback}
      />
      <ModalCardDialog
        $active={$.$changeManagementTypeForAllDialogState.$active}
        title={t('sparePartsReference.changeManagementTypeForAllModal.title')}
        onOkClicked={changeManagementTypeForAllHandler}
      >
        {t(`sparePartsReference.changeManagementTypeForAllModal.message.${targetType}`)}
      </ModalCardDialog>
    </>
  );
}

interface SparePartsReferenceButtonsProps {
  readonly t: TFunction;
  readonly operatedKanban: Kanban | undefined;
  readonly workStatus: WorkStatus;
  readonly $: StoreStateSelector<Store, SparePartsReferenceState>;
  readonly noAvailablePackageDeal: boolean;
}

function SparePartsReferenceButtons({
  t,
  operatedKanban,
  workStatus,
  noAvailablePackageDeal,
  $,
}: SparePartsReferenceButtonsProps): JSX.Element {
  const spartParts = useGetState($.$sparePartsUnderModification);

  const activeSparePartsUnderModification = useMemo(
    () => spartParts.filter(nonDeleted),
    [spartParts]
  );

  const openAddHandler = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, SparePartsReferenceState>): Promise<void> => {
      await actionDispatch.exec(openAddSparePartToPackageDealModalAction, operatedKanban);
    },
    [operatedKanban],
    $
  );

  const areAllSparePartsManagedByStimcar = useMemo((): boolean => {
    const sparePartsDifferentlyManaged = activeSparePartsUnderModification.filter(
      (sp) => sp.managementType !== 'fullyManagedByStimcar'
    );
    return sparePartsDifferentlyManaged.length === 0;
  }, [activeSparePartsUnderModification]);

  const areAllSparePartsManagedByCustomer = useMemo((): boolean => {
    const sparePartsDifferentlyManaged = activeSparePartsUnderModification.filter(
      (sp) => sp.managementType !== 'fullyManagedByCustomer'
    );
    return sparePartsDifferentlyManaged.length === 0;
  }, [activeSparePartsUnderModification]);

  const areAllSparePartsOrderedByStimcarFromCustomersCatalog = useMemo((): boolean => {
    const sparePartsDifferentlyManaged = activeSparePartsUnderModification.filter(
      (sp) => sp.managementType !== 'orderedByStimcarFromCustomersCatalog'
    );
    return sparePartsDifferentlyManaged.length === 0;
  }, [activeSparePartsUnderModification]);
  const openFullyStimcarForAllModalHandler = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, SparePartsReferenceState>): Promise<void> => {
      await actionDispatch.exec(openChangeManagementTypeModalAction, 'fullyManagedByStimcar');
    },
    [],
    $
  );
  const openFullyCustomerForAllModalHandler = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, SparePartsReferenceState>): Promise<void> => {
      await actionDispatch.exec(openChangeManagementTypeModalAction, 'fullyManagedByCustomer');
    },
    [],
    $
  );
  const openCustomersCatalogForAllModalHandler = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, SparePartsReferenceState>): Promise<void> => {
      await actionDispatch.exec(
        openChangeManagementTypeModalAction,
        'orderedByStimcarFromCustomersCatalog'
      );
    },
    [],
    $
  );
  return (
    <div className="box">
      <div className="columns">
        <div className="column" />
        <div className="column is-narrow has-text-centered">
          <p className="has-text-weight-bold is-6">
            {t('sparePartsReference.buttons.addSparePartToPackageDealGroupName')}
          </p>
          <Button
            label={t('sparePartsReference.buttons.addSparePartToPackageDealButtonLabel')}
            additionalClass="button"
            onClick={openAddHandler}
            disabled={workStatus !== 'started' || noAvailablePackageDeal}
          />
        </div>
        <div className="column is-narrow has-text-centered">
          <p className="has-text-weight-bold is-6">
            {t('sparePartsReference.buttons.changeManagementTypeGroupName')}
          </p>
          <div className="field is-grouped">
            <p className="control">
              <Button
                label={t(
                  'sparePartsReference.buttons.changeManagementTypeToFullymanagedByStimcarButtonLabel'
                )}
                additionalClass="button"
                onClick={openFullyStimcarForAllModalHandler}
                disabled={
                  workStatus !== 'started' ||
                  activeSparePartsUnderModification.length === 0 ||
                  areAllSparePartsManagedByStimcar
                }
              />
            </p>
            <p className="control">
              <Button
                label={t(
                  'sparePartsReference.buttons.changeManagementTypeToFullymanagedByCustomerButtonLabel'
                )}
                additionalClass="button"
                onClick={openFullyCustomerForAllModalHandler}
                disabled={
                  workStatus !== 'started' ||
                  activeSparePartsUnderModification.length === 0 ||
                  areAllSparePartsManagedByCustomer
                }
              />
            </p>
            <p className="control">
              <Button
                label={t(
                  'sparePartsReference.buttons.changeManagementTypeToOrderedByStimcarFromCustomersCatalogButtonLabel'
                )}
                additionalClass="button"
                onClick={openCustomersCatalogForAllModalHandler}
                disabled={
                  workStatus !== 'started' ||
                  activeSparePartsUnderModification.length === 0 ||
                  areAllSparePartsOrderedByStimcarFromCustomersCatalog
                }
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

interface SparePartReferenceRowProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, SparePartsReferenceState>;
  readonly sparePartId: string;
  readonly packageDealId: string;
  readonly disableInput: boolean;
  readonly canOverrideSparepartPrice: boolean;
  readonly kanbanId: string;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly $imageModal: StoreStateSelector<Store, ImageModalState>;
  readonly $expandedSectionIds: StoreStateSelector<Store, readonly string[]>;
  readonly $commentModal: StoreStateSelector<
    Store,
    {
      readonly active: boolean;
      readonly sparePartId: string;
    }
  >;
  readonly sparePartManagementTypeSuggestions: readonly SimpleOption[];
}

function SparePartReferenceRow({
  sparePartId,
  $,
  $gs,
  disableInput,
  canOverrideSparepartPrice,
  kanbanId,
  computeAttachmentUrl,
  $imageModal,
  $expandedSectionIds,
  sparePartManagementTypeSuggestions,
}: SparePartReferenceRowProps): JSX.Element {
  const [t] = useTranslation('operators');

  const $sparePart = useArrayItemSelector($.$sparePartsUnderModification, sparePartId);
  const sparePart = useGetState($sparePart);
  const { $editCommentsModalState } = $gs;

  const sparePartsProviderIds = useSparePartProviderIdOptions($gs);

  const isInputDisabled = useCallback(
    (
      disabledIfManagedByCustomer: boolean,
      disabledIfOrderedByStimcarFromCustomersCatalog: boolean,
      disabledIfManagedByStimcar: boolean
    ): boolean => {
      return (
        disableInput ||
        isSparePartDisabled(
          sparePart,
          disabledIfManagedByCustomer,
          disabledIfOrderedByStimcarFromCustomersCatalog,
          disabledIfManagedByStimcar
        )
      );
    },
    [disableInput, sparePart]
  );

  const deleteSparePartActionCallback = useActionCallback(
    async function deleteSparePartAction({ actionDispatch }) {
      await actionDispatch.exec(deleteSparePartFromPackageDealAction, sparePartId);
    },
    [sparePartId],
    $
  );

  const onSparePartReferenceFormDataChangeActionCallback = useActionCallback(
    async function _onSparePartReferenceFormDataChangeAction({ actionDispatch }) {
      await actionDispatch.exec(onSparePartReferenceFormDataChangeAction, t);
    },
    [t],
    $sparePart
  );

  const onIsReferencedChangeActionCallback = useActionCallback(
    async function onIsReferencedChangeAction({ actionDispatch, getState }) {
      actionDispatch.setProperty('dateOfReference', getState().isReferenced ? Date.now() : null);
      await actionDispatch.execCallback(onSparePartReferenceFormDataChangeActionCallback);
    },
    [onSparePartReferenceFormDataChangeActionCallback],
    $sparePart
  );

  const $isReferencedWithTrigger = useSelectorWithChangeTrigger(
    $sparePart.$isReferenced,
    onIsReferencedChangeActionCallback
  );

  const $labelWithChangerTrigger = useSelectorWithChangeTrigger(
    $sparePart.$label,
    onSparePartReferenceFormDataChangeActionCallback
  );

  const labelWarning = useFormFieldWarning($sparePart.$label);

  const onProviderChangeActionCallback = useActionCallback(
    async function onProviderChangeAction({ actionDispatch }) {
      actionDispatch.setProperty('dateOfReference', null);
      actionDispatch.setProperty('isReferenced', false);
      await actionDispatch.execCallback(onSparePartReferenceFormDataChangeActionCallback);
    },
    [onSparePartReferenceFormDataChangeActionCallback],
    $sparePart
  );

  const $providerWithChangeTrigger = useSelectorWithChangeTrigger(
    $sparePart.$provider,
    onProviderChangeActionCallback
  );
  const providerWarning = useFormFieldWarning($providerWithChangeTrigger);

  const $quantityWithChangeTrigger = useSelectorWithChangeTrigger(
    $sparePart.$quantity,
    onSparePartReferenceFormDataChangeActionCallback
  );
  const quantityWarning = useFormFieldWarning($quantityWithChangeTrigger);

  const $providerUnitPriceWithChangeTrigger = useSelectorWithChangeTrigger(
    $sparePart.$providerUnitPrice,
    onSparePartReferenceFormDataChangeActionCallback
  );
  const providerUnitPriceWarning = useFormFieldWarning($providerUnitPriceWithChangeTrigger);

  const $priceWithChangeTrigger = useSelectorWithChangeTrigger(
    $sparePart.$price,
    onSparePartReferenceFormDataChangeActionCallback
  );
  const priceWarning = useFormFieldWarning($priceWithChangeTrigger);

  const $priceIsOverriddenWithTrigger = useSelectorWithChangeTrigger(
    $sparePart.$priceIsOverridden,
    onSparePartReferenceFormDataChangeActionCallback
  );

  const customerLabel = t('sparePartsReference.customer');
  const fakeSuggestionsForCustomer = useMemo(() => [customerLabel], [customerLabel]);

  const onManagementTypeDispatchChangeActionCallback = useActionCallback(
    async function onManagementTypeDispatchChangeAction({ actionDispatch, getState }) {
      const { managementType } = getState();
      switch (managementType as SparePartManagementType) {
        case 'fullyManagedByStimcar':
          actionDispatch.applyPayload({
            provider: '',
            dateOfReference: null,
            isReferenced: true,
          });
          break;
        case 'fullyManagedByCustomer':
          actionDispatch.applyPayload({
            providerUnitPrice: '0',
            priceIsOverridden: false,
            price: '0',
            provider: customerLabel,
            dateOfReference: null,
          });
          break;
        case 'orderedByStimcarFromCustomersCatalog':
          actionDispatch.applyPayload({
            providerUnitPrice: '0',
            priceIsOverridden: false,
            price: '0',
            provider: customerLabel,
          });
          break;
        default:
          throw new Error(`Unknown spare part management type: ${managementType}`);
      }
      // Run generic on change trigger
      await actionDispatch.execCallback(onSparePartReferenceFormDataChangeActionCallback);
    },
    [onSparePartReferenceFormDataChangeActionCallback, customerLabel],
    $sparePart
  );

  const $managementTypeWithChangeTrigger = useSelectorWithChangeTrigger(
    $sparePart.$managementType,
    onManagementTypeDispatchChangeActionCallback
  );
  const managementTypeWarning = useFormFieldWarning($managementTypeWithChangeTrigger);

  const expandedSectionIds = useGetState($expandedSectionIds);

  return (
    <>
      <tr>
        <td>
          <Checkbox $={$isReferencedWithTrigger} disabled />
        </td>
        <td>
          <ToggleNestedButton
            $expandedIds={$expandedSectionIds}
            id={sparePart.id}
            hasChildren={sparePart.packageDealAttachments.length > 0}
            label={sparePart.carElement?.label ?? ''}
          />
        </td>
        <td>{sparePart.packageDealLabel}</td>
        <td>
          <WithWarning warning={labelWarning}>
            <Input
              type="text"
              $={$labelWithChangerTrigger}
              disabled={isInputDisabled(true, false, false)}
              className="is-small"
            />
          </WithWarning>
        </td>
        <td>
          <div className="refitit-select small-fontsize-height">
            {sparePart.managementType === 'fullyManagedByStimcar' ? (
              <WithWarning warning={providerWarning}>
                <ReactSelect
                  suggestions={sparePartsProviderIds}
                  $={$providerWithChangeTrigger}
                  isClearable
                  isDisabled={isInputDisabled(true, true, false)}
                />
              </WithWarning>
            ) : (
              <ReactSelect
                suggestions={fakeSuggestionsForCustomer}
                $={$providerWithChangeTrigger /* will not be used as the component is readonly */}
                isClearable
                isDisabled
              />
            )}
          </div>
        </td>
        <td>
          <WithWarning warning={quantityWarning}>
            <Input
              type="number"
              $={$quantityWithChangeTrigger}
              disabled={isInputDisabled(true, false, false)}
              className="is-small"
            />
          </WithWarning>
        </td>
        <td>
          <WithWarning warning={providerUnitPriceWarning}>
            <Input
              type="number"
              $={$providerUnitPriceWithChangeTrigger}
              disabled={isInputDisabled(true, true, false)}
              className="is-small"
            />
          </WithWarning>
        </td>
        <td>
          <WithWarning warning={priceWarning}>
            <Input
              type="number"
              $={$priceWithChangeTrigger}
              disabled={
                !canOverrideSparepartPrice ||
                isInputDisabled(true, true, false) ||
                !sparePart.priceIsOverridden
              }
              className="is-small"
            />
          </WithWarning>
        </td>
        <td>
          <WithWarning warning={useFormFieldWarning($priceIsOverriddenWithTrigger)}>
            <Checkbox
              $={$priceIsOverriddenWithTrigger}
              disabled={isInputDisabled(true, true, false)}
            />
          </WithWarning>
        </td>
        <td>
          <EditableCommentsComponent
            kanbanId={kanbanId}
            packageDealId={sparePart.packageDealId}
            sparePart={sparePart}
            packageDealComment={sparePart.packageDealComment}
            commentForStore={sparePart.comment}
            commentForWorkshop={sparePart.commentForWorkshop}
            commentForCustomer={sparePart.commentForCustomer}
            $={$editCommentsModalState}
          />
        </td>
        <td>
          <div className="refitit-select small-fontsize-height">
            <WithWarning warning={managementTypeWarning}>
              <ReactSelect
                suggestions={sparePartManagementTypeSuggestions}
                $={$managementTypeWithChangeTrigger}
                isClearable={false}
                isDisabled={isInputDisabled(false, false, false)}
              />
            </WithWarning>
          </div>
        </td>
        <TableActionCell
          onClick={deleteSparePartActionCallback}
          iconId="trash"
          tooltip={t('sparePartsReference.delete')}
          disabled={isInputDisabled(false, false, false)}
        />
      </tr>
      {expandedSectionIds.includes(sparePart.id) && (
        <tr>
          <td colSpan={10}>
            <ColumnedCarviewAndAttachmentThumbnailsDisplayer
              category={sparePart.carElement?.category ?? 'MISC'}
              selectedShapes={sparePart.carElement?.shapes ?? []}
              attachments={sparePart.packageDealAttachments}
              computeAttachmentUrl={computeAttachmentUrl}
              $imageModal={$imageModal}
              kanbanId={kanbanId}
              photoHeight={125}
            />
          </td>
        </tr>
      )}
    </>
  );
}
