/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from '../FormField.js';
import { FormField } from '../FormField.js';
import type { ReactSelectMultiProps } from './ReactSelectMulti.js';
import { ReactSelectMulti } from './ReactSelectMulti.js';

export type ReactSelectMultiFormFieldProps<
  SD extends AnyStoreDef,
  T extends string | number,
> = Omit<FormFieldProps, 'type' | 'children' | 'warning'> & ReactSelectMultiProps<SD, T>;

export function ReactSelectMultiFormField<SD extends AnyStoreDef, T extends string | number>({
  label,
  $,
  noExclamationTriangleIfWarning,
  suggestions,
  creation,
  isClearable,
  placeholder,
  hideDropdownIndicator,
  horizontal,
  formatOptionLabel,
  isDisabled,
}: ReactSelectMultiFormFieldProps<SD, T>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($);
  return (
    <>
      <FormField
        label={label}
        warning={dispatchWarning}
        noExclamationTriangleIfWarning={noExclamationTriangleIfWarning}
        horizontal={horizontal}
      >
        <ReactSelectMulti
          creation={creation}
          suggestions={suggestions}
          $={$}
          isClearable={isClearable}
          formatOptionLabel={formatOptionLabel}
          placeholder={placeholder}
          hideDropdownIndicator={hideDropdownIndicator}
          isDisabled={isDisabled}
        />
      </FormField>
    </>
  );
}
