import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { SharedKanban, SharedUIContract, Site } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { electronicSignatureHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { DeliveryView } from '@stimcar/libs-uitoolkit';
import type {
  KanbanShareDeliveryTabState,
  SharePreferencesType,
  ShareStore,
} from '../state/typings/store.js';
import { DeliverySecondFactorMailInputAndModalComponent } from './SecondFactorComponents.js';

export function initializeKanbanShareDeliveryTab({
  actionDispatch,
  keyValueStorage,
}: ActionContext<ShareStore, KanbanShareDeliveryTabState>): void {
  const sharePreferences = keyValueStorage.getObjectItem<SharePreferencesType>(
    LocalStorageKeys.SHARE_PREFERENCES
  );

  actionDispatch.reduce((initial) => {
    return {
      ...initial,
      secondFactor: {
        ...initial.secondFactor,
        validationEmail: isTruthy(sharePreferences)
          ? sharePreferences.email
          : initial.secondFactor.validationEmail,
      },
    };
  });
}

interface Props {
  readonly shareId: string;
  readonly site: Site;
  readonly kanban: SharedKanban;
  readonly contract: SharedUIContract;
  readonly $: StoreStateSelector<ShareStore, KanbanShareDeliveryTabState>;
}

export function KanbanShareDeliveryTab({ kanban, shareId, site, $, contract }: Props): JSX.Element {
  const [t] = useTranslation('share');

  const { $secondFactor } = $;

  const impossibleSignatureMessage = useMemo(() => {
    const { canBeSigned, reasonIfNot } = electronicSignatureHelpers.hasADocumentToSignByCustomer(
      kanban,
      site.configuration,
      'LIV'
    );
    if (canBeSigned) {
      return undefined;
    }
    switch (reasonIfNot) {
      case 'isCurrentlyHandled':
        return t('status.kanbanRefurbishNotFinished');
      case 'notReadyForSignature':
        return t('status.kanbanRefurbishNotFinished');
      case 'signatureAlreadyDone':
        return t('status.kanbanAlreadyDelivered');
      default:
        throw Error(`Unknown status ${reasonIfNot}`);
    }
  }, [kanban, site.configuration, t]);

  return (
    <>
      <DeliveryView
        kanban={kanban}
        address={site.configuration.infos.address}
        attributeDescs={contract.attributeDescs}
        companyName={site.configuration.infos.companyName}
        logoUrl={site.configuration.infos.logoUrl}
        bottomPlaceholderSize={30}
      />
      <div className="columns m-t-sm no-printing">
        <div className="column">
          <DeliverySecondFactorMailInputAndModalComponent
            $={$secondFactor}
            kanban={kanban}
            shareId={shareId}
            businessMessage={impossibleSignatureMessage}
          />
        </div>
      </div>
    </>
  );
}
