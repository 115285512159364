import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from './FormField.js';
import type { SelectProps } from './Select.js';
import { FormField } from './FormField.js';
import { Select } from './Select.js';

export type SelectFormFieldProps<
  SD extends AnyStoreDef,
  T extends string | number | boolean,
> = Omit<FormFieldProps, 'children' | 'noExclamationTriangleIfWarning' | 'warning'> &
  SelectProps<SD, T>;

export function SelectFormField<SD extends AnyStoreDef, T extends string | number | boolean>({
  label,
  $,
  isFullWidth,
  isEmptyValueAllowed,
  entries,
  disabled,
  sortEntries,
  horizontal,
  isSmall,
  ...props
}: SelectFormFieldProps<SD, T>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($);
  return (
    <FormField
      label={label}
      warning={dispatchWarning}
      horizontal={horizontal}
      noExclamationTriangleIfWarning
    >
      <Select<SD, T>
        $={$}
        isFullWidth={isFullWidth}
        isEmptyValueAllowed={isEmptyValueAllowed}
        entries={entries}
        disabled={disabled}
        sortEntries={sortEntries}
        isSmall={isSmall}
        {...props}
      />
    </FormField>
  );
}
