import React from 'react';
import { useTranslation } from 'react-i18next';
import type {
  ActionCallbackFromFunction,
  ActionContext,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { REFURBISHING_TYPES } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  ModalCardDialog,
  RadioButtonsFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../state/typings/store.js';
import type { AddPackageDealToDefectDialogState } from '../../../typings/store.js';
import {
  EMPTY_ADD_PACKAGE_DEAL_TO_DEFECT_DIALOG_STATE,
  EMPTY_ADD_PACKAGE_DEAL_TO_DEFECT_FORM,
} from '../../../typings/store.js';

export function openAddPackageDealToDefectModalAction(
  { actionDispatch }: ActionContext<Store, AddPackageDealToDefectDialogState>,
  packageDealId: string,
  packageDealLabel: string
): void {
  actionDispatch.setValue({
    ...EMPTY_ADD_PACKAGE_DEAL_TO_DEFECT_DIALOG_STATE,
    packageDealId,
    packageDealLabel,
    formData: {
      ...EMPTY_ADD_PACKAGE_DEAL_TO_DEFECT_FORM,
      refurbishingType: 'fully',
    },
    active: true,
  });
}

type AddPackageDealToDefectModalProps = {
  readonly $: StoreStateSelector<Store, AddPackageDealToDefectDialogState>;
  readonly onSubmitCallback: ActionCallbackFromFunction<
    Store,
    (packageDealId: string, fullyRefurbishing: boolean) => void
  >;
};

export function AddPackageDealToDefectModal({
  $,
  onSubmitCallback,
}: AddPackageDealToDefectModalProps): JSX.Element {
  const [tGlobal] = useTranslation('');
  const [t] = useTranslation('operators', {
    keyPrefix: 'defects.addOrUpdateDefect.addPackageDealToDefect',
  });

  const [tRefurbishing] = useTranslation('operators', {
    keyPrefix: 'defects.addOrUpdateDefect.refurbishingOptions',
  });

  const packageDealLabel = useGetState($.$packageDealLabel);

  const submitFormActionCallback = useActionCallback(
    async ({
      actionDispatch,
      getState,
    }: ActionContext<Store, AddPackageDealToDefectDialogState>): Promise<void> => {
      const { packageDealId, formData } = getState();
      const { refurbishingType } = formData;

      await actionDispatch.execCallback(
        onSubmitCallback,
        packageDealId,
        refurbishingType === 'fully'
      );

      actionDispatch.applyPayload({
        active: false,
      });
    },
    [onSubmitCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    AddPackageDealToDefectDialogState
  >({
    $,
    mandatoryFields: ['refurbishingType'],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction: submitFormActionCallback,
    t: tGlobal,
  });

  const formWarning = useGetState($.$formWarning);

  const entries = REFURBISHING_TYPES.map((id) => ({
    id,
    label: tRefurbishing(`type.${id}`),
  }));

  return (
    <ModalCardDialog
      title={t('title')}
      $active={$.$active}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <p>{t('subtitle', { packageDealLabel })}</p>
      <RadioButtonsFormField
        id="refurbishingType"
        label=""
        entries={entries}
        radioGroupLayout="horizontal"
        $={$formDataWithChangeTrigger.$refurbishingType}
      />
    </ModalCardDialog>
  );
}
