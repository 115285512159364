import type { Kanban, KanbanLocationElement } from '../../model/typings/kanban.js';
import type { SpecificFields } from '../../model/typings/repository.js';

/**
 * Sorting function used to sort location elements. After applying this function, most recent location
 * will be first in the array, oldest will be last
 */
function compareLocationHistory(a: KanbanLocationElement, b: KanbanLocationElement): number {
  if (a.date < b.date) {
    return 1;
  }
  if (a.date > b.date) {
    return -1;
  }
  return 0;
}

function getCurrentLocationElement<K extends SpecificFields<Kanban>>(
  kanban: K
): KanbanLocationElement | undefined {
  const sortedHistory = kanban.logisticInfos.locationHistory.slice().sort(compareLocationHistory);
  if (sortedHistory && sortedHistory.length > 0) {
    return sortedHistory[0];
  }
  return undefined;
}

function getCurrentLocation<K extends SpecificFields<Kanban>>(kanban: K): string | undefined {
  const locationElement = getCurrentLocationElement(kanban);
  return locationElement ? locationElement.location : undefined;
}

function getUpdatedLogisticInfosWithNewLocation(
  kanban: Kanban,
  carLocation: string,
  historyEntryId: string
) {
  return {
    ...kanban.logisticInfos,
    locationHistory: [
      ...kanban.logisticInfos.locationHistory,
      {
        id: historyEntryId,
        location: carLocation,
        date: Date.now(),
      },
    ],
  };
}

function hasLocationChanged(originalKanban: Kanban, newLocation: string): boolean {
  const originalLocation = getCurrentLocation(originalKanban);
  return (originalLocation ?? '') !== (newLocation ?? '');
}

export const locationHelpers = {
  getCurrentLocation,
  getCurrentLocationElement,
  getUpdatedLogisticInfosWithNewLocation,
  hasLocationChanged,
};
