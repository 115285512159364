import type { RepositoryEntity } from '../model/typings/repository.js';

function isARepositoryStatusString(value: string): boolean {
  switch (value) {
    case 'open':
    case 'closed':
    case 'archived':
      return true;

    default:
      return false;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isARepositoryEntity(e: any): e is RepositoryEntity {
  return (
    e.id &&
    typeof e.id === 'string' &&
    e.id !== '' &&
    typeof e.sequenceId === 'number' &&
    typeof e.timestamp === 'number' &&
    e.status &&
    isARepositoryStatusString(e.status)
  );
}
