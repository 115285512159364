import React from 'react';
import type { FormFieldProps } from './FormField.js';
import { FormField } from './FormField.js';

interface Props extends FormFieldProps {
  readonly addExtraTopMargin?: boolean;
}

export function CustomContentFormField({
  children,
  addExtraTopMargin,
  ...props
}: Props): JSX.Element {
  return (
    <FormField {...props}>
      <div className={addExtraTopMargin ? 'm-t-sm' : ''}>{children}</div>
    </FormField>
  );
}
