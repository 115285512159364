import type { RepositoryEntities } from '@stimcar/libs-base';
import type { TxAOP } from '@stimcar/libs-kernel';
import { compareEntities } from '@stimcar/libs-base';
import { Logger } from '@stimcar/libs-kernel';
import type { DatabaseTx } from '../../database/typings/database.js';
import type {
  LocalDbAction,
  LocalEntityHolder,
  RepositoryStoreDesc,
} from '../typings/repository-internals.js';
import type { EntityQueriesDAO } from './typings/repository-dao.js';
import {
  INDEX_LOCAL_SUFFIX,
  INDEX_SERVER_SUFFIX,
  LOCAL_ENTITIES_BY_STATUS_INDEX,
  RepositoryDAOImpl,
} from './RepositoryDAOImpl.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

/**
 * Entity queries DAO implementation.
 */
export class EntityQueriesDAOImpl<ENAME extends keyof RepositoryEntities>
  extends RepositoryDAOImpl<ENAME>
  implements TxAOP<EntityQueriesDAO<ENAME>, DatabaseTx<RepositoryStoreDesc<ENAME>>>
{
  private getServerAndLocalConsolidateResults = (
    localHolders: LocalEntityHolder<RepositoryEntities[ENAME]>[] | undefined,
    serverHolders: LocalEntityHolder<RepositoryEntities[ENAME]>[] | undefined
  ): LocalEntityHolder<RepositoryEntities[ENAME]>[] => {
    const holders: Map<string, LocalEntityHolder<RepositoryEntities[ENAME]>> = new Map();
    if (serverHolders) {
      serverHolders.forEach((h): void => {
        holders.set(h.id, h);
      });
    }
    if (localHolders) {
      localHolders.forEach((h): void => {
        holders.set(h.id, h);
      });
    }
    return Array.from(holders.values());
  };

  private convertHoldersToEntities = (
    holders: LocalEntityHolder<RepositoryEntities[ENAME]>[]
  ): RepositoryEntities[ENAME][] => {
    const result: RepositoryEntities[ENAME][] = [];
    holders.forEach((holder): void => {
      result.push(this.toLocalEntity(holder));
    });
    return result;
  };

  public noAOPFor = [
    // Own methods
    // RepositoryAPIBaseImpl methods
    this.getEntityData,
    this.toLocalEntity,
    this.getServerAndLocalConsolidateResults,
    this.convertHoldersToEntities,
  ];

  public async getAllEntities(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    status: 'open' | 'archived',
    partitionKey?: string
  ): Promise<RepositoryEntities[ENAME][]> {
    log.warn(`${this.entityName}Repository.getAllEntities(${status}, ${partitionKey})`);
    const holders = await tx.getFromIndex('entities', LOCAL_ENTITIES_BY_STATUS_INDEX, status);
    return this.convertHoldersToEntities(
      partitionKey === undefined ? holders : holders.filter((h) => h.partitionKey === partitionKey)
    );
  }

  public async getEntities(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    ids: string[]
  ): Promise<RepositoryEntities[ENAME][]> {
    const holders = await tx.getN('entities', ...ids);
    return this.convertHoldersToEntities(holders);
  }

  public async getEntitiesFromIndex(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    index: string,
    value: string | number | boolean
  ): Promise<RepositoryEntities[ENAME][]> {
    const holders: LocalEntityHolder<RepositoryEntities[ENAME]>[] = [];
    const localHolders = await tx.getFromIndex('entities', `${index}${INDEX_LOCAL_SUFFIX}`, value);
    const serverHolders = await tx.getFromIndex(
      'entities',
      `${index}${INDEX_SERVER_SUFFIX}`,
      value
    );
    holders.push(...this.getServerAndLocalConsolidateResults(localHolders, serverHolders));
    return this.convertHoldersToEntities(holders.filter(({ status }) => status === 'open'));
  }

  public async getEntity(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    id: string
  ): Promise<RepositoryEntities[ENAME]> {
    const holder = await tx.get('entities', id);
    if (!holder) {
      throw Error(`Unknown entity id ${id}`);
    }
    return this.toLocalEntity(holder);
  }

  public async hasEntity(tx: DatabaseTx<RepositoryStoreDesc<ENAME>>, id: string): Promise<boolean> {
    return tx.exists('entities', id);
  }

  /**
   * Return local actions list.
   */
  public getLocalActionsToExecute = async (
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>
  ): Promise<LocalDbAction<ENAME>[]> => {
    return (await tx.getAll('localActionLog'))
      .filter((a): boolean => !a.acknowledged)
      .sort(compareEntities);
  };

  public getLocalActionsCount = async (
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>
  ): Promise<number> => {
    return await tx.count('localActionLog');
  };
}
