import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { LicensePosition } from '@stimcar/core-libs-common';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { Store } from '../../../../state/typings/store.js';
import type { KanbanListWithSearchState, SearchByImageState } from '../typings/store.js';
import type { RecognizedLicenseSVGRectangleStyle } from './RecognizedLicensesSVGImage.js';
import { RecognizedLicenseSVGRectangle } from './RecognizedLicensesSVGImage.js';

const ABOVE_PLATE_RATIO = 3 / 5;
const BELOW_PLATE_RATIO = 1 - ABOVE_PLATE_RATIO;

type SVGImageCenteredOnSelectedLicenseProps = Pick<
  SearchByImageState,
  'imageHeight' | 'imageWidth' | 'imageBlobUrl'
> & {
  readonly $: StoreStateSelector<Store, KanbanListWithSearchState>;
  readonly license: string;
  readonly licensePosition: LicensePosition;
};

function computeStyle(): RecognizedLicenseSVGRectangleStyle {
  return 'selected';
}

export function SVGImageCenteredOnSelectedLicense({
  $,
  imageBlobUrl,
  imageWidth,
  imageHeight,
  licensePosition,
  license,
}: SVGImageCenteredOnSelectedLicenseProps): JSX.Element {
  const [viewPortX, viewPortY, viewPortWidth, viewPortHeight] = useMemo(() => {
    const { x, y, width, height } = licensePosition;
    let plateCx = x + width / 2;
    let plateCy = y + height / 2;
    // Try to create a viewport (6 * plateWidth) x (4 * plateWidth)
    let viewPortWidth = width * 6;
    let viewPortHeight = width * 4;

    // Initialise viewport coordinates
    let x0 = 0;
    let y0 = 0;
    let x1 = 0;
    let y1 = 0;

    // Now we will iteratively :
    // - compute coords
    // - adjust viewport coordinates to fix coordinates that are outside of the image
    function computeCoords() {
      x0 = plateCx - viewPortWidth / 2;
      x1 = plateCx + viewPortWidth / 2;
      y0 = plateCy - viewPortHeight * ABOVE_PLATE_RATIO;
      y1 = plateCy + viewPortHeight * BELOW_PLATE_RATIO;
    }
    // Compute initial coords
    computeCoords();
    // Fix width until x0 and x1 are contained in the image
    function removeWidth(widthToRemove: number) {
      const oldViewPortWidth = viewPortWidth;
      viewPortWidth -= Math.abs(widthToRemove);
      plateCx += -widthToRemove / 2;
      viewPortHeight *= viewPortWidth / oldViewPortWidth;
      computeCoords();
    }
    if (x0 < 0) {
      removeWidth(x0);
    }
    if (x1 > imageWidth) {
      removeWidth(x1 - imageWidth);
    }
    // Fix height until x0 and x1 are contained in the image
    function removeHeight(heightToRemove: number) {
      const oldViewPortHeight = viewPortHeight;
      viewPortHeight -= Math.abs(heightToRemove);
      plateCy += -heightToRemove / 2;
      viewPortWidth *= viewPortHeight / oldViewPortHeight;
      computeCoords();
    }
    if (y0 < 0) {
      removeHeight(y0);
    }
    if (y1 > imageHeight) {
      removeHeight(y1 - imageHeight);
    }
    return [x0, y0, x1 - x0, y1 - y0];
  }, [imageHeight, imageWidth, licensePosition]);
  return (
    <svg viewBox={`${viewPortX} ${viewPortY} ${viewPortWidth} ${viewPortHeight}`}>
      <image href={imageBlobUrl} x="0" y="0" />
      <RecognizedLicenseSVGRectangle
        {...licensePosition}
        $={$}
        computeStyle={computeStyle}
        license={license}
      />
    </svg>
  );
}
