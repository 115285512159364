import { t } from 'i18next';
import type { EcoStickerValue, EnergyValue } from '@stimcar/libs-kernel';
import type { SimpleOption, TabElementDef } from '@stimcar/libs-uitoolkit';
import { globalHelpers } from '@stimcar/libs-base';
import {
  formatKilometers,
  formatPrice,
  isTruthy,
  isTruthyAndNotEmpty,
  MKTP_ECO_STICKER_ELEC_OR_HYDRO,
  MKTP_ECO_STICKER_LVL_1,
  MKTP_ECO_STICKER_LVL_2,
  MKTP_ECO_STICKER_LVL_3,
  MKTP_ECO_STICKER_LVL_4,
  MKTP_ECO_STICKER_LVL_5,
  MKTP_ECO_STICKER_UNKNOWN,
  MKTP_ENERGY_DIESEL,
  MKTP_ENERGY_ELECTRIC,
  MKTP_ENERGY_GAS,
  MKTP_ENERGY_HYBRID,
  MKTP_ENERGY_UNKNOWN,
  MKTP_GEARBOX_AUTO,
  MKTP_GEARBOX_MANUAL,
  MKTP_GEARBOX_UNKNOWN,
} from '@stimcar/libs-kernel';
import type { MarketplaceTabs } from './typings/store.js';

export function getDateLabel(date?: number | null): string | undefined {
  if (isTruthy(date)) {
    return globalHelpers.getDDmmYYYYDateOrPlaceholderFromTimestamp(date, '');
  }
  return undefined;
}

export function getPriceLabel(price?: number): string | undefined {
  if (isTruthy(price)) {
    return formatPrice(price);
  }
  return undefined;
}

export function getMileageLabel(mileage?: number): string | undefined {
  if (isTruthy(mileage)) {
    return formatKilometers(mileage);
  }
  return undefined;
}

export function getNumberLabel(number?: number): string | undefined {
  if (isTruthy(number)) {
    return String(number);
  }
  return undefined;
}

export function getEnergyLabel(energy?: string): string {
  if (isTruthyAndNotEmpty(energy)) {
    return t(`details:tabs.marketplace.mainSpecs.energyValues.${energy}`);
  }
  return t('details:tabs.marketplace.mainSpecs.energyValues.unknown');
}

export function getGearboxLabel(gearbox?: string): string {
  if (isTruthyAndNotEmpty(gearbox)) {
    return t(`details:tabs.marketplace.mainSpecs.gearboxValues.${gearbox}`);
  }
  return t('details:tabs.marketplace.mainSpecs.gearboxValues.unknown');
}

export function getEcoStickerLabel(ecoSticker?: string): string {
  if (isTruthyAndNotEmpty(ecoSticker)) {
    return t(`details:tabs.marketplace.mainSpecs.ecoStickerValues.${ecoSticker}`);
  }
  return t('details:tabs.marketplace.mainSpecs.ecoStickerValues.unknown');
}

export function computeGearboxValues(): SimpleOption<EnergyValue>[] {
  return [
    {
      label: t('details:tabs.marketplace.mainSpecs.gearboxValues.unknown'),
      value: MKTP_GEARBOX_UNKNOWN,
    },
    {
      label: t('details:tabs.marketplace.mainSpecs.gearboxValues.auto'),
      value: MKTP_GEARBOX_AUTO,
    },
    {
      label: t('details:tabs.marketplace.mainSpecs.gearboxValues.manual'),
      value: MKTP_GEARBOX_MANUAL,
    },
  ];
}

export function computeEnergyValues(): SimpleOption<EnergyValue>[] {
  return [
    {
      label: t('details:tabs.marketplace.mainSpecs.energyValues.unknown'),
      value: MKTP_ENERGY_UNKNOWN,
    },
    {
      label: t('details:tabs.marketplace.mainSpecs.energyValues.diesel'),
      value: MKTP_ENERGY_DIESEL,
    },
    {
      label: t('details:tabs.marketplace.mainSpecs.energyValues.gas'),
      value: MKTP_ENERGY_GAS,
    },
    {
      label: t('details:tabs.marketplace.mainSpecs.energyValues.hybrid'),
      value: MKTP_ENERGY_HYBRID,
    },
    {
      label: t('details:tabs.marketplace.mainSpecs.energyValues.electric'),
      value: MKTP_ENERGY_ELECTRIC,
    },
  ];
}

export function computeEcoStickerValues(): SimpleOption<EcoStickerValue>[] {
  return [
    {
      label: t('details:tabs.marketplace.mainSpecs.ecoStickerValues.unknown'),
      value: MKTP_ECO_STICKER_UNKNOWN,
    },
    {
      label: t('details:tabs.marketplace.mainSpecs.ecoStickerValues.elec-or-hydro'),
      value: MKTP_ECO_STICKER_ELEC_OR_HYDRO,
    },
    {
      label: t('details:tabs.marketplace.mainSpecs.ecoStickerValues.level-1'),
      value: MKTP_ECO_STICKER_LVL_1,
    },
    {
      label: t('details:tabs.marketplace.mainSpecs.ecoStickerValues.level-2'),
      value: MKTP_ECO_STICKER_LVL_2,
    },
    {
      label: t('details:tabs.marketplace.mainSpecs.ecoStickerValues.level-3'),
      value: MKTP_ECO_STICKER_LVL_3,
    },
    {
      label: t('details:tabs.marketplace.mainSpecs.ecoStickerValues.level-4'),
      value: MKTP_ECO_STICKER_LVL_4,
    },
    {
      label: t('details:tabs.marketplace.mainSpecs.ecoStickerValues.level-5'),
      value: MKTP_ECO_STICKER_LVL_5,
    },
  ];
}

export function computeMarketplaceTabs(): Record<MarketplaceTabs, string | TabElementDef> {
  return {
    overview: {
      label: t('details:tabs.marketplace.overview.title'),
      icon: { id: 'file-invoice-dollar' },
    },
    specs: {
      label: t('details:tabs.marketplace.equipmentTitle'),
      icon: { id: 'list' },
    },
    preview: {
      label: t('details:tabs.marketplace.previewTitle'),
      icon: { id: 'globe' },
    },
    photoAttachmentsGallery: {
      label: t('details:tabs.marketplace.photoAttachmentsGallery'),
      icon: { id: 'image' },
    },
    textAttachmentsGallery: {
      label: t('details:tabs.marketplace.textAttachmentsGallery'),
      icon: { id: 'brands/markdown' },
    },
  };
}
