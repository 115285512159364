import type { Fetch } from '@stimcar/libs-kernel';
import { Logger } from '@stimcar/libs-kernel';
import type {
  CounterMetric,
  HistogramMetric,
  HistogramTimerFunction,
  MetricLabelValues,
  MetricsRegistry,
} from '../typings/metrics.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

const HTTP_REQUEST_METRICS_LABELS = ['statusCode', 'hostPort'] as const;

type HttpRequestMetricLabelsType = (typeof HTTP_REQUEST_METRICS_LABELS)[number];

interface HttpRequestMetrics {
  readonly requestDurationHistogram: HistogramMetric<HttpRequestMetricLabelsType>;
  readonly responseContentLengthCounter: CounterMetric<HttpRequestMetricLabelsType>;
}

export const getHttpRequestMetrics = (metricsRegistry: MetricsRegistry): HttpRequestMetrics => ({
  requestDurationHistogram: metricsRegistry.histogram<HttpRequestMetricLabelsType>({
    name: 'http_client_request_duration_histogram',
    help: 'HTTP client request duration histogram',
    labelNames: HTTP_REQUEST_METRICS_LABELS,
  }),
  responseContentLengthCounter: metricsRegistry.counter<HttpRequestMetricLabelsType>({
    name: 'http_client_response_content_length_total',
    help: 'HTTP client response content length total',
    labelNames: HTTP_REQUEST_METRICS_LABELS,
  }),
});

export function updateHttpRequestMetrics(
  statusCode: number | undefined,
  contentLengthHeader: string | undefined | null,
  uri: string,
  requestDurationHistogramTimerFunction: HistogramTimerFunction<HttpRequestMetricLabelsType>,
  requestContentLengthCounter: CounterMetric<HttpRequestMetricLabelsType>
): void {
  try {
    const hostPortRegexp = new RegExp('^https?://[^/]*', 'g');
    const regExpResult = hostPortRegexp.exec(uri);
    const hostPort = (regExpResult !== null ? regExpResult[0] : '')
      .replace(/^https?:\/\//, '') // Remove protocol
      .replace(/^[^@]*@/, ''); // Remove "<login>:<password>@" if present
    const labels: MetricLabelValues<HttpRequestMetricLabelsType> = {
      statusCode: statusCode === undefined ? 0 : statusCode,
      hostPort,
    };
    requestDurationHistogramTimerFunction(labels);
    if (contentLengthHeader) {
      requestContentLengthCounter.inc(labels, Number.parseInt(contentLengthHeader, 10));
    }
  } catch (ignored) {
    // Only log : a probe must not interrupt the processing if it fails
    log.warn('Unexpected error during probe:', ignored);
  }
}

const IS_FETCH_WITH_METRICS_FIELD_NAME = 'isFetchWithMetrics';

export function wrapFetchWithMetrics(fetch: Fetch, metricsRegistry: MetricsRegistry): Fetch {
  const isFetchWithMetrics = Reflect.get(fetch, IS_FETCH_WITH_METRICS_FIELD_NAME);
  // If the given fetch is already wrapped, don't wrap it again
  if (isFetchWithMetrics) {
    return fetch;
  }
  const metrics = getHttpRequestMetrics(metricsRegistry);
  const wrapped = async function fetchWithMetrics(
    input: RequestInfo,
    init?: RequestInit
  ): Promise<Response> {
    // Prepare duration histogram metric...
    const httpRequestDurationHistorgamMetricTimer = metrics.requestDurationHistogram.startTimer();
    // Invoke...
    const response = await fetch(input, init);
    // Update metrics (safely, ignoring potential exceptions)
    updateHttpRequestMetrics(
      response.status,
      response.headers.get('content-length'),
      typeof input === 'object' ? input.url : input,
      httpRequestDurationHistorgamMetricTimer,
      metrics.responseContentLengthCounter
    );
    return response;
  };
  // Hide a marker so that the wraped fetch can be recognized
  Reflect.set(wrapped, IS_FETCH_WITH_METRICS_FIELD_NAME, true);
  return wrapped;
}
