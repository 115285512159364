import i18next from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { RepositoryHTTPClient } from '@stimcar/core-libs-repository';
import type {
  CarElement,
  DocumentDesc,
  MarketplaceCategory,
  OperationDesc,
  PackageDealCategory,
  PackageDealDesc,
  SparePartDesc,
} from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
  CheckFormFieldInput,
  FormFieldEntry,
  HorizontalFormFieldProps,
  RichOption,
  SimpleOption,
} from '@stimcar/libs-uitoolkit';
import { PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX } from '@stimcar/core-libs-repository';
import {
  EMPTY_REPOSITORY_ENTITY,
  enumerate,
  forEachRecordValues,
  MARKETPLACE_CATEGORIES,
  nonDeleted,
  PACKAGE_DEAL_DESC_TEMPLATE_LABEL_SUFFIX_AND_PREFIX,
  PACKAGEDEAL_CATEGORY_IDS,
  packageDealDescHelpers,
  sortingHelpers,
} from '@stimcar/libs-base';
import { applyPayload, computePayload, isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useFormFieldWarning,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  CreatableElementListFormField,
  FaIcon,
  FormField,
  Input,
  InputFormField,
  ModalCardDialog,
  MultiListSelectModalAndListResultFormField,
  ReactSelectMultiFormField,
  SelectFormField,
  SwitchFormField,
  TextArea,
  TextAreaFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type {
  AdminPackageDealDescsState,
  EditDocumentDescModalState,
  EditPackageDealDescModalState,
  EditSparePartDescModalState,
  PackageDealDescFormDataWithWarning,
  PackageDealDescRawFormData,
} from '../typings/store.js';
import { isUniqueEntityCheck } from '../../../utils/coreFormChecks.js';
import { validateExpression } from '../adminPackageDealDescUtils.js';
import {
  EDIT_DOCUMENT_DESC_MODAL_EMPTY_STATE,
  EDIT_SPARE_PART_DESC_MODAL_EMPTY_STATE,
  EMPTY_DOCUMENT_DESC_FORM,
  EMPTY_EDIT_PACKAGE_DEAL_DESC_MODAL_STATE,
  EMPTY_PACKAGE_DEAL_DESC_FORM,
  EMPTY_SPARE_PART_DESC_FORM,
} from '../typings/store.js';
import { EditDocumentDescModal } from './EditDocumentDescModal.js';
import {
  EditOperationDescModal,
  operationDescUpdateNotificationInEditDialogAction,
} from './EditOperationDescModal.js';
import { EditSparePartDescModal } from './EditSparePartDescModal.js';
import { OperationDescListFormField } from './OperationDescListFormField.js';

function convertPackageDealDescToFormState(
  packageDealDesc: PackageDealDesc,
  carElements: readonly CarElement[]
): PackageDealDescFormDataWithWarning {
  const {
    priceExpression,
    durationExpression,
    category,
    isMarketplace,
    marketplaceBuyCategory,
    marketplaceSaleCategory,
    tags,
    carElementIds,
    operationDescs,
    variableDescs,
    isSubcontractable,
    ...packageDealRest
  } = packageDealDesc;
  const mecaCarElementIds: string[] = [];
  const exteCarElementIds: string[] = [];
  const inteCarElementIds: string[] = [];
  const bumpCarElementIds: string[] = [];

  carElements
    .filter((ce) => carElementIds.some((id) => id === ce.id))
    .forEach((ce) => {
      switch (ce.category) {
        case 'BUMP':
          bumpCarElementIds.push(ce.id);
          break;
        case 'EXTE':
          exteCarElementIds.push(ce.id);
          break;
        case 'INTE':
          inteCarElementIds.push(ce.id);
          break;
        case 'MECA':
          mecaCarElementIds.push(ce.id);
          break;
        default:
          break;
      }
    });

  const variableDescsString =
    packageDealDescHelpers.convertVariableDescTypeToDisplayedString(variableDescs);

  return {
    ...packageDealRest,
    priceExpression:
      packageDealDescHelpers.convertPackageDealRelatedExpressionToDisplayedString(priceExpression),
    variableDescs,
    variableDescsString,
    durationExpression:
      packageDealDescHelpers.convertPackageDealRelatedExpressionToDisplayedString(
        durationExpression
      ),
    isSubcontractable,
    bumpCarElementIds,
    category,
    isMarketplace,
    marketplaceBuyCategory,
    marketplaceSaleCategory,
    tags,
    exteCarElementIds,
    inteCarElementIds,
    mecaCarElementIds,
    operationDescs,
    warnings: {},
  };
}

function computePackageDealDescFormStatePayload(
  initialPackageDealDesc: PackageDealDesc | undefined,
  packageDealDesc: PackageDealDescFormDataWithWarning,
  carElements: readonly CarElement[]
): undefined | DeepPartial<PackageDealDescFormDataWithWarning> {
  return initialPackageDealDesc === undefined
    ? packageDealDesc
    : computePayload(
        convertPackageDealDescToFormState(initialPackageDealDesc, carElements),
        packageDealDesc
      );
}

export async function openUpdatePackageDealDescModalAction(
  { actionDispatch, getState }: ActionContext<Store, AdminPackageDealDescsState>,
  selectedPackageDealDescId: string | undefined
) {
  const { carElements, items } = getState();
  const packageDealDesc = items.find(({ id }) => id === selectedPackageDealDescId)!;
  const nestedActionDispatch = actionDispatch.scopeProperty('editPackageDealDescModal');
  nestedActionDispatch.setValue({
    ...EMPTY_EDIT_PACKAGE_DEAL_DESC_MODAL_STATE,
    initialPackageDealDesc: packageDealDesc,
    formData: convertPackageDealDescToFormState(packageDealDesc, carElements),
    editSparePartDescModalState: {
      ...EMPTY_EDIT_PACKAGE_DEAL_DESC_MODAL_STATE.editSparePartDescModalState,
      sparePartDescs: packageDealDesc.sparePartDescs,
    },
    editDocumentDescModalState: {
      ...EMPTY_EDIT_PACKAGE_DEAL_DESC_MODAL_STATE.editDocumentDescModalState,
      documentDescs: packageDealDesc.documentDescs,
    },
  });
  await actionDispatch.exec(doOpenPackageDealDescModalAction);
}

export async function openClonePackageDealDescModalAction(
  { actionDispatch, getState, httpClient }: ActionContext<Store, AdminPackageDealDescsState>,
  selectedPackageDealDescId: string | undefined
) {
  const { carElements, items } = getState();
  const packageDealDesc = items.find(({ id }) => id === selectedPackageDealDescId)!;
  const nestedActionDispatch = actionDispatch.scopeProperty('editPackageDealDescModal');
  nestedActionDispatch.setValue({
    ...EMPTY_EDIT_PACKAGE_DEAL_DESC_MODAL_STATE,
    initialPackageDealDesc: undefined,
    formData: {
      ...convertPackageDealDescToFormState(packageDealDesc, carElements),
      operationDescs: packageDealDesc.operationDescs.filter(nonDeleted).map((od) => ({
        ...od,
        // Override identifiers (clone)
        id: httpClient.getBrowserSequence().next(),
      })),
    },
    editSparePartDescModalState: {
      ...EMPTY_EDIT_PACKAGE_DEAL_DESC_MODAL_STATE.editSparePartDescModalState,
      sparePartDescs: packageDealDesc.sparePartDescs
        .filter(nonDeleted)
        // Override identifiers (clone)
        .map(({ label }) => ({ id: httpClient.getBrowserSequence().next(), label })),
    },
    editDocumentDescModalState: {
      ...EDIT_DOCUMENT_DESC_MODAL_EMPTY_STATE,
      documentDescs: packageDealDesc.documentDescs
        .filter(nonDeleted)
        // Override identifiers (clone)
        .map(({ label, standId, targetFolder }) => ({
          id: httpClient.getBrowserSequence().next(),
          label,
          standId,
          targetFolder,
        })),
    },
  });
  await actionDispatch.exec(doOpenPackageDealDescModalAction);
}

export async function openCreatePackageDealDescModalAction(
  { actionDispatch }: ActionContext<Store, AdminPackageDealDescsState>,
  database: string
) {
  const nestedActionDispatch = actionDispatch.scopeProperty('editPackageDealDescModal');

  nestedActionDispatch.setValue({
    ...EMPTY_EDIT_PACKAGE_DEAL_DESC_MODAL_STATE,
    initialPackageDealDesc: undefined,
    formData: { ...EMPTY_PACKAGE_DEAL_DESC_FORM, database },
  });

  await actionDispatch.exec(doOpenPackageDealDescModalAction);
}

function doOpenPackageDealDescModalAction({
  actionDispatch,
  getState,
}: ActionContext<Store, AdminPackageDealDescsState>): void {
  const { carElements, items } = getState();
  const nestedActionDispatch = actionDispatch.scopeProperty('editPackageDealDescModal');
  const availableTags = new Set<string>();
  items.forEach((pdd) => {
    pdd.tags.forEach((t) => availableTags.add(t));
  });
  nestedActionDispatch.setProperty('availableTags', [...availableTags]);
  nestedActionDispatch.setProperty('carElements', carElements);
  nestedActionDispatch.setProperty('active', true);
}

export async function packageDealDescUpdateNotificationInEditDialogAction(
  { getState, actionDispatch }: ActionContext<Store, EditPackageDealDescModalState>,
  updated: PackageDealDesc
): Promise<void> {
  const { active, initialPackageDealDesc, carElements, selectedOdID, formData } = getState();

  if (active && initialPackageDealDesc && initialPackageDealDesc.id === updated.id) {
    actionDispatch.setProperty('initialPackageDealDesc', updated);
    const updatedToFormState = convertPackageDealDescToFormState(updated, carElements);
    const payload = computePackageDealDescFormStatePayload(
      initialPackageDealDesc,
      formData,
      carElements
    );
    const newFormState = payload ? applyPayload(updatedToFormState, payload) : updatedToFormState;
    actionDispatch.setProperty('formData', newFormState);

    const selectedOD = updated.operationDescs.find((od) => od.id === selectedOdID);
    if (selectedOD) {
      await actionDispatch
        .scopeProperty('editOperationDescModalState')
        .exec(operationDescUpdateNotificationInEditDialogAction, selectedOD);
    }
  }
}

function createPDDFromFormData(
  initialPackageDealDesc: PackageDealDesc | undefined,
  httpClient: RepositoryHTTPClient,
  formData: PackageDealDescFormDataWithWarning,
  sparePartDescs: readonly SparePartDesc[],
  documentDescs: readonly DocumentDesc[]
): PackageDealDesc {
  const {
    bumpCarElementIds,
    exteCarElementIds,
    inteCarElementIds,
    mecaCarElementIds,
    tags,
    variableDescs,
    priceExpression,
    durationExpression,
    ...rest
  } = formData;

  const newOperationDescs = formData.operationDescs.map((od): OperationDesc => {
    if (isTruthyAndNotEmpty(od.id)) {
      return od;
    }
    return { ...od, id: httpClient.getBrowserSequence().next() };
  });

  const newSparePartDescs = sparePartDescs.map((sp): SparePartDesc => {
    if (isTruthyAndNotEmpty(sp.id)) {
      return sp;
    }
    return { ...sp, id: httpClient.getBrowserSequence().next() };
  });

  const newDocumentDescs = documentDescs.map(
    (doc): DocumentDesc =>
      isTruthyAndNotEmpty(doc.id) ? doc : { ...doc, id: httpClient.getBrowserSequence().next() }
  );

  let id = '';
  if (isTruthy(initialPackageDealDesc)) {
    id = initialPackageDealDesc.id;
  } else if (isTruthy(httpClient)) {
    id = httpClient.getBrowserSequence().next();
  }
  const newPackageDealDesc: PackageDealDesc = {
    ...(!initialPackageDealDesc ? EMPTY_REPOSITORY_ENTITY : initialPackageDealDesc),
    ...rest,
    status: 'open',
    priceExpression:
      packageDealDescHelpers.convertPackageDealRelatedExpressionToStoredString(priceExpression),
    variableDescs,
    tags,
    durationExpression:
      packageDealDescHelpers.convertPackageDealRelatedExpressionToStoredString(durationExpression),
    sparePartDescs: newSparePartDescs,
    operationDescs: newOperationDescs,
    documentDescs: newDocumentDescs,
    attributes: {},
    carElementIds: [
      ...bumpCarElementIds,
      ...exteCarElementIds,
      ...inteCarElementIds,
      ...mecaCarElementIds,
    ],
    category: rest.category as PackageDealCategory,
    marketplaceBuyCategory: rest.marketplaceBuyCategory as MarketplaceCategory,
    marketplaceSaleCategory: rest.marketplaceSaleCategory as MarketplaceCategory,
    id,
  };
  return newPackageDealDesc;
}

async function savePackageDealDescAction({
  packageDealDescRepository,
  getState,
  actionDispatch,
  httpClient,
}: ActionContext<Store, EditPackageDealDescModalState>): Promise<void> {
  const {
    initialPackageDealDesc,
    formData,
    editSparePartDescModalState,
    editDocumentDescModalState,
  } = getState();

  const newPackageDealDesc = createPDDFromFormData(
    initialPackageDealDesc,
    httpClient,
    formData,
    editSparePartDescModalState.sparePartDescs,
    editDocumentDescModalState.documentDescs
  );

  if (!initialPackageDealDesc) {
    await packageDealDescRepository.createEntity(newPackageDealDesc);
  } else {
    const payload = computePayload(initialPackageDealDesc, newPackageDealDesc);
    if (payload !== undefined) {
      await packageDealDescRepository.updateEntityFromPayload({
        entityId: newPackageDealDesc.id,
        payload,
      });
    }
  }
  // Close the dialog
  actionDispatch.setProperty('active', false);
}

const mandatoryPackageDealDescFields: (keyof PackageDealDescRawFormData)[] = [
  'code',
  'category',
  'label',
  'durationExpression',
  'priceExpression',
  'isSubcontractable',
];

function isUniquePackageDealDesc<K extends keyof PackageDealDescRawFormData>(
  key: 'code' | 'label'
) {
  return async ({
    packageDealDescRepository,
    formState,
    value,
    t,
  }: CheckFormFieldInput<Store, EditPackageDealDescModalState, K>): Promise<string | undefined> => {
    const { initialPackageDealDesc } = formState;
    const isUnique = await isUniqueEntityCheck(
      packageDealDescRepository,
      initialPackageDealDesc,
      key,
      value,
      PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
      formState.formData.database
    );
    return !isUnique ? t(`packageDealDescModal.form.warnings.${key}AlreadyUsed`) : undefined;
  };
}

function getSubcontractablePackageDealDescWarnings(
  operationDescs: readonly OperationDesc[],
  documentDescs: readonly DocumentDesc[]
): string | undefined {
  const activeOperations = operationDescs.filter(nonDeleted);
  const nbOfOperationsAndDocumentsForPackageDealCompletion =
    activeOperations.length + documentDescs.filter(nonDeleted).length;
  if (nbOfOperationsAndDocumentsForPackageDealCompletion === 0) {
    return i18next.t(
      'packageDealDescs:packageDealDescModal.form.warnings.subcontractableModeRequiresOneOperationOrDocument'
    );
  }
  if (nbOfOperationsAndDocumentsForPackageDealCompletion > 1) {
    return i18next.t(
      'packageDealDescs:packageDealDescModal.form.warnings.subcontractableModeOnlyAllowsOneOperationOrDocument'
    );
  }
  return undefined;
}

const checkFieldContentActions: CheckFormFieldContentActions<Store, EditPackageDealDescModalState> =
  {
    label: isUniquePackageDealDesc('label'),
    code: isUniquePackageDealDesc('code'),
    durationExpression: ({ t, value, formState }) => {
      const { tags, variableDescs } = formState.formData;
      return validateExpression(t, value, variableDescs, tags);
    },
    priceExpression: ({ t, value, formState }) => {
      const { tags, variableDescs } = formState.formData;
      return validateExpression(t, value, variableDescs, tags);
    },
    variableDescsString: ({ value, t, formState }): string | undefined => {
      const { tags } = formState.formData;
      const globalVariables = packageDealDescHelpers
        .getGlobalPackageDealDescVariables(tags)
        .map((v) => v.label);
      switch (packageDealDescHelpers.validatePackageDealDescVariableDeclarations(value, tags)) {
        case 'duplicateIds':
          return t('packageDealDescModal.form.warnings.duplicateIds');
        case 'incorrectId':
          return t('packageDealDescModal.form.warnings.incorrectVariableDescId');
        case 'incorrectSyntax':
        case 'incorrectStructure':
        case 'incorrectValuesType':
          return t('packageDealDescModal.form.warnings.incorrectVariableDesc');
        case 'incorrectDefaultValue':
          return t('packageDealDescModal.form.warnings.incorrectVariableDescDefaultValue');
        case 'overrideGlobal':
          return t('packageDealDescModal.form.warnings.overrideGlobalVariable', {
            globalVariables: enumerate(globalVariables),
          });
        default:
          // Empty case is not an issue because if this is handled by the "mandatory" value of  the
          // form framework.
          return undefined;
      }
    },
    operationDescs: ({ value, formState }): string | undefined => {
      if (formState.formData.isSubcontractable) {
        return getSubcontractablePackageDealDescWarnings(
          value,
          formState.editDocumentDescModalState.documentDescs
        );
      }
      return undefined;
    },
  };

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  EditPackageDealDescModalState
> = ({ formState, t }): string | undefined => {
  const { initialPackageDealDesc, carElements, formData, editSparePartDescModalState } = formState;
  if (initialPackageDealDesc) {
    // Check if PDD form has been modified
    const payload = computePackageDealDescFormStatePayload(
      initialPackageDealDesc,
      formData,
      carElements
    );
    const updated = payload !== undefined && Reflect.ownKeys(payload).length !== 0;
    if (!updated) {
      // Check if spare parts descs have been modified
      const sparePartsModified = computePayload(
        initialPackageDealDesc.sparePartDescs,
        editSparePartDescModalState.sparePartDescs
      );
      if (!sparePartsModified) {
        return t('globals:warnings.noChange');
      }
    }
  }

  if (formState.formData.isSubcontractable) {
    const warnings = getSubcontractablePackageDealDescWarnings(
      formState.formData.operationDescs,
      formState.editDocumentDescModalState.documentDescs
    );
    if (isTruthyAndNotEmpty(warnings)) {
      return warnings;
    }
  }

  return undefined;
};

function sortMultiListComponentEntries(
  a: FormFieldEntry<string>,
  b: FormFieldEntry<string>
): number {
  return a.label.localeCompare(b.label);
}

function initializeEditSparePartDescModalState(
  { actionDispatch, httpClient }: ActionContext<Store, EditSparePartDescModalState>,
  id?: string
): void {
  let formData = EMPTY_SPARE_PART_DESC_FORM;
  actionDispatch.reduce((initial): EditSparePartDescModalState => {
    const initialSparePartDesc = initial.sparePartDescs.find((sp) => sp.id === id);
    if (isTruthy(initialSparePartDesc)) {
      formData = {
        ...formData,
        id: initialSparePartDesc.id,
        label: initialSparePartDesc.label,
      };
    } else {
      formData = {
        ...formData,
        id: httpClient.getBrowserSequence().next(),
      };
    }
    return {
      ...EDIT_SPARE_PART_DESC_MODAL_EMPTY_STATE,
      active: true,
      sparePartDescs: initial.sparePartDescs,
      initialSparePartDesc,
      formData,
    };
  });
}

function deleteSparePartDescAction(
  { actionDispatch }: ActionContext<Store, EditSparePartDescModalState>,
  id: string
): void {
  actionDispatch.reduce((initial) => {
    const modifiedSparePartDescs = initial.sparePartDescs.map((sp) => {
      if (sp.id === id) {
        return {
          ...sp,
          deleted: true,
        };
      }
      return sp;
    });
    return {
      ...initial,
      sparePartDescs: modifiedSparePartDescs,
    };
  });
}

function deleteDocumentDescAction(
  { actionDispatch }: ActionContext<Store, EditPackageDealDescModalState>,
  id: string
): void {
  actionDispatch.reduce((initial): EditPackageDealDescModalState => {
    const updatedDocumentsDescs = initial.editDocumentDescModalState.documentDescs.map((doc) =>
      doc.id === id ? { ...doc, deleted: true } : doc
    );
    return {
      ...initial,
      selectedDocumentDescId: '',
      editDocumentDescModalState: {
        ...initial.editDocumentDescModalState,
        documentDescs: updatedDocumentsDescs,
      },
    };
  });
}

function initializeEditDocumentDescModalState(
  { actionDispatch, httpClient }: ActionContext<Store, EditDocumentDescModalState>,
  id?: string
): void {
  let formData = EMPTY_DOCUMENT_DESC_FORM;
  actionDispatch.reduce((initial): EditDocumentDescModalState => {
    const initialDocumentDesc = initial.documentDescs.find((doc) => doc.id === id);
    if (isTruthy(initialDocumentDesc)) {
      formData = {
        ...formData,
        id: initialDocumentDesc.id,
        label: initialDocumentDesc.label,
        targetFolder: initialDocumentDesc.targetFolder,
        standId: initialDocumentDesc.standId,
      };
    } else {
      formData = {
        ...formData,
        id: httpClient.getBrowserSequence().next(),
      };
    }
    return {
      ...EDIT_DOCUMENT_DESC_MODAL_EMPTY_STATE,
      active: true,
      documentDescs: initial.documentDescs,
      initialDocumentDesc,
      formData,
    };
  });
}

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  bodyFlexGrow: 5,
  labelFlexGrow: 2,
};

export function EditPackageDealDescModal({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation(['packageDealDescs', 'globals']);

  const $ = $gs.$adminView.$adminPackageDealDescs.$editPackageDealDescModal;
  const {
    $editSparePartDescModalState,
    $editDocumentDescModalState,
    $updateBumpCarElementsModalState,
    $updateExteCarElementsModalState,
    $updateInteCarElementsModalState,
    $updateMecaCarElementsModalState,
  } = $;

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => await actionDispatch.exec(savePackageDealDescAction),
    [],
    $
  );

  const [onFormSubmit, genericOnFormChange, $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditPackageDealDescModalState
  >({
    $,
    mandatoryFields: mandatoryPackageDealDescFields,
    checkFieldContentActions,
    checkFormConsistencyAction,
    submitValidDataAction,
    t,
  });

  const createNewSparePartDescActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> =>
      await actionDispatch
        .scopeProperty('editSparePartDescModalState')
        .exec(initializeEditSparePartDescModalState),
    [],
    $
  );

  const updateSparePartDescActionCallback = useActionCallback(
    async ({ actionDispatch, getState }): Promise<void> => {
      const { selectedSparePartDescId } = getState();
      await actionDispatch
        .scopeProperty('editSparePartDescModalState')
        .exec(initializeEditSparePartDescModalState, selectedSparePartDescId);
    },
    [],
    $
  );

  const deleteSparePartDescActionCallback = useActionCallback(
    async ({ actionDispatch, getState }): Promise<void> => {
      const { selectedSparePartDescId } = getState();
      await actionDispatch
        .scopeProperty('editSparePartDescModalState')
        .exec(deleteSparePartDescAction, selectedSparePartDescId);
    },
    [],
    $
  );

  const createNewDocumentDescActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> =>
      await actionDispatch
        .scopeProperty('editDocumentDescModalState')
        .exec(initializeEditDocumentDescModalState),
    [],
    $
  );

  const updateDocumentDescActionCallback = useActionCallback(
    async ({ actionDispatch, getState }): Promise<void> => {
      const { selectedDocumentDescId } = getState();
      await actionDispatch
        .scopeProperty('editDocumentDescModalState')
        .exec(initializeEditDocumentDescModalState, selectedDocumentDescId);
    },
    [],
    $
  );

  const deleteDocumentDescActionCallback = useActionCallback(
    async ({ actionDispatch, getState }): Promise<void> => {
      const { selectedDocumentDescId } = getState();
      await actionDispatch.exec(deleteDocumentDescAction, selectedDocumentDescId);
      await actionDispatch.execCallback(genericOnFormChange);
    },
    [genericOnFormChange],
    $
  );

  const tags = useGetState($formDataWithChangeTrigger.$tags);
  const variableDescs = useGetState($formDataWithChangeTrigger.$variableDescs);
  const operationDescs = useGetState($formDataWithChangeTrigger.$operationDescs);

  const availableGlobalVariables = useMemo(() => {
    return packageDealDescHelpers.getGlobalPackageDealDescVariables(tags).map((v) => v.label);
  }, [tags]);

  const availableInternalVariableNames = useMemo(() => {
    const variableNames: string[] = [];
    forEachRecordValues(variableDescs, (value, key) => {
      if (value !== null) {
        variableNames.push(key);
      }
    });
    return variableNames;
  }, [variableDescs]);

  const allAvailableVariableNames = useMemo(() => {
    return [...availableGlobalVariables, ...availableInternalVariableNames];
  }, [availableGlobalVariables, availableInternalVariableNames]);

  const initialPackageDealDesc = useGetState($.$initialPackageDealDesc);

  const isCreateMode = useMemo(
    (): boolean => initialPackageDealDesc === undefined,
    [initialPackageDealDesc]
  );

  const carElements = useGetState($.$carElements);

  const mecaCarElements = useMemo(() => {
    return carElements.filter((ce) => ce.category === 'MECA');
  }, [carElements]);

  const inteCarElements = useMemo(() => {
    return carElements.filter((ce) => ce.category === 'INTE');
  }, [carElements]);

  const exteCarElements = useMemo(() => {
    return carElements.filter((ce) => ce.category === 'EXTE');
  }, [carElements]);

  const bumpCarElements = useMemo(() => {
    return carElements.filter((ce) => ce.category === 'BUMP');
  }, [carElements]);

  const onVariableDescsStringChangeHandlerCallback = useActionCallback(
    ({ actionDispatch, getState }: ActionContext<Store, PackageDealDescFormDataWithWarning>) => {
      try {
        const variableDescs = packageDealDescHelpers.convertVariableDescStringToType(
          getState().variableDescsString
        );
        actionDispatch.reduce((initial) => {
          return {
            ...initial,
            variableDescs,
          };
        });
      } catch {
        // Do nothing, the user is editing the string
      }
    },
    [],
    $formDataWithChangeTrigger
  );

  const formWarning = useGetState($.$formWarning);
  const labelWarning = useFormFieldWarning($formDataWithChangeTrigger.$label);
  const priceExpressionWarning = useFormFieldWarning($formDataWithChangeTrigger.$priceExpression);

  const availableTags = useGetState($.$availableTags);

  const existingPackageDealCategories: FormFieldEntry<string>[] = PACKAGEDEAL_CATEGORY_IDS.map(
    (id): FormFieldEntry<string> => {
      return {
        id,
        label: t(`packageDealCategory.${id}`),
      };
    }
  ).sort(sortingHelpers.createSortByStringField('UP', 'label'));
  const existingMarketplaceCategories: FormFieldEntry<string>[] = MARKETPLACE_CATEGORIES.map(
    (id): FormFieldEntry<string> => {
      return {
        id,
        label: t(`marketplaceCategory.${id}`),
      };
    }
  );

  const existingTagsGroup = useMemo(() => {
    const tags = availableTags.map((tag): SimpleOption => {
      return {
        value: tag,
        label: tag,
      };
    });
    return [
      {
        label: t('packageDealDescModal.form.existingTagsGroupLabel'),
        options: tags,
      },
    ];
  }, [availableTags, t]);

  const formatOptionLabel = (tagOption: SimpleOption | RichOption): JSX.Element => {
    if ('icon' in tagOption && 'color' in tagOption) {
      return (
        <>
          <FaIcon id={tagOption.icon} iconColor={tagOption.color} additionalClass="spaced-icon" />
          {tagOption.label}
        </>
      );
    }
    return <>{tagOption.label}</>;
  };

  const sparePartDescs = useGetState($editSparePartDescModalState.$sparePartDescs);

  const displayedSparePartDescs = sparePartDescs.filter(nonDeleted).map(({ id, label }) => {
    return {
      id,
      label,
    };
  });

  const documentDescs = useGetState($editDocumentDescModalState.$documentDescs);

  const displayedDocumentDescs = documentDescs
    .filter(nonDeleted)
    .map(({ id, label, targetFolder, standId }) => {
      return {
        id,
        label: `${label} => ${targetFolder} (${standId})`,
      };
    });

  const stands = useGetState($gs.$siteConfiguration.$stands);
  const standIds = useMemo((): string[] => stands.map(({ id }): string => id), [stands]);

  const isMarketplace = useGetState($.$formData.$isMarketplace);

  return (
    <>
      <ModalCardDialog
        title={
          isCreateMode ? t('packageDealDescModal.titleNew') : t('packageDealDescModal.titleUpdate')
        }
        $active={$.$active}
        okLabel={
          isCreateMode
            ? t('packageDealDescModal.form.newButton')
            : t('packageDealDescModal.form.updateButton')
        }
        onOkClicked={onFormSubmit}
        warning={formWarning}
      >
        <InputFormField
          label={t('packageDealDescModal.form.code')}
          $={$formDataWithChangeTrigger.$code}
          horizontal={HORIZONTAL_PROPS}
        />
        <FormField
          warning={labelWarning}
          label={t('packageDealDescModal.form.label')}
          horizontal={HORIZONTAL_PROPS}
        >
          <Input
            $={$formDataWithChangeTrigger.$label}
            placeholder={t('packageDealDescModal.form.label')}
          />
          {availableInternalVariableNames.length > 0 && (
            <p className="help">
              {t('packageDealDescModal.form.availableVariables', {
                value: enumerate(
                  availableInternalVariableNames,
                  ', ',
                  PACKAGE_DEAL_DESC_TEMPLATE_LABEL_SUFFIX_AND_PREFIX
                ),
              })}
            </p>
          )}
        </FormField>

        <SelectFormField
          label={t('packageDealDescModal.form.category')}
          $={$formDataWithChangeTrigger.$category}
          horizontal={HORIZONTAL_PROPS}
          isEmptyValueAllowed
          entries={existingPackageDealCategories}
        />
        <SwitchFormField
          label={t('packageDealDescModal.form.isMarketplace')}
          switchId="isMarketplace"
          $={$formDataWithChangeTrigger.$isMarketplace}
          horizontal={HORIZONTAL_PROPS}
        />
        {isMarketplace && (
          <SelectFormField
            label={t('packageDealDescModal.form.marketplaceBuyCategory')}
            $={$formDataWithChangeTrigger.$marketplaceBuyCategory}
            horizontal={HORIZONTAL_PROPS}
            entries={existingMarketplaceCategories}
          />
        )}
        {isMarketplace && (
          <SelectFormField
            label={t('packageDealDescModal.form.marketplaceSaleCategory')}
            $={$formDataWithChangeTrigger.$marketplaceSaleCategory}
            horizontal={HORIZONTAL_PROPS}
            entries={existingMarketplaceCategories}
          />
        )}
        <ReactSelectMultiFormField
          label={t('packageDealDescModal.form.tags')}
          creation
          $={$formDataWithChangeTrigger.$tags}
          suggestions={existingTagsGroup}
          formatOptionLabel={formatOptionLabel}
          isClearable
          horizontal={HORIZONTAL_PROPS}
        />
        <TextAreaFormField
          label="Variables"
          $={useSelectorWithChangeTrigger(
            $formDataWithChangeTrigger.$variableDescsString,
            onVariableDescsStringChangeHandlerCallback
          )}
          horizontal={HORIZONTAL_PROPS}
        />
        <FormField
          label={t('packageDealDescModal.form.durationExpression')}
          warning={useFormFieldWarning($formDataWithChangeTrigger.$durationExpression)}
          horizontal={HORIZONTAL_PROPS}
        >
          <TextArea
            $={$formDataWithChangeTrigger.$durationExpression}
            placeholder={t('packageDealDescModal.form.durationExpressionPlaceholder')}
          />
          {allAvailableVariableNames.length > 0 && (
            <p className="help">
              {t('packageDealDescModal.form.availableVariables', {
                value: enumerate(allAvailableVariableNames),
              })}
            </p>
          )}
        </FormField>
        <FormField
          label={t('packageDealDescModal.form.priceExpression')}
          warning={priceExpressionWarning}
          horizontal={HORIZONTAL_PROPS}
        >
          <TextArea
            $={$formDataWithChangeTrigger.$priceExpression}
            placeholder={t('packageDealDescModal.form.priceExpressionPlaceholder')}
          />
          {allAvailableVariableNames.length > 0 && (
            <p className="help">
              {t('packageDealDescModal.form.availableVariables', {
                value: enumerate(allAvailableVariableNames),
              })}
            </p>
          )}
        </FormField>
        <SwitchFormField
          label={t('packageDealDescModal.form.overridablePrice')}
          switchId="overridablePrice"
          $={$formDataWithChangeTrigger.$overridablePrice}
          horizontal={HORIZONTAL_PROPS}
        />
        <SwitchFormField
          label={t('packageDealDescModal.form.ignoreVAT')}
          switchId="invoiceVAT"
          $={$formDataWithChangeTrigger.$ignoreVAT}
          horizontal={HORIZONTAL_PROPS}
        />
        <MultiListSelectModalAndListResultFormField
          label={t('packageDealDescModal.form.exteCarElements')}
          $selection={$formDataWithChangeTrigger.$exteCarElementIds}
          sortingFunction={sortMultiListComponentEntries}
          entries={exteCarElements}
          $modalIsActive={$updateExteCarElementsModalState.$isActive}
          $preSelection={$updateExteCarElementsModalState.$preSelectedCarElements}
          $preUnselect={$updateExteCarElementsModalState.$preUnselectedCarElements}
          modalTitle={t('packageDealDescModal.form.exteCarElementsModalTitle')}
          horizontal={HORIZONTAL_PROPS}
        />
        <MultiListSelectModalAndListResultFormField
          label={t('packageDealDescModal.form.bumpCarElements')}
          $selection={$formDataWithChangeTrigger.$bumpCarElementIds}
          sortingFunction={sortMultiListComponentEntries}
          entries={bumpCarElements}
          $modalIsActive={$updateBumpCarElementsModalState.$isActive}
          $preSelection={$updateBumpCarElementsModalState.$preSelectedCarElements}
          $preUnselect={$updateBumpCarElementsModalState.$preUnselectedCarElements}
          modalTitle={t('packageDealDescModal.form.bumpCarElementsModalTitle')}
          horizontal={HORIZONTAL_PROPS}
        />
        <MultiListSelectModalAndListResultFormField
          label={t('packageDealDescModal.form.inteCarElements')}
          $selection={$formDataWithChangeTrigger.$inteCarElementIds}
          sortingFunction={sortMultiListComponentEntries}
          entries={inteCarElements}
          $modalIsActive={$updateInteCarElementsModalState.$isActive}
          $preSelection={$updateInteCarElementsModalState.$preSelectedCarElements}
          $preUnselect={$updateInteCarElementsModalState.$preUnselectedCarElements}
          modalTitle={t('packageDealDescModal.form.inteCarElementsModalTitle')}
          horizontal={HORIZONTAL_PROPS}
        />
        <MultiListSelectModalAndListResultFormField
          label={t('packageDealDescModal.form.mecaCarElements')}
          $selection={$formDataWithChangeTrigger.$mecaCarElementIds}
          sortingFunction={sortMultiListComponentEntries}
          entries={mecaCarElements}
          $modalIsActive={$updateMecaCarElementsModalState.$isActive}
          $preSelection={$updateMecaCarElementsModalState.$preSelectedCarElements}
          $preUnselect={$updateMecaCarElementsModalState.$preUnselectedCarElements}
          modalTitle={t('packageDealDescModal.form.mecaCarElementsModalTitle')}
          horizontal={HORIZONTAL_PROPS}
        />
        <CreatableElementListFormField
          createElement={createNewSparePartDescActionCallback}
          deleteSelectedElement={deleteSparePartDescActionCallback}
          editSelectedElement={updateSparePartDescActionCallback}
          elements={displayedSparePartDescs}
          label={t('packageDealDescModal.form.spareParts')}
          $selectedElementId={$.$selectedSparePartDescId}
          horizontal={HORIZONTAL_PROPS}
        />
        <SwitchFormField
          label={t('packageDealDescModal.form.isSubcontractable')}
          switchId="isSubcontractable"
          $={$formDataWithChangeTrigger.$isSubcontractable}
          horizontal={HORIZONTAL_PROPS}
        />
        <OperationDescListFormField
          $={$}
          horizontal={HORIZONTAL_PROPS}
          noExclamationTriangleIfWarning
          operationDescs={operationDescs}
          runValidationGlobalCallback={genericOnFormChange}
          label={t('packageDealDescModal.form.operationDescs')}
        />
        <CreatableElementListFormField
          horizontal={HORIZONTAL_PROPS}
          elements={displayedDocumentDescs}
          $selectedElementId={$.$selectedDocumentDescId}
          label={t('packageDealDescModal.form.documents')}
          createElement={createNewDocumentDescActionCallback}
          editSelectedElement={updateDocumentDescActionCallback}
          deleteSelectedElement={deleteDocumentDescActionCallback}
        />
        <TextAreaFormField
          label={t('packageDealDescModal.form.hint')}
          placeholder={t('packageDealDescModal.form.hint-tooltip')}
          $={$formDataWithChangeTrigger.$hint}
          horizontal={HORIZONTAL_PROPS}
        />
      </ModalCardDialog>
      <EditSparePartDescModal $={$editSparePartDescModalState} localVariables={variableDescs} />
      <EditOperationDescModal
        $gs={$gs}
        runValidationGlobalCallback={genericOnFormChange}
        localVariables={variableDescs}
        tags={tags}
      />
      <EditDocumentDescModal
        standIds={standIds}
        $={$.$editDocumentDescModalState}
        runValidationGlobalCallback={genericOnFormChange}
      />
    </>
  );
}
