/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from 'react';
import type {
  Kanban,
  KanbanHandling,
  WorkshopPostCategory,
  WorkshopStandImplantation,
} from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  globalHelpers,
  kanbanHelpers,
  KanbanPriorityLevel,
  packageDealHelpers,
  shortDayMonthDateFormatOptions,
  shortDayMonthWithHourFormatOptions,
  sortingHelpers,
  transverseHelpers,
  workshopHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import {
  ActionButtonComponent,
  actionButtonDescIndexComparator,
  FaIcon,
  KanbanIdentityPictureComponent,
  TruncableP,
  useActionButtonDescs,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type {
  KanbanDropHandlerSignature,
  WorkshopActionProviderType,
  WorkshopImplantationViewState,
} from '../typings/store.js';
import { PackageDealDecorators } from '../../../../app/utils/PackageDealDecorators.js';
import { useComputeAttachmentUrl } from '../../../../app/utils/useComputeAttachmentUrl.js';
import { useGetIconForWorkshopPost } from './useGetIconForWorkshopPost.js';
import { computeAnomalyPostQualifiedCategoryId } from './workshopImplantationUtils.js';

interface BaseWorkshopPostViewerProps {
  readonly kanban?: Kanban;
  readonly implantation: WorkshopStandImplantation;
  readonly category: WorkshopPostCategory;
  readonly postName: string;
  readonly postDisplayedLabel?: string;
  readonly postIconId?: string;
  readonly isVerticalNarrow?: boolean;
  readonly isHighlighted?: boolean;
  readonly dropHandler?: KanbanDropHandlerSignature;
  readonly actionsProvider?: WorkshopActionProviderType;
  readonly isOnline: boolean;
  readonly allKanbansInImplantation: readonly Kanban[];
  readonly standId: string;
}

interface WorkshopPostViewerWithOperationsOverviewProps
  extends BaseWorkshopPostViewerProps,
    AppProps<Store> {
  readonly openShowOperationsModal: (kanban: Kanban, qualifiedPostId: string) => Promise<void>;
  readonly userName: string;
  readonly openActionUnavailableWhenDisconnectedModal: () => void;
  readonly $: StoreStateSelector<Store, WorkshopImplantationViewState>;
}

export function WorkshopPostViewerWithOperationsOverview({
  kanban,
  category,
  postDisplayedLabel,
  implantation,
  dropHandler,
  actionsProvider,
  postName,
  postIconId,
  isVerticalNarrow = false,
  isHighlighted = false,
  isOnline,
  openShowOperationsModal,
  userName,
  openActionUnavailableWhenDisconnectedModal,
  allKanbansInImplantation,
  standId,
  $,
  $gs,
}: WorkshopPostViewerWithOperationsOverviewProps): JSX.Element {
  const { unfinishedOperations, finishedOperations, totalOperations } = useMemo(() => {
    const packageDeals = kanban?.packageDeals ?? [];
    const qualifiedPostId = globalHelpers.computeQualifiedWorkshopPostId(
      implantation.id,
      category.id,
      postName
    );
    const unfinished = workshopHelpers.getAllUnfinishedOperationsSoFar(
      packageDealHelpers.getAvailablePackageDeals(packageDeals),
      standId,
      implantation,
      qualifiedPostId
    );
    const finished = packageDealHelpers.getAllFinishedOperationsForWorkshopPostCategoryInStand(
      packageDealHelpers.getAvailablePackageDeals(packageDeals),
      implantation.id,
      category.id,
      standId
    );
    return {
      unfinishedOperations: unfinished,
      finishedOperations: finished,
      totalOperations: [...unfinished, ...finished],
    };
  }, [kanban?.packageDeals, implantation, category.id, postName, standId]);

  const totalWorkload = useMemo(() => {
    return [...unfinishedOperations, ...finishedOperations].reduce((acc, o) => acc + o.workload, 0);
  }, [finishedOperations, unfinishedOperations]);

  const finishedWorkload = useMemo(() => {
    return finishedOperations.reduce((acc, o) => acc + o.workload, 0);
  }, [finishedOperations]);

  const unfinishedWorkload = useMemo(() => {
    return unfinishedOperations.reduce((acc, o) => acc + o.workload, 0);
  }, [unfinishedOperations]);

  const showPostOperations = async (): Promise<void> => {
    const qualifiedPostId = globalHelpers.computeQualifiedWorkshopPostId(
      implantation.id,
      category.id,
      postName
    );
    await openShowOperationsModal(nonnull(kanban), qualifiedPostId);
  };

  const unfinishedOpsPercentage = useMemo(() => {
    if (totalWorkload !== 0) {
      return unfinishedWorkload / totalWorkload;
    }
    if (totalOperations.length !== 0) {
      return unfinishedOperations.length / totalOperations.length;
    }
    return 0;
  }, [totalOperations.length, totalWorkload, unfinishedOperations.length, unfinishedWorkload]);

  const finishedOpsPercentage = useMemo(() => {
    if (totalWorkload !== 0) {
      return finishedWorkload / totalWorkload;
    }
    if (totalOperations.length !== 0) {
      return finishedOperations.length / totalOperations.length;
    }
    return 0;
  }, [finishedOperations.length, finishedWorkload, totalOperations.length, totalWorkload]);

  /* eslint-disable jsx-a11y/interactive-supports-focus */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <WorkshopPostViewer
      isOnline={isOnline}
      category={category}
      actionsProvider={actionsProvider}
      dropHandler={dropHandler}
      isHighlighted={isHighlighted}
      isVerticalNarrow={isVerticalNarrow}
      implantation={implantation}
      postIconId={postIconId}
      postName={postName}
      postDisplayedLabel={postDisplayedLabel}
      kanban={kanban}
      userName={userName}
      openActionUnavailableWhenDisconnectedModal={openActionUnavailableWhenDisconnectedModal}
      allKanbansInImplantation={allKanbansInImplantation}
      standId={standId}
      $={$}
      $gs={$gs}
    >
      <div
        className="has-text-centered p-l-none p-r-none m-r-xs"
        onClick={isTruthy(kanban) ? showPostOperations : undefined}
        role="link"
        style={{ cursor: 'pointer' }}
      >
        <div
          className="has-background-grey-lighter"
          style={{
            width: '1.25em',
            height: `${(unfinishedOpsPercentage * 100).toFixed(2)}%`,
          }}
        />
        <div
          className="has-background-success"
          style={{
            width: '1.25em',
            height: `${(finishedOpsPercentage * 100).toFixed(2)}%`,
          }}
        />
      </div>
    </WorkshopPostViewer>
  );
}

interface AnomalyPostViewerProps extends AppProps<Store> {
  readonly kanban: Kanban;
  readonly standId: string;
  readonly categoryId: string;
  readonly implantationId: string;
  readonly isOnline: boolean;
  readonly allKanbansInImplantation: readonly Kanban[];
  readonly actionsProvider?: WorkshopActionProviderType;
  readonly $: StoreStateSelector<Store, WorkshopImplantationViewState>;
}

export function AnomalyPostViewer({
  kanban,
  standId,
  categoryId,
  implantationId,
  isOnline,
  allKanbansInImplantation,
  actionsProvider,
  $,
  $gs,
}: AnomalyPostViewerProps): JSX.Element {
  const onDragStartHandler = (event: React.DragEvent<HTMLDivElement>): void => {
    const data = { droppedKanbanId: kanban.id, originCategory: categoryId };
    event.dataTransfer.setData('text/plain', JSON.stringify(data));
  };

  const handle = useMemo(() => {
    const anomalyCategory = computeAnomalyPostQualifiedCategoryId(implantationId);
    return nonnull(kanbanHelpers.getOpenOperatorHandleForPost(kanban, anomalyCategory));
  }, [implantationId, kanban]);

  const anomalyComment = useMemo(() => {
    return handle.intervals.find((i) => !i.endDate)?.comment;
  }, [handle.intervals]);

  const originPostId = useMemo((): string | undefined => {
    const filteredHandling = kanban.handlings.filter(
      (h) =>
        isTruthy(h.endDate) &&
        h.standId === standId &&
        h.postId.startsWith(implantationId) &&
        h.endDate <= handle.startDate
    );
    const sortFunction = sortingHelpers.createSortByNumericField<KanbanHandling>('UP', 'endDate');
    const sortedHandlings = filteredHandling.sort(sortFunction);
    if (sortedHandlings.length > 0) {
      const { workshopPostId } = nonnull(
        transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(
          sortedHandlings[0].postId
        )
      );
      return workshopPostId;
    }
    return undefined;
  }, [handle.startDate, implantationId, kanban.handlings, standId]);

  const actionDescs = useActionButtonDescs(
    () => {
      return actionsProvider
        ? [...actionsProvider(kanban.id, allKanbansInImplantation, categoryId)].sort(
            actionButtonDescIndexComparator
          )
        : [];
    },
    [actionsProvider, allKanbansInImplantation, categoryId, kanban.id],
    $
  );

  return (
    <InternalWorkshopPostViewer
      kanban={kanban}
      categoryId={categoryId}
      isOnline={isOnline}
      postColorClass="has-background-anomaly"
      allKanbansInImplantation={[]}
      $={$}
      $gs={$gs}
    >
      <div
        className="has-text-centered has-text-white"
        style={{ flexGrow: 2, cursor: isTruthyAndNotEmpty(kanban.id) ? 'move' : 'unset' }}
        draggable={isTruthyAndNotEmpty(kanban.id)}
        onDragStart={onDragStartHandler}
      >
        <div style={{ position: 'relative' }}>
          <TruncableP
            style={{ maxWidth: '7em' }}
            className="is-size-5 has-text-weight-bold has-text-left"
          >
            {kanban.infos.license || '\xa0'}
          </TruncableP>
          <div style={{ position: 'absolute', top: 0, right: 0, float: 'right', zIndex: 29 }}>
            {kanban && <PackageDealDecorators kanban={kanban} $gs={$gs} />}
          </div>
        </div>
        <TruncableP style={{ maxWidth: '10em' }}>
          {`${originPostId}-${new Date(handle?.startDate).toLocaleDateString(
            undefined,
            shortDayMonthWithHourFormatOptions
          )}`}
        </TruncableP>
        <TruncableP style={{ maxWidth: '10em' }}>
          {isTruthyAndNotEmpty(anomalyComment) ? `${anomalyComment}` : '\xa0'}
        </TruncableP>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {actionDescs.length > 0 && (
            <div className="buttons are-smaller has-text-centered">
              {actionDescs.map((ad): JSX.Element => {
                return (
                  <ActionButtonComponent
                    key={`${categoryId}${kanban.infos.license}${ad.id}`}
                    actionDesc={ad}
                    size="small"
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </InternalWorkshopPostViewer>
  );
}

interface WorkshopPostViewerProps extends BaseWorkshopPostViewerProps, AppProps<Store> {
  readonly children?: JSX.Element;
  readonly preventColoration?: boolean;
  readonly userName: string;
  readonly openActionUnavailableWhenDisconnectedModal: () => void;
  readonly $: StoreStateSelector<Store, WorkshopImplantationViewState>;
}

export function WorkshopPostViewer({
  kanban,
  category,
  postDisplayedLabel,
  postIconId,
  postName,
  dropHandler,
  actionsProvider,
  isVerticalNarrow = false,
  isHighlighted = false,
  preventColoration = false,
  isOnline,
  implantation,
  children,
  userName,
  openActionUnavailableWhenDisconnectedModal,
  allKanbansInImplantation,
  standId,
  $,
  $gs,
}: WorkshopPostViewerProps): JSX.Element {
  const dueDateThreshold = useGetState(
    $gs.$siteConfiguration.$displayConfiguration.$kanbanColorationCharter.$dueDateThreshold
  );

  const unfinishedOperations = useMemo(() => {
    const packageDeals = kanban?.packageDeals ?? [];
    const qualifiedPostId = globalHelpers.computeQualifiedWorkshopPostId(
      implantation.id,
      category.id,
      postName
    );
    return workshopHelpers.getAllUnfinishedOperationsSoFar(
      packageDealHelpers.getAvailablePackageDeals(packageDeals),
      standId,
      implantation,
      qualifiedPostId
    );
  }, [kanban?.packageDeals, implantation, category.id, postName, standId]);

  const openHandling = kanban?.handlings.find((h) => !isTruthy(h.endDate) && h.standId === standId);

  const anomalyInterval = useMemo(() => {
    if (!isTruthy(openHandling)) {
      return undefined;
    }
    return openHandling.intervals.find((i) => !isTruthy(i.endDate) && i.type === 'anomaly');
  }, [openHandling]);

  const postColorClass = useMemo((): string => {
    let colorClass = '';
    if (isTruthy(kanban)) {
      // The coloration order is openAndon, then ops finished then dueDate
      if (!preventColoration && kanbanHelpers.hasOpenAndon(kanban)) {
        colorClass = 'action-needed';
      } else if (isTruthy(anomalyInterval)) {
        colorClass = 'has-background-anomaly';
      } else if (!preventColoration && unfinishedOperations.length === 0) {
        colorClass = 'is-green-light';
      } else if (
        !preventColoration &&
        (isTruthy(kanban.dueDate) || isTruthy(kanban.refitEndDate))
      ) {
        const priorityLevel = kanbanHelpers.getKanbanPriorityLevelForKanbansDueDate(
          kanban.dueDate,
          kanban.refitEndDate,
          dueDateThreshold
        );
        if (priorityLevel === KanbanPriorityLevel.dueDateIsWithinThreshold) {
          colorClass = 'has-background-yellow';
        } else if (priorityLevel === KanbanPriorityLevel.dueDateIsPast) {
          colorClass = 'has-background-danger has-text-white';
        }
      }
    } else {
      colorClass = 'is-empty-workshop-post';
    }
    if (isVerticalNarrow) {
      colorClass += ' is-workshop-post-vertical-narrow';
    }
    if (isHighlighted) {
      colorClass += ' is-active-box';
    }
    return colorClass;
  }, [
    kanban,
    isVerticalNarrow,
    isHighlighted,
    preventColoration,
    anomalyInterval,
    unfinishedOperations.length,
    dueDateThreshold,
  ]);

  const computedIcon = useGetIconForWorkshopPost($gs, standId, category.id);
  const postIcon = postIconId || computedIcon;

  const onDragStartHandler = (event: React.DragEvent<HTMLDivElement>): void => {
    if (isTruthy(kanban)) {
      const data = { droppedKanbanId: kanban.id, originCategory: category.id };
      event.dataTransfer.setData('text/plain', JSON.stringify(data));
    }
  };

  const prefixedKanbanModel = useMemo((): string => {
    let computedKanbanModel = '';
    if (isTruthy(kanban)) {
      computedKanbanModel = kanban.infos.model;
      if (isTruthy(kanban.dueDate) || isTruthy(kanban.refitEndDate)) {
        const realDueDate = kanbanHelpers.getMostRestrictiveDueDate(
          kanban.dueDate,
          kanban.refitEndDate
        );
        computedKanbanModel = `${globalHelpers.convertTimestampToDateString(
          realDueDate,
          shortDayMonthDateFormatOptions
        )}-${computedKanbanModel}`;
      }
    }
    return computedKanbanModel;
  }, [kanban]);

  const label = postDisplayedLabel || globalHelpers.computeWorkshopPostId(category.id, postName);

  return (
    <InternalWorkshopPostViewer
      kanban={kanban}
      categoryId={category.id}
      postName={postName}
      dropHandler={dropHandler}
      actionsProvider={actionsProvider}
      isOnline={isOnline}
      postColorClass={postColorClass}
      userName={userName}
      openActionUnavailableWhenDisconnectedModal={openActionUnavailableWhenDisconnectedModal}
      allKanbansInImplantation={allKanbansInImplantation}
      $={$}
      $gs={$gs}
    >
      <>
        <div className="has-text-centered p-r-none p-l-xxs" style={{ flexGrow: 1 }}>
          <p className="is-size-6 has-text-weight-bold p-b-xs">{label}</p>
          <FaIcon id={postIcon} tooltip={label} size="standard-lg" />
        </div>
        <div
          className="has-text-centered p-r-xs p-l-xs"
          style={{ flexGrow: 2, cursor: isTruthy(kanban) ? 'move' : 'unset' }}
          draggable={isTruthy(kanban)}
          onDragStart={onDragStartHandler}
        >
          <div style={{ position: 'relative' }}>
            <TruncableP
              style={{ maxWidth: '7em' }}
              className="is-size-5 has-text-weight-bold has-text-left"
            >
              {kanban?.infos.license || '\xa0'}
            </TruncableP>
            <div style={{ position: 'absolute', top: 0, right: 0, float: 'right', zIndex: 29 }}>
              {kanban && <PackageDealDecorators kanban={kanban} $gs={$gs} />}
            </div>
          </div>
          {isTruthy(anomalyInterval) ? (
            <TruncableP style={{ maxWidth: '8.5em' }}>
              {anomalyInterval.comment || '\xa0'}
            </TruncableP>
          ) : (
            <TruncableP style={{ maxWidth: '8.5em' }}>{prefixedKanbanModel || '\xa0'}</TruncableP>
          )}
        </div>
        {children}
      </>
    </InternalWorkshopPostViewer>
  );
}

interface InternalWorkshopPostViewerProps extends AppProps<Store> {
  readonly kanban?: Kanban;
  readonly categoryId: string;
  readonly postName?: string;
  readonly dropHandler?: KanbanDropHandlerSignature;
  readonly actionsProvider?: WorkshopActionProviderType;
  readonly isOnline: boolean;
  readonly postColorClass: string;
  readonly children?: JSX.Element;
  readonly userName?: string;
  readonly openActionUnavailableWhenDisconnectedModal?: () => void;
  readonly allKanbansInImplantation: readonly Kanban[];
  readonly $: StoreStateSelector<Store, WorkshopImplantationViewState>;
}

function InternalWorkshopPostViewer({
  kanban,
  categoryId,
  postName,
  dropHandler,
  actionsProvider,
  isOnline,
  postColorClass,
  children,
  userName,
  openActionUnavailableWhenDisconnectedModal,
  allKanbansInImplantation,
  $,
  $gs,
}: InternalWorkshopPostViewerProps): JSX.Element {
  const computeAttachmentUrlCallback = useComputeAttachmentUrl($gs);

  const actionDescs = useActionButtonDescs(
    () => {
      return actionsProvider
        ? [...actionsProvider(kanban?.id, allKanbansInImplantation, categoryId, postName)].sort(
            actionButtonDescIndexComparator
          )
        : [];
    },
    [actionsProvider, allKanbansInImplantation, categoryId, kanban, postName],
    $
  );

  const onDragOverHandler = (event: React.DragEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    if (
      !isTruthy(kanban) &&
      isTruthy(dropHandler) &&
      event.dataTransfer.types.includes('text/plain')
    ) {
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.dropEffect = 'copy';
    } else {
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.dropEffect = 'none';
    }
  };

  const onDropHandler = (event: React.DragEvent<HTMLDivElement>): void => {
    if (!isTruthyAndNotEmpty(userName) && isTruthy(openActionUnavailableWhenDisconnectedModal)) {
      openActionUnavailableWhenDisconnectedModal();
      return;
    }
    const textualData = event.dataTransfer.getData('text/plain');
    const data = JSON.parse(textualData);
    if (!isTruthy(kanban) && isTruthy(dropHandler) && isTruthyAndNotEmpty(data.droppedKanbanId)) {
      event.stopPropagation();
      event.preventDefault();
      dropHandler(data.droppedKanbanId, categoryId, postName, data.originCategory);
    }
  };

  return (
    <div className={`box ${postColorClass}`} onDrop={onDropHandler} onDragOver={onDragOverHandler}>
      <div style={{ display: 'flex' }}>
        <div className="p-r-xs">
          <KanbanIdentityPictureComponent
            kanbanId={kanban?.id}
            computeAttachmentUrl={computeAttachmentUrlCallback}
            $imageModal={$gs.$imageModal}
            thumbnailDisplayWidth={102}
            isOnline={isOnline}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }} className="p-b-xs">
            {children}
          </div>
          {actionDescs.length > 0 && (
            <div className="buttons are-smaller has-text-centered">
              {actionDescs.map((ad): JSX.Element => {
                return (
                  <ActionButtonComponent
                    key={`${categoryId}${postName}${ad.id}`}
                    actionDesc={ad}
                    size="small"
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

interface WorkshopPostQueueElementComponentProps extends AppProps<Store> {
  readonly kanban: Kanban;
  readonly categoryId: string;
  readonly actionsProvider?: WorkshopActionProviderType;
  readonly isOnline: boolean;
  readonly allKanbansInImplantation: readonly Kanban[];
  readonly $: StoreStateSelector<Store, WorkshopImplantationViewState>;
}

export function WorkshopPostQueueElementComponent({
  kanban,
  categoryId,
  isOnline,
  actionsProvider,
  allKanbansInImplantation,
  $,
  $gs,
}: WorkshopPostQueueElementComponentProps): JSX.Element {
  const computeAttachmentUrlCallback = useComputeAttachmentUrl($gs);
  const dueDateThreshold = useGetState(
    $gs.$siteConfiguration.$displayConfiguration.$kanbanColorationCharter.$dueDateThreshold
  );

  const actionDescs = useActionButtonDescs(
    () => {
      return actionsProvider
        ? [...actionsProvider(kanban.id, allKanbansInImplantation, categoryId)].sort(
            actionButtonDescIndexComparator
          )
        : [];
    },
    [actionsProvider, allKanbansInImplantation, categoryId, kanban.id],
    $
  );

  const onDragStartHandler = (event: React.DragEvent<HTMLDivElement>): void => {
    if (isTruthy(kanban)) {
      const data = { droppedKanbanId: kanban.id, originCategory: categoryId };
      event.dataTransfer.setData('text/plain', JSON.stringify(data));
    }
  };
  const postColorClass = useMemo((): string => {
    let colorClass = 'is-empty-workshop-post';
    if (isTruthy(kanban.dueDate) || isTruthy(kanban.refitEndDate)) {
      const priorityLevel = kanbanHelpers.getKanbanPriorityLevelForKanbansDueDate(
        kanban.dueDate,
        kanban.refitEndDate,
        dueDateThreshold
      );
      if (priorityLevel === KanbanPriorityLevel.dueDateIsWithinThreshold) {
        colorClass = 'has-background-yellow';
      } else if (priorityLevel === KanbanPriorityLevel.dueDateIsPast) {
        colorClass = 'has-background-danger has-text-white';
      }
    }
    return colorClass;
  }, [dueDateThreshold, kanban.dueDate, kanban.refitEndDate]);

  return (
    <div className={`box ${postColorClass} p-xs`}>
      <div style={{ display: 'flex' }}>
        <div className="p-r-xs">
          <KanbanIdentityPictureComponent
            kanbanId={kanban?.id}
            computeAttachmentUrl={computeAttachmentUrlCallback}
            $imageModal={$gs.$imageModal}
            thumbnailDisplayWidth={55}
            isOnline={isOnline}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }} className="p-b-none">
            <div
              className="has-text-weight-bold"
              style={{ flexGrow: 2, cursor: isTruthyAndNotEmpty(kanban.id) ? 'move' : 'unset' }}
              draggable={isTruthyAndNotEmpty(kanban.id)}
              onDragStart={onDragStartHandler}
            >
              {kanban.infos.license}
              <PackageDealDecorators kanban={kanban} $gs={$gs} />
            </div>
          </div>
          {actionDescs.length > 0 && (
            <div className="buttons are-smaller has-text-centered">
              {actionDescs.map((ad): JSX.Element => {
                return (
                  <ActionButtonComponent
                    key={`${ad.id}${kanban.id}`}
                    actionDesc={ad}
                    size="small"
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
