import type { JSX } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment, AttachmentMetadata, Kanban, UIContract } from '@stimcar/libs-base';
import type {
  StandardPictureConfEntry,
  StandardPictureId,
  StandardPictureInfo,
  StandardPicturesConfiguration,
  StandardPicturesConfigurationKey,
} from '@stimcar/libs-kernel';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { compareNumbers, CoreBackendRoutes, mapRecordValues } from '@stimcar/libs-base';
import {
  getMaskPicturePath,
  getPlaceholderPicturePath,
  getStandardPicturesConfiguration,
  getTargetFolderForStandardPictures,
  MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
  nonnull,
} from '@stimcar/libs-kernel';
import {
  IconButton,
  useActionCallback,
  useArrayItemSelector,
  useGetState,
} from '@stimcar/libs-uikernel';
import type { ConfirmAttachmentDialogState } from '../../lib/components/attachments/typings/store.js';
import type { PictureInputState } from '../../lib/components/typings/store.js';
import type { Store } from '../state/typings/store.js';
import { ConfirmAttachmentRemovalDialog } from '../../lib/components/attachments/ConfirmAttachmentRemovalDialog.js';
import { EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE } from '../../lib/components/attachments/typings/store.js';
import { StandardPictureInput } from '../../lib/components/pictureInput/StandardPictureInput.js';
import { ShowHideBoxContainer } from '../../lib/components/ShowHideContainer.js';
import { downloadAndSave } from '../../lib/utils/download.js';
import { useComputeStandardPictureUrl } from '../utils/useComputeAttachmentUrl.js';
import type { StandardPicturesTabState } from './typings/store.js';
import { shouldAllowAttachmentDeletion } from './kanbanDetailsUtils.js';

function getSortedStandardPicturesToDisplay(
  defaultStandardPictures: readonly StandardPictureInfo[],
  standardPicturesConfiguration: readonly StandardPictureConfEntry[]
): readonly StandardPictureInfo[] {
  const standardPictureIdsToDisplay = standardPicturesConfiguration
    .filter(({ displayed }) => displayed)
    .map(({ standardPictureId }) => standardPictureId);
  return defaultStandardPictures
    .filter(({ id }) => standardPictureIdsToDisplay.includes(id))
    .sort(({ index: index1 }, { index: index2 }) => compareNumbers(index1, index2, 'DOWN'));
}

interface StandardPictureCellProps extends AppProps<Store> {
  readonly kanbanId: string;
  readonly pictureId: string;
  readonly isEditable: boolean;
  readonly $: StoreStateSelector<Store, readonly PictureInputState[]>;
  readonly $confirmAttachmentRemovalDialog: StoreStateSelector<Store, ConfirmAttachmentDialogState>;
  readonly standardPicturesConfigurationKey: StandardPicturesConfigurationKey;
  readonly standardPicturesConfEntry: readonly StandardPictureConfEntry[];
}

function StandardPictureCell({
  $,
  $gs,
  kanbanId,
  pictureId,
  isEditable,
  $confirmAttachmentRemovalDialog,
  standardPicturesConfigurationKey,
  standardPicturesConfEntry,
}: StandardPictureCellProps): JSX.Element {
  const [tStandardPictures] = useTranslation('adminStandardPictures');

  const computeStandardPictureUrlCallback = useComputeStandardPictureUrl($gs);
  const $pictureStateSelector = useArrayItemSelector($, pictureId);
  const placeholderPicturePath = useMemo(
    () => getPlaceholderPicturePath(standardPicturesConfigurationKey, pictureId),
    [pictureId, standardPicturesConfigurationKey]
  );
  const maskPicturePath = useMemo(
    () => getMaskPicturePath(standardPicturesConfigurationKey, pictureId),
    [pictureId, standardPicturesConfigurationKey]
  );

  const folder = useMemo(
    () => getTargetFolderForStandardPictures(standardPicturesConfigurationKey),
    [standardPicturesConfigurationKey]
  );

  const isImprovedImage = useMemo(
    () =>
      standardPicturesConfEntry.find((picture) => pictureId === picture.standardPictureId)
        ?.improveImage ?? false,
    [standardPicturesConfEntry, pictureId]
  );

  return (
    <div className="cell is-col-span-3">
      <StandardPictureInput
        $gs={$gs}
        kanbanId={kanbanId}
        folder={folder}
        filename={pictureId}
        isEditable={isEditable}
        $={$pictureStateSelector}
        computeAttachmentUrlCallback={computeStandardPictureUrlCallback}
        placeholderPicturePath={placeholderPicturePath}
        label={tStandardPictures(`viewValues.${standardPicturesConfigurationKey}.${pictureId}`)}
        $confirmAttachmentRemovalDialog={$confirmAttachmentRemovalDialog}
        notSetPictureAdditionalLayer="img/standard-pictures/set-picture-layer.webp"
        maskPath={maskPicturePath}
        isImprovedImage={isImprovedImage}
      />
    </div>
  );
}

interface StandardPicturesForCategoryProps extends AppProps<Store> {
  readonly kanbanId: string;
  readonly isEditable: boolean;
  readonly categoryLabel: string;
  readonly picturesToDisplay: readonly StandardPictureId[];
  readonly standardPicturesConfigurationKey: StandardPicturesConfigurationKey;
  readonly $: StoreStateSelector<Store, StandardPicturesTabState>;
  readonly standardPicturesConfEntry: readonly StandardPictureConfEntry[];
}

function StandardPicturesForCategory({
  $gs,
  $,
  kanbanId,
  picturesToDisplay,
  isEditable,
  categoryLabel,
  standardPicturesConfigurationKey,
  standardPicturesConfEntry,
}: StandardPicturesForCategoryProps): JSX.Element {
  return picturesToDisplay.length === 0 ? (
    <></>
  ) : (
    <>
      <h4 className="title is-4 mt-2 mb-3">{categoryLabel}</h4>
      <div className="fixed-grid has-12-cols">
        <div className="grid">
          {picturesToDisplay.map((pictureId) => (
            <StandardPictureCell
              key={pictureId}
              $gs={$gs}
              pictureId={pictureId}
              isEditable={isEditable}
              kanbanId={kanbanId}
              $={$.$pictures}
              $confirmAttachmentRemovalDialog={$.$confirmAttachmentRemovalDialog}
              standardPicturesConfigurationKey={standardPicturesConfigurationKey}
              standardPicturesConfEntry={standardPicturesConfEntry}
            />
          ))}
        </div>
      </div>
    </>
  );
}

interface PictureStandardProps extends AppProps<Store> {
  readonly isEditable: boolean;
  readonly contract: UIContract;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
  readonly $: StoreStateSelector<Store, StandardPicturesTabState>;
}

export function StandardPictures({
  $,
  $gs,
  $selectedKanban,
  contract,
  isEditable,
}: PictureStandardProps): JSX.Element {
  const [t] = useTranslation('details');
  const [tStandardPictures] = useTranslation('adminStandardPictures');

  const selectedKanban = useGetState($selectedKanban);
  const computeStandardPictureUrl = useComputeStandardPictureUrl($gs);

  const standardPicturesConfigurationKey = nonnull(contract.standardPicturesConfigurationKey);
  const standardPicturesConf: StandardPicturesConfiguration = getStandardPicturesConfiguration(
    standardPicturesConfigurationKey
  );

  const shouldRestrictAttachmentRemoval = useCallback(
    (attachment: Attachment, metadata: AttachmentMetadata | undefined): boolean => {
      return !shouldAllowAttachmentDeletion(selectedKanban?.status, attachment, metadata);
    },
    [selectedKanban]
  );

  const onRemoveActionCallback = useActionCallback(
    async ({ httpClient, getState, actionDispatch }) => {
      const filename = getState().confirmAttachmentRemovalDialog.name;
      const currentPictureConf = contract.standardPicturesConfiguration?.filter(
        (picture) => filename === picture.standardPictureId
      )[0];
      const folder = getTargetFolderForStandardPictures(standardPicturesConfigurationKey);
      const isImprovedImage = currentPictureConf?.improveImage;
      await httpClient.httpGet(
        computeStandardPictureUrl(
          'kanban',
          folder,
          filename,
          selectedKanban.id,
          undefined,
          isImprovedImage
        ),
        'DELETE'
      );
      actionDispatch.scopeProperty('pictures').scopeArrayItem(filename).setProperty('isSet', false);
      actionDispatch
        .scopeProperty('confirmAttachmentRemovalDialog')
        .setValue(EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE);
    },
    [
      contract.standardPicturesConfiguration,
      standardPicturesConfigurationKey,
      computeStandardPictureUrl,
      selectedKanban.id,
    ],
    $
  );

  const downloadStandardPicturesActionCallback = useActionCallback(
    async ({ httpClient }) =>
      await downloadAndSave(
        httpClient,
        CoreBackendRoutes.ZIPPED_ATTACHMENT_STANDARD_PICTURE_FOLDER(
          nonnull(selectedKanban).id,
          MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID
        ),
        'download.zip'
      ),
    [selectedKanban],
    $
  );

  return (
    <div>
      <IconButton
        icon="download"
        ariaLabel="download"
        label={t('tabs.pictureStandard.download')}
        onClickCallback={downloadStandardPicturesActionCallback}
      />
      {mapRecordValues(standardPicturesConf, (standardPicturesInfos, category) => {
        const pictureIdsToDisplay = getSortedStandardPicturesToDisplay(
          standardPicturesInfos,
          contract.standardPicturesConfiguration ?? []
        ).map(({ id }) => id);
        return (
          <StandardPicturesForCategory
            key={category}
            $={$}
            $gs={$gs}
            isEditable={isEditable}
            kanbanId={selectedKanban.id}
            picturesToDisplay={pictureIdsToDisplay}
            categoryLabel={tStandardPictures(
              `categoryValues.${standardPicturesConfigurationKey}.${category}`
            )}
            standardPicturesConfigurationKey={standardPicturesConfigurationKey}
            standardPicturesConfEntry={contract.standardPicturesConfiguration ?? []}
          />
        );
      })}
      <ConfirmAttachmentRemovalDialog
        category="kanban"
        objectId={selectedKanban.id}
        onOkClicked={onRemoveActionCallback}
        $={$.$confirmAttachmentRemovalDialog}
        computeAttachmentUrl={computeStandardPictureUrl}
        okLabel={t('confirmAttachmentRemovalDialog.okLabel')}
        shouldRestrictRemove={shouldRestrictAttachmentRemoval}
      >
        <p>{t('confirmAttachmentRemovalDialog.message')}</p>
      </ConfirmAttachmentRemovalDialog>
    </div>
  );
}

interface MobileStandardPicturesProps extends AppProps<Store> {
  readonly isEditable: boolean;
  readonly contract: UIContract;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
  readonly $: StoreStateSelector<Store, StandardPicturesTabState>;
}

export function MobileStandardPictures({
  $,
  $gs,
  contract,
  isEditable,
  $selectedKanban,
}: MobileStandardPicturesProps): JSX.Element {
  const [t] = useTranslation('details');

  return (
    <ShowHideBoxContainer
      isMobile
      $={$.$isUnfoldedOnMobile}
      title={t('tabs.pictureStandard.title')}
    >
      <StandardPictures
        $={$}
        $gs={$gs}
        contract={contract}
        isEditable={isEditable}
        $selectedKanban={$selectedKanban}
      />
    </ShowHideBoxContainer>
  );
}
