import { EMPTY_SITE_CONFIGURATION } from '@stimcar/libs-base';
import { EMPTY_BASE_STORE_STATE, EMPTY_LOGIN_MODAL_DIALOG_STATE } from '@stimcar/libs-uitoolkit';
import { EMPTY_REGISTER_VIEW_STATE } from '../components/authentication/typings/store.js';
import type { RegisteredAppStoreState, SessionState } from './typings/store.js';

export const EMPTY_SESSION_STATE: SessionState = {
  localRepositoryChangesCount: 0,
  isRegistered: false,
  isOnline: false,
  isActiveWindow: false,
};

export const EMPTY_REGISTERED_APP_STORE_STATE: RegisteredAppStoreState = {
  ...EMPTY_BASE_STORE_STATE,
  session: EMPTY_SESSION_STATE,
  loginView: EMPTY_LOGIN_MODAL_DIALOG_STATE,
  registerView: EMPTY_REGISTER_VIEW_STATE,
  siteConfiguration: EMPTY_SITE_CONFIGURATION,
};
