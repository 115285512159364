import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { RepositoryHTTPClient } from '@stimcar/core-libs-repository';
import type { KanbanSummary } from '@stimcar/libs-base';
import type {
  ActionCallbackFromFunction,
  StoreDef,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { BaseStoreActionContext, BaseStoreState } from '@stimcar/libs-uitoolkit';
import { isTruthy, isTruthyAndNotEmpty, sanitizeValue } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { DeleteClickableIcon, Pagination } from '@stimcar/libs-uitoolkit';
import { selectKanbanInArchiveViewAction } from '../actions.js';
import type { KanbanListProps } from './KanbanList.js';
import type { RecognizedLicenseSVGRectangleStyle } from './svg/RecognizedLicensesSVGImage.js';
import type { KanbanListWithSearchState } from './typings/store.js';
import { ImageBasedSearchButton } from './ImageBasedSearchButton.js';
import { KanbanList } from './KanbanList.js';
import { RecognizedLicensesSVGImage } from './svg/RecognizedLicensesSVGImage.js';

export type KanbanListWithSearchBaseActionContext = BaseStoreActionContext & {
  readonly httpClient: RepositoryHTTPClient;
};

export type KanbanListWithSearchBaseStoreDef = StoreDef<
  BaseStoreState,
  KanbanListWithSearchBaseActionContext
>;

type Props<SD extends KanbanListWithSearchBaseStoreDef> = KanbanListProps & {
  readonly $: StoreStateSelector<SD, KanbanListWithSearchState>;
  readonly runSearchActionCallback: ActionCallbackFromFunction<
    SD,
    (page: number, autoSelectIfOneResult?: boolean) => Promise<void>
  >;
  readonly pageCount?: number;
  readonly activePage?: number;
  readonly totalFound?: number;
  readonly maxPageButtons?: number;
  readonly searchShouldBeRerun: boolean;
  readonly warning?: string;
};

export function KanbanListWithSearch<SD extends KanbanListWithSearchBaseStoreDef>({
  kanbans,
  selectKanban,
  runSearchActionCallback,
  searchShouldBeRerun,
  warning,
  kanbanColorationCharter,
  $,
  pageCount = 1,
  activePage = 0,
  maxPageButtons = 5,
  totalFound,
  selectedKanbanId,
}: Props<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');

  const { $searchText } = $;

  const defaultSearchActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.execCallback(runSearchActionCallback, 1);
    },
    [runSearchActionCallback],
    $searchText
  );

  const onKeyPressedActionCallback = useActionCallback(
    async ({ actionDispatch }, e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await actionDispatch.execCallback(defaultSearchActionCallback);
      }
    },
    [defaultSearchActionCallback],
    $searchText
  );

  const onSearchTextChangedHandler = useActionCallback(
    ({ actionDispatch }, e: React.ChangeEvent<HTMLInputElement>) => {
      actionDispatch.setValue(e.target.value);
    },
    [],
    $searchText
  );

  const searchText = useGetState($searchText);

  const searchByImageState = useGetState($.$searchByImage);

  const switchToTextBasedSearch = useActionCallback(
    async ({ actionDispatch }) => {
      actionDispatch.applyPayload({
        searchText: '',
        searchByImage: undefined,
      });
      // Perform a fake search to reinitialize the form
      await actionDispatch.execCallback(runSearchActionCallback, 1);
    },
    [runSearchActionCallback],
    $
  );

  const removeLicenseFilterActionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.applyPayload({
        searchByImage: {
          selectedImageLicenseFilter: undefined,
        },
      });
    },
    [],
    $
  );

  const selectedKanbanLicense = useMemo((): string | undefined => {
    if (!selectedKanbanId) {
      return undefined;
    }
    const selectedKanban = kanbans.find(({ id }) => selectedKanbanId === id);
    return selectedKanban ? sanitizeValue(selectedKanban.infos.license) : undefined;
  }, [kanbans, selectedKanbanId]);

  const filteredKanbans = useMemo((): readonly KanbanSummary[] => {
    if (!isTruthy(searchByImageState) || !isTruthy(searchByImageState.selectedImageLicenseFilter)) {
      return kanbans;
    }
    return kanbans.filter(
      ({ infos }) => sanitizeValue(infos.license) === searchByImageState.selectedImageLicenseFilter
    );
  }, [kanbans, searchByImageState]);

  const computeLicenseStyle = useMemo(
    () =>
      (license: string): RecognizedLicenseSVGRectangleStyle => {
        const selectedImageLicenseFilter = searchByImageState?.selectedImageLicenseFilter;
        // If there is an active license filter, only highlight the license
        // that corresponds to that filter
        if (selectedImageLicenseFilter !== undefined && selectedImageLicenseFilter === license) {
          return 'selected';
        }
        // If there is no active license filter, highlight the license
        // only if it appears in the details view
        if (selectedImageLicenseFilter === undefined && selectedKanbanLicense === license) {
          return 'selected';
        }
        // Otherwise, unknown cars must be red
        if (
          kanbans &&
          kanbans.filter(({ infos }) => sanitizeValue(infos.license) === license).length === 0
        ) {
          return 'unknown';
        }
        // And by default orange
        return 'default';
      },
    [kanbans, searchByImageState?.selectedImageLicenseFilter, selectedKanbanLicense]
  );

  const filterLicenseActionCallback = useActionCallback(
    async ({ actionDispatch, globalActionDispatch }, recognizedLicense: string) => {
      actionDispatch.applyPayload({
        searchByImage: {
          selectedImageLicenseFilter: recognizedLicense,
        },
      });
      const remainingKanbans = kanbans.filter(
        ({ infos }) => sanitizeValue(infos.license) === recognizedLicense
      );
      if (remainingKanbans.length === 1) {
        await globalActionDispatch.exec(selectKanbanInArchiveViewAction, remainingKanbans[0].id);
      }
    },
    [kanbans],
    $
  );

  return (
    <div>
      {
        /* hide form if an image is shown to free some space on the screen */
        !isTruthy(searchByImageState) && (
          <div className="field">
            <div className="field has-addons">
              <div className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  placeholder={t('search.placeholder')}
                  defaultValue={searchText}
                  onKeyDown={onKeyPressedActionCallback}
                  onChange={onSearchTextChangedHandler}
                />
              </div>
              <div className="control">
                <button
                  type="button"
                  className="button is-primary"
                  onClick={defaultSearchActionCallback}
                  aria-label={t('search.label')}
                >
                  <span className="icon is-small">
                    <i className="fas fa-magnifying-glass" />
                  </span>
                </button>
              </div>
              <div className="control">
                <ImageBasedSearchButton $={$} runSearchActionCallback={runSearchActionCallback} />
              </div>
            </div>
            {isTruthyAndNotEmpty(warning) && (
              <p className="has-text-centered help is-primary">{warning}</p>
            )}
            {searchShouldBeRerun && (
              <p className="has-text-centered help is-primary">
                {t('kanbanList.rerunSearchMessage')}
              </p>
            )}
          </div>
        )
      }
      {isTruthy(searchByImageState) && (
        <div style={{ position: 'relative' }}>
          <RecognizedLicensesSVGImage
            $={$}
            onClickActionCallback={filterLicenseActionCallback}
            computeStyle={computeLicenseStyle}
          />
          <DeleteClickableIcon
            handler={switchToTextBasedSearch}
            customStyle={{ position: 'absolute', top: 2, right: 2 }}
            isSmall
          />
        </div>
      )}
      {searchByImageState?.selectedImageLicenseFilter && (
        <span className="tag is-primary">
          {searchByImageState.selectedImageLicenseFilter}
          <button
            type="button"
            className="delete is-small"
            onClick={removeLicenseFilterActionCallback}
            aria-label="close"
          />
        </span>
      )}
      {pageCount > 1 ? (
        <Pagination
          activePage={activePage}
          gotoPage={runSearchActionCallback}
          maxPageButtons={maxPageButtons}
          pageCount={pageCount}
        />
      ) : (
        <></>
      )}
      <KanbanList
        kanbans={filteredKanbans}
        selectKanban={selectKanban}
        kanbanColorationCharter={kanbanColorationCharter}
        selectedKanbanId={selectedKanbanId}
      />
      {
        /* If an image has been uploaded, hide the results count that is not very significant */
        !isTruthy(searchByImageState) && (
          <div>
            {totalFound !== undefined && totalFound > 0
              ? t('search.totalFoundLabel', { totalFound })
              : ''}
          </div>
        )
      }
    </div>
  );
}
