import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { purchaseOrderHelpers } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, RadioButtonsFormField } from '../../../bulma/index.js';
import { useFormWithValidation } from '../../../hooks/useFormWithValidation.js';
import type { ChangePackageDealLinkedPurchaseOrderModalState } from './typings/store.js';

interface ChangePackageDealLinkedPurchaseOrderDialogProps<SD extends AnyStoreDef> {
  $: StoreStateSelector<SD, ChangePackageDealLinkedPurchaseOrderModalState>;
  readonly changePackageDealLinkedPurchaseOrder?: (
    packageDealId: string,
    purchaseOrderId: string
  ) => void;
}

export function ChangePackageDealLinkedPurchaseOrderDialog<SD extends AnyStoreDef>({
  $,
  changePackageDealLinkedPurchaseOrder,
}: ChangePackageDealLinkedPurchaseOrderDialogProps<SD>): JSX.Element {
  const [t] = useTranslation('custom');

  const purchaseOrders = useGetState($.$changePackageDealLinkedPurchaseOrderDialog.$purchaseOrders);

  const purchaseOrdersLabels = useMemo(
    () => purchaseOrders.map(purchaseOrderHelpers.getPurchaseOrderDisplayedLabel),
    [purchaseOrders]
  );

  const submitChangePackageDealLinkedPurchaseOrderAction = useActionCallback(
    ({ getState }) => {
      if (isTruthy(changePackageDealLinkedPurchaseOrder)) {
        const { packageDealId, purchaseOrders } =
          getState().changePackageDealLinkedPurchaseOrderDialog;
        const { selectedPurchaseOrderLabel } = getState().formData;
        const { id: selectedPurchaseOrderId } = nonnull(
          purchaseOrders.find(
            (purchaseOrder) =>
              purchaseOrderHelpers.getPurchaseOrderDisplayedLabel(purchaseOrder) ===
              selectedPurchaseOrderLabel
          )
        );
        changePackageDealLinkedPurchaseOrder(packageDealId, selectedPurchaseOrderId);
      }
    },
    [changePackageDealLinkedPurchaseOrder],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    SD,
    ChangePackageDealLinkedPurchaseOrderModalState
  >({
    $,
    mandatoryFields: ['selectedPurchaseOrderLabel'],
    submitValidDataAction: submitChangePackageDealLinkedPurchaseOrderAction,
    t,
  });

  return (
    <ModalCardDialog
      onOkClicked={onFormSubmit}
      titleIconId="scale-balanced"
      warning={useGetState($.$formWarning)}
      $active={$.$changePackageDealLinkedPurchaseOrderDialog.$active}
      okLabel={t('documentGeneration.estimate.purchaseOrders.update')}
      title={t('documentGeneration.estimate.purchaseOrders.ventilatePackageOnPurchaseOrder')}
    >
      <div className="m-t-md">
        <RadioButtonsFormField
          id="purchaseOrders"
          radioGroupLayout="vertical"
          entries={purchaseOrdersLabels}
          $={$formDataWithChangeTrigger.$selectedPurchaseOrderLabel}
          label={`${t('documentGeneration.estimate.purchaseOrders.purchaseOrder')} :`}
        />
      </div>
    </ModalCardDialog>
  );
}
