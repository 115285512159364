import type { JSX } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { keysOf } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import { Button, SelectFormField } from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import type { StandAchievementsDisplayState, StandDisplayState } from './typings/store.js';
import { StandAchievementsTable } from './StandAchievementsTable.js';

function persistStateAction({ getState }: ActionContext<Store, StandAchievementsDisplayState>) {
  localStorage.setItem(LocalStorageKeys.STAND_ACHIEVEMENT_DISPLAY, JSON.stringify(getState()));
}

// eslint-disable-next-line @typescript-eslint/require-await
export function StandAchievementsDisplay({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation(['display']);
  const $ = $gs.$displayView;
  const { $standDisplay } = $;
  const workflowId = useGetState($standDisplay.$workflowId);
  const standId = useGetState($standDisplay.$standId);
  const size = useGetState($standDisplay.$size);

  const workflowsStates = useGetState($.$workflowsStates);

  const asyncEffect = useActionCallback(
    ({ actionDispatch }) => {
      const persistedState = localStorage.getItem(LocalStorageKeys.STAND_ACHIEVEMENT_DISPLAY);
      if (persistedState) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        actionDispatch.applyPayload({
          ...JSON.parse(persistedState),
        });
      }
    },
    [],
    $standDisplay
  );
  useEffect((): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const workflowIds = useMemo(() => keysOf(workflowsStates), [workflowsStates]);

  const standIds = useMemo(() => {
    if (workflowId && workflowsStates[workflowId]) {
      return keysOf(workflowsStates[workflowId].stands);
    }
    return [];
  }, [workflowId, workflowsStates]);

  const standState = useMemo(
    (): StandDisplayState | undefined =>
      workflowId && workflowsStates[workflowId]
        ? workflowsStates[workflowId].stands[standId]
        : undefined,
    [standId, workflowId, workflowsStates]
  );

  const persistStateCallback = useActionCallback(persistStateAction, [], $standDisplay);
  const $standDisplayWithChangeTrigger = useSelectorWithChangeTrigger(
    $standDisplay,
    persistStateCallback
  );

  const onToggleShowHideActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      const active = getState();
      actionDispatch.setValue(!active);
    },
    [],
    $standDisplayWithChangeTrigger.$active
  );

  const active = useGetState($standDisplayWithChangeTrigger.$active);

  /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
  return (
    <>
      {standState && (
        <div className="columns">
          <div className="column is-1">
            <Button onClick={onToggleShowHideActionCallback} iconId="gear" size="small" />
            {active && (
              <>
                <SelectFormField
                  label={t('standDisplay.workflow')}
                  $={$standDisplayWithChangeTrigger.$workflowId}
                  entries={workflowIds}
                  sortEntries
                />
                <SelectFormField
                  label={t('standDisplay.stand')}
                  $={$standDisplayWithChangeTrigger.$standId}
                  entries={standIds}
                  sortEntries={false}
                />
                <SelectFormField
                  label={t('standDisplay.size')}
                  $={$standDisplayWithChangeTrigger.$size}
                  entries={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                  sortEntries
                />
              </>
            )}
          </div>
          <div className="column is-11">
            <StandAchievementsTable
              standId={standId}
              size={size}
              kanbans={standState.kanbans}
              achievements={standState.achievements}
            />
          </div>
        </div>
      )}
    </>
  );
}
