import type { SortDirection } from '../../model/index.js';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toStr = (str: any): string => {
  if (str !== undefined && str !== null && typeof str !== 'string') {
    throw Error(`Data is not a string : ${str}`);
  }
  return !str ? '' : str.trim();
};

export function compareStrings(
  str1: string | undefined | null,
  str2: string | undefined | null,
  sortDirection: SortDirection
): number {
  return sortDirection === 'UP'
    ? toStr(str1).localeCompare(toStr(str2))
    : toStr(str2).localeCompare(toStr(str1));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toNbr = (nbr: any): number => {
  if (nbr !== undefined && nbr !== null && typeof nbr !== 'number') {
    throw Error(`Data is not a number : ${nbr}`);
  }
  return !nbr ? 0 : nbr;
};

export function compareNumbers(
  n1: number | undefined | null,
  n2: number | undefined | null,
  sortDirection: SortDirection
): number {
  let compare = 0;
  const nbr1 = toNbr(n1);
  const nbr2 = toNbr(n2);
  if (nbr1 < nbr2) {
    compare = -1;
  }
  if (nbr2 < nbr1) {
    compare = 1;
  }
  return sortDirection === 'UP' ? -compare : compare;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toBool = (bool: any): boolean => {
  if (bool !== undefined && bool !== null && typeof bool !== 'boolean') {
    throw Error(`Data is not a boolean : ${bool}`);
  }
  return bool ?? false;
};

export function compareBooleans(
  b1: boolean | undefined | null,
  b2: boolean | undefined | null,
  sortDirection: SortDirection
): number {
  let compare = 0;
  const bool1 = toBool(b1);
  const bool2 = toBool(b2);
  if (!bool1 && bool2) {
    compare = -1;
  }
  if (bool1 && !bool2) {
    compare = 1;
  }
  return sortDirection === 'UP' ? -compare : compare;
}

export const createSortByNumericField = <T>(
  sortDirection: SortDirection,
  key: keyof T
): ((obj1: T, obj2: T) => number) => {
  return function sort(obj1, obj2): number {
    return compareNumbers(toNbr(obj1[key]), toNbr(obj2[key]), sortDirection);
  };
};

export const createSortByStringField = <T>(
  sortDirection: SortDirection,
  key: keyof T
): ((obj1: T, obj2: T) => number) => {
  return function sort(obj1, obj2): number {
    return compareStrings(toStr(obj1[key]), toStr(obj2[key]), sortDirection);
  };
};

export const createSortByBooleanField = <T>(
  sortDirection: SortDirection,
  key: keyof T
): ((obj1: T, obj2: T) => number) => {
  return function sort(obj1, obj2): number {
    return compareBooleans(toBool(obj1[key]), toBool(obj2[key]), sortDirection);
  };
};
