import type { CarElement, SpecificFields } from '@stimcar/libs-base';
import type { BusinessObjectToFormData, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type {
  ComponentWithMountTracking,
  FormWithValidationState,
  TableState,
} from '@stimcar/libs-uitoolkit';
import { EMPTY_CAR_ELEMENT } from '@stimcar/libs-base';
import { TABLE_EMPTY_STATE } from '@stimcar/libs-uitoolkit';
import type { ByCarElementsFilterState } from '../ByCarElementsFilter.js';

export type CarElementFormData = BusinessObjectToFormData<
  Omit<SpecificFields<CarElement>, 'index'>
>;
export type CarElementForm = WithFormValidationWarnings<CarElementFormData>;

const EMPTY_CAR_ELEMENT_FORM: CarElementForm = {
  ...EMPTY_CAR_ELEMENT,
  warnings: {},
};

export type EditCarElementDialogState = FormWithValidationState<CarElementFormData> & {
  readonly active: boolean;
  readonly initialCarElement: undefined | CarElement;
};

export const EMPTY_EDIT_CAR_ELEMENT_DIALOG_STATE: EditCarElementDialogState = {
  active: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  initialCarElement: undefined,
  formData: EMPTY_CAR_ELEMENT_FORM,
};

export type DeleteCarElementModalState = {
  readonly active: boolean;
  readonly carElementId: string;
};

export const EMPTY_DELETE_CAR_ELEMENT_MODEL_STATE: DeleteCarElementModalState = {
  active: false,
  carElementId: '',
};

export type CarElementsSortValues =
  | 'category'
  | 'label'
  | 'carElementCount'
  | 'index'
  | 'timestamp';

export type SortCarElementFormData = {
  readonly duplicateIndexArray: readonly string[];
};

export type SortCarElementForm = WithFormValidationWarnings<SortCarElementFormData>;

export const EMPTY_SORT_CAR_ELEMENT_FORM: SortCarElementForm = {
  duplicateIndexArray: [],
  warnings: {},
};

export type SortCarElementDialogState = FormWithValidationState<SortCarElementFormData> & {
  readonly active: boolean;
  readonly initialCarElements: readonly CarElement[];
  readonly carElements: readonly CarElement[];
};

export const EMPTY_SORT_CAR_ELEMENT_DIALOG_STATE: SortCarElementDialogState = {
  active: false,
  initialCarElements: [],
  carElements: [],
  formData: EMPTY_SORT_CAR_ELEMENT_FORM,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  formSubmitted: false,
};

export type AdminCarElementsState = TableState<CarElement> &
  ComponentWithMountTracking & {
    readonly editCarElementDialog: EditCarElementDialogState;
    readonly deleteCarElementDialog: DeleteCarElementModalState;
    readonly sortCarElementDialog: SortCarElementDialogState;
    readonly searchFilter: ByCarElementsFilterState;
  };

export const EMPTY_ADMIN_CAR_ELEMENTS_STATE: AdminCarElementsState = {
  ...TABLE_EMPTY_STATE,
  componentIsMounted: false,
  searchFilter: {
    category: '',
    shapeId: '',
  },
  editCarElementDialog: EMPTY_EDIT_CAR_ELEMENT_DIALOG_STATE,
  deleteCarElementDialog: EMPTY_DELETE_CAR_ELEMENT_MODEL_STATE,
  sortCarElementDialog: EMPTY_SORT_CAR_ELEMENT_DIALOG_STATE,
};
