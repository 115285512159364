import React from 'react';
import type { Decorator } from '@stimcar/libs-base';
import { FaIcon } from '../FaIcon.js';

interface DecoratorsProps {
  readonly decorators: readonly Decorator[];
}

export function Decorators({ decorators }: DecoratorsProps): JSX.Element {
  return (
    <>
      {decorators.map((decorator): JSX.Element => {
        const { id, label, bgColor, fgColor } = decorator;
        const pulsating = decorator.type === 'icon' ? decorator.pulsating : false;
        return (
          <span
            key={id}
            className={`bulletIcon ${pulsating ? 'pulsating' : ''}`}
            style={{ backgroundColor: bgColor, color: !fgColor ? '#FFF' : fgColor }}
            title={!label ? id : label}
          >
            {decorator.type === 'text' && <>{decorator.text}</>}
            {decorator.type === 'icon' && <FaIcon id={decorator.icon} />}
          </span>
        );
      })}
    </>
  );
}
