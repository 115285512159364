import React, { useEffect, useMemo, useRef, useState } from 'react';
import type { NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import type {
  ComputeAttachmentUrlCallback,
  ConfirmAttachmentDialogState,
} from '@stimcar/libs-uitoolkit';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import { DeleteClickableIcon, loadFile } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../app/state/typings/store.js';
import type { PictureInputState } from '../typings/store.js';
import { importAttachmentsAction } from '../../../app/utils/attachmentGalleryActions.js';
import './PictureInput.scss';

interface GenericPictureInputProps {
  readonly $: StoreStateSelector<Store, PictureInputState>;
  readonly computeAttachmentUrlCallback: ComputeAttachmentUrlCallback;
  readonly label?: string;
  readonly folder: string;
  readonly kanbanId: string;
  readonly filename: string;
  readonly isEditable: boolean;
  readonly placeholderPicturePath: string;
  readonly notSetPictureAdditionalLayer: string;
  readonly $confirmAttachmentRemovalDialog: StoreStateSelector<Store, ConfirmAttachmentDialogState>;
  readonly overridingOnClickCallback?: NoArgActionCallback<Store>;
  readonly customAttachmentUploadRoute?: string;
}

export function GenericPictureInput({
  $,
  label,
  folder,
  kanbanId,
  filename,
  isEditable,
  placeholderPicturePath,
  notSetPictureAdditionalLayer,
  $confirmAttachmentRemovalDialog,
  overridingOnClickCallback,
  computeAttachmentUrlCallback,
  customAttachmentUploadRoute,
}: GenericPictureInputProps): JSX.Element {
  const isSet = useGetState($.$isSet);
  const [isLoading, setIsLoading] = useState(true);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const pictureUrl = useMemo(() => {
    return computeAttachmentUrlCallback('kanban', folder, filename, kanbanId, {
      mode: 'cover',
      size: '640x480',
    });
  }, [computeAttachmentUrlCallback, filename, folder, kanbanId]);

  const checkImageIsSetAsyncEffect = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      try {
        await httpClient.httpGet(pictureUrl);
        actionDispatch.setProperty('isSet', true);
      } catch {
      } finally {
        setIsLoading(false);
      }
    },
    [pictureUrl],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkImageIsSetAsyncEffect();
  }, [checkImageIsSetAsyncEffect]);

  const src = useMemo(() => {
    if (isLoading) {
      return 'transp.png';
    }
    if (isSet) {
      // We need to add a unique url parameter to avoid the browser to cache the image
      // Otherwise, a deleted image would still appear after a new image has been set
      return `${pictureUrl}?t=${new Date().getTime()}`;
    }
    return placeholderPicturePath;
  }, [isSet, pictureUrl, placeholderPicturePath, isLoading]);

  const openConfirmAttachmentRemovalModalActionCallback = useSetCallback(
    $confirmAttachmentRemovalDialog,
    {
      id: '',
      active: true,
      name: filename,
      metadata: undefined,
      folder,
    }
  );

  const onImportAttachmentCallback = useActionCallback(
    async (
      { actionDispatch, globalActionDispatch },
      event: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
      const file = new File([nonnull(event.target.files)[0]], filename);

      await loadFile(file);
      await globalActionDispatch.exec(
        importAttachmentsAction,
        'kanban',
        kanbanId,
        folder,
        [file],
        undefined,
        customAttachmentUploadRoute
      );

      actionDispatch.setProperty('isSet', true);
    },
    [filename, folder, kanbanId, customAttachmentUploadRoute],
    $
  );

  const onClickActionCallback = useActionCallback(
    async ({ globalActionDispatch }) => {
      if (isSet) {
        const url = computeAttachmentUrlCallback('kanban', folder, filename, kanbanId);
        if (isTruthy(overridingOnClickCallback)) {
          await globalActionDispatch.execCallback(overridingOnClickCallback);
        } else {
          globalActionDispatch.applyPayload({
            imageModal: {
              active: true,
              imageIndex: 0,
              imagesUrls: [url],
            },
          });
        }
      } else {
        fileInputRef.current?.click();
      }
    },
    [computeAttachmentUrlCallback, filename, folder, isSet, kanbanId, overridingOnClickCallback],
    $
  );

  const onKeyDownActionCallback = useActionCallback(
    async ({ actionDispatch }, e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        await actionDispatch.execCallback(onClickActionCallback);
      }
    },
    [onClickActionCallback],
    $
  );

  const isClickable = useMemo(() => isSet || isEditable, [isSet, isEditable]);

  return (
    <>
      <figure
        className={`picture-input image is-4by3${isClickable ? ' is-clickable' : ''} has-background-light`}
      >
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          alt=""
          src={src}
          onClick={isSet ? onClickActionCallback : undefined}
          onKeyDown={isSet ? onKeyDownActionCallback : undefined}
        />
        {isSet && isEditable && (
          <DeleteClickableIcon
            isSmall
            customStyle={{ position: 'absolute', top: 2, right: 2 }}
            handler={openConfirmAttachmentRemovalModalActionCallback}
          />
        )}
        {!isSet && isClickable && (
          <>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              alt=""
              onClick={onClickActionCallback}
              src={notSetPictureAdditionalLayer}
              onKeyDown={onKeyDownActionCallback}
            />
            <input
              capture
              type="file"
              accept="image/*"
              ref={fileInputRef}
              className="file-input"
              onChange={onImportAttachmentCallback}
            />
          </>
        )}
      </figure>
      {isTruthy(label) && <p className="has-text-centered">{label}</p>}
    </>
  );
}
