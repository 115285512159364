import i18next from 'i18next';
import type {
  NotificationMessage,
  SparePartsProvider,
  UIContract,
  WorkflowsState,
} from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { CoreBackendSSEMessages, Role } from '@stimcar/libs-base';
import { computePayload } from '@stimcar/libs-kernel';
import { launchRegisteredApp } from '../registeredapp/launchRegisteredApp.js';
import { ThemeSelector } from '../themes/ThemeSelector.js';
import { App } from './App.js';
import { SELECT_KANBAN_FULL_PATH } from './coreConstants.js';
import {
  KANBAN_WORKFLOWS_STATE_SNAPSHOT_KEY,
  updateDisplayViewStateFromSSEAction,
} from './display/Display.js';
import './i18n.js';
import type { Store, StoreState } from './state/typings/store.js';
import { bootstrapSelectKanbanFilterPreferencesAction } from './selectKanban/SelectKanban.js';
import { carElementRepositoryEventListenerAction } from './state/actions/carElementRepositoryEventListenerAction.js';
import { customerRepositoryEventListenerAction } from './state/actions/customerRepositoryEventListenerAction.js';
import { kanbanRepositoryEventListenerAction } from './state/actions/kanbanRepositoryEventListenerAction.js';
import { packageDealDescRepositoryEventListenerAction } from './state/actions/packageDealDescRepositoryEventListenerAction.js';
import { EMPTY_STORE_STATE } from './state/store.js';
import { retrieveHandledKanbansInitialization } from './utils/operatorUtils.js';
import { retrieveKanbansHandledInWorkshopInitialization } from './workshop/implantation/WorkshopImplantationView.js';

export function launchMainApp() {
  const onStoreReadyAction = async ({
    httpClient,
    actionDispatch,
  }: ActionContext<Store, StoreState>) => {
    // Initialize state
    const isBrowserRegistered = httpClient.isBrowserRegistered();
    const infos = isBrowserRegistered ? httpClient.getBrowserInfos() : undefined;
    const isWorkshop = isBrowserRegistered && infos?.role === Role.WorkshopOperator;
    if (!isWorkshop) {
      await actionDispatch.exec(retrieveHandledKanbansInitialization);
      await actionDispatch
        .scopeProperty('selectKanbanView')
        .exec(bootstrapSelectKanbanFilterPreferencesAction);
    } else {
      await actionDispatch.exec(
        retrieveKanbansHandledInWorkshopInitialization,
        isBrowserRegistered,
        infos
      );
    }
  };

  // Launch the core application
  launchRegisteredApp<false, Store>({
    appDecoratorComponent: ThemeSelector,
    registeredAppComponent: App,
    registeredAppRoute: SELECT_KANBAN_FULL_PATH,
    initialStoreState: EMPTY_STORE_STATE,
    sseMessageListenersActions: {
      [CoreBackendSSEMessages.KANBAN_WORKFLOWS_STATE_SNAPSHOT]:
        async function sseWorkflowStateSnapshotAction(
          { keyValueStorage, actionDispatch },
          { state }: WorkflowsState
        ) {
          // Save start state
          keyValueStorage.setObjectItem(KANBAN_WORKFLOWS_STATE_SNAPSHOT_KEY, state);
          // Update state
          await actionDispatch
            .scopeProperty('displayView')
            .exec(updateDisplayViewStateFromSSEAction);
        },
      [CoreBackendSSEMessages.CONTRACTS]: function sseContractsAction(
        { actionDispatch, getState },
        contracts: readonly UIContract[]
      ) {
        const newContracts = contracts.map((c) => ({ ...c, id: c.code }));
        try {
          // Try a minimalistic state update
          const payload = computePayload(getState().contracts, newContracts);
          actionDispatch.scopeProperty('contracts').applyPayload(payload);
        } catch {
          // Otherwise, overwrite the state
          actionDispatch.setProperty('contracts', newContracts);
        }
      },
      [CoreBackendSSEMessages.SPARE_PARTS_PROVIDERS]: function sseSparePartsProvidersAction(
        { actionDispatch, getState },
        sparaPartsProviders: readonly SparePartsProvider[]
      ) {
        try {
          // Try a minimalistic state update
          const payload = computePayload(getState().sparepartsProviders, sparaPartsProviders);
          actionDispatch.scopeProperty('sparepartsProviders').applyPayload(payload);
        } catch {
          // Otherwise, overwrite the state
          actionDispatch.setProperty('sparepartsProviders', sparaPartsProviders);
        }
      },
      [CoreBackendSSEMessages.NOTIFICATION]: function sseNotificationMessageAction(
        { actionDispatch, httpClient, getState },
        { message, level }: NotificationMessage
      ) {
        // Add the notification
        // Don't show the notification if the user is not connected
        if (getState().session.user) {
          actionDispatch.applyPayload({
            notifications: [
              {
                id: httpClient.getBrowserSequence().next(),
                level,
                message,
              },
            ],
          });
        }
      },
    },
    repositoryEventListenerActions: {
      kanbanRepositoryEventListenerAction,
      packageDealDescRepositoryEventListenerAction,
      carElementRepositoryEventListenerAction,
      customerRepositoryEventListenerAction,
    },
    onStoreReadyAction,
    localStorageKeysToKeepDuringSoftReset: [
      LocalStorageKeys.KANBAN_SELECTION_FILTER_PREFERENCE,
      LocalStorageKeys.ADMIN_PDD_COLUMNS_PREFERENCE,
      LocalStorageKeys.STAND_ACHIEVEMENT_DISPLAY,
      LocalStorageKeys.ADMIN_USERS_COLUMNS_PREFERENCE,
      LocalStorageKeys.ALREADY_KNOWN_USERS_LIST,
      LocalStorageKeys.WORKSHOP_IMPLANTATION_VIEW_MIRROR_EFFECT,
      LocalStorageKeys.LAST_DISPLAY_PATH,
      LocalStorageKeys.SHIFT_PROGRESS_DISPLAY,
    ],
    // @mcartaud said: Expertise view as an issue on mobile device that can lead to the
    // lost of the current expertise. To prevent this loss, an expertise state dump is
    // done before probematic actions. Reloading the page with a dump of the state may
    // lead to unknown behavior, so the dump is removed before refresh (not needed in
    // reset mode because it is already deleted by the reset)
    localStorageKeysToRemoveWhenAppIsUpdated: [LocalStorageKeys.DESKTOP_EXPERTISE_STATE_DUMP],
    lightMode: false,
    t: i18next.t,
  });
}
