import type { ProdStatsRange } from '@stimcar/core-libs-common';
import type { Entity } from '@stimcar/libs-kernel';

export type ShiftProgressRange =
  Entity /* an ID is inserted in the object to become compliant with state conventions */ &
    ProdStatsRange;

export const SHIFT_PROGRESS_CATEGORIES = ['EXP', 'MECA', 'CARRO', 'AUTRE'] as const;

export type ShiftProgressCategory = (typeof SHIFT_PROGRESS_CATEGORIES)[number];

export const SHIFT_PROGRESS_DIPLAY_MODES = ['aheadBehindTarget', 'achievements'] as const;

export type ShiftProgressDisplayMode = (typeof SHIFT_PROGRESS_DIPLAY_MODES)[number];

export type ShiftProgressDisplayState = {
  readonly lastShifts: readonly [
    ShiftProgressRange, // Day -1
    ShiftProgressRange, // Shift-2
    ShiftProgressRange, // Shift-1
    ShiftProgressRange, // Current shift
  ];
  readonly expectedCurrentShiftWorkload: number;
  readonly dayWorkload: number;
  readonly dayProgressesSum: number;
  readonly dayKanbansDone: number;
  readonly size: number;
  readonly statsMode: 'EXP' | 'MECA_CARRO_AUTRE';
  readonly includeSubcontractors: boolean;
  readonly displayMode: ShiftProgressDisplayMode;
  readonly active: boolean;
};

const EMPTY_SHIFT_WORKLOAD = (id: number): ShiftProgressRange => ({
  id: String(id),
  from: 0,
  workload: 0,
  progressesSum: 0,
  kanbansDone: 0,
});

export const EMPTY_SHIFT_PROGRESS_DISPLAY_STATE: ShiftProgressDisplayState = {
  lastShifts: [
    EMPTY_SHIFT_WORKLOAD(0),
    EMPTY_SHIFT_WORKLOAD(1),
    EMPTY_SHIFT_WORKLOAD(2),
    EMPTY_SHIFT_WORKLOAD(3),
  ],
  expectedCurrentShiftWorkload: 0,
  dayKanbansDone: 0,
  dayProgressesSum: 0,
  dayWorkload: 0,
  size: 2,
  includeSubcontractors: false,
  statsMode: 'MECA_CARRO_AUTRE',
  displayMode: 'aheadBehindTarget',
  active: false,
};
