import type {
  EventSourceFactory,
  KnownKeysOf,
  ListenerUnregisterer,
  RepositoryAccessMode,
  Role,
  SequencePrefixProvider,
} from '@stimcar/libs-base';
import type {
  Fetch,
  FormDataFactory,
  HttpLogFormatter,
  KeyValueStorage,
} from '@stimcar/libs-kernel';
import {
  APP_BROWSER_SESSION_TOKEN,
  BackendSSEMessages,
  CoreBackendRoutes,
  HTTPClientWithAuthImpl,
  HttpErrorCodes,
  Sequence,
  SSE_ACCESS_REVOKED,
} from '@stimcar/libs-base';
import { applyAsyncCallbackSequentially, getHttpStatusCode, Logger } from '@stimcar/libs-kernel';
import type {
  BrowserRegistrationListener,
  RegisteredBrowserInfos,
  RepositoryHTTPClient,
} from './typings/RepositoryHTTPClient.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

const BROWSER_SESSION_DATA_KEY = 'browserSessionData';

interface BrowserData extends RegisteredBrowserInfos {
  readonly token: string;
}

export class RepositoryHTTPClientImpl
  extends HTTPClientWithAuthImpl
  implements RepositoryHTTPClient
{
  private keyValueStorage: KeyValueStorage;

  private browserSessionData: BrowserData | null = null;

  private sequence!: Sequence;

  /**
   * Network status listeners.
   */
  private listeners: BrowserRegistrationListener[] = [];

  private unregisterers: ListenerUnregisterer[] = [];

  /**
   * Default constructor.
   */
  public constructor(
    keyValueStorage: KeyValueStorage,
    fetch: Fetch,
    formDataFactory: FormDataFactory,
    evFactory: EventSourceFactory,
    baseUrl?: string,
    logFormatter?: HttpLogFormatter
  ) {
    super(fetch, formDataFactory, evFactory, baseUrl, logFormatter);
    this.keyValueStorage = keyValueStorage;
    // Register SSE revocation listener
    this.unregisterers.push(
      this.registerServerMessageListener(SSE_ACCESS_REVOKED, this.handleBrowserRevokedEvent)
    );

    // Load the browser token
    this.browserSessionData = this.keyValueStorage.getObjectItem(BROWSER_SESSION_DATA_KEY);

    /**
     * A prefix provider is created to provide the prefix just in time. Generated
     * identifiers must be prefixed by the browser registration ID. But when the
     * repository is created, the browser is not registered, so we have to read it
     * lazily.
     */
    const prefixProvider: SequencePrefixProvider = (): string => {
      if (this.isBrowserRegistered()) {
        return `${this.getBrowserInfos().id}:`;
      }
      throw Error('Cannot generate identifiers, the browser is not registered');
    };
    this.sequence = new Sequence('c', prefixProvider);
  }

  public registerBrowserRegistrationListener(
    listener: BrowserRegistrationListener
  ): ListenerUnregisterer {
    this.listeners.push(listener);
    // Create listener unregisterer
    return (): void => {
      log.debug('Unregistering browser registration listener');
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  public async shutdown(timeout?: number): Promise<void> {
    log.info('Shutdowning repository HTTP client');
    await applyAsyncCallbackSequentially(
      this.unregisterers,
      async (unregisterer): Promise<void> => {
        await unregisterer();
      }
    );
    await super.shutdown(timeout);
  }

  public startSSE() {
    if (!this.browserSessionData) {
      throw new Error(`Registered browser token is missing, cannot open SSE`);
    }
    // Start the client only if the browser is registered
    this.restartSSE(BackendSSEMessages.SSE(encodeURIComponent(this.browserSessionData.token)));
  }

  public async registerBrowser(
    siteId: string,
    role: KnownKeysOf<typeof Role>,
    standId: string | undefined,
    label: string,
    canLogWithPin: boolean,
    forceLabel = false,
    repositoryAccessMode: RepositoryAccessMode = 'default'
  ): Promise<void> {
    const {
      token,
      company,
      site: siteWithConfiguration,
      id,
    } = await this.httpPostAsJSON(CoreBackendRoutes.BROWSER_REGISTER, {
      siteId,
      role,
      standId,
      label,
      canLogWithPin,
      forceLabel,
      repositoryAccessMode,
    });
    // Remember the registration
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { configuration, ...site } = siteWithConfiguration;
    this.browserSessionData = {
      company,
      site,
      role,
      label,
      standId,
      token,
      id,
    };
    this.saveBrowserSessiondata();
    // Notify listeners (but don't await, otherwise, we may have deadlocks)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Promise.all(this.listeners.map(async (becomes): Promise<void> => await becomes('registered')));
    // Once the browser is registered, start the SSE
    this.startSSE();
  }

  public switchToRole(newRole: string, newStandId: string): void {
    if (!this.browserSessionData) {
      throw Error('Missing session data');
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { role, ...data } = this.browserSessionData;
    this.browserSessionData = {
      ...data,
      role: newRole,
      standId: newStandId,
    };
    this.saveBrowserSessiondata();
  }

  private saveBrowserSessiondata(): void {
    if (!this.browserSessionData) {
      this.keyValueStorage.removeItem(BROWSER_SESSION_DATA_KEY);
    } else {
      this.keyValueStorage.setObjectItem(BROWSER_SESSION_DATA_KEY, this.browserSessionData);
    }
  }

  public isBrowserRegistered(): boolean {
    return this.browserSessionData !== null;
  }

  public getBrowserInfos(): RegisteredBrowserInfos {
    if (!this.browserSessionData) {
      throw Error('Browser is not registered');
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { token, ...rest } = this.browserSessionData;
    return rest;
  }

  public getBrowserSequence(): Sequence {
    return this.sequence;
  }

  public getBrowserSessionToken = (): string | undefined => this.browserSessionData?.token;

  protected getAdditionalHeaders(): Record<string, string> {
    let additionalHeaders = super.getAdditionalHeaders();
    // Append user session token
    if (this.browserSessionData) {
      additionalHeaders = {
        ...additionalHeaders,
        [APP_BROWSER_SESSION_TOKEN]: this.browserSessionData.token,
      };
    }
    return additionalHeaders;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected async handleFetchError(e: Error, path: string, init: RequestInit): Promise<void> {
    if (getHttpStatusCode(e) === HttpErrorCodes.INVALID_BROWSER_TOKEN && this.browserSessionData) {
      await this.handleBrowserRevokedEvent();
    }
    await super.handleFetchError(e, path, init);
  }

  private handleBrowserRevokedEvent = async (): Promise<void> => {
    log.warn('Browser session has been revoked on the server');
    this.browserSessionData = null;
    this.keyValueStorage.removeItem(BROWSER_SESSION_DATA_KEY);
    this.closeSSE();
    // Notify the UI
    await Promise.all(
      this.listeners.map(async (becomes): Promise<void> => await becomes('unregistered'))
    );
  };
}
