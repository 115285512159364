import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  CheckboxFormField,
  InputFormField,
  ModalCardDialog,
  ReactSelectFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { ReactSelectMultiFormField } from '../../../lib/bulma/form/custom/ReactSelectMultiFormField.js';
import type {
  EditUserInfosData,
  EditUserInfosDialogState,
  OnUserChangeActionCallback,
} from './typings/store.js';
import { EMPTY_EDIT_USER_INFOS_DIALOG_STATE } from './typings/store.js';

async function updateUserInfosAction(
  { getState, httpClient, actionDispatch }: ActionContext<Store, EditUserInfosDialogState>,
  onUserChangeActionCallback: OnUserChangeActionCallback
): Promise<void> {
  const { formData, initialUser } = getState();
  const {
    login,
    editedFirstName,
    editedLastName,
    editedActive,
    selectedProfiles,
    editedIsSubcontractor,
    editedFirm,
  } = formData;
  const newUser = {
    newFirstName: editedFirstName,
    newLastName: editedLastName,
    newActive: editedActive,
    newIsSubcontractor: editedIsSubcontractor,
    newFirm: editedFirm,
    newProfiles: selectedProfiles,
  };
  await httpClient.httpPostAsJSON(CoreBackendRoutes.UPDATE_USER_INFOS(login), newUser);
  actionDispatch.setValue(EMPTY_EDIT_USER_INFOS_DIALOG_STATE);
  // Notify UI
  await actionDispatch.execCallback(
    onUserChangeActionCallback,
    [
      {
        ...initialUser,
        firstName: editedFirstName,
        lastName: editedLastName,
        active: editedActive,
        isSubcontractor: editedIsSubcontractor,
        firm: editedFirm,
        // For an unknown reason, the profile names array is declared as
        // a nested object field in the final User object
        profiles: { names: selectedProfiles },
      },
    ],
    []
  );
}

export interface EditUserInfosModalProps {
  readonly $: StoreStateSelector<Store, EditUserInfosDialogState>;
  readonly availableProfiles: readonly string[];
  readonly onUserChangeActionCallback: OnUserChangeActionCallback;
}

export function EditUserInfosModal({
  $,
  availableProfiles,
  onUserChangeActionCallback,
}: EditUserInfosModalProps): JSX.Element {
  const [t] = useTranslation('usersAdmin');
  const formWarning = useGetState($.$formWarning);
  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) =>
      actionDispatch.exec(updateUserInfosAction, onUserChangeActionCallback),
    [onUserChangeActionCallback],
    $
  );

  const subcontractors = useGetState($.$subcontractors);
  const isSubcontractor = useGetState($.$formData.$editedIsSubcontractor);

  const mandatoryFields: (keyof EditUserInfosData)[] = useMemo(() => {
    const mandatoryFields = [
      'editedFirstName',
      'editedLastName',
      'selectedProfiles',
    ] as (keyof EditUserInfosData)[];
    if (isSubcontractor) return [...mandatoryFields, 'editedFirm' as keyof EditUserInfosData];
    return mandatoryFields;
  }, [isSubcontractor]);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditUserInfosDialogState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  const onSubcontractorChange = useActionCallback(
    ({ actionDispatch, getState }) => {
      const { editedIsSubcontractor } = getState();
      actionDispatch.setProperty('editedFirm', editedIsSubcontractor ? '' : 'Stimcar');
    },
    [],
    $formDataWithChangeTrigger
  );

  const $editedIsSubcontractorWithChangeTrigger = useSelectorWithChangeTrigger(
    $formDataWithChangeTrigger.$editedIsSubcontractor,
    onSubcontractorChange
  );

  return (
    <ModalCardDialog
      $active={$.$active}
      titleIconId="exclamation-triangle"
      title={t('users.editUserInfos.title')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <InputFormField
        label={t('users.editUserInfos.firstName')}
        $={$formDataWithChangeTrigger.$editedFirstName}
        horizontal
      />
      <InputFormField
        label={t('users.editUserInfos.lastName')}
        $={$formDataWithChangeTrigger.$editedLastName}
        horizontal
      />
      <CheckboxFormField
        label={t('users.editUserInfos.isSubcontractor')}
        $={$editedIsSubcontractorWithChangeTrigger}
        horizontal
        style={{ verticalAlign: 'bottom' }}
      />
      <ReactSelectFormField
        label={t('users.editUserInfos.firm')}
        $={$formDataWithChangeTrigger.$editedFirm}
        horizontal
        suggestions={subcontractors}
        isDisabled={!isSubcontractor}
      />
      <ReactSelectMultiFormField
        label={t('users.editUserInfos.profiles')}
        $={$formDataWithChangeTrigger.$selectedProfiles}
        horizontal
        suggestions={availableProfiles}
      />
      <CheckboxFormField
        label={t('users.editUserInfos.active')}
        $={$formDataWithChangeTrigger.$editedActive}
        horizontal
        style={{ verticalAlign: 'bottom' }}
      />
    </ModalCardDialog>
  );
}
