import type {
  Action,
  EntityServerActionList,
  Omit,
  RepositoryEntities,
  RepositoryEntityPayload,
  Sequence,
  ServerEntitySnapshot,
  SpecificFields,
} from '@stimcar/libs-base';
import type { TxAOP } from '@stimcar/libs-kernel';
import { objectListToMap } from '@stimcar/libs-base';
import {
  applyAsyncCallbackSequentially,
  applyPayload,
  computePayload,
  ensureError,
  isTruthy,
  Logger,
  nonnull,
} from '@stimcar/libs-kernel';
import type { Database, DatabaseTx } from '../../database/typings/database.js';
import type {
  LocalDbAction,
  LocalEntityHolder,
  Provider,
  RepositoryStoreDesc,
} from '../typings/repository-internals.js';
import type { RepositoryEventListener } from '../typings/repository.js';
import type { EntityMutationsDAO } from './typings/repository-dao.js';
import { LOCAL_ACTION_LOG_BY_ENTITY_ID_INDEX, RepositoryDAOImpl } from './RepositoryDAOImpl.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

/**
 * This array is used to enforce the fact that for direct UI calls, unexpected errors musn't
 * be pushed through UI notifications : if an error occurs, the exception will return to the
 * caller (the UI).
 */
const EMPTY_ERROR_ARRAY_FOR_UI_CALLS: readonly Error[] = [];

/**
 * Server push handler implementation.
 */
export class EntityMutationsDAOImpl<ENAME extends keyof RepositoryEntities>
  extends RepositoryDAOImpl<ENAME>
  implements TxAOP<EntityMutationsDAO<ENAME>, DatabaseTx<RepositoryStoreDesc<ENAME>>>
{
  private sequence: Sequence;

  private uiNotifierProvider: Provider<RepositoryEventListener<ENAME>>;

  public constructor(
    entityName: ENAME,
    database: Database<RepositoryStoreDesc<ENAME>>,
    uiNotifierProvider: Provider<RepositoryEventListener<ENAME>>,
    sequence: Sequence
  ) {
    super(entityName, database);
    this.sequence = sequence;
    this.uiNotifierProvider = uiNotifierProvider;
  }

  private applyLocalActions = async (
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    entityId: string,
    initial: SpecificFields<RepositoryEntities[ENAME]>
  ): Promise<SpecificFields<RepositoryEntities[ENAME]> | undefined> => {
    let local: SpecificFields<RepositoryEntities[ENAME]> | undefined;
    const actions = await tx.getFromIndex(
      'localActionLog',
      LOCAL_ACTION_LOG_BY_ENTITY_ID_INDEX,
      entityId
    );
    actions.forEach((action): void => {
      if (action.entityId === entityId) {
        switch (action.type) {
          case 'create':
            local = { id: entityId, ...action.payload };
            break;
          case 'update':
            local = {
              id: entityId,
              ...applyPayload(!local ? initial : local, action.payload),
            };
            break;
          case 'archive':
          case 'close':
            // Nothing to change in the entity
            break;
          case 'fix':
            throw Error('Fix actions are not expected to be applied in the repository client');
          default:
            throw Error(`Unknown action type ${Reflect.get(action, 'type')}`);
        }
      }
    });
    return local;
  };

  public noAOPFor = [
    // Own methods
    this.applyLocalActions,
    // RepositoryAPIBaseImpl methods
    this.getEntityData,
    this.toLocalEntity,
    this.toLocalEntity,
  ];

  public async updateEntitiesFromServerSnapshot(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    entities: readonly ServerEntitySnapshot<RepositoryEntities[ENAME]>[]
  ): Promise<void> {
    const unexpectedProcessingErrors: Error[] = [];
    const newEntities: RepositoryEntities[ENAME][] = [];
    const updatedEntities: RepositoryEntities[ENAME][] = [];

    // Retrieve existing holders (or undefined for non existing holders)
    const existingHolders: Record<
      string,
      LocalEntityHolder<RepositoryEntities[ENAME]> | undefined
    > = objectListToMap('id', await tx.getN('entities', ...entities.map(({ id }): string => id)));

    await Promise.all(
      entities.map(async ({ id, sequenceId, timestamp, entity, status, partitionKey }) => {
        try {
          const existing = existingHolders[id];
          const holder: LocalEntityHolder<RepositoryEntities[ENAME]> = {
            id,
            sequenceId,
            timestamp,
            status,
            serverEntity: entity,
            localEntity: await this.applyLocalActions(tx, id, entity),
            partitionKey,
          };
          await tx.put('entities', holder);
          const uiEntity = this.toLocalEntity(holder);
          (existing ? updatedEntities : newEntities).push(uiEntity);
        } catch (unexpectedError) {
          // Keep track of unexpected errors to ask the user
          // to save his work and reset the data as soon
          // as possible
          unexpectedProcessingErrors.push(ensureError(unexpectedError));
        }
      })
    );
    // Notify the UI
    this.notifyEntitiesUpdate(unexpectedProcessingErrors, updatedEntities, newEntities, [], []);
  }

  public async replaceAllEntitiesFromServerSnapshot(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    entities: readonly ServerEntitySnapshot<RepositoryEntities[ENAME]>[]
  ): Promise<void> {
    // Retrieve all entity ids
    const existingEntityIds = (await tx.getAll('entities')).map(({ id }) => id);
    // Remove all entities
    await tx.deleteAll('entities');

    const unexpectedProcessingErrors: Error[] = [];
    const newEntities: RepositoryEntities[ENAME][] = [];
    const updatedEntities: RepositoryEntities[ENAME][] = [];

    await Promise.all(
      entities.map(async ({ id, sequenceId, timestamp, entity, status, partitionKey }) => {
        try {
          const holder: LocalEntityHolder<RepositoryEntities[ENAME]> = {
            id,
            sequenceId,
            timestamp,
            status,
            serverEntity: entity,
            localEntity: await this.applyLocalActions(tx, id, entity),
            partitionKey,
          };
          await tx.put('entities', holder);
          const uiEntity = this.toLocalEntity(holder);
          const idx = existingEntityIds.indexOf(id);
          (idx >= 0 ? updatedEntities : newEntities).push(uiEntity);
          // Remove the current id from the list
          if (idx >= 0) {
            existingEntityIds.splice(idx, 1);
          }
        } catch (unexpectedError) {
          // Keep track of unexpected errors to ask the user
          // to save his work and reset the data as soon
          // as possible
          unexpectedProcessingErrors.push(ensureError(unexpectedError));
        }
      })
    );
    // Notify the UI (consider existing entities that no longer exist are closed)
    this.notifyEntitiesUpdate(
      unexpectedProcessingErrors,
      updatedEntities,
      newEntities,
      [],
      existingEntityIds
    );
  }

  public async handleServerActions(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    browserId: string,
    actionList: readonly EntityServerActionList<RepositoryEntities[ENAME]>[]
  ): Promise<void> {
    const unexpectedProcessingErrors: Error[] = [];
    const newEntities: RepositoryEntities[ENAME][] = [];
    const updatedEntities: RepositoryEntities[ENAME][] = [];
    const archivedEntyIds: string[] = [];
    const closedEntyIds: string[] = [];

    // Retrieve existing holders (or undefined for non existing holders)
    const entityIds = actionList.map(({ entityId }): string => entityId);
    const existingHoldersArray = await tx.getN('entities', ...entityIds);
    const existingHoldersMap: Record<
      string,
      LocalEntityHolder<RepositoryEntities[ENAME]> | undefined
    > = objectListToMap('id', existingHoldersArray);
    await Promise.all(
      actionList.map(async ({ entityId, actions }): Promise<void> => {
        try {
          const existingHolder = existingHoldersMap[entityId];
          let holder: LocalEntityHolder<RepositoryEntities[ENAME]> | undefined = existingHolder;
          let archiveActionDetected = false;
          let closeActionDetected = false;
          // Don't use Promise.all to ensure sequential execution
          await applyAsyncCallbackSequentially(actions, async (action, index): Promise<void> => {
            try {
              // Apply current action (only if the action is more recent
              // than the known server entity, or if the holder doesn't exist
              // which happens when the holder is created with a 'create' action)
              if (action.sequenceId > (holder?.sequenceId ?? 0)) {
                switch (action.type) {
                  case 'create':
                    // If the entity has been created within the current repository, the
                    // coresponding holder already exists. Otherwise (on other clients)
                    // it is not supposed to exist
                    if (isTruthy(holder) && browserId !== action.browserId) {
                      throw new Error(
                        `Create action coming from another client but for a holder that exists locally (entityId:${entityId}, actionId:${action.id})`
                      );
                    }
                    if (action.partitionKey === undefined) {
                      throw new Error(
                        `Missing partition key in server action (entityId:${entityId}, actionId:${action.id})`
                      );
                    }
                    holder = {
                      id: entityId,
                      status: 'open',
                      partitionKey: action.partitionKey,
                      sequenceId: action.sequenceId,
                      timestamp: action.timestamp,
                      serverEntity: action.payload,
                    };
                    break;
                  case 'update':
                    if (!isTruthy(holder)) {
                      throw new Error(
                        `Trying to apply an update action on an unknown holder (entityId:${entityId}, actionId:${action.id})`
                      );
                    }
                    // Update the holder
                    holder = {
                      ...holder,
                      sequenceId: action.sequenceId,
                      timestamp: action.timestamp,
                      serverEntity: applyPayload(
                        nonnull(
                          holder.serverEntity,
                          `The server entity is not expected to be undefined (entityId:${entityId}, actionId:${action.id})`
                        ),
                        action.payload
                      ),
                    };
                    break;
                  case 'archive':
                    if (!isTruthy(holder)) {
                      throw new Error(
                        `Trying to apply an archive action on an unknown holder (entityId:${entityId}, actionId:${action.id})`
                      );
                    }
                    archiveActionDetected = true;
                    // Update the holder
                    holder = {
                      ...holder,
                      sequenceId: action.sequenceId,
                      timestamp: action.timestamp,
                      status: 'archived',
                    };
                    break;
                  case 'close':
                    if (!isTruthy(holder)) {
                      throw new Error(
                        `Trying to apply a close action on an unknown holder (entityId:${entityId}, actionId:${action.id})`
                      );
                    }
                    closeActionDetected = true;
                    break;
                  case 'fix':
                    // If the fix is applied on an archived entity, the kanban is available
                    // otherwise, the kanban is closed and thus is not available locally : no
                    // update is required on the client side (the fix will be available on the
                    // server side where relies closed kanbans).
                    if (isTruthy(holder)) {
                      holder = {
                        ...holder,
                        serverEntity: applyPayload(
                          nonnull(
                            holder.serverEntity,
                            `The server entity is not expected to be undefined (entityId:${entityId}, actionId:${action.id})`
                          ),
                          action.payload
                        ),
                        timestamp: action.timestamp,
                        sequenceId: action.sequenceId,
                      };
                    }
                    break;
                  default:
                    throw Error(
                      `Unknown action type '${Reflect.get(action, 'type')}' (entityId:${entityId}, actionId:${Reflect.get(action, 'id')})`
                    );
                }
              }
              // Delete the action locally if it exists
              await tx.delete('localActionLog', action.id);
            } catch (unexpectedError) {
              log.error('Unexpected error while processing server action :');
              log.error('- EntityId:', entityId);
              log.error('- Actual holder:', holder);
              log.error('- Database holder:', await tx.get('entities', entityId));
              log.error('- Actions to apply:', actions);
              log.error('- Action index:', index);
              log.error('- Action :', action);
              log.error('- Exception:', unexpectedError);
              // Keep track of unexpected errors to ask the user
              // to save his work and reset the data as soon
              // as possible
              unexpectedProcessingErrors.push(ensureError(unexpectedError));
            }
          });
          if (holder && !closeActionDetected && !archiveActionDetected) {
            // Apply local actions
            holder = {
              ...holder,
              localEntity: await this.applyLocalActions(
                tx,
                entityId,
                nonnull(
                  holder.serverEntity,
                  `The server entity is not expected to be undefined (entityId:${entityId})`
                )
              ),
            };
            // Save the holder
            await tx.put('entities', holder);
            // Prepare UI notification
            const uiEntity: Omit<RepositoryEntities[ENAME], 'dirty'> = this.toLocalEntity(holder);
            (!existingHolder ? newEntities : updatedEntities).push(uiEntity);
          } else if (archiveActionDetected) {
            // Save the holder
            await tx.put('entities', nonnull(holder));
            // Prepare UI notification
            archivedEntyIds.push(entityId);
          } else if (closeActionDetected) {
            // Remove the holder
            await tx.delete('entities', entityId);
            // Prepare UI notification
            closedEntyIds.push(entityId);
          }
        } catch (unexpectedError) {
          // Keep track of unexpected errors to ask the user
          // to save his work and reset the data as soon
          // as possible
          unexpectedProcessingErrors.push(ensureError(unexpectedError));
        }
      })
    );

    // Notify the UI
    this.notifyEntitiesUpdate(
      unexpectedProcessingErrors,
      updatedEntities,
      newEntities,
      archivedEntyIds,
      closedEntyIds
    );
  }

  public async createEntity(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    entity: RepositoryEntities[ENAME]
  ): Promise<RepositoryEntities[ENAME]> {
    return (await this.createEntities(tx, entity))[0];
  }

  public async closeEntity(tx: DatabaseTx<RepositoryStoreDesc<ENAME>>, id: string): Promise<void> {
    await this.processNewLocalAction(tx, id, { type: 'close', payload: {} });
    // Notify the UI
    this.notifyEntitiesUpdate(EMPTY_ERROR_ARRAY_FOR_UI_CALLS, [], [], [], [id]);
  }

  public async archiveEntity(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    id: string
  ): Promise<void> {
    await this.processNewLocalAction(tx, id, { type: 'archive', payload: {} });
    // Notify the UI
    this.notifyEntitiesUpdate(EMPTY_ERROR_ARRAY_FOR_UI_CALLS, [], [], [id], []);
  }

  public async createEntities(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    ...entities: RepositoryEntities[ENAME][]
  ): Promise<readonly RepositoryEntities[ENAME][]> {
    const uiEntities = await Promise.all(
      entities.map(async (e): Promise<RepositoryEntities[ENAME]> => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, dirty, sequenceId, timestamp, status, ...payload } = e;
        const localAction = await this.processNewLocalAction(tx, id, {
          type: 'create',
          payload,
        });
        return localAction;
      })
    );
    // Notify the UI
    this.notifyEntitiesUpdate(EMPTY_ERROR_ARRAY_FOR_UI_CALLS, [], uiEntities, [], []);
    return uiEntities;
  }

  public async updateEntities(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    ...entities: RepositoryEntities[ENAME][]
  ): Promise<void> {
    const uiEntities: RepositoryEntities[ENAME][] = [];
    await Promise.all(
      entities.map(async (entity): Promise<void> => {
        // We must read the entity first to compute the payload
        const holder = await tx.get('entities', entity.id);
        if (!holder) {
          throw Error(`Unknown entity ${entity.id}`);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, dirty, sequenceId, timestamp, status, ...rest } = entity;
          const entityData: SpecificFields<RepositoryEntities[ENAME]> = rest;
          const existingEntity = this.getEntityData(holder);
          if (existingEntity === undefined) {
            throw Error(`Illegal state during update : missing entity data ${id}`);
          }
          const payload = computePayload(existingEntity, entityData);
          if (!payload) {
            throw Error(`Trying to update an entity that has not changed (entityId: ${holder.id})`);
          }
          uiEntities.push(
            await this.processNewLocalAction(tx, id, {
              type: 'update',
              payload,
            })
          );
        }
      })
    );
    // Notify the UI
    this.notifyEntitiesUpdate(EMPTY_ERROR_ARRAY_FOR_UI_CALLS, uiEntities, [], [], []);
  }

  public async updateEntity(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    entity: RepositoryEntities[ENAME]
  ): Promise<void> {
    await this.updateEntities(tx, entity);
  }

  public async updateEntitiesFromPayloads(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    ...payloads: RepositoryEntityPayload<RepositoryEntities[ENAME]>[]
  ): Promise<readonly RepositoryEntities[ENAME][]> {
    const uiEntities: RepositoryEntities[ENAME][] = [];
    await Promise.all(
      payloads.map(async ({ entityId, payload }): Promise<void> => {
        if (typeof payload === 'function') {
          throw Error(
            'Payload as function is not supported here, please provide a valid payload object'
          );
        }
        uiEntities.push(
          await this.processNewLocalAction(tx, entityId, {
            type: 'update',
            payload,
          })
        );
      })
    );
    // Notify the UI
    this.notifyEntitiesUpdate(EMPTY_ERROR_ARRAY_FOR_UI_CALLS, uiEntities, [], [], []);
    return uiEntities;
  }

  public async updateEntityFromPayload(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    payload: RepositoryEntityPayload<RepositoryEntities[ENAME]>
  ): Promise<RepositoryEntities[ENAME]> {
    return (await this.updateEntitiesFromPayloads(tx, payload))[0];
  }

  public acknowledgeLocalActions = async (
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    ...actionIds: string[]
  ): Promise<void> => {
    // If everything is fine, acknowledge the local actions
    await Promise.all(
      actionIds.map(async (actionId): Promise<void> => {
        const existing = await tx.get('localActionLog', actionId);
        // Some times the server push message is processed before the action is acknowledged
        // That is not a problem. We can simply ignore it (the acknowledgment helps not to
        // send an action more than once to the server. If the server hase already pushed the
        // server action, that's perfect)
        if (!isTruthy(existing)) {
          log.info(
            'The action that has been sent to the server has already been removed from the local action log (server broadcast has passed before the acknowledge could be put)',
            actionId
          );
        } else {
          await tx.put('localActionLog', { ...existing, acknowledged: true });
        }
      })
    );
  };

  private async processNewLocalAction(
    tx: DatabaseTx<RepositoryStoreDesc<ENAME>>,
    entityId: string,
    localAction: Action<RepositoryEntities[ENAME]>
  ): Promise<RepositoryEntities[ENAME]> {
    // Create the action
    const actionId = this.sequence.next();
    const actionWithId: LocalDbAction<ENAME> = {
      id: actionId,
      entityId,
      acknowledged: false,
      ...localAction,
    };
    await tx.put('localActionLog', actionWithId);

    let holder: LocalEntityHolder<RepositoryEntities[ENAME]>;
    switch (localAction.type) {
      case 'create':
        holder = {
          id: entityId,
          serverEntity: undefined,
          localEntity: localAction.payload,
          status: 'open',
          sequenceId: 0,
          timestamp: Date.now(),
          partitionKey: null,
        };
        break;
      case 'update':
        {
          const existingHolder = await tx.get('entities', entityId);
          if (!existingHolder) {
            throw Error(`Trying to update an unknown entity ${entityId}`);
          }
          const actualEntity = this.getEntityData(existingHolder);
          if (!actualEntity) {
            throw Error(`Illegal state, missing entity data ${entityId}`);
          }
          holder = {
            ...existingHolder,
            localEntity: applyPayload(actualEntity, localAction.payload),
          };
        }
        break;
      case 'archive':
      case 'close':
        {
          // Simply load the existing holder
          const existingHolder = await tx.get('entities', entityId);
          if (!existingHolder) {
            throw Error(`Trying to ${localAction.type} an unknown entity ${entityId}`);
          }
          holder = {
            ...existingHolder,
            status: localAction.type === 'close' ? 'closed' : 'archived',
          };
        }
        break;
      case 'fix':
        throw Error('Fix actions are not expected to be applied in the repository client');
      default:
        throw Error(`Unknown action type ${Reflect.get(localAction, 'type')}`);
    }
    // Save the holder
    await tx.put('entities', holder);
    return this.toLocalEntity(holder);
  }

  private notifyEntitiesUpdate(
    unexpectedServerPushMessageErrors: readonly Error[],
    updatedEntities: readonly RepositoryEntities[ENAME][],
    newEntities: readonly RepositoryEntities[ENAME][],
    archivedEntityIds: readonly string[],
    closedEntityIds: readonly string[]
  ): void {
    // Any server message processing error ?
    if (unexpectedServerPushMessageErrors.length > 0) {
      log.error(
        'Critical : unexpected errors in server messages processing, data may be corrupted.' +
          'You should reset the browser data as soon as possible.',
        unexpectedServerPushMessageErrors
      );
    }
    const uiNotifier = this.uiNotifierProvider.get();
    if (
      uiNotifier &&
      (unexpectedServerPushMessageErrors.length !== 0 ||
        updatedEntities.length !== 0 ||
        newEntities.length !== 0 ||
        archivedEntityIds.length !== 0 ||
        closedEntityIds.length !== 0)
    ) {
      // See https://stackoverflow.com/a/4575011 for an explanation of the interest of setTimeout(fn, 0)
      setTimeout((): void => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          uiNotifier(
            unexpectedServerPushMessageErrors,
            updatedEntities,
            newEntities,
            archivedEntityIds,
            closedEntityIds
          );
        } catch (e) {
          // If a failure happens in the UI it must n't
          // have any consequence on the repository
          // The error is caught and simply logged.
          log.error(
            'Unexpected error while notifying the UI for',
            unexpectedServerPushMessageErrors,
            updatedEntities,
            newEntities,
            archivedEntityIds,
            closedEntityIds,
            e
          );
        }
      }, 0);
    }
  }
}
