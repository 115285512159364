import i18next from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, StorageCategories } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  Column,
  SavedFilter,
  TableToolbarConfiguration,
} from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  CoreBackendRoutes,
  kanbanHelpers,
  mergeArrayItems,
  URL_LIST_ELEMENTS_SEPARATOR,
} from '@stimcar/libs-base';
import { isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useScreenIsBulmaMobile,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  applyAndFilterTableItemsAction,
  Button,
  KanbanListWithActions,
  MasterDetailsTable,
  PdfCreationAndUploadModal,
  TableToolbar,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import {
  convertToPdfAction,
  importAttachmentsAction,
  loadAttachmentsGalleryAction,
  removePdfPageAction,
} from '../utils/attachmentGalleryActions.js';
import { useGetActionDescsProvider } from '../utils/navigationActions.js';
import { useComputeAttachmentUrl } from '../utils/useComputeAttachmentUrl.js';
import type { SelectKanbanViewLocalStorage } from './typings/localStorage.js';
import type { SelectKanbanState } from './typings/store.js';
import { SelectionKanbanDetails } from './components/SelectionKanbanDetails.js';
import { StandSelectionButton } from './components/StandSelectionButton.js';
import {
  computeColumnDescs,
  filterTableKanbans,
  kanbanTableContentProvider,
  reloadKanbanTableContentAction,
} from './selectKanbanUtil.js';

const getDefaultFilters = (): SavedFilter[] => {
  return [
    {
      id: i18next.t('selectKanban:filter.marketplaceKanbans'),
      isCreatedByDefault: true,
      filter: {
        associationOperator: 'and',
        filterConditions: [
          {
            id: 'marketplaceContract',
            columnId: 'contract',
            operator: 'contains',
            propertyType: 'string',
            value: 'marketplace',
          },
        ],
      },
    },
    {
      id: i18next.t('selectKanban:filter.lateSpareParts'),
      isCreatedByDefault: true,
      filter: {
        associationOperator: 'and',
        filterConditions: [
          {
            id: 'lateSparePartsDefaultConditionId',
            columnId: 'sparePartsAge',
            operator: 'greater',
            propertyType: 'number',
            value: '2',
          },
        ],
      },
    },
  ];
};

/**
 * This initializes the SelectKanban state once during bootstrap.
 */
export function bootstrapSelectKanbanFilterPreferencesAction({
  actionDispatch,
  keyValueStorage,
  getGlobalState,
}: ActionContext<Store, SelectKanbanState>) {
  const { siteConfiguration } = getGlobalState();

  const properties = keyValueStorage.getObjectItem<SelectKanbanViewLocalStorage>(
    LocalStorageKeys.KANBAN_SELECTION_FILTER_PREFERENCE
  );
  const selectedStands: string[] = [];
  if (properties?.selectedStands) {
    selectedStands.push(...properties.selectedStands);
  } else {
    const stand = siteConfiguration.stands.find((s) => isTruthyAndNotEmpty(s.id));
    if (stand) {
      selectedStands.push(stand.id);
    }
  }
  actionDispatch.setProperty('selectedStands', selectedStands);
}

const MOBILE_COLUMNS: Column[] = [
  {
    id: 'license',
    isDisplayed: true,
    label: '',
    type: 'display',
  },
  {
    id: 'position',
    isDisplayed: true,
    label: '',
    type: 'display',
  },
  {
    id: 'contract',
    isDisplayed: true,
    label: '',
    type: 'display',
  },
  {
    id: 'customer',
    isDisplayed: true,
    label: '',
    type: 'display',
  },
  {
    id: 'brand',
    isDisplayed: true,
    label: '',
    type: 'display',
  },
  {
    id: 'model',
    isDisplayed: true,
    label: '',
    type: 'display',
  },
  {
    id: 'motor',
    isDisplayed: true,
    label: '',
    type: 'display',
  },
  {
    id: 'vin',
    isDisplayed: true,
    label: '',
    type: 'display',
  },
];

async function loadMobileColumnsAndKanbansAction(ctx: ActionContext<Store, SelectKanbanState>) {
  const { actionDispatch, getState } = ctx;
  // This is a hack to be able to use the Table framework search without re-coding it.
  // The textual search uses displayed columns for its search but the mobile, even if it uses
  // the same state is not a table. Since this state part is provided by the Table framework,
  // with the mobile versino it is always empty and thus there is never any results if we don't tell
  // the filtering method in which part it can search
  if (getState().columnSelectionState.columns !== MOBILE_COLUMNS) {
    actionDispatch.scopeProperty('columnSelectionState').setProperty('columns', MOBILE_COLUMNS);
  }
  await actionDispatch.exec(reloadKanbanTableContentAction);
}

export function updateSelectKanbanStateFromSSEAction(
  ctx: ActionContext<Store, SelectKanbanState>,
  updatedOrNewKanbans: readonly Kanban[],
  removedKanbanIds: readonly string[]
) {
  const { getState, getGlobalState } = ctx;
  const { siteConfiguration, contracts, session } = getGlobalState();
  const { selectedStands, items } = getState();

  const kanbans = filterTableKanbans(
    ctx,
    mergeArrayItems(items, updatedOrNewKanbans, removedKanbanIds)
  );

  const columnDescs = computeColumnDescs(
    siteConfiguration,
    contracts,
    session.infos,
    selectedStands
  );
  applyAndFilterTableItemsAction(ctx, kanbans, columnDescs);
}

export function SelectKanban({ $gs }: AppProps<Store>): JSX.Element {
  const isMobile = useScreenIsBulmaMobile($gs.$window);

  const localStorageKey = LocalStorageKeys.KANBAN_SELECTION_FILTER_PREFERENCE;

  return (
    <>
      {isMobile ? (
        <MobileSelectionKanban localStorageKey={localStorageKey} $gs={$gs} />
      ) : (
        <DesktopSelectKanban localStorageKey={localStorageKey} $gs={$gs} />
      )}
    </>
  );
}

function updateSelectedStandsAction(
  { actionDispatch, keyValueStorage }: ActionContext<Store, SelectKanbanState>,
  selectedStands: readonly string[],
  localStorageKey: string
) {
  actionDispatch.setProperty('selectedStands', selectedStands);

  // No need to explicitly trigger a refresh as the fact that the standIds change
  // in the state will trigger a reload of the table entities (because the columnDefs
  // will change)
  // await actionDispatch.exec(reloadKanbanTableContentAction);
  const storedPrefs = keyValueStorage.getObjectItem<SelectKanbanViewLocalStorage>(localStorageKey);
  keyValueStorage.setObjectItem<SelectKanbanViewLocalStorage>(localStorageKey, {
    ...storedPrefs,
    selectedStands,
  });
}

async function toggleStandSelectionStatusAction(
  { actionDispatch, getState }: ActionContext<Store, SelectKanbanState>,
  localStorageKey: string,
  standId: string
) {
  const { selectedStands: oldSelectedStands } = getState();
  const filteredStands = oldSelectedStands.filter((s) => s !== standId);
  const selectedStands =
    filteredStands.length < oldSelectedStands.length
      ? filteredStands
      : [...oldSelectedStands, standId];
  await actionDispatch.exec(updateSelectedStandsAction, selectedStands, localStorageKey);
}

async function selectAllStandsAction(
  { actionDispatch, getGlobalState }: ActionContext<Store, SelectKanbanState>,
  localStorageKey: string
) {
  const selectedStands = getGlobalState().siteConfiguration.stands.map(({ id }) => id);
  await actionDispatch.exec(updateSelectedStandsAction, selectedStands, localStorageKey);
}

async function selectNoStandAction(
  { actionDispatch }: ActionContext<Store, SelectKanbanState>,
  localStorageKey: string
) {
  await actionDispatch.exec(updateSelectedStandsAction, [], localStorageKey);
}

function clearExpandedStatusesRecordAction({
  actionDispatch,
  getState,
}: ActionContext<Store, SelectKanbanState>) {
  // This actions removes expanded status from the state for
  // kanbans that are not anymore in the selection list.
  const actualRecord = getState().expandedKanbanStatuses;
  const actualKanbansIds = keysOf(actualRecord);
  const allKanbanIds = getState().items.map(({ id }) => id);
  const filteredKanbanIds = actualKanbansIds.filter((id) => allKanbanIds.includes(id));
  if (actualKanbansIds.length !== filteredKanbanIds.length) {
    const newRecord: Record<string, boolean> = {};
    filteredKanbanIds.forEach((id) => {
      newRecord[id] = actualRecord[id];
    });
    actionDispatch.setProperty('expandedKanbanStatuses', newRecord);
  }
}
interface Props extends AppProps<Store> {
  readonly localStorageKey: string;
}

function MobileSelectionKanban({ localStorageKey, $gs }: Props): JSX.Element {
  const [t] = useTranslation('selectKanban');

  const { $selectKanbanView } = $gs;
  const { $pdfCreationAndUploadModal } = $selectKanbanView;

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const browserLabel = nonnull(useGetState($gs.$session.$infos.asDefined().$label));
  const isOnline = useGetState($gs.$session.$isOnline);

  const actionDescsProvider = useGetActionDescsProvider($gs, 'selection');

  const toggleDisplayKanbansForStand = useActionCallback(
    async ({ actionDispatch }, standId: string): Promise<void> => {
      await actionDispatch.exec(toggleStandSelectionStatusAction, localStorageKey, standId);
    },
    [localStorageKey],
    $selectKanbanView
  );

  const attachmentUrl = useComputeAttachmentUrl($gs);

  const computeIconFunction = useGetState($gs.$siteConfiguration.$computeIconFunction);
  const workflows = useGetState($gs.$siteConfiguration.$workflows);

  const computeIconFromKanbanFunction = useMemo(
    () => kanbanHelpers.getComputeIconFromKanbanFunction(computeIconFunction, workflows),
    [computeIconFunction, workflows]
  );

  const importAttachmentsActionCallback = useActionCallback(importAttachmentsAction, [], $gs);
  const loadGalleryAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $selectKanbanView.$pdfCreationAndUploadModal
  );

  const stands = useGetState($gs.$siteConfiguration.$stands);
  const siteConfiguration = useGetState($gs.$siteConfiguration);
  const contracts = useGetState($gs.$contracts);
  const browserInfos = useGetState($gs.$session.$infos);
  const { displayConfiguration } = siteConfiguration;
  const items = useGetState($selectKanbanView.$items);
  const selectedStands = useGetState($selectKanbanView.$selectedStands);

  const columnDescs = useMemo(() => {
    return computeColumnDescs(siteConfiguration, contracts, browserInfos, selectedStands);
  }, [siteConfiguration, contracts, browserInfos, selectedStands]);

  const toobarConf = useMemo((): TableToolbarConfiguration => {
    return {
      localStorageKey,
      textualSearch: { show: true, customPlaceholder: t('filter.textSearchPlaceholder') },
      itemCount: {
        show: true,
        getCustomLabel: (count) => {
          return t('filter.displayedKanbanCount', { count });
        },
      },
    };
  }, [localStorageKey, t]);

  // Hack ? (see method comments)
  const asyncEffect = useActionCallback(
    loadMobileColumnsAndKanbansAction,
    [selectedStands, siteConfiguration, browserInfos, browserInfos],
    $selectKanbanView
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  // Clear the expanded status every time a kanban is expanded / collapsed
  const clearExpandedStatusesRecordActionCallback = useActionCallback(
    clearExpandedStatusesRecordAction,
    [],
    $selectKanbanView
  );
  const $WithChangeTrigger = useSelectorWithChangeTrigger(
    $selectKanbanView,
    clearExpandedStatusesRecordActionCallback
  );

  return (
    <div>
      <TableToolbar
        $={$selectKanbanView}
        columnDescs={columnDescs}
        contentProvider={kanbanTableContentProvider}
        toolbar={toobarConf}
      />
      <div className="columns is-multiline is-mobile">
        {stands.map(
          (s): JSX.Element => (
            <div className="column is-2" key={s.id}>
              <StandSelectionButton
                clickHandler={toggleDisplayKanbansForStand}
                iconId={s.iconClass}
                additionalClass={selectedStands.includes(s.id) ? 'is-primary' : ''}
                tooltip={t('tooltips.selectStand', { standId: s.id })}
                standId={s.id}
              />
            </div>
          )
        )}
      </div>
      <KanbanListWithActions
        kanbans={items}
        actionDescsProvider={actionDescsProvider}
        $gs={$gs}
        $imageModal={$gs.$imageModal}
        kanbanColorationCharter={displayConfiguration.kanbanColorationCharter}
        attachmentUrl={attachmentUrl}
        getKanbanPositionIconId={computeIconFromKanbanFunction}
        $expandedKanbanStatuses={$WithChangeTrigger.$expandedKanbanStatuses}
      />
      <PdfCreationAndUploadModal
        $={$pdfCreationAndUploadModal}
        $imageModal={$gs.$imageModal}
        computeAttachmentUrl={computeAttachmentUrl}
        clientSpecificFolderId={browserLabel}
        isOnline={isOnline}
        loadAttachmentsActionCallback={loadGalleryAttachmentsActionCallback}
        importAttachmentsActionCallback={importAttachmentsActionCallback}
        removePdfPageHttpRequestAction={removePdfPageAction}
        convertToPdfHttpRequestAction={convertToPdfAction}
      />
    </div>
  );
}

function DesktopSelectKanban({ localStorageKey, $gs }: Props): JSX.Element {
  const [t] = useTranslation('selectKanban');
  const $ = $gs.$selectKanbanView;

  const isOnline = useGetState($gs.$session.$isOnline);

  const browserInfos = useGetState($gs.$session.$infos);
  const configurationState = useGetState($gs.$siteConfiguration);
  const contracts = useGetState($gs.$contracts);
  const selectedStands = useGetState($.$selectedStands);

  const columnDescs = useMemo(() => {
    return computeColumnDescs(configurationState, contracts, browserInfos, selectedStands);
  }, [configurationState, contracts, browserInfos, selectedStands]);

  const actionDescsProvider = useGetActionDescsProvider($gs, 'selection');

  const defaultFilters = useMemo(() => {
    return getDefaultFilters();
  }, []);

  const toggleDisplayKanbansForStand = useActionCallback(
    async ({ actionDispatch }, standId: string): Promise<void> => {
      await actionDispatch.exec(toggleStandSelectionStatusAction, localStorageKey, standId);
    },
    [localStorageKey],
    $
  );

  const selectAllStandsActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(selectAllStandsAction, localStorageKey);
    },
    [localStorageKey],
    $
  );

  const selectNoStandActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(selectNoStandAction, localStorageKey);
    },
    [localStorageKey],
    $
  );

  const toobarConf = useMemo((): TableToolbarConfiguration => {
    return {
      csvDownloadBaseFileName: t('csvDownloadBaseFileName'),
      filters: { show: true, defaultFilters },
      localStorageKey,
      showColumnSelection: true,
      showSorts: true,
      textualSearch: { show: true, customPlaceholder: t('filter.textSearchPlaceholder') },
      itemCount: {
        show: true,
        getCustomLabel: (count) => {
          return t('filter.displayedKanbanCount', { count });
        },
      },
    };
  }, [defaultFilters, localStorageKey, t]);

  // Clear the expanded status every time a kanban is expanded / collapsed
  const clearExpandedStatusesRecordActionCallback = useActionCallback(
    clearExpandedStatusesRecordAction,
    [],
    $
  );
  const $WithChangeTrigger = useSelectorWithChangeTrigger(
    $,
    clearExpandedStatusesRecordActionCallback
  );

  return (
    <div className="columns">
      <div className="column is-narrow">
        <Button
          iconId="check-square"
          tooltip={t('tooltips.selectAllStands')}
          onClick={selectAllStandsActionCallback}
          size="small"
          disabled={selectedStands.length === configurationState.stands.length}
        />
        <Button
          iconId="r/square"
          tooltip={t('tooltips.selectNoStand')}
          onClick={selectNoStandActionCallback}
          size="small"
          disabled={selectedStands.length === 0}
        />
        {configurationState.stands.map(
          (s): JSX.Element => (
            <div className="m-t-sm m-b-sm" key={s.id}>
              <StandSelectionButton
                clickHandler={toggleDisplayKanbansForStand}
                iconId={s.iconClass}
                additionalClass={selectedStands.includes(s.id) ? 'is-primary' : ''}
                tooltip={t('tooltips.selectStand', { standId: s.id })}
                standId={s.id}
                showLabel
              />
            </div>
          )
        )}
      </div>
      <div className="column">
        <MasterDetailsTable
          $={$WithChangeTrigger /* selector that clears the expanded items automatically */}
          columnDescs={columnDescs}
          contentProvider={kanbanTableContentProvider}
          isOnline={isOnline}
          isScrollable
          isTruncable
          tableClassName="table is-striped is-hoverable is-narrow is-fullwidth"
          columnsToKeepWhenDetailsIsOpen={4}
          detailsDrawerProportion={8}
          isTableInBox
          showRepositoryStatus
          toolbar={toobarConf}
        >
          <SelectionKanbanDetails $={$} $gs={$gs} actionDescsProvider={actionDescsProvider} />
        </MasterDetailsTable>
      </div>
    </div>
  );
}
