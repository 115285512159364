import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { ActionCallbackFromFunction } from '@stimcar/libs-uikernel';
import { KanbanPriorityLevel } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { iconHelpers } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import './KanbansBoardComponent.scss';
import type { KanbansBoardDisplayMode } from './typings/store.js';
import {
  DECORATOR_BULLET_SIZE,
  DECORATOR_ICON_SIZE,
  KANBANS_BOARD_REPAIR_ORDER_BG_CLASS,
  KANBANS_BOARD_REPAIR_ORDER_BG_CLASS_DEFAULT,
  KANBANS_BOARD_REPAIR_ORDER_BG_CLASS_DUE_DATE_IS_PAST,
  KANBANS_BOARD_REPAIR_ORDER_BG_CLASS_DUE_DATE_WITHIN_THRESHOLD,
  REPAIR_ORDER_LABEL_SIZE,
} from './typings/store.js';
import { kanbansBoardHelpers } from './utils/kanbansBoardHelpers.js';

const { grid, sizeInPx, fontSize } = kanbansBoardHelpers;

interface BulletIconProps {
  readonly size: number;
  readonly icon: string;
  readonly tooltip: string;
}

export function BulletIcon({ size, icon, tooltip }: BulletIconProps) {
  const { icon: realIcon } = useMemo(
    () => iconHelpers.getFontAwesomeStyleAndIconFromIconId(icon),
    [icon]
  );
  const dimension = sizeInPx(DECORATOR_BULLET_SIZE, size);

  return (
    <div
      title={tooltip}
      className="is-flex is-justify-content-center is-align-items-center has-background-white"
      style={{
        width: dimension,
        height: dimension,
        borderRadius: '50%',
      }}
    >
      <i className={`fas fa-${realIcon}`} style={fontSize(DECORATOR_ICON_SIZE, size)} />
    </div>
  );
}

function useGetRepairOrderSlotColorClassName(
  priorityLevel: KanbanPriorityLevel,
  mode: KanbansBoardDisplayMode
) {
  return useMemo(() => {
    if (mode === 'unicolor') {
      return KANBANS_BOARD_REPAIR_ORDER_BG_CLASS;
    }
    if (priorityLevel === KanbanPriorityLevel.dueDateIsPast) {
      return KANBANS_BOARD_REPAIR_ORDER_BG_CLASS_DUE_DATE_IS_PAST;
    }
    if (priorityLevel === KanbanPriorityLevel.dueDateIsWithinThreshold) {
      return KANBANS_BOARD_REPAIR_ORDER_BG_CLASS_DUE_DATE_WITHIN_THRESHOLD;
    }
    return KANBANS_BOARD_REPAIR_ORDER_BG_CLASS_DEFAULT;
  }, [priorityLevel, mode]);
}

interface RepairOrderSlotItemDisplayProps {
  readonly row?: number;
  readonly col?: number;
  readonly id: string;
  readonly license: string;
  readonly standId: string;
  readonly priorityLevel: KanbanPriorityLevel;
  readonly isBeforeLastStand: boolean;
  readonly standIconToDisplay: string | undefined;
  readonly mode: KanbansBoardDisplayMode;
  readonly size: number;
  readonly onClickActionCallback?: ActionCallbackFromFunction<Store, () => void>;
}

export function RepairOrderSlotItemDisplay({
  row,
  col,
  id,
  license,
  standId,
  priorityLevel,
  isBeforeLastStand,
  standIconToDisplay,
  mode,
  size,
  onClickActionCallback,
}: RepairOrderSlotItemDisplayProps): JSX.Element {
  const classBgColor = useGetRepairOrderSlotColorClassName(priorityLevel, mode);

  const gridPositionStyle = isTruthy(row) && isTruthy(col) ? grid({ row, col }) : {};

  return (
    <div className={`columns is-repair-order-slot ${classBgColor}`} style={gridPositionStyle}>
      <div className="column" title={`${license} (${standId})`}>
        <button
          key={id}
          type="button"
          onClick={onClickActionCallback}
          style={fontSize(REPAIR_ORDER_LABEL_SIZE, size)}
        >
          {license}
        </button>
      </div>
      <div className="column is-narrow">
        {isBeforeLastStand && isTruthy(standIconToDisplay) && (
          <BulletIcon size={size} icon={standIconToDisplay} tooltip={standId} />
        )}
      </div>
    </div>
  );
}
