import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import type { SiteConfiguration } from '../../model/typings/configuration.js';
import type { BasePackageDeal, Kanban, PriceablePackageDeal } from '../../model/typings/kanban.js';
import type { PackageDealExpressionComputationResult } from '../../model/typings/share.js';
import { PACKAGE_DEAL_CODES_THAT_CANNOT_BE_REMOVED } from '../../model/globalConstants.js';
import { nonDeleted } from '../misc.js';
import type { FlatOperationItem } from './packageDealHelpers.js';
import { packageDealHelpers } from './packageDealHelpers.js';
import { workflowHelpers } from './workflowHelpers.js';

interface UnfinishedExpertiseRelatedFlatOperationsResult {
  readonly unfinishedExpertiseOrSparePartsReferenceOperations: readonly FlatOperationItem[];
  readonly unfinishedValidationExpertiseOperations: readonly FlatOperationItem[];
}

function getUnfinishedExpertiseRelatedFlatOperations(
  packageDeals: readonly BasePackageDeal[],
  workflowId: string,
  siteConfiguration: SiteConfiguration
): UnfinishedExpertiseRelatedFlatOperationsResult {
  const unfinishedExpertiseOrSparePartsReferenceOps: FlatOperationItem[] = [];
  const unfinishedValidationExpertiseOps: FlatOperationItem[] = [];

  const expertiseStandsByWorkflowId =
    workflowHelpers.getExpertiseStandsByWorkflowId(siteConfiguration);
  if (expertiseStandsByWorkflowId[workflowId].length > 1) {
    throw new Error(`More than one expertise stand within workflow ${workflowId}`);
  }
  const sparepartsReferenceStandsByWorkflowId = workflowHelpers.getAllStandsOfTypeByWorkflowId(
    siteConfiguration,
    'sparepartsReference'
  );
  if (sparepartsReferenceStandsByWorkflowId[workflowId].length > 1) {
    throw new Error(`More than one spareparts reference stand within workflow ${workflowId}`);
  }
  const expertiseValidationStandsByWorkflowId = workflowHelpers.getAllStandsOfTypeByWorkflowId(
    siteConfiguration,
    'expertiseValidation'
  );
  if (expertiseValidationStandsByWorkflowId[workflowId].length > 1) {
    throw new Error(`More than one expertise validation stand within workflow ${workflowId}`);
  }
  const expertiseStandId = expertiseStandsByWorkflowId[workflowId][0];
  const sparepartsReferenceStandId = sparepartsReferenceStandsByWorkflowId[workflowId][0];
  const validationExpertiseStandId = expertiseValidationStandsByWorkflowId[workflowId][0];
  const expertisePackageDeal =
    packageDealHelpers.getExpertisePackageDealWithoutRaisingAnError(packageDeals);
  if (expertisePackageDeal === 'MORE_THAN_ONE_EXPERTISE_PACKAGE_DEAL') {
    throw new Error(`More than one expertise package deal`);
  }
  if (expertisePackageDeal === 'NO_EXPERTISE_PACKAGE_DEAL') {
    return {
      unfinishedExpertiseOrSparePartsReferenceOperations: [],
      unfinishedValidationExpertiseOperations: [],
    };
  }
  packageDealHelpers
    .getFlatOperationList([expertisePackageDeal], 'achievable')
    .forEach((operation) => {
      if (!operation.deleted && !operation.completionDate) {
        if (
          expertiseStandId === operation.standId ||
          sparepartsReferenceStandId === operation.standId
        ) {
          unfinishedExpertiseOrSparePartsReferenceOps.push(operation);
        } else if (validationExpertiseStandId === operation.standId) {
          unfinishedValidationExpertiseOps.push(operation);
        }
      }
    });
  return {
    unfinishedExpertiseOrSparePartsReferenceOperations: unfinishedExpertiseOrSparePartsReferenceOps,
    unfinishedValidationExpertiseOperations: unfinishedValidationExpertiseOps,
  };
}

function hasAnExpertiseToValidate(
  packageDeals: readonly BasePackageDeal[],
  workflowId: string,
  siteConfiguration: SiteConfiguration
): boolean {
  const {
    unfinishedExpertiseOrSparePartsReferenceOperations,
    unfinishedValidationExpertiseOperations,
  } = getUnfinishedExpertiseRelatedFlatOperations(packageDeals, workflowId, siteConfiguration);
  return (
    unfinishedValidationExpertiseOperations.length > 0 &&
    unfinishedExpertiseOrSparePartsReferenceOperations.length === 0
  );
}

function selectExpertiseToValidate(
  kanbans: readonly Kanban[],
  siteConfiguration: SiteConfiguration
): Kanban[] {
  return kanbans.filter((k) => {
    return hasAnExpertiseToValidate(k.packageDeals, k.workflowId, siteConfiguration);
  });
}

function unremovableByCustomerPackageDealCodes(): string[] {
  return [
    ...PACKAGE_DEAL_CODES_THAT_CANNOT_BE_REMOVED,
    'AGR',
    'ANTI',
    'CONS',
    'CONV1',
    'CONV2',
    'CONV3',
    'CQUA',
    'CTREQUI',
    'CTRLMEC',
    'DEPOPLA',
    'DIAG',
    'ESTHSALE',
    'ESTHU',
    'GEO',
    'GEO2',
    'NIVADBLU',
    'NIVHUILE',
    'NIVLDF',
    'NIVLDR',
    'NIVLG',
    'PR',
    'PRESPNE',
    'REPAPB',
    'REPPNME',
    'RETHUIL',
    'RPTAAR',
    'RPTAAV',
    'RPTCAT',
    'RPTCEIN',
    'RPTCRE',
    'RPTGRED',
    'RPTJA',
    'RPTLV',
    'RPTMOLG',
    'RPTP1',
    'RPTP2',
    'RPTPED',
    'RPTREP',
    'RPTRET',
    'RPTRLT',
    'RPTSOUCAR',
    'RPTSOUCARB',
    'RPTSOUCARR',
    'RPTTRIA',
    'SIL',
    'TRANS',
    'VITRE',
    'VITRE2',
  ];
}

function hasPackageDealsWithStatusIncorrectlyModifiedByCustomer(
  initialKanban: Kanban,
  modifiedKanban: Kanban
): boolean {
  const unremovableCodes = unremovableByCustomerPackageDealCodes();
  for (const pck of modifiedKanban.packageDeals) {
    if (nonDeleted(pck) && unremovableCodes.includes(pck.code)) {
      const initial = nonnull(initialKanban.packageDeals.find((iPck) => iPck.id === pck.id));
      if (
        packageDealHelpers.isPackageDealCanceled(pck) &&
        !packageDealHelpers.isPackageDealCanceled(initial, true)
      ) {
        return true;
      }
    }
  }
  return false;
}

function applyPackageDealsValidationUpdatedInformations(
  packageDeals: readonly PriceablePackageDeal[],
  updatedInformations: readonly PackageDealExpressionComputationResult[]
): readonly PriceablePackageDeal[] {
  const usedExpertiseValidationIds: string[] = [];
  const updatedPackageDeals = packageDeals.map((pck): PriceablePackageDeal => {
    if (!pck.deleted) {
      const update = updatedInformations.find((u) => u.id === pck.id);
      if (isTruthy(update)) {
        usedExpertiseValidationIds.push(update.id);
        return {
          ...pck,
          label: update.label,
          status: update.status,
          price: update.price,
          purchaseOrderId: update.purchaseOrderId,
        };
      }
    }
    return pck;
  });

  // Assert all package deals have a status and that all not deleted package deals are covered by the given working copy
  if (
    updatedPackageDeals.filter((pck) => !pck.deleted && pck.status === null).length > 0 ||
    (updatedInformations.length ?? 0) !== usedExpertiseValidationIds.length
  ) {
    throw Error('Unexpected Error when applying computation');
  }

  return updatedPackageDeals;
}

function deduceCanceledPackageDealIdListFromAvailableOnes<T extends PriceablePackageDeal>(
  packageDeals: readonly T[],
  availablePackageDealIds: readonly string[]
): string[] {
  return packageDeals
    .filter((pck) => !pck.deleted && !availablePackageDealIds.includes(pck.id))
    .map((pck) => pck.id);
}

function deduceAvailablePackageDealsIdsFromActualStatusOrExpertRecommendation<
  T extends PriceablePackageDeal,
>(packageDeals: readonly T[]): string[] {
  return packageDeals
    .filter(
      (pck) =>
        !pck.deleted &&
        (pck.status === 'available' || (pck.status === null && pck.recommendedByExpert))
    )
    .map((pck) => pck.id);
}

export const expertiseHelpers = {
  selectExpertiseToValidate,
  applyPackageDealsValidationUpdatedInformations,
  hasAnExpertiseToValidate,
  unremovableByCustomerPackageDealCodes,
  deduceCanceledPackageDealIdListFromAvailableOnes,
  deduceAvailablePackageDealsIdsFromActualStatusOrExpertRecommendation,
  hasPackageDealsWithStatusIncorrectlyModifiedByCustomer,
  getUnfinishedExpertiseRelatedFlatOperations,
};
