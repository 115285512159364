import { marked } from 'marked';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { ComputeAttachmentUrlCallback, ImageModalState } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  MARKDOWN_FILE_EXTENSION,
  WORK_SHEET_ATTACHMENTS_FOLDER,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { AttachmentThumbnail } from '@stimcar/libs-uitoolkit';
import type { WorkSheetHintsTabState } from '../../app/operators/typings/store.js';
import type { Store } from '../../app/state/typings/store.js';
import { EMPTY_WORK_SHEET_HINTS_TAB_STATE } from '../../app/operators/typings/store.js';
import './DisplayWorkSheetHintsComponent.scss';

interface DisplayWorkSheetHintsComponentProps {
  readonly isOnline: boolean;
  readonly objectId: string;
  readonly $imageModal: StoreStateSelector<Store, ImageModalState>;
  readonly workSheetFileName: string;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly $: StoreStateSelector<Store, WorkSheetHintsTabState>;
}

export function DisplayWorkSheetHintsComponent({
  $,
  objectId,
  isOnline,
  $imageModal,
  workSheetFileName,
  computeAttachmentUrl,
}: DisplayWorkSheetHintsComponentProps): JSX.Element {
  const [t] = useTranslation('operators');

  const loadWorkSheet = useActionCallback(
    async ({ actionDispatch, httpClient }) => {
      if (workSheetFileName.endsWith(MARKDOWN_FILE_EXTENSION)) {
        try {
          const attachment = await httpClient.httpGet(
            CoreBackendRoutes.ATTACHMENT(
              'kanban',
              objectId,
              WORK_SHEET_ATTACHMENTS_FOLDER.id,
              workSheetFileName
            )
          );

          const attachmentText = await attachment.text();

          marked.use({ async: false });
          const workSheetHtmlContent = marked.parse(attachmentText) as string;

          actionDispatch.setValue({
            ...EMPTY_WORK_SHEET_HINTS_TAB_STATE,
            workSheetHtmlContent,
            loadingStatus: undefined,
          });
        } catch {
          actionDispatch.setValue({
            ...EMPTY_WORK_SHEET_HINTS_TAB_STATE,
            loadingStatus: undefined,
          });
        }
      } else {
        actionDispatch.setValue({
          ...EMPTY_WORK_SHEET_HINTS_TAB_STATE,
          workSheetAttachment: {
            folder: WORK_SHEET_ATTACHMENTS_FOLDER.id,
            name: workSheetFileName,
          } as Attachment,
          loadingStatus: undefined,
        });
      }
    },
    [objectId, workSheetFileName],
    $
  );

  const loadWorkSheetCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      if (isOnline) {
        actionDispatch.applyPayload({
          loadingStatus: t('expertiseView.workSheetHints.loadingWorkSheet'),
        });
        await actionDispatch.execCallback(loadWorkSheet);
      }
    },
    [t, isOnline, loadWorkSheet],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadWorkSheetCallback();
  }, [loadWorkSheetCallback]);

  const loadingStatus = useGetState($.$loadingStatus);

  const workSheetAttachment = useGetState($.$workSheetAttachment);
  const workSheetHtmlContent = useGetState($.$workSheetHtmlContent);

  if (!isOnline) {
    return <h1 className="ml-2 mt-2">{t('expertiseView.workSheetHints.offline')}</h1>;
  }

  if (isTruthy(loadingStatus)) {
    return <h1 className="ml-2 mt-2">{loadingStatus}</h1>;
  }

  if (!isTruthyAndNotEmpty(workSheetHtmlContent) && !isTruthy(workSheetAttachment)) {
    return <h1 className="ml-2 mt-2">{t('expertiseView.workSheetHints.noWorkSheetFound')}</h1>;
  }

  if (isTruthy(workSheetAttachment)) {
    return (
      <div className="mt-2 ml-5">
        <AttachmentThumbnail
          size={128}
          category="kanban"
          objectId={objectId}
          attachmentIndex={0}
          $imageModal={$imageModal}
          caption={workSheetAttachment.name}
          attachments={[workSheetAttachment]}
          computeAttachmentUrl={computeAttachmentUrl}
        />
      </div>
    );
  }

  return (
    <div
      className="m-2 content work-sheet-container"
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{ __html: workSheetHtmlContent }}
    />
  );
}
