import type { Location } from 'history';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { forEachRecordValues, mapRecordValues } from '@stimcar/libs-base';
import { useActionCallback } from '@stimcar/libs-uikernel';
import type { Store } from '../state/typings/store.js';
import { DropDownMenu } from '../../lib/components/DropDownMenu.js';
import {
  DISPLAY_DASHBOARD_FULL_PATH,
  DISPLAY_FULL_PATH,
  DISPLAY_KANBANS_BOARD_FULL_PATH,
  DISPLAY_SHIFT_PROGRESS_FULL_PATH,
  DISPLAY_STAND_ACHIEVEMENTS_FULL_PATH,
  DISPLAY_WORKSHOP_DASHBOARD_V2_FULL_PATH,
} from '../coreConstants.js';
import type { DisplayType, DisplayViewState } from './typings/store.js';

export interface DisplayMenuProps extends AppProps<Store> {
  readonly computeUserIsOnGivenUrlClassName: (location: Location, pathname: string) => string;
}

const FULL_PATHS: Record<DisplayType, string> = {
  KanbanBoard: DISPLAY_DASHBOARD_FULL_PATH,
  KanbanBoardNG: DISPLAY_KANBANS_BOARD_FULL_PATH,
  StandAchievements: DISPLAY_STAND_ACHIEVEMENTS_FULL_PATH,
  Workshop: DISPLAY_WORKSHOP_DASHBOARD_V2_FULL_PATH,
  ShiftProgress: DISPLAY_SHIFT_PROGRESS_FULL_PATH,
};

// eslint-disable-next-line @typescript-eslint/require-await
async function persistPathAndHideMenuAction(
  { actionDispatch, keyValueStorage }: ActionContext<Store, DisplayViewState>,
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
): Promise<void> {
  keyValueStorage.setItem(LocalStorageKeys.LAST_DISPLAY_PATH, event.currentTarget.pathname);
  actionDispatch.setProperty('menuIsActive', false);
}

export function DisplayMenu({
  $gs,
  computeUserIsOnGivenUrlClassName,
}: DisplayMenuProps): JSX.Element {
  const [t] = useTranslation(['display']);
  const $ = $gs.$displayView;
  const location = useLocation();
  let type: DisplayType | undefined;
  forEachRecordValues(FULL_PATHS, (path, displayType) => {
    if (location.pathname === path) {
      type = displayType;
    }
  });
  const hideMenuActionCallback = useActionCallback(persistPathAndHideMenuAction, [], $);
  return (
    <DropDownMenu
      $active={$.$menuIsActive}
      additionalClassNames={computeUserIsOnGivenUrlClassName(location, DISPLAY_FULL_PATH)}
      title={t(`displays.${!type ? 'unset' : type}.menu`)}
    >
      {mapRecordValues(FULL_PATHS, (path, displayType): JSX.Element => {
        if (path === location.pathname) {
          return <React.Fragment key={displayType} />;
        }
        return (
          <NavLink
            key={displayType}
            className="navbar-item"
            onClick={hideMenuActionCallback}
            to={path}
          >
            {t(`displays.${displayType}.menu`)}
          </NavLink>
        );
      })}
    </DropDownMenu>
  );
}
