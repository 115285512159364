/* eslint-disable jsx-a11y/control-has-associated-label */
import type { TFunction } from 'i18next';
import type { MutableRefObject } from 'react';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Attachment,
  CarElement,
  PackageDealVariableBaseType,
  Sequence,
  SparePart,
  SparePartManagementType,
  StorageCategories,
} from '@stimcar/libs-base';
import type {
  ActionCallback,
  ActionContext,
  NoArgAction,
  NoArgActionCallback,
  StoreStateSelector,
  WithFormValidationWarnings,
} from '@stimcar/libs-uikernel';
import type {
  AppProps,
  AttachmentsState,
  CheckFormFieldContentActions,
  HorizontalFormFieldProps,
} from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  CoreBackendRoutes,
  forEachRecordValues,
  isBooleanVariable,
  isNumericVariable,
  isTextualVariable,
  mapRecordValues,
  nonDeleted,
  packageDealDescHelpers,
  packageDealHelpers,
  purchaseOrderHelpers,
  sortingHelpers,
  sortRecord,
  TAG_GLOBAL_VARIABLE_NAME_PREFIX,
  toUpperFirst,
  URL_LIST_ELEMENTS_SEPARATOR,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useArrayItemSelector,
  useFormFieldWarning,
  useGetState,
  useRecordItemSelector,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  AttachmentsPanel,
  Button,
  ClickableIcon,
  CustomContentFormField,
  DisplayMultilineString,
  FaIcon,
  Input,
  ModalCardDialog,
  RadioButtonsFormField,
  ScrollableContainer,
  SelectFormField,
  Switch,
  TextArea,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { SparePartFormData } from '../../../admin/packageDealDesc/typings/store.js';
import type { Store } from '../../../state/typings/store.js';
import type {
  AddOrUpdatePackageDealFormData,
  AddOrUpdatePackageDealModalState,
  OperatorExpertViewState,
} from '../../typings/store.js';
import {
  convertSparePartFormDataToSparePart,
  createSparePartFormDataFromSparePartDesc,
} from '../../../admin/packageDealDesc/adminPackageDealDescUtils.js';
import { EMPTY_SPARE_PART_FORM_DATA } from '../../../admin/packageDealDesc/typings/store.js';
import {
  EXPERTISE_ATTACHMENT_CATEGORY,
  importAttachmentsAction,
  loadAttachmentsGalleryAction,
  useGetExpertiseAttachmentFolder,
} from '../../../utils/attachmentGalleryActions.js';
import {
  attachDocumentOkClickedAction,
  removeAttachmentOkClickedAction,
} from '../../../utils/attachmentPanelActions.js';
import { useComputeAttachmentUrl } from '../../../utils/useComputeAttachmentUrl.js';
import { useGetCarElementLabel } from '../../../utils/useGetCarElementLabel.js';
import {
  addOrUpdatePackageDealAction,
  FORM_HORIZONTAL_BODY_FLEX_GROW,
  NOT_ALLOCATED_PACKAGE_DEAL_PURCHASE_ORDER_ID,
} from '../../expertiseUtils.js';
import {
  ADD_OR_UPDATE_PACKAGE_DEAL_MODAL_EMPTY_STATE,
  EMPTY_CURRENTLY_MODIFIED_PACKAGE_DEAL_FORM_DATA,
} from '../../typings/store.js';

export async function openDialogForAddition(
  {
    actionDispatch,
    getState,
    httpClient,
    packageDealDescRepository,
  }: ActionContext<Store, OperatorExpertViewState>,
  packageDealDescId: string,
  sparePartManagementType: SparePartManagementType,
  carElement?: CarElement
): Promise<void> {
  const { initialKanban, packageDealListComponentState, detailsAndMessagesComponentState } =
    getState();
  const selectedPDD = await packageDealDescRepository.getEntity(packageDealDescId);

  if (selectedPDD) {
    const rawPackageDeal = packageDealHelpers.createPackageDealFromPackageDealDesc(
      httpClient.getBrowserSequence(),
      selectedPDD,
      carElement,
      sparePartManagementType
    );
    const localGlobalVariables = packageDealHelpers.computeGlobalVariableValuesForGivenTags(
      {
        ...initialKanban,
        packageDeals: packageDealListComponentState.packageDeals,
      },
      selectedPDD.tags,
      true
    );
    forEachRecordValues(localGlobalVariables, (val, key) => {
      if (key.startsWith(TAG_GLOBAL_VARIABLE_NAME_PREFIX)) {
        if (typeof val === 'number') {
          localGlobalVariables[key] = val + 1;
        } else {
          throw new Error('A tag value must always be a number');
        }
      }
    });
    let packageDeal = packageDealHelpers.recomputePackageDealExpressionResults(
      rawPackageDeal,
      localGlobalVariables,
      initialKanban.contract.configuration.roundPriceTo
    );

    const variableValues: Record<string, number | boolean | string> = {};

    forEachRecordValues(packageDeal.variables, (variable, key) => {
      variableValues[key] = variable.value;
    });

    // Initialize the spare parts form data that will be displayed in the form
    const sparePartsFormData = selectedPDD.sparePartDescs.map((spd) =>
      createSparePartFormDataFromSparePartDesc(spd, httpClient.getBrowserSequence())
    );

    // Then we replace the spare parts that have been created in the package deal
    // with spare parts created from the spare parts form data
    // by doing so we can ensure ids are the same on both sides
    const spareParts = sparePartsFormData.map((spfd) =>
      convertSparePartFormDataToSparePart(spfd, sparePartManagementType)
    );
    packageDeal = {
      ...packageDeal,
      spareParts,
    };

    const selectedPackageDeal = packageDealListComponentState.packageDeals.find(
      ({ id }) => id === detailsAndMessagesComponentState.selectedPackageDealId
    );

    const formData = {
      ...EMPTY_CURRENTLY_MODIFIED_PACKAGE_DEAL_FORM_DATA,
      spareParts: sparePartsFormData,
      price: String(packageDeal.price),
      variableValues,
      warnings: {},
      selectedPurchaseOrderId:
        selectedPackageDeal?.purchaseOrderId ?? NOT_ALLOCATED_PACKAGE_DEAL_PURCHASE_ORDER_ID,
    } as WithFormValidationWarnings<AddOrUpdatePackageDealFormData>;

    actionDispatch.reduce((initial: OperatorExpertViewState): OperatorExpertViewState => {
      return {
        ...initial,
        selectedElementType: 'packageDealDesc',
        addOrUpdatePackageDealModalState: {
          ...ADD_OR_UPDATE_PACKAGE_DEAL_MODAL_EMPTY_STATE,
          attachments: [],
          active: true,
          packageDeal,
          openFor: 'creation',
          overridablePrice: packageDeal.overridablePrice,
          computedDuration: packageDeal.duration,
          computedPrice: packageDeal.price,
          formData,
        },
      };
    });
  }
}

function convertSparePartToSparePartFormData(sp: SparePart): SparePartFormData {
  return {
    id: sp.id,
    label: sp.label,
    editMode: false,
    deleted: sp.deleted ?? false,
  };
}

export async function openDialogForModification(
  {
    actionDispatch,
    getState,
    packageDealDescRepository,
  }: ActionContext<Store, OperatorExpertViewState>,
  packageDealId: string
): Promise<void> {
  const { packageDealListComponentState, initialKanban } = getState();
  const selectedPD = packageDealListComponentState.packageDeals
    .filter(nonDeleted)
    .find((pdd) => pdd.id === packageDealId);

  if (selectedPD) {
    const variableValues: Record<string, number | boolean | string> = {};

    const { roundPriceTo } = initialKanban.contract.configuration;

    forEachRecordValues(selectedPD.variables, (variable, key) => {
      variableValues[key] = variable.value;
    });

    const originPackageDealDesc = (await packageDealDescRepository.hasEntity(
      selectedPD.packageDealDescId
    ))
      ? await packageDealDescRepository.getEntity(selectedPD.packageDealDescId)
      : undefined;

    const localGlobalVariables = packageDealHelpers.computeGlobalVariableValuesForGivenTags(
      {
        ...initialKanban,
        packageDeals: packageDealListComponentState.packageDeals,
      },
      selectedPD.tags,
      true
    );
    const packageDeal = packageDealHelpers.recomputePackageDealExpressionResults(
      selectedPD,
      localGlobalVariables,
      roundPriceTo
    );

    // The recomputePackageDealExpressionResults might not have computed the price expression if the package deal has
    // priceIsOverridden = true (in this case we want to save the price given by user). So the result of the expression is computed
    // here to be sure to have the correct value in state
    const allLocalVariables: Record<string, boolean | number | string> = {
      ...localGlobalVariables,
    };
    forEachRecordValues(packageDeal.variables, (variable, key) => {
      allLocalVariables[key] = variable.value;
    });
    const computedPrice = packageDealHelpers.computePackageDealExpressionResult(
      packageDeal.priceExpression,
      allLocalVariables,
      roundPriceTo
    );

    const noCost =
      !packageDeal.overridablePrice && packageDeal.price === 0 && packageDeal.priceIsOverridden;

    const spareParts = packageDeal.spareParts.map((sp) => convertSparePartToSparePartFormData(sp));

    const computedDuration = packageDealHelpers.computePackageDealExpressionResult(
      packageDeal.durationExpression,
      variableValues,
      0.01
    );

    const forceWorkloadToZero = packageDealHelpers.isPackageDealPriceManuallySetToZero(packageDeal);

    const formData = {
      ...EMPTY_CURRENTLY_MODIFIED_PACKAGE_DEAL_FORM_DATA,
      spareParts,
      price: String(packageDeal.price),
      variableValues,
      estimateComment: packageDeal.estimateComment,
      packageDealComment: packageDeal.comment,
      noCost,
      warnings: {},
      selectedPurchaseOrderId:
        packageDeal.purchaseOrderId ?? NOT_ALLOCATED_PACKAGE_DEAL_PURCHASE_ORDER_ID,
    } as WithFormValidationWarnings<AddOrUpdatePackageDealFormData>;

    actionDispatch.reduce((initial: OperatorExpertViewState): OperatorExpertViewState => {
      return {
        ...initial,
        selectedElementType: 'packageDeal',
        addOrUpdatePackageDealModalState: {
          ...ADD_OR_UPDATE_PACKAGE_DEAL_MODAL_EMPTY_STATE,
          active: true,
          pddNotFound: !isTruthy(originPackageDealDesc),
          overridablePrice: packageDeal.overridablePrice,
          attachments: packageDeal.attachments ?? [],
          computedPrice,
          computedDuration,
          forceWorkloadToZero,
          openFor: 'edition',
          packageDeal,
          formData,
        },
      };
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sequenceProvider = ({ httpClient }: ActionContext<Store, any>): Sequence =>
  httpClient.getBrowserSequence();

const checkFieldContentActions: CheckFormFieldContentActions<
  Store,
  AddOrUpdatePackageDealModalState
> = {
  price: ({ value, t }): string | undefined => {
    if (!isTruthyAndNotEmpty(value)) {
      return undefined;
    }
    const priceValue = value.replace(',', '.');
    // The signature of isNaN is: isNaN(number).
    // I don't get it, I want to check if my string is a number,
    // if I already know it is a number, I doesn't have to call this method ....
    //
    // In addition we have to use isNaN global function instead of Number.isNaN
    // because Number.isNaN('12aze') returns false
    // and isNaN('12aze') returns true which is the correct result for us
    // The MDN doc says that Number.isNaN is more robust, because for instance
    // Number.isNaN("blabla"); returns false. I can't understand how this behavior is more robust
    // than isNaN("blabla") that return true .....
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
    //
    // @ts-ignore
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(priceValue)) {
      return t('priceUpdateModal.form.warnings.incorrectConversion');
    }
    return undefined;
  },
};

function getMandatoryFields(
  isPriceOverridable: boolean,
  hasMultiplePurchaseOrders: boolean
): (keyof AddOrUpdatePackageDealFormData)[] {
  const fields: (keyof AddOrUpdatePackageDealFormData)[] = [];
  if (isPriceOverridable) {
    fields.push('price');
  }
  if (hasMultiplePurchaseOrders) {
    fields.push('selectedPurchaseOrderId');
  }
  return fields;
}

const ADDITIONAL_CUSTOM_INPUT_SPACING_CLASS = 'm-t-sm';

interface EditPackageDealModalComponentProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, OperatorExpertViewState>;
  readonly kanbanId: string;
  readonly isReadOnly?: boolean;
  readonly updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>;
}

export function EditPackageDealModalComponent({
  $,
  $gs,
  kanbanId,
  isReadOnly = false,
  updateStandRelatedPackageDealsAndOperationsActionCallback,
}: EditPackageDealModalComponentProps): JSX.Element {
  const active = useGetState($.$addOrUpdatePackageDealModalState.$active);
  return active ? (
    <InternalEditPackageDealModalComponent
      $={$}
      $gs={$gs}
      kanbanId={kanbanId}
      isReadOnly={isReadOnly}
      updateStandRelatedPackageDealsAndOperationsActionCallback={
        updateStandRelatedPackageDealsAndOperationsActionCallback
      }
    />
  ) : (
    <></>
  );
}

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  bodyFlexGrow: FORM_HORIZONTAL_BODY_FLEX_GROW,
};

function InternalEditPackageDealModalComponent({
  $,
  $gs,
  kanbanId,
  isReadOnly = false,
  updateStandRelatedPackageDealsAndOperationsActionCallback,
}: EditPackageDealModalComponentProps): JSX.Element {
  const [t] = useTranslation('operators');

  const { $addOrUpdatePackageDealModalState, $initialKanban } = $;

  const purchaseOrders = useGetState($initialKanban.$purchaseOrders);
  const activePurchaseOrders = useMemo(() => purchaseOrders.filter(nonDeleted), [purchaseOrders]);
  const hasMultiplePurchaseOrders = useMemo(
    () => purchaseOrderHelpers.hasMultiplePurchaseOrders(activePurchaseOrders),
    [activePurchaseOrders]
  );
  const purchaseOrdersEntries = useMemo(
    () => [
      ...activePurchaseOrders.map((purchaseOrder) => ({
        id: purchaseOrder.id,
        label: purchaseOrderHelpers.getPurchaseOrderDisplayedLabel(purchaseOrder),
      })),
      {
        id: NOT_ALLOCATED_PACKAGE_DEAL_PURCHASE_ORDER_ID,
        label: t('expertiseView.packageDealDetails.notAllocatedPackageDeal'),
      },
    ],
    [activePurchaseOrders, t]
  );

  const expertiseAttachmentFolder = useGetExpertiseAttachmentFolder();

  const saveStateInLocalStorageCallback = useActionCallback(
    ({ keyValueStorage, getState }) => {
      keyValueStorage.setObjectItem(LocalStorageKeys.DESKTOP_EXPERTISE_STATE_DUMP, getState());
    },
    [],
    $
  );

  const clearStateInLocalStorageCallback = useActionCallback(
    ({ keyValueStorage }) => {
      keyValueStorage.removeItem(LocalStorageKeys.DESKTOP_EXPERTISE_STATE_DUMP);
    },
    [],
    $
  );

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        addOrUpdatePackageDealAction,
        updateStandRelatedPackageDealsAndOperationsActionCallback
      );
      await actionDispatch.execCallback(clearStateInLocalStorageCallback);
    },
    [clearStateInLocalStorageCallback, updateStandRelatedPackageDealsAndOperationsActionCallback],
    $
  );

  const overridablePrice = useGetState($addOrUpdatePackageDealModalState.$overridablePrice);
  const mandatoryFields = useMemo(() => {
    return getMandatoryFields(overridablePrice, hasMultiplePurchaseOrders);
  }, [overridablePrice, hasMultiplePurchaseOrders]);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    AddOrUpdatePackageDealModalState
  >({
    $: $addOrUpdatePackageDealModalState,
    mandatoryFields,
    checkFieldContentActions,
    submitValidDataAction,
    t,
  });

  const onCancelClickedCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      actionDispatch
        .scopeProperty('addOrUpdatePackageDealModalState')
        .setValue(ADD_OR_UPDATE_PACKAGE_DEAL_MODAL_EMPTY_STATE);
      await actionDispatch.execCallback(clearStateInLocalStorageCallback);
    },
    [clearStateInLocalStorageCallback],
    $
  );

  const onAttachDocumentActionCallback = useActionCallback(
    async ({
      actionDispatch,
      getState,
    }: ActionContext<Store, AddOrUpdatePackageDealModalState>): Promise<void> => {
      await actionDispatch.exec(attachDocumentOkClickedAction, sequenceProvider);
      // Update state
      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          packageDeal: {
            ...nonnull(initial.packageDeal),
            attachments: getState().attachments,
          },
        };
      });
    },
    [],
    $addOrUpdatePackageDealModalState
  );

  const onDetachDocumentActionCallback = useActionCallback(
    async ({
      actionDispatch,
      getState,
    }: ActionContext<Store, AddOrUpdatePackageDealModalState>): Promise<void> => {
      await actionDispatch.exec(removeAttachmentOkClickedAction);

      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          packageDeal: {
            ...nonnull(initial.packageDeal),
            attachments: getState().attachments,
          },
        };
      });
      actionDispatch.scopeProperty('confirmRemoval').setProperty('active', false);
    },
    [],
    $addOrUpdatePackageDealModalState
  );

  const onImportDocumentAction = async (
    context: ActionContext<Store, AttachmentsState>,
    files: readonly File[]
  ): Promise<void> => {
    const { actionDispatch, globalActionDispatch, getState } = context;
    const detailDispatch = globalActionDispatch
      .scopeProperty('operatorView')
      .scopeProperty('expertOperatorState')
      .scopeProperty('addOrUpdatePackageDealModalState');
    const actualAttachments = getState().attachments;
    await globalActionDispatch.exec(
      importAttachmentsAction,
      EXPERTISE_ATTACHMENT_CATEGORY,
      kanbanId,
      expertiseAttachmentFolder.id,
      files,
      // eslint-disable-next-line @typescript-eslint/require-await
      async (filenamesMap: Record<string, string>): Promise<void> => {
        actionDispatch.setProperty('attachments', [
          ...(!actualAttachments ? [] : actualAttachments),
          ...files.map((file): Attachment => {
            return {
              id: sequenceProvider(context).next(),
              folder: expertiseAttachmentFolder.id,
              name: filenamesMap[file.name],
            };
          }),
        ]);
        // Update state
        detailDispatch.reduce((initial) => {
          return {
            ...initial,
            packageDeal: {
              ...nonnull(initial.packageDeal),
              attachments: getState().attachments,
            },
          };
        });
      }
    );
  };

  const onVariableChangeCallback = useActionCallback(
    ({ actionDispatch, getState }: ActionContext<Store, OperatorExpertViewState>): void => {
      const { addOrUpdatePackageDealModalState, initialKanban, packageDealListComponentState } =
        getState();
      const { formData, packageDeal, computedPrice, openFor } = addOrUpdatePackageDealModalState;
      const { variableValues, price } = formData;

      const localVariables: Record<string, number | boolean | string> =
        packageDealHelpers.computeGlobalVariableValuesForGivenTags(
          {
            ...initialKanban,
            packageDeals: packageDealListComponentState.packageDeals,
          },
          packageDeal.tags,
          true
        );
      forEachRecordValues(localVariables, (val, key) => {
        if (openFor === 'creation' && key.startsWith(TAG_GLOBAL_VARIABLE_NAME_PREFIX)) {
          // Tag variables are always numeric
          localVariables[key] = (val as number) + 1;
        }
      });
      forEachRecordValues(variableValues, (value, key) => {
        localVariables[key] = value;
      });

      const newPrice = packageDealHelpers.computePackageDealExpressionResult(
        packageDeal.priceExpression,
        localVariables,
        initialKanban.contract.configuration.roundPriceTo
      );
      const newDuration = packageDealHelpers.computePackageDealExpressionResult(
        packageDeal.durationExpression,
        localVariables,
        0.01
      );

      const modalActionDispatch = actionDispatch.scopeProperty('addOrUpdatePackageDealModalState');
      if (String(computedPrice) === price) {
        modalActionDispatch.scopeProperty('formData').setProperty('price', String(newPrice));
      }

      modalActionDispatch.setProperty('computedPrice', newPrice);
      modalActionDispatch.setProperty('computedDuration', newDuration);
    },
    [],
    $
  );

  const onNoCostChangeCallback = useActionCallback(
    ({
      actionDispatch,
      getState,
    }: ActionContext<Store, AddOrUpdatePackageDealModalState>): void => {
      const { computedPrice, formData } = getState();
      const { noCost } = formData;
      const newPrice = noCost ? '0' : String(computedPrice);
      actionDispatch.scopeProperty('formData').setProperty('price', newPrice);
      actionDispatch.setProperty('forceWorkloadToZero', noCost);
    },
    [],
    $addOrUpdatePackageDealModalState
  );

  const $noCostWithTrigger = useSelectorWithChangeTrigger(
    $formDataWithChangeTrigger.$noCost,
    onNoCostChangeCallback
  );
  const noCost = useGetState($formDataWithChangeTrigger.$noCost);
  const price = useGetState($formDataWithChangeTrigger.$price);
  const priceWarning = useFormFieldWarning($formDataWithChangeTrigger.$price);
  const formWarning = useGetState($addOrUpdatePackageDealModalState.$formWarning);

  const reinitializePriceCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      actionDispatch.scopeProperty('formData').applyPayload({
        price: String(getState().computedPrice),
      });
    },
    [],
    $addOrUpdatePackageDealModalState
  );

  const variableValues = useGetState($formDataWithChangeTrigger.$variableValues);
  const packageVariables = useGetState($addOrUpdatePackageDealModalState.$packageDeal.$variables);
  const formVariableValues = useMemo(() => {
    return sortRecord(
      packageVariables,
      sortingHelpers.createSortPackageDealVariablesByIndexThenName
    );
  }, [packageVariables]);

  const priceExpression = useGetState(
    $addOrUpdatePackageDealModalState.$packageDeal.$priceExpression
  );
  const durationExpression = useGetState(
    $addOrUpdatePackageDealModalState.$packageDeal.$durationExpression
  );

  const isInteractiveDurationExpression = useMemo(
    () => packageDealDescHelpers.isInteractiveExpression(durationExpression),
    [durationExpression]
  );

  const isInteractivePriceExpression = useMemo(
    () => packageDealDescHelpers.isInteractiveExpression(priceExpression),
    [priceExpression]
  );

  const carElement = useGetState($addOrUpdatePackageDealModalState.$packageDeal.$carElement);
  const carElementLabel = useGetCarElementLabel(carElement);

  const valuePlaceholder = t('expertiseView.packageDealDetails.nothingToDisplay');

  const packageDealLabel = useGetState($addOrUpdatePackageDealModalState.$packageDeal.$label);
  const pddNotFound = useGetState($addOrUpdatePackageDealModalState.$pddNotFound);
  const computedPrice = useGetState($addOrUpdatePackageDealModalState.$computedPrice);
  const computedDuration = useGetState($addOrUpdatePackageDealModalState.$computedDuration);
  const forceWorkloadToZero = useGetState($addOrUpdatePackageDealModalState.$forceWorkloadToZero);

  const loadAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ): Promise<void> => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $addOrUpdatePackageDealModalState.$galleryModal
  );

  const computeAttachmentUrlCallback = useComputeAttachmentUrl($gs);
  const isOnline = useGetState($gs.$session.$isOnline);

  const openFor = useGetState($.$addOrUpdatePackageDealModalState.$openFor);
  const modalOkLabel = useMemo(
    () => (openFor === 'creation' ? t('expertiseView.addButton') : t('expertiseView.editButton')),
    [t, openFor]
  );

  const onPriceChangeCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      const { formData } = getState();
      const formattedPrice = formData.price.replace(',', '.');
      const isPriceNull = Number(formattedPrice) === 0;
      actionDispatch.setProperty('forceWorkloadToZero', isPriceNull);
    },
    [],
    $addOrUpdatePackageDealModalState
  );

  const $priceWithChangeTrigger = useSelectorWithChangeTrigger(
    $formDataWithChangeTrigger.$price,
    onPriceChangeCallback
  );

  return (
    <ModalCardDialog
      title={t('expertiseView.editPackageDealModalTitle', {
        packageDealName: packageDealHelpers.computePackageDealDisplayedLabelFromVariableValues(
          packageDealLabel,
          variableValues
        ),
      })}
      $active={$addOrUpdatePackageDealModalState.$active}
      okLabel={modalOkLabel}
      onOkClicked={onFormSubmit}
      warning={formWarning}
      onCancelClicked={onCancelClickedCallback}
    >
      {pddNotFound && (
        <p className="has-text-centered">
          <FaIcon id="exclamation-triangle" additionalClass="has-text-warning" />
          {t('expertiseView.notFoundPDD')}
        </p>
      )}
      <ScrollableContainer>
        <CustomContentFormField
          addExtraTopMargin
          horizontal={HORIZONTAL_PROPS}
          label={`${t('expertiseView.packageDealDetails.element')}:`}
        >
          <DisplayMultilineString value={carElementLabel} />
        </CustomContentFormField>
        <>
          {hasMultiplePurchaseOrders && (
            <RadioButtonsFormField
              id="purchaseOrders"
              radioGroupLayout="vertical"
              entries={purchaseOrdersEntries}
              $={$formDataWithChangeTrigger.$selectedPurchaseOrderId}
              horizontal={{
                bodyFlexGrow: FORM_HORIZONTAL_BODY_FLEX_GROW,
              }}
              label={`${t('expertiseView.packageDealDetails.purchaseOrder')}:`}
            />
          )}
        </>
        <div className="p-b-sm m-t-sm">
          <CustomContentFormField
            label={`${t('expertiseView.packageDealDetails.priceLabel')}:`}
            horizontal={HORIZONTAL_PROPS}
            warning={priceWarning}
          >
            <div
              className="columns"
              title={
                isInteractivePriceExpression && !noCost && String(computedPrice) === price
                  ? t('expertiseView.packageDealDetails.estimationTooltip')
                  : ''
              }
            >
              {(isReadOnly || !overridablePrice) && (
                <div className={`column is-one-third ${ADDITIONAL_CUSTOM_INPUT_SPACING_CLASS}`}>
                  {isInteractivePriceExpression && !noCost
                    ? t('expertiseView.packageDealDetails.priceEstimation', {
                        price: computedPrice,
                      })
                    : t('expertiseView.packageDealDetails.price', {
                        price: noCost ? 0 : computedPrice,
                      })}
                </div>
              )}
              {overridablePrice && !isReadOnly && (
                <>
                  <div className="column is-one-third">
                    <Input
                      $={$priceWithChangeTrigger}
                      disabled={isReadOnly}
                      defaultValue={String(price)}
                    />
                  </div>
                  <div className="column is-narrow">
                    <Button
                      onClick={reinitializePriceCallback}
                      label={t('expertiseView.resetButton')}
                      additionalClass="is-primary"
                    />
                  </div>
                </>
              )}
              {!isReadOnly && !overridablePrice && (
                <div className={`column ${ADDITIONAL_CUSTOM_INPUT_SPACING_CLASS}`}>
                  <Switch
                    text={t('expertiseView.packageDealDetails.noCost')}
                    $={$noCostWithTrigger}
                    switchId="noCostId"
                    style={{ verticalAlign: 'bottom' }}
                  />
                </div>
              )}
            </div>
          </CustomContentFormField>
          <CustomContentFormField
            label={`${t('expertiseView.packageDealDetails.durationLabel')}:`}
            horizontal={HORIZONTAL_PROPS}
          >
            {isInteractiveDurationExpression ? (
              <div title={t('expertiseView.packageDealDetails.estimationTooltip')}>
                {t('expertiseView.packageDealDetails.durationEstimation', {
                  duration: forceWorkloadToZero ? 0 : computedDuration,
                })}
              </div>
            ) : (
              t('expertiseView.packageDealDetails.duration', {
                duration: forceWorkloadToZero ? 0 : computedDuration,
              })
            )}
          </CustomContentFormField>
          {mapRecordValues(formVariableValues, (variable, variableName): JSX.Element => {
            return (
              <VariableSelectForm
                key={variableName}
                variable={nonnull(variable)}
                variableName={variableName}
                onFormChange={onVariableChangeCallback}
                $={$formDataWithChangeTrigger}
                isReadonly={isReadOnly}
              />
            );
          })}
          <CustomContentFormField
            label={`${t('expertiseView.packageDealDetails.indication')}:`}
            addExtraTopMargin
            horizontal={HORIZONTAL_PROPS}
          >
            <DisplayMultilineString
              value={useGetState($addOrUpdatePackageDealModalState.$packageDeal.$hint)}
              placeholder={valuePlaceholder}
            />
          </CustomContentFormField>
          <CustomContentFormField
            label={`${t('expertiseView.packageDealDetails.attachments')}:`}
            addExtraTopMargin
            horizontal={HORIZONTAL_PROPS}
          >
            <AttachmentsPanel
              category={EXPERTISE_ATTACHMENT_CATEGORY}
              objectId={kanbanId}
              folder={expertiseAttachmentFolder}
              $={$addOrUpdatePackageDealModalState}
              $window={$gs.$window}
              toolsAreEnabled={isOnline && !isReadOnly}
              computeAttachmentUrl={computeAttachmentUrlCallback}
              $imageModal={$gs.$imageModal}
              loadAttachmentsActionCallback={loadAttachmentsActionCallback}
              importDocumentAction={onImportDocumentAction}
              attachDocumentActionCallback={onAttachDocumentActionCallback}
              detachDocumentActionCallback={onDetachDocumentActionCallback}
              onUploadButtonClickedCallback={saveStateInLocalStorageCallback}
            />
          </CustomContentFormField>
          <CustomContentFormField
            horizontal={HORIZONTAL_PROPS}
            label={`${t('expertiseView.packageDealDetails.spareParts')}:`}
          >
            <SparePartsListForm $={$formDataWithChangeTrigger} t={t} />
          </CustomContentFormField>
          <CustomContentFormField
            horizontal={HORIZONTAL_PROPS}
            label={`${t('expertiseView.packageDealDetails.workshopComment')}:`}
          >
            <TextArea $={$formDataWithChangeTrigger.$packageDealComment} disabled={isReadOnly} />
          </CustomContentFormField>
          <CustomContentFormField
            horizontal={HORIZONTAL_PROPS}
            label={`${t('expertiseView.packageDealDetails.estimateComment')}:`}
          >
            <TextArea $={$formDataWithChangeTrigger.$estimateComment} disabled={isReadOnly} />
          </CustomContentFormField>
        </div>
      </ScrollableContainer>
    </ModalCardDialog>
  );
}

function switchPartEditModeAction(
  { actionDispatch, getState }: ActionContext<Store, AddOrUpdatePackageDealFormData>,
  currentPartId: string
): void {
  const currentPart = getState().spareParts.find(({ id }) => id === currentPartId)!;
  actionDispatch.applyPayload({
    spareParts: [
      {
        id: currentPartId,
        editMode: !currentPart.editMode,
      },
    ],
  });
}

function deletePartAction(
  { actionDispatch }: ActionContext<Store, AddOrUpdatePackageDealFormData>,
  partId: string
): void {
  actionDispatch.applyPayload({
    spareParts: [
      {
        id: partId,
        deleted: true,
      },
    ],
  });
}

function getInputInsideTR(trAsRef: MutableRefObject<Element | null>): HTMLInputElement | null {
  if (trAsRef.current !== null) {
    const inputTR = trAsRef.current as unknown as Element;
    const inputChildren = inputTR.getElementsByTagName('input');
    return inputChildren.length > 0 ? inputChildren[0] : null;
  }
  return null;
}

interface SparePartsListFormProps {
  readonly $: StoreStateSelector<Store, AddOrUpdatePackageDealFormData>;
  readonly t: TFunction;
}

function SparePartsListForm({ $, t }: SparePartsListFormProps): JSX.Element {
  const spareParts = useGetState($.$spareParts);
  const activeSpareParts = useMemo(() => spareParts.filter(nonDeleted), [spareParts]);

  const newSparePartLabel = useGetState($.$newSparePartLabel);

  const newSparePartActionCallback = useActionCallback(
    ({ actionDispatch, getState, httpClient }) => {
      const { newSparePartLabel } = getState();

      if (isTruthyAndNotEmpty(newSparePartLabel)) {
        const newPart = {
          ...EMPTY_SPARE_PART_FORM_DATA,
          id: httpClient.getBrowserSequence().next(),
          label: newSparePartLabel,
        };
        actionDispatch.applyPayload({
          spareParts: [newPart],
          newSparePartLabel: '',
        });
      }
    },
    [],
    $
  );

  const newSparePartFieldTR = useRef(null);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 13) {
        e.stopPropagation();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        newSparePartActionCallback();
      }
    },
    [newSparePartActionCallback]
  );

  const addSparePartDisabled = !isTruthyAndNotEmpty(newSparePartLabel);

  return (
    <div
      className="table-container mimicInput"
      style={{ overflow: 'auto', cursor: 'pointer', maxHeight: '140px', minHeight: '140px' }}
    >
      <table className="table is-fullwidth is-narrow">
        <tbody>
          {activeSpareParts.map((part): JSX.Element => {
            return (
              <SparePartForm
                key={part.id}
                currentPartId={part.id}
                newPartInputTRRef={newSparePartFieldTR}
                $={$}
              />
            );
          })}
          <tr style={{ padding: '0px' }} ref={newSparePartFieldTR}>
            <td colSpan={2} style={{ width: '100%', padding: '5px 5px 0px 0px' }}>
              <Input
                $={$.$newSparePartLabel}
                placeholder={t('expertiseView.packageDealDetails.newSparePart')}
                type="text"
                style={{ padding: '0px', height: 'fit-content', fontSize: '0.85em' }}
                onKeyDown={onKeyDown}
              />
            </td>
            <td style={{ padding: '5px 5px 0px 5px' }}>
              <ClickableIcon
                id="plus-circle"
                size="standard"
                clickHandler={newSparePartActionCallback}
                isDisabled={addSparePartDisabled}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

interface SparePartFormProps {
  readonly readonly?: boolean;
  readonly currentPartId: string;
  readonly newPartInputTRRef: MutableRefObject<Element | null>;
  readonly $: StoreStateSelector<Store, AddOrUpdatePackageDealFormData>;
}

function SparePartForm({
  readonly = false,
  currentPartId,
  newPartInputTRRef,
  $,
}: SparePartFormProps): JSX.Element {
  const variableValues = useGetState($.$variableValues);

  const $sparePartForm = useArrayItemSelector($.$spareParts, currentPartId);

  const label = useGetState($sparePartForm.$label);
  const editMode = useGetState($sparePartForm.$editMode);

  const computedLabel = useMemo(() => {
    return packageDealHelpers.computePackageDealDisplayedLabelFromVariableValues(
      label,
      variableValues
    );
  }, [label, variableValues]);

  const switchEditModeCallback = useActionCallback(
    async function _switchEditModeAction({ actionDispatch }) {
      await actionDispatch.exec(switchPartEditModeAction, currentPartId);
    },
    [currentPartId],
    $
  );

  const deletePartActionCallback = useActionCallback(
    async function _deletePartAction({ actionDispatch }) {
      await actionDispatch.exec(deletePartAction, currentPartId);
    },
    [currentPartId],
    $
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 13) {
        e.stopPropagation();
        // Enter or NumpadEnter
        if (newPartInputTRRef != null) {
          const newPartInput = getInputInsideTR(newPartInputTRRef);
          if (newPartInput !== null) {
            // We set the focus on the input field that creates new spare part
            // Side effect is that the current input field loses focus which triggers an immediate state change
            newPartInput.focus();
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        switchEditModeCallback();
      }
    },
    [newPartInputTRRef, switchEditModeCallback]
  );

  return (
    <tr style={{ padding: '0px' }}>
      <td style={{ width: '100%', padding: '0px 5px 0px 0px' }}>
        {!editMode ? (
          <span>{computedLabel}</span>
        ) : (
          <Input
            $={$sparePartForm.$label}
            placeholder={label}
            type="text"
            style={{ padding: '0px', height: 'fit-content', fontSize: '0.85em' }}
            onKeyDown={onKeyDown}
          />
        )}
      </td>
      <td style={{ padding: '0px 5px 0px 5px' }}>
        {!readonly && (
          <ClickableIcon
            id={editMode ? 'eye' : 'edit'}
            size="standard"
            clickHandler={switchEditModeCallback}
          />
        )}
      </td>
      <td style={{ padding: '0px 5px 0px 5px' }}>
        <ClickableIcon id="trash" size="standard" clickHandler={deletePartActionCallback} />
      </td>
    </tr>
  );
}

interface VariableSelectFormProps<T extends PackageDealVariableBaseType> {
  readonly variableName: string;
  readonly variable: T;
  readonly $: StoreStateSelector<Store, AddOrUpdatePackageDealFormData>;
  readonly onFormChange: ActionCallback<
    Store,
    NoArgAction<Store, AddOrUpdatePackageDealModalState>
  >;
  readonly isReadonly: boolean;
}

function VariableSelectForm<T extends PackageDealVariableBaseType>({
  variableName,
  variable,
  $,
  onFormChange,
  isReadonly,
}: VariableSelectFormProps<T>): JSX.Element {
  const [t] = useTranslation('operators');

  const { $variableValues } = $;

  const $variableValue = useRecordItemSelector($variableValues, variableName);
  const $variableWithChangeTrigger = useSelectorWithChangeTrigger($variableValue, onFormChange);

  let formLabel = isTruthyAndNotEmpty(variable.label) ? variable.label : toUpperFirst(variableName);
  if (isNumericVariable(variable)) {
    formLabel += `${isTruthyAndNotEmpty(variable.unit) ? ` (${variable.unit})` : ''}:`;
  }

  const availableValues = useMemo(() => {
    if (isBooleanVariable(variable)) {
      return [
        {
          id: false,
          label: t('expertiseView.packageDealDetails.variablesValues.false'),
        },
        {
          id: true,
          label: t('expertiseView.packageDealDetails.variablesValues.true'),
        },
      ];
    }
    if (isNumericVariable(variable) || isTextualVariable(variable)) {
      return variable.availableValues;
    }
    throw Error(`Variable ${formLabel}, unknown type ${Reflect.get(variable, 'type')}`);
  }, [formLabel, t, variable]);

  return (
    <SelectFormField
      label={formLabel}
      disabled={isReadonly}
      horizontal={{
        bodyFlexGrow: FORM_HORIZONTAL_BODY_FLEX_GROW,
      }}
      $={$variableWithChangeTrigger}
      entries={availableValues}
    />
  );
}
