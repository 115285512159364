import type {
  CarElement,
  PackageDeal,
  PackageDealDesc,
  Sequence,
  SiteConfiguration,
  SparePartManagementType,
} from '@stimcar/libs-base';
import { packageDealCreation, packageDealHelpers, workflowHelpers } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';

export function createPackageDeal(
  packageDealDesc: PackageDealDesc,
  sequence: Sequence,
  siteConfiguration: SiteConfiguration,
  workflowId: string,
  carElementsById: Map<string, CarElement>,
  sparePartManagementType: SparePartManagementType,
  roundPriceTo: number,
  purchaseOrderId?: string
): PackageDeal {
  const workflow = siteConfiguration.workflows.find((c) => c.id === workflowId);
  const validationStands =
    workflowHelpers.getAllStandsOfTypeByWorkflowId(siteConfiguration, 'expertiseValidation')[
      workflowId
    ] ?? [];
  const linearizedWorkflow = workflowHelpers.linearize(nonnull(workflow).definition);
  const lastValidationStandPosition = linearizedWorkflow.reduce(
    (acc, s, i): number => (validationStands.includes(s) ? i : acc),
    -1
  );

  const carElementId = packageDealDesc.carElementIds[0];
  const carElement = carElementsById.get(carElementId);

  const packageDealStatus = packageDealCreation.computePackageDealStatus(
    packageDealDesc,
    linearizedWorkflow,
    lastValidationStandPosition
  );

  return packageDealHelpers.createPackageDealFromPackageDealDesc(
    sequence,
    packageDealDesc,
    carElement,
    sparePartManagementType,
    {
      localVariableValues: null,
      globalVariableValues: null,
      packageDealStatus,
      roundPriceTo,
      purchaseOrderId,
    }
  );
}
