import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useArrayItemSelector, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, TextAreaFormField } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { SparePartsReferenceState } from '../../typings/store.js';

export function openCommentModalSparePartAction(
  { actionDispatch }: ActionContext<Store, SparePartsReferenceState['commentModal']>,
  id: string
) {
  actionDispatch.applyPayload({
    active: true,
    sparePartId: id,
  });
}

interface SparePartCommentModalProps {
  readonly $: StoreStateSelector<Store, SparePartsReferenceState>;
}

export function SparePartCommentModal({ $ }: SparePartCommentModalProps): JSX.Element {
  const [t] = useTranslation('operators');

  const { $commentModal } = $;
  const sparePartId = useGetState($commentModal.$sparePartId);

  const $sparePart = useArrayItemSelector($.$sparePartsUnderModification, sparePartId);

  return (
    <ModalCardDialog
      $active={$commentModal.$active}
      title={t('sparePartsReference.commentsModal.title')}
    >
      <TextAreaFormField
        label={t('sparePartsReference.commentsModal.internal')}
        $={$sparePart.$comment}
        rows={3}
      />
      <TextAreaFormField
        label={t('sparePartsReference.commentsModal.workshop')}
        $={$sparePart.$commentForWorkshop}
        rows={3}
      />
      <TextAreaFormField
        label={t('sparePartsReference.commentsModal.customer')}
        $={$sparePart.$commentForCustomer}
        rows={3}
      />
    </ModalCardDialog>
  );
}
