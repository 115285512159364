import { useCallback } from 'react';
import type { StorageCategories } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { useGetState } from '@stimcar/libs-uikernel';
import type {
  ComputeAttachmentUrlCallback,
  ThumbnailOptions,
} from '../../lib/components/attachments/typings/attachment.js';
import type { Store } from '../state/typings/store.js';
import { appendRegisteredBrowserSessionToken } from './security.js';

export function useComputeAttachmentUrl(
  $gs: GlobalStoreStateSelector<Store>
): ComputeAttachmentUrlCallback {
  return useComputeAttachmentUrlFromSessionToken($gs.$session.$infos.asDefined().$sessionToken);
}

export function useComputeAttachmentUrlFromSessionToken(
  $sessionToken: StoreStateSelector<Store, string>
): ComputeAttachmentUrlCallback {
  const sessionToken = useGetState($sessionToken);
  return useCallback(
    (
      category: StorageCategories,
      folder: string,
      name: string,
      objectId: string,
      thumbnailOptions?: ThumbnailOptions
    ): string => {
      const baseUrl = thumbnailOptions
        ? CoreBackendRoutes.ATTACHMENT_THUMBNAIL(
            category,
            objectId,
            folder,
            thumbnailOptions.mode,
            thumbnailOptions.size,
            name
          )
        : CoreBackendRoutes.ATTACHMENT(category, objectId, folder, name);
      return appendRegisteredBrowserSessionToken(baseUrl, sessionToken);
    },
    [sessionToken]
  );
}

export function useComputeStandardPictureUrl(
  $gs: GlobalStoreStateSelector<Store>
): ComputeAttachmentUrlCallback {
  return useComputeStandardPictureUrlFromSessionToken(
    $gs.$session.$infos.asDefined().$sessionToken
  );
}

export function useComputeStandardPictureUrlFromSessionToken(
  $sessionToken: StoreStateSelector<Store, string>
): ComputeAttachmentUrlCallback {
  const sessionToken = useGetState($sessionToken);
  return useCallback(
    (
      category: StorageCategories,
      folder: string,
      name: string,
      kanbanId: string,
      thumbnailOptions?: ThumbnailOptions,
      isImprovedImage?: boolean
    ): string => {
      const baseUrl = thumbnailOptions
        ? CoreBackendRoutes.ATTACHMENT_STANDARD_PICTURE_THUMBNAIL(
            kanbanId,
            folder,
            thumbnailOptions.mode,
            thumbnailOptions.size,
            name
          )
        : CoreBackendRoutes.ATTACHMENT_STANDARD_PICTURE(kanbanId, folder, name, isImprovedImage);
      return appendRegisteredBrowserSessionToken(baseUrl, sessionToken);
    },
    [sessionToken]
  );
}
