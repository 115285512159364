import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { kanbanHelpers, transverseHelpers } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import {
  CheckboxFormField,
  InputFormField,
  ModalCardDialog,
  useFormWithValidation,
  VirtualKeyboardInputFormField,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type {
  SendKanbanToAnomalyModalState,
  SendKanbanToAnomalyValidationData,
} from '../typings/store.js';
import { useIsWorkshopLogin } from '../../../../app/utils/useIsWorkshopLogin.js';
import { SEND_KANBAN_TO_ANOMALY_MODAL_STATE } from '../typings/store.js';
import { computeAnomalyPostQualifiedCategoryId } from './workshopImplantationUtils.js';

async function sendKanbanToAnomalyValidationAction({
  getState,
  httpClient,
  actionDispatch,
  kanbanRepository,
}: ActionContext<Store, SendKanbanToAnomalyModalState>): Promise<void> {
  const { kanban, qualifiedPostId, standId, user, formData } = getState();
  const { reason, sendToAnomalyPost } = formData;
  const { implantationId } =
    transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(qualifiedPostId);

  if (!isTruthyAndNotEmpty(standId) || !isTruthyAndNotEmpty(qualifiedPostId)) {
    throw Error(`The stand (${standId}) and origin post (${qualifiedPostId}) must be provided`);
  }

  if (isTruthy(kanban)) {
    if (sendToAnomalyPost) {
      const anomalyPostId = computeAnomalyPostQualifiedCategoryId(nonnull(implantationId));

      const theKanban = kanbanHelpers.closeAllHandlingStartingWith(kanban, nonnull(implantationId));
      const kanbanInAnomaly = kanbanHelpers.openNewHandlingWithInterval(
        httpClient.getBrowserSequence(),
        theKanban,
        standId,
        anomalyPostId,
        [user],
        'anomaly',
        reason
      );
      await kanbanRepository.updateEntity(kanbanInAnomaly);
    } else {
      const kanbanInAnomaly = kanbanHelpers.openAnomalyInterval(
        httpClient.getBrowserSequence(),
        kanban,
        [user],
        reason,
        qualifiedPostId,
        standId
      );
      await kanbanRepository.updateEntity(kanbanInAnomaly);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial) => {
    return SEND_KANBAN_TO_ANOMALY_MODAL_STATE;
  });
}
const mandatoryFields: (keyof SendKanbanToAnomalyValidationData)[] = ['reason'];
interface Props extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, SendKanbanToAnomalyModalState>;
}

export function SendKanbanToAnomalyModal({ $, $gs }: Props): JSX.Element {
  const [t] = useTranslation('workshop');
  const isWorkshopLogin = useIsWorkshopLogin($gs);

  const closeModalCallback = useActionCallback(
    ({ actionDispatch }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => {
        return SEND_KANBAN_TO_ANOMALY_MODAL_STATE;
      });
    },
    [],
    $
  );

  const submitValidDataAction = useActionCallback(sendKanbanToAnomalyValidationAction, [], $);
  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    SendKanbanToAnomalyModalState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      title={t('implantation.sendKanbanToAnomalyModal.title')}
      $active={$.$active}
      onOkClicked={onFormSubmit}
      onCancelClicked={closeModalCallback}
      okLabel={t('implantation.sendKanbanToAnomalyModal.okLabel')}
    >
      <CheckboxFormField
        label={t('implantation.sendKanbanToAnomalyModal.sendToAnomalyPost')}
        $={$formDataWithChangeTrigger.$sendToAnomalyPost}
        horizontal
        style={{ verticalAlign: 'bottom' }}
      />
      {isWorkshopLogin ? (
        <VirtualKeyboardInputFormField
          $={$formDataWithChangeTrigger.$reason}
          horizontal
          label={t('implantation.sendKanbanToAnomalyModal.reasonInput')}
        />
      ) : (
        <InputFormField
          label={t('implantation.sendKanbanToAnomalyModal.reasonInput')}
          $={$formDataWithChangeTrigger.$reason}
          horizontal
        />
      )}
    </ModalCardDialog>
  );
}
