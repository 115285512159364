export const MKTP_MANDATE_UNKNOWN = '';
export const MKTP_MANDATE_SALE = 'SALE';
export const MKTP_MANDATE_BUY = 'BUY';
export const MKTP_MANDATES = [MKTP_MANDATE_UNKNOWN, MKTP_MANDATE_BUY, MKTP_MANDATE_SALE];
export type MarketplaceMandate = (typeof MKTP_MANDATES)[number];

export const MKTP_OFFLINE_STATUS = 'offline';
export const MKTP_ONLINE_STATUS = 'online';
export const MKTP_SOLD_STATUS = 'sold';
export const MKTP_STATUSES = [MKTP_OFFLINE_STATUS, MKTP_ONLINE_STATUS, MKTP_SOLD_STATUS];
export type MarketplaceStatus = (typeof MKTP_STATUSES)[number];

export const MKTP_PROCESS_UNKNOWN = '';
export const MKTP_PROCESS_SALE = 'SALE';
export const MKTP_PROCESS_BUY = 'BUY';
export const MKTP_PROCESSES = [MKTP_PROCESS_UNKNOWN, MKTP_PROCESS_BUY, MKTP_PROCESS_SALE];
export type MarketplaceProcess = (typeof MKTP_PROCESSES)[number];

export const MKTP_GEARBOX_UNKNOWN = '';
export const MKTP_GEARBOX_AUTO = 'auto';
export const MKTP_GEARBOX_MANUAL = 'manual';
export const MKTP_GEARBOXES = [MKTP_GEARBOX_UNKNOWN, MKTP_GEARBOX_AUTO, MKTP_GEARBOX_MANUAL];
export type GearboxValue = (typeof MKTP_GEARBOXES)[number];

export const MKTP_ENERGY_UNKNOWN = '';
export const MKTP_ENERGY_DIESEL = 'diesel';
export const MKTP_ENERGY_GAS = 'gas';
export const MKTP_ENERGY_HYBRID = 'hybrid';
export const MKTP_ENERGY_ELECTRIC = 'electric';
export const MKTP_ENERGIES = [
  MKTP_ENERGY_UNKNOWN,
  MKTP_ENERGY_DIESEL,
  MKTP_ENERGY_GAS,
  MKTP_ENERGY_HYBRID,
  MKTP_ENERGY_ELECTRIC,
];
export type EnergyValue = (typeof MKTP_ENERGIES)[number];

export const MKTP_ECO_STICKER_UNKNOWN = '';
export const MKTP_ECO_STICKER_ELEC_OR_HYDRO = 'elec-or-hydro';
export const MKTP_ECO_STICKER_LVL_1 = 'level-1';
export const MKTP_ECO_STICKER_LVL_2 = 'level-2';
export const MKTP_ECO_STICKER_LVL_3 = 'level-3';
export const MKTP_ECO_STICKER_LVL_4 = 'level-4';
export const MKTP_ECO_STICKER_LVL_5 = 'level-5';
export const MKTP_ECO_STICKERS = [
  MKTP_ECO_STICKER_UNKNOWN,
  MKTP_ECO_STICKER_ELEC_OR_HYDRO,
  MKTP_ECO_STICKER_LVL_1,
  MKTP_ECO_STICKER_LVL_2,
  MKTP_ECO_STICKER_LVL_3,
  MKTP_ECO_STICKER_LVL_4,
  MKTP_ECO_STICKER_LVL_5,
];
export type EcoStickerValue = (typeof MKTP_ECO_STICKERS)[number];

export type MainSpecs = {
  readonly energy?: EnergyValue;
  readonly gearbox?: GearboxValue;
  readonly horsepower?: number;
  readonly taxHorsepower?: number;
  readonly doors?: number;
  readonly seats?: number;
  readonly ecoSticker?: string;
};

export const MKTP_EQUIPMENT_CATEGORY_SECURITY = 'security';
export const MKTP_EQUIPMENT_CATEGORY_COMFORT = 'comfort';
export const MKTP_EQUIPMENT_CATEGORY_MULTIMEDIA = 'multimedia';
export const MKTP_EQUIPMENT_CATEGORY_OTHERS = 'others';
export const MKTP_EQUIPMENT_CATEGORIES = [
  MKTP_EQUIPMENT_CATEGORY_SECURITY,
  MKTP_EQUIPMENT_CATEGORY_COMFORT,
  MKTP_EQUIPMENT_CATEGORY_MULTIMEDIA,
  MKTP_EQUIPMENT_CATEGORY_OTHERS,
] as const;
export type EquipmentCategory = (typeof MKTP_EQUIPMENT_CATEGORIES)[number];

export type MarketplaceInfos = {
  readonly mandate: MarketplaceMandate;
  // Input price can be either:
  // - public price for SALE mandate
  // - sale price for BUY mandate
  readonly inputPrice: number;
  readonly negotiation: number;
  readonly canceledPackageDealIds: readonly string[];
  readonly deposit: number;
  readonly textAttachment: string;
  readonly mainSpecs: MainSpecs;
  readonly equipments: Record<EquipmentCategory, readonly string[]>;
};
