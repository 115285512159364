/* eslint-disable class-methods-use-this */
import type { PollerErrorHandler } from '@stimcar/libs-base';
import type { KeyValueStorage } from '@stimcar/libs-kernel';
import type { DatabaseFactory } from '../../database/typings/database.js';
import type { RepositoryHTTPClient } from '../../httpclient/typings/RepositoryHTTPClient.js';
import type { DatabaseIndex } from '../../repository/dao/typings/repository-dao.js';
import type { RepositoryExtender } from '../../repository/typings/repository.js';
import { newExtendedRepository } from '../../repository/RepositoryImpl.js';
import { AbstractRepositoryExtender } from '../AbstractRepositoryExtender.js';
import type { CustomerExtender, CustomerRepository } from './typings/customersExtender.js';

class CustomerExtenderImpl
  extends AbstractRepositoryExtender
  implements RepositoryExtender<'customer', CustomerExtender>
{
  public getDataBaseIndexesToCreateOrDelete(oldVersion: number): DatabaseIndex[] {
    const indexes: DatabaseIndex[] = [];
    /* eslint-disable no-fallthrough */
    switch (oldVersion) {
      default:
      /* eslint-enable no-fallthrough */
    }
    return indexes;
  }
}

/**
 * Creates a kanban extender instance.
 * @param httpClient the HTTP Client.
 */
export async function newCustomerRepository(
  keyValueStorage: KeyValueStorage,
  httpClient: RepositoryHTTPClient,
  pollerErrorHandler: PollerErrorHandler,
  dbFactory: DatabaseFactory
): Promise<CustomerRepository> {
  return newExtendedRepository<'customer', CustomerExtender>(
    'customer',
    keyValueStorage,
    httpClient,
    pollerErrorHandler,
    dbFactory,
    new CustomerExtenderImpl(0)
  );
}
