import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PurchaseOrder } from '@stimcar/libs-base';
import type {
  ActionContext,
  AnyStoreDef,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { nonDeleted, packageDealHelpers, purchaseOrderHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { ButtonProps } from '../../../bulma/elements/Button.js';
import type { PrintableDocumentViewProps } from '../DocumentGenerationCommonComponents.js';
import { Button } from '../../../bulma/elements/Button.js';
import { ChoiceAddOnButtons } from '../../../bulma/form/custom/ChoiceAddOnButtons.js';
import { PrintableDocumentView } from '../DocumentGenerationCommonComponents.js';
import type { EstimateProps } from './EstimateComponent.js';
import type { EstimateViewState } from './typings/store.js';
import { EstimateComponent } from './EstimateComponent.js';

type Props<SD extends AnyStoreDef> = Omit<
  EstimateProps<SD>,
  | '$expandedSectionIds'
  | 'availableAttachments'
  | 'packageDealsDisplayMode'
  | 'packageDealsSortMode'
  | '$changePackageDealLinkedPurchaseOrderModalState'
  | '$selectedPurchaseOrderTab'
> &
  Omit<PrintableDocumentViewProps, 'children'> & {
    readonly $: StoreStateSelector<SD, EstimateViewState>;
    readonly loadAvailableAttachmentsEffect: NoArgActionCallback<SD>;
    readonly downloadAttachmentsZipCallback?: NoArgActionCallback<SD>;
  };

export function useInitializeSelectedPurchaseOrderTabActionCallback<SD extends AnyStoreDef>(
  $estimateView: StoreStateSelector<SD, EstimateViewState>
) {
  return useActionCallback(
    async ({ actionDispatch }, purchaseOrders: readonly PurchaseOrder[]) => {
      await actionDispatch.exec(initializeSelectedPurchaseOrderTabAction, purchaseOrders);
    },
    [],
    $estimateView
  );
}

export function initializeSelectedPurchaseOrderTabAction<SD extends AnyStoreDef>(
  { actionDispatch }: ActionContext<SD, EstimateViewState>,
  purchaseOrders: readonly PurchaseOrder[]
): void {
  const nonDeletedPurchaseOrders = purchaseOrders.filter(nonDeleted);
  if (purchaseOrderHelpers.hasMultiplePurchaseOrders(nonDeletedPurchaseOrders)) {
    const sortedPurchaseOrders = [...nonDeletedPurchaseOrders].sort((a, b) =>
      a.purchaseNumber.localeCompare(b.purchaseNumber)
    );
    actionDispatch.setProperty('selectedPurchaseOrderTab', sortedPurchaseOrders[0].purchaseNumber);
  }
}

export function EstimateView<SD extends AnyStoreDef>({
  returnAction,
  kanban,
  attributeDescs,
  estimateMention,
  memoDescs,
  address,
  companyName,
  logoUrl,
  $,
  computeAttachmentUrl,
  $imageModal,
  loadAvailableAttachmentsEffect,
  downloadAttachmentsZipCallback,
  bottomPlaceholderSize,
  toolbarContributions,
  hidePrintAction,
  dontShowLocalEstimateId,
  showPrices = true,
  expertiseValidationToolkit,
}: Props<SD>): JSX.Element {
  const [t] = useTranslation('custom');

  const asyncEffect = loadAvailableAttachmentsEffect;

  const asyncUnmountEffect = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.setProperty('availableAttachments', undefined);
    },
    [],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
    return asyncUnmountEffect as () => void;
  }, [asyncEffect, asyncUnmountEffect]);

  const modeSelectionControls = (
    <ChoiceAddOnButtons
      buttonsDesc={[
        {
          id: 'all',
          label: t('documentGeneration.estimate.actions.allMode'),
          selectedClass: 'is-primary',
        },
        {
          id: 'retained',
          label: t('documentGeneration.estimate.actions.retainedMode'),
          selectedClass: 'is-primary',
        },
      ]}
      $={$.$packageDealsDisplayMode}
    />
  );

  const sortedPackageDealsControls = (
    <ChoiceAddOnButtons
      buttonsDesc={[
        {
          id: 'byCategory',
          label: t('documentGeneration.estimate.actions.sortByCategory'),
          selectedClass: 'is-primary',
        },
        {
          id: 'byCarElementIndex',
          label: t('documentGeneration.estimate.actions.sortByCarElementIndex'),
          selectedClass: 'is-primary',
        },
      ]}
      $={$.$packageDealsSortMode}
    />
  );

  const hasPackageDealWithAttachments = useMemo(
    () =>
      kanban.packageDeals
        .filter(packageDealHelpers.buildPackageDealFilter(undefined))
        .filter(({ attachments }) => attachments && attachments.length > 0).length > 0,
    [kanban.packageDeals]
  );
  const availableAttachments = useGetState($.$availableAttachments);

  const mergedToolbarContributions = downloadAttachmentsZipCallback
    ? [
        modeSelectionControls,
        sortedPackageDealsControls,
        <Button
          iconId="download"
          label={t('documentGeneration.estimate.actions.downloadAttachments')}
          onClick={downloadAttachmentsZipCallback}
          disabled={
            !hasPackageDealWithAttachments &&
            (availableAttachments === undefined || availableAttachments.length === 0)
          }
        />,
      ]
    : [modeSelectionControls];

  if (isTruthy(toolbarContributions)) {
    mergedToolbarContributions.push(...toolbarContributions);
  }

  return (
    <PrintableDocumentView
      printDocumentTitle={t(
        `documentGeneration.estimate.${showPrices ? 'estimate' : 'plannedWork'}DocumentTitle`,
        {
          contract: kanban.contract.code,
          license: kanban.infos.license,
        }
      )}
      kanban={kanban}
      returnAction={returnAction}
      companyName={companyName}
      address={address}
      logoUrl={logoUrl}
      toolbarContributions={mergedToolbarContributions}
      bottomPlaceholderSize={bottomPlaceholderSize}
      hidePrintAction={hidePrintAction}
    >
      <EstimateComponent
        kanban={kanban}
        attributeDescs={attributeDescs}
        estimateMention={estimateMention}
        memoDescs={memoDescs}
        $selectedPurchaseOrderTab={$.$selectedPurchaseOrderTab}
        packageDealsDisplayMode={useGetState($.$packageDealsDisplayMode)}
        $expandedSectionIds={$.$expandedSectionIds}
        computeAttachmentUrl={computeAttachmentUrl}
        $imageModal={$imageModal}
        packageDealsSortMode={useGetState($.$packageDealsSortMode)}
        dontShowLocalEstimateId={dontShowLocalEstimateId}
        showPrices={showPrices}
        expertiseValidationToolkit={expertiseValidationToolkit}
        $changePackageDealLinkedPurchaseOrderModalState={
          $.$changePackageDealLinkedPurchaseOrderModalState
        }
      />
    </PrintableDocumentView>
  );
}

type EstimateButtonProps = Pick<
  ButtonProps,
  'isFullWidth' | 'onClick' | 'size' | 'additionalClass'
>;

export function ShowEstimateButton({ ...props }: EstimateButtonProps): JSX.Element {
  const [t] = useTranslation('custom');
  return (
    <Button
      label={t('documentGeneration.estimate.actions.show')}
      tooltip={t('documentGeneration.estimate.actions.showTooltip')}
      iconId="print"
      isFullWidth
      {...props}
    />
  );
}
