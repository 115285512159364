import type { TFunction } from 'i18next';
import { t } from 'i18next';
import type { Labelled } from '@stimcar/libs-kernel';
import {
  applyPayload,
  computePayload,
  isTruthy,
  isTruthyAndNotEmpty,
  keysOf,
  nonnull,
} from '@stimcar/libs-kernel';
import type {
  BasePackageDeal,
  CarElement,
  CarViewCategory,
  CoreFields,
  DisplayableSparePart,
  Kanban,
  MergedCarViewCategory,
  Operation,
  PackageDeal,
  PackageDealDesc,
  PackageDealSignature,
  PackageDealsSignature,
  PackageDealStatus,
  PackageDealStatusWithAchievability,
  PackageDealVariable,
  PddOrODExpressionType,
  PriceablePackageDeal,
  PriceableSparePart,
  SiteConfiguration,
  SparePart,
  SparePartManagementType,
  SubcontractorPackageDeal,
  SubcontractorUnassignedPackageDeal,
} from '../../model/index.js';
import type { Sequence } from '../sequence.js';
import {
  ADMIN_PACKAGE_DEAL_CODE,
  BODY_CATEGORY_CODE,
  EMPTY_OPERATION,
  EMPTY_PACKAGE_DEAL,
  EMPTY_SPAREPART_REFERENCE_OPERATION,
  isBooleanVariable,
  MKTP_PKG_CATEGORY_NA,
  OPERATION_ATTRIBUTES,
  PACKAGE_DEAL_CODES_THAT_CANNOT_BE_REMOVED,
  STIMCAR_FIRM,
  TAG_GLOBAL_VARIABLE_NAME_PREFIX,
} from '../../model/index.js';
import { filterReject, forEachRecordValues, nonDeleted } from '../misc.js';
import { toLowerFirst } from '../string-helper.js';
import type { SparePartsStatus } from './sparePartHelpers.js';
import type {
  BaseProgress,
  PackageDealWithBaseProgress,
  PackageDealWithRevenueProgress,
  RevenueProgress,
} from './typings/progress.js';
import { carElementHelpers } from './carElementHelpers.js';
import { globalHelpers } from './globalHelpers.js';
import {
  PACKAGE_DEAL_DESC_TEMPLATE_LABEL_SUFFIX_AND_PREFIX,
  packageDealDescHelpers,
} from './packageDealDescHelpers.js';
import { priceHelpers } from './priceHelpers.js';
import { compareStrings } from './sortingHelperUtils.js';
import { sparePartHelpers } from './sparePartHelpers.js';
import { EMPTY_BASE_PROGRESS, EMPTY_REVENUE_PROGRESS } from './typings/progress.js';
import { workflowHelpers } from './workflowHelpers.js';

export interface FlatOperationItem extends Operation {
  readonly packageDealId: string;
  readonly packageDealCode: string;
  readonly packageDealLabel: string;
  readonly packageDealComment: string;
  readonly carElementCategory: CarViewCategory;
  readonly status: PackageDealStatus | null;
  readonly carElementLabel: string | undefined;
  readonly carElementShapes: readonly string[];
}

export interface FlatOperationItemWithWeight extends FlatOperationItem {
  readonly operationWeightWithinPackageDeal: number;
  readonly operationWeightWithinStand: number;
  readonly operationGlobalWeight: number;
}

function doReplaceVariableValuePlaceholder(
  label: string,
  variable: string,
  value: number | string | boolean | undefined
): string {
  const { prefix, suffix, undefinedValue } = PACKAGE_DEAL_DESC_TEMPLATE_LABEL_SUFFIX_AND_PREFIX;
  return label.replace(
    `${prefix}${variable}${suffix}`,
    value === undefined ? undefinedValue : String(value)
  );
}

function computePackageDealDisplayedLabelFromVariableValues(
  pckLabel: string,
  variableValues: Record<string, number | string | boolean>
): string {
  let theLabel = pckLabel;
  forEachRecordValues(variableValues, (value, key) => {
    theLabel = doReplaceVariableValuePlaceholder(theLabel, key, value);
  });
  return theLabel;
}

function getPackageDealDisplayedLabel(pck: Pick<PackageDeal, 'label' | 'variables'>): string {
  let theLabel = pck.label;
  if (isTruthy(pck.variables)) {
    forEachRecordValues(pck.variables, (variable, key) => {
      theLabel = doReplaceVariableValuePlaceholder(theLabel, key, variable.value);
    });
  }
  return theLabel;
}

function getOperationDisplayedLabel(
  o: Operation,
  packageDealVariables: Record<string, PackageDealVariable>
): string {
  let theLabel = o.label;
  forEachRecordValues(packageDealVariables, (variable, key) => {
    theLabel = doReplaceVariableValuePlaceholder(theLabel, key, variable.value);
  });
  return theLabel;
}

function isPackageDealAvailable(
  packageDeal: BasePackageDeal,
  includesRecommended = false
): boolean {
  if (includesRecommended && packageDeal.status === null) {
    return packageDeal.recommendedByExpert;
  }
  return packageDeal.status === 'available';
}

function isPackageDealCanceled(
  packageDeal: BasePackageDeal,
  includeNotRecommended = false
): boolean {
  if (includeNotRecommended && packageDeal.status === null) {
    return !packageDeal.recommendedByExpert;
  }
  return packageDeal.status === 'canceled';
}

function isPackageDealAchievable(packageDeal: BasePackageDeal): boolean {
  return !isTruthyAndNotEmpty(packageDeal.unachievableReason);
}

function isPackageDealAvailableAndAchievable(
  packageDeal: BasePackageDeal,
  includesRecommended = false
): boolean {
  return (
    isPackageDealAvailable(packageDeal, includesRecommended) && isPackageDealAchievable(packageDeal)
  );
}

function packageDealComparator(a: BasePackageDeal, b: BasePackageDeal): number {
  if (a.status !== b.status) {
    return isPackageDealAvailable(a) ? -1 : 1;
  }
  return getPackageDealDisplayedLabel(a).localeCompare(getPackageDealDisplayedLabel(b));
}

function buildPackageDealFilter(
  availableFilter: PackageDealStatusWithAchievability | undefined,
  includesExpertRecoIfNoStatus = false
): (pkg: BasePackageDeal) => boolean {
  return (pd): boolean => {
    if (pd.deleted) {
      return false;
    }
    // By default, keep all package deals
    if (availableFilter === undefined) {
      return includesExpertRecoIfNoStatus || pd.status !== null;
    }
    if (availableFilter === 'canceled') {
      return isPackageDealCanceled(pd, includesExpertRecoIfNoStatus);
    }
    if (availableFilter === 'available') {
      return isPackageDealAvailable(pd, includesExpertRecoIfNoStatus);
    }
    return isPackageDealAvailableAndAchievable(pd, includesExpertRecoIfNoStatus);
  };
}

function getFlatOperationList(
  packageDeals: readonly BasePackageDeal[],
  availableFilter?: PackageDealStatusWithAchievability,
  includesExpertRecoIfNoStatus = false
): readonly FlatOperationItem[] {
  return packageDeals
    .filter(buildPackageDealFilter(availableFilter, includesExpertRecoIfNoStatus))
    .sort(packageDealComparator)
    .flatMap((pkg): readonly FlatOperationItem[] => {
      const {
        id: packageDealId,
        code: packageDealCode,
        operations,
        carElement,
        status,
        variables,
        comment: packageDealComment,
      } = pkg;
      const packageDealLabel = getPackageDealDisplayedLabel(pkg);
      const undeletedOperations = operations.filter(nonDeleted);
      return undeletedOperations.map((operation): FlatOperationItem => {
        const operationLabel = getOperationDisplayedLabel(operation, variables);
        return {
          ...operation,
          label: operationLabel,
          packageDealId,
          packageDealCode,
          packageDealLabel,
          packageDealComment,
          carElementCategory: carElementHelpers.getCarElementCategory(carElement),
          carElementLabel: carElement?.label,
          carElementShapes: carElement ? carElement.shapes : [],
          status,
        };
      });
    });
}

function computeOperationProgressPercentage({
  operationCount,
  operationDone,
  operationTotalWorkload,
  operationDoneWorkload,
}: BaseProgress): number {
  if (operationTotalWorkload === 0) {
    return operationCount > 0 ? operationDone / operationCount : 0;
  }
  return operationDoneWorkload / operationTotalWorkload;
}

function computePackageDealProgressPercentage(progress: BaseProgress): number {
  const {
    operationTotalWorkload,
    operationDoneWorkload,
    operationCount,
    operationDone,
    sparePartCount,
    sparePartReceived,
  } = progress;
  if (operationTotalWorkload > 0) {
    return operationDoneWorkload / operationTotalWorkload;
  }
  if (operationCount > 0 || sparePartCount > 0) {
    return (operationDone + sparePartReceived) / (operationCount + sparePartCount);
  }
  return 0;
}

function mergeProgresses(progresses: readonly BaseProgress[]): BaseProgress {
  return progresses.reduce<BaseProgress>((p, c) => {
    return {
      operationCount: p.operationCount + c.operationCount,
      operationDone: p.operationDone + c.operationDone,
      operationTotalWorkload: p.operationTotalWorkload + c.operationTotalWorkload,
      operationDoneWorkload: p.operationDoneWorkload + c.operationDoneWorkload,
      sparePartCount: p.sparePartCount + c.sparePartCount,
      sparePartReceived: p.sparePartReceived + c.sparePartReceived,
    };
  }, EMPTY_BASE_PROGRESS);
}

/** There are two cases to handle here:
 *  1. ignoreVAT is undefined ==> true
 *  2. ignoreVAT is true ==> false
 */
function isPackageDealWithVAT(packageDeal: BasePackageDeal): boolean {
  return !packageDeal?.ignoreVAT;
}

function hasPackageDealWithVAT(packageDeals: readonly PriceablePackageDeal[]): boolean {
  return packageDeals.some(isPackageDealWithVAT);
}

function getSparePartsPriceWithoutVAT(
  packageDeal: PriceablePackageDeal,
  sparePartsStatus: SparePartsStatus
): number {
  const spareParts = sparePartHelpers
    .getFullyManagedActiveSpareParts(packageDeal.spareParts)
    .filter((sp) => {
      switch (sparePartsStatus) {
        case 'all':
          return true;
        case 'received':
          return isTruthy(sp.dateOfReception);
        default:
          throw Error(`Unknown spare part status ${sparePartsStatus}`);
      }
    });
  return sparePartHelpers.sumPrice(spareParts);
}

function getPackageDealAndSparePartsPriceWithoutVAT(
  packageDeal: PriceablePackageDeal,
  sparePartsStatus: SparePartsStatus
): number {
  return packageDeal.price + getSparePartsPriceWithoutVAT(packageDeal, sparePartsStatus);
}

function getPackageDealAndSparePartsPriceWithVAT(
  packageDeal: PriceablePackageDeal,
  sparePartsStatus: SparePartsStatus
): number {
  const priceWithoutVAT = getPackageDealAndSparePartsPriceWithoutVAT(packageDeal, sparePartsStatus);
  if (isPackageDealWithVAT(packageDeal)) {
    return priceHelpers.getPriceWithVAT(priceWithoutVAT);
  }
  return priceWithoutVAT;
}

function getPackageDealsPriceWithoutVAT(packageDeals: readonly PriceablePackageDeal[]): number {
  return packageDeals.reduce((acc, pkgDeal) => acc + pkgDeal.price, 0);
}

function getPackageDealsAndSparePartsTotalPriceWithoutVAT(
  packageDeals: readonly PriceablePackageDeal[],
  sparePartsStatus: SparePartsStatus
): number {
  return packageDeals.reduce(
    (acc, pkgDeal) => acc + getPackageDealAndSparePartsPriceWithoutVAT(pkgDeal, sparePartsStatus),
    0
  );
}

function getPackageDealsAndSparePartsTotalVAT(
  packageDeals: readonly PriceablePackageDeal[],
  sparePartsStatus: SparePartsStatus
): number {
  const packageDealsWithVAT = packageDeals.filter(isPackageDealWithVAT);
  const packageDealsAndSparePartsTotalPriceWithoutVAT =
    getPackageDealsAndSparePartsTotalPriceWithoutVAT(packageDealsWithVAT, sparePartsStatus);
  return priceHelpers.getVAT(packageDealsAndSparePartsTotalPriceWithoutVAT);
}

function getPackageDealsAndSparePartsTotalPriceWithVAT(
  packageDeals: readonly PriceablePackageDeal[],
  sparePartsStatus: SparePartsStatus
): number {
  const priceWithoutVAT = getPackageDealsAndSparePartsTotalPriceWithoutVAT(
    packageDeals,
    sparePartsStatus
  );
  const VAT = getPackageDealsAndSparePartsTotalVAT(packageDeals, sparePartsStatus);
  return priceWithoutVAT + VAT;
}

function getInvoiceablePackageDealsAndSparePartsPriceWithoutVAT(
  packageDeals: readonly PriceablePackageDeal[]
): number {
  const validatedAndAchievablePackageDeals = packageDeals.filter(
    buildPackageDealFilter('achievable')
  );
  return getPackageDealsAndSparePartsTotalPriceWithoutVAT(
    validatedAndAchievablePackageDeals,
    'received'
  );
}

function appendOperationProgressToKanbanProgressByKey(
  progressByKey: Record<string, BaseProgress>,
  op: Operation,
  spreadKeyProvider: (operation: Operation) => string
): void {
  const spreadKeyValue = spreadKeyProvider(op);
  const { completionDate, workload } = op;
  const {
    operationCount,
    operationDoneWorkload,
    operationDone,
    operationTotalWorkload,
    sparePartReceived,
    sparePartCount,
  } = !progressByKey[spreadKeyValue] ? EMPTY_REVENUE_PROGRESS : progressByKey[spreadKeyValue];
  // eslint-disable-next-line no-param-reassign
  progressByKey[spreadKeyValue] = {
    operationCount: operationCount + 1,
    operationDone: operationDone + (completionDate ? 1 : 0),
    operationTotalWorkload: operationTotalWorkload + workload,
    operationDoneWorkload: operationDoneWorkload + (completionDate ? workload : 0),
    sparePartCount,
    sparePartReceived,
  };
}

function appendSparePartProgressToKanbanProgressByKey(
  progressByKey: Record<string, BaseProgress>,
  sp: PriceableSparePart,
  spreadKeyProvider: (operation: PriceableSparePart) => string
): void {
  const spreadKeyValue = spreadKeyProvider(sp);
  const progress = !progressByKey[spreadKeyValue]
    ? EMPTY_REVENUE_PROGRESS
    : progressByKey[spreadKeyValue];
  // eslint-disable-next-line no-param-reassign
  progressByKey[spreadKeyValue] = {
    operationCount: progress.operationCount,
    operationDone: progress.operationDone,
    operationTotalWorkload: progress.operationTotalWorkload,
    operationDoneWorkload: progress.operationDoneWorkload,
    sparePartCount: progress.sparePartCount + 1,
    sparePartReceived: progress.sparePartReceived + (isTruthy(sp.dateOfReception) ? 1 : 0),
  };
}

/**
 * This functions injects in the package deals :
 * - a stand based progress
 * - a firm based progress
 * - the package deal progress
 *
 * The package deal progress is supposed to be the sum of
 * the stand based progresses.
 */
function computePackageDealsWithRevenueProgress(
  packageDeals: readonly PriceablePackageDeal[]
): readonly PackageDealWithRevenueProgress[] {
  const { filtered: achievablePackageDeals, rejected: nonAchievablePackageDeals } = filterReject(
    packageDeals,
    buildPackageDealFilter('achievable', true)
  );

  const achievablePackageDealsWithBaseProgress = achievablePackageDeals.map(
    (pkg): PackageDealWithBaseProgress => {
      const progressByStand: Record<string, BaseProgress> = {};
      const progressByFirm: Record<string, BaseProgress> = {};
      pkg.operations
        .filter(({ deleted }) => !deleted)
        .forEach((ope) => {
          appendOperationProgressToKanbanProgressByKey(
            progressByStand,
            ope,
            ({ standId }) => standId
          );
          appendOperationProgressToKanbanProgressByKey(
            progressByFirm,
            ope,
            ({ subcontractor }) => subcontractor ?? STIMCAR_FIRM
          );
        });
      pkg.spareParts
        .filter((sp) => !sp.deleted)
        .forEach((sp) => {
          appendSparePartProgressToKanbanProgressByKey(
            progressByStand,
            sp,
            ({ standId }) => standId
          );
          // Progress by firm : affect all spare parts to STIMCAR
          appendSparePartProgressToKanbanProgressByKey(
            progressByFirm,
            sp,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (sp) => STIMCAR_FIRM
          );
        });

      return {
        ...pkg,
        progressByStand,
        progressByFirm,
      };
    }
  );
  if (achievablePackageDealsWithBaseProgress.length === 0) {
    return [];
  }

  /**
   * Computation of a "referenceRevenuePercentageForEmptyPackageDeals"
   *
   * This percentage will allow to compute a progress for package deals
   * that have no operation).
   */
  // If possible only consider tube progress as a reference for empty package deals
  let referenceBaseProgressForEmptyPackageDeals = mergeProgresses(
    achievablePackageDealsWithBaseProgress.reduce<readonly BaseProgress[]>(
      (p, c) => (c.progressByStand.TUBE ? [...p, c.progressByStand.TUBE] : p),
      []
    )
  );
  // If not possible, consider all stand progresses as a reference for empty package deals
  if (referenceBaseProgressForEmptyPackageDeals.operationCount === 0) {
    referenceBaseProgressForEmptyPackageDeals = mergeProgresses(
      achievablePackageDealsWithBaseProgress.reduce<readonly BaseProgress[]>(
        (p, c) => [
          ...p,
          ...keysOf(c.progressByStand).map((standId): BaseProgress => c.progressByStand[standId]),
        ],
        []
      )
    );
  }
  const referenceRevenuePercentageForEmptyPackageDeals = computeOperationProgressPercentage(
    referenceBaseProgressForEmptyPackageDeals
  );

  return [
    // Non achievable package deals : progress === 0
    ...nonAchievablePackageDeals.map(
      (pkg): PackageDealWithRevenueProgress => ({
        ...pkg,
        progressByStand: {},
        progressByFirm: {},
        progress: EMPTY_REVENUE_PROGRESS,
      })
    ),
    // Achievable package deals
    ...achievablePackageDealsWithBaseProgress.map(
      ({ progressByStand, progressByFirm, ...pkg }): PackageDealWithRevenueProgress => {
        const standIds = keysOf(progressByStand);
        // If this is a package deal with no operations and no spare parts, package deal is spread
        // to the TUBE stand and inherits the TUBE progress
        if (standIds.length === 0) {
          const emptyPackageDealProgress: RevenueProgress = {
            operationCount: 0,
            operationDone: 0,
            operationTotalWorkload: 0,
            operationDoneWorkload: 0,
            operationDoneRevenue: referenceRevenuePercentageForEmptyPackageDeals * pkg.price,
            operationTotalRevenue: pkg.price,
            sparePartReceivedRevenue: 0,
            sparePartTotalRevenue: 0,
            sparePartCount: 0,
            sparePartReceived: 0,
          };
          return {
            ...pkg,
            // Empty package deal are supposed to be spread in the TUBE
            progressByStand: {
              TUBE: emptyPackageDealProgress,
            },
            // Empty package deal are affected to stimcar
            progressByFirm: {
              [STIMCAR_FIRM]: emptyPackageDealProgress,
            },
            // Same progress in global package deal scope
            progress: emptyPackageDealProgress,
          };
        }
        // Otherwise...

        /**
         * Compute progress with revenue by stand
         */
        const progressWithRevenueByStand: Record<string, RevenueProgress> = {};
        const packageDealProgress = mergeProgresses(
          standIds.map((standId): BaseProgress => progressByStand[standId])
        );
        let pkgSparePartsReceivedRevenue = 0;
        let pkgSparePartsTotalRevenue = 0;
        standIds.forEach((standId) => {
          const standProgress = progressByStand[standId];
          const standRevenuePercentageWithinPackageDealRevenue = computeOperationProgressPercentage(
            {
              operationCount: packageDealProgress.operationCount, // Use package deal global value
              operationDone: standProgress.operationCount, // Use stand total as 'done' value
              operationTotalWorkload: packageDealProgress.operationTotalWorkload, // Use package deal global value
              operationDoneWorkload: standProgress.operationTotalWorkload, // Use stand total as 'done' value
              sparePartCount: packageDealProgress.sparePartCount,
              sparePartReceived: standProgress.sparePartCount,
            }
          );
          const operationTotalStandRevenueWithinPackageDeal =
            standRevenuePercentageWithinPackageDealRevenue * pkg.price;
          const standOperationDonePercentage = computeOperationProgressPercentage(standProgress);
          let pkgSparePartsReceivedRevenueByStand = 0;
          let pkgSparePartsTotalRevenueByStand = 0;
          pkg.spareParts
            .filter((sp) => !sp.deleted && sp.standId === standId)
            .forEach((sp) => {
              pkgSparePartsTotalRevenueByStand += sp.price;
              if (isTruthy(sp.dateOfReception)) {
                pkgSparePartsReceivedRevenueByStand += sp.price;
              }
            });
          progressWithRevenueByStand[standId] = {
            ...standProgress,
            operationDoneRevenue:
              standOperationDonePercentage * operationTotalStandRevenueWithinPackageDeal,
            operationTotalRevenue: operationTotalStandRevenueWithinPackageDeal,
            sparePartReceivedRevenue: pkgSparePartsReceivedRevenueByStand,
            sparePartTotalRevenue: pkgSparePartsTotalRevenueByStand,
          };
          pkgSparePartsReceivedRevenue += pkgSparePartsReceivedRevenueByStand;
          pkgSparePartsTotalRevenue += pkgSparePartsTotalRevenueByStand;
        });

        /**
         * Compute progress with revenue by firm
         */
        const progressWithRevenueByFirm: Record<string, RevenueProgress> = {};

        // Collect subcontractors
        const firms = new Set<string>();
        firms.add(STIMCAR_FIRM);
        achievablePackageDealsWithBaseProgress.forEach(({ operations }) => {
          operations.filter(nonDeleted).forEach(({ subcontractor }) => {
            firms.add(subcontractor ?? STIMCAR_FIRM);
          });
        });
        firms.forEach((firm) => {
          const firmProgress = progressByFirm[firm] ?? EMPTY_BASE_PROGRESS;
          const firmRevenuePercentageWithinPackageDealRevenue = computeOperationProgressPercentage({
            operationCount: packageDealProgress.operationCount, // Use package deal global value
            operationDone: firmProgress.operationCount, // Use firm total as 'done' value
            operationTotalWorkload: packageDealProgress.operationTotalWorkload, // Use package deal global value
            operationDoneWorkload: firmProgress.operationTotalWorkload, // Use firm total as 'done' value
            sparePartCount: packageDealProgress.sparePartCount,
            sparePartReceived: firmProgress.sparePartCount,
          });
          const firmOperationDonePercentage = computeOperationProgressPercentage(firmProgress);
          const operationTotalFirmRevenueWithinPackageDeal =
            firmRevenuePercentageWithinPackageDealRevenue * pkg.price;
          progressWithRevenueByFirm[firm] = {
            ...firmProgress,
            operationDoneRevenue:
              firmOperationDonePercentage * operationTotalFirmRevenueWithinPackageDeal,
            operationTotalRevenue: operationTotalFirmRevenueWithinPackageDeal,
            sparePartReceivedRevenue: 0, // No spare parts with subcontractors
            sparePartTotalRevenue: 0, // No spare parts with subcontractors
          };
        });

        let globalProgress: RevenueProgress = {
          ...packageDealProgress,
          operationDoneRevenue: computeOperationProgressPercentage(packageDealProgress) * pkg.price,
          operationTotalRevenue: pkg.price,
          sparePartReceivedRevenue: pkgSparePartsReceivedRevenue,
          sparePartTotalRevenue: pkgSparePartsTotalRevenue,
        };

        // If this is a package deal with no operations but with spare parts, add the package deal price to the TUBE
        if (globalProgress.operationCount === 0) {
          if (isTruthy(progressWithRevenueByStand.TUBE)) {
            progressWithRevenueByStand.TUBE = {
              ...progressWithRevenueByStand.TUBE,
              operationDoneRevenue:
                progressWithRevenueByStand.TUBE.operationDoneRevenue +
                referenceRevenuePercentageForEmptyPackageDeals * pkg.price,
              operationTotalRevenue:
                progressWithRevenueByStand.TUBE.operationTotalRevenue + pkg.price,
            };
            globalProgress = {
              ...globalProgress,
              operationDoneRevenue:
                globalProgress.operationDoneRevenue +
                referenceRevenuePercentageForEmptyPackageDeals * pkg.price,
              operationTotalRevenue: globalProgress.operationTotalRevenue + pkg.price,
            };
          } else {
            progressWithRevenueByStand.TUBE = {
              operationCount: 0,
              operationDone: 0,
              operationTotalWorkload: 0,
              operationDoneWorkload: 0,
              operationDoneRevenue: referenceRevenuePercentageForEmptyPackageDeals * pkg.price,
              operationTotalRevenue: pkg.price,
              sparePartReceivedRevenue: 0,
              sparePartTotalRevenue: 0,
              sparePartCount: 0,
              sparePartReceived: 0,
            };
            globalProgress = {
              ...globalProgress,
              operationDoneRevenue: referenceRevenuePercentageForEmptyPackageDeals * pkg.price,
              operationTotalRevenue: pkg.price,
            };
          }
          if (isTruthy(progressWithRevenueByFirm[STIMCAR_FIRM])) {
            progressWithRevenueByFirm[STIMCAR_FIRM] = {
              ...progressWithRevenueByFirm[STIMCAR_FIRM],
              operationDoneRevenue:
                progressWithRevenueByFirm[STIMCAR_FIRM].operationDoneRevenue +
                referenceRevenuePercentageForEmptyPackageDeals * pkg.price,
              operationTotalRevenue:
                progressWithRevenueByFirm[STIMCAR_FIRM].operationTotalRevenue + pkg.price,
            };
          } else {
            progressWithRevenueByFirm[STIMCAR_FIRM] = {
              operationCount: 0,
              operationDone: 0,
              operationTotalWorkload: 0,
              operationDoneWorkload: 0,
              operationDoneRevenue: referenceRevenuePercentageForEmptyPackageDeals * pkg.price,
              operationTotalRevenue: pkg.price,
              sparePartReceivedRevenue: 0,
              sparePartTotalRevenue: 0,
              sparePartCount: 0,
              sparePartReceived: 0,
            };
          }
        }
        return {
          ...pkg,
          progressByStand: progressWithRevenueByStand,
          progressByFirm: progressWithRevenueByFirm,
          progress: globalProgress,
        };
      }
    ),
  ];
}

/**
 * Return true if the operation is unfinished and not deleted. False otherwise
 * @param operation
 */
function isOperationUnfinished(operation: Operation): boolean {
  return !operation.deleted && !operation.completionDate;
}

function getAllPackageDeals<P extends PriceablePackageDeal>(
  packageDeals: readonly P[],
  searchedStatus: PackageDealStatus | undefined,
  achievableOnly: boolean,
  includesExpertRecoIfNoStatus: boolean
): readonly P[] {
  return packageDeals.filter(nonDeleted).filter((pck) => {
    switch (searchedStatus) {
      case 'available':
        return (
          (!achievableOnly || isPackageDealAchievable(pck)) &&
          isPackageDealAvailable(pck, includesExpertRecoIfNoStatus)
        );
      case 'canceled':
        return isPackageDealCanceled(pck, includesExpertRecoIfNoStatus);
      case undefined:
        return !achievableOnly || isPackageDealAchievable(pck);
      default:
        throw Error(`Unknown package deal status ${searchedStatus}`);
    }
  });
}

function getCanceledOrNotRecommendedPackageDeals<P extends PriceablePackageDeal>(
  packageDeals: readonly P[]
): readonly P[] {
  return getAllPackageDeals(packageDeals, 'canceled', false, true);
}

function getCanceledPackageDeals<P extends PriceablePackageDeal>(
  packageDeals: readonly P[]
): readonly P[] {
  return getAllPackageDeals(packageDeals, 'canceled', false, false);
}

function getAvailableOrRecommendedPackageDeals<P extends PriceablePackageDeal>(
  packageDeals: readonly P[],
  achievableOnly = true
): readonly P[] {
  return getAllPackageDeals(packageDeals, 'available', achievableOnly, true);
}

function getAvailablePackageDeals<P extends PriceablePackageDeal>(
  packageDeals: readonly P[],
  achievableOnly = true
): readonly P[] {
  return getAllPackageDeals(packageDeals, 'available', achievableOnly, false);
}

function getSortedActiveOperations(packageDeal: PackageDeal): readonly Operation[] {
  const sort = (operation1: Operation, operation2: Operation): number => {
    if (operation1.orderIndex < operation2.orderIndex) {
      return -1;
    }
    if (operation1.orderIndex > operation2.orderIndex) {
      return 1;
    }
    return 0;
  };
  return packageDeal.operations.filter(nonDeleted).sort(sort);
}

function getFinishedOperations<T extends PriceablePackageDeal>(
  packageDeal: T
): readonly Operation[] {
  return packageDeal.operations.filter((o) => !o.deleted && o.completionDate);
}

function getUnfinishedOperations(packageDeal: PackageDeal): readonly Operation[] {
  return packageDeal.operations.filter((o) => !o.deleted && !o.completionDate);
}

function getFinishedOperationsForStandId(
  packageDeal: PackageDeal,
  standId: string
): readonly Operation[] {
  return packageDeal.operations.filter(
    (o) => !o.deleted && o.completionDate && o.standId === standId
  );
}

function getUnfinishedOperationsForStandId(
  packageDeal: PackageDeal,
  standId: string
): readonly Operation[] {
  return packageDeal.operations.filter(
    (o) => !o.deleted && !o.completionDate && o.standId === standId
  );
}

function getOperationsForStandId(packageDeal: PackageDeal, standId: string): readonly Operation[] {
  return packageDeal.operations.filter((o) => !o.deleted && o.standId === standId);
}

function isOperationAssignedOnWorkshopQualifiedPostCategoryInStand(
  operation: Operation,
  qualifiedPostCategoryOrId: string,
  standId: string
): boolean {
  if (operation.standId !== standId) {
    return false;
  }
  const dispatchAttribute = operation.attributes[OPERATION_ATTRIBUTES.WORKSHOP_POST];
  if (isTruthy(dispatchAttribute) && typeof dispatchAttribute === 'string') {
    return qualifiedPostCategoryOrId.startsWith(dispatchAttribute);
  }
  return false;
}

function isOperationAssignedInStandButNotOnKnownPostOrCategory(
  operation: Operation,
  allQualifiedPostOrCategoryIds: readonly string[],
  standId: string
): boolean {
  if (operation.standId !== standId) {
    return false;
  }
  const dispatchAttribute = operation.attributes[OPERATION_ATTRIBUTES.WORKSHOP_POST];
  if (isTruthy(dispatchAttribute) && typeof dispatchAttribute === 'string') {
    let knownSpotInImplantation = false;
    for (const qualifiedId of allQualifiedPostOrCategoryIds) {
      if (qualifiedId.startsWith(dispatchAttribute)) {
        knownSpotInImplantation = true;
        break;
      }
    }
    return !knownSpotInImplantation;
  }
  return true;
}

function getUnfinishedOperationsInStandButNotOnKnownPostOrCategory(
  packageDeal: PackageDeal,
  allQualifiedPostOrCategoryIds: readonly string[],
  standId: string
): readonly Operation[] {
  const activeOperations = packageDeal.operations.filter(nonDeleted);
  return activeOperations.filter(
    (o) =>
      !o.completionDate &&
      isOperationAssignedInStandButNotOnKnownPostOrCategory(
        o,
        allQualifiedPostOrCategoryIds,
        standId
      )
  );
}

function getFinishedOperationsForWorkshopQualifiedPostCategoryInStand(
  packageDeal: PackageDeal,
  qualifiedPostCategoryOrId: string,
  standId: string
): readonly Operation[] {
  const activeOperations = packageDeal.operations.filter(nonDeleted);
  return activeOperations.filter(
    (o) =>
      o.completionDate &&
      isOperationAssignedOnWorkshopQualifiedPostCategoryInStand(
        o,
        qualifiedPostCategoryOrId,
        standId
      )
  );
}

function getFinishedOperationsForWorkshopPostCategoryInStand(
  packageDeal: PackageDeal,
  implantationId: string,
  postCategoryOrId: string,
  standId: string
): readonly Operation[] {
  const qualifiedCategory = globalHelpers.computeQualifiedWorkshopPostId(
    implantationId,
    postCategoryOrId
  );
  return getFinishedOperationsForWorkshopQualifiedPostCategoryInStand(
    packageDeal,
    qualifiedCategory,
    standId
  );
}

function getUnfinishedOperationsForWorkshopQualifiedPostCategoryInStand(
  packageDeal: PackageDeal,
  qualifiedPostCategoryOrId: string,
  standId: string
): readonly Operation[] {
  const activeOperations = packageDeal.operations.filter(nonDeleted);
  return activeOperations.filter(
    (o) =>
      !o.completionDate &&
      isOperationAssignedOnWorkshopQualifiedPostCategoryInStand(
        o,
        qualifiedPostCategoryOrId,
        standId
      )
  );
}

function getUnfinishedOperationsForWorkshopPostCategoryInStand(
  packageDeal: PackageDeal,
  implantationId: string,
  postCategoryOrId: string,
  standId: string
): readonly Operation[] {
  const qualifiedCategory = globalHelpers.computeQualifiedWorkshopPostId(
    implantationId,
    postCategoryOrId
  );
  return getUnfinishedOperationsForWorkshopQualifiedPostCategoryInStand(
    packageDeal,
    qualifiedCategory,
    standId
  );
}

function getOperationsForWorkshopQualifiedPostCategoryInStand(
  packageDeal: PackageDeal,
  qualifiedPostCategoryOrId: string,
  standId: string
): readonly Operation[] {
  const activeOperations = packageDeal.operations.filter(nonDeleted);
  return activeOperations.filter((o) =>
    isOperationAssignedOnWorkshopQualifiedPostCategoryInStand(o, qualifiedPostCategoryOrId, standId)
  );
}

function getOperationsForWorkshopPostCategoryInStand(
  packageDeal: PackageDeal,
  implantationId: string,
  postCategoryOrId: string,
  standId: string
): readonly Operation[] {
  const qualifiedCategory = globalHelpers.computeQualifiedWorkshopPostId(
    implantationId,
    postCategoryOrId
  );
  return getOperationsForWorkshopQualifiedPostCategoryInStand(
    packageDeal,
    qualifiedCategory,
    standId
  );
}

function getAllFinishedOperationsForWorkshopQualifiedPostCategoryInStand(
  packageDeals: readonly PackageDeal[],
  qualifiedPostCategoryOrId: string,
  standId: string,
  achievableOnly = true
): readonly Operation[] {
  const operations: Operation[] = [];
  const activePackageDeals = packageDeals
    .filter(nonDeleted)
    .filter((p) => !achievableOnly || !isTruthyAndNotEmpty(p.unachievableReason));
  activePackageDeals.forEach((pd) => {
    getFinishedOperationsForWorkshopQualifiedPostCategoryInStand(
      pd,
      qualifiedPostCategoryOrId,
      standId
    ).forEach((o) => {
      operations.push(o);
    });
  });

  return operations;
}

function getAllFinishedOperationsForWorkshopPostCategoryInStand(
  packageDeals: readonly PackageDeal[],
  implantationId: string,
  postCategoryOrId: string,
  standId: string,
  achievableOnly = true
): readonly Operation[] {
  const qualifiedId = globalHelpers.computeQualifiedWorkshopPostId(
    implantationId,
    postCategoryOrId
  );
  return getAllFinishedOperationsForWorkshopQualifiedPostCategoryInStand(
    packageDeals,
    qualifiedId,
    standId,
    achievableOnly
  );
}

function getAllUnfinishedOperationsForWorkshopQualifiedPostCategoryInStand(
  packageDeals: readonly PackageDeal[],
  qualifiedPostCategoryOrId: string,
  standId: string,
  achievableOnly = true
): readonly Operation[] {
  const operations: Operation[] = [];
  const activePackageDeals = packageDeals
    .filter(nonDeleted)
    .filter((p) => !achievableOnly || !isTruthyAndNotEmpty(p.unachievableReason));
  activePackageDeals.forEach((pd) => {
    getUnfinishedOperationsForWorkshopQualifiedPostCategoryInStand(
      pd,
      qualifiedPostCategoryOrId,
      standId
    ).forEach((o) => {
      operations.push(o);
    });
  });

  return operations;
}

function getAllUnfinishedOperationsForWorkshopPostCategoryInStand(
  packageDeals: readonly PackageDeal[],
  implantationId: string,
  postCategoryOrId: string,
  standId: string,
  achievableOnly = true
): readonly Operation[] {
  const qualifiedCategory = globalHelpers.computeQualifiedWorkshopPostId(
    implantationId,
    postCategoryOrId
  );
  return getAllUnfinishedOperationsForWorkshopQualifiedPostCategoryInStand(
    packageDeals,
    qualifiedCategory,
    standId,
    achievableOnly
  );
}

function getAllOperationsForWorkshopQualifiedPostCategoryInStand(
  packageDeals: readonly PackageDeal[],
  qualifiedPostCategoryOrId: string,
  standId: string,
  achievableOnly = true
): readonly Operation[] {
  const operations: Operation[] = [];
  const activePackageDeals = packageDeals
    .filter(nonDeleted)
    .filter((p) => !achievableOnly || !isTruthyAndNotEmpty(p.unachievableReason));
  activePackageDeals.forEach((pd) => {
    getOperationsForWorkshopQualifiedPostCategoryInStand(
      pd,
      qualifiedPostCategoryOrId,
      standId
    ).forEach((o) => {
      operations.push(o);
    });
  });

  return operations;
}

function getAllOperationsForWorkshopPostCategoryInStand(
  packageDeals: readonly PackageDeal[],
  implantationId: string,
  postCategoryOrId: string,
  standId: string,
  achievableOnly = true
): readonly Operation[] {
  const qualifiedCategory = globalHelpers.computeQualifiedWorkshopPostId(
    implantationId,
    postCategoryOrId
  );
  return getAllOperationsForWorkshopQualifiedPostCategoryInStand(
    packageDeals,
    qualifiedCategory,
    standId,
    achievableOnly
  );
}

function getAllUnfinishedOperations(
  packageDeals: readonly PackageDeal[],
  achievableOnly = true
): readonly Operation[] {
  const unfinishedOperations: Operation[] = [];
  const achievablePackageDeals = getAvailablePackageDeals(packageDeals, achievableOnly);

  achievablePackageDeals.forEach((achievablePackageDeal) => {
    const operations = getUnfinishedOperations(achievablePackageDeal);
    operations.forEach((o) => unfinishedOperations.push(o));
  });
  return unfinishedOperations;
}

function allOperationsAreFinished(packageDeal: PackageDeal): boolean {
  return packageDeal.operations.filter(nonDeleted).filter((o) => !o.completionDate).length === 0;
}

function getOperations(packageDeal: PackageDeal): Operation[] {
  return packageDeal.operations.filter((o) => !o.deleted);
}

function getAllOperations(
  packageDeals: readonly PackageDeal[],
  includesRecommended = false,
  achievableOnly = true
): readonly Operation[] {
  const operations: Operation[] = [];

  const availablePackageDeals = getAllPackageDeals(
    packageDeals,
    'available',
    achievableOnly,
    includesRecommended
  );
  availablePackageDeals.forEach((availablePackageDeal) => {
    const ops = getOperations(availablePackageDeal);
    ops.forEach((o) => operations.push(o));
  });
  return operations;
}

function getAllUnfinishedOperationsForStandId(
  packageDeals: readonly PackageDeal[],
  standId: string,
  achievableOnly = true
): readonly Operation[] {
  return getAllUnfinishedOperations(packageDeals, achievableOnly).filter(
    (o) => o.standId === standId
  );
}

function getUnfinishedOperationsInPackageDealForStandId(
  packageDeal: PackageDeal,
  standId: string
): readonly Operation[] {
  return packageDeal.operations
    .filter(nonDeleted)
    .filter((o) => !o.completionDate && o.standId === standId);
}

function getAllOperationsForStandId(
  packageDeals: readonly PackageDeal[],
  standId: string,
  achievableOnly = true
): readonly Operation[] {
  return getAllOperations(packageDeals, achievableOnly).filter((o) => o.standId === standId);
}

function getPackageDealsRelatedToStandWithUnfinishedOperations(
  packageDeals: readonly PackageDeal[],
  standId: string,
  achievableOnly = true
): readonly PackageDeal[] {
  return getAvailablePackageDeals(packageDeals, achievableOnly).filter((pd) => {
    for (const o of pd.operations.filter(nonDeleted)) {
      if (o.standId === standId && !o.completionDate) {
        return true;
      }
    }
    return false;
  });
}

function getPackageDealsRelatedToStand(
  packageDeals: readonly PackageDeal[],
  standId: string,
  achievableOnly = true
): readonly PackageDeal[] {
  return getAvailablePackageDeals(packageDeals, achievableOnly).filter(({ operations }) =>
    operations.filter(nonDeleted).some((operation) => operation.standId === standId)
  );
}

function getNotCancelledPackageDealsRelatedToStand(
  packageDeals: readonly PackageDeal[],
  standId: string
): readonly PackageDeal[] {
  return getAllPackageDeals(packageDeals, undefined, true, false).filter(({ operations }) =>
    operations.filter(nonDeleted).some((operation) => operation.standId === standId)
  );
}

function getTotalWorkload(packageDeals: readonly PackageDeal[], achievableOnly = true): number {
  return getAllUnfinishedOperations(packageDeals, achievableOnly).reduce(
    (a, o) => a + o.workload,
    0
  );
}

function getPackageDealForOperation(
  packageDeals: readonly PackageDeal[],
  operationId: string
): PackageDeal | undefined {
  for (const pd of packageDeals) {
    const fountOp = pd.operations.find((o) => o.id === operationId);
    if (fountOp !== undefined) {
      return pd;
    }
  }
  return undefined;
}

function getWorkloadForStand(
  packageDeals: readonly PackageDeal[],
  standId: string,
  achievableOnly = true
): number {
  return getAllUnfinishedOperationsForStandId(packageDeals, standId, achievableOnly).reduce(
    (a, o) => a + o.workload,
    0
  );
}

function getTotalWorkloadForStand(
  packageDeals: readonly PackageDeal[],
  standId: string,
  achievableOnly = true
): number {
  return getAllOperationsForStandId(packageDeals, standId, achievableOnly).reduce(
    (a, o) => a + o.workload,
    0
  );
}

function getRemainingWorkloadForStand(
  packageDeals: readonly PackageDeal[],
  standId: string,
  achievableOnly = true
): number {
  return getAllUnfinishedOperationsForStandId(packageDeals, standId, achievableOnly).reduce(
    (a, o) => a + o.workload,
    0
  );
}

function addReturnInExpressionMemberIfNecessary(expression: string): string {
  let theExpression = expression;
  if (!theExpression.trim().startsWith('return ')) {
    theExpression = `return ${expression}`;
  }
  return theExpression;
}

function internalDoComputePackageDealExpressionResult(
  expression: PddOrODExpressionType | string | number,
  allVariables: Record<string, boolean | number | string>,
  roundTo: number
): number {
  const allVariableNames: string[] = [];
  const allVariableValues: (boolean | number | string)[] = [];
  forEachRecordValues(allVariables, (v, k) => {
    allVariableNames.push(k);
    allVariableValues.push(v);
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  let theFunction: Function | undefined;
  switch (typeof expression) {
    case 'string':
      // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func
      theFunction = Function(
        ...allVariableNames,
        addReturnInExpressionMemberIfNecessary(expression)
      );
      return packageDealDescHelpers.isInteractiveExpression(expression)
        ? globalHelpers.roundToUpper(theFunction(...allVariableValues), roundTo)
        : globalHelpers.roundTo(theFunction(...allVariableValues));
    case 'number':
      return expression;
    default:
      for (const condition of keysOf(expression)) {
        if (condition !== '*') {
          // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func
          theFunction = Function(
            ...allVariableNames,
            addReturnInExpressionMemberIfNecessary(condition as string)
          );
        }
        if (condition === '*' || (isTruthy(theFunction) && theFunction(...allVariableValues))) {
          return internalDoComputePackageDealExpressionResult(
            expression[condition],
            allVariables,
            roundTo
          );
        }
      }
      break;
  }
  throw Error('An expression should have a default case "*"');
}

function computePackageDealExpressionResult(
  expression: string,
  allVariables: Record<string, boolean | number | string>,
  roundTo: number
): number {
  const expressionObject =
    packageDealDescHelpers.convertPackageDealRelatedExpressionStringToType(expression);
  const value = internalDoComputePackageDealExpressionResult(
    expressionObject,
    allVariables,
    roundTo
  );
  return value === Infinity ? 0 : value;
}

function isPackageDealPriceManuallySetToZero(packageDeal: PackageDeal): boolean {
  return packageDeal.overridablePrice && packageDeal.priceIsOverridden && packageDeal.price === 0;
}

function recomputePackageDealExpressionResults(
  packageDeal: PackageDeal,
  globalVariableValues: Record<string, number | string>,
  roundPriceTo: number
): PackageDeal {
  const allVariables: Record<string, boolean | number | string> = { ...globalVariableValues };
  forEachRecordValues(packageDeal.variables, (variable, key) => {
    allVariables[key] = variable.value;
  });

  let { duration, price } = packageDeal;
  if (isTruthyAndNotEmpty(packageDeal.durationExpression)) {
    duration = computePackageDealExpressionResult(
      packageDeal.durationExpression,
      allVariables,
      0.01
    );
  }
  if (!packageDeal.priceIsOverridden && isTruthyAndNotEmpty(packageDeal.priceExpression)) {
    price = computePackageDealExpressionResult(
      packageDeal.priceExpression,
      allVariables,
      roundPriceTo
    );
  }

  const operations = packageDeal.operations.map((o): Operation => {
    if (o.deleted) {
      return o;
    }
    let { workload } = o;
    if (isTruthyAndNotEmpty(o.workloadExpression)) {
      workload = computePackageDealExpressionResult(o.workloadExpression, allVariables, 0.01);
    }

    return {
      ...o,
      workload,
    };
  });

  // If price have been manually set to zero, we must ignore package deal's duration &
  // operation workloads
  if (packageDealHelpers.isPackageDealPriceManuallySetToZero(packageDeal)) {
    const operationsWithNullWorkload: readonly Operation[] = operations.map((operation) => {
      return {
        ...operation,
        workload: 0,
      };
    });
    return { ...packageDeal, duration: 0, price, operations: operationsWithNullWorkload };
  }

  return { ...packageDeal, duration, price, operations };
}

function getSparePartDisplayedLabel(sp: Labelled, pkgDeal: PriceablePackageDeal): string {
  const allVariables: Record<string, boolean | number | string> = {};
  forEachRecordValues(pkgDeal.variables, (variable, key) => {
    allVariables[key] = variable.value;
  });
  return computePackageDealDisplayedLabelFromVariableValues(sp.label, allVariables);
}

function getSparePartDisplayedLabelWithAsterix(
  sp: Labelled,
  pkgDeal: PriceablePackageDeal
): string {
  const sparePartDisplayedLabel = getSparePartDisplayedLabel(sp, pkgDeal);
  return `${sparePartDisplayedLabel} *`;
}

function convertExpertRecommendationToStatusIfNull<T extends PriceablePackageDeal>(
  packageDeals: readonly T[],
  filterDeleted = false
): readonly T[] {
  const updatedPackageDeals = packageDeals.map((pck): T => {
    let { status } = pck;

    if (pck.deleted) {
      return pck;
    }

    if (pck.status === null) {
      status = pck.recommendedByExpert ? 'available' : 'canceled';
    }
    return {
      ...pck,
      status,
    };
  });
  if (filterDeleted) {
    return updatedPackageDeals.filter((pck) => !pck.deleted);
  }
  return updatedPackageDeals;
}

function computeGlobalVariableValuesForGivenTags(
  kanban: CoreFields<Kanban>,
  tags: readonly string[],
  considerExpertRecommendationIfNoStatut = false
): Record<string, number | string> {
  const globalVariables: Record<string, number | string> = {};
  const globalVariableDescs = packageDealDescHelpers.getGlobalPackageDealDescVariables([
    ...new Set(tags),
  ]);

  let theKanban = kanban;
  if (considerExpertRecommendationIfNoStatut) {
    theKanban = {
      ...kanban,
      packageDeals: convertExpertRecommendationToStatusIfNull(kanban.packageDeals),
    };
  }

  globalVariableDescs.forEach((v) => {
    globalVariables[v.label] = v.computation(theKanban);
  });
  return globalVariables;
}

function computeAllGlobalVariableValues(
  kanban: CoreFields<Kanban>,
  considerExpertRecommendationIfNoStatut = false
): Record<string, number | string> {
  const allTags = kanban.packageDeals.filter((pd) => isTruthy(pd.tags)).flatMap((pd) => pd.tags);

  return computeGlobalVariableValuesForGivenTags(
    kanban,
    allTags,
    considerExpertRecommendationIfNoStatut
  );
}

function isVariableCreatedFromPackageDealTag(
  tags: readonly string[],
  variableName: string
): boolean {
  if (variableName.startsWith(TAG_GLOBAL_VARIABLE_NAME_PREFIX)) {
    const tag = variableName.replace(TAG_GLOBAL_VARIABLE_NAME_PREFIX, '');
    return tags.includes(tag) || tags.includes(toLowerFirst(tag));
  }
  return false;
}

function updateAllPackageDealsExpressionComputations(
  kanban: CoreFields<Kanban>,
  considerExpertRecommendationIfNoStatut = false
): readonly PackageDeal[] {
  let theKanban = kanban;
  if (considerExpertRecommendationIfNoStatut) {
    theKanban = {
      ...kanban,
      packageDeals: convertExpertRecommendationToStatusIfNull(kanban.packageDeals),
    };
  }

  const allGlobalVariables = computeAllGlobalVariableValues(theKanban);

  const recomputedPackageDeals = theKanban.packageDeals.map((pd): PackageDeal => {
    function prepareVariablesValuesForComputation(
      forCanceledPackageDeals: boolean
    ): Record<string, number | string> {
      const localGlobalVariables: Record<string, number | string> = {};
      if (pd.status !== null) {
        forEachRecordValues(allGlobalVariables, (val, key) => {
          if (isVariableCreatedFromPackageDealTag(pd.tags, key)) {
            // If we want to compute the base price for canceled package deals, compute the price as if the package deal
            // was alone for his tags
            localGlobalVariables[key] = forCanceledPackageDeals ? 1 : val;
          } else {
            localGlobalVariables[key] = val;
          }
        });
      }
      return localGlobalVariables;
    }

    if (!nonDeleted(pd)) {
      return pd;
    }
    if (isPackageDealAvailable(pd)) {
      return recomputePackageDealExpressionResults(
        pd,
        prepareVariablesValuesForComputation(false),
        kanban.contract.configuration.roundPriceTo
      );
    }
    if (isPackageDealCanceled(pd)) {
      return recomputePackageDealExpressionResults(
        pd,
        prepareVariablesValuesForComputation(true),
        kanban.contract.configuration.roundPriceTo
      );
    }
    return pd;
  });

  const payloads = computePayload(theKanban.packageDeals, recomputedPackageDeals);

  if (isTruthy(payloads) && payloads.length > 0) {
    return applyPayload(kanban.packageDeals, payloads);
  }

  return kanban.packageDeals;
}

function createPackageDealFromPackageDealDesc(
  sequence: Sequence,
  packageDealDesc: PackageDealDesc,
  selectedCarElement: CarElement | undefined,
  sparePartManagementType: SparePartManagementType,
  options?: {
    // If provided, the expressions will be computed
    globalVariableValues: Record<string, number> | null;
    packageDealStatus: PackageDealStatus | null;
    roundPriceTo: number;
    purchaseOrderId?: string;
  }
): PackageDeal {
  let pkgCarElement: PackageDeal['carElement'];
  if (packageDealDesc.carElementIds.length > 0) {
    if (selectedCarElement === undefined) {
      throw Error('Missing car element');
    }
    if (!packageDealDesc.carElementIds.includes(selectedCarElement.id)) {
      throw Error(
        `Invalid car element : '${selectedCarElement.label}' (${selectedCarElement.id}) is not associated to the package deal '${packageDealDesc.label}' (${packageDealDesc.id})`
      );
    }
    const { id, label, shapes, index, category } = selectedCarElement;
    pkgCarElement = {
      id,
      label,
      shapes,
      index,
      category,
    };
  }

  const {
    id: packageDealDescId,
    code,
    label,
    hint,
    sparePartDescs,
    documentDescs,
    durationExpression,
    variableDescs,
    tags,
    category,
    marketplaceBuyCategory,
    marketplaceSaleCategory,
    overridablePrice,
    ignoreVAT,
    priceExpression,
    isSubcontractable,
  } = packageDealDesc;

  const variables: Record<string, PackageDealVariable> = {};
  forEachRecordValues(variableDescs, (variableDesc, key) => {
    if (isTruthy(variableDesc)) {
      const { defaultValue, ...rest } = variableDesc;
      if (isBooleanVariable(variableDesc)) {
        const value = defaultValue !== null && defaultValue !== undefined ? defaultValue : false;
        if (typeof value !== 'boolean') {
          throw Error(
            `The type of the variable value is incompatible with the variable definition type ${variableDesc.type}`
          );
        }
        // @ts-ignore
        variables[key] = {
          ...rest,
          value,
        };
      } else {
        const value = isTruthy(defaultValue) ? defaultValue : variableDesc.availableValues[0];
        if (!isTruthy(value)) {
          throw Error('All PackageDealDescs variable must have a value');
        }
        const variableTypeError = `The type of the variable value is incompatible with the variable definition type ${variableDesc.type}`;
        switch (variableDesc.type) {
          case 'numeric':
            if (typeof value !== 'number') {
              throw Error(variableTypeError);
            }
            break;
          case 'text':
            if (typeof value !== 'string') {
              throw Error(variableTypeError);
            }
            break;
          default:
            throw Error(variableTypeError);
        }
        // @ts-ignore
        variables[key] = {
          ...rest,
          value,
        };
      }
    }
  });

  const operations = packageDealDesc.operationDescs.filter(nonDeleted).map((od): Operation => {
    return {
      ...EMPTY_OPERATION,
      id: sequence.next(),
      orderIndex: od.orderIndex,
      label: od.label,
      workloadExpression: od.workloadExpression,
      standId: od.standId,
      operationDescId: od.id,
    };
  });

  const maxOrderIndex = operations.reduce(
    (max, operation) => Math.max(max, operation.orderIndex),
    -1
  );
  const operationsFromDocumentDescs: readonly Operation[] = documentDescs.filter(nonDeleted).map(
    (doc, index): Operation => ({
      ...EMPTY_OPERATION,
      id: sequence.next(),
      orderIndex: maxOrderIndex + index,
      label: t(`globals:uploadDocumentOperationLabel`, { docLabel: doc.label }),
      standId: doc.standId,
      operationDescId: '',
      type: 'UploadDocument',
      documentsInfo: {
        documentsFileNames: [],
        documentLabel: doc.label,
        targetFolder: doc.targetFolder,
      },
    })
  );

  const packageDeal: PackageDeal = {
    ...EMPTY_PACKAGE_DEAL,
    id: sequence.next(),
    packageDealDescId,
    code,
    category,
    label,
    carElement: pkgCarElement,
    hint,
    tags,
    comment: '',
    durationExpression,
    overridablePrice,
    priceExpression,
    variables,
    operations: [...operations, ...operationsFromDocumentDescs],
    purchaseOrderId: options?.purchaseOrderId,
    status: options?.packageDealStatus ?? EMPTY_PACKAGE_DEAL.status,
    spareParts: sparePartDescs.filter(nonDeleted).map((spd): SparePart => {
      const sparePart = sparePartHelpers.convertSparePartDescToSparePart(
        spd,
        sparePartManagementType
      );
      return {
        ...sparePart,
        id: sequence.next(),
      };
    }),
    // Helps not to propagate the optional fields when not necessary
    ...(isSubcontractable ? { isSubcontractable: true } : {}),
    ...(ignoreVAT ? { ignoreVAT: true } : {}),
    ...(isTruthy(marketplaceBuyCategory) && marketplaceBuyCategory !== MKTP_PKG_CATEGORY_NA
      ? { marketplaceBuyCategory }
      : {}),
    ...(isTruthy(marketplaceSaleCategory) && marketplaceSaleCategory !== MKTP_PKG_CATEGORY_NA
      ? { marketplaceSaleCategory }
      : {}),
  };

  if (isTruthy(options?.globalVariableValues)) {
    return recomputePackageDealExpressionResults(
      packageDeal,
      nonnull(options?.globalVariableValues),
      nonnull(options).roundPriceTo
    );
  }

  return packageDeal;
}

/**
 * This method seeks, from a package deal, all unfinished operations that are assigned to a stand but not to the particular
 * workshopPost defined by currentQualifiedPostId
 * @param packageDeal the package deal whose we seek operations.
 * @param standId we are looking for operations that are assigned to this stand.
 * @param currentQualifiedCategory we are looking for operations assigned to elswhere than this level's category.
 * @returns possibly empty, all package deal's unfinished operations that are assigned to another workshopPost than currentQualifiedPostId
 * but within the standId
 */
function getAllOperationsWithinStandToDoElsewhereThanCurrentCategory(
  packageDeal: PackageDeal,
  standId: string,
  currentQualifiedCategory: string
): readonly Operation[] {
  return getAllUnfinishedOperations([packageDeal], true).filter((operation) => {
    return (
      operation.standId === standId &&
      operation.attributes[OPERATION_ATTRIBUTES.WORKSHOP_POST] !== undefined &&
      operation.attributes[OPERATION_ATTRIBUTES.WORKSHOP_POST] !== currentQualifiedCategory
    );
  });
}

function getReceivedAndMissingSpareParts(pkg: PackageDeal): {
  missing: readonly DisplayableSparePart[];
  received: readonly DisplayableSparePart[];
} {
  const { filtered, rejected } = filterReject(
    pkg.spareParts.filter(nonDeleted).map(
      (sp): DisplayableSparePart => ({
        ...sp,
        displayLabel: getSparePartDisplayedLabel(sp, pkg),
      })
    ),
    (sp) => !isTruthy(sp.dateOfReception)
  );
  return {
    missing: filtered,
    received: rejected,
  };
}

function getAllSparePartLabels(pkg: PackageDeal): readonly string[] {
  return pkg.spareParts.filter(nonDeleted).map((sp): string => {
    return sp.label;
  });
}

function getUnfinishedOperationsOnPrecedentOrUnknownCategories(
  packageDeal: PackageDeal,
  standId: string,
  selfExcludedEarlierQualifiedWorkshopCategories: readonly string[],
  allQualifiedCategories: readonly string[]
): readonly Operation[] {
  const operationItems: Operation[] = [];
  selfExcludedEarlierQualifiedWorkshopCategories.forEach((qualifiedId) => {
    const precedentUnfinishedOps = getUnfinishedOperationsForWorkshopQualifiedPostCategoryInStand(
      packageDeal,
      qualifiedId,
      standId
    );
    operationItems.push(...precedentUnfinishedOps);
  });
  const unfinishedOperationsOnPrecedentOrUnknownCategories =
    getUnfinishedOperationsInStandButNotOnKnownPostOrCategory(
      packageDeal,
      allQualifiedCategories,
      standId
    );
  operationItems.push(...unfinishedOperationsOnPrecedentOrUnknownCategories);
  return operationItems;
}

function isAdminPackageDeal(packageDeal: PackageDeal): boolean {
  return packageDeal.code === ADMIN_PACKAGE_DEAL_CODE;
}

function hasUnfinishedOpForTag(pkg: PackageDeal, tagName: string): boolean {
  if (pkg.tags.includes(tagName)) {
    return getUnfinishedOperations(pkg).length > 0;
  }
  return false;
}

function collectAvailablePkgsFilteredByTagsIfProvided(
  pkgs: readonly PackageDeal[],
  tagsFilteringPkgs: readonly string[] | undefined,
  achievableOnly = true
): readonly PackageDeal[] {
  if (!isTruthy(tagsFilteringPkgs) || tagsFilteringPkgs.length === 0) {
    return pkgs;
  }
  return getAvailablePackageDeals(pkgs, achievableOnly).filter((pkg) =>
    pkg.tags.some((tag) => tagsFilteringPkgs.includes(tag))
  );
}

function isExpertisePackageDeal(packageDeal: BasePackageDeal): boolean {
  return (
    packageDealDescHelpers.isExpertiseCategory(packageDeal.category) &&
    packageDeal.operations.length > 0
  );
}

function isActiveAndAvailableExpertisePackageDeal(packageDeal: BasePackageDeal): boolean {
  if (
    packageDeal.deleted ||
    !isPackageDealAvailable(packageDeal) ||
    isTruthyAndNotEmpty(packageDeal.unachievableReason)
  ) {
    return false;
  }
  return isExpertisePackageDeal(packageDeal);
}

function getExpertisePackageDealWithoutRaisingAnError(
  packageDeals: readonly BasePackageDeal[]
): BasePackageDeal | 'NO_EXPERTISE_PACKAGE_DEAL' | 'MORE_THAN_ONE_EXPERTISE_PACKAGE_DEAL' {
  const expertisePackageDeals = packageDeals.filter((packageDeal) =>
    isActiveAndAvailableExpertisePackageDeal(packageDeal)
  );
  switch (expertisePackageDeals.length) {
    case 0:
      return 'NO_EXPERTISE_PACKAGE_DEAL';
    case 1:
      return expertisePackageDeals[0];
    default:
      return 'MORE_THAN_ONE_EXPERTISE_PACKAGE_DEAL';
  }
}

function getExpertisePackageDeal(packageDeals: readonly BasePackageDeal[]): BasePackageDeal {
  const expertisePackageDeal = getExpertisePackageDealWithoutRaisingAnError(packageDeals);
  switch (expertisePackageDeal) {
    case 'NO_EXPERTISE_PACKAGE_DEAL':
      throw new Error('Missing expertise package deal');
    case 'MORE_THAN_ONE_EXPERTISE_PACKAGE_DEAL':
      throw new Error(`More than one expertise package deal`);
    default:
      return expertisePackageDeal;
  }
}

function convertToPackageDealSignature<T extends PriceablePackageDeal>(
  pck: T
): PackageDealSignature {
  return {
    code: pck.code,
    price: pck.price,
    sparePartsPrice: pck.spareParts.reduce((acc, sp) => acc + sp.price, 0),
    recommendedByExpert: pck.recommendedByExpert,
  };
}

function computePackageDealsSignature<T extends PriceablePackageDeal>(
  packageDeals: readonly T[],
  deduceStatusFromExpertRecoIfNull = false
): PackageDealsSignature {
  const notDeletedPcks = packageDeals.filter((pck) => !pck.deleted);
  const packageDealsWithStatus = deduceStatusFromExpertRecoIfNull
    ? convertExpertRecommendationToStatusIfNull(notDeletedPcks)
    : notDeletedPcks;

  const { filtered: canceled, rejected: selected } = filterReject(packageDealsWithStatus, (pck) => {
    if (pck.status === null) {
      throw Error(
        `All packageDeals must have a status when used as a kanban signature (offender: ${pck.code} - ${pck.id})`
      );
    }
    return pck.status === 'canceled';
  });

  const { filtered: achievables, rejected: unachievables } = filterReject(selected, (pck) =>
    isPackageDealAchievable(pck)
  );

  return {
    canceledPackageDeals: canceled.map(convertToPackageDealSignature),
    selectedPackageDeals: {
      achievables: achievables.map(convertToPackageDealSignature),
      unachievables: unachievables.map(convertToPackageDealSignature),
    },
  };
}

function setPackageDealUnachievable(
  packageDeal: PackageDeal,
  unachievableReason: string
): PackageDeal {
  return {
    ...packageDeal,
    unachievableReason,
  };
}

function setPackageDealAchievable(packageDeal: PackageDeal): PackageDeal {
  return {
    ...packageDeal,
    unachievableReason: null,
  };
}

function addSparePartReferenceOperation(
  sequence: Sequence,
  packageDeal: PackageDeal,
  markOperationAsFinished: boolean,
  sparepartsReferenceStandId: string,
  userLogin: string | null
): PackageDeal {
  let alreadyExists = false;
  const updatedOps = packageDeal.operations.map((op): Operation => {
    if (op.type === 'ReferenceSparePart') {
      alreadyExists = true;
      return {
        ...op,
        deleted: false,
        completionDate: markOperationAsFinished ? Date.now() : null,
        user: markOperationAsFinished ? userLogin : null,
      };
    }
    return op;
  });
  if (alreadyExists) {
    return {
      ...packageDeal,
      operations: updatedOps,
    };
  }

  const newReferenceOp: Operation = {
    ...EMPTY_SPAREPART_REFERENCE_OPERATION,
    id: sequence.next(),
    completionDate: markOperationAsFinished ? Date.now() : null,
    user: markOperationAsFinished ? userLogin : null,
    standId: sparepartsReferenceStandId,
  };
  return {
    ...packageDeal,
    operations: [...updatedOps, newReferenceOp],
  };
}

function addSparePartReferenceOperationToExpertise(
  sequence: Sequence,
  packageDeals: readonly PackageDeal[],
  siteConfiguration: SiteConfiguration,
  markOperationAsFinished: boolean,
  userLogin: string | null
): readonly PackageDeal[] {
  const expertisePackageDeal = getExpertisePackageDeal(packageDeals);
  const sparepartsReferenceStands = workflowHelpers.getAllStandsOfType(
    siteConfiguration,
    'sparepartsReference'
  );
  return packageDeals.map((packageDeal) => {
    if (packageDeal.id === expertisePackageDeal.id) {
      return addSparePartReferenceOperation(
        sequence,
        packageDeal,
        markOperationAsFinished,
        sparepartsReferenceStands[0].id,
        userLogin
      );
    }
    return packageDeal;
  });
}

function convertPackageDealToBasePackageDeal(packageDeal: PackageDeal): BasePackageDeal {
  return {
    id: packageDeal.id,
    attributes: packageDeal.attributes,
    carElement: packageDeal.carElement,
    code: packageDeal.code,
    category: packageDeal.category,
    comment: packageDeal.comment,
    estimateComment: packageDeal.estimateComment,
    label: packageDeal.label,
    operations: packageDeal.operations,
    price: packageDeal.price,
    status: packageDeal.status,
    recommendedByExpert: packageDeal.recommendedByExpert,
    unachievableReason: packageDeal.unachievableReason,
    variables: packageDeal.variables,
    attachments: packageDeal.attachments,
    deleted: packageDeal.deleted,
    purchaseOrderId: packageDeal.purchaseOrderId,
    // Propagate fields only if necessary
    ...(packageDeal.isSubcontractable ? { isSubcontractable: true } : {}),
    ...(packageDeal.ignoreVAT ? { ignoreVAT: true } : {}),
  };
}

function convertPackageDealToPriceablePackageDeal(packageDeal: PackageDeal): PriceablePackageDeal {
  const basePackageDeal = convertPackageDealToBasePackageDeal(packageDeal);
  return {
    ...basePackageDeal,
    spareParts: sparePartHelpers.convertSparePartsToPriceableSpareParts(packageDeal.spareParts),
  };
}

function convertPackageDealsToPriceablePackageDeals(
  packageDeals: readonly PackageDeal[]
): readonly PriceablePackageDeal[] {
  return packageDeals.map((p): PriceablePackageDeal => convertPackageDealToPriceablePackageDeal(p));
}

function findNewPackageDealsWithStatus(
  initialPds: readonly PackageDeal[],
  targetPds: readonly PackageDeal[],
  status: PackageDealStatus
): readonly PackageDeal[] {
  let filter: (packageDeal: BasePackageDeal) => boolean;
  switch (status) {
    case 'available':
      filter = isPackageDealAvailable;
      break;
    case 'canceled':
      filter = isPackageDealCanceled;
      break;
    default:
      throw new Error('Unknown packageDeal status');
  }
  const newlyInStatus: PackageDeal[] = [];
  const initialInStatusIds = initialPds
    .filter(nonDeleted)
    .filter(filter)
    .map((p): string => p.id);
  targetPds
    .filter(nonDeleted)
    .filter(filter)
    .forEach((p) => {
      if (!initialInStatusIds.includes(p.id)) {
        newlyInStatus.push(p);
      }
    });
  return newlyInStatus;
}

function doPdsContainSparePartsToReferenced(
  pds: readonly PackageDeal[],
  includeRecommended = false
): boolean {
  for (const pd of pds
    .filter(nonDeleted)
    .filter((pck) => isPackageDealAvailable(pck, includeRecommended))) {
    for (const sp of pd.spareParts.filter(nonDeleted)) {
      if (sparePartHelpers.mandatoryReferenceNotDone(sp)) {
        return true;
      }
    }
  }
  return false;
}

function isThereUnfinishedSparePartsReferenceOperation(
  packageDeals: readonly PriceablePackageDeal[]
): boolean {
  const expertisePkg = getExpertisePackageDeal(packageDeals);
  return (
    expertisePkg.operations.filter(
      (o) => !o.deleted && o.type === 'ReferenceSparePart' && !o.completionDate
    ).length > 0
  );
}

function isThereASparePartsReferenceOperation(
  packageDeals: readonly PriceablePackageDeal[]
): boolean {
  const expertisePkg = getExpertisePackageDeal(packageDeals);
  return (
    expertisePkg.operations.filter((o) => !o.deleted && o.type === 'ReferenceSparePart').length > 0
  );
}

function isAValidPackageDealStatus(value: string): value is PackageDealStatus {
  return value === 'available' || value === 'canceled';
}

function getValidationOperations(packageDeals: readonly BasePackageDeal[]): FlatOperationItem[] {
  return getFlatOperationList(packageDeals, 'achievable').filter((o) => o.type === 'Validation');
}

function hasBeenValidated(packageDeals: readonly BasePackageDeal[]): boolean {
  const validationOperations = getValidationOperations(packageDeals);
  return (
    validationOperations.length > 0 &&
    validationOperations.filter((o) => !isTruthy(o.completionDate)).length === 0
  );
}

function isPackageDealUnremovable(pdCode: string): boolean {
  return PACKAGE_DEAL_CODES_THAT_CANNOT_BE_REMOVED.includes(pdCode);
}

function isPackageDealStartedOrUnremovable<T extends PriceablePackageDeal>(
  packageDeal: T
): boolean {
  return (
    isPackageDealUnremovable(packageDeal.code) || getFinishedOperations(packageDeal).length > 0
  );
}

/**
 * Get the "merged category" code for a package deal.
 * This merged BUMP and EXTE categories in a single one BODY
 * @param packageDeal
 */
function getMergedCategoryCode<
  T extends BasePackageDeal | SubcontractorPackageDeal | SubcontractorUnassignedPackageDeal,
>(packageDeal: T): MergedCarViewCategory {
  const category = packageDeal.carElement?.category ?? 'MISC';
  // We merge EXTE and BUMP into a same category
  if (category === 'EXTE' || category === 'BUMP') {
    return BODY_CATEGORY_CODE;
  }
  return category;
}

/**
 * Returns the i18n label for the merged category
 * @param categoryCode
 * @param t
 */
function getMergedCategoryLabelFromCategoryCode(
  categoryCode: MergedCarViewCategory,
  t: TFunction
): string {
  return t(`globals:operationCategories.${categoryCode}`);
}

/**
 * Returns the i18n label for the merged category
 * @param categoryCode
 * @param t
 */
function getMergedCategoryLabel<
  T extends BasePackageDeal | SubcontractorPackageDeal | SubcontractorUnassignedPackageDeal,
>(packageDeal: T, t: TFunction): string {
  const categoryCode = getMergedCategoryCode(packageDeal);
  return getMergedCategoryLabelFromCategoryCode(categoryCode, t);
}

function compareCarElementMergedCategories(
  t: TFunction,
  categoryCode1: MergedCarViewCategory,
  categoryCode2: MergedCarViewCategory
): number {
  // MISC category should be displayed as the first one
  if (categoryCode1 !== categoryCode2) {
    if (categoryCode1 === 'MISC') {
      return -1;
    }
    if (categoryCode2 === 'MISC') {
      return 1;
    }
  }

  const category1 = getMergedCategoryLabelFromCategoryCode(categoryCode1, t);
  const category2 = getMergedCategoryLabelFromCategoryCode(categoryCode2, t);

  return compareStrings(category1, category2, 'UP');
}

/**
 * Sorts the given package deals with an alphabetical sort by their merged category.
 * The MISC category is always first
 * @param t
 */
function createSortPackageDealsByMergedCarElementCategory<
  T extends BasePackageDeal | SubcontractorPackageDeal | SubcontractorUnassignedPackageDeal,
>(t: TFunction): (pd1: T, pd2: T) => number {
  return function sort(pd1, pd2): number {
    const code1 = getMergedCategoryCode(pd1);
    const code2 = getMergedCategoryCode(pd2);

    return compareCarElementMergedCategories(t, code1, code2);
  };
}

function isNotFullyManagedSparePart(packageDeals: readonly PriceablePackageDeal[]): boolean {
  return packageDeals.reduce(
    (accumulator, { spareParts }) =>
      accumulator ||
      spareParts
        .filter(nonDeleted)
        .find(({ managementType }) => managementType !== 'fullyManagedByStimcar') !== undefined,
    false
  );
}

export const packageDealHelpers = {
  getMergedCategoryCode,
  getMergedCategoryLabel,
  createSortPackageDealsByMergedCarElementCategory,
  compareCarElementMergedCategories,
  getSortedActiveOperations,
  getMergedCategoryLabelFromCategoryCode,
  getAllUnfinishedOperations,
  getPackageDealDisplayedLabel,
  getOperationDisplayedLabel,
  computePackageDealDisplayedLabelFromVariableValues,
  getFinishedOperationsForWorkshopPostCategoryInStand,
  getFinishedOperationsForWorkshopQualifiedPostCategoryInStand,
  getAllFinishedOperationsForWorkshopPostCategoryInStand,
  getAllFinishedOperationsForWorkshopQualifiedPostCategoryInStand,
  getUnfinishedOperationsForWorkshopPostCategoryInStand,
  getUnfinishedOperationsForWorkshopQualifiedPostCategoryInStand,
  getAllUnfinishedOperationsForWorkshopPostCategoryInStand,
  getAllUnfinishedOperationsForWorkshopQualifiedPostCategoryInStand,
  getOperationsForWorkshopPostCategoryInStand,
  getOperationsForWorkshopQualifiedPostCategoryInStand,
  getAllOperationsForWorkshopPostCategoryInStand,
  getAllOperationsForWorkshopQualifiedPostCategoryInStand,
  isVariableCreatedFromPackageDealTag,
  allOperationsAreFinished,
  getAllOperations,
  getAllUnfinishedOperationsForStandId,
  getAllOperationsForStandId,
  getPackageDealsRelatedToStandWithUnfinishedOperations,
  getTotalWorkload,
  updateAllPackageDealsExpressionComputations,
  getPackageDealsRelatedToStand,
  getNotCancelledPackageDealsRelatedToStand,
  getPackageDealForOperation,
  getWorkloadForStand,
  getTotalWorkloadForStand,
  getRemainingWorkloadForStand,
  computeAllGlobalVariableValues,
  computeGlobalVariableValuesForGivenTags,
  getUnfinishedOperationsInPackageDealForStandId,
  getUnfinishedOperations,
  computePackageDealExpressionResult,
  isOperationUnfinished,
  getUnfinishedOperationsForStandId,
  getOperationsForStandId,
  getFlatOperationList,
  createPackageDealFromPackageDealDesc,
  packageDealComparator,
  recomputePackageDealExpressionResults,
  getFinishedOperations,
  getFinishedOperationsForStandId,
  buildPackageDealFilter,
  getAllOperationsWithinStandToDoElsewhereThanCurrentCategory,
  getAllSparePartLabels,
  getUnfinishedOperationsInStandButNotOnKnownPostOrCategory,
  getUnfinishedOperationsOnPrecedentOrUnknownCategories,
  isPackageDealStartedOrUnremovable,
  hasUnfinishedOpForTag,
  getExpertisePackageDeal,
  getExpertisePackageDealWithoutRaisingAnError,
  computePackageDealsWithRevenueProgress,
  computePackageDealsSignature,
  isPackageDealAvailable,
  isPackageDealCanceled,
  isPackageDealAchievable,
  isPackageDealAvailableAndAchievable,
  setPackageDealUnachievable,
  setPackageDealAchievable,
  isAdminPackageDeal,
  isPackageDealWithVAT,
  hasPackageDealWithVAT,
  addSparePartReferenceOperationToExpertise,
  convertPackageDealToPriceablePackageDeal,
  convertPackageDealsToPriceablePackageDeals,
  findNewPackageDealsWithStatus,
  computePackageDealProgressPercentage,
  doPdsContainSparePartsToReferenced,
  convertPackageDealToBasePackageDeal,
  isThereUnfinishedSparePartsReferenceOperation,
  isThereASparePartsReferenceOperation,
  getReceivedAndMissingSpareParts,
  isAValidPackageDealStatus,
  getCanceledOrNotRecommendedPackageDeals,
  getCanceledPackageDeals,
  getAvailableOrRecommendedPackageDeals,
  getAvailablePackageDeals,
  collectAvailablePkgsFilteredByTagsIfProvided,
  getValidationOperations,
  hasBeenValidated,
  isActiveAndAvailableExpertisePackageDeal,
  getAllPackageDeals,
  isPackageDealUnremovable,
  getSparePartDisplayedLabel,
  getSparePartDisplayedLabelWithAsterix,
  isExpertisePackageDeal,
  isNotFullyManagedSparePart,
  isPackageDealPriceManuallySetToZero,
  getPackageDealsPriceWithoutVAT,
  getPackageDealAndSparePartsPriceWithoutVAT,
  getPackageDealAndSparePartsPriceWithVAT,
  getPackageDealsAndSparePartsTotalPriceWithoutVAT,
  getPackageDealsAndSparePartsTotalVAT,
  getPackageDealsAndSparePartsTotalPriceWithVAT,
  getInvoiceablePackageDealsAndSparePartsPriceWithoutVAT,
};
