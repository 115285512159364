import type { TFunction } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { SparePartsProvider } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps, ColumnDesc } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { AvailablePermissionPaths, CoreBackendRoutes, mergeArrayItems } from '@stimcar/libs-base';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import {
  applyAndFilterTableItemsAction,
  DisplayContentOrPlaceholder,
  ModalCardDialog,
  Table,
  useGetDefaultTableToolbarConf,
} from '@stimcar/libs-uitoolkit';
import type { Store, StoreActionContext } from '../../state/typings/store.js';
import { useHasModifyPermission } from '../../../registeredapp/permissionHooks.js';
import type {
  AdminSparePartsProvidersState,
  OnSparePartProviderChangeActionCallback,
} from './typings/store.js';
import {
  CreateSparePartsProviderModal,
  openCreateSparePartsProviderModalAction,
} from './CreateSparePartsProvider.js';
import {
  EMPTY_ADMIN_SPAREPARTS_PROVIDERS_STATE,
  EMPTY_DELETE_SPAREPARTS_PROVIDER_DIALOG_STATE,
} from './typings/store.js';

function openDeleteSparePartsProviderModalAction(
  { actionDispatch }: ActionContext<Store, AdminSparePartsProvidersState>,
  provider: SparePartsProvider
): void {
  actionDispatch.reduce((initial: AdminSparePartsProvidersState): AdminSparePartsProvidersState => {
    return {
      ...initial,
      deleteSparePartsProviderDialogState: {
        active: true,
        providerId: provider.id,
      },
    };
  });
}

async function deleteSparePartsProviderAction(
  { getState, httpClient, actionDispatch }: ActionContext<Store, AdminSparePartsProvidersState>,
  onSparePartChangeActionCallback: OnSparePartProviderChangeActionCallback
): Promise<void> {
  const { providerId } = getState().deleteSparePartsProviderDialogState;
  await httpClient.httpGet(CoreBackendRoutes.DELETE_SPAREPARTS_PROVIDER(providerId));
  actionDispatch.setProperty(
    'deleteSparePartsProviderDialogState',
    EMPTY_DELETE_SPAREPARTS_PROVIDER_DIALOG_STATE
  );
  await actionDispatch.execCallback(onSparePartChangeActionCallback, [], [providerId]);
}

const computeColumnDescs = (
  t: TFunction,
  isEditionForbidden: boolean
): readonly ColumnDesc<Store, AdminSparePartsProvidersState, SparePartsProvider>[] => {
  return [
    {
      columnLabel: t('adminSparePartsProviders:formData.id'),
      columnType: 'display',
      id: 'id',
      propertyType: 'string',
      getPropertyValue: (provider): string => provider.id,
      isDisplayedByDefault: true,
    },
    {
      columnType: 'action',
      id: 'deleteContract',
      columnLabel: t('adminSparePartsProviders:buttons.delete'),
      columnIcon: {
        iconId: '',
        showText: false,
      },
      getCellIconId: (): string => {
        return 'trash';
      },
      disabled: (): boolean => isEditionForbidden,
      cellTooltip: t('adminSparePartsProviders:buttons.delete'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      action: async (dispatch, provider): Promise<void> => {
        await dispatch.exec(openDeleteSparePartsProviderModalAction, provider);
      },
    },
  ];
};

export function initializeDefaultSortAction({
  actionDispatch,
}: ActionContext<Store, AdminSparePartsProvidersState>) {
  // Add a default sort option (sort by code)
  actionDispatch
    .scopeProperty('sortsMenuState')
    .scopeProperty('sorts')
    .setValue([
      {
        id: 'id',
        direction: 'UP',
      },
    ]);
}

const tableContentProvider = async (
  ctx: StoreActionContext
): Promise<readonly SparePartsProvider[]> => {
  return await ctx.httpClient.httpGetAsJson<SparePartsProvider[]>(
    CoreBackendRoutes.GET_ALL_SPAREPARTS_PROVIDERS
  );
};

export function AdminSparePartsProviders({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('adminSparePartsProviders');

  const isEditionForbidden = !useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.SPAREPARTS_PROVIDERS_VIEW
  );

  const { $adminSparePartsProvidersState } = $gs.$adminView;
  const { $deleteSparePartsProviderDialogState, $createSparePartsProviderDialogState } =
    $adminSparePartsProvidersState;

  const isOnline = useGetState($gs.$session.$isOnline);

  const columnDescs = useMemo(() => {
    return computeColumnDescs(t, isEditionForbidden);
  }, [t, isEditionForbidden]);

  const onSparePartChangeActionCallback = useActionCallback(
    function onSparePartChangeAction(
      ctx,
      addedOrUpdated: readonly SparePartsProvider[],
      removedContractCodes: readonly string[]
    ) {
      const { getState } = ctx;
      // Merge actual items (the same way as SSE updates for repository entities)
      const { items } = getState();

      const newSparePartProviders = mergeArrayItems(items, addedOrUpdated, removedContractCodes);
      applyAndFilterTableItemsAction(ctx, newSparePartProviders, columnDescs);
    },
    [columnDescs],
    $adminSparePartsProvidersState
  );

  const asyncCleanupEffect = useSetCallback(
    $adminSparePartsProvidersState,
    EMPTY_ADMIN_SPAREPARTS_PROVIDERS_STATE
  );

  useEffect((): (() => void) => {
    return (): void => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      asyncCleanupEffect();
    };
  }, [asyncCleanupEffect]);

  const openCreateDialogActionCallback = useActionCallback(
    openCreateSparePartsProviderModalAction,
    [],
    $createSparePartsProviderDialogState
  );

  const deleteSparePartsProviderActionCallback = useActionCallback(
    async function _deleteSparePartsProviderAction({ actionDispatch }) {
      await actionDispatch.exec(deleteSparePartsProviderAction, onSparePartChangeActionCallback);
    },
    [onSparePartChangeActionCallback],
    $adminSparePartsProvidersState
  );

  const providerId = useGetState($deleteSparePartsProviderDialogState.$providerId);

  const toolbarConf = useGetDefaultTableToolbarConf(
    LocalStorageKeys.ADMIN_SPAREPARTSPROVIDERS_COLUMN_PREFERENCE,
    t('csvDownloadBaseFileName')
  );

  return (
    <DisplayContentOrPlaceholder
      displayCondition={isOnline}
      placeholder={t('warning.notAvailableIfOffline')}
    >
      <>
        <div className="level">
          <div className="level-left">
            <div>
              <p className="title is-2">{t('title')}</p>
              <p className="subtitle is-4">{t('subtitle')}</p>
            </div>
          </div>
          <div className="level-right">
            <div className="buttons">
              <button
                type="button"
                className="button"
                onClick={openCreateDialogActionCallback}
                disabled={isEditionForbidden}
              >
                <span>{t('buttons.new')}</span>
              </button>
            </div>
          </div>
        </div>
        <Table
          $={$adminSparePartsProvidersState}
          contentProvider={tableContentProvider}
          preInitActionCallback={useActionCallback(
            initializeDefaultSortAction,
            [],
            $adminSparePartsProvidersState
          )}
          columnDescs={columnDescs}
          isScrollable
          isTruncable
          tableClassName="table is-bordered is-narrow"
          toolbar={toolbarConf}
          noContentPlaceholder={t('warning.emptyExistingProviders')}
        />
        <CreateSparePartsProviderModal
          $={$adminSparePartsProvidersState}
          onSparePartChangeActionCallback={onSparePartChangeActionCallback}
        />
        <ModalCardDialog
          $active={$deleteSparePartsProviderDialogState.$active}
          title={t('deleteSparePartsProviderDialog.title')}
          onOkClicked={deleteSparePartsProviderActionCallback}
        >
          <p>
            {t('deleteSparePartsProviderDialog.message', {
              providerId,
            })}
          </p>
        </ModalCardDialog>
      </>
    </DisplayContentOrPlaceholder>
  );
}
