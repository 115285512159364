import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import type { Kanban } from '../../model/typings/kanban.js';
import type { CoreFields } from '../../model/typings/repository.js';
import { locationHelpers } from './locationHelpers.js';

const VARIABLES_FROM_REPAIR_ORDER: Record<string, (repairOrder: CoreFields<Kanban>) => unknown> = {
  repairOrder: (repairOrder) => repairOrder,
  kanban: (repairOrder) => repairOrder,
  location: (repairOrder) => locationHelpers.getCurrentLocation(repairOrder) ?? '',
  customerShortName: (repairOrder) => repairOrder.customer.shortName,
  brand: (repairOrder) => repairOrder.infos.brand,
  model: (repairOrder) => repairOrder.infos.model,
  mileage: (repairOrder) => String(repairOrder.infos.mileage),
};

/**
 * Create a function from the code specified as string.
 * This function will take a repair order as its lone parameter.
 * The code specified in codeAsString will be executed within a context specified
 * using the VARIABLES_FROM_REPAIR_ORDER record
 * @param codeAsString
 * @returns function to be called on a repair order
 */
export function getFunctionFromString<K extends CoreFields<Kanban>, U>(
  codeAsString: string
): (repairOrder: K | undefined) => U | undefined {
  return (repairOrder: K | undefined): U | undefined => {
    // Fast fail
    if (!isTruthy(repairOrder) || !isTruthyAndNotEmpty(codeAsString)) {
      return undefined;
    }

    // Add a return statement if required
    const wrappedCodeAsString = codeAsString.trim().startsWith('return')
      ? codeAsString
      : `return ${codeAsString};`;

    // Create execution context from variables definition
    const context = Object.fromEntries(
      Object.entries(VARIABLES_FROM_REPAIR_ORDER).map(([key, getValue]) => [
        key,
        getValue(repairOrder),
      ])
    );

    const functionBody = `with (context) { ${wrappedCodeAsString} }`;

    try {
      // eslint-disable-next-line no-new-func
      return new Function('context', functionBody)(context) as U;
    } catch (error) {
      throw new Error(`Error creating function from string "${codeAsString}": ${error}`);
    }
  };
}
