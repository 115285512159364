import type { ActionCallback, ActionContext, State } from '@stimcar/libs-uikernel';
import type { Store } from '../../../state/typings/store.js';
import type {
  CommentsFormData,
  EditCommentsModalDialogState,
  PackageDealComments,
  SparePartComments,
} from '../typings/store.js';
import { updatePackageDealCommentsAction } from './packageDealCommentsActions.js';
import { updateSparePartCommentsAction } from './sparePartCommentsActions.js';

export type AfterCommentUpdateActionCallback<S extends State> = ActionCallback<
  Store,
  (
    context: ActionContext<Store, S>,
    packageDealComments: PackageDealComments | undefined,
    sparePartComments: SparePartComments | undefined,
    data: CommentsFormData
  ) => Promise<void>
>;

export async function onCommentsChangedAction<S extends State>(
  { actionDispatch, getState }: ActionContext<Store, EditCommentsModalDialogState>,
  afterUpdate?: AfterCommentUpdateActionCallback<S>
) {
  const {
    packageDeal,
    packageDealId,
    sparePart,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formData: { warnings, ...formData },
  } = getState();

  if (sparePart && packageDealId) {
    await actionDispatch.exec(updateSparePartCommentsAction);
  } else if (packageDeal) {
    await actionDispatch.exec(updatePackageDealCommentsAction);
  } else {
    throw new Error('Invalid state, need "sparePart" and "packageDealId" or "packageDeal"');
  }

  if (afterUpdate) {
    await actionDispatch.execCallback(afterUpdate, packageDeal, sparePart, formData);
  }
}
