import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../elements/Button.js';
import { FormWarning } from './FormWarning.js';

export interface FormButtonsProps {
  readonly onSubmitButtonClicked: () => Promise<void> | void;
  readonly onCancelButtonClicked?: () => Promise<void> | void;
  readonly submitLabel?: string;
  readonly submitIcon?: string;
  readonly cancelLabel?: string;
  readonly cancelIcon?: string;
  readonly isFullwidth?: boolean;
  readonly isReadonly?: boolean;
  readonly neverDisableSubmitButton?: boolean;
  readonly formWarning?: string;
}

export function FormButtons({
  onSubmitButtonClicked,
  onCancelButtonClicked,
  formWarning,
  neverDisableSubmitButton = false,
  submitLabel,
  cancelLabel,
  isFullwidth = false,
  isReadonly = false,
  submitIcon,
  cancelIcon,
}: FormButtonsProps): JSX.Element {
  const [t] = useTranslation('bulma');

  /**
   * If there is no 'onCancel' callback, the cancel button
   * is hidden and the submitButton's label is 'Ok' instead
   * of 'Submit'.
   */
  const validationLabel = useMemo(() => {
    if (submitLabel) {
      return submitLabel;
    }
    if (!onCancelButtonClicked) {
      return t('buttons.ok');
    }
    return t('buttons.submit');
  }, [submitLabel, onCancelButtonClicked, t]);

  const computedCancelLabel = useMemo(() => {
    if (cancelLabel) {
      return cancelLabel;
    }
    return t('buttons.cancel');
  }, [cancelLabel, t]);

  const isSubmitButtonDisabled = (): boolean => {
    if (isReadonly) {
      return true;
    }
    if (neverDisableSubmitButton) {
      return false;
    }
    return formWarning !== undefined && formWarning !== '';
  };

  const isCancelButtonDisabled = (): boolean => {
    if (isReadonly) {
      return true;
    }
    return false;
  };

  return (
    <div className="buttons">
      <Button
        additionalClass={`is-primary${isFullwidth ? ' is-fullwidth' : ''}`}
        onClick={onSubmitButtonClicked}
        disabled={isSubmitButtonDisabled()}
        iconId={submitIcon}
        label={validationLabel}
      />
      {onCancelButtonClicked && (
        <Button
          additionalClass={isFullwidth ? ' is-fullwidth' : ''}
          onClick={onCancelButtonClicked}
          disabled={isCancelButtonDisabled()}
          iconId={cancelIcon}
          label={computedCancelLabel}
        />
      )}
      <FormWarning warning={formWarning} />
    </div>
  );
}
