import { useMemo } from 'react';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import type { SimpleOption } from '@stimcar/libs-uitoolkit';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../../../state/typings/store.js';

export function useSparePartProviderIdOptions($gs: GlobalStoreStateSelector<Store>) {
  const sparepartsProviders = useGetState($gs.$sparepartsProviders);

  const sparePartsProviderOptions = useMemo((): readonly SimpleOption[] => {
    return sparepartsProviders.map((p) => {
      return {
        value: p.id,
        label: p.id,
      };
    });
  }, [sparepartsProviders]);

  return sparePartsProviderOptions;
}
