import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ScheduledTaskType } from '@stimcar/libs-base';
import type { StoreStateSelector, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import { EMAIL_SCHEDULED_TASK_TYPES, IMPORT_SCHEDULED_TASK_TYPES } from '@stimcar/libs-base';
import {
  CheckboxFormField,
  InputFormField,
  ReactSelectFormField,
  ReadOnlyInputFormField,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { ScheduledTaskIdentityData } from '../typings/store.js';

export interface ScheduledTaskIdentityInputsProps {
  readonly $formData: StoreStateSelector<
    Store,
    WithFormValidationWarnings<ScheduledTaskIdentityData>
  >;
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}
export function ScheduledTaskIdentityInputs({
  $formData,
  horizontalFormFields,
}: ScheduledTaskIdentityInputsProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const typeSuggestions = [...EMAIL_SCHEDULED_TASK_TYPES, ...IMPORT_SCHEDULED_TASK_TYPES].map(
    (cronType) => {
      return {
        value: cronType,
        label: t(`cronTypes.${cronType}`),
      };
    }
  );

  return (
    <>
      <CheckboxFormField
        label={t('active')}
        $={$formData.$scheduledTaskActive}
        horizontal={horizontalFormFields}
        style={{ verticalAlign: 'bottom' }}
      />
      <InputFormField label={t('label')} $={$formData.$label} horizontal={horizontalFormFields} />
      <ReactSelectFormField
        label={t('type')}
        suggestions={typeSuggestions}
        $={$formData.$type}
        creation={false}
        horizontal={horizontalFormFields}
        isClearable={false}
      />
    </>
  );
}

export interface ScheduledTaskIdentityInputsForUpdateProps {
  readonly $formData: StoreStateSelector<
    Store,
    WithFormValidationWarnings<Omit<ScheduledTaskIdentityData, 'type'>>
  >;
  readonly type: ScheduledTaskType;
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}
export function ScheduledTaskIdentityForUpdateInputs({
  $formData,
  type,
  horizontalFormFields,
}: ScheduledTaskIdentityInputsForUpdateProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const typeToDisplay = t(`cronTypes.${type}`);

  return (
    <>
      <CheckboxFormField
        label={t('active')}
        $={$formData.$scheduledTaskActive}
        horizontal={horizontalFormFields}
        style={{ verticalAlign: 'bottom' }}
      />
      <InputFormField label={t('label')} $={$formData.$label} horizontal={horizontalFormFields} />
      <ReadOnlyInputFormField
        label={t('type')}
        horizontal={horizontalFormFields}
        value={typeToDisplay}
      />
    </>
  );
}
