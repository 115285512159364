import type { DashboardFilter, Kanban, WithProgress } from '@stimcar/libs-base';
import { getFunctionFromString } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';

export type DashboardFilterFunction = (
  kanbans: readonly WithProgress<Kanban>[]
) => readonly WithProgress<Kanban>[];

/**
 * Returns a filter function depending on the user's choice
 * @param selectedByUserFilter
 * @param boardFiltersFromConfig
 * @returns a function which can be used to filter a list of kanbans
 */
function filterForColumnGivenUserChoice(
  selectedByUserFilter: string,
  boardFiltersFromConfig: readonly DashboardFilter[]
): DashboardFilterFunction {
  if (isTruthyAndNotEmpty(selectedByUserFilter)) {
    const selectedFilter = boardFiltersFromConfig.find(({ id }) => id === selectedByUserFilter);
    if (isTruthy(selectedFilter)) {
      const { filterFunction } = selectedFilter;
      if (isTruthyAndNotEmpty(filterFunction)) {
        return (kanbans: readonly WithProgress<Kanban>[]) => {
          const fun = getFunctionFromString<WithProgress<Kanban>, boolean>(filterFunction);
          if (isTruthy(fun)) {
            return kanbans.filter(fun);
          }
          return kanbans;
        };
      }
    }
  }
  // Do not filter anything
  return (kanbans: readonly WithProgress<Kanban>[]) => kanbans;
}

export const dashboardFiltersHelpers = {
  filterForColumnGivenUserChoice,
};
