import type { SubcontractorKanbansPerStand } from '@stimcar/libs-base';
import type { ExpandedKanbanStatuses } from '../../../app/archives/components/kanbanList/KanbanListItem.js';

export type SubcontractorSelectKanbanState = {
  readonly expandedKanbanStatuses: ExpandedKanbanStatuses;
  readonly kanbansPerStands: SubcontractorKanbansPerStand;
  readonly standOrder: readonly string[];
  readonly selectedTab: string;
  readonly searchByLicense: string;
  readonly searchBySite: string;
  readonly searchByContract: string;
};

export const SUBCONTRACTOR_SELECT_KANBAN_EMPTY_STATE: SubcontractorSelectKanbanState = {
  kanbansPerStands: {},
  standOrder: [],
  selectedTab: '',
  searchByLicense: '',
  searchBySite: '',
  searchByContract: '',
  expandedKanbanStatuses: {},
};
