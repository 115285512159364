import type {
  KnownKeysOf,
  PollerErrorHandler,
  RepositoryEntities,
  RepositoryEntityPayload,
} from '@stimcar/libs-base';
import type { KeyValueStorage } from '@stimcar/libs-kernel';
import { enumerate } from '@stimcar/libs-base';
import {
  collectObjectMethods,
  ensureSequentialMethodCalls,
  instrumentTx,
  isTruthy,
  Logger,
} from '@stimcar/libs-kernel';
import type { Database, DatabaseFactory, DatabaseTx } from '../database/typings/database.js';
import type { RepositoryHTTPClient } from '../httpclient/typings/RepositoryHTTPClient.js';
import type {
  DatabaseConfigurator,
  EntityMutationsDAO,
  EntityQueriesDAO,
} from './dao/typings/repository-dao.js';
import type { ServerPushHandler } from './ServerPushHandlerImpl.js';
import type { RepositoryStoreDesc } from './typings/repository-internals.js';
import type {
  ExtendedRepository,
  Repository,
  RepositoryEventListener,
  RepositoryExtender,
} from './typings/repository.js';
import { EntityMutationsDAOImpl } from './dao/EntityMutationsDAOImpl.js';
import { EntityQueriesDAOImpl } from './dao/EntityQueriesDAOImpl.js';
import { newDatabase } from './dao/RepositoryDAOImpl.js';
import { ServerPushHandlerImpl } from './ServerPushHandlerImpl.js';
import { BasicProviderImpl } from './typings/repository-internals.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

const getDataBaseIndexesToCreateOrDeleteMethodName: KnownKeysOf<DatabaseConfigurator> =
  'getDataBaseIndexesToCreateOrDelete';

/**
 * Repository implementation.
 */
class RepositoryImpl<ENAME extends keyof RepositoryEntities> implements Repository<ENAME> {
  private entityName: ENAME;

  private uiNotifierProvider: BasicProviderImpl<RepositoryEventListener<ENAME>>;

  private entityMutationsDAO: EntityMutationsDAO<ENAME>;

  private entityQueriesDAO: EntityQueriesDAO<ENAME>;

  private httpClient: RepositoryHTTPClient;

  private serverPushHandler: ServerPushHandler<ENAME>;

  private isClosed = false;

  public static async asyncConstructor<ENAME extends keyof RepositoryEntities>(
    entityName: ENAME,
    keyValueStorage: KeyValueStorage,
    httpClient: RepositoryHTTPClient,
    pollerErrorHandler: PollerErrorHandler,
    dbFactory: DatabaseFactory,
    configurator?: DatabaseConfigurator
  ): Promise<RepositoryImpl<ENAME>> {
    log.info(`[${entityName}] Creating repository...`);
    /**
     * Create the database here instead of the DAO because it has to be instanciated once
     * and used in both entity DAO.
     */
    const database: Database<RepositoryStoreDesc<ENAME>> = await newDatabase(
      entityName,
      dbFactory,
      configurator
    );

    const uiNotifierProvider = new BasicProviderImpl<RepositoryEventListener<ENAME>>();

    /**
     * The EntityMutationsDAO manages all entity updates. All method invocations
     * are ordered thanks to the ensureSequentialMethodCalls function in order to
     * prevent concurrent calls from updating local and / or server versions of
     * an entity in a non consistent way.
     */
    const entityMutationsDAO = ensureSequentialMethodCalls(
      instrumentTx<EntityMutationsDAO<ENAME>, DatabaseTx<RepositoryStoreDesc<ENAME>>>(
        new EntityMutationsDAOImpl<ENAME>(
          entityName,
          database,
          uiNotifierProvider,
          httpClient.getBrowserSequence()
        )
      )
    );

    /**
     * Entity queries don't need to be ordered as entity updates.
     */
    const entityQueriesDAO = instrumentTx<
      EntityQueriesDAO<ENAME>,
      DatabaseTx<RepositoryStoreDesc<ENAME>>
    >(new EntityQueriesDAOImpl(entityName, database));

    // Creates the server push
    const serverPushHandler = await ServerPushHandlerImpl.asyncConstructor(
      entityName,
      keyValueStorage,
      httpClient,
      entityQueriesDAO,
      entityMutationsDAO,
      pollerErrorHandler
    );

    return new RepositoryImpl(
      entityName,
      httpClient,
      uiNotifierProvider,
      entityMutationsDAO,
      entityQueriesDAO,
      serverPushHandler
    );
  }

  private constructor(
    entityName: ENAME,
    httpClient: RepositoryHTTPClient,
    uiNotifierProvider: BasicProviderImpl<RepositoryEventListener<ENAME>>,
    entityMutationsDAO: EntityMutationsDAO<ENAME>,
    entityQueriesDAO: EntityQueriesDAO<ENAME>,
    serverPushHandler: ServerPushHandler<ENAME>
  ) {
    this.entityName = entityName;
    this.httpClient = httpClient;
    this.uiNotifierProvider = uiNotifierProvider;
    this.entityMutationsDAO = entityMutationsDAO;
    this.entityQueriesDAO = entityQueriesDAO;
    this.serverPushHandler = serverPushHandler;
  }

  private internalWaitForEmptyActionLog = async (
    runnable: (() => Promise<void>) | undefined,
    timeout: number | undefined
  ): Promise<void> => {
    // The shutdown procedure is not trivial as we have to wait for the
    // local action log to become empty (or at least acknowledged)
    log.info('Shutdowning repository', this.entityName, '...');
    const start = Date.now();
    const doWaitForEmptyActionLog = (
      resolve: () => void,
      reject: (reason: unknown) => void
    ): void => {
      // Retrieve local action log
      this.entityQueriesDAO
        .getLocalActionsToExecute()
        .then((localActions) => {
          // It the log is empty, we can proceed with the shutdown
          if (localActions.length === 0) {
            if (isTruthy(runnable)) {
              runnable().then(resolve).catch(reject);
            } else {
              resolve();
            }
          }
          // Otherwise check the timeout
          else if (timeout && Date.now() - start > timeout) {
            reject(
              `Timeout exception, the local action log still contains ${
                localActions.length
              } action(s) : ${enumerate(localActions.map((a): string => a.id))}`
            );
          }
          // Otherwise wait 100 ms
          else {
            log.info(
              'Local action log not empty :',
              localActions.map((a): string => a.id),
              '; shutdown delayed, waiting for poller...'
            );
            setTimeout((): void => doWaitForEmptyActionLog(resolve, reject), 500);
          }
        })
        .catch((reason: unknown) => reject(reason));
    };
    return new Promise<void>(doWaitForEmptyActionLog);
  };

  public waitForEmptyActionLog = async (timeout?: number): Promise<void> => {
    return this.internalWaitForEmptyActionLog(undefined, timeout);
  };

  public shutdown = async (timeout?: number): Promise<void> => {
    const runnable = async (): Promise<void> => {
      await this.serverPushHandler.shutdown();
      this.uiNotifierProvider.set(null);
      this.isClosed = true;
      log.debug('Repository', this.entityName, 'shut down');
    };

    return this.internalWaitForEmptyActionLog(runnable, timeout);
  };

  public isShutdown = (): boolean => {
    return this.isClosed;
  };

  // TODO replace this injection by a shared context among ServerPush & UIServices
  public registerUINotifier = (notifier: RepositoryEventListener<ENAME>): void => {
    this.uiNotifierProvider.set(notifier);
  };

  public async createEntity(entity: RepositoryEntities[ENAME]): Promise<RepositoryEntities[ENAME]> {
    this.checkBrowserRegistered();
    return this.entityMutationsDAO.createEntity(entity);
  }

  public async closeEntity(id: string): Promise<void> {
    this.checkBrowserRegistered();
    return this.entityMutationsDAO.closeEntity(id);
  }

  public async archiveEntity(id: string): Promise<void> {
    this.checkBrowserRegistered();
    return this.entityMutationsDAO.archiveEntity(id);
  }

  public async createEntities(
    ...entities: readonly RepositoryEntities[ENAME][]
  ): Promise<readonly RepositoryEntities[ENAME][]> {
    this.checkBrowserRegistered();
    return this.entityMutationsDAO.createEntities(...entities);
  }

  public async updateEntity(entity: RepositoryEntities[ENAME]): Promise<void> {
    this.checkBrowserRegistered();
    await this.entityMutationsDAO.updateEntity(entity);
  }

  public async updateEntities(...entities: readonly RepositoryEntities[ENAME][]): Promise<void> {
    this.checkBrowserRegistered();
    await this.entityMutationsDAO.updateEntities(...entities);
  }

  public createId(): string {
    this.checkBrowserRegistered();
    return this.httpClient.getBrowserSequence().next();
  }

  public async updateEntityFromPayload(
    payload: RepositoryEntityPayload<RepositoryEntities[ENAME]>
  ): Promise<RepositoryEntities[ENAME]> {
    this.checkBrowserRegistered();
    return this.entityMutationsDAO.updateEntityFromPayload(payload);
  }

  public async updateEntitiesFromPayloads(
    ...payloads: RepositoryEntityPayload<RepositoryEntities[ENAME]>[]
  ): Promise<readonly RepositoryEntities[ENAME][]> {
    this.checkBrowserRegistered();
    return this.entityMutationsDAO.updateEntitiesFromPayloads(...payloads);
  }

  public async getAllEntities(
    partitionKey?: string
  ): Promise<readonly RepositoryEntities[ENAME][]> {
    this.checkBrowserRegistered();
    return this.entityQueriesDAO.getAllEntities('open', partitionKey);
  }

  public async getArchivedEntities(
    partitionKey?: string
  ): Promise<readonly RepositoryEntities[ENAME][]> {
    this.checkBrowserRegistered();
    return this.entityQueriesDAO.getAllEntities('archived', partitionKey);
  }

  public async getEntities(...ids: string[]): Promise<readonly RepositoryEntities[ENAME][]> {
    this.checkBrowserRegistered();
    return this.entityQueriesDAO.getEntities(ids);
  }

  public async getEntitiesFromIndex(
    index: string,
    value: string | number | boolean
  ): Promise<readonly RepositoryEntities[ENAME][]> {
    this.checkBrowserRegistered();
    return this.entityQueriesDAO.getEntitiesFromIndex(index, value);
  }

  public async getEntity(id: string): Promise<RepositoryEntities[ENAME]> {
    this.checkBrowserRegistered();
    return this.entityQueriesDAO.getEntity(id);
  }

  public async hasEntity(id: string): Promise<boolean> {
    this.checkBrowserRegistered();
    return this.entityQueriesDAO.hasEntity(id);
  }

  public async getLocalChangesCount(): Promise<number> {
    return this.entityQueriesDAO.getLocalActionsCount();
  }

  public extend<E extends object>(
    extender: RepositoryExtender<ENAME, E> & DatabaseConfigurator
  ): ExtendedRepository<ENAME, E> {
    const repositoryMethods = collectObjectMethods(this);
    const wrapper = {};
    repositoryMethods.forEach(({ propertyKey, method }): void => {
      Reflect.set(wrapper, propertyKey, method.bind(this));
    });
    const extenderMethods = collectObjectMethods(extender);
    extenderMethods.forEach(({ propertyKey, method }): void => {
      // Do not instrument method coming from DatabaseConfigurator
      if (propertyKey !== getDataBaseIndexesToCreateOrDeleteMethodName) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const override = async (...args: any): Promise<any> => {
          this.checkBrowserRegistered();
          const result = await Reflect.apply(method, extender, [
            this.entityQueriesDAO,
            this.entityMutationsDAO,
            ...args,
          ]);
          return result;
        };
        Reflect.set(wrapper, propertyKey, override);
      }
    });
    return wrapper as ExtendedRepository<ENAME, E>;
  }

  private checkBrowserRegistered = (): void => {
    if (!this.httpClient.isBrowserRegistered()) {
      throw Error('This repository is not ready, please register this HTTP client first');
    }
  };
}

/**
 * Creates a repository instance.
 *
 * @param entityName the entity type identifier.
 * @param httpClient the HTTP Client.
 * @param inMemoryDatabase tells whether the in memory database must be used (testing purpose).
 */
export async function newRepository<ENAME extends keyof RepositoryEntities>(
  entityName: ENAME,
  keyValueStorage: KeyValueStorage,
  httpClient: RepositoryHTTPClient,
  pollerErrorHandler: PollerErrorHandler,
  dbFactory: DatabaseFactory
): Promise<Repository<ENAME>> {
  return RepositoryImpl.asyncConstructor(
    entityName,
    keyValueStorage,
    httpClient,
    pollerErrorHandler,
    dbFactory
  );
}

/**
 * Creates a repository instance.
 *
 * @param entityName the entity type identifier.
 * @param httpClient the HTTP Client.
 * @param repositoryExtender behavior extension for the repository.
 * Can be used to add indexes to the repository database or to add additional methods.
 * @param inMemoryDatabase tells whether the in memory database must be used (testing purpose).
 */
export async function newExtendedRepository<
  ENAME extends keyof RepositoryEntities,
  E extends object,
>(
  entityName: ENAME,
  keyValueStorage: KeyValueStorage,
  httpClient: RepositoryHTTPClient,
  pollerErrorHandler: PollerErrorHandler,
  dbFactory: DatabaseFactory,
  repositoryExtender: RepositoryExtender<ENAME, E> & DatabaseConfigurator
): Promise<ExtendedRepository<ENAME, E>> {
  const repository = await RepositoryImpl.asyncConstructor(
    entityName,
    keyValueStorage,
    httpClient,
    pollerErrorHandler,
    dbFactory,
    repositoryExtender
  );
  return repository.extend(repositoryExtender);
}
