import React from 'react';
import { useTranslation } from 'react-i18next';
import type { SparePartsProvider } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { CheckFormFieldContentActions } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { InputFormField, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type {
  AdminSparePartsProvidersState,
  CreateSparePartsProviderData,
  CreateSparePartsProviderDialogState,
  OnSparePartProviderChangeActionCallback,
} from './typings/store.js';
import { EMPTY_CREATE_SPAREPARTS_PROVIDER_DIALOG_STATE } from './typings/store.js';

const HORIZONTAL_LABEL_FLEX_GROW = 1;
const HORIZONTAL_BODY_FLEX_GROW = 5;

export function openCreateSparePartsProviderModalAction({
  actionDispatch,
}: ActionContext<Store, CreateSparePartsProviderDialogState>): void {
  actionDispatch.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial: CreateSparePartsProviderDialogState): CreateSparePartsProviderDialogState => {
      return {
        ...EMPTY_CREATE_SPAREPARTS_PROVIDER_DIALOG_STATE,
        active: true,
      };
    }
  );
}

async function createSparePartsProviderAction(
  {
    getState,
    httpClient,
    actionDispatch,
    getGlobalState,
  }: ActionContext<Store, AdminSparePartsProvidersState>,
  onSparePartChangeActionCallback: OnSparePartProviderChangeActionCallback
): Promise<void> {
  const { site } = nonnull(getGlobalState().session.infos);
  const { formData } = getState().createSparePartsProviderDialogState;
  const { id } = formData;
  const providerToCreate: SparePartsProvider = {
    id,
  };
  await httpClient.httpPostAsJSON(CoreBackendRoutes.CREATE_SPAREPARTS_PROVIDER, {
    siteId: site.id,
    providerToCreate,
  });
  actionDispatch.setProperty(
    'createSparePartsProviderDialogState',
    EMPTY_CREATE_SPAREPARTS_PROVIDER_DIALOG_STATE
  );
  await actionDispatch.execCallback(onSparePartChangeActionCallback, [providerToCreate], []);
}

const checkFieldContentActions: CheckFormFieldContentActions<
  Store,
  CreateSparePartsProviderDialogState,
  [readonly SparePartsProvider[]]
> = {
  id: ({ value, t }, existingProviders): string | undefined => {
    const existingIds = existingProviders.map((p) => p.id);
    if (existingIds.includes(value)) {
      return t('warning.idAlreadyExists', { providerId: value });
    }
    return undefined;
  },
};

const mandatoryFields: (keyof CreateSparePartsProviderData)[] = ['id'];

interface EditSparePartsProviderModalProps {
  readonly $: StoreStateSelector<Store, AdminSparePartsProvidersState>;
  readonly onSparePartChangeActionCallback: OnSparePartProviderChangeActionCallback;
}

export function CreateSparePartsProviderModal({
  $,
  onSparePartChangeActionCallback,
}: EditSparePartsProviderModalProps): JSX.Element {
  const [t] = useTranslation('adminSparePartsProviders');
  const { $createSparePartsProviderDialogState } = $;
  const existingProviders = useGetState($.$existingProviders);
  const formWarning = useGetState($createSparePartsProviderDialogState.$formWarning);
  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(createSparePartsProviderAction, onSparePartChangeActionCallback);
    },
    [onSparePartChangeActionCallback],
    $
  );
  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    CreateSparePartsProviderDialogState,
    [readonly SparePartsProvider[]]
  >(
    {
      $: $createSparePartsProviderDialogState,
      mandatoryFields,
      checkFieldContentActions,
      checkFormConsistencyAction: undefined,
      submitValidDataAction,
      t,
    },
    existingProviders
  );

  return (
    <ModalCardDialog
      $active={$createSparePartsProviderDialogState.$active}
      titleIconId="user-plus"
      title={t('createSparePartsProviderDialog.title')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <InputFormField
        label={t('formData.id')}
        placeholder={t('formData.id')}
        $={$formDataWithChangeTrigger.$id}
        horizontal={{
          labelFlexGrow: HORIZONTAL_LABEL_FLEX_GROW,
          bodyFlexGrow: HORIZONTAL_BODY_FLEX_GROW,
        }}
      />
    </ModalCardDialog>
  );
}
