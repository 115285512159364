import React, { useCallback, useMemo } from 'react';
import type { AddOnButton } from '../AddOnButtons.js';
import { AddOnButtons } from '../AddOnButtons.js';

const ELLIPSIS_BUTTON_1: AddOnButton = {
  id: '...#1',
  label: '...',
  disabled: true,
};

const ELLIPSIS_BUTTON_2: AddOnButton = {
  ...ELLIPSIS_BUTTON_1,
  id: '...#2',
};

export type PaginationProps = {
  readonly gotoPage: (page: number) => Promise<void>;
  readonly pageCount: number;
  readonly activePage: number;
  readonly maxPageButtons: number;
};

export function Pagination({
  gotoPage,
  pageCount,
  activePage,
  maxPageButtons: initialMaxPageButtons,
}: PaginationProps): JSX.Element {
  const pageButtons = useMemo((): readonly AddOnButton[] => {
    // page buttons count cannot be smaller than 5
    // and the pagination is better with an odd number
    // (otherwise the active page is not perfectly centered)
    let maxPageButtons = initialMaxPageButtons < 5 ? 5 : initialMaxPageButtons;
    if (maxPageButtons % 2 === 0) {
      maxPageButtons += 1;
    }
    const pageButton = (page: number): AddOnButton => {
      return {
        id: String(page),
        additionalClass: activePage === page ? 'is-primary' : undefined,
        disabled: activePage === page,
      };
    };
    if (pageCount <= 1) {
      return [];
    }
    const buttons: AddOnButton[] = [];
    if (pageCount <= maxPageButtons) {
      for (let i = 1; i <= pageCount; i += 1) {
        buttons.push(pageButton(i));
      }
    } else {
      const middle = Math.floor(maxPageButtons / 2) + 1;
      buttons.push(pageButton(1));
      if (activePage <= middle) {
        for (let i = 2; i < maxPageButtons - 1; i += 1) {
          buttons.push(pageButton(i));
        }
        buttons.push(ELLIPSIS_BUTTON_1);
      } else if (activePage >= pageCount - middle + 2) {
        buttons.push(ELLIPSIS_BUTTON_1);
        for (let i = pageCount - maxPageButtons + 3; i < pageCount; i += 1) {
          buttons.push(pageButton(i));
        }
      } else {
        buttons.push(ELLIPSIS_BUTTON_1);
        for (let i = activePage - middle + 3; i < activePage + middle - 2; i += 1) {
          buttons.push(pageButton(i));
        }
        buttons.push(ELLIPSIS_BUTTON_2);
      }
      buttons.push(pageButton(pageCount));
    }
    return [
      {
        id: 'PREV',
        iconId: 'step-backward',
        disabled: activePage <= 1,
      },
      ...buttons,
      {
        id: 'NEXT',
        iconId: 'step-forward',
        disabled: activePage >= pageCount,
      },
    ];
  }, [activePage, initialMaxPageButtons, pageCount]);

  const doGotoPage = useCallback(
    async (boutonId: string): Promise<void> => {
      switch (boutonId) {
        case 'NEXT':
          await gotoPage(activePage + 1);
          break;
        case 'PREV':
          await gotoPage(activePage - 1);
          break;
        default:
          await gotoPage(Number.parseInt(boutonId, 10));
      }
    },
    [activePage, gotoPage]
  );

  return <AddOnButtons buttons={pageButtons} onClick={doGotoPage} isRounded isSmall size="small" />;
}
