import {
  EMPTY_FORM_WITH_VALIDATION_STATE,
  EMPTY_RESET_DIALOG_STATE,
} from '@stimcar/libs-uitoolkit';
import { WORKSHOP_IMPLANTATION_VIEW_EMPTY_STATE } from '../../lib/components/workshop/typings/store.js';
import { EMPTY_EDIT_PASSWORD_DIALOG_STATE } from '../../registeredapp/components/authentication/typings/store.js';
import { EMPTY_REGISTERED_APP_STORE_STATE } from '../../registeredapp/state/store.js';
import {
  EMPTY_ADMIN_EXPORT_MARKETPLACE_INVOICING_DIALOG_STATE,
  EMPTY_ADMIN_INVOICES_REMINDER_DIALOG_STATE,
} from '../admin/accounting/typings/store.js';
import { EMPTY_ADMIN_CAR_ELEMENTS_STATE } from '../admin/carElement/typings/store.js';
import { EMPTY_CONTRACTS_ADMIN_STATE } from '../admin/contract/typings/store.js';
import { EMPTY_ADMIN_CUSTOMERS_STATE } from '../admin/customer/typings/store.js';
import { EMPTY_ADMIN_KANBANS_IMPORT_STATE } from '../admin/kanbansImport/typings/store.js';
import { EMPTY_ADMIN_PACKAGE_DEAL_DESCS_STATE } from '../admin/packageDealDesc/typings/store.js';
import { EMPTY_ADMIN_SCHEDULED_TASKS_STATE } from '../admin/scheduledTasks/typings/store.js';
import { EMPTY_ADMIN_SHIFT_PARAMETERS_CONFIGURATION_DIALOG_STATE } from '../admin/shiftParameters/typings/store.js';
import { EMPTY_ADMIN_SPAREPARTS_PROVIDERS_STATE } from '../admin/sparePartsProvider/typings/store.js';
import { EMPTY_USERS_ADMIN_STATE } from '../admin/users/typings/store.js';
import { EMPTY_ADMIN_WORKFLOW_STATE } from '../admin/workflow/typings/store.js';
import { ARCHIVES_VIEW_EMPTY_STATE } from '../archives/typings/store.js';
import { EMPTY_EDIT_COMMENTS_MODAL_DIALOG_STATE } from '../commons/comments/typings/store.js';
import { CREATE_KANBAN_VIEW_EMPTY_STATE } from '../creation/typings/store.js';
import { EMPTY_KANBAN_DETAILS_STATE } from '../details/typings/store.js';
import { DEFAULT_DASHBOARD_FILTER_ID, EMPTY_DISPLAY_VIEW_STATE } from '../display/typings/store.js';
import { EMPTY_OPERATOR_VIEW_STATE } from '../operators/typings/store.js';
import { SELECT_KANBAN_EMPTY_STATE } from '../selectKanban/typings/store.js';
import { EMPTY_WORKSHOP_POST_VIEW_STATE } from '../workshop/post/typings/store.js';
import type {
  NavBarState,
  StartKanbanHandlingWarningModalState,
  StoreState,
} from './typings/store.js';

export const START_KANBAN_HANDLING_WARNING_MODAL_EMPTY_STATE: StartKanbanHandlingWarningModalState =
  {
    isActive: false,
    kanban: undefined,
    requestedOnStand: '',
    standsExpectedAt: [],
    alreadyHandledOnPosts: [],
  };

export const EMPTY_NAVBAR_STATE: NavBarState = {
  burgerIsActive: false,
  implantationMenuIsActive: false,
  adminMenuIsActive: false,
  userMenuIsActive: false,
  resetDialogState: EMPTY_RESET_DIALOG_STATE,
  handledKanban: undefined,
  detailKanban: undefined,
  showPossibleDataLossModal: false,
  switchToRoleDialogState: {
    active: false,
    ...EMPTY_FORM_WITH_VALIDATION_STATE,
    formData: {
      newRole: '',
      newStandId: '',
      warnings: {},
      actionToPerformOnRoleChange: 'pause',
    },
  },
  logoutDialogState: {
    active: false,
    ...EMPTY_FORM_WITH_VALIDATION_STATE,
    formData: {
      warnings: {},
      actionToPerformOnLogout: 'close',
    },
  },
  updatePasswordDialogState: EMPTY_EDIT_PASSWORD_DIALOG_STATE,
};

// Empty state
export const EMPTY_STORE_STATE: StoreState = {
  ...EMPTY_REGISTERED_APP_STORE_STATE,
  preventNavigation: false,
  showPreventNavigationModal: false,
  startKanbanHandlingWarningModal: START_KANBAN_HANDLING_WARNING_MODAL_EMPTY_STATE,
  workshopImplantationView: WORKSHOP_IMPLANTATION_VIEW_EMPTY_STATE,
  detailKanbanId: undefined,
  handledKanbanId: undefined,
  detailsView: EMPTY_KANBAN_DETAILS_STATE,
  creationView: CREATE_KANBAN_VIEW_EMPTY_STATE,
  selectKanbanView: SELECT_KANBAN_EMPTY_STATE,
  operatorView: EMPTY_OPERATOR_VIEW_STATE,
  archivesView: ARCHIVES_VIEW_EMPTY_STATE,
  navbar: EMPTY_NAVBAR_STATE,
  adminView: {
    adminCarElements: EMPTY_ADMIN_CAR_ELEMENTS_STATE,
    adminCustomers: EMPTY_ADMIN_CUSTOMERS_STATE,
    adminWorkflows: EMPTY_ADMIN_WORKFLOW_STATE,
    adminPackageDealDescs: EMPTY_ADMIN_PACKAGE_DEAL_DESCS_STATE,
    adminKanbansImport: EMPTY_ADMIN_KANBANS_IMPORT_STATE,
    adminUsers: EMPTY_USERS_ADMIN_STATE,
    adminWorkshopImplantation: WORKSHOP_IMPLANTATION_VIEW_EMPTY_STATE,
    adminScheduledTasks: EMPTY_ADMIN_SCHEDULED_TASKS_STATE,
    adminContracts: EMPTY_CONTRACTS_ADMIN_STATE,
    adminSparePartsProvidersState: EMPTY_ADMIN_SPAREPARTS_PROVIDERS_STATE,
    adminShiftProgressDisplay: EMPTY_ADMIN_SHIFT_PARAMETERS_CONFIGURATION_DIALOG_STATE,
    adminExportMarketplaceInvoicing: EMPTY_ADMIN_EXPORT_MARKETPLACE_INVOICING_DIALOG_STATE,
    adminInvoicesReminder: EMPTY_ADMIN_INVOICES_REMINDER_DIALOG_STATE,
  },
  contracts: [],
  sparePartsProviders: [],
  displayView: EMPTY_DISPLAY_VIEW_STATE,
  workshopPostView: EMPTY_WORKSHOP_POST_VIEW_STATE,
  notifications: [],
  uiPreferencesState: {
    selectedDashboardFilterId: DEFAULT_DASHBOARD_FILTER_ID,
  },
  editCommentsModalState: EMPTY_EDIT_COMMENTS_MODAL_DIALOG_STATE,
};
