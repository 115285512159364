import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ConfirmAttachmentRemovalDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { OperatorExpertViewState, OperatorViewState } from '../../typings/store.js';
import { appendRegisteredBrowserSessionToken } from '../../../utils/security.js';
import { useComputeAttachmentUrl } from '../../../utils/useComputeAttachmentUrl.js';
import { ExpertComponentView } from './ExpertComponentView.js';

function initializeExpertiseComponent(
  { actionDispatch, keyValueStorage }: ActionContext<Store, OperatorExpertViewState>,
  kanbanId: string
): void {
  const storedState = keyValueStorage.getObjectItem<OperatorExpertViewState>(
    LocalStorageKeys.DESKTOP_EXPERTISE_STATE_DUMP
  );
  if (isTruthy(storedState)) {
    if (storedState.initialKanban.id === kanbanId) {
      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          ...storedState,
        };
      });
    }
    keyValueStorage.removeItem(LocalStorageKeys.DESKTOP_EXPERTISE_STATE_DUMP);
  }
}

const removeAttachmentAction = async ({
  actionDispatch,
  getState,
  httpClient,
  getGlobalState,
}: // eslint-disable-next-line @typescript-eslint/require-await
ActionContext<Store, OperatorViewState>): Promise<void> => {
  const { operatedKanban, expertOperatorState } = getState();
  const { attachmentsTab } = expertOperatorState;
  if (!isTruthy(operatedKanban)) {
    throw Error('No kanban are selected');
  }
  const { confirmAttachmentRemovalDialog, attachments } = attachmentsTab;
  const { folder, name, id } = confirmAttachmentRemovalDialog;
  await httpClient.httpGet(
    appendRegisteredBrowserSessionToken(
      CoreBackendRoutes.ATTACHMENT('kanban', operatedKanban.id, folder, name),
      nonnull(getGlobalState().session.infos).sessionToken
    ),
    'DELETE'
  );
  actionDispatch
    .scopeProperty('expertOperatorState')
    .scopeProperty('attachmentsTab')
    .scopeProperty('confirmAttachmentRemovalDialog')
    .setProperty('active', false);
  actionDispatch
    .scopeProperty('expertOperatorState')
    .scopeProperty('attachmentsTab')
    .setProperty(
      'attachments',
      attachments.filter(
        ({ id: currentId, folder: currentFolder }) => currentId !== id || currentFolder !== folder
      )
    );
};

interface Props extends AppProps<Store> {
  readonly standId: string;
}

export function ExpertComponent({ standId, $gs }: Props): JSX.Element {
  const [t] = useTranslation('operators');
  const { $operatorView } = $gs;
  const { $expertOperatorState } = $operatorView;

  const operatedKanban = useGetState($operatorView.$operatedKanban);

  const computeAttachmentThumbnailUrl = useComputeAttachmentUrl($gs);

  const initialKanbanId = useGetState($expertOperatorState.$initialKanban.$id);

  const asyncEffect = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(initializeExpertiseComponent, initialKanbanId);
    },
    [initialKanbanId],
    $expertOperatorState
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  return (
    <>
      <ExpertComponentView standId={standId} $gs={$gs} />
      <ConfirmAttachmentRemovalDialog
        category="kanban"
        objectId={nonnull(operatedKanban).id}
        $={$expertOperatorState.$attachmentsTab.$confirmAttachmentRemovalDialog}
        okLabel={t('expertiseView.confirmAttachmentRemovalDialog.okLabel')}
        onOkClicked={useActionCallback(removeAttachmentAction, [], $operatorView)}
        computeAttachmentUrl={computeAttachmentThumbnailUrl}
      >
        <p>{t('expertiseView.confirmAttachmentRemovalDialog.message')}</p>
      </ConfirmAttachmentRemovalDialog>
    </>
  );
}
