import React from 'react';
import { MultilineValueTableCellContent } from '@stimcar/libs-uitoolkit';

interface DisplaySparePartCommentProps {
  readonly prefix: string;
  readonly value: string;
}

export function DisplaySparePartComment({
  prefix,
  value,
}: DisplaySparePartCommentProps): JSX.Element {
  return (
    <p>
      <span className="has-text-weight-bold">{`${prefix} : `}</span>
      <MultilineValueTableCellContent value={value} />
    </p>
  );
}
