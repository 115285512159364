import type {
  EventSourceFactory,
  MetricsRegistry,
  PollerErrorHandler,
  ServerMessageListener,
} from '@stimcar/libs-base';
import type { Fetch, FormDataFactory, KeyValueStorage } from '@stimcar/libs-kernel';
import { wrapFetchWithMetrics } from '@stimcar/libs-base';
import { keysOf, Logger } from '@stimcar/libs-kernel';
import type { DatabaseFactory } from '../database/typings/database.js';
import type { RepositoryHTTPClient } from '../httpclient/typings/RepositoryHTTPClient.js';
import type { MattermostLogger } from '../mattermostLogger/typings/MattermostLogger.js';
import type { CarElementRepository } from '../repositoryExtenders/carElement/typings/carElementExtender.js';
import type { CustomerRepository } from '../repositoryExtenders/customer/typings/customersExtender.js';
import type { KanbanRepository } from '../repositoryExtenders/kanban/typings/kanbanExtender.js';
import type { PackageDealDescRepository } from '../repositoryExtenders/packageDealDesc/typings/packageDealDescExtender.js';
import { RepositoryHTTPClientImpl } from '../httpclient/RepositoryHTTPClientImpl.js';
import { MattermostLoggerImpl } from '../mattermostLogger/MattermostLoggerImpl.js';
import { newCarElementRepository } from '../repositoryExtenders/carElement/CarElementExtenderImpl.js';
import { newCustomerRepository } from '../repositoryExtenders/customer/CustomerExtenderImpl.js';
import { newKanbanRepository } from '../repositoryExtenders/kanban/KanbanExtenderImpl.js';
import { newPackageDealDescRepository } from '../repositoryExtenders/packageDealDesc/PackageDealDescExtenderImpl.js';
import type {
  Environment,
  EnvironmentResetListener,
  LightEnvironment,
  UnexpectedRepositoryErrorsListener,
} from './typings/environments.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

const DEFAULT_UNEXPECTED_REPOSITORY_ERRORS_LISTENER: UnexpectedRepositoryErrorsListener =
  // eslint-disable-next-line @typescript-eslint/require-await
  async (unexpectedServerPushMessageErrors: readonly Error[]): Promise<void> => {
    if (unexpectedServerPushMessageErrors.length > 0) {
      log.error('Unexpected error in server repository:', ...unexpectedServerPushMessageErrors);
      throw unexpectedServerPushMessageErrors[0];
    }
  };

const DEFAULT_REPOSITORY_POLLER_ERROR_HANDLER: PollerErrorHandler =
  // eslint-disable-next-line @typescript-eslint/require-await
  async (error: Error): Promise<void> => {
    log.error('Unexpected error in server repository:', error);
  };

const COMMON_KEYS_TO_KEEP_IN_SOFT_RESET: readonly string[] = [
  'userSessionData',
  'browserSessionData',
  'environmentSiteUrl',
];

class EnvironmentImpl<IS_LIGHT_ENVIRONMENT_ENVIRONMENT extends boolean = false>
  implements Environment
{
  private baseUrl: string | undefined;

  private keyValueStorage: KeyValueStorage;

  private dbFactory: DatabaseFactory;

  private fetch: Fetch;

  private formDataFactory: FormDataFactory;

  private evFactory: EventSourceFactory;

  private metricsRegistry: MetricsRegistry;

  private httpClient: RepositoryHTTPClient | undefined;

  private loggerClient: MattermostLogger;

  private serverMessageListeners?:
    | Record<string, ServerMessageListener>
    | ((
        env: IS_LIGHT_ENVIRONMENT_ENVIRONMENT extends false ? Environment : LightEnvironment
      ) => Record<string, ServerMessageListener>);

  /**
   * To ensure that the same repository will be shared by all environment callers, the promise
   * is kept instead of the instance in order not to create one repository by caller
   * during repository bootstrap.
   */
  private kanbanRepository: Promise<KanbanRepository> | undefined;

  /**
   * To ensure that the same repository will be shared by all environment callers, the promise
   * is kept instead of the instance in order not to create one repository by caller
   * during repository bootstrap.
   */
  private packageDealDescRepository: Promise<PackageDealDescRepository> | undefined;

  /**
   * To ensure that the same repository will be shared by all environment callers, the promise
   * is kept instead of the instance in order not to create one repository by caller
   * during repository bootstrap.
   */
  private carElementRepository: Promise<CarElementRepository> | undefined;

  /**
   * To ensure that the same repository will be shared by all environment callers, the promise
   * is kept instead of the instance in order not to create one repository by caller
   * during repository bootstrap.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private customerRepository: Promise<CustomerRepository> | undefined;

  private repositoryErrorsListener: UnexpectedRepositoryErrorsListener;

  private repositoryPollerErrorHandler: PollerErrorHandler;

  private resetListeners: EnvironmentResetListener[] = [];

  private softResetLocalStorageKeysToKeep: readonly string[];

  private isClosed = false;

  private lightMode: boolean;

  private autoStartSSE: boolean;

  constructor(
    keyValueStorage: KeyValueStorage,
    dbFactory: DatabaseFactory,
    fetch: Fetch,
    formDataFactory: FormDataFactory,
    evFactory: EventSourceFactory,
    metricsRegistry: MetricsRegistry,
    localStorageKeysToKeepDuringSoftReset: readonly string[],
    baseUrl?: string,
    repositoryPollerErrorHandler: PollerErrorHandler = DEFAULT_REPOSITORY_POLLER_ERROR_HANDLER,
    repositoryErrorsListener: UnexpectedRepositoryErrorsListener = DEFAULT_UNEXPECTED_REPOSITORY_ERRORS_LISTENER,
    serverMessageListeners?:
      | Record<string, ServerMessageListener>
      | ((
          env: IS_LIGHT_ENVIRONMENT_ENVIRONMENT extends false ? Environment : LightEnvironment
        ) => Record<string, ServerMessageListener>),
    lightMode = false,
    autoStartSSE = true
  ) {
    this.keyValueStorage = keyValueStorage;
    this.dbFactory = dbFactory;
    this.fetch = metricsRegistry ? wrapFetchWithMetrics(fetch, metricsRegistry) : fetch;
    this.formDataFactory = formDataFactory;
    this.evFactory = evFactory;
    this.metricsRegistry = metricsRegistry;
    this.repositoryPollerErrorHandler = repositoryPollerErrorHandler;
    this.repositoryErrorsListener = repositoryErrorsListener;
    this.baseUrl = baseUrl;
    this.softResetLocalStorageKeysToKeep = [
      ...COMMON_KEYS_TO_KEEP_IN_SOFT_RESET,
      ...localStorageKeysToKeepDuringSoftReset,
    ];
    this.serverMessageListeners = serverMessageListeners;
    this.lightMode = lightMode;
    this.autoStartSSE = autoStartSSE;
    this.loggerClient = this.getLoggerClient();
  }

  public registerResetListener(listener: EnvironmentResetListener): void {
    this.resetListeners.push(listener);
  }

  public async reset(hard?: boolean): Promise<void> {
    log.info('Reset environment', typeof hard, hard);
    // Reset local storage data at first
    if (hard) {
      // Hard reset (remove all items)
      this.keyValueStorage.clear();
    } else {
      // Soft reset (keep session information)
      // Collect keys to remove
      const size = this.keyValueStorage.size();
      const keysToRemove: string[] = [];
      for (let index = 0; index < size; index += 1) {
        const key = this.keyValueStorage.key(index);
        if (key !== null && !this.softResetLocalStorageKeysToKeep.includes(key)) {
          keysToRemove.push(this.keyValueStorage.key(index)!);
        }
      }
      // Remove keys
      keysToRemove.forEach((key) => {
        log.info('Remove', key, 'from key value storage');
        this.keyValueStorage.removeItem(key);
      });
    }
    // Shutdown repositories + http client
    await this.shutdownComponents();
    // Reset repository data
    await this.dbFactory.removeAllData();
    await Promise.all(
      this.resetListeners.map(async (listener: EnvironmentResetListener): Promise<void> => {
        await listener(hard);
      })
    );
  }

  public async getCustomerRepository(): Promise<CustomerRepository> {
    if (this.lightMode) {
      throw new Error(`Customer repository is not available in light mode`);
    }
    this.checkIsClosed();
    if (!this.customerRepository) {
      log.info('Create CustomerRepository');
      this.customerRepository = newCustomerRepository(
        this.keyValueStorage,
        this.getHttpClient(),
        this.repositoryPollerErrorHandler,
        this.dbFactory
      );
      (await this.customerRepository).registerUINotifier(this.repositoryErrorsListener);
    }
    return this.customerRepository;
  }

  public async getKanbanRepository(): Promise<KanbanRepository> {
    this.checkIsClosed();
    if (!this.kanbanRepository) {
      log.info('Create KanbanRepository');
      this.kanbanRepository = newKanbanRepository(
        this.keyValueStorage,
        this.getHttpClient(),
        this.repositoryPollerErrorHandler,
        this.dbFactory
      );
      (await this.kanbanRepository).registerUINotifier(this.repositoryErrorsListener);
    }
    return this.kanbanRepository;
  }

  public async getPackageDealDescRepository(): Promise<PackageDealDescRepository> {
    if (this.lightMode) {
      throw new Error(`Package Deal repository is not available in light mode`);
    }
    this.checkIsClosed();
    if (!this.packageDealDescRepository) {
      log.info('Create PackageDealDescRepository');
      this.packageDealDescRepository = newPackageDealDescRepository(
        this.keyValueStorage,
        this.getHttpClient(),
        this.repositoryPollerErrorHandler,
        this.dbFactory
      );
      (await this.packageDealDescRepository).registerUINotifier(this.repositoryErrorsListener);
    }
    return this.packageDealDescRepository;
  }

  public async getCarElementRepository(): Promise<CarElementRepository> {
    if (this.lightMode) {
      throw new Error(`Car element repository is not available in light mode`);
    }
    if (!this.carElementRepository) {
      log.info('Create CarElementRepository');
      this.carElementRepository = newCarElementRepository(
        this.keyValueStorage,
        this.getHttpClient(),
        this.repositoryPollerErrorHandler,
        this.dbFactory
      );
      (await this.carElementRepository).registerUINotifier(this.repositoryErrorsListener);
    }
    return this.carElementRepository;
  }

  public getHttpClient(): RepositoryHTTPClient {
    this.checkIsClosed();
    if (!this.httpClient) {
      log.info('Create HTTPClient');
      const httpClient = new RepositoryHTTPClientImpl(
        this.keyValueStorage,
        this.fetch,
        this.formDataFactory,
        this.evFactory,
        this.baseUrl
      );
      // Register additional message listeners
      const { serverMessageListeners } = this;
      if (serverMessageListeners) {
        // The server message listeners can be provided as a factory instead of
        // a static object
        const finalServerMessageListeners: Record<string, ServerMessageListener> =
          typeof serverMessageListeners === 'function'
            ? serverMessageListeners(this)
            : serverMessageListeners;
        keysOf(finalServerMessageListeners).forEach((id) => {
          httpClient.registerServerMessageListener(id, finalServerMessageListeners[id]);
        });
      }
      if (this.autoStartSSE && httpClient.isBrowserRegistered()) {
        httpClient.startSSE();
      }
      this.httpClient = httpClient;
    }
    return this.httpClient;
  }

  public getLoggerClient(): MattermostLogger {
    if (!this.loggerClient) {
      const httpClient = this.getHttpClient();
      log.info('Create LoggerClient');
      this.loggerClient = new MattermostLoggerImpl(httpClient);
    }
    return this.loggerClient;
  }

  public getMetricsRegistry(): MetricsRegistry {
    return this.metricsRegistry;
  }

  public getKeyValueStorage(): KeyValueStorage {
    return this.keyValueStorage;
  }

  public getDatabaseFactory(): DatabaseFactory {
    return this.dbFactory;
  }

  public async shutdown(): Promise<void> {
    await this.shutdownComponents();
    await this.metricsRegistry.close();
    this.isClosed = true;
  }

  public isShutdown(): boolean {
    return this.isClosed;
  }

  private checkIsClosed(): void {
    if (this.isClosed) {
      throw Error('Environment is closed');
    }
  }

  private async shutdownComponents(): Promise<void> {
    // Retrieve actual component instances
    const kanbanRepository = await this.kanbanRepository;
    const customerRepository = await this.customerRepository;
    const packageDealDescRepository = await this.packageDealDescRepository;
    const carElementRepository = await this.carElementRepository;
    const { httpClient } = this;
    // Dereference actual component instances
    this.kanbanRepository = undefined;
    this.customerRepository = undefined;
    this.packageDealDescRepository = undefined;
    this.carElementRepository = undefined;
    this.httpClient = undefined;
    // Shutdown actual component instances
    if (kanbanRepository) {
      await kanbanRepository.shutdown();
    }
    if (customerRepository) {
      await customerRepository.shutdown();
    }
    if (packageDealDescRepository) {
      await packageDealDescRepository.shutdown();
    }
    if (carElementRepository) {
      await carElementRepository.shutdown();
    }
    if (httpClient) {
      await httpClient.shutdown();
    }
    log.info('Shutdown complete');
  }
}

export function newEnvironment<IS_LIGHT_ENVIRONMENT extends boolean>(
  keyValueStorage: KeyValueStorage,
  dbFactory: DatabaseFactory,
  fetch: Fetch,
  formDataFactory: FormDataFactory,
  evFactory: EventSourceFactory,
  metricsRegistry: MetricsRegistry,
  localStorageKeysToKeepDuringSoftReset: readonly string[],
  baseUrl?: string,
  repositoryPollerErrorHandler: PollerErrorHandler = DEFAULT_REPOSITORY_POLLER_ERROR_HANDLER,
  repositoryErrorsListener: UnexpectedRepositoryErrorsListener = DEFAULT_UNEXPECTED_REPOSITORY_ERRORS_LISTENER,
  serverMessageListeners?:
    | Record<string, ServerMessageListener>
    | ((
        env: IS_LIGHT_ENVIRONMENT extends false ? Environment : LightEnvironment
      ) => Record<string, ServerMessageListener>),
  lightMode?: IS_LIGHT_ENVIRONMENT,
  autoStartSSE = true
): IS_LIGHT_ENVIRONMENT extends false ? Environment : LightEnvironment {
  return new EnvironmentImpl(
    keyValueStorage,
    dbFactory,
    fetch,
    formDataFactory,
    evFactory,
    metricsRegistry,
    localStorageKeysToKeepDuringSoftReset,
    baseUrl,
    repositoryPollerErrorHandler,
    repositoryErrorsListener,
    serverMessageListeners,
    lightMode,
    autoStartSSE
  );
}
