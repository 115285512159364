import type { Attachment, PurchaseOrder } from '@stimcar/libs-base';
import type { WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import { EMPTY_FORM_WITH_VALIDATION_STATE } from '../../../../hooks/useFormWithValidation.js';

export type ChangePackageDealLinkedPurchaseOrderFormData = {
  readonly selectedPurchaseOrderLabel: string;
};

export const EMPTY_CHANGE_PACKAGE_DEAL_LINKED_PURCHASE_ORDER_DATA: WithFormValidationWarnings<ChangePackageDealLinkedPurchaseOrderFormData> =
  { selectedPurchaseOrderLabel: '', warnings: {} };

export type ChangePackageDealLinkedPurchaseOrderModalState = {
  readonly changePackageDealLinkedPurchaseOrderDialog: {
    readonly active: boolean;
    readonly packageDealId: string;
    readonly purchaseOrders: readonly PurchaseOrder[];
  };
  readonly formSubmitClickedOnce: boolean;
  readonly formSubmitted: boolean;
  readonly formWarning?: string;
  readonly formData: WithFormValidationWarnings<ChangePackageDealLinkedPurchaseOrderFormData>;
};

export const EMPTY_CHANGE_PACKAGE_DEAL_LINKED_PURCHASE_ORDER_MODAL_STATE: ChangePackageDealLinkedPurchaseOrderModalState =
  {
    changePackageDealLinkedPurchaseOrderDialog: {
      active: false,
      packageDealId: '',
      purchaseOrders: [],
    },
    ...EMPTY_FORM_WITH_VALIDATION_STATE,
    formData: EMPTY_CHANGE_PACKAGE_DEAL_LINKED_PURCHASE_ORDER_DATA,
  };

export type EstimatePackageDealsDisplayMode = 'all' | 'retained' | 'retainedWithoutPrice';

export type EstimatePackageDealsSortMode = 'byCategory' | 'byCarElementIndex';

export type EstimateViewState = {
  readonly selectedPurchaseOrderTab: string;
  readonly expandedSectionIds: readonly string[];
  readonly packageDealsDisplayMode: EstimatePackageDealsDisplayMode;
  readonly availableAttachments?: readonly Attachment[];
  readonly packageDealsSortMode: EstimatePackageDealsSortMode;
  readonly changePackageDealLinkedPurchaseOrderModalState: ChangePackageDealLinkedPurchaseOrderModalState;
};

export const EMPTY_ESTIMATE_VIEW_STATE: EstimateViewState = {
  selectedPurchaseOrderTab: '',
  expandedSectionIds: [],
  packageDealsDisplayMode: 'retained',
  packageDealsSortMode: 'byCarElementIndex',
  changePackageDealLinkedPurchaseOrderModalState:
    EMPTY_CHANGE_PACKAGE_DEAL_LINKED_PURCHASE_ORDER_MODAL_STATE,
};
