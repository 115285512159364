import type {
  Attachment,
  CarElement,
  CarViewCategory,
  CoreFields,
  Kanban,
  Memo,
  MemoAutomaticPackageDealCreation,
  Operation,
  PackageDeal,
  PackageDealDesc,
  PackageDealDescWithScoreAndCarElement,
  PartialBy,
  SparePart,
  SparePartManagementType,
} from '@stimcar/libs-base';
import type {
  Defect,
  DefectHighlighter,
  DefectType,
  Entity,
  PositionOnCar,
  RefurbishingType,
} from '@stimcar/libs-kernel';
import type { BusinessObjectToFormData, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type {
  AttachmentsState,
  AttachmentsTabState,
  CarViewCanvasSize,
  ComponentWithMountTracking,
  ConfirmAttachmentDialogState,
  FormWithValidationState,
  PictureEditionToolkitState,
} from '@stimcar/libs-uitoolkit';
import { EMPTY_KANBAN, EMPTY_PACKAGE_DEAL } from '@stimcar/libs-base';
import { MAIN_EXTERNAL_STANDARD_PICTURES } from '@stimcar/libs-kernel';
import {
  ATTACHMENTS_TAB_EMPTY_STATE,
  EMPTY_ATTACHMENTS_STATE,
  EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE,
  EMPTY_FORM_WITH_VALIDATION_STATE,
  EMPTY_PICTURE_EDITION_TOOLKIT_STATE,
} from '@stimcar/libs-uitoolkit';
import type { EditMileageDialogState } from '../../../lib/components/identityPictureAndGeneralInfoDisplay/typings/store.js';
import type { SortState } from '../../../lib/components/TableSortableHeaderComponent.js';
import type {
  DisplayAttributesState,
  PictureInputState,
} from '../../../lib/components/typings/store.js';
import type { SparePartFormData } from '../../admin/packageDealDesc/typings/store.js';
import type {
  AddKanbanMessageInputState,
  MoveStandSparePartModalState,
} from '../../details/typings/store.js';
import type { AdditionalStepsForOperationCompletionModalState } from '../../utils/operationCompletion/typings/store.js';
import { EMPTY_EDIT_MILEAGE_DIALOG_STATE } from '../../../lib/components/identityPictureAndGeneralInfoDisplay/typings/store.js';
import { DISPLAY_ATTRIBUTES_EMPTY_STATE } from '../../../lib/components/typings/store.js';
import {
  EMPTY_ADD_KANBAN_MESSAGE_INPUT_STATE,
  EMPTY_MOVE_STAND_SPARE_PART_MODAL_STATE,
} from '../../details/typings/store.js';
import {
  EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE,
  EMPTY_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE,
} from '../../utils/operationCompletion/typings/store.js';

export type WorkStatus = 'unstarted' | 'started' | 'paused' | 'finished';

export type StopKanbanHandlingModalState = {
  readonly displayStopHandlingModal: boolean;
  readonly closeInterval: boolean;
  readonly closeHandling: boolean;
};

export const EMPTY_STOP_KANBAN_HANDLING_MODAL_STATE: StopKanbanHandlingModalState = {
  closeHandling: false,
  closeInterval: true,
  displayStopHandlingModal: false,
};

export type PackageDealSortValues =
  | 'code'
  | 'label'
  | 'carElement'
  | 'price'
  | 'duration'
  | 'status'
  | 'index';

export type OperationSortValues = 'code' | 'label' | 'carElement' | 'workload' | 'user';

export type PackageDealListComponentState = {
  readonly packageDeals: readonly PackageDeal[];
  readonly sort: SortState<PackageDealSortValues>;
};

export const PACKAGE_DEAL_LIST_EMPTY_STATE: PackageDealListComponentState = {
  packageDeals: [],
  sort: { by: undefined, direction: 'UP' },
};

type PackageDealPriceUpdateFormData = {
  readonly price: string;
};

export type PackageDealPriceUpdateFormDataWithWarning =
  WithFormValidationWarnings<PackageDealPriceUpdateFormData>;
export type PackageDealPriceUpdateModalState =
  FormWithValidationState<PackageDealPriceUpdateFormData> & {
    readonly isActive: boolean;
  };

export const PACKAGE_DEAL_PRICE_UPDATE_MODAL_EMPTY_STATE: PackageDealPriceUpdateModalState = {
  ...EMPTY_FORM_WITH_VALIDATION_STATE,
  isActive: false,
  formData: {
    price: '0',
    warnings: {},
  },
};

export type SelectedElementType = 'packageDealDesc' | 'packageDeal';

export type AddOrUpdatePackageDealFormData = {
  readonly price: string;
  readonly spareParts: readonly SparePartFormData[];
  readonly newSparePartLabel: string;
  readonly packageDealComment: string;
  readonly noCost: boolean;
  readonly estimateComment: string;
  readonly selectedPurchaseOrderId: string;
  readonly variableValues: Record<string, number | boolean | string>;
};

export const EMPTY_CURRENTLY_MODIFIED_PACKAGE_DEAL_FORM_DATA: AddOrUpdatePackageDealFormData = {
  price: '',
  spareParts: [],
  newSparePartLabel: '',
  packageDealComment: '',
  noCost: false,
  estimateComment: '',
  variableValues: {},
  selectedPurchaseOrderId: '',
};

export type AddOrUpdatePackageDealModalState = AttachmentsState & {
  readonly formSubmitClickedOnce: boolean;
  readonly formSubmitted: boolean;
  readonly formWarning?: string;
  readonly formData: WithFormValidationWarnings<AddOrUpdatePackageDealFormData>;
  readonly pddNotFound: boolean;
  readonly overridablePrice: boolean;
  readonly openFor: 'creation' | 'edition';
  readonly packageDeal: PackageDeal;
  readonly isForceAddPDDModalActive: boolean;
  readonly forceAddDuplicatePD: boolean;
  readonly computedDuration: number;
  readonly computedPrice: number;
  readonly forceWorkloadToZero: boolean;
  readonly active: boolean;
};

export const ADD_OR_UPDATE_PACKAGE_DEAL_MODAL_EMPTY_STATE: AddOrUpdatePackageDealModalState = {
  ...EMPTY_ATTACHMENTS_STATE,
  ...EMPTY_FORM_WITH_VALIDATION_STATE,
  pddNotFound: false,
  overridablePrice: false,
  packageDeal: EMPTY_PACKAGE_DEAL,
  forceAddDuplicatePD: false,
  openFor: 'creation',
  isForceAddPDDModalActive: false,
  computedDuration: 0,
  computedPrice: 0,
  forceWorkloadToZero: false,
  active: false,
  formData: {
    ...EMPTY_CURRENTLY_MODIFIED_PACKAGE_DEAL_FORM_DATA,
    warnings: {},
  },
};

export type DeletionModalState = {
  readonly active: boolean;
  readonly packageDealId: string;
  readonly packageDealLabel: string;
};

export const DELETION_MODAL_EMPTY_STATE: DeletionModalState = {
  active: false,
  packageDealId: '',
  packageDealLabel: '',
};

// Main tabs in the upper section of the expertise screen
export type UpperExpertiseTab = CarViewCategory | OtherExpertiseCategory;

// Expertise categories which are not car view related
export type OtherExpertiseCategory =
  | 'ATTACHMENTS'
  | 'ATTRIBUTES'
  | 'GENERAL_INFOS'
  | 'HINTS'
  | 'DEFECTS';

// Sub tabs on the left of the upper section of the expertise screen
export type UpperExpertiseSubTab = 'PACKAGE_DEALS' | 'MEMOS';

// Sub tabs on the left of the upper section of the expertise screen when the Hints tab is selected
export type UpperExpertiseHintsSubTab = 'PREDICTIONS' | 'WORK_SHEET_HINTS';

// Elements that can be displayed in the lower section of the expertise screen
export const lowerExpertiseTabs = ['ESTIMATE', 'OPERATIONS'] as const;

// Tabs on the left in the lower section of the expertise screen
export type LowerExpertiseTab = (typeof lowerExpertiseTabs)[number];

export type UpperExpertiseTabsState = Record<
  CarViewCategory | 'HINTS',
  UpperExpertiseSubTab | UpperExpertiseHintsSubTab
>;

export type ResetMemoModalState = {
  readonly active: boolean;
  readonly memoIdToReset: string;
  readonly memoToResetCategory: CarViewCategory | '';
};

export const RESET_MEMO_MODAL_EMPTY_STATE: ResetMemoModalState = {
  active: false,
  memoIdToReset: '',
  memoToResetCategory: '',
};

export type DuplicatedPDDFromMemoModalState = {
  readonly active: boolean;
  readonly duplicatedPackageDeal: PackageDeal | undefined;
};

export const DUPLICATED_PDD_FROM_MEMO_MODAL_EMPTY_STATE: DuplicatedPDDFromMemoModalState = {
  active: false,
  duplicatedPackageDeal: undefined,
};

export type IncorrectPckDealAutoCreationExpressionModalState = {
  readonly active: boolean;
  readonly pckAutoCreationDefinition: MemoAutomaticPackageDealCreation | undefined;
};

export const INCORRECT_PCK_DEAL_AUTO_CREATION_EXPRESSION_MODAL_EMPTY_STATE: IncorrectPckDealAutoCreationExpressionModalState =
  {
    active: false,
    pckAutoCreationDefinition: undefined,
  };

export type CarElementNotFoundModalState = {
  readonly active: boolean;
  readonly category: string;
  readonly carElementLabel: string;
  readonly pddCode: string;
};

export const CAR_ELEMENT_NOT_FOUND_MODAL_EMPTY_STATE: CarElementNotFoundModalState = {
  active: false,
  category: '',
  carElementLabel: '',
  pddCode: '',
};

export type DuplicatePackageDealWarningModalState = {
  readonly active: boolean;
  readonly carElementLabel: string;
  readonly pddCode: string;
};

export const DUPLICATE_PACKAGE_DEAL_WARNING_MODAL_STATE: DuplicatePackageDealWarningModalState = {
  active: false,
  carElementLabel: '',
  pddCode: '',
};

export type UserDefinedNullMemo = Entity & PartialBy<Memo, 'value'>;

export type MemosState = {
  readonly memos: Record<string, string>;
  readonly userDefinedNullMemos: readonly UserDefinedNullMemo[];
  readonly duplicatedPDDFromMemoModalState: DuplicatedPDDFromMemoModalState;
  readonly incorrectPckDealAutoCreationExpressionModal: IncorrectPckDealAutoCreationExpressionModalState;
  readonly resetMemoModalState: ResetMemoModalState;
  readonly carElementNotFoundModal: CarElementNotFoundModalState;
};

export const MEMOS_EMPTY_STATE: MemosState = {
  memos: {},
  userDefinedNullMemos: [],
  resetMemoModalState: RESET_MEMO_MODAL_EMPTY_STATE,
  duplicatedPDDFromMemoModalState: DUPLICATED_PDD_FROM_MEMO_MODAL_EMPTY_STATE,
  carElementNotFoundModal: CAR_ELEMENT_NOT_FOUND_MODAL_EMPTY_STATE,
  incorrectPckDealAutoCreationExpressionModal:
    INCORRECT_PCK_DEAL_AUTO_CREATION_EXPRESSION_MODAL_EMPTY_STATE,
};

export type DetailsAndMessagesComponentState = AttachmentsState & {
  readonly selectPackageDealOrMessagesPanelTab: 'packageDeal' | 'messages';
  readonly selectedPackageDealId: string;
  readonly kanbanMessageInput: string;
};

export const DETAILS_AND_MESSAGES_COMPONENT_EMPTY_STATE: DetailsAndMessagesComponentState = {
  ...EMPTY_ATTACHMENTS_STATE,
  selectPackageDealOrMessagesPanelTab: 'packageDeal',
  selectedPackageDealId: '',
  kanbanMessageInput: '',
};

export type WorkSheetHintsTabState = {
  readonly loadingStatus?: string;
  readonly workSheetHtmlContent: string;
  readonly workSheetAttachment: Attachment | null;
};

export const EMPTY_WORK_SHEET_HINTS_TAB_STATE: WorkSheetHintsTabState = {
  workSheetHtmlContent: '',
  workSheetAttachment: null,
};

export type OperatorExpertViewValidationErrors = Record<
  'OPERATIONS' | 'MEMOS' | 'PURCHASE_ORDERS',
  readonly string[]
>;

export type PredictionTabState = {
  readonly error?: boolean;
  readonly isLoading: boolean;
  readonly predictedPackageDealsDescs: readonly PackageDealDescWithScoreAndCarElement[];
};

const EMPTY_PREDICTION_TAB_STATE: PredictionTabState = {
  isLoading: true,
  predictedPackageDealsDescs: [],
};

export type AddPackageDealToDefectFormData = {
  readonly refurbishingType: RefurbishingType;
};

export const EMPTY_ADD_PACKAGE_DEAL_TO_DEFECT_FORM: WithFormValidationWarnings<AddPackageDealToDefectFormData> =
  {
    refurbishingType: 'fully',
    warnings: {},
  };

export type DeleteRefurbishingOptionDialogState = {
  readonly refurbishingType: RefurbishingType;
  readonly packageDealId: string;
  readonly active: boolean;
};

export const EMPTY_DELETE_REFURBISHING_OPTION_DIALOG_STATE: DeleteRefurbishingOptionDialogState = {
  refurbishingType: 'fully',
  packageDealId: '',
  active: false,
};

export const EMPTY_DEFECT_HIGHLIGHTER = { x: 0, y: 0, width: 0, height: 0 };

export type HighlightDefectDialogState = {
  readonly active: boolean;
  readonly startPositionX: number | null;
  readonly startPositionY: number | null;
  readonly isDrawing: boolean;
  readonly highlighter: DefectHighlighter;
};

export const EMPTY_HIGHLIGHT_DEFECT_DIALOG_STATE: HighlightDefectDialogState = {
  active: false,
  startPositionX: null,
  startPositionY: null,
  isDrawing: false,
  highlighter: EMPTY_DEFECT_HIGHLIGHTER,
};

export type AddPackageDealToDefectDialogState =
  FormWithValidationState<AddPackageDealToDefectFormData> & {
    readonly packageDealId: string;
    readonly packageDealLabel: string;
    readonly active: boolean;
  };

export const EMPTY_ADD_PACKAGE_DEAL_TO_DEFECT_DIALOG_STATE: AddPackageDealToDefectDialogState = {
  packageDealId: '',
  packageDealLabel: '',
  active: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  formData: EMPTY_ADD_PACKAGE_DEAL_TO_DEFECT_FORM,
};

export type UpdateRefurbishingOptionLabelFormData = {
  readonly label: string;
};

export const EMPTY_UPDATE_REFURBISHING_OPTION_LABEL_FORM: WithFormValidationWarnings<UpdateRefurbishingOptionLabelFormData> =
  {
    label: '',
    warnings: {},
  };

export type UpdateRefurbishingOptionLabelDialogState =
  FormWithValidationState<UpdateRefurbishingOptionLabelFormData> & {
    readonly refurbishingOptionId: string;
    readonly active: boolean;
  };

export const EMPTY_UPDATE_REFURBISHING_OPTION_LABEL_DIALOG_STATE: UpdateRefurbishingOptionLabelDialogState =
  {
    refurbishingOptionId: '',
    active: false,
    formSubmitClickedOnce: false,
    formSubmitted: false,
    formWarning: undefined,
    formData: EMPTY_UPDATE_REFURBISHING_OPTION_LABEL_FORM,
  };

export type AddOrUpdateDefectFormData = {
  readonly type: DefectType | '';
  readonly positionOnCar: PositionOnCar;
};

export const EMPTY_POSITION_ON_CAR: PositionOnCar = {
  pictureId: null,
  x: null,
  y: null,
};

export const EMPTY_ADD_OR_UPDATE_DEFECT_FORM: WithFormValidationWarnings<AddOrUpdateDefectFormData> =
  {
    type: '',
    positionOnCar: EMPTY_POSITION_ON_CAR,
    warnings: {},
  };

export type AddOrUpdateDefectDialogState = FormWithValidationState<AddOrUpdateDefectFormData> & {
  readonly selectedPictureId: string;
  readonly defectId: string; // Even for new defect to create we pass an ID to be used for the picture's filename
  readonly updateMode: boolean;
  readonly fullyRefurbishingPackageDealIds: readonly string[];
  readonly partiallyRefurbishingPackageDealIds: readonly string[];
  readonly addPackageDealToDefectDialogState: AddPackageDealToDefectDialogState;
  readonly deleteRefurbishingOptionDialogState: DeleteRefurbishingOptionDialogState;
  readonly confirmAttachmentRemovalDialog: ConfirmAttachmentDialogState;
  readonly active: boolean;
  readonly defectPicture: PictureInputState;
  readonly defectHighlighter: DefectHighlighter;
  readonly highlightDefectDialogState: HighlightDefectDialogState;
};

export const EMPTY_ADD_OR_UPDATE_DEFECT_DIALOG_STATE: AddOrUpdateDefectDialogState = {
  selectedPictureId: '',
  defectId: '',
  updateMode: false,
  fullyRefurbishingPackageDealIds: [],
  partiallyRefurbishingPackageDealIds: [],
  addPackageDealToDefectDialogState: EMPTY_ADD_PACKAGE_DEAL_TO_DEFECT_DIALOG_STATE,
  deleteRefurbishingOptionDialogState: EMPTY_DELETE_REFURBISHING_OPTION_DIALOG_STATE,
  confirmAttachmentRemovalDialog: EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE,
  active: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  formData: EMPTY_ADD_OR_UPDATE_DEFECT_FORM,
  defectPicture: {
    id: '',
    isSet: false,
  },
  defectHighlighter: EMPTY_DEFECT_HIGHLIGHTER,
  highlightDefectDialogState: EMPTY_HIGHLIGHT_DEFECT_DIALOG_STATE,
};

export type DeleteDefectDialogState = {
  readonly defectId: string;
  readonly active: boolean;
};

export const EMPTY_DELETE_DEFECT_DIALOG_STATE: DeleteDefectDialogState = {
  defectId: '',
  active: false,
};

export type DefectsTabState = {
  readonly defects: readonly Defect[];
  readonly selectedPictureId: string;
  readonly addOrUpdateDefectDialogState: AddOrUpdateDefectDialogState;
  readonly deleteDefectDialogState: DeleteDefectDialogState;
  readonly sort: SortState<string>;
};

export const EMPTY_DEFECTS_TAB_STATE: DefectsTabState = {
  selectedPictureId: MAIN_EXTERNAL_STANDARD_PICTURES.LEFT_SIDE.id,
  defects: [],
  addOrUpdateDefectDialogState: EMPTY_ADD_OR_UPDATE_DEFECT_DIALOG_STATE,
  deleteDefectDialogState: EMPTY_DELETE_DEFECT_DIALOG_STATE,
  sort: { by: 'index', direction: 'DOWN' },
};

export type OperatorExpertViewState = {
  readonly attachmentsTab: AttachmentsTabState;
  readonly initialKanban: Kanban;
  readonly mainPictureModal: {
    readonly active: boolean;
  };
  readonly memosPackageDealsSectionIsExpanded: boolean;
  readonly selectedUpperExpertiseTab: UpperExpertiseTab;
  readonly upperExpertiseTabsState: UpperExpertiseTabsState;
  readonly selectedLowerExpertiseTab: LowerExpertiseTab;
  readonly disabledLowerExpertiseTabs: readonly LowerExpertiseTab[];
  readonly detailsAndMessagesComponentState: DetailsAndMessagesComponentState;
  readonly selectedCarViewShapes: readonly string[];
  readonly highlightedCarViewShapes: readonly string[];
  readonly packageDealCarElements: readonly CarElement[];
  readonly allCarElements: readonly CarElement[];
  readonly availableCarElementIds: readonly string[];
  readonly selectedCarElementId: string;
  readonly availablePackageDealDescs: readonly PackageDealDesc[];
  readonly allPackageDealDescs: readonly PackageDealDesc[];
  readonly carViewCanvasSize: CarViewCanvasSize;
  readonly packageDealListComponentState: PackageDealListComponentState;
  readonly addOrUpdatePackageDealModalState: AddOrUpdatePackageDealModalState;
  readonly deletionModalState: DeletionModalState;
  readonly selectedElementType: SelectedElementType;
  readonly showKanbanMessages: boolean;
  readonly selectedPackageDealDescId: string;
  readonly memosState: MemosState;
  readonly attributesState: DisplayAttributesState;
  readonly editMileageDialogState: EditMileageDialogState;
  readonly packageDealDatabase: string;
  readonly showEstimate: boolean;
  readonly standRelatedOperations: readonly Operation[];
  readonly workSheetHintsTabState: WorkSheetHintsTabState;
  readonly isIncorrectOrientationModalOpen: boolean;
  readonly validationErrors: OperatorExpertViewValidationErrors;
  readonly showValidationErrorsModal: boolean;
  readonly pictureEditionToolkitState: PictureEditionToolkitState;
  readonly predictionsTabState: PredictionTabState;
  readonly defectsTabState: DefectsTabState;
};

export const EMPTY_OPERATOR_EXPERT_VIEW: OperatorExpertViewState = {
  attachmentsTab: ATTACHMENTS_TAB_EMPTY_STATE,
  initialKanban: EMPTY_KANBAN,
  mainPictureModal: {
    active: false,
  },
  selectedElementType: 'packageDealDesc',
  detailsAndMessagesComponentState: DETAILS_AND_MESSAGES_COMPONENT_EMPTY_STATE,
  selectedUpperExpertiseTab: 'HINTS',
  upperExpertiseTabsState: {
    HINTS: 'WORK_SHEET_HINTS',
    BUMP: 'MEMOS',
    EXTE: 'MEMOS',
    INTE: 'MEMOS',
    MECA: 'MEMOS',
    MISC: 'MEMOS',
  },
  memosPackageDealsSectionIsExpanded: true, // Memos and package deals are displayed by default
  selectedLowerExpertiseTab: 'ESTIMATE',
  disabledLowerExpertiseTabs: [],
  selectedCarViewShapes: [],
  highlightedCarViewShapes: [],
  packageDealCarElements: [],
  allCarElements: [],
  availableCarElementIds: [],
  availablePackageDealDescs: [],
  allPackageDealDescs: [],
  carViewCanvasSize: {
    width: 0,
    height: 0,
  },
  selectedCarElementId: '',
  selectedPackageDealDescId: '',
  packageDealListComponentState: PACKAGE_DEAL_LIST_EMPTY_STATE,
  addOrUpdatePackageDealModalState: ADD_OR_UPDATE_PACKAGE_DEAL_MODAL_EMPTY_STATE,
  deletionModalState: DELETION_MODAL_EMPTY_STATE,
  showKanbanMessages: false,
  memosState: MEMOS_EMPTY_STATE,
  attributesState: DISPLAY_ATTRIBUTES_EMPTY_STATE,
  editMileageDialogState: EMPTY_EDIT_MILEAGE_DIALOG_STATE,
  packageDealDatabase: '',
  showEstimate: false,
  standRelatedOperations: [],
  workSheetHintsTabState: EMPTY_WORK_SHEET_HINTS_TAB_STATE,
  isIncorrectOrientationModalOpen: false,
  validationErrors: { OPERATIONS: [], MEMOS: [], PURCHASE_ORDERS: [] },
  showValidationErrorsModal: false,
  pictureEditionToolkitState: EMPTY_PICTURE_EDITION_TOOLKIT_STATE,
  predictionsTabState: EMPTY_PREDICTION_TAB_STATE,
  defectsTabState: EMPTY_DEFECTS_TAB_STATE,
};

export type EditSparePartCommentModalState = FormWithValidationState<
  Pick<SparePart, 'comment' | 'commentForWorkshop'>
> & {
  readonly active: boolean;
  readonly sparePartId: string | undefined;
  readonly sparePartLabel: string | undefined;
};

export const EMPTY_EDIT_SPARE_PART_COMMENT_MODAL_STATE: EditSparePartCommentModalState = {
  active: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  formData: { comment: '', commentForWorkshop: '', warnings: {} },
  sparePartId: undefined,
  sparePartLabel: undefined,
};

export type SparePartEstimatedDateOfReceptionModalDialogState = {
  readonly active: boolean;
  readonly packageDealId: string;
  readonly sparePartId: string;
  readonly noDelay: boolean;
  readonly estimatedDateOfReception: number;
};

export const EMPTY_SPARE_PART_ESTIMATED_DATE_OF_RECEPTION_MODAL_DIALOG_STATE: SparePartEstimatedDateOfReceptionModalDialogState =
  {
    active: false,
    sparePartId: '',
    packageDealId: '',
    noDelay: false,
    estimatedDateOfReception: 0,
  };

export type OperatorSparePartState = {
  readonly spareParts: readonly SparePart[];
  readonly sparePartIdToPackageDealIdMap: Record<string, string>;
  readonly sparePartsOrderValues: Record<string, boolean>;
  readonly sparePartsReceivedValues: Record<string, boolean>;
  readonly moveStandSparePartModalState: MoveStandSparePartModalState;
  readonly editSparePartsCommentModal: EditSparePartCommentModalState;
  readonly expandedSectionIds: readonly string[];
  readonly estimatedDateOfReceptionModal: SparePartEstimatedDateOfReceptionModalDialogState;
};

export type OperatorGeneralViewState = {
  readonly packageDealDescs: readonly PackageDealDesc[];
  readonly standRelatedPackageDeals: readonly PackageDeal[];
  readonly sort: SortState<OperationSortValues>;
  readonly showKanbanMessages: boolean;
  readonly addKanbanMessageInputState: AddKanbanMessageInputState;
  readonly sparePartsState: OperatorSparePartState;
  readonly attachmentsState: AttachmentsTabState;
  readonly attachmentsVisible: boolean;
};

export const EMPTY_OPERATOR_GENERAL_VIEW: OperatorGeneralViewState = {
  packageDealDescs: [],
  standRelatedPackageDeals: [],
  sort: { by: undefined, direction: 'UP' },
  showKanbanMessages: false,
  addKanbanMessageInputState: EMPTY_ADD_KANBAN_MESSAGE_INPUT_STATE,
  sparePartsState: {
    spareParts: [],
    sparePartIdToPackageDealIdMap: {},
    sparePartsOrderValues: {},
    sparePartsReceivedValues: {},
    moveStandSparePartModalState: EMPTY_MOVE_STAND_SPARE_PART_MODAL_STATE,
    editSparePartsCommentModal: EMPTY_EDIT_SPARE_PART_COMMENT_MODAL_STATE,
    expandedSectionIds: [],
    estimatedDateOfReceptionModal: EMPTY_SPARE_PART_ESTIMATED_DATE_OF_RECEPTION_MODAL_DIALOG_STATE,
  },
  attachmentsState: ATTACHMENTS_TAB_EMPTY_STATE,
  attachmentsVisible: false,
};

export type SparePartReferenceFormData = WithFormValidationWarnings<
  BusinessObjectToFormData<Omit<SparePart, 'provider'>> & {
    readonly packageDealId: string;
    readonly packageDealLabel: string;
    readonly packageDealAttachments: readonly Attachment[];
    readonly carElement: CoreFields<CarElement> | undefined;
    readonly isReferenced: boolean;
    readonly provider: string;
  }
>;

export type AddSparePartToPackageDealData = BusinessObjectToFormData<{
  readonly packageDealId: string;
  readonly label: string;
  readonly providerId: string;
  readonly providerUnitPrice: number;
  readonly comment: string;
}>;
export const EMPTY_ADD_SPARE_PART_TO_PACKAGEDEAL_DATA: AddSparePartToPackageDealData = {
  packageDealId: '',
  label: '',
  providerId: '',
  providerUnitPrice: '',
  comment: '',
};

export type AddSparePartToPackageDealDialogState =
  FormWithValidationState<AddSparePartToPackageDealData> & {
    readonly packageDeals: readonly PackageDeal[];
    readonly active: boolean;
  };
export const EMPTY_ADD_SPARE_PART_TO_PACKAGE_DEAL_DIALOG_STATE: AddSparePartToPackageDealDialogState =
  {
    packageDeals: [],
    active: false,
    formSubmitted: false,
    formSubmitClickedOnce: false,
    formWarning: undefined,
    formData: {
      ...EMPTY_ADD_SPARE_PART_TO_PACKAGEDEAL_DATA,
      warnings: {},
    },
  };
export type DeleteSparePartFromPackageDealDialogState = {
  readonly kanban: Kanban | undefined;
  readonly packageDealId: string;
  readonly sparePartId: string;
  readonly active: boolean;
};

export const EMPTY_DELETE_SPARE_PART_FROM_PACKAGE_DEAL_DIALOG_STATE: DeleteSparePartFromPackageDealDialogState =
  {
    kanban: undefined,
    active: false,
    packageDealId: '',
    sparePartId: '',
  };

export type ChangeManagementTypeForAllDialogState = {
  readonly targetType: SparePartManagementType | undefined;
  readonly active: boolean;
};
export const EMPTY_CHANGE_MANAGEMENT_TYPE_FOR_ALL_DIALOG_STATE: ChangeManagementTypeForAllDialogState =
  {
    active: false,
    targetType: undefined,
  };

export type SparePartsReferenceState = {
  readonly sparePartsUnderModification: readonly SparePartReferenceFormData[];
  readonly addSparePartToPackageDealDialogState: AddSparePartToPackageDealDialogState;
  readonly deleteSparePartFromPackageDealDialogState: DeleteSparePartFromPackageDealDialogState;
  readonly changeManagementTypeForAllDialogState: ChangeManagementTypeForAllDialogState;
  readonly commentModal: {
    readonly active: boolean;
    readonly sparePartId: string;
  };
  readonly expandedSectionIds: readonly string[];
};

export const EMPTY_SPARE_PARTS_REFERENCE_STATE: SparePartsReferenceState = {
  sparePartsUnderModification: [],
  addSparePartToPackageDealDialogState: EMPTY_ADD_SPARE_PART_TO_PACKAGE_DEAL_DIALOG_STATE,
  deleteSparePartFromPackageDealDialogState: EMPTY_DELETE_SPARE_PART_FROM_PACKAGE_DEAL_DIALOG_STATE,
  changeManagementTypeForAllDialogState: EMPTY_CHANGE_MANAGEMENT_TYPE_FOR_ALL_DIALOG_STATE,
  expandedSectionIds: [],
  commentModal: {
    active: false,
    sparePartId: '',
  },
};

export type ValidationPackageDealSortValues =
  | 'carElementIndex'
  | 'carElement'
  | 'label'
  | 'tag'
  | 'price'
  | 'category'
  | 'duration';

export type ValidateExpertiseComponentState = {
  readonly kanbanId: string | undefined;
  readonly standId: string;
  readonly carElements: readonly CarElement[];
  readonly packageDealDescs: readonly PackageDealDesc[];
  readonly packageDealsWorkingCopy: readonly PackageDeal[];
  readonly sort: SortState<ValidationPackageDealSortValues>;
  readonly showFinishModalDialog: boolean;
  readonly expandedPackageDealIds: readonly string[];
};

export const EMPTY_VALIDATE_EXPERTISE_STATE: ValidateExpertiseComponentState = {
  kanbanId: undefined,
  standId: '',
  packageDealDescs: [],
  carElements: [],
  packageDealsWorkingCopy: [],
  sort: { by: 'carElementIndex', direction: 'DOWN' },
  showFinishModalDialog: false,
  expandedPackageDealIds: [],
};

export type OperatorViewState = ComponentWithMountTracking &
  AdditionalStepsForOperationCompletionModalState & {
    readonly consumedTimeInSeconds: number;
    readonly totalTimeInSeconds: number;
    readonly workStatus: WorkStatus;
    readonly startHandling: boolean;
    readonly operationsToDoIds: readonly string[];
    readonly stopKanbanHandlingModalState: StopKanbanHandlingModalState;
    readonly operatedKanban: Kanban | undefined;
    readonly expertOperatorState: OperatorExpertViewState;
    readonly generalOperatorState: OperatorGeneralViewState;
    readonly sparePartsReferenceState: SparePartsReferenceState;
    readonly validateExpertiseViewState: ValidateExpertiseComponentState;
  };

export const EMPTY_OPERATOR_VIEW_STATE: OperatorViewState = {
  componentIsMounted: false,
  consumedTimeInSeconds: 0,
  totalTimeInSeconds: 0,
  workStatus: 'unstarted',
  startHandling: false,
  operationsToDoIds: [],
  uploadDocumentForCompletionModal: EMPTY_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE,
  selectSubcontractorFirmModal: EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE,
  stopKanbanHandlingModalState: EMPTY_STOP_KANBAN_HANDLING_MODAL_STATE,
  operatedKanban: undefined,
  expertOperatorState: EMPTY_OPERATOR_EXPERT_VIEW,
  generalOperatorState: EMPTY_OPERATOR_GENERAL_VIEW,
  sparePartsReferenceState: EMPTY_SPARE_PARTS_REFERENCE_STATE,
  validateExpertiseViewState: EMPTY_VALIDATE_EXPERTISE_STATE,
};
