import type { AttributeType, ContractAttributeDesc } from '@stimcar/libs-base';
import type { Entity } from '@stimcar/libs-kernel';
import type { SortState } from '../TableSortableHeaderComponent.js';

export type KanbanAttributeUpdateModalState = {
  readonly attributeKey: string;
  readonly attributeValue: string;
  readonly isActive: boolean;
};

export const KANBAN_UPDATE_ATTRIBUTE_MODAL_EMPTY_STATE: KanbanAttributeUpdateModalState = {
  attributeKey: '',
  attributeValue: '',
  isActive: false,
};

export type DisplayAttributesState = {
  readonly kanbanAttributeUpdateModalState: KanbanAttributeUpdateModalState;
  readonly contractAttributeDescs: readonly ContractAttributeDesc[];
  readonly attributes: Record<string, AttributeType>;
  readonly sort: SortState<string>;
};

export const DISPLAY_ATTRIBUTES_EMPTY_STATE: DisplayAttributesState = {
  kanbanAttributeUpdateModalState: KANBAN_UPDATE_ATTRIBUTE_MODAL_EMPTY_STATE,
  contractAttributeDescs: [],
  sort: { by: 'label', direction: 'UP' },
  attributes: {},
};

export type PictureInputState = Entity & {
  readonly isSet: boolean;
};
