import type { TFunction } from 'i18next';
import { isFloat } from '@stimcar/libs-base';
import { isInteger } from '@stimcar/libs-kernel';

/**
 * Format the given string to produce a string that can be converted into Number.
 * For instance in French, float numbers are written with a comma, but this leads to errors when converting it to number.
 * The comma should be replaced by a period
 *
 * @param s
 */
export const formatStringNumber = (s: string): string => {
  return s.replace(',', '.');
};

export function isIntegerCheck(value: string, t: TFunction): string | undefined {
  // We have to use a regex instead of Number.parseInt because parseInt will do some magic to be able to convert the given string
  // into a number, blocking us to know if the given string is a number or not.
  // For instance Number.parseInt('1234p', 10) return the number 1234
  const isValid = isInteger(value);
  return isValid ? undefined : t('libUtils:warnings.integerField');
}

export function isPositiveIntegerCheck(
  value: string,
  t: TFunction,
  zeroAllowed = true
): string | undefined {
  const isFloatError = isIntegerCheck(value, t);
  if (isFloatError !== undefined) {
    return isFloatError;
  }
  const integer = parseInt(value, 10);
  if (zeroAllowed) {
    return integer >= 0 ? undefined : t('libUtils:warnings.shouldBePositive');
  }
  return integer > 0 ? undefined : t('libUtils:warnings.shouldBeStrictlyPositive');
}

export function isFloatCheck(value: string, t: TFunction): string | undefined {
  if (!isFloat(value)) {
    return t('libUtils:warnings.floatField');
  }
  return undefined;
}

export function isPositiveFloatCheck(
  value: string,
  t: TFunction,
  zeroAllowed = true
): string | undefined {
  const isFloatError = isFloatCheck(value, t);
  if (isFloatError !== undefined) {
    return isFloatError;
  }
  const float = parseFloat(value);
  if (zeroAllowed) {
    return float >= 0 ? undefined : t('libUtils:warnings.shouldBePositive');
  }
  return float > 0 ? undefined : t('libUtils:warnings.shouldBeStrictlyPositive');
}
