import type { TFunction } from 'i18next';
import type { Kanban, PackageDeal } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import { expertiseHelpers, kanbanHelpers, packageDealHelpers } from '@stimcar/libs-base';
import { computePayload, isTruthy, nonnull } from '@stimcar/libs-kernel';
import type { Store } from '../state/typings/store.js';
import {
  clearOperatorViewAndNavigateToSelectionAction,
  closeKanbanHandleOnPost,
} from '../utils/operatorUtils.js';
import type {
  OperatorCustomActionToPerformOnKanban,
  OperatorCustomRaiseWarningBeforeTerminateAction,
  OperatorCustomTerminateAction,
} from './components/OperatorCountdown.js';
import type { OperatorViewState } from './typings/store.js';
import { addValidationOperation } from './expertiseUtils.js';

export const computeHasLocalValidateExpertiseChanges = (
  operatorViewState: OperatorViewState,
  initialKanban: Kanban | undefined
): boolean => {
  if (!isTruthy(initialKanban)) {
    return false;
  }
  const { packageDealsWorkingCopy } = operatorViewState.validateExpertiseViewState;
  if (packageDealsWorkingCopy.length === 0) {
    return true;
  }
  const selectedPackageDealIds =
    expertiseHelpers.deduceAvailablePackageDealsIdsFromActualStatusOrExpertRecommendation(
      initialKanban.packageDeals
    );
  const canceledPackageDealIds = expertiseHelpers.deduceCanceledPackageDealIdListFromAvailableOnes(
    initialKanban.packageDeals,
    selectedPackageDealIds
  );
  const updatedPackageDeals = kanbanHelpers.applyStatusOnAllPackageDeals(
    initialKanban,
    selectedPackageDealIds,
    canceledPackageDealIds,
    false,
    null
  );
  const payload = computePayload(updatedPackageDeals, packageDealsWorkingCopy);
  return payload !== undefined && payload.length > 0;
};

function getUpdatedPackageDeals(
  ctx: ActionContext<Store, OperatorViewState>
): readonly PackageDeal[] {
  const { getGlobalState, getState, httpClient } = ctx;
  const { packageDealsWorkingCopy } = getState().validateExpertiseViewState;
  const { siteConfiguration, session } = getGlobalState();

  const newSparePartsToReference = packageDealHelpers.doPdsContainSparePartsToReferenced(
    packageDealsWorkingCopy,
    true
  );
  let packageDealsCompletedWithSparePartsReferenceOperation = packageDealsWorkingCopy;
  packageDealsCompletedWithSparePartsReferenceOperation =
    packageDealHelpers.addSparePartReferenceOperationToExpertise(
      httpClient.getBrowserSequence(),
      packageDealsCompletedWithSparePartsReferenceOperation,
      siteConfiguration,
      !newSparePartsToReference,
      session.user?.login ?? null
    );
  return packageDealsCompletedWithSparePartsReferenceOperation;
}

export const applyValidateExpertiseModificationsToKanban: OperatorCustomActionToPerformOnKanban = (
  ctx: ActionContext<Store, OperatorViewState>,
  kanban: Kanban
): Kanban => {
  const { actionDispatch, getGlobalState, httpClient } = ctx;
  const { siteConfiguration } = getGlobalState();
  const updatedPds = getUpdatedPackageDeals(ctx);
  let updatedKanban: Kanban = {
    ...kanban,
    packageDeals: updatedPds,
  };

  const { unfinishedValidationExpertiseOperations } =
    expertiseHelpers.getUnfinishedExpertiseRelatedFlatOperations(
      updatedKanban.packageDeals,
      updatedKanban.workflowId,
      siteConfiguration
    );

  if (unfinishedValidationExpertiseOperations.length === 0) {
    updatedKanban = addValidationOperation(
      httpClient.getBrowserSequence(),
      updatedKanban,
      siteConfiguration
    );
  }
  actionDispatch.reduce((initial): OperatorViewState => {
    return {
      ...initial,
      validateExpertiseViewState: {
        ...initial.validateExpertiseViewState,
        packageDealsWorkingCopy: updatedKanban.packageDeals,
      },
    };
  });
  return updatedKanban;
};

export const validateExpertiseRaiseWarningBeforeTerminate: OperatorCustomRaiseWarningBeforeTerminateAction =
  (
    ctx: ActionContext<Store, OperatorViewState>,
    kanban: Kanban,
    t: TFunction
  ): string | undefined => {
    if (packageDealHelpers.doPdsContainSparePartsToReferenced(kanban.packageDeals, true)) {
      return t('errors.unreferencedSparePartsDuringExpertiseValidation');
    }
    const { siteConfiguration } = ctx.getGlobalState();
    const { unfinishedExpertiseOrSparePartsReferenceOperations } =
      expertiseHelpers.getUnfinishedExpertiseRelatedFlatOperations(
        kanban.packageDeals,
        kanban.workflowId,
        siteConfiguration
      );
    if (unfinishedExpertiseOrSparePartsReferenceOperations.length > 0) {
      return t('errors.unfinishedExpertiseOrSparePartsReferenceOperations');
    }
    return undefined;
  };

export const validateExpertiseTerminateAction: OperatorCustomTerminateAction = async (
  ctx: ActionContext<Store, OperatorViewState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  kanban: Kanban,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  standId: string
  // eslint-disable-next-line @typescript-eslint/require-await
) => {
  const { actionDispatch } = ctx;
  actionDispatch
    .scopeProperty('validateExpertiseViewState')
    .setProperty('showFinishModalDialog', true);
};

export const validateExpertiseAndStopWork = async (
  ctx: ActionContext<Store, OperatorViewState>,
  t: TFunction,
  kanban: Kanban,
  standId: string
): Promise<void> => {
  const { actionDispatch, kanbanRepository, getGlobalState, httpClient, getState } = ctx;
  const { session, siteConfiguration } = getGlobalState();

  const { packageDealsWorkingCopy } = getState().validateExpertiseViewState;

  const selectedPackageDealIds =
    expertiseHelpers.deduceAvailablePackageDealsIdsFromActualStatusOrExpertRecommendation(
      packageDealsWorkingCopy
    );
  const canceledPackageDealIds = expertiseHelpers.deduceCanceledPackageDealIdListFromAvailableOnes(
    kanban.packageDeals,
    selectedPackageDealIds
  );

  const username = nonnull(session.user?.login);
  const unfinishedValidationOps = packageDealHelpers
    .getFlatOperationList(kanban.packageDeals)
    .filter((o) => !o.deleted && o.type === 'Validation' && !isTruthy(o.completionDate));
  let updatedKanban = kanban;
  if (unfinishedValidationOps.length === 0) {
    updatedKanban = addValidationOperation(
      httpClient.getBrowserSequence(),
      kanban,
      siteConfiguration
    );
  }
  updatedKanban = kanbanHelpers.validateKanbanExpertise(
    httpClient.getBrowserSequence(),
    kanban,
    selectedPackageDealIds,
    canceledPackageDealIds,
    username,
    username,
    t('operators:expertiseValidation.kanbanValidationMessage', { user: username })
  );

  const stoppedKanban = closeKanbanHandleOnPost(
    nonnull(session.infos?.id),
    updatedKanban,
    session.user?.permissions ?? {},
    standId,
    session.user?.login ?? null
  );
  await kanbanRepository.updateEntity(stoppedKanban);
  await actionDispatch.exec(clearOperatorViewAndNavigateToSelectionAction);
};
