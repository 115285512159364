/* eslint-disable jsx-a11y/control-has-associated-label */
import type { Location } from 'history';
import type { SyntheticEvent } from 'react';
import i18next from 'i18next';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import type { Kanban, User } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  LoginModalDialogState,
  ProgressBarMonitor,
  ResetDialogState,
} from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  AvailablePermissionPaths,
  CoreBackendRoutes,
  kanbanHelpers,
  Role,
  userHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useScreenIsBulmaMobile,
  useSetCallback,
} from '@stimcar/libs-uikernel';
import {
  downloadAndSave,
  DropDownMenu,
  FaIcon,
  LoginModalDialog,
  ModalCardDialog,
  ResetContext,
  ResetDialog,
} from '@stimcar/libs-uitoolkit';
import { NavLinkWithDirtyNavigationWarning } from '../lib/components/NavLinkWithDirtyNavigationWarning.js';
import { loginAction } from '../registeredapp/components/authentication/loginaction.js';
import {
  EMPTY_EDIT_PASSWORD_DATA,
  EMPTY_EDIT_PASSWORD_DIALOG_STATE,
} from '../registeredapp/components/authentication/typings/store.js';
import { NetworkIconWithRepositoryChanges } from '../registeredapp/components/navbar/NetworkIconWithRepositoryChanges.js';
import {
  useHasAccessPermission,
  useHasModifyPermission,
} from '../registeredapp/permissionHooks.js';
import type {
  HandledKanbanActions,
  LogoutDialogState,
  NavBarState,
  Store,
  StoreState,
} from './state/typings/store.js';
import type { MenuGroups } from './utils/MenuBuilder.js';
import {
  ExportMarketplaceInvoicingDialog,
  openExportMarketplaceInvoicingDialogAction,
} from './admin/marketplace/ExportMarketplaceInvoicingDialog.js';
import {
  openShiftParametersDialogAction,
  ShiftProgressDisplayConfigurationDialog,
} from './admin/shiftParameters/ShiftParametersDialog.js';
import {
  ADMIN_BASE_FULL_PATH,
  ADMIN_CAR_ELEMENTS_FULL_PATH,
  ADMIN_CONTRACTS_FULL_PATH,
  ADMIN_CREATE_KANBAN_FULL_PATH,
  ADMIN_CUSTOMERS_FULL_PATH,
  ADMIN_KANBAN_IMPORT_FULL_PATH,
  ADMIN_PACKAGE_DEALS_FULL_PATH,
  ADMIN_SCHEDULED_TASKS_FULL_PATH,
  ADMIN_SPAREPARTS_PROVIDERS_FULL_PATH,
  ADMIN_WORKFLOWS_FULL_PATH,
  ARCHIVE_FULL_PATH,
  computeKanbanDetailsPath,
  computeOperatePath,
  DISPLAY_DASHBOARD_FULL_PATH,
  SELECT_KANBAN_FULL_PATH,
  USERS_ADMIN_FULL_PATH,
  WORKSHOP_DASHBOARD_FULL_PATH,
  WORKSHOP_OPERATOR_DASHBOARD_FULL_PATH,
  WORKSHOP_OPERATOR_FULL_PATH,
} from './coreConstants.js';
import { DisplayMenu } from './display/DisplayMenu.js';
import { LogoutWithWIPModal } from './globalModals/LogoutWithWIPModal.js';
import { NavigationWithWIPModal } from './globalModals/NavigationWithWIPModal.js';
import { logoutAction } from './state/actions/authenticate.js';
import { SwitchToRoleDialog } from './SwitchToRole.js';
import { UpdatePasswordModal } from './UpdatePasswordModal.js';
import { MenuBuilder } from './utils/MenuBuilder.js';
import { closeOrPauseHandledKanbansAction } from './utils/postConfigurationUtils.js';
import { useGetCurrentWorkshopPostDisplayedLabels } from './utils/useGetCurrentWorkshopPostDisplayedLabels.js';
import { useIsWorkshopLogin } from './utils/useIsWorkshopLogin.js';

const DEFAULT_STAND_OR_ROLE_ICON = 'pencil-ruler';

const computeUserIsOnGivenUrlClassName = (
  location: Location,
  pathname: string,
  exact = true
): string => {
  if (exact) {
    if (location.pathname === pathname) {
      return 'is-active';
    }
  } else if (location.pathname.startsWith(pathname)) {
    return 'is-active';
  }
  return '';
};

const cancelLoginAndCloseLoginDialogAction = async ({
  globalActionDispatch,
  getGlobalState,
}: ActionContext<Store, LoginModalDialogState>): Promise<void> => {
  globalActionDispatch.scopeProperty('loginView').setProperty('loginModalActive', false);
  if (getGlobalState().session.user !== undefined) {
    await globalActionDispatch.scopeProperty('session').exec(logoutAction);
  }
};

async function closeOrPauseKanbanOnLogoutAction({
  getState,
  globalActionDispatch,
}: ActionContext<Store, LogoutDialogState>): Promise<void> {
  const { actionToPerformOnLogout } = getState().formData;
  await globalActionDispatch.exec(
    closeOrPauseHandledKanbansAction,
    actionToPerformOnLogout as HandledKanbanActions
  );
}

async function logoutOrWarnAboutHandledKanbanAction({
  getState,
  kanbanRepository,
  actionDispatch,
  carElementRepository,
  customerRepository,
  packageDealDescRepository,
}: ActionContext<Store, StoreState>): Promise<void> {
  const { handledKanbanId, session } = getState();
  const navBarDispatch = actionDispatch.scopeProperty('navbar');
  const logoutDialogDispatch = navBarDispatch.scopeProperty('logoutDialogState');

  const hasLocalChanges =
    (await kanbanRepository.getLocalChangesCount()) +
      (await carElementRepository.getLocalChangesCount()) +
      (await customerRepository.getLocalChangesCount()) +
      (await packageDealDescRepository.getLocalChangesCount()) >
    0;

  if (!session.isOnline && hasLocalChanges) {
    navBarDispatch.applyPayload({ showPossibleDataLossModal: true });
    return;
  }
  let kanbans: readonly Kanban[] = [];
  if (isTruthyAndNotEmpty(handledKanbanId)) {
    kanbans = await kanbanRepository.getEntities(handledKanbanId);
  }
  if (kanbans.length > 0) {
    logoutDialogDispatch.setProperty('active', true);
  } else {
    await logoutDialogDispatch.exec(closeOrPauseKanbanOnLogoutAction);
    await actionDispatch.scopeProperty('session').exec(logoutAction);
    document.location.reload();
  }
}

function openUpdatePasswordDialogAction({
  getState,
  actionDispatch,
  httpClient,
  globalActionDispatch,
}: ActionContext<Store, StoreState>): void {
  if (!httpClient.isOnline()) {
    globalActionDispatch.reduce((initial: StoreState): StoreState => {
      return {
        ...initial,
        message: {
          type: 'error',
          title: i18next.t('refitit:warning'),
          content: i18next.t('refitit:updatePasswordForbiddenIfHttpClientOffline'),
        },
      };
    });
  } else {
    const { session } = getState();
    const { user } = session;
    actionDispatch
      .scopeProperty('navbar')
      .scopeProperty('updatePasswordDialogState')
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .reduce((initial) => {
        return {
          ...EMPTY_EDIT_PASSWORD_DIALOG_STATE,
          active: true,
          userLogin: user?.login ?? '',
          formData: {
            ...EMPTY_EDIT_PASSWORD_DATA,
          },
        };
      });
  }
}

async function onResetTriggerAction({ httpClient }: ActionContext<Store, ResetDialogState>) {
  await httpClient.logout();
}

export function NavBar({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation(['refitit', 'globals']);
  const location = useLocation();
  const { $navbar } = $gs;
  const { $resetDialogState } = $navbar;

  const handledKanbanId = useGetState($gs.$handledKanbanId);
  const detailKanbanId = useGetState($gs.$detailKanbanId);
  const preventNavigation = useGetState($gs.$preventNavigation);
  const stands = useGetState($gs.$siteConfiguration.$stands);

  const isMobile = useScreenIsBulmaMobile($gs.$window);
  const isWorkshopLogin = useIsWorkshopLogin($gs);

  const logoutOrWarnAboutHandledKanbanCallback = useActionCallback(
    logoutOrWarnAboutHandledKanbanAction,
    [],
    $gs
  );

  const showPreventNavigationModalAction = useSetCallback($gs.$showPreventNavigationModal, true);

  const standId = useGetState($gs.$session.$infos.optChaining().$standId);
  const stand = useMemo(() => stands.find((s) => s.id === standId), [standId, stands]);

  const standOrRoleIconId = useMemo(() => {
    if (stand) {
      return stand.iconClass;
    }
    return DEFAULT_STAND_OR_ROLE_ICON;
  }, [stand]);

  const currentLocationKanbanId = useMemo((): string | undefined => {
    const parts = location.pathname.split('/');
    const maybeId = parts[1];
    let count = 0;
    for (let index = 0; index < maybeId.length; index += 1) {
      if (maybeId[index] === ':') {
        count += 1;
      }
    }
    if (count === 2) {
      return maybeId;
    }
    return undefined;
  }, [location]);

  const asyncEffect = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(async ({ kanbanRepository, actionDispatch }) => {
        const handledKanban = isTruthy(handledKanbanId)
          ? await kanbanRepository.getEntity(handledKanbanId)
          : undefined;
        const detailKanban = isTruthy(detailKanbanId)
          ? await kanbanRepository.getEntity(detailKanbanId)
          : undefined;

        actionDispatch.reduce((initial): NavBarState => {
          return {
            ...initial,
            handledKanban,
            detailKanban,
          };
        });
      });
    },
    [detailKanbanId, handledKanbanId],
    $navbar
  );
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const hideAdminMenu = useSetCallback($navbar.$adminMenuIsActive, false);

  const downloadDevDumpMenuAction = useActionCallback(
    async ({ httpClient, runWithProgressBar, actionDispatch }, e: SyntheticEvent) => {
      e.preventDefault();
      actionDispatch.setProperty('adminMenuIsActive', false);
      await runWithProgressBar(100, async (monitor: ProgressBarMonitor): Promise<void> => {
        // Turn into indeterminate mode (we don't know how much time it will take)
        monitor.setIndeterminateProgress();
        // Download
        await downloadAndSave(httpClient, CoreBackendRoutes.DEV_DUMP, 'dump.zip');
      });
    },
    [],
    $navbar
  );

  const burgerIsActive = useGetState($navbar.$burgerIsActive);
  const toggleBurger = useSetCallback($navbar.$burgerIsActive, !burgerIsActive);

  const showLoginModal = useActionCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async ({ actionDispatch, keyValueStorage, httpClient }: ActionContext<Store, StoreState>) => {
      // All users known in this workshop
      const allUsersForWorkshop = (
        await httpClient.httpGetAsJson<readonly User[]>(CoreBackendRoutes.GET_USERS_FOR_WORKSHOP)
      )
        .map((user) => user.login)
        .sort();

      // Users who already logged on this computer
      const alreadyLoggedInUsers = (
        keyValueStorage.getObjectItem<string[]>(LocalStorageKeys.ALREADY_KNOWN_USERS_LIST) ?? []
      ).sort();

      const alreadyLoggedInAndStillActiveUsers = alreadyLoggedInUsers.filter((login) =>
        allUsersForWorkshop.includes(login)
      );

      const allUsersForWorkshopWhoNeverLogged = allUsersForWorkshop.filter(
        (login) => !alreadyLoggedInUsers.includes(login)
      );

      const alreadyKnownUsers = [
        ...alreadyLoggedInAndStillActiveUsers,
        '---',
        ...allUsersForWorkshopWhoNeverLogged,
      ];

      actionDispatch.applyPayload({
        loginView: {
          loginModalActive: true,
          fixedLogin: false,
          formSubmitClickedOnce: false,
          formSubmitted: false,
          formWarning: undefined,
          alreadyKnownUsers: alreadyKnownUsers ?? [],
          selectOrVirtualKeyboardInputScopedState: {
            usingSelect: true,
          },
        },
      });
    },
    [],
    $gs
  );

  const showSwitchToRoleDialogActionCallback = useActionCallback(
    ({ actionDispatch, getGlobalState }, e: SyntheticEvent) => {
      e.preventDefault();
      const sessionStateInfos = getGlobalState().session.infos;
      actionDispatch.applyPayload({
        switchToRoleDialogState: {
          active: true,
          formSubmitted: false,
          formSubmitClickedOnce: false,
          formWarning: undefined,
          formData: {
            newRole: sessionStateInfos ? sessionStateInfos.role : '',
            newStandId: sessionStateInfos ? sessionStateInfos.standId : '',
          },
        },
      });
    },
    [],
    $navbar
  );

  const showResetDialogActionCallback = useActionCallback(
    function showResetDialogAction(
      { actionDispatch, globalActionDispatch, httpClient },
      e: SyntheticEvent
    ) {
      e.preventDefault();
      if (!httpClient.isOnline()) {
        globalActionDispatch.reduce((initial: StoreState): StoreState => {
          return {
            ...initial,
            message: {
              type: 'error',
              title: t('warning'),
              content: t('resetForbidenIfHttpClientOffline'),
            },
          };
        });
      } else {
        actionDispatch.setProperty('active', true);
        actionDispatch.setProperty('hardReset', false);
      }
    },
    [t],
    $resetDialogState
  );

  const openChangePasswordCallback = useActionCallback(openUpdatePasswordDialogAction, [], $gs);

  const handledKanban = useGetState($navbar.$handledKanban);
  const detailKanban = useGetState($navbar.$detailKanban);

  const getNavBarKanbanFromId = (id: string): Kanban | undefined => {
    if (handledKanban?.id === id) {
      return handledKanban;
    }
    return detailKanban?.id === id ? detailKanban : undefined;
  };

  const browserInfos = useGetState($gs.$session.$infos);
  const user = useGetState($gs.$session.$user);

  const standIdWhereKanbanIsHandled = useMemo(() => {
    let standId = '';
    if (isTruthy(handledKanban)) {
      standId =
        kanbanHelpers.getOpenOperatorHandleForPost(handledKanban, browserInfos?.id ?? '')
          ?.standId ?? '';
    }
    return standId;
  }, [handledKanban, browserInfos]);

  const isNotADisplayPost =
    user && browserInfos !== undefined && Role.Display !== browserInfos.role;
  const isNotAWorkshopOperator =
    isNotADisplayPost && Role.WorkshopOperator !== nonnull(browserInfos).role;

  const canAccessAdminWorkflow = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.WORKFLOW_VIEW
  );
  const canCreateDataDump = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.CAN_CREATE_DATA_DUMP
  );

  const canAccessAdminInvoicingMenu = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.CAN_ACCESS_ADMIN_INVOICING
  );

  const canEditShiftParameters = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.SHIFT_PARAMETERS_PERMISSION_VIEW
  );

  const canAccessAdminCustomersMenu = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CAN_ACCESS_ADMIN_CUSTOMER
  );
  const canAccessCustomersRepository = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.REPOSITORY_ACCESS_PERMISSION('customer')
  );
  const canAccessAdminCustomers = canAccessAdminCustomersMenu && canAccessCustomersRepository;

  const canAccessAdminCarElementMenu = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CAN_ACCESS_ADMIN_CAR_ELEMENT
  );
  const canAccessCarElementRepository = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.REPOSITORY_ACCESS_PERMISSION('carElement')
  );
  const canAccessAdminCarElement = canAccessAdminCarElementMenu && canAccessCarElementRepository;

  const canAccessAdminPackageDealDescsMenu = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CAN_ACCESS_ADMIN_PACKAGE_DEAL_DESC
  );
  const canAccessPackageDealDescsRespository = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.REPOSITORY_ACCESS_PERMISSION('packageDealDesc')
  );
  const canAccessAdminPackageDealDescs =
    canAccessAdminPackageDealDescsMenu && canAccessPackageDealDescsRespository;

  const canAccessAdminUsers = useHasAccessPermission($gs, AvailablePermissionPaths.USERS_VIEW);
  const canAccessAdminScheduledTasks = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.SCHEDULED_TASKS_VIEW
  );
  const canAccessAdminContracts = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CONTRACTS_VIEW
  );
  const canAccessAdminSparepartsProviders = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.SPAREPARTS_PROVIDERS_VIEW
  );

  const canCreateKanban = useHasModifyPermission($gs, AvailablePermissionPaths.CAN_CREATE_KANBAN);
  const canImportKanban = useHasModifyPermission($gs, AvailablePermissionPaths.CAN_IMPORT_KANBAN);

  const shouldAdminMenuBeVisible =
    canAccessAdminWorkflow ||
    canCreateDataDump ||
    canAccessAdminInvoicingMenu ||
    canAccessAdminCustomers ||
    canAccessAdminCarElement ||
    canAccessAdminPackageDealDescs ||
    canAccessAdminUsers ||
    canAccessAdminScheduledTasks ||
    canAccessAdminContracts ||
    canAccessAdminSparepartsProviders ||
    canCreateKanban ||
    canImportKanban;

  const canAccessKanbanDetails = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CAN_ACCESS_KANBAN_DETAILS
  );
  const canAccessDashboard = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CAN_ACCESS_DASHBOARD
  );

  const canAccessArchives = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CAN_ACCESS_ARCHIVES
  );

  const { qualifiedPostLabel: workshopPostLabel } = useGetCurrentWorkshopPostDisplayedLabels($gs);
  const displayedPostLabel = useMemo((): string => {
    if (
      isTruthy(stand) &&
      isTruthy(stand.implantations) &&
      stand.implantations.length > 0 &&
      isTruthyAndNotEmpty(workshopPostLabel)
    ) {
      return workshopPostLabel;
    }
    return browserInfos?.label ?? '';
  }, [browserInfos, stand, workshopPostLabel]);

  const closeDataLossModalActionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.applyPayload({
        showPossibleDataLossModal: false,
        logoutDialogState: { active: false },
      });
    },
    [],
    $navbar
  );

  const currentHandleStandIcon = useMemo(() => {
    if (isTruthy(handledKanban)) {
      const openHandleOnPost = kanbanHelpers.getOpenOperatorHandleForPost(
        handledKanban,
        browserInfos?.id ?? ''
      );
      return (
        stands.find((s) => s.id === openHandleOnPost?.standId)?.iconClass ??
        DEFAULT_STAND_OR_ROLE_ICON
      );
    }
    return DEFAULT_STAND_OR_ROLE_ICON;
  }, [handledKanban, browserInfos, stands]);

  const resetCallback = useContext(ResetContext);

  const isOnline = useGetState($gs.$session.$isOnline);

  const loginActionCallback = useActionCallback(loginAction, [], $gs.$loginView);
  const cancelLoginAndCloseLoginDialogActionCallback = useActionCallback(
    cancelLoginAndCloseLoginDialogAction,
    [],
    $gs.$loginView
  );

  const openShiftParametersDialogActionCallback = useActionCallback(
    openShiftParametersDialogAction,
    [],
    $gs.$adminView.$adminShiftProgressDisplay
  );

  const openExportMarketplaceInvoicingDialogActionCallback = useActionCallback(
    openExportMarketplaceInvoicingDialogAction,
    [],
    $gs.$adminView.$adminExportMarketplaceInvoicing
  );

  const adminMenuGroups = useMemo((): MenuGroups => {
    const menuBuilder = new MenuBuilder();
    /**
     * Admin parameters Menu
     */
    const adminParametersMenu = menuBuilder.getMenuGroupBuilder('adminParameters');
    if (canAccessAdminWorkflow) {
      adminParametersMenu.append({
        label: t('admin.workflows'),
        target: ADMIN_WORKFLOWS_FULL_PATH,
      });
    }
    if (canEditShiftParameters) {
      adminParametersMenu.append({
        label: t('admin.shiftParameters'),
        target: openShiftParametersDialogActionCallback,
      });
    }
    if (canAccessAdminScheduledTasks) {
      adminParametersMenu.append({
        label: t('admin.adminScheduledTasks'),
        target: ADMIN_SCHEDULED_TASKS_FULL_PATH,
      });
    }
    if (canAccessAdminUsers) {
      adminParametersMenu.append({
        label: t('admin.usersAdmin'),
        target: USERS_ADMIN_FULL_PATH,
      });
    }
    /**
     * Kanban Menu
     */
    const kanbanAdminMenu = menuBuilder.getMenuGroupBuilder('kanban');
    if (canCreateKanban) {
      kanbanAdminMenu.append({
        label: t('admin.createKanban'),
        target: ADMIN_CREATE_KANBAN_FULL_PATH,
      });
    }
    if (canImportKanban) {
      kanbanAdminMenu.append({
        label: t('admin.importKanbans'),
        target: ADMIN_KANBAN_IMPORT_FULL_PATH,
      });
    }
    /**
     * Business entities menu
     */
    const businessAdminMenu = menuBuilder.getMenuGroupBuilder('admin');
    if (canAccessAdminCustomers) {
      businessAdminMenu.append({
        label: t('admin.customers'),
        target: ADMIN_CUSTOMERS_FULL_PATH,
      });
    }
    if (canAccessAdminContracts) {
      businessAdminMenu.append({
        label: t('admin.adminContracts'),
        target: ADMIN_CONTRACTS_FULL_PATH,
      });
    }
    if (canAccessAdminCarElement) {
      businessAdminMenu.append({
        label: t('admin.carElements'),
        target: ADMIN_CAR_ELEMENTS_FULL_PATH,
      });
    }
    if (canAccessAdminPackageDealDescs) {
      businessAdminMenu.append({
        label: t('admin.packages'),
        target: ADMIN_PACKAGE_DEALS_FULL_PATH,
      });
    }
    if (canAccessAdminSparepartsProviders) {
      businessAdminMenu.append({
        label: t('admin.adminSparePartsProviders'),
        target: ADMIN_SPAREPARTS_PROVIDERS_FULL_PATH,
      });
    }
    /**
     * Accounting Menu
     */
    const accountingMenu = menuBuilder.getMenuGroupBuilder('accounting');
    if (canAccessAdminInvoicingMenu) {
      accountingMenu.append({
        label: t('admin.exportMarketplaceInvoicing'),
        target: openExportMarketplaceInvoicingDialogActionCallback,
      });
    }
    /**
     * Dev Menu
     */
    const devMenu = menuBuilder.getMenuGroupBuilder('dev');
    if (canCreateDataDump) {
      devMenu.append({
        label: t('admin.devDump'),
        target: downloadDevDumpMenuAction,
      });
    }
    return menuBuilder.getMenu(true);
  }, [
    canAccessAdminCarElement,
    canAccessAdminContracts,
    canAccessAdminCustomers,
    canAccessAdminPackageDealDescs,
    canAccessAdminScheduledTasks,
    canAccessAdminSparepartsProviders,
    canAccessAdminUsers,
    canAccessAdminWorkflow,
    canCreateDataDump,
    canAccessAdminInvoicingMenu,
    canCreateKanban,
    canEditShiftParameters,
    canImportKanban,
    downloadDevDumpMenuAction,
    openShiftParametersDialogActionCallback,
    openExportMarketplaceInvoicingDialogActionCallback,
    t,
  ]);

  /* eslint-disable jsx-a11y/interactive-supports-focus */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <>
      <nav
        className="navbar is-primary is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          {canAccessDashboard ? (
            <NavLinkWithDirtyNavigationWarning
              preventNavigation={preventNavigation}
              showPreventNavigationModalAction={showPreventNavigationModalAction}
              className={`navbar-item ${computeUserIsOnGivenUrlClassName(
                location,
                DISPLAY_DASHBOARD_FULL_PATH
              )}`}
              to={DISPLAY_DASHBOARD_FULL_PATH}
            >
              <img
                src="img/logo.png"
                alt="stimcar"
                style={
                  !isOnline && isMobile
                    ? { boxShadow: '0 0 3pt 3pt darkred', borderRadius: '50%' }
                    : {}
                }
              />
            </NavLinkWithDirtyNavigationWarning>
          ) : (
            <div
              className={`navbar-item ${computeUserIsOnGivenUrlClassName(
                location,
                DISPLAY_DASHBOARD_FULL_PATH
              )}`}
            >
              <img
                src="img/logo.png"
                alt="stimcar"
                style={
                  !isOnline && isMobile
                    ? { boxShadow: '0 0 3pt 3pt darkred', borderRadius: '50%' }
                    : {}
                }
              />
            </div>
          )}
          {isNotAWorkshopOperator && (
            <>
              {canAccessKanbanDetails && (
                <NavLinkWithDirtyNavigationWarning
                  preventNavigation={preventNavigation}
                  showPreventNavigationModalAction={showPreventNavigationModalAction}
                  className={`navbar-item ${computeUserIsOnGivenUrlClassName(
                    location,
                    SELECT_KANBAN_FULL_PATH
                  )}`}
                  to={SELECT_KANBAN_FULL_PATH}
                >
                  {t('selectKanbanTab')}
                </NavLinkWithDirtyNavigationWarning>
              )}
              {canAccessArchives && (
                <NavLinkWithDirtyNavigationWarning
                  preventNavigation={preventNavigation}
                  showPreventNavigationModalAction={showPreventNavigationModalAction}
                  className={`navbar-item ${computeUserIsOnGivenUrlClassName(
                    location,
                    ARCHIVE_FULL_PATH
                  )}`}
                  to={ARCHIVE_FULL_PATH}
                >
                  {t('archiveTab')}
                </NavLinkWithDirtyNavigationWarning>
              )}
            </>
          )}
          {isMobile && isTruthyAndNotEmpty(currentLocationKanbanId) && (
            <div className="navbar-item">
              {getNavBarKanbanFromId(currentLocationKanbanId)?.infos.license}
            </div>
          )}
          {/* TODO replace by button when possible */}
          <a
            role="button"
            className={`navbar-burger burger${burgerIsActive ? ' is-active' : ''}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={toggleBurger}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div id="mainNavbar" className={`navbar-menu ${burgerIsActive ? ' is-active' : ''}`}>
          <div className="navbar-start">
            {isNotADisplayPost && (
              <>
                {isNotAWorkshopOperator ? (
                  <>
                    <NavLinkWithDirtyNavigationWarning
                      preventNavigation={preventNavigation}
                      showPreventNavigationModalAction={showPreventNavigationModalAction}
                      className="navbar-item"
                      to={WORKSHOP_DASHBOARD_FULL_PATH}
                    >
                      {t('navbar.displayWorkshops')}
                    </NavLinkWithDirtyNavigationWarning>
                    {isTruthy(handledKanban) && (
                      <NavLinkWithDirtyNavigationWarning
                        preventNavigation={preventNavigation}
                        showPreventNavigationModalAction={showPreventNavigationModalAction}
                        className={`navbar-item has-text-weight-bold ${computeUserIsOnGivenUrlClassName(
                          location,
                          computeOperatePath(handledKanban.id, standIdWhereKanbanIsHandled)
                        )}`}
                        to={computeOperatePath(handledKanban.id, standIdWhereKanbanIsHandled)}
                      >
                        <FaIcon id={currentHandleStandIcon} label={handledKanban.infos.license} />
                      </NavLinkWithDirtyNavigationWarning>
                    )}
                  </>
                ) : (
                  <>
                    <NavLinkWithDirtyNavigationWarning
                      preventNavigation={preventNavigation}
                      showPreventNavigationModalAction={showPreventNavigationModalAction}
                      className={`navbar-item ${computeUserIsOnGivenUrlClassName(
                        location,
                        WORKSHOP_OPERATOR_DASHBOARD_FULL_PATH
                      )}`}
                      to={WORKSHOP_OPERATOR_DASHBOARD_FULL_PATH}
                    >
                      {t('workshop.implantation')}
                    </NavLinkWithDirtyNavigationWarning>
                    <NavLinkWithDirtyNavigationWarning
                      preventNavigation={preventNavigation}
                      showPreventNavigationModalAction={showPreventNavigationModalAction}
                      className={`navbar-item ${computeUserIsOnGivenUrlClassName(
                        location,
                        WORKSHOP_OPERATOR_FULL_PATH
                      )}`}
                      to={WORKSHOP_OPERATOR_FULL_PATH}
                    >
                      {t('workshop.post')}
                    </NavLinkWithDirtyNavigationWarning>
                  </>
                )}
                {isTruthy(detailKanban) && canAccessKanbanDetails && (
                  <NavLinkWithDirtyNavigationWarning
                    preventNavigation={preventNavigation}
                    showPreventNavigationModalAction={showPreventNavigationModalAction}
                    className={`navbar-item ${computeUserIsOnGivenUrlClassName(
                      location,
                      computeKanbanDetailsPath(detailKanban.id)
                    )}`}
                    to={computeKanbanDetailsPath(detailKanban.id)}
                  >
                    <FaIcon id="info" label={detailKanban.infos.license} />
                  </NavLinkWithDirtyNavigationWarning>
                )}
                {isNotAWorkshopOperator && shouldAdminMenuBeVisible && (
                  <DropDownMenu
                    $active={$navbar.$adminMenuIsActive}
                    additionalClassNames={computeUserIsOnGivenUrlClassName(
                      location,
                      ADMIN_BASE_FULL_PATH
                    )}
                    title={t('admin.title')}
                  >
                    {keysOf(adminMenuGroups).map((groupId, index) => (
                      <React.Fragment key={groupId}>
                        <>{index !== 0 ? <hr className="navbar-divider" /> : <></>}</>
                        {adminMenuGroups[groupId].map(({ label, target }) =>
                          typeof target === 'string' ? (
                            <NavLinkWithDirtyNavigationWarning
                              key={label}
                              preventNavigation={preventNavigation}
                              showPreventNavigationModalAction={showPreventNavigationModalAction}
                              customNavigationAction={hideAdminMenu}
                              className="navbar-item"
                              to={target}
                            >
                              {label}
                            </NavLinkWithDirtyNavigationWarning>
                          ) : (
                            <a className="navbar-item" onClick={target} role="button" key={label}>
                              {label}
                            </a>
                          )
                        )}
                      </React.Fragment>
                    ))}
                  </DropDownMenu>
                )}
              </>
            )}
            {/* Display menu */}
            {(!user || browserInfos === undefined || Role.Display === browserInfos.role) && (
              <DisplayMenu
                computeUserIsOnGivenUrlClassName={computeUserIsOnGivenUrlClassName}
                $gs={$gs}
              />
            )}
          </div>
          <div className="navbar-end">
            {!burgerIsActive && browserInfos && (
              <>
                <div className="navbar-item">
                  <FaIcon
                    id="map-marker-alt"
                    tooltip={t('tooltips.site')}
                    label={browserInfos.site.name}
                  />
                </div>
                <div className="navbar-item">
                  <FaIcon
                    id={standOrRoleIconId}
                    tooltip={t('tooltips.role')}
                    label={t(`globals:roles.${browserInfos.role}`)}
                  />
                </div>
                <div className="navbar-item">
                  <FaIcon id="laptop" tooltip={t('tooltips.label')} label={displayedPostLabel} />
                </div>
              </>
            )}
            {user ? (
              <>
                <DropDownMenu
                  $active={$navbar.$userMenuIsActive}
                  title={userHelpers.getDisplayName(user)}
                  iconId="user"
                  iconTooltip={t('tooltips.user')}
                  isRight
                >
                  {browserInfos?.role !== Role.WorkshopOperator && (
                    <NavLinkWithDirtyNavigationWarning
                      to="/"
                      className="navbar-item"
                      preventNavigation={preventNavigation}
                      showPreventNavigationModalAction={showPreventNavigationModalAction}
                      customNavigationAction={showSwitchToRoleDialogActionCallback}
                    >
                      {t('user.switchRole')}
                    </NavLinkWithDirtyNavigationWarning>
                  )}
                  <NavLinkWithDirtyNavigationWarning
                    to="/"
                    className="navbar-item"
                    preventNavigation={preventNavigation}
                    showPreventNavigationModalAction={showPreventNavigationModalAction}
                    customNavigationAction={openChangePasswordCallback}
                  >
                    {t('user.changePassword')}
                  </NavLinkWithDirtyNavigationWarning>
                  <NavLinkWithDirtyNavigationWarning
                    to="/"
                    className="navbar-item disabled"
                    preventNavigation={preventNavigation}
                    showPreventNavigationModalAction={showPreventNavigationModalAction}
                    customNavigationAction={showResetDialogActionCallback}
                  >
                    {t('user.reset')}
                  </NavLinkWithDirtyNavigationWarning>
                  <NavLinkWithDirtyNavigationWarning
                    to="/"
                    className="navbar-item"
                    preventNavigation={preventNavigation}
                    showPreventNavigationModalAction={showPreventNavigationModalAction}
                    customNavigationAction={logoutOrWarnAboutHandledKanbanCallback}
                  >
                    {t('user.logout')}
                  </NavLinkWithDirtyNavigationWarning>
                </DropDownMenu>
              </>
            ) : (
              <div className="navbar-item">
                <a role="button" onClick={showLoginModal}>
                  <FaIcon id="user" tooltip={t('user.login')} />
                </a>
              </div>
            )}
            {!burgerIsActive && browserInfos && <NetworkIconWithRepositoryChanges $gs={$gs} />}
          </div>
        </div>
      </nav>
      <SwitchToRoleDialog $gs={$gs} />
      <LoginModalDialog
        isWorkshopLogin={isWorkshopLogin}
        $={$gs.$loginView}
        loginActionCallback={loginActionCallback}
        cancelLoginActionCallback={cancelLoginAndCloseLoginDialogActionCallback}
      />
      <ResetDialog
        $={$resetDialogState}
        onResetTriggerAction={onResetTriggerAction}
        resetCallback={resetCallback}
      />
      <LogoutWithWIPModal $gs={$gs} />
      <NavigationWithWIPModal $gs={$gs} />
      <ModalCardDialog
        $active={$navbar.$showPossibleDataLossModal}
        titleIconId="exclamation-triangle"
        title={t('logoutModal.notEmptyActionLogModalTitle')}
        onOkClicked={closeDataLossModalActionCallback}
        dontShowCancelButton
      >
        {t('logoutModal.notEmptyActionLogModalContent')}
      </ModalCardDialog>
      <UpdatePasswordModal $={$navbar.$updatePasswordDialogState} $gs={$gs} />
      <ShiftProgressDisplayConfigurationDialog $gs={$gs} />
      <ExportMarketplaceInvoicingDialog $gs={$gs} />
    </>
  );
}
