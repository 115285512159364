import type { Kanban, StandAchievements, WithProgress, WORKFLOW_ID_TYPE } from '@stimcar/libs-base';
import type { WorkshopImplantationViewState } from '../../../lib/components/workshop/typings/store.js';
import type { ComponentWithMountTracking } from '../../../lib/hooks/useComponentWithMountTracking.js';
import type { KanbansBoardDisplayState } from '../kanbansBoard/typings/store.js';
import type { ShiftProgressDisplayState } from '../shift/typings/store.js';
import { WORKSHOP_IMPLANTATION_VIEW_EMPTY_STATE } from '../../../lib/components/workshop/typings/store.js';
import { EMPTY_KANBANS_BOARD_DISPLAY_STATE } from '../kanbansBoard/typings/store.js';
import { EMPTY_SHIFT_PROGRESS_DISPLAY_STATE } from '../shift/typings/store.js';

export type StandDisplayState = {
  readonly kanbans: readonly WithProgress<Kanban>[];
  readonly achievements: StandAchievements;
};

export type WorkflowDisplayState = {
  readonly stands: Record<WORKFLOW_ID_TYPE, StandDisplayState>;
  readonly kanbansCount: number;
};

export type StandAchievementsDisplayState = {
  readonly workflowId: string;
  readonly standId: string;
  readonly size: number;
  readonly active: boolean;
};

const EMPTY_STAND_ACHIEVEMENTS_DISPLAY_STATE: StandAchievementsDisplayState = {
  workflowId: '',
  standId: '',
  size: 2,
  active: false,
};

export type DisplayViewState = ComponentWithMountTracking & {
  readonly workflowsStates: Record<WORKFLOW_ID_TYPE, WorkflowDisplayState>;
  readonly menuIsActive: boolean;
  readonly standDisplay: StandAchievementsDisplayState;
  readonly workshopImplantationView: WorkshopImplantationViewState;
  readonly shiftProgressDisplay: ShiftProgressDisplayState;
  readonly kanbansBoardDisplay: KanbansBoardDisplayState;
  readonly dashboardSelectedContracts: string[];
};

export type DisplayType =
  | 'KanbanBoard'
  | 'KanbanBoardNG'
  | 'StandAchievements'
  | 'Workshop'
  | 'ShiftProgress';

export const DEFAULT_DASHBOARD_FILTER_ID = 'all';

export const EMPTY_DISPLAY_VIEW_STATE: DisplayViewState = {
  componentIsMounted: false,
  workflowsStates: {},
  menuIsActive: false,
  standDisplay: EMPTY_STAND_ACHIEVEMENTS_DISPLAY_STATE,
  workshopImplantationView: WORKSHOP_IMPLANTATION_VIEW_EMPTY_STATE,
  shiftProgressDisplay: EMPTY_SHIFT_PROGRESS_DISPLAY_STATE,
  kanbansBoardDisplay: EMPTY_KANBANS_BOARD_DISPLAY_STATE,
  dashboardSelectedContracts: [],
};
