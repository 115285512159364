import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AnyStoreDef, NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { documentEventListenerWrapper } from '../../../utils/DocumentEventListenerWrapper.js';
import { Button } from '../elements/Button.js';
import { FaIcon } from '../elements/FaIcon.js';

export interface WizardPage<SD extends AnyStoreDef> {
  readonly content: JSX.Element;
  readonly nextAdditionalAction?: NoArgActionCallback<SD>;
  readonly isNextInactive: boolean;
  readonly backwardAdditionalAction?: NoArgActionCallback<SD>;
}

const defaultModalPage: WizardPage<AnyStoreDef> = {
  isNextInactive: true,
  content: <div>default</div>,
};

interface ModalWizardProps<SD extends AnyStoreDef> {
  readonly pageTitleProvider: (index: number) => string;
  readonly pageTitleIconProvider?: (index: number) => string | undefined;
  readonly isNarrow?: boolean;
  readonly wizardPages: WizardPage<SD>[];
  readonly onCancelClicked?: NoArgActionCallback<SD>;
  readonly $active: StoreStateSelector<SD, boolean>;
  readonly $activePage: StoreStateSelector<SD, number>;
  readonly zIndex?: number;
  readonly isFullWidth?: boolean;
}

export function ModalWizard<SD extends AnyStoreDef>({
  pageTitleProvider,
  pageTitleIconProvider,
  $active,
  isNarrow,
  wizardPages,
  onCancelClicked,
  $activePage,
  zIndex,
  isFullWidth,
}: ModalWizardProps<SD>): JSX.Element {
  const active = useGetState($active);
  return (
    <>
      {active && (
        <InternalModalWizard
          pageTitleProvider={pageTitleProvider}
          pageTitleIconProvider={pageTitleIconProvider}
          isNarrow={isNarrow}
          $active={$active}
          wizardPages={wizardPages}
          onCancelClicked={onCancelClicked}
          $activePage={$activePage}
          zIndex={zIndex}
          isFullWidth={isFullWidth}
        />
      )}
    </>
  );
}

interface CancelButtonProps<SD extends AnyStoreDef> {
  readonly handler: NoArgActionCallback<SD>;
}

function CancelButton<SD extends AnyStoreDef>({ handler }: CancelButtonProps<SD>): JSX.Element {
  const [t] = useTranslation('bulma');
  return <Button additionalClass="button" label={t('buttons.cancel')} onClick={handler} />;
}

// FIXME This component should use ModalDialog component (or ModalDialogWithoutDispatch)
function InternalModalWizard<SD extends AnyStoreDef>({
  pageTitleProvider,
  pageTitleIconProvider,
  $active,
  isNarrow = false,
  wizardPages,
  onCancelClicked,
  $activePage,
  zIndex = 10,
  isFullWidth = false,
}: ModalWizardProps<SD>): JSX.Element {
  const [t] = useTranslation('bulma');

  const active = useGetState($active);
  const activePage = useGetState($activePage);

  const closeModalHandler = useActionCallback(
    async ({ globalActionDispatch, actionDispatch }): Promise<void> => {
      // Close the dialog
      actionDispatch.setValue(false);
      // Run the trigger if provided
      if (onCancelClicked) {
        await globalActionDispatch.execCallback(onCancelClicked);
      }
    },
    [onCancelClicked],
    $active
  );

  useEffect((): (() => void) => {
    function eventListener(e: KeyboardEvent): void {
      if (e.key === 'Escape') {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        closeModalHandler();
      }
    }
    // Register a click listener
    documentEventListenerWrapper.addEventListener('keydown', eventListener);
    return (): void => {
      // Unregister the click listener
      documentEventListenerWrapper.removeEventListener('keydown', eventListener);
    };
  }, [closeModalHandler]);

  const page = useMemo(() => {
    return wizardPages[activePage] ?? defaultModalPage;
  }, [activePage, wizardPages]);

  const nextButtonHandler = useActionCallback(
    async ({ getState, actionDispatch, globalActionDispatch }): Promise<void> => {
      const activePage = getState();
      if (page.nextAdditionalAction) {
        await actionDispatch.execCallback(page.nextAdditionalAction);
      }
      if (activePage < wizardPages.length - 1) {
        actionDispatch.setValue(activePage + 1);
      } else {
        await globalActionDispatch.execCallback(closeModalHandler);
      }
    },
    [closeModalHandler, page, wizardPages.length],
    $activePage
  );

  const previousButtonHandler = useActionCallback(
    async ({ globalActionDispatch, actionDispatch, getState }): Promise<void> => {
      const activePage = getState();
      if (page.backwardAdditionalAction) {
        await globalActionDispatch.execCallback(page.backwardAdditionalAction);
      }
      if (activePage !== 0) {
        actionDispatch.setValue(activePage - 1);
      }
    },
    [page.backwardAdditionalAction],
    $activePage
  );

  const computeWizardFooterButtons = (): JSX.Element => {
    if (activePage === 0) {
      return (
        <>
          <Button
            additionalClass="modal-button-close button"
            label={t('buttons.previous')}
            onClick={previousButtonHandler}
            disabled
          />
          <Button
            additionalClass="modal-button-close button is-primary"
            label={t('buttons.next')}
            onClick={nextButtonHandler}
            disabled={page.isNextInactive}
          />
          <CancelButton handler={closeModalHandler} />
        </>
      );
    }
    if (activePage === wizardPages.length - 1) {
      return (
        <>
          <Button
            additionalClass="modal-button-close button"
            label={t('buttons.previous')}
            onClick={previousButtonHandler}
          />
          <Button
            additionalClass="modal-button-close button is-primary"
            label={t('buttons.submit')}
            onClick={nextButtonHandler}
            disabled={page.isNextInactive}
          />
          <CancelButton handler={closeModalHandler} />
        </>
      );
    }
    return (
      <>
        <Button
          additionalClass="modal-button-close button"
          label={t('buttons.previous')}
          onClick={previousButtonHandler}
        />
        <Button
          additionalClass="modal-button-close button is-primary"
          label={t('buttons.next')}
          onClick={nextButtonHandler}
        />
        <CancelButton handler={closeModalHandler} />
      </>
    );
  };
  const pageIconId = pageTitleIconProvider ? pageTitleIconProvider(activePage) : undefined;
  const pageTitle = pageTitleProvider(activePage);
  return (
    <div
      style={{ zIndex }}
      className={`modal${isFullWidth ? ' is-fullscreen' : ''}${active ? ' is-active' : ''}`}
    >
      <div
        className="modal-background"
        role="button"
        onClick={closeModalHandler}
        onKeyUp={closeModalHandler}
        tabIndex={-100}
        aria-label="Close"
      />
      <div className="modal-content modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {pageIconId ? <FaIcon id={pageIconId} tooltip={pageTitle} /> : ''}
            {pageTitle}
          </p>
          <Button
            additionalClass="modal-button-close delete"
            onClick={closeModalHandler}
            aria-label="close"
          />
        </header>
        <section className="modal-card-body" style={isNarrow ? { padding: '10px' } : {}}>
          <div>{page.content}</div>
        </section>
        <footer className="modal-card-foot">{computeWizardFooterButtons()}</footer>
      </div>
    </div>
  );
}
