import type { CoreFields, RepositoryEntity, SpecificFields } from '../model/typings/repository.js';

export const getSpecificFields = <T extends RepositoryEntity>(entity: T): SpecificFields<T> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, timestamp, sequenceId, status, dirty, ...specificFields } = entity;
  return specificFields;
};

export const getCoreFields = <T extends RepositoryEntity>(entity: T): CoreFields<T> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { timestamp, sequenceId, status, dirty, ...coreFields } = entity;
  return coreFields;
};
