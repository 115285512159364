import { EMPTY_IMAGE_MODAL_STATE, EMPTY_LOGIN_MODAL_DIALOG_STATE } from '@stimcar/libs-uitoolkit';
import { SUBCONTRACTOR_OPERATE_EMPTY_STATE } from '../operate/typings/store.js';
import { SUBCONTRACTOR_SELECT_KANBAN_EMPTY_STATE } from '../selectKanban/typings/store.js';
import type { SubcontractorSessionState, SubcontractorStoreState } from './typings/store.js';

export const EMPTY_SUBCONTRACTOR_SESSION_STATE: SubcontractorSessionState = {
  isOnline: false,
  selectedSite: '',
  availableSites: [],
};

export const EMPTY_SUBCONTRACTOR_STORE_STATE: SubcontractorStoreState = {
  imageModal: EMPTY_IMAGE_MODAL_STATE,
  message: false,
  navbar: {
    burgerIsActive: false,
    userMenuIsActive: false,
  },
  window: {
    height: 0,
    width: 0,
    bulmaMode: 'Mobile',
  },
  session: EMPTY_SUBCONTRACTOR_SESSION_STATE,
  loginView: EMPTY_LOGIN_MODAL_DIALOG_STATE,
  selectKanban: SUBCONTRACTOR_SELECT_KANBAN_EMPTY_STATE,
  operate: SUBCONTRACTOR_OPERATE_EMPTY_STATE,
};
