/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDeal } from '@stimcar/libs-base';
import type {
  ActionContext,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import {
  carElementHelpers,
  nonDeleted,
  packageDealHelpers,
  purchaseOrderHelpers,
  sortingHelpers,
} from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  ClickableIcon,
  FaIcon,
  onGenericMoveSelectionUpOrDownHandlerAction,
  ScrollableTableComponent,
  TableActionHeaderCell,
  TruncableTableTd,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { OperatorExpertViewState, OperatorViewState } from '../../typings/store.js';
import { TableSortableHeaderComponent } from '../../../../lib/components/TableSortableHeaderComponent.js';
import {
  selectPackageDealAction,
  updateStandRelatedPackageDealsAndOperationsAction,
} from '../../expertiseUtils.js';
import { openDialogForModification } from './EditPackageDealModalComponent.js';

async function toggleExpertRecommendationAction(
  { actionDispatch, getState }: ActionContext<Store, OperatorExpertViewState>,
  id: string,
  updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>
): Promise<void> {
  const { packageDealListComponentState, initialKanban } = getState();
  const newPackageDeals = packageDealListComponentState.packageDeals.map((pkg): PackageDeal => {
    if (pkg.id === id) {
      return {
        ...pkg,
        recommendedByExpert: !pkg.recommendedByExpert,
      };
    }
    return pkg;
  });
  const updatePackageDeals = packageDealHelpers.updateAllPackageDealsExpressionComputations(
    {
      ...initialKanban,
      packageDeals: newPackageDeals,
    },
    true
  );
  actionDispatch.scopeProperty('packageDealListComponentState').reduce((initial) => {
    return {
      ...initial,
      packageDeals: updatePackageDeals,
    };
  });

  await actionDispatch.execCallback(updateStandRelatedPackageDealsAndOperationsActionCallback);
}

function allPackageDealsAreRecommended(packageDeals: readonly PackageDeal[]): boolean {
  return packageDeals.every(({ recommendedByExpert }) => recommendedByExpert);
}

async function toggleAllExpertRecommendationsAction(
  { actionDispatch, getState }: ActionContext<Store, OperatorExpertViewState>,
  updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>
): Promise<void> {
  const { packageDealListComponentState, initialKanban } = getState();
  const allPackagedealsAreRecommended = allPackageDealsAreRecommended(
    packageDealListComponentState.packageDeals
  );
  const newPackageDeals = packageDealListComponentState.packageDeals.map((packageDeal) => {
    if (
      allPackagedealsAreRecommended &&
      packageDealHelpers.isPackageDealStartedOrUnremovable(packageDeal)
    ) {
      return packageDeal;
    }
    return {
      ...packageDeal,
      recommendedByExpert: !allPackagedealsAreRecommended,
    };
  });
  const updatePackageDeals = packageDealHelpers.updateAllPackageDealsExpressionComputations(
    {
      ...initialKanban,
      packageDeals: newPackageDeals,
    },
    true
  );
  actionDispatch.scopeProperty('packageDealListComponentState').reduce((initial) => {
    return {
      ...initial,
      packageDeals: updatePackageDeals,
    };
  });

  await actionDispatch.execCallback(updateStandRelatedPackageDealsAndOperationsActionCallback);
}

export function openDialogForDeletion(
  { actionDispatch, getState }: ActionContext<Store, OperatorExpertViewState>,
  pddId: string
): void {
  const { packageDealListComponentState: listState } = getState();
  const selectedPD = listState.packageDeals.find((pdd) => pdd.id === pddId);
  if (selectedPD) {
    actionDispatch.reduce((initial: OperatorExpertViewState): OperatorExpertViewState => {
      const updatedState: OperatorExpertViewState = {
        ...initial,
        deletionModalState: {
          ...initial.deletionModalState,
          packageDealId: selectedPD.id,
          packageDealLabel: packageDealHelpers.getPackageDealDisplayedLabel(selectedPD),
          active: true,
        },
      };
      return updatedState;
    });
  }
}

interface PackageDealItemProps {
  readonly packageDeal: PackageDeal;
  readonly hasFocus: boolean;
  readonly carElementLabel: string;
  readonly selectPackageDealCallback: (id: string) => Promise<void>;
  readonly selectedKanbanId: string;
  readonly isEditionDisabled: boolean;
  readonly $: StoreStateSelector<Store, OperatorViewState>;
}

function PackageDealItem({
  packageDeal,
  hasFocus,
  carElementLabel,
  selectPackageDealCallback,
  selectedKanbanId,
  isEditionDisabled,
  $,
}: PackageDealItemProps): JSX.Element {
  const [t] = useTranslation('operators');

  const { id, duration, carElement, price, recommendedByExpert, status } = packageDeal;
  const cssClasses = useMemo((): string => {
    let classes = status === 'available' ? 'has-text-weight-bold' : '';
    if (selectedKanbanId === id) {
      classes += ' is-selected no-border-cells';
      if (!hasFocus) {
        classes += ' has-background-grey-light';
      }
    }
    return classes;
  }, [hasFocus, id, selectedKanbanId, status]);

  const selectionCallback = useCallback(async (): Promise<void> => {
    await selectPackageDealCallback(id);
  }, [id, selectPackageDealCallback]);

  const filesCount = packageDeal.attachments?.filter(nonDeleted).length;

  const openEditDialogActionCallback = useActionCallback(
    async ({ actionDispatch }, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.stopPropagation();
      await actionDispatch.exec(openDialogForModification, id);
    },
    [id],
    $.$expertOperatorState
  );

  const openDialogForDeletionActionCallback = useActionCallback(
    async ({ actionDispatch }, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.stopPropagation();
      await actionDispatch.exec(openDialogForDeletion, id);
    },
    [id],
    $.$expertOperatorState
  );

  const cannotRemoveRecommendation = useMemo(
    () =>
      isEditionDisabled ||
      (recommendedByExpert && packageDealHelpers.isPackageDealStartedOrUnremovable(packageDeal)),
    [isEditionDisabled, packageDeal, recommendedByExpert]
  );

  const expertRecommandationCheckboxToolTip = useMemo(() => {
    if (recommendedByExpert) {
      if (cannotRemoveRecommendation) {
        return t('expertiseView.packageDealTable.tooltip.disabledRecommandationCheckbox');
      }
      return t('expertiseView.packageDealTable.tooltip.unCheck');
    }
    return t('expertiseView.packageDealTable.tooltip.check');
  }, [recommendedByExpert, cannotRemoveRecommendation, t]);

  const isUnremovable = useMemo(
    () => isEditionDisabled || packageDealHelpers.isPackageDealStartedOrUnremovable(packageDeal),
    [isEditionDisabled, packageDeal]
  );

  const updateStandRelatedPackageDealsAndOperationsActionCallback = useActionCallback(
    updateStandRelatedPackageDealsAndOperationsAction,
    [],
    $
  );

  const toggleExpertRecommendationActionCallback = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(
        toggleExpertRecommendationAction,
        id,
        updateStandRelatedPackageDealsAndOperationsActionCallback
      ),
    [id, updateStandRelatedPackageDealsAndOperationsActionCallback],
    $.$expertOperatorState
  );

  return (
    <tr className={cssClasses} style={{ cursor: 'pointer' }} onClick={selectionCallback}>
      <td>
        <input
          type="checkbox"
          onChange={toggleExpertRecommendationActionCallback}
          checked={recommendedByExpert}
          title={expertRecommandationCheckboxToolTip}
          style={{
            cursor: `${cannotRemoveRecommendation ? 'not-allowed' : 'pointer'}`,
          }}
          disabled={cannotRemoveRecommendation}
        />
      </td>
      <TruncableTableTd className="has-text-centered">
        {filesCount ? String(filesCount) : ''}
      </TruncableTableTd>
      <TruncableTableTd className="has-text-centered">
        {carElementHelpers.getCarElementIndexToDisplayWithPadding(carElement)}
      </TruncableTableTd>
      <TruncableTableTd>{carElementLabel}</TruncableTableTd>
      <TruncableTableTd>
        {packageDealHelpers.getPackageDealDisplayedLabel(packageDeal)}
      </TruncableTableTd>
      <TruncableTableTd className="has-text-right">{duration.toFixed(2)}</TruncableTableTd>
      <TruncableTableTd className="has-text-right">{price.toFixed(2)}</TruncableTableTd>
      <TruncableTableTd className="has-text-centered">
        <ClickableIcon
          clickHandler={openEditDialogActionCallback}
          isDisabled={isEditionDisabled}
          id="edit"
        />
      </TruncableTableTd>
      <TruncableTableTd className="has-text-centered">
        <ClickableIcon
          clickHandler={openDialogForDeletionActionCallback}
          isDisabled={isUnremovable}
          id="trash"
          tooltip={
            isUnremovable
              ? t('expertiseView.packageDealTable.tooltip.disabledDeletePackageDeal')
              : t('expertiseView.packageDealTable.tooltip.deletePackageDeal')
          }
        />
      </TruncableTableTd>
    </tr>
  );
}

interface Props {
  readonly isFocused: boolean;
  readonly isEditionDisabled: boolean;
  readonly recomputeSizeHint: number; // a change in this number is used to indicate the component size should be recalculated
  readonly $: StoreStateSelector<Store, OperatorViewState>;
  readonly packageDeals: readonly PackageDeal[];
}

/**
 * React component used to display the general informations of a vehicle.
 *
 * Those informations may or may not be readonly depending of the context where this component is used.
 */
export function ExpertPackageDealListComponent({
  $,
  isFocused,
  isEditionDisabled,
  recomputeSizeHint,
  packageDeals,
}: Props): JSX.Element {
  const [t] = useTranslation('operators');

  const { $packageDealListComponentState } = $.$expertOperatorState;

  const sortBy = useGetState($.$expertOperatorState.$packageDealListComponentState.$sort.$by);
  const sortDirection = useGetState(
    $.$expertOperatorState.$packageDealListComponentState.$sort.$direction
  );

  const sortedPackageDeals = useMemo((): PackageDeal[] => {
    const activePackageDeals = packageDeals.filter(nonDeleted);

    let sortFunction: (pd1: PackageDeal, pd2: PackageDeal) => number;
    switch (sortBy) {
      case 'carElement':
        sortFunction =
          sortingHelpers.createSortPackageDealsByCarElementLabelFunction(sortDirection);
        break;
      case 'index':
        sortFunction =
          sortingHelpers.createSortPackageDealByCarElementIndexThenLabelFunction(sortDirection);
        break;
      case 'code':
      case 'label':
      case 'duration':
      case 'status':
        sortFunction = sortingHelpers.createSortByStringField<PackageDeal>(sortDirection, sortBy);
        break;
      case 'price':
        sortFunction = sortingHelpers.createSortPackageDealsByPriceFunction(sortDirection);
        break;
      default:
        return activePackageDeals.reverse();
    }

    return activePackageDeals.sort(sortFunction);
  }, [sortBy, sortDirection, packageDeals]);

  const selectedTotalPrice = useMemo(() => {
    const availablePackageDeals = packageDeals.filter(
      packageDealHelpers.buildPackageDealFilter('available', true)
    );
    return packageDealHelpers.getPackageDealsPriceWithoutVAT(availablePackageDeals);
  }, [packageDeals]);

  const selectedTotalDuration = useMemo(() => {
    return packageDeals
      .filter(nonDeleted)
      .filter((pkg) => packageDealHelpers.isPackageDealAvailable(pkg, true))
      .map((pkg): number => pkg.duration)
      .reduce((a, b) => a + b, 0);
  }, [packageDeals]);

  const unselectedTotalPrice = useMemo(() => {
    const canceledPackageDeals = packageDeals.filter(
      packageDealHelpers.buildPackageDealFilter('canceled', true)
    );
    return packageDealHelpers.getPackageDealsPriceWithoutVAT(canceledPackageDeals);
  }, [packageDeals]);

  const unselectedTotalDuration = useMemo(() => {
    return packageDeals
      .filter(nonDeleted)
      .filter((pkg) => packageDealHelpers.isPackageDealCanceled(pkg, true))
      .map((pkg): number => pkg.duration)
      .reduce((a, b) => a + b, 0);
  }, [packageDeals]);

  const updateStandRelatedPackageDealsAndOperationsActionCallback = useActionCallback(
    updateStandRelatedPackageDealsAndOperationsAction,
    [],
    $
  );

  const toggleAllExpertRecommendationsActionCallback = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(
        toggleAllExpertRecommendationsAction,
        updateStandRelatedPackageDealsAndOperationsActionCallback
      ),
    [updateStandRelatedPackageDealsAndOperationsActionCallback],
    $.$expertOperatorState
  );

  const selectPackageDealCallback = useActionCallback(
    async ({ actionDispatch }, packageDealId: string) => {
      await actionDispatch.exec(selectPackageDealAction, packageDealId);
    },
    [],
    $.$expertOperatorState
  );

  const selectedPackageDealId = useGetState(
    $.$expertOperatorState.$detailsAndMessagesComponentState.$selectedPackageDealId
  );

  const moveSelectionUpOrDownActionActionCallback = useActionCallback(
    async (
      { actionDispatch, getState },
      event: React.KeyboardEvent<HTMLDivElement>
    ): Promise<void> => {
      const { selectedPackageDealId } = getState().detailsAndMessagesComponentState;
      await actionDispatch.exec(
        onGenericMoveSelectionUpOrDownHandlerAction<Store, OperatorExpertViewState, PackageDeal>,
        selectedPackageDealId,
        selectPackageDealAction,
        event,
        sortedPackageDeals
      );
    },
    [sortedPackageDeals],
    $.$expertOperatorState
  );

  const isChecked = useMemo((): boolean => {
    return allPackageDealsAreRecommended(packageDeals.filter(nonDeleted));
  }, [packageDeals]);

  return (
    <ScrollableTableComponent
      isTruncable
      tableClassName="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
      recomputeSizeHint={recomputeSizeHint}
    >
      <thead>
        <tr>
          <th style={{ width: '1.875em' }}>
            <input
              type="checkbox"
              onChange={toggleAllExpertRecommendationsActionCallback}
              checked={isChecked}
              disabled={isEditionDisabled}
              title={
                isChecked
                  ? t('expertiseView.packageDealTable.tooltip.unCheckAll')
                  : t('expertiseView.packageDealTable.tooltip.checkAll')
              }
            />
          </th>
          <th style={{ width: '4%' }} className="has-text-centered">
            <FaIcon id="image" />
          </th>
          <TableSortableHeaderComponent
            content={t('expertiseView.packageDealTable.carElementIndex')}
            isTruncable
            sortedField="index"
            $sort={$packageDealListComponentState.$sort}
            style={{ width: '6%' }}
            centerLabel={false}
          />
          <TableSortableHeaderComponent
            content={t('expertiseView.packageDealTable.carElement')}
            isTruncable
            sortedField="carElement"
            $sort={$packageDealListComponentState.$sort}
            style={{ width: '21%' }}
            centerLabel={false}
          />
          <TableSortableHeaderComponent
            content={t('expertiseView.packageDealTable.label')}
            isTruncable
            sortedField="label"
            $sort={$packageDealListComponentState.$sort}
            style={{ width: '45%' }}
            centerLabel={false}
          />
          <TableSortableHeaderComponent
            content={t('expertiseView.packageDealTable.duration')}
            isTruncable
            sortedField="duration"
            $sort={$packageDealListComponentState.$sort}
            style={{ width: '15%' }}
          />
          <TableSortableHeaderComponent
            content={t('expertiseView.packageDealTable.price')}
            isTruncable
            sortedField="price"
            $sort={$packageDealListComponentState.$sort}
            style={{ width: '15%' }}
          />
          <TableActionHeaderCell />
          <TableActionHeaderCell />
        </tr>
      </thead>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */}
      <tbody onKeyDown={moveSelectionUpOrDownActionActionCallback} tabIndex={0} role="menubar">
        {packageDeals.filter(nonDeleted).length === 0 && (
          <tr key="emptyline">
            <td>&nbsp;</td>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
        )}
        <PackageDealGroupedByPurchaseOrdersRows
          $={$}
          isFocused={isFocused}
          isEditionDisabled={isEditionDisabled}
          sortedPackageDeals={sortedPackageDeals}
          selectedPackageDealId={selectedPackageDealId}
          selectPackageDealCallback={selectPackageDealCallback}
        />
        <tr>
          <td colSpan={5} className="has-text-right has-text-weight-bold">
            {t('expertiseView.packageDealTable.selectedTotal')}
          </td>
          <td className="has-text-right has-text-weight-bold">
            {selectedTotalDuration.toFixed(2)}
          </td>
          <td className="has-text-right has-text-weight-bold">{selectedTotalPrice.toFixed(2)}</td>
          <td />
          <td />
        </tr>
        <tr>
          <td colSpan={5} className="has-text-right has-text-weight-bold">
            {t('expertiseView.packageDealTable.unselectedTotal')}
          </td>
          <td className="has-text-right has-text-weight-bold">
            {unselectedTotalDuration.toFixed(2)}
          </td>
          <td className="has-text-right has-text-weight-bold">{unselectedTotalPrice.toFixed(2)}</td>
          <td />
          <td />
        </tr>
      </tbody>
    </ScrollableTableComponent>
  );
}

interface PackageDealGroupedByPurchaseOrdersRowsProps {
  readonly isFocused: boolean;
  readonly isEditionDisabled: boolean;
  readonly selectedPackageDealId: string;
  readonly sortedPackageDeals: readonly PackageDeal[];
  readonly $: StoreStateSelector<Store, OperatorViewState>;
  readonly selectPackageDealCallback: (packageDealId: string) => Promise<void>;
}

function PackageDealGroupedByPurchaseOrdersRows({
  $,
  isEditionDisabled,
  sortedPackageDeals,
  selectedPackageDealId,
  isFocused,
  selectPackageDealCallback,
}: PackageDealGroupedByPurchaseOrdersRowsProps): JSX.Element {
  const [t] = useTranslation('operators');

  const purchaseOrders = useGetState($.$expertOperatorState.$initialKanban.$purchaseOrders);

  const hasMultiplePurchaseOrders = useMemo(
    () => purchaseOrderHelpers.hasMultiplePurchaseOrders(purchaseOrders),
    [purchaseOrders]
  );

  const packageDealsByPurchaseOrderSortedEntries = useMemo(() => {
    const packageDealsGroupedByPurchaseOrders =
      purchaseOrderHelpers.getPackageDealsWithCostByPurchaseOrder(
        purchaseOrders,
        sortedPackageDeals
      );
    return Object.entries(packageDealsGroupedByPurchaseOrders).sort(([a], [b]) =>
      a.localeCompare(b)
    );
  }, [sortedPackageDeals, purchaseOrders]);

  const notAllocatedPackageDeals = useMemo(
    () => sortedPackageDeals.filter(({ purchaseOrderId }) => !purchaseOrderId),
    [sortedPackageDeals]
  );

  const notAllocatedPackageDealsPrice = useMemo(() => {
    const notAllocatedAndAvailablePackageDeals = notAllocatedPackageDeals.filter(
      packageDealHelpers.buildPackageDealFilter('available', true)
    );
    return packageDealHelpers.getPackageDealsPriceWithoutVAT(notAllocatedAndAvailablePackageDeals);
  }, [notAllocatedPackageDeals]);

  return (
    <>
      {packageDealsByPurchaseOrderSortedEntries.map(
        ([purchaseOrderDisplayedLabel, { packageDeals, cost }]) => (
          <PurchaseOrderPackageDealRows
            $={$}
            cost={cost}
            isFocused={isFocused}
            packageDeals={packageDeals}
            key={purchaseOrderDisplayedLabel}
            isEditionDisabled={isEditionDisabled}
            selectedPackageDealId={selectedPackageDealId}
            purchaseOrderLabel={purchaseOrderDisplayedLabel}
            hasMultiplePurchaseOrders={hasMultiplePurchaseOrders}
            selectPackageDealCallback={selectPackageDealCallback}
          />
        )
      )}
      {notAllocatedPackageDeals.length > 0 && (
        <PurchaseOrderPackageDealRows
          $={$}
          isFocused={isFocused}
          isEditionDisabled={isEditionDisabled}
          cost={notAllocatedPackageDealsPrice}
          packageDeals={notAllocatedPackageDeals}
          selectedPackageDealId={selectedPackageDealId}
          hasMultiplePurchaseOrders={hasMultiplePurchaseOrders}
          selectPackageDealCallback={selectPackageDealCallback}
          purchaseOrderLabel={t(
            'expertiseView.packageDealTable.packageDealsNotLinkedToPurchaseOrder'
          )}
        />
      )}
    </>
  );
}

interface PurchaseOrderPackageDealRowsProps {
  readonly cost?: number;
  readonly purchaseOrderLabel: string;
  readonly packageDeals: readonly PackageDeal[];
  readonly isFocused: boolean;
  readonly isEditionDisabled: boolean;
  readonly selectedPackageDealId: string;
  readonly $: StoreStateSelector<Store, OperatorViewState>;
  readonly selectPackageDealCallback: (packageDealId: string) => Promise<void>;
  readonly hasMultiplePurchaseOrders: boolean;
}

function PurchaseOrderPackageDealRows({
  $,
  cost,
  isFocused,
  packageDeals,
  isEditionDisabled,
  purchaseOrderLabel,
  selectedPackageDealId,
  hasMultiplePurchaseOrders,
  selectPackageDealCallback,
}: PurchaseOrderPackageDealRowsProps): JSX.Element {
  const [t] = useTranslation('operators');

  return (
    <>
      {hasMultiplePurchaseOrders && (
        <PurchaseOrderRows
          hasNoPackageDeals={packageDeals.length === 0}
          purchaseOrderLabel={purchaseOrderLabel}
          cost={packageDeals.length > 0 ? cost : undefined}
        />
      )}
      {packageDeals.map(
        (packageDeal: PackageDeal): JSX.Element => (
          <PackageDealItem
            $={$}
            hasFocus={isFocused}
            key={packageDeal.id}
            packageDeal={packageDeal}
            isEditionDisabled={isEditionDisabled}
            selectedKanbanId={selectedPackageDealId}
            selectPackageDealCallback={selectPackageDealCallback}
            carElementLabel={
              packageDeal.carElement
                ? packageDeal.carElement.label
                : t('expertiseView.packageDealTable.miscOperationCarElementPlaceholder')
            }
          />
        )
      )}
    </>
  );
}

interface PurchaseOrderRowsProps {
  readonly cost?: number;
  readonly hasNoPackageDeals: boolean;
  readonly purchaseOrderLabel: string;
}

function PurchaseOrderRows({
  cost,
  hasNoPackageDeals,
  purchaseOrderLabel,
}: PurchaseOrderRowsProps): JSX.Element {
  const [t] = useTranslation('operators');
  return (
    <>
      <tr>
        <td colSpan={6} className="has-background-grey-lighter has-text-weight-bold">
          {purchaseOrderLabel}
        </td>
        {isTruthy(cost) && (
          <>
            <td className="has-background-grey-lighter has-text-weight-bold has-text-right">
              {cost.toFixed(2)}
            </td>
            <td className="has-background-grey-lighter has-text-weight-bold has-text-left">
              {t('expertiseView.packageDealTable.dutyFree')}
            </td>
          </>
        )}
        <td className="has-background-grey-lighter" colSpan={hasNoPackageDeals ? 3 : 1} />
      </tr>
      {hasNoPackageDeals && (
        <tr>
          <td colSpan={9} className="has-text-grey-light">
            {t('expertiseView.packageDealTable.noPackageDealsForPurchaseOrder')}
          </td>
        </tr>
      )}
    </>
  );
}
