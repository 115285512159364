import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDealVariableBaseType } from '@stimcar/libs-base';
import type { AnyStoreDef, NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import {
  isBooleanVariable,
  isNumericVariable,
  isTextualVariable,
  toUpperFirst,
} from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useRecordItemSelector, useSelectorWithChangeTrigger } from '@stimcar/libs-uikernel';
import type { HorizontalFormFieldProps } from './FormField.js';
import { SelectFormField } from './SelectFormField.js';

type VariableFormData = {
  readonly variableValues: Record<string, number | boolean | string>;
};

interface VariableSelectFormProps<
  T extends PackageDealVariableBaseType,
  SD extends AnyStoreDef,
  F extends VariableFormData,
> {
  readonly variableName: string;
  readonly variable: T;
  readonly $: StoreStateSelector<SD, F>;
  readonly onFormChange: NoArgActionCallback<SD>;
  readonly isReadonly?: boolean;
  readonly horizontal?: boolean | HorizontalFormFieldProps;
}

export function VariableSelectForm<
  T extends PackageDealVariableBaseType,
  SD extends AnyStoreDef,
  F extends VariableFormData,
>({
  variableName,
  variable,
  $,
  onFormChange,
  isReadonly = false,
  horizontal,
}: VariableSelectFormProps<T, SD, F>): JSX.Element {
  const [t] = useTranslation('bulma');

  const { $variableValues } = $;

  const $variableValue = useRecordItemSelector($variableValues, variableName);
  const $variableWithChangeTrigger = useSelectorWithChangeTrigger($variableValue, onFormChange);

  let formLabel = isTruthyAndNotEmpty(variable.label) ? variable.label : toUpperFirst(variableName);
  if (isNumericVariable(variable)) {
    formLabel += `${isTruthyAndNotEmpty(variable.unit) ? ` (${variable.unit})` : ''}:`;
  }

  const availableValues = useMemo(() => {
    if (isBooleanVariable(variable)) {
      return [
        {
          id: false,
          label: t('variableSelectForm.variablesValues.false'),
        },
        {
          id: true,
          label: t('variableSelectForm.variablesValues.true'),
        },
      ];
    }
    if (isNumericVariable(variable) || isTextualVariable(variable)) {
      return variable.availableValues;
    }
    throw Error(`Variable ${formLabel}, unknown type ${Reflect.get(variable, 'type')}`);
  }, [formLabel, t, variable]);

  return (
    <SelectFormField
      label={formLabel}
      disabled={isReadonly}
      horizontal={horizontal}
      $={$variableWithChangeTrigger}
      entries={availableValues}
    />
  );
}
