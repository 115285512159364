import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { CopyPackageDealDescResult } from '@stimcar/core-libs-common';
import type { SiteKey } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type {
  CheckFormConsistencyInput,
  FormFieldEntry,
  ReactSelectOption,
} from '@stimcar/libs-uitoolkit';
import { COPY_PACKAGE_DEAL_DESC_MODES, CoreBackendRoutes } from '@stimcar/libs-base';
import { keysOf, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  ModalCardDialog,
  RadioButtonsFormField,
  ReactSelectMultiFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type {
  CopyPackageDealDescFormData,
  CopyPackageDealDescModalState,
  CopyPackageDealDescReportItem,
} from '../typings/store.js';
import { EMPTY_COPY_PACKAGE_DEAL_DESC_MODEL_STATE } from '../typings/store.js';

export async function copyPackageDealDescAction(
  {
    actionDispatch,
    httpClient,
    getState,
    runWithProgressBar,
  }: ActionContext<Store, CopyPackageDealDescModalState>,
  dryRun: boolean
) {
  await runWithProgressBar(1, async (monitor) => {
    monitor.setIndeterminateProgress();
    const { formData, packageDealDesc } = getState();
    const { mode, selectedPackageDealDatabases, selectedSites } = formData;
    const { report } = await httpClient.httpPostAsJSON<object, CopyPackageDealDescResult>(
      CoreBackendRoutes.COPY_PACKAGE_DEAL_DESC(packageDealDesc.id, mode),
      { dryRun, selectedPackageDealDatabases, selectedSites }
    );
    actionDispatch.setProperty('report', report);
  });
}

export async function openCopyPackageDealDescModalAction(
  {
    httpClient,
    actionDispatch,
    getGlobalState,
    packageDealDescRepository,
  }: ActionContext<Store, CopyPackageDealDescModalState>,
  selectedPackageDealDescId: string
) {
  const { packageDealDatabases } = nonnull(getGlobalState().siteConfiguration);
  const packageDealDesc = await packageDealDescRepository.getEntity(selectedPackageDealDescId);
  const sites = await httpClient.httpGetAsJson<readonly SiteKey[]>(CoreBackendRoutes.GET_ALL_SITES);

  actionDispatch.setValue({
    ...EMPTY_COPY_PACKAGE_DEAL_DESC_MODEL_STATE,
    sites: sites.map(({ siteId }) => siteId),
    packageDealDesc,
    packageDealDatabases,
  });

  // Perform a dry run
  await actionDispatch.exec(copyPackageDealDescAction, true);
  // Open the dialog
  actionDispatch.setProperty('active', true);
}

interface ReportItemProps extends CopyPackageDealDescReportItem {
  readonly companyId: string;
  readonly siteId: string;
  readonly database: string;
}

const CHANGE_TYPE_TO_CLASSNAME = {
  updated: 'warning',
  added: 'danger',
  unchanged: 'success',
};

function ReportItem({
  companyId,
  siteId,
  database,
  entityId,
  type,
  payload,
}: ReportItemProps): JSX.Element {
  const [t] = useTranslation(['packageDealDescs', 'globals']);
  return (
    <>
      <nav className={`level has-background-${CHANGE_TYPE_TO_CLASSNAME[type]}-light p-sm`}>
        <div className="level-left">
          <div className="level-item">{`${companyId} / ${siteId} / ${database}`}</div>
          <div className="level-item">{`@${entityId}`}</div>
        </div>
        <div className="level-right">
          <span className={`tag is-${CHANGE_TYPE_TO_CLASSNAME[type]}`}>
            {t(`copyModal.changeType.${type}`)}
          </span>
        </div>
      </nav>
      {type === 'updated' && <pre>{JSON.stringify(payload, null, 2)}</pre>}
    </>
  );
}

function checkFormConsistencyAction({
  formState,
  t,
}: CheckFormConsistencyInput<Store, CopyPackageDealDescModalState>): string | undefined {
  const { report } = formState;
  const unchanged = keysOf(report).reduce((p, k) => p && report[k].type === 'unchanged', true);
  return unchanged ? t(`copyModal.form.warnings.noChange`) : undefined;
}

interface Props {
  readonly $: StoreStateSelector<Store, CopyPackageDealDescModalState>;
}

export function CopyPackageDealDescModal({ $ }: Props): JSX.Element {
  const [t] = useTranslation(['packageDealDescs', 'globals']);
  const submitValidDataActionCallback = useActionCallback(
    async function doCopyPackageDealDescAction({ actionDispatch, globalActionDispatch, getState }) {
      await actionDispatch.exec(copyPackageDealDescAction, false);
      actionDispatch.setProperty('active', false);
      const { report } = getState();
      globalActionDispatch.setProperty(
        'message',
        t('copyModal.copyPerformed', {
          count: keysOf(report).filter((k) => report[k].type !== 'unchanged').length,
        })
      );
    },
    [t],
    $
  );

  const selectedMode = useGetState($.$formData.$mode);
  const mandatoryFields = useMemo((): readonly (keyof CopyPackageDealDescFormData)[] => {
    const mandatoryFields: readonly (keyof CopyPackageDealDescFormData)[] = ['mode'];
    if (selectedMode === 'someDatabasesOnSomeSites') {
      return [...mandatoryFields, 'selectedPackageDealDatabases', 'selectedSites'];
    }
    return mandatoryFields;
  }, [selectedMode]);

  const [
    onFormSubmit,
    // we can't use the $formDataWithChangeTrigger selector as we want to update
    // the report first (through a HttpClient call)
    genericOnFormChange,
    // $formDataWithChangeTrigger
  ] = useFormWithValidation({
    $,
    mandatoryFields,
    checkFormConsistencyAction,
    submitValidDataAction: submitValidDataActionCallback,
    t,
  });

  const database = useGetState($.$packageDealDesc.$database);

  const modeEntries = useMemo((): readonly FormFieldEntry<string>[] => {
    return COPY_PACKAGE_DEAL_DESC_MODES.map((id) => ({
      id,
      label: t(`copyModal.form.modes.${id}`, { database }),
    }));
  }, [t, database]);

  const dryRunActionCallback = useActionCallback(
    async function dryRunAction({ actionDispatch }) {
      await actionDispatch.exec(copyPackageDealDescAction, true);
      // The form validation is performed after the report is loaded
      await actionDispatch.execCallback(genericOnFormChange);
    },
    [genericOnFormChange],
    $
  );

  const $modeWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$formData.$mode,
    dryRunActionCallback
  );

  const $selectedPackageDealDatabasesWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$formData.$selectedPackageDealDatabases,
    dryRunActionCallback
  );

  const $selectedSitesWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$formData.$selectedSites,
    dryRunActionCallback
  );

  const code = useGetState($.$packageDealDesc.$code);

  const dryRunReport = useGetState($.$report);

  const formWarning = useGetState($.$formWarning);

  const packageDealDatabases = useGetState($.$packageDealDatabases);

  const sites = useGetState($.$sites);

  const formatSiteOptionLabel = useCallback(({ value }: ReactSelectOption<string>): string => {
    const siteName = value.replace(/stherblain/, 'nantes');
    return siteName.charAt(0).toUpperCase() + siteName.slice(1);
  }, []);

  return (
    <ModalCardDialog
      $active={$.$active}
      title={t('copyModal.title', { database, code })}
      onOkClicked={onFormSubmit}
      size="max-desktop"
      warning={formWarning}
    >
      <RadioButtonsFormField
        $={$modeWithChangeTrigger}
        id="mode"
        radioGroupLayout="vertical"
        entries={modeEntries}
        label={t('copyModal.form.mode')}
      />
      {selectedMode === 'someDatabasesOnSomeSites' && (
        <>
          <ReactSelectMultiFormField
            isClearable
            suggestions={packageDealDatabases}
            label={t('copyModal.form.databases')}
            horizontal={{ bodyFlexGrow: 5, labelFlexGrow: 1 }}
            $={$selectedPackageDealDatabasesWithChangeTrigger}
          />
          <ReactSelectMultiFormField
            isClearable
            suggestions={sites}
            label={t('copyModal.form.sites')}
            formatOptionLabel={formatSiteOptionLabel}
            $={$selectedSitesWithChangeTrigger}
            horizontal={{ bodyFlexGrow: 5, labelFlexGrow: 1 }}
          />
        </>
      )}
      {keysOf(dryRunReport).map((k) => {
        const [companyId, siteId, database] = k.split('/');
        return (
          <ReportItem
            key={k}
            companyId={companyId}
            siteId={siteId}
            database={database}
            {...dryRunReport[k]}
          />
        );
      })}
    </ModalCardDialog>
  );
}
