import type { JSX } from 'react';
import React from 'react';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { useGetState } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { ShiftProgressDisplayState } from './typings/store.js';

type Props = {
  readonly $: StoreStateSelector<Store, ShiftProgressDisplayState>;
};

export function ProdAchievementsDisplayMode({ $ }: Props): JSX.Element {
  const size = useGetState($.$size);
  const dayKanbansDone = useGetState($.$dayKanbansDone);
  return (
    <>
      <div className="has-text-centered">
        <span
          className={dayKanbansDone > 0 ? 'has-text-success' : 'has-text-grey'}
          style={{ fontSize: `${240 * size}px`, lineHeight: '1em' }}
        >
          {dayKanbansDone}
        </span>
        <FaIcon
          id="arrow-circle-right"
          additionalClass={dayKanbansDone > 0 ? ' has-text-success' : ' has-text-grey'}
          size={(120 * size) / 2}
        />
      </div>
    </>
  );
}
