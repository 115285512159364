import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  FlatOperationItem,
  Kanban,
  PaymentOrder,
  PurchaseOrder,
  RepositoryEntityPayload,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import {
  INVOICE_ID_ATTRIBUTE,
  kanbanHelpers,
  longNumberDayMonthYearWithHourFormatOptions,
  nonDeleted,
  packageDealHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import {
  convertBusinessCustomerToFormData,
  EMPTY_EDIT_CUSTOMER_DIALOG_STATE,
  EMPTY_FORM_WITH_VALIDATION_STATE,
  FaIcon,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { ConvertUrlsToLinks } from '../../../lib/components/DisplayAttributesComponent.js';
import { PRICE_DECIMAL_LENGTH } from '../kanbanDetailsUtils.js';
import { openDeleteInvoiceInfosDeletionModalAction } from './DeleteInvoiceInfosModal.js';
import './InvoiceDetailItem.scss';
import { getExistingPurchaseOrder } from './invoicingUtils.js';
import { openPaymentOrderModalAction } from './PaymentOrderModal.js';
import { openPurchaseOrderEditModalAction } from './PurchaseOrderAddEditModal.js';
import { openPurchaseOrderDeletionModalAction } from './PurchaseOrderDeleteModal.js';
import { openRefundInvoiceModalAction } from './RefundInvoiceModal.js';
import { openSendInvoiceModalAction } from './SendInvoiceMailModal.js';
import { type InvoiceDetail, type KanbanInvoiceTabState } from './typings/store.js';

type InvoiceDetailItemProps = {
  readonly invoiceDetail: InvoiceDetail;
  readonly kanban: Kanban;
  readonly canModifyInvoicing: boolean;
  readonly canEditPurchaseOrders: boolean;
  readonly canEditPurchaseOrderCustomers: boolean;
  readonly hasSiblingsPurchaseOrders: boolean;
  readonly showTechnicalId: boolean;
  readonly isKanbanB2C: boolean;
  readonly $: StoreStateSelector<Store, KanbanInvoiceTabState>;
};

export function InvoiceDetailItem({
  invoiceDetail,
  kanban,
  canModifyInvoicing,
  canEditPurchaseOrders,
  canEditPurchaseOrderCustomers,
  hasSiblingsPurchaseOrders,
  showTechnicalId,
  isKanbanB2C,
  $,
}: InvoiceDetailItemProps): JSX.Element {
  const [t] = useTranslation('details');

  const { $invoiceRefundModalState, $invoiceInfoDeletionModalState } = $;

  const {
    invoiceDetailId,
    purchaseOrderId,
    purchaseOrderLabel,
    purchaseOrderCustomerLabel,
    invoiceInfoId,
    invoiceId,
    reference,
    amount,
    amountWithVAT,
    isRefund,
    refundedInvoiceReference,
    refundingInvoiceId,
    link,
  } = invoiceDetail;

  const hasAllocatedPackageDeals = useMemo(
    () =>
      kanbanHelpers.getPackageDealsAllocatedToPurchaseOrderId(kanban, invoiceDetail.purchaseOrderId)
        .length > 0,
    [kanban, invoiceDetail]
  );

  const openRefundInvoiceDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      if (isTruthy(invoiceId)) {
        await actionDispatch.exec(
          openRefundInvoiceModalAction,
          invoiceId,
          purchaseOrderId,
          reference ?? ''
        );
      }
    },
    [invoiceId, purchaseOrderId, reference],
    $invoiceRefundModalState
  );

  const openDeleteInvoiceInfosDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        openDeleteInvoiceInfosDeletionModalAction,
        purchaseOrderId,
        invoiceInfoId ?? ''
      );
    },
    [purchaseOrderId, invoiceInfoId],
    $invoiceInfoDeletionModalState
  );

  const openPurchaseOrderEditDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openPurchaseOrderEditModalAction, kanban.id, purchaseOrderId);
    },
    [kanban, purchaseOrderId],
    $.$purchaseOrderEditModalState
  );

  const openPurchaseOrderDeletionDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openPurchaseOrderDeletionModalAction, kanban.id, purchaseOrderId);
    },
    [kanban, purchaseOrderId],
    $.$purchaseOrderDeletionModalState
  );

  const currentPurchaseOrder = useMemo(
    (): PurchaseOrder | undefined =>
      kanban.purchaseOrders.filter(nonDeleted).find(({ id }) => id === purchaseOrderId),
    [kanban.purchaseOrders, purchaseOrderId]
  );

  const recipient = useMemo((): string => {
    return currentPurchaseOrder?.customer?.email ?? kanban.customer.email;
  }, [kanban.customer.email, currentPurchaseOrder]);

  const nonDeletedPaymentOrders = useMemo((): readonly PaymentOrder[] => {
    return currentPurchaseOrder?.paymentOrders?.filter(nonDeleted) ?? [];
  }, [currentPurchaseOrder]);

  const canBePaid = useMemo((): boolean => {
    const hasPaymentStatusPaid = nonDeletedPaymentOrders.some(
      ({ paymentOrderStatus }) => paymentOrderStatus === 'PAID'
    );

    const isValidPaymentOrder =
      [invoiceId, amount, amountWithVAT].every(isTruthy) &&
      !isTruthy(refundingInvoiceId) &&
      !isRefund;

    return isValidPaymentOrder && !hasPaymentStatusPaid;
  }, [amount, amountWithVAT, invoiceId, isRefund, nonDeletedPaymentOrders, refundingInvoiceId]);

  const openPaymentOrderDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        openPaymentOrderModalAction,
        purchaseOrderId,
        recipient,
        purchaseOrderLabel,
        nonnull(amount),
        nonnull(amountWithVAT),
        invoiceInfoId ?? ''
      );
    },
    [amount, amountWithVAT, purchaseOrderLabel, purchaseOrderId, recipient, invoiceInfoId],
    $.$paymentOrderModalState
  );

  const nonDeletedOperations = useMemo(
    (): FlatOperationItem[] =>
      packageDealHelpers.getFlatOperationList(kanban.packageDeals, 'achievable').filter(nonDeleted),
    [kanban.packageDeals]
  );
  const canSendInvoiceEmail = useMemo((): boolean => {
    return isTruthy(recipient) && isTruthy(invoiceId);
  }, [invoiceId, recipient]);

  const { hasCompletedSendInvoiceEmailOperations, hasUnCompletedSendInvoiceEmailOperations } =
    useMemo(() => {
      const sendInvoiceOperations = nonDeletedOperations.filter(
        ({ attributes, type }) =>
          attributes[INVOICE_ID_ATTRIBUTE] === invoiceId && type === 'SendInvoiceMail'
      );

      return {
        hasUnCompletedSendInvoiceEmailOperations: sendInvoiceOperations.some(
          ({ completionDate }) => !isTruthy(completionDate)
        ),
        hasCompletedSendInvoiceEmailOperations: sendInvoiceOperations.some(({ completionDate }) =>
          isTruthy(completionDate)
        ),
      };
    }, [invoiceId, nonDeletedOperations]);

  const openSendInvoiceDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      if (isTruthy(invoiceId)) {
        await actionDispatch.exec(
          openSendInvoiceModalAction,
          recipient,
          purchaseOrderId,
          reference ?? '',
          invoiceInfoId!
        );
      }
    },
    [invoiceId, purchaseOrderId, reference, recipient, invoiceInfoId],
    $.$sendInvoiceModalState
  );

  const openAddCustomerDialogCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.setValue({
        purchaseOrderId,
        editCustomerDialogState: {
          ...EMPTY_FORM_WITH_VALIDATION_STATE,
          active: true,
          initialCustomer: undefined,
          mode: 'create',
          formData: EMPTY_EDIT_CUSTOMER_DIALOG_STATE.formData,
        },
      });
    },
    [purchaseOrderId],
    $.$purchaseOrderCustomerAddModalState
  );

  const openEditCustomerDialogCallback = useActionCallback(
    async ({ actionDispatch, kanbanRepository }): Promise<void> => {
      const currentPurchaseOrder = await getExistingPurchaseOrder(
        kanbanRepository,
        kanban.id,
        purchaseOrderId
      );

      actionDispatch.setValue({
        purchaseOrderId,
        editCustomerDialogState: {
          ...EMPTY_FORM_WITH_VALIDATION_STATE,
          active: true,
          initialCustomer: currentPurchaseOrder.customer ?? undefined,
          mode: 'update',
          formData: currentPurchaseOrder.customer
            ? convertBusinessCustomerToFormData(currentPurchaseOrder.customer)
            : EMPTY_EDIT_CUSTOMER_DIALOG_STATE.formData,
        },
      });
    },
    [kanban, purchaseOrderId],
    $.$purchaseOrderCustomerEditModalState
  );

  const deleteCustomerActionCallback = useActionCallback(
    async ({ kanbanRepository }: ActionContext<Store, KanbanInvoiceTabState>): Promise<void> => {
      const currentPurchaseOrder = await getExistingPurchaseOrder(
        kanbanRepository,
        kanban.id,
        purchaseOrderId
      );

      const payload: RepositoryEntityPayload<Kanban> = {
        entityId: kanban.id,
        payload: {
          purchaseOrders: [
            {
              id: purchaseOrderId,
              customer: currentPurchaseOrder.customer ? null : undefined,
            },
          ],
        },
      };
      await kanbanRepository.updateEntityFromPayload(payload);
    },
    [kanban, purchaseOrderId],
    $
  );

  const typeLabel = isRefund ? t('tabs.invoice.refund') : t('tabs.invoice.invoice');

  // A refund is possible on InvoiceInfos instances :
  // - which do not correspond to a refund
  // - which correspond to an invoice which has not yet been refunded
  const hasNoInvoiceInfos = !isTruthy(invoiceId);
  const isARefundedInvoice = isTruthy(refundingInvoiceId);
  const isRefundPossible = !hasNoInvoiceInfos && !isRefund && !isARefundedInvoice;

  let refundTooltip = t('tabs.invoice.actions.refund');
  if (hasNoInvoiceInfos) {
    refundTooltip = t('tabs.invoice.actions.noRefundWhenNoInvoiceInfos');
  } else if (isRefund) {
    refundTooltip = t('tabs.invoice.actions.noRefundOnRefund');
  } else if (isARefundedInvoice) {
    refundTooltip = t('tabs.invoice.actions.noRefundOnRefundedInvoice');
  }

  const hasCustomer = isTruthyAndNotEmpty(purchaseOrderCustomerLabel);
  const isDeletePossible = isTruthy(invoiceInfoId);

  const paymentOrdersTooltip = useMemo((): string => {
    if (!isTruthy(nonDeletedPaymentOrders)) {
      return '';
    }

    const formatDate = (date: number | null) =>
      isTruthy(date) && nonnull(date)
        ? new Date(date).toLocaleDateString('fr-FR', longNumberDayMonthYearWithHourFormatOptions)
        : 'N/A';

    return nonDeletedPaymentOrders
      .map(({ paymentOrderStatus, createdDate, updatedStatusDate }) =>
        t('tabs.invoice.paymentOrder.paymentOrderTooltip', {
          createdDate: formatDate(createdDate),
          updatedDate: formatDate(updatedStatusDate),
          status: t(`tabs.invoice.paymentOrder.status.${paymentOrderStatus ?? 'TOBEPAID'}`),
        })
      )
      .join('\n');
  }, [t, nonDeletedPaymentOrders]);

  /**
   * Determines the consolidated payment status from a list of `PaymentOrder`.
   * - If there are no valid payment orders and it's not a refund, the status is `TOBEPAID`.
   * - If at least one `PaymentOrder` has the status `PAID`, the status is `PAID`.
   * - If at least one `PaymentOrder` has the status `RUNNING`, the status is `RUNNING`.
   * - If all `PaymentOrder`s have the status `CANCELED`, `REFUSED`, or `EXPIRED`, the status is `FAILURE`.
   * - Otherwise, defaults to `TOBEPAID`.
   */
  const computedPaymentOrderStatus = useMemo((): DisplayedPaymentOrderStatus | null => {
    if (!isTruthy(nonDeletedPaymentOrders) || (nonDeletedPaymentOrders.length === 0 && !isRefund)) {
      return 'TOBEPAID';
    }

    if (nonDeletedPaymentOrders.some((order) => order.paymentOrderStatus === 'PAID')) {
      return 'PAID';
    }

    if (nonDeletedPaymentOrders.some((order) => order.paymentOrderStatus === 'RUNNING')) {
      return 'RUNNING';
    }

    if (
      nonDeletedPaymentOrders.every(
        (order) =>
          order.paymentOrderStatus === 'CANCELED' ||
          order.paymentOrderStatus === 'REFUSED' ||
          order.paymentOrderStatus === 'EXPIRED'
      )
    ) {
      return 'FAILURE';
    }
    return 'TOBEPAID';
  }, [nonDeletedPaymentOrders, isRefund]);

  return (
    <tr key={invoiceDetailId}>
      {showTechnicalId && <td>{invoiceDetailId}</td>}
      <td>
        <div className="is-flex is-justify-content-space-between">
          {purchaseOrderLabel}
          <div>
            <button
              key="edit"
              aria-label="edit"
              type="button"
              className="button is-small is-transparent"
              title={t('tabs.invoice.actions.edit')}
              onClick={openPurchaseOrderEditDialogCallback}
              disabled={!canEditPurchaseOrders}
            >
              <FaIcon id="edit" />
            </button>
            <button
              key="delete"
              aria-label="delete"
              type="button"
              className="button is-small is-transparent"
              title={t('tabs.invoice.actions.delete')}
              onClick={openPurchaseOrderDeletionDialogCallback}
              disabled={
                hasAllocatedPackageDeals || !canEditPurchaseOrders || !hasSiblingsPurchaseOrders
              }
            >
              <FaIcon id="trash" />
            </button>
          </div>
        </div>
      </td>
      <td>
        {hasCustomer ? (
          <div className="is-flex is-justify-content-space-between">
            {purchaseOrderCustomerLabel}
            <div>
              <button
                key="edit"
                aria-label="edit"
                type="button"
                className="button is-small is-transparent"
                title={t('tabs.invoice.actions.edit')}
                onClick={openEditCustomerDialogCallback}
                disabled={!canEditPurchaseOrderCustomers}
              >
                <FaIcon id="edit" />
              </button>
              <button
                key="delete"
                aria-label="delete"
                type="button"
                className="button is-small is-transparent"
                title={t('tabs.invoice.actions.delete')}
                onClick={deleteCustomerActionCallback}
                disabled={!canEditPurchaseOrderCustomers}
              >
                <FaIcon id="trash" />
              </button>
            </div>
          </div>
        ) : (
          <div className="is-flex is-justify-content-space-between">
            <div />
            <button
              key="add"
              aria-label="add"
              type="button"
              className="button has-text-right is-small is-transparent"
              title={t('tabs.invoice.actions.delete')}
              onClick={openAddCustomerDialogCallback}
              disabled={!canEditPurchaseOrderCustomers}
            >
              <FaIcon id="plus" />
            </button>
          </div>
        )}
      </td>
      <td>{isTruthyAndNotEmpty(reference) ? typeLabel : ''}</td>
      <td>
        <div className="is-flex is-justify-content-space-between">
          <div>
            <span className={isTruthy(refundingInvoiceId) ? 'strike-through' : ''}>
              {reference}
            </span>
            {isTruthy(refundedInvoiceReference) && (
              <span
                className={`is-size-7 p-l-sm${isTruthy(refundedInvoiceReference) ? ' strike-through' : ''}`}
              >
                {`(${refundedInvoiceReference})`}
              </span>
            )}
          </div>
          {canModifyInvoicing && (
            <div>
              <button
                key="refund"
                aria-label="refund"
                type="button"
                className="button is-small is-transparent"
                title={t('tabs.invoice.actions.refund')}
                onClick={openRefundInvoiceDialogCallback}
                disabled={!isRefundPossible}
              >
                <FaIcon id="rotate-left" tooltip={refundTooltip} />
              </button>
              <button
                key="delete"
                aria-label="delete"
                type="button"
                className="button is-small is-transparent"
                title={t('tabs.invoice.actions.delete')}
                onClick={openDeleteInvoiceInfosDialogCallback}
                disabled={!isDeletePossible}
              >
                <FaIcon
                  id="trash"
                  tooltip={
                    isDeletePossible
                      ? t('tabs.invoice.actions.delete')
                      : t('tabs.invoice.actions.noActionWhenNoInvoiceInfos')
                  }
                />
              </button>
            </div>
          )}
        </div>
      </td>
      <td className="has-text-right">
        {isTruthy(amount)
          ? t('tabs.price', {
              price: amount.toFixed(PRICE_DECIMAL_LENGTH),
            })
          : ''}
      </td>
      {isKanbanB2C && (
        <>
          <td className="has-text-right">
            {isTruthy(amountWithVAT)
              ? t('tabs.price', {
                  price: amountWithVAT.toFixed(PRICE_DECIMAL_LENGTH),
                })
              : ''}
          </td>
          <td className="has-text-right">
            <div className="is-flex is-justify-content-space-between">
              <div>
                {isTruthy(computedPaymentOrderStatus) && !isRefund && (
                  <TagStatusComponent
                    label={t(`tabs.invoice.paymentOrder.status.${computedPaymentOrderStatus}`)}
                    status={computedPaymentOrderStatus}
                    tooltip={paymentOrdersTooltip}
                  />
                )}
                {canBePaid && !isTruthy(computedPaymentOrderStatus) && (
                  <TagStatusComponent
                    label={t('tabs.invoice.paymentOrder.status.TOBEPAID')}
                    status="TOBEPAID"
                  />
                )}
                {canBePaid && (
                  <>
                    <button
                      type="button"
                      className="button has-text-right is-small is-transparent"
                      title={t('tabs.invoice.actions.paymentOrder')}
                      onClick={openPaymentOrderDialogCallback}
                      disabled={!canBePaid}
                    >
                      <FaIcon
                        id="fa-solid fa-credit-card"
                        tooltip={t('tabs.invoice.actions.paymentOrder')}
                      />
                    </button>
                  </>
                )}
              </div>
            </div>
          </td>
          <td className="has-text-right">
            <div className="is-flex is-justify-content-space-between">
              <div>
                {hasUnCompletedSendInvoiceEmailOperations && (
                  <TagStatusComponent
                    label={t('tabs.invoice.sendInvoice.status.TOSEND')}
                    status="TOSEND"
                    tooltip={t('tabs.invoice.sendInvoice.status.TOSEND')}
                  />
                )}
                {hasCompletedSendInvoiceEmailOperations &&
                  !hasUnCompletedSendInvoiceEmailOperations && (
                    <TagStatusComponent
                      label={t('tabs.invoice.sendInvoice.status.SENT')}
                      status="SENT"
                    />
                  )}
                {canSendInvoiceEmail && (
                  <button
                    type="button"
                    className="button has-text-right is-small is-transparent"
                    title={t('tabs.invoice.actions.sendInvoice')}
                    onClick={openSendInvoiceDialogCallback}
                  >
                    <FaIcon
                      id="fa-solid fa-envelope"
                      tooltip={t('tabs.invoice.actions.sendInvoice')}
                    />
                  </button>
                )}
              </div>
            </div>
          </td>
        </>
      )}
      <td aria-label="link" className="has-text-ellipsis">
        <ConvertUrlsToLinks jsxElementKeyPrefix="link" text={link ?? ''} />
      </td>
    </tr>
  );
}

type DisplayedInvoiceEmailStatus = 'TOSEND' | 'SENT';
type DisplayedPaymentOrderStatus = 'TOBEPAID' | 'PAID' | 'RUNNING' | 'FAILURE';
type PaymentOrInvoiceStatus = DisplayedPaymentOrderStatus | DisplayedInvoiceEmailStatus;

const paymentOrderStatusColors: Record<DisplayedPaymentOrderStatus, string> = {
  PAID: 'success',
  RUNNING: 'warning',
  FAILURE: 'danger',
  TOBEPAID: 'light',
};
const invoiceEmailStatusColors: Record<DisplayedInvoiceEmailStatus, string> = {
  SENT: 'success',
  TOSEND: 'light',
};

const statusColors: Record<PaymentOrInvoiceStatus, string> = {
  ...paymentOrderStatusColors,
  ...invoiceEmailStatusColors,
};

type TagStatusComponentProps = {
  readonly label: string;
  readonly status: PaymentOrInvoiceStatus;
  readonly tooltip?: string;
};

function TagStatusComponent({ label, status, tooltip }: TagStatusComponentProps): JSX.Element {
  const statusColor = useMemo((): string => {
    return statusColors[status] ?? 'light';
  }, [status]);

  return (
    <span className={`ml-3 tag is-${statusColor} are-medium`} title={tooltip}>
      {label}
    </span>
  );
}
