import type { PackageDeal, SparePart } from '@stimcar/libs-base';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';

export type CommentsFormData = {
  readonly commentForStore?: string;
  readonly commentForWorkshop?: string;
  readonly commentForCustomer?: string;
};

export const EMPTY_COMMENTS_FORM_DATA: CommentsFormData = {
  commentForStore: '',
  commentForWorkshop: '',
  commentForCustomer: '',
};

export type PackageDealComments = Pick<
  PackageDeal,
  'id' | 'comment' | 'commentForStore' | 'estimateComment'
>;

export type SparePartComments = Pick<
  SparePart,
  'id' | 'comment' | 'commentForWorkshop' | 'commentForCustomer'
>;

export type CommentsElementHierarchy = {
  readonly kanbanId: string;
  /**
   * If editing a PackageDeal.
   */
  readonly packageDeal?: PackageDealComments;
  /**
   * If editing a SparePart.
   */
  readonly packageDealId?: string;
  /**
   * If editing a SparePart.
   */
  readonly sparePart?: SparePartComments;
};

export type EditCommentsModalDialogState = FormWithValidationState<CommentsFormData> &
  CommentsElementHierarchy & {
    readonly active: boolean;
  };

export const EMPTY_EDIT_COMMENTS_MODAL_DIALOG_STATE: EditCommentsModalDialogState = {
  active: false,
  kanbanId: '',
  formData: {
    ...EMPTY_COMMENTS_FORM_DATA,
    warnings: {},
  },
  formSubmitClickedOnce: false,
  formSubmitted: false,
};
