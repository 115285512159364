import type { ActionContext } from '@stimcar/libs-uikernel';
import type { BaseStoreDef } from '@stimcar/libs-uitoolkit';
import { computeArchiveDetailsPath } from '../../coreConstants.js';

export function selectKanbanInArchiveViewAction<SD extends BaseStoreDef>(
  { navigate }: ActionContext<SD, SD['globalState']>,
  id: string
) {
  navigate(computeArchiveDetailsPath(id));
}
