import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, PackageDeal, SparePart, Workflow } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, RawActionButtonDesc } from '@stimcar/libs-uitoolkit';
import {
  enumerate,
  globalHelpers,
  i18nHelpers,
  kanbanHelpers,
  packageDealHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import { useArrayItemSelector, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import {
  ActionButtonComponent,
  KanbanIdentityPictureComponent,
  ScrollableContainerWithHeaderAndFooter,
  useActionButtonDescs,
} from '@stimcar/libs-uitoolkit';
import type { Store, StoreState } from '../../state/typings/store.js';
import type { SelectKanbanState } from '../typings/store.js';
import { GraphicWorkflowWithProgress } from '../../../lib/components/GraphicWorkflowWithProgress.js';
import { SparePartEstimatedDateOfReceptionText } from '../../operators/components/spareparts/common/SparePartEstimatedDateOfReceptionText.js';
import { useSparePartEstimatedDateOfReceptionColorClass } from '../../operators/components/spareparts/common/useSparePartEstimatedDateOfReceptionColorClass.js';
import { HandleKanbanSplitButtonDropdown } from '../../utils/HandleKanbanSplitButtonDropdown.js';
import { MarketplaceIcon } from '../../utils/MarketplaceIcon.js';
import {
  computeKanbanHandlingStatus,
  isKanbanNormallyAvailableForPosts,
} from '../../utils/operatorUtils.js';
import { PackageDealDecorators } from '../../utils/PackageDealDecorators.js';
import { useComputeAttachmentUrl } from '../../utils/useComputeAttachmentUrl.js';
import { useGetContractByCode } from '../../utils/useGetContract.js';
import { KanbanCurrentLocationStatusIconComponent } from './KanbanPositionStatusIconComponent.js';

interface Props extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, SelectKanbanState>;
  readonly actionDescsProvider?: (
    kanban: Kanban
  ) => readonly RawActionButtonDesc<Store, StoreState>[];
}

export function SelectionKanbanDetails({ actionDescsProvider, $, $gs }: Props): JSX.Element {
  const [t] = useTranslation('selectKanban');

  const isOnline = useGetState($gs.$session.$isOnline);

  const selectedKanbanId = useGetState($.$selectedItemId);
  const $selectedKanban = useArrayItemSelector($.$items, selectedKanbanId ?? '');
  const selectedKanban = useGetState($selectedKanban);
  const selectedStands = useGetState($.$selectedStands);

  const actionDescs = useActionButtonDescs(
    () => {
      if (isTruthy(actionDescsProvider) && isTruthy(selectedKanban)) {
        return actionDescsProvider(nonnull(selectedKanban));
      }
      return [];
    },
    [actionDescsProvider, selectedKanban],
    $gs
  );

  const configuration = useGetState($gs.$siteConfiguration);
  const browserInfos = useGetState($gs.$session.$infos);

  const selectionStatus = useMemo(() => {
    const handlingStatus = computeKanbanHandlingStatus(selectedKanban, browserInfos?.id ?? '');
    const normallyEligible = isKanbanNormallyAvailableForPosts(
      selectedKanban,
      configuration,
      nonnull(browserInfos),
      selectedStands
    );
    return {
      ...handlingStatus,
      notNormallyEligible: !normallyEligible,
    };
  }, [selectedKanban, browserInfos, configuration, selectedStands]);

  const computeDetailsTitle = (): string => {
    const publicPrice = selectedKanban?.marketplaceInfos?.inputPrice;
    return `${
      selectionStatus.openHandlingOnPost ? `(${t('details.general.handledOnCurrentPost')}) ` : ''
    }${i18nHelpers.displayStringOrPlaceholder(t, selectedKanban?.infos.brand)} - ${
      selectedKanban?.infos.model ?? ''
    }${publicPrice ? ` - ${publicPrice} €` : ''}`;
  };

  const clearSelectionHandler = useSetCallback($.$selectedItemId, '');

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const computeHeader = (): JSX.Element => {
    return (
      <div className="columns is-vcentered">
        <div className="column is-narrow">
          <KanbanIdentityPictureComponent
            kanbanId={selectedKanbanId}
            computeAttachmentUrl={computeAttachmentUrl}
            $imageModal={$gs.$imageModal}
            isOnline={isOnline}
          />
        </div>
        <div className="column">
          <div className="level">
            <div className="level-left">
              <div>
                <p className="title is-4">
                  <MarketplaceIcon kanban={nonnull(selectedKanban)} />
                  {` ${computeDetailsTitle()}`}
                </p>
                <p className="subtitle is-5">
                  {nonnull(selectedKanban).infos.license}
                  <PackageDealDecorators kanban={nonnull(selectedKanban)} $gs={$gs} />
                </p>
              </div>
            </div>
            <div style={{ float: 'right' }}>
              <button
                className="delete m-b-xl"
                type="button"
                aria-label="close"
                onClick={clearSelectionHandler}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const computeFooter = (): JSX.Element => {
    return (
      <div className="columns p-t-md">
        <>
          <div className="column">
            <HandleKanbanSplitButtonDropdown
              isUpDropdownMenu
              isFullwidth
              isDisabled={false}
              kanban={nonnull(selectedKanban)}
              $gs={$gs}
              $active={$.$handleKanbanMenuActive}
            />
          </div>
          {actionDescs.map((ad): JSX.Element => {
            return (
              <div className="column" key={`${selectedKanbanId}_${ad.id}`}>
                <ActionButtonComponent actionDesc={ad} isFullwidth />
              </div>
            );
          })}
        </>
      </div>
    );
  };

  const keyNumber = selectedKanban ? selectedKanban.logisticInfos.keyNumber : null;

  const contract = useGetContractByCode($gs, selectedKanban?.contract.code);

  const attributesToDisplay = useMemo((): Record<string, string> => {
    let toDisplay: Record<string, string> = {};
    if (isTruthy(contract)) {
      contract.attributeDescs.forEach((ad) => {
        const attributeValue = selectedKanban?.attributes[ad.id];
        if (
          attributeValue !== undefined &&
          (typeof attributeValue !== 'string' || isTruthyAndNotEmpty(attributeValue))
        ) {
          toDisplay = {
            ...toDisplay,
            [ad.id]: String(attributeValue ?? ''),
          };
        } else {
          toDisplay = {
            ...toDisplay,
            [ad.id]: t('details.notCommunicated'),
          };
        }
      });
    }
    return toDisplay;
  }, [selectedKanban, t, contract]);

  const workflow = useMemo((): Workflow | undefined => {
    return configuration.workflows.find((w) => w.id === selectedKanban?.workflowId);
  }, [configuration.workflows, selectedKanban]);

  return (
    <>
      {selectedKanban ? (
        <div className="box">
          <ScrollableContainerWithHeaderAndFooter
            headerComponent={computeHeader()}
            footerComponent={computeFooter()}
          >
            <div className="content">
              {selectionStatus.notNormallyEligible && (
                <p>
                  <span className="has-text-weight-bold m-r-md">
                    {t('details.general.notNormallyAvailableForStand')}
                  </span>
                </p>
              )}
              <div className="columns m-l-xxs m-r-xxs">
                <div className="column">
                  <div className="content">
                    <>
                      <b>{`${t('details.general.actualPosition')}: `}</b>
                      <KanbanCurrentLocationStatusIconComponent kanban={selectedKanban} />
                    </>
                    {keyNumber && (
                      <>
                        <br />
                        <b>{`${t('details.general.key')}: `}</b>
                        {keyNumber}
                      </>
                    )}
                    {selectionStatus.openHandlingsOnOtherPosts.length > 0 && (
                      <>
                        <br />
                        <b>{`${t('details.general.handledElsewhere')}: `}</b>
                        {enumerate(
                          selectionStatus.openHandlingsOnOtherPosts.map((h) =>
                            kanbanHelpers.getPostLabelForHandling(h, true)
                          )
                        )}
                      </>
                    )}
                    {Object.keys(attributesToDisplay).map((key): JSX.Element => {
                      return (
                        <React.Fragment key={key}>
                          <br />
                          <b>{`${key}: `}</b>
                          {attributesToDisplay[key]}
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <RemainingOperationsAndSparePartsDetails
                    selectedKanban={selectedKanban}
                    selectedStands={selectedStands}
                  />
                </div>
                <div className="column is-narrow">
                  <GraphicWorkflowWithProgress
                    kanban={selectedKanban}
                    workflow={nonnull(workflow)}
                  />
                </div>
              </div>
            </div>
          </ScrollableContainerWithHeaderAndFooter>
        </div>
      ) : (
        <div>
          <h2 className="has-text-centered">{t('details.noKanbanSelected')}</h2>
        </div>
      )}
    </>
  );
}

interface RemainingOperationsAndSparePartsDetailsProps {
  readonly selectedStands: readonly string[];
  readonly selectedKanban: Kanban | undefined;
}

function RemainingOperationsAndSparePartsDetails({
  selectedStands,
  selectedKanban,
}: RemainingOperationsAndSparePartsDetailsProps): JSX.Element {
  const [t] = useTranslation('selectKanban');

  const packageDealsWithOnlyAvailableOperationsAndSparePartsPerStand = useMemo(() => {
    const map: Record<
      string,
      {
        packageDeals: readonly PackageDeal[];
        duration: number;
        operationCount: number;
        sparePartsCount: number;
      }
    > = {};
    if (isTruthy(selectedKanban)) {
      selectedStands.forEach((s) => {
        let result = 0;
        let operationCount = 0;
        let sparePartsCount = 0;
        let hasAtLeastOneOperationOrSparePartOnStand = false;
        const filteredPckDeals = packageDealHelpers
          .getAvailablePackageDeals(nonnull(selectedKanban).packageDeals)
          .map((pd): PackageDeal => {
            return {
              ...pd,
              spareParts: pd.spareParts.filter((sp) => {
                if (!sp.deleted && sp.standId === s && !sp.dateOfReception) {
                  sparePartsCount += 1;
                  hasAtLeastOneOperationOrSparePartOnStand = true;
                  return true;
                }
                return false;
              }),
              operations: pd.operations.filter((o) => {
                if (!o.deleted && o.standId === s && !o.completionDate) {
                  result += o.workload;
                  operationCount += 1;
                  hasAtLeastOneOperationOrSparePartOnStand = true;
                  return true;
                }
                return false;
              }),
            };
          })
          .filter((pd) => pd.spareParts.length > 0 || pd.operations.length > 0);

        if (hasAtLeastOneOperationOrSparePartOnStand) {
          map[s] = {
            packageDeals: filteredPckDeals,
            operationCount,
            sparePartsCount,
            duration: globalHelpers.roundTo(result),
          };
        }
      });
    }
    return map;
  }, [selectedKanban, selectedStands]);

  if (keysOf(packageDealsWithOnlyAvailableOperationsAndSparePartsPerStand).length === 0) {
    return <></>;
  }

  return (
    <div className="content">
      {keysOf(packageDealsWithOnlyAvailableOperationsAndSparePartsPerStand).map(
        (s): JSX.Element => {
          const { duration, operationCount, sparePartsCount, packageDeals } =
            packageDealsWithOnlyAvailableOperationsAndSparePartsPerStand[s];
          return (
            <React.Fragment key={s}>
              <div className="has-text-weight-bold has-text-black is-size-5 m-b-xs">
                {t('details.general.standWorkTitle', { stand: s })}
              </div>
              {sparePartsCount > 0 && (
                <>
                  <div className="has-text-weight-bold m-b-xs">
                    {t('details.general.sparePartsTitle', { sparePartsCount })}
                  </div>
                  <table className="table is-narrow is-hoverable is-fullwidth">
                    <thead>
                      <tr>
                        <th>{t('details.general.table.packageDeals')}</th>
                        <th>{t('details.general.table.spareParts')}</th>
                        <th>{t('details.general.table.provider')}</th>
                        <th>{t('details.general.table.sparePartReceptionDelay')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {packageDeals.map((pck): JSX.Element[] =>
                        pck.spareParts.map(
                          (sp): JSX.Element => (
                            <MissingSparePartRow
                              key={sp.id}
                              packageDealLabel={packageDealHelpers.getPackageDealDisplayedLabel(
                                pck
                              )}
                              sparePart={sp}
                            />
                          )
                        )
                      )}
                    </tbody>
                  </table>
                </>
              )}
              {operationCount > 0 && (
                <>
                  <div className="has-text-weight-bold m-b-xs">
                    {t('details.general.operationsTitle', { duration })}
                  </div>
                  <table className="table is-narrow is-hoverable is-fullwidth">
                    <thead>
                      <tr>
                        <th>{t('details.general.table.packageDeals')}</th>
                        <th>{t('details.general.table.operations')}</th>
                        <th>{t('details.general.table.carElements')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {packageDeals.map((pd): JSX.Element[] =>
                        pd.operations.map(
                          (o): JSX.Element => (
                            <tr key={o.id}>
                              <td>{packageDealHelpers.getPackageDealDisplayedLabel(pd)}</td>
                              <td>
                                {packageDealHelpers.getOperationDisplayedLabel(o, pd.variables)}
                              </td>
                              <td>
                                {pd.carElement
                                  ? pd.carElement.label
                                  : t('details.general.table.noCarElement')}
                              </td>
                            </tr>
                          )
                        )
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </React.Fragment>
          );
        }
      )}
    </div>
  );
}

interface MissingSparePartRowProps {
  readonly packageDealLabel: string;
  readonly sparePart: SparePart;
}

function MissingSparePartRow({
  sparePart,
  packageDealLabel,
}: MissingSparePartRowProps): JSX.Element {
  const estimatedDateOfReceptionColorClass = useSparePartEstimatedDateOfReceptionColorClass(
    sparePart.estimatedDateOfReception
  );
  const toBeOrdered =
    (sparePart.managementType === 'fullyManagedByStimcar' ||
      sparePart.managementType === 'orderedByStimcarFromCustomersCatalog') &&
    !isTruthy(sparePart.dateOfOrder);
  return (
    <tr>
      <td>{packageDealLabel}</td>
      <td>{sparePart.label}</td>
      <td>{sparePart.provider}</td>
      <td className={estimatedDateOfReceptionColorClass}>
        {toBeOrdered ? (
          <b>Non commandé</b>
        ) : (
          <SparePartEstimatedDateOfReceptionText
            estimatedDateOfReception={sparePart.estimatedDateOfReception}
          />
        )}
      </td>
    </tr>
  );
}
