import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Entity, SiteKey } from '@stimcar/libs-kernel';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
  HorizontalFormFieldProps,
} from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, shortDayMonthFullYearDateFormatOptions } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  CalendarFormField,
  downloadAndSaveBlob,
  ModalCardDialog,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type {
  AdminExportMarketplaceInvoicingDialogState,
  AdminExportMarketplaceInvoicingFormState,
} from './typings/store.js';
import { EMPTY_ADMIN_EXPORT_MARKETPLACE_INVOICING_DIALOG_STATE } from './typings/store.js';

type MarketplaceInvoicingRow = SiteKey &
  Entity & {
    readonly license: string;
    readonly purchaseNumber: string;
    readonly totalInvoicedRevenue: number;
    readonly invoiceRefs: readonly string[];
    readonly totalRefurbishingRevenue: number;
    readonly totalSupportRevenue: number;
  };

export function openExportMarketplaceInvoicingDialogAction({
  actionDispatch,
}: ActionContext<Store, AdminExportMarketplaceInvoicingDialogState>) {
  actionDispatch.setValue(EMPTY_ADMIN_EXPORT_MARKETPLACE_INVOICING_DIALOG_STATE);
  actionDispatch.setProperty('active', true);
  actionDispatch.applyPayload({
    formData: {
      endDate: Date.now(),
    },
  });
  actionDispatch.setProperty('active', true);
}

function getExportFileName(startDate: number, endDate: number): string {
  const startYYYYMMDD = new Date(startDate).toLocaleDateString(
    'fr-FR',
    shortDayMonthFullYearDateFormatOptions
  );
  const endYYYYMMDD = new Date(endDate).toLocaleDateString(
    'fr-FR',
    shortDayMonthFullYearDateFormatOptions
  );
  return `export-marketplace-${startYYYYMMDD}-${endYYYYMMDD} .csv`;
}

function getExportContent(reportRows: readonly MarketplaceInvoicingRow[]): Blob {
  const header = [
    'Centre',
    'Site',
    'Id',
    'Immat',
    'BDC',
    'Ref facture',
    'Total facturé',
    'Total reconditionnement',
    'Total accompagnement',
    'Ecart',
  ];

  const rows = reportRows.map(
    ({
      companyId,
      siteId,
      id,
      license,
      purchaseNumber,
      totalInvoicedRevenue,
      invoiceRefs,
      totalRefurbishingRevenue,
      totalSupportRevenue,
    }) => [
      companyId,
      siteId,
      id,
      license,
      purchaseNumber,
      invoiceRefs.join(' '),
      String(totalInvoicedRevenue),
      String(totalRefurbishingRevenue),
      String(totalSupportRevenue),
      String(totalInvoicedRevenue - (totalRefurbishingRevenue + totalSupportRevenue)),
    ]
  );

  const toCsvValue = (value: string): string => `"${value.replace(/"/g, '""')}"`;
  const toCsvLine = (values: string[]): string => values.map(toCsvValue).join(',');

  const csvLines = [header, ...rows].map(toCsvLine).join('\n');
  return new Blob([csvLines], {
    type: 'text/csv',
  });
}

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  labelFlexGrow: 2,
  bodyFlexGrow: 3,
};

const mandatoryFields: (keyof AdminExportMarketplaceInvoicingFormState)[] = [
  'startDate',
  'endDate',
];

const checkFieldContentActions: CheckFormFieldContentActions<
  Store,
  AdminExportMarketplaceInvoicingDialogState
> = {
  startDate: ({ t, formState }): string | undefined => {
    const { startDate } = formState.formData;
    if (Number.isNaN(startDate)) {
      return t('form.requiredDate');
    }
    return undefined;
  },
  endDate: ({ t, formState }): string | undefined => {
    const { endDate } = formState.formData;
    if (Number.isNaN(endDate)) {
      return t('form.requiredDate');
    }
    return undefined;
  },
};

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  AdminExportMarketplaceInvoicingDialogState,
  []
> = ({ formState, t }): string | undefined => {
  const { startDate, endDate } = formState.formData;
  if (
    !Number.isNaN(startDate) &&
    isTruthy(startDate) &&
    !Number.isNaN(endDate) &&
    isTruthy(endDate) &&
    endDate < startDate
  ) {
    return t('form.startDateMustBeBeforeEndDate');
  }
  return undefined;
};

type Props = AppProps<Store>;

export function ExportMarketplaceInvoicingDialog({ $gs }: Props): JSX.Element {
  const [t] = useTranslation('exportMarketplaceInvoicing');
  const { $adminExportMarketplaceInvoicing } = $gs.$adminView;
  const formWarning = useGetState($adminExportMarketplaceInvoicing.$formWarning);

  const submitValidDataActionCallback = useActionCallback(
    async function submitValidDataAction({ actionDispatch, getState, httpClient }) {
      // Retrieve workshop configuration
      const { startDate, endDate } = getState().formData;

      const reportRows: readonly MarketplaceInvoicingRow[] = await httpClient.httpGetAsJson(
        CoreBackendRoutes.EXPORT_MARKETPLACE_KANBANS_INVOICING(startDate, endDate)
      );

      const fileName = getExportFileName(startDate, endDate);
      const content = getExportContent(reportRows);
      await downloadAndSaveBlob(content, fileName);

      // Close dialog
      actionDispatch.setProperty('active', false);
    },
    [],
    $adminExportMarketplaceInvoicing
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    AdminExportMarketplaceInvoicingDialogState
  >({
    $: $adminExportMarketplaceInvoicing,
    mandatoryFields,
    checkFieldContentActions,
    checkFormConsistencyAction,
    submitValidDataAction: submitValidDataActionCallback,
    t,
  });

  return (
    <ModalCardDialog
      size="max-desktop"
      warning={formWarning}
      title={t('modalTitle')}
      onOkClicked={onFormSubmit}
      $active={$adminExportMarketplaceInvoicing.$active}
    >
      <div>
        <CalendarFormField
          label={t('form.startDate')}
          horizontal={HORIZONTAL_PROPS}
          noExclamationTriangleIfWarning
          $={$formDataWithChangeTrigger.$startDate}
        />
        <CalendarFormField
          label={t('form.endDate')}
          horizontal={HORIZONTAL_PROPS}
          noExclamationTriangleIfWarning
          $={$formDataWithChangeTrigger.$endDate}
        />
      </div>
    </ModalCardDialog>
  );
}
