/* eslint-disable jsx-a11y/control-has-associated-label */
import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Operation, PackageDeal } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  EMPTY_OPERATION,
  enumerate,
  globalHelpers,
  nonDeleted,
  shortDayMonthYearHourFormatOptions,
  sortingHelpers,
  workflowHelpers,
} from '@stimcar/libs-base';
import { applyPayload, isTruthy, keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  Checkbox,
  ScrollableTableComponent,
  TableActionCell,
  TableActionHeaderCell,
  TableSortableHeaderComponent,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import { DisplayContentOrPlaceholder } from '../../lib/components/misc/DisplayContentOrPlaceholder.js';
import { TruncableLi } from '../../lib/components/misc/TruncableLi.js';
import { ShowHideBoxContainer } from '../../lib/components/ShowHideContainer.js';
import { useGetAllCarViewCategoryLabels } from '../../utils/carViewCategoriesTranslationHooks.js';
import type {
  EditOperationModalState,
  KanbanDetailsState,
  MobileDetailsSubPartState,
  OperationDetailsInternalDataStructure,
} from './typings/store.js';
import { convertOperationToFormData, EditOperationModal } from './EditOperationModal.js';
import {
  DURATION_DECIMAL_LENGTH,
  kanbanToDetailsInternalOperationStructure,
} from './kanbanDetailsUtils.js';
import { MobileDetailsModalMessageDialog } from './MobileDetailsModalMessageDialog.js';
import { EMPTY_EDIT_OPERATION_MODAL_STATE } from './typings/store.js';

function sortByNullCompletionDate(
  o1: OperationDetailsInternalDataStructure,
  o2: OperationDetailsInternalDataStructure
): number {
  if (!isTruthy(o1.operationCompletionDate)) {
    return !isTruthy(o2.operationCompletionDate) ? 0 : -1;
  }
  return !isTruthy(o2.operationCompletionDate) ? 1 : 0;
}

function sortByCanceledOrUnachievable(
  o1: OperationDetailsInternalDataStructure,
  o2: OperationDetailsInternalDataStructure
): number {
  const status1 = o1.status;
  const status2 = o2.status;
  if (status1 === 'canceled' || status1 === 'unachievable') {
    return status2 === 'canceled' || status2 === 'unachievable' ? 0 : 1;
  }
  return status2 === 'canceled' || status2 === 'unachievable' ? -1 : 0;
}

const computeCompletionStatusMsg = (
  t: TFunction,
  data: OperationDetailsInternalDataStructure
): string => {
  if (data.status === 'canceled') {
    return t('tabs.canceledTooltip');
  }
  if (data.status === 'unachievable') {
    return t('tabs.unachievableTooltip');
  }

  const completionDateMsg = isTruthy(data.operationCompletionDate)
    ? t('tabs.operations.completionDateMsg', {
        formattedCompletionDate: globalHelpers.convertTimestampToDateString(
          data.operationCompletionDate,
          shortDayMonthYearHourFormatOptions
        ),
      })
    : t('tabs.operations.nullCompletionDateMsg');

  const completionLoginMsg =
    (isTruthy(data.operationCompletionDate) && isTruthy(data.user)) || isTruthy(data.subcontractor)
      ? ` (${data.subcontractor ?? data.user})`
      : '';

  return `${completionDateMsg}${completionLoginMsg}`;
};

interface OperationItemProps {
  readonly operation: OperationDetailsInternalDataStructure;
  readonly $: StoreStateSelector<Store, EditOperationModalState>;
  readonly showTechnicalId: boolean;
  readonly isEditable: boolean;
  readonly t: TFunction;
}

function OperationItem({
  operation,
  $,
  t,
  isEditable,
  showTechnicalId,
}: OperationItemProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/require-await
  const showEditOperationModalCallback = useActionCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async ({ actionDispatch }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => {
        const initialOperation: Operation = {
          ...EMPTY_OPERATION,
          id: operation.id,
          label: operation.label,
          orderIndex: operation.orderIndex,
          completionDate: operation.operationCompletionDate,
          standId: operation.stand,
          attributes: operation.attributes,
          workload: operation.workload,
          deleted: false,
        };
        return {
          ...EMPTY_EDIT_OPERATION_MODAL_STATE,
          active: true,
          initialOperation,
          isExpertisePackageDeal: operation.isExpertisePackageDeal,
          formData: convertOperationToFormData(initialOperation),
        };
      });
    },
    [operation],
    $
  );
  const completionStatusMsg = computeCompletionStatusMsg(t, operation);
  const isOperationFinished = isTruthy(operation.operationCompletionDate);
  return (
    <tr
      style={{
        textDecoration:
          operation.status === 'canceled' || operation.status === 'unachievable'
            ? 'line-through'
            : 'none',
      }}
    >
      {showTechnicalId && <td>{operation.id}</td>}
      <td>{operation.stand}</td>
      <td>{operation.translatedCategoryLabel}</td>
      <td>{operation.packageDealLabel}</td>
      <td>{operation.label}</td>
      <td>{operation.carElement}</td>
      <td className="has-text-right">
        {t('tabs.hoursDuration', {
          hours: operation.workload.toFixed(DURATION_DECIMAL_LENGTH),
        })}
      </td>
      <td>
        {enumerate(
          keysOf(operation.attributes)
            .slice()
            .sort()
            .map((k) => `${k}: ${operation.attributes[k]}`)
        )}
      </td>
      <td>{completionStatusMsg}</td>
      {isEditable && (
        <TableActionCell
          onClick={showEditOperationModalCallback}
          iconId="edit"
          tooltip={
            isOperationFinished ? t('tabs.operations.disabledEditButton') : t('tabs.editButton')
          }
          disabled={isOperationFinished || operation.status !== 'available'}
        />
      )}
    </tr>
  );
}

interface Props extends AppProps<Store> {
  readonly packageDeals: readonly PackageDeal[];
  readonly workflowId: string;
  readonly isEditable: boolean;
  readonly $: StoreStateSelector<Store, KanbanDetailsState>;
}

export function DisplayAllOperationsComponent({
  $gs,
  packageDeals,
  workflowId,
  isEditable,
  $,
}: Props): JSX.Element {
  const [t] = useTranslation('details');

  const { $operationsTab } = $.$desktopState;
  const { $editOperationModal } = $operationsTab;

  const workflows = useGetState($gs.$siteConfiguration.$workflows);

  const workflow = useMemo(
    () => nonnull(workflows.find((w) => w.id === workflowId)),
    [workflowId, workflows]
  );

  const expertiseCategoryLabels = useGetAllCarViewCategoryLabels();

  const internalData = useMemo(() => {
    return kanbanToDetailsInternalOperationStructure(packageDeals, expertiseCategoryLabels);
  }, [packageDeals, expertiseCategoryLabels]);

  const sortBy = useGetState($operationsTab.$sort.$by);
  const sortDirection = useGetState($operationsTab.$sort.$direction);

  const sortedOperations = useMemo((): readonly OperationDetailsInternalDataStructure[] => {
    const sortedData = internalData.slice();
    let sortFunction: (
      c1: OperationDetailsInternalDataStructure,
      c2: OperationDetailsInternalDataStructure
    ) => number;
    switch (sortBy) {
      case 'packageDealLabel':
      case 'translatedCategoryLabel':
      case 'label':
      case 'carElement':
        sortFunction = sortingHelpers.createSortByStringField(sortDirection, sortBy);
        break;
      case 'workload':
      case 'operationCompletionDate':
        sortFunction = sortingHelpers.createSortByNumericField(sortDirection, sortBy);
        break;
      case 'stand':
        sortFunction = workflowHelpers.flatOperationsSorter(sortDirection, workflow.definition);
        break;
      default:
        return sortedData
          .sort(workflowHelpers.flatOperationsSorter('DOWN', workflow.definition))
          .sort(sortingHelpers.createSortByNumericField('UP', 'operationCompletionDate'))
          .sort(sortByNullCompletionDate)
          .sort(sortByCanceledOrUnachievable);
    }
    return sortedData.sort(sortFunction);
  }, [internalData, sortBy, sortDirection, workflow.definition]);

  const computeTotalWorkload = useCallback((): string => {
    return internalData
      .filter((d) => d.status === 'available')
      .reduce((acc, d) => acc + d.workload, 0)
      .toFixed(DURATION_DECIMAL_LENGTH);
  }, [internalData]);

  const submitEditOperationActionCallback = useActionCallback(
    async ({ getState, kanbanRepository, actionDispatch }) => {
      const { selectedKanban, desktopState } = getState();
      const { operationsTab } = desktopState;

      const { formData, initialOperation } = operationsTab.editOperationModal;
      const operationId = nonnull(initialOperation).id;
      const kanban = nonnull(selectedKanban);
      const operationPkg = kanban.packageDeals
        .filter(nonDeleted)
        .find((pkg) => isTruthy(pkg.operations.find(({ id }) => id === operationId)));
      if (!operationPkg) {
        throw new Error(`Unexpected error : unknown package deal for operation ${operationId}`);
      }
      const newKanban = applyPayload(kanban, {
        packageDeals: [
          {
            id: operationPkg.id,
            operations: [
              {
                id: operationId,
                label: formData.label,
                workload: Number.parseFloat(formData.workload),
                standId: formData.standId,
              },
            ],
          },
        ],
      });
      await kanbanRepository.updateEntity(newKanban);
      actionDispatch
        .scopeProperty('desktopState')
        .scopeProperty('operationsTab')
        .scopeProperty('editOperationModal')
        .setProperty('active', false);
    },
    [],
    $
  );
  const showTechnicalId = useGetState($operationsTab.$showTechnicalId);
  return (
    <>
      <DisplayContentOrPlaceholder
        displayCondition={internalData.length > 0}
        placeholder={t('tabs.operations.emptyPlaceholder')}
        isScrollable
      >
        <>
          <Checkbox $={$operationsTab.$showTechnicalId} text="ID " />
          <ScrollableTableComponent tableClassName="table is-narrow is-striped is-hoverable is-fullwidth">
            <thead>
              <tr>
                {showTechnicalId && <th>{t('tabs.idTitle')}</th>}
                <TableSortableHeaderComponent
                  content={t('tabs.operations.stand')}
                  tooltip={t('tabs.operations.standTooltip')}
                  centerLabel={false}
                  sortedField="stand"
                  isTruncable
                  $sort={$operationsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.operations.category')}
                  centerLabel={false}
                  isTruncable
                  sortedField="translatedCategoryLabel"
                  $sort={$operationsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.operations.packageDealLabel')}
                  centerLabel={false}
                  isTruncable
                  sortedField="packageDealLabel"
                  $sort={$operationsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.operations.label')}
                  centerLabel={false}
                  sortedField="label"
                  isTruncable
                  $sort={$operationsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.operations.carElement')}
                  centerLabel={false}
                  sortedField="carElement"
                  isTruncable
                  $sort={$operationsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.operations.workload')}
                  centerLabel={false}
                  className="has-text-right"
                  sortedField="workload"
                  isTruncable
                  $sort={$operationsTab.$sort}
                />
                <th>{t('tabs.operations.attributes')}</th>
                <TableSortableHeaderComponent
                  content={t('tabs.operations.completionStatus')}
                  className="has-text-centered"
                  centerLabel={false}
                  isTruncable
                  sortedField="operationCompletionDate"
                  $sort={$operationsTab.$sort}
                />
                {isEditable && <TableActionHeaderCell />}
              </tr>
            </thead>
            <tbody>
              {sortedOperations.map((o): JSX.Element => {
                return (
                  <OperationItem
                    key={o.id}
                    operation={o}
                    $={$editOperationModal}
                    showTechnicalId={showTechnicalId}
                    isEditable={isEditable}
                    t={t}
                  />
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={5} className="has-text-right">
                  <strong>{t('tabs.operations.totalAvailableWorkloadTitle')}</strong>
                </td>
                <td className="has-text-right">
                  {t('tabs.hoursDuration', {
                    hours: computeTotalWorkload(),
                  })}
                </td>
                <td colSpan={4} />
              </tr>
            </tfoot>
          </ScrollableTableComponent>
        </>
      </DisplayContentOrPlaceholder>
      <EditOperationModal
        $gs={$gs}
        $={$editOperationModal}
        submitValidDataAction={submitEditOperationActionCallback}
      />
    </>
  );
}

interface MobileOperationsComponentProps {
  readonly $: StoreStateSelector<
    Store,
    MobileDetailsSubPartState<OperationDetailsInternalDataStructure>
  >;
  readonly packageDeals: readonly PackageDeal[];
}

export function MobileOperationsComponent({
  $,
  packageDeals,
}: MobileOperationsComponentProps): JSX.Element {
  const [t] = useTranslation('details');

  const expertiseCategoryLabels = useGetAllCarViewCategoryLabels();

  const operationsInternalDatas = useMemo(() => {
    const datas = kanbanToDetailsInternalOperationStructure(packageDeals, expertiseCategoryLabels);
    return datas
      .sort(sortingHelpers.createSortByStringField('DOWN', 'stand'))
      .sort(sortingHelpers.createSortByNumericField('UP', 'operationCompletionDate'))
      .sort(sortByNullCompletionDate)
      .sort(sortByCanceledOrUnachievable);
  }, [packageDeals, expertiseCategoryLabels]);

  const details = useGetState($.$showDetailsFor);

  const completionStatus = useMemo((): string => {
    if (!isTruthy(details)) {
      return t('tabs.noStatus');
    }
    return computeCompletionStatusMsg(t, details);
  }, [details, t]);

  return (
    <>
      <ShowHideBoxContainer title={t('tabs.operations.title')} $={$.$isUnfolded} isMobile>
        <DisplayContentOrPlaceholder
          displayCondition={operationsInternalDatas.length > 0}
          placeholder={t('tabs.operations.emptyPlaceholder')}
        >
          <ul>
            {operationsInternalDatas.map(
              (o): JSX.Element => (
                <MobileOperationLine key={o.id} operation={o} $={$} />
              )
            )}
          </ul>
        </DisplayContentOrPlaceholder>
      </ShowHideBoxContainer>
      <MobileDetailsModalMessageDialog $={$}>
        <>
          <p>
            <strong>{`${t('tabs.operations.stand')}: `}</strong>
            {details?.stand}
          </p>
          <p>
            <strong>{`${t('tabs.operations.category')}: `}</strong>
            {details?.translatedCategoryLabel}
          </p>
          <p>
            <strong>{`${t('tabs.operations.packageDealLabel')}: `}</strong>
            {details?.packageDealLabel}
          </p>
          <p>
            <strong>{`${t('tabs.operations.label')}: `}</strong>
            {details?.label}
          </p>
          <p>
            <strong>{`${t('tabs.operations.carElement')}: `}</strong>
            {details?.carElement}
          </p>
          <p>
            <strong>{`${t('tabs.operations.workload')}: `}</strong>
            {t('tabs.hoursDuration', {
              hours: details?.workload.toFixed(DURATION_DECIMAL_LENGTH),
            })}
          </p>
          <p>
            <strong>{`${t('tabs.operations.attributes')}: `}</strong>
            {enumerate(
              keysOf(details?.attributes ?? {})
                .slice()
                .sort()
                .map((k) => `${k}: ${details?.attributes[k]}`)
            )}
          </p>
          <p>
            <strong>{`${t('tabs.operations.completionStatus')}: `}</strong>
            {completionStatus}
          </p>
        </>
      </MobileDetailsModalMessageDialog>
    </>
  );
}

interface MobileOperationLineProps {
  readonly operation: OperationDetailsInternalDataStructure;
  readonly $: StoreStateSelector<
    Store,
    MobileDetailsSubPartState<OperationDetailsInternalDataStructure>
  >;
}

function MobileOperationLine({ operation, $ }: MobileOperationLineProps): JSX.Element {
  const actionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('showDetailsFor', operation);
    },
    [operation],
    $
  );

  return (
    <TruncableLi
      key={operation.id}
      actionToolbox={{ action: actionCallback, className: 'is-rounded is-small is-text' }}
    >
      <span>{`${operation.label} (${operation.stand})`}</span>
    </TruncableLi>
  );
}
