import type { TFunction } from 'i18next';
import { useCallback } from 'react';
import type { Repository, RepositoryHTTPClient } from '@stimcar/core-libs-repository';
import type { Kanban, WorkshopPostCategory, WorkshopStandImplantation } from '@stimcar/libs-base';
import type {
  ActionContext,
  GlobalStoreStateSelector,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { RawActionButtonDesc } from '@stimcar/libs-uitoolkit';
import {
  AvailablePermissionPaths,
  globalHelpers,
  handlingHelpers,
  kanbanHelpers,
  packageDealHelpers,
  transverseHelpers,
  workshopHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { useInitializeSelectedPurchaseOrderTabActionCallback } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type {
  MoveForwardModalState,
  WorkshopActionProviderType,
  WorkshopImplantationViewState,
} from '../typings/store.js';
import { computeKanbanDetailsPath } from '../../../../app/coreConstants.js';
import { useHasAccessPermission } from '../../../../registeredapp/permissionHooks.js';
import {
  EDIT_ANOMALY_MESSAGE_MODAL_STATE,
  MOVE_FORWARD_MODAL_EMPTY_STATE,
} from '../typings/store.js';
import {
  computeAnomalyPostQualifiedCategoryId,
  openKanbanSelectionWizard,
} from './workshopImplantationUtils.js';

function isInAnomaly(kanban: Kanban | undefined, standId: string): boolean {
  if (!isTruthy(kanban)) {
    return false;
  }
  const openHandling = kanban.handlings.find((h) => !isTruthy(h.endDate) && h.standId === standId);
  if (!isTruthy(openHandling)) {
    return false;
  }
  const openAnomalyInterval = openHandling.intervals.find(
    (i) => i.type === 'anomaly' && !isTruthy(i.endDate)
  );
  return isTruthy(openAnomalyInterval);
}

async function moveKanbanToPost(
  kanbanRepository: Repository<'kanban'>,
  httpClient: RepositoryHTTPClient,
  kanban: Kanban | undefined,
  standId: string,
  user: string,
  qualifiedCurrentPostId: string,
  qualifiedTargetPostId: string | undefined,
  toCategoryQueue: boolean
): Promise<void> {
  let newKanban = kanbanHelpers.closeCurrentHandling(nonnull(kanban), qualifiedCurrentPostId);
  if (isTruthyAndNotEmpty(qualifiedTargetPostId)) {
    if (toCategoryQueue) {
      newKanban = kanbanHelpers.openNewHandlingWithoutInterval(
        httpClient.getBrowserSequence(),
        newKanban,
        standId,
        qualifiedTargetPostId,
        [user]
      );
    } else {
      newKanban = kanbanHelpers.openNewHandlingWithInterval(
        httpClient.getBrowserSequence(),
        newKanban,
        standId,
        qualifiedTargetPostId,
        [user],
        'work'
      );
    }
  }
  await kanbanRepository.updateEntity(newKanban);
}

const computeMoveForwardAvailablePostAndCategoriesOrCloseAction = (
  nextCategories: readonly WorkshopPostCategory[],
  allKanbans: readonly Kanban[],
  actualKanban: Kanban | undefined,
  implantationId: string,
  standId: string
): readonly WorkshopPostCategory[] => {
  const returnValue: WorkshopPostCategory[] = [];
  for (const nextCategory of nextCategories) {
    returnValue.push({
      ...nextCategory,
      posts: nextCategory.posts.filter((post) => {
        const qualifiedId = globalHelpers.computeQualifiedWorkshopPostId(
          implantationId,
          nextCategory.id,
          workshopHelpers.getImplantationPostName(post)
        );

        return (
          kanbanHelpers.getKanbanCurrentlyOnWorkshopQualifiedPost(
            allKanbans,
            standId,
            qualifiedId
          ) === undefined
        );
      }),
    });
    if (
      packageDealHelpers.getAllUnfinishedOperationsForWorkshopPostCategoryInStand(
        actualKanban?.packageDeals ?? [],
        implantationId,
        nextCategory.id,
        standId
      ).length > 0
    ) {
      break;
    }
  }
  return returnValue;
};

function openOperationsRedispatchModal(
  { actionDispatch }: ActionContext<Store, WorkshopImplantationViewState>,
  kanban: Kanban | undefined,
  currentPostCategory: string | undefined,
  standId: string,
  implantation: WorkshopStandImplantation,
  users: readonly string[],
  postName: string | undefined
): void {
  actionDispatch.scopeProperty('operationsRedispatchModal').applyPayload({
    isActive: true,
    originKanban: kanban,
    redispatchMotive: 'default',
    destinationCategory: currentPostCategory,
    standId,
    implantation,
    users,
    kanbanWithOperationsDispatched: kanban,
    destinationPostLabel: postName,
    originPostCategory: currentPostCategory,
  });
}

function openPostEjectionModal(
  { actionDispatch }: ActionContext<Store, WorkshopImplantationViewState>,
  kanban: Kanban,
  qualifiedCurrentPostId: string
): void {
  actionDispatch.scopeProperty('postEjectionModal').applyPayload({
    isActive: true,
    kanban,
    qualifiedPostId: qualifiedCurrentPostId,
  });
}

function openMoveForwardModal(
  { actionDispatch }: ActionContext<Store, WorkshopImplantationViewState>,
  kanban: Kanban | undefined,
  qualifiedCurrentPostId: string,
  availableCategoriesAndPosts: readonly WorkshopPostCategory[]
): void {
  actionDispatch.scopeProperty('moveForwardModal').reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial: MoveForwardModalState): MoveForwardModalState => {
      return {
        ...MOVE_FORWARD_MODAL_EMPTY_STATE,
        isActive: true,
        kanban,
        currentPostQualifiedId: qualifiedCurrentPostId,
        availableCategoriesAndPosts,
      };
    }
  );
}

function openSendKanbanToAnomalyModal(
  { actionDispatch }: ActionContext<Store, WorkshopImplantationViewState>,
  kanban: Kanban | undefined,
  qualifiedCurrentPostId: string,
  standId: string | undefined,
  user: string | undefined
): void {
  actionDispatch.scopeProperty('sendKanbanToAnomalyModal').applyPayload({
    active: true,
    kanban,
    qualifiedPostId: qualifiedCurrentPostId,
    standId,
    user,
  });
}

function openEditAnomalyMessageModal(
  { actionDispatch }: ActionContext<Store, WorkshopImplantationViewState>,
  kanban: Kanban,
  user: string,
  standId: string,
  qualifiedPostId: string
): void {
  const handling = nonnull(
    handlingHelpers.getOpenHandlingForPost(kanban, standId, qualifiedPostId)
  );
  if (!handlingHelpers.isAnActiveAnomalyHandling(handling)) {
    throw Error(`The openend handling on post ${qualifiedPostId} is not an anomaly handling`);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.scopeProperty('editAnomalyMessageModal').reduce((_) => {
    return {
      ...EDIT_ANOMALY_MESSAGE_MODAL_STATE,
      active: true,
      formData: {
        ...EDIT_ANOMALY_MESSAGE_MODAL_STATE.formData,
        reason:
          handlingHelpers.getCurrentIntervalInActiveHandling(handling)?.comment ??
          EDIT_ANOMALY_MESSAGE_MODAL_STATE.formData.reason,
      },
      kanban,
      user,
      standId,
      qualifiedPostId,
    };
  });
}

export function useCreateAnomalyPostActionProvider(
  t: TFunction,
  standId: string | undefined,
  implantation: WorkshopStandImplantation | undefined,
  user: string,
  noActions: boolean,
  $: StoreStateSelector<Store, WorkshopImplantationViewState>
): WorkshopActionProviderType {
  const anomalyQualifiedId = computeAnomalyPostQualifiedCategoryId(implantation?.id ?? '');
  const openEditAnomalyMessageModalCallback = useActionCallback(
    async ({ actionDispatch }, kanban: Kanban) => {
      await actionDispatch.exec(
        openEditAnomalyMessageModal,
        kanban,
        user,
        nonnull(standId),
        anomalyQualifiedId
      );
    },
    [anomalyQualifiedId, standId, user],
    $
  );
  return useCallback(
    (
      actualKanbanId,
      allKanbans,
      postCategoryId, // eslint-disable-line @typescript-eslint/no-unused-vars
      postName // eslint-disable-line @typescript-eslint/no-unused-vars
    ): RawActionButtonDesc<Store, WorkshopImplantationViewState>[] => {
      if (!isTruthy(implantation) || !isTruthyAndNotEmpty(standId) || noActions) {
        return [];
      }
      const actualKanban = allKanbans.find((k) => k.id === actualKanbanId);

      if (!isTruthy(actualKanban)) {
        return [];
      }

      const actionDescs: RawActionButtonDesc<Store, WorkshopImplantationViewState>[] = [];
      actionDescs.push({
        id: 'showDetails',
        icon: 'info',
        tooltip: t('implantation.postActions.showDetails'),
        disabled: !isTruthy(actualKanban),
        action: ({ navigate }): void => {
          navigate(computeKanbanDetailsPath(actualKanban.id));
        },
      });
      actionDescs.push({
        id: 'editAnomalyMessage',
        icon: 'comment',
        tooltip: t('implantation.postActions.editAnomalyMessage'),
        disabled: !isTruthy(actualKanban),
        action: async ({ globalActionDispatch }): Promise<void> => {
          await globalActionDispatch.execCallback(
            openEditAnomalyMessageModalCallback,
            actualKanban
          );
        },
      });

      return actionDescs;
    },
    [implantation, noActions, openEditAnomalyMessageModalCallback, standId, t]
  );
}

export function useCreatePostActionsProvider(
  t: TFunction,
  standId: string | undefined,
  implantation: WorkshopStandImplantation | undefined,
  user: string,
  noActions: boolean,
  $: StoreStateSelector<Store, WorkshopImplantationViewState>,
  $gs: GlobalStoreStateSelector<Store>
): WorkshopActionProviderType {
  const openKanbanSelectionWizardActionCallback = useActionCallback(
    async (
      { actionDispatch },
      furtherCategories: readonly WorkshopPostCategory[],
      postId: string,
      qualifiedPostIdOrCategoryId: string
    ) => {
      await actionDispatch.exec(
        openKanbanSelectionWizard,
        nonnull(implantation),
        nonnull(standId),
        furtherCategories,
        postId,
        [user],
        qualifiedPostIdOrCategoryId,
        false
      );
    },
    [implantation, standId, user],
    $
  );

  const openOperationsRedispatchModalActionCallback = useActionCallback(
    async (
      { actionDispatch },
      kanban: Kanban | undefined,
      currentPostCategory: string | undefined,
      postName: string | undefined
    ) => {
      await actionDispatch.exec(
        openOperationsRedispatchModal,
        kanban,
        currentPostCategory,
        nonnull(standId),
        nonnull(implantation),
        [user],
        postName
      );
    },
    [implantation, standId, user],
    $
  );

  const openPostEjectionModalActionCallback = useActionCallback(
    async ({ actionDispatch }, kanban: Kanban, qualifiedCurrentPostId: string) => {
      await actionDispatch.exec(openPostEjectionModal, kanban, qualifiedCurrentPostId);
    },
    [],
    $
  );

  const openMoveForwardModalActionCallback = useActionCallback(
    async (
      { actionDispatch },
      kanban: Kanban | undefined,
      qualifiedCurrentPostId: string,
      availableCategoriesAndPosts: readonly WorkshopPostCategory[]
    ) => {
      await actionDispatch.exec(
        openMoveForwardModal,
        kanban,
        qualifiedCurrentPostId,
        availableCategoriesAndPosts
      );
    },
    [],
    $
  );

  const openSendKanbanToAnomalyModalActionCallback = useActionCallback(
    async ({ actionDispatch }, kanban: Kanban | undefined, qualifiedCurrentPostId: string) => {
      await actionDispatch.exec(
        openSendKanbanToAnomalyModal,
        kanban,
        qualifiedCurrentPostId,
        standId,
        user
      );
    },
    [standId, user],
    $
  );

  const initializeSelectedPurchaseOrderTabActionCallback =
    useInitializeSelectedPurchaseOrderTabActionCallback(
      $gs.$detailsView.$desktopState.$estimateView
    );

  const canDispatchOperations = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CAN_DISPATCH_OPERATIONS
  );

  const canViewKanbanDetails = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CAN_ACCESS_KANBAN_DETAILS
  );

  const canShowEstimateFromWorkshop = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CAN_SHOW_ESTIMATE_FROM_WORKSHOP
  );

  return useCallback(
    (
      actualKanbanId,
      allKanbans,
      postCategoryId,
      postName
    ): RawActionButtonDesc<Store, WorkshopImplantationViewState>[] => {
      if (!isTruthy(implantation) || !isTruthyAndNotEmpty(standId) || noActions) {
        return [];
      }
      const actualKanban = allKanbans.find((k) => k.id === actualKanbanId);

      const handledPostQualifiedId = globalHelpers.computeQualifiedWorkshopPostId(
        implantation.id,
        postCategoryId,
        postName
      );

      const nextCategories = transverseHelpers.getFurtherWorkshopCategories(
        implantation,
        postCategoryId
      );

      const furtherCategories = transverseHelpers.getFurtherWorkshopCategories(
        implantation,
        postCategoryId,
        true
      );

      const { categoryId, implantationId, workshopPostId } =
        transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(handledPostQualifiedId);
      const isNextDisabled = (): boolean => {
        return (
          !isTruthy(actualKanban) ||
          workshopHelpers.getAllUnfinishedOperationsSoFar(
            actualKanban.packageDeals,
            standId,
            implantation,
            handledPostQualifiedId
          ).length > 0
        );
      };

      const isAndonActive = (): boolean => {
        return isTruthy(actualKanban) && kanbanHelpers.hasOpenAndon(nonnull(actualKanban));
      };

      const actionDescs: RawActionButtonDesc<Store, WorkshopImplantationViewState>[] = [];

      actionDescs.push({
        id: 'insertKanban',
        icon: isTruthy(actualKanban) ? 'eject' : 'plus-square',
        tooltip: isTruthy(actualKanban)
          ? t('implantation.postActions.eject')
          : t('implantation.postActions.insert'),
        disabled: false,
        action: async ({ actionDispatch }): Promise<void> => {
          if (isTruthy(actualKanban)) {
            await actionDispatch.execCallback(
              openPostEjectionModalActionCallback,
              actualKanban,
              handledPostQualifiedId
            );
          } else {
            await actionDispatch.execCallback(
              openKanbanSelectionWizardActionCallback,
              furtherCategories,
              nonnull(workshopPostId),
              handledPostQualifiedId
            );
          }
        },
        index: 2,
      });
      actionDescs.push({
        id: 'putInQueue',
        icon: 'step-backward',
        tooltip: t('implantation.postActions.putInQueue'),
        disabled: !isTruthy(actualKanban),
        action: async ({ httpClient, kanbanRepository }): Promise<void> => {
          await moveKanbanToPost(
            kanbanRepository,
            httpClient,
            actualKanban,
            standId,
            user,
            handledPostQualifiedId,
            nonnull(
              transverseHelpers.computeQualifiedWorkshopCategoryFromQualifiedWorkshopPostId(
                handledPostQualifiedId
              )
            ),
            true
          );
        },
        index: 3,
      });
      actionDescs.push({
        id: 'stepForward',
        icon: 'step-forward',
        tooltip: t('implantation.postActions.moveForward'),
        disabled: isNextDisabled(),
        action: async ({ httpClient, kanbanRepository, actionDispatch }): Promise<void> => {
          if (nextCategories.length === 0) {
            await moveKanbanToPost(
              kanbanRepository,
              httpClient,
              actualKanban,
              standId,
              user,
              handledPostQualifiedId,
              undefined,
              true
            );
          } else {
            const availableCategoriesAndPosts =
              computeMoveForwardAvailablePostAndCategoriesOrCloseAction(
                nextCategories,
                allKanbans,
                actualKanban,
                nonnull(implantation).id,
                nonnull(standId)
              );
            // If only the nextCategory is available and no posts are available in it, go directly in the waiting list
            if (
              availableCategoriesAndPosts.length === 1 &&
              availableCategoriesAndPosts[0].posts.length === 0
            ) {
              await moveKanbanToPost(
                kanbanRepository,
                httpClient,
                actualKanban,
                standId,
                user,
                handledPostQualifiedId,
                globalHelpers.computeQualifiedWorkshopPostId(
                  nonnull(implantationId),
                  nextCategories[0].id
                ),
                true
              );
            } else {
              await actionDispatch.execCallback(
                openMoveForwardModalActionCallback,
                actualKanban,
                handledPostQualifiedId,
                availableCategoriesAndPosts
              );
            }
          }
        },
        index: 4,
      });
      if (canDispatchOperations) {
        actionDescs.push({
          id: 'dispatch',
          icon: 'balance-scale',
          tooltip: t('implantation.postActions.dispatchOperations'),
          disabled: !isTruthy(actualKanban),
          action: async ({ actionDispatch }): Promise<void> => {
            await actionDispatch.execCallback(
              openOperationsRedispatchModalActionCallback,
              actualKanban,
              categoryId,
              postName
            );
          },
          index: 5,
        });
      }
      actionDescs.push({
        id: 'toggleAndon',
        icon: 'ambulance',
        tooltip: isAndonActive() ? t('andon.turnOff') : t('andon.turnOn'),
        disabled: !isTruthy(actualKanban) || isInAnomaly(actualKanban, standId),
        action: async ({ httpClient, kanbanRepository }): Promise<void> => {
          let newKanban: Kanban;
          if (isAndonActive()) {
            newKanban = kanbanHelpers.closeAndonAndOpenNewWorkInterval(
              httpClient.getBrowserSequence(),
              nonnull(actualKanban),
              handledPostQualifiedId,
              [user],
              standId
            );
          } else {
            newKanban = kanbanHelpers.openAndonInterval(
              httpClient.getBrowserSequence(),
              nonnull(actualKanban),
              handledPostQualifiedId,
              [user],
              standId
            );
          }
          await kanbanRepository.updateEntity(newKanban);
        },
        index: 6,
      });
      actionDescs.push({
        id: 'sendToAnomaly',
        icon: isInAnomaly(actualKanban, standId) ? 'check' : 'exclamation-triangle',
        tooltip: isInAnomaly(actualKanban, standId)
          ? t('implantation.postActions.closeAnomaly')
          : t('implantation.postActions.setInAnomaly'),
        disabled: !isTruthy(actualKanban),
        action: async ({ actionDispatch, httpClient, kanbanRepository }): Promise<void> => {
          if (!isInAnomaly(actualKanban, standId)) {
            await actionDispatch.execCallback(
              openSendKanbanToAnomalyModalActionCallback,
              actualKanban,
              handledPostQualifiedId
            );
          } else {
            const kanbanInAnomaly = kanbanHelpers.closeAnomalyAndOpenNewWorkInterval(
              httpClient.getBrowserSequence(),
              nonnull(actualKanban),
              [user],
              handledPostQualifiedId,
              standId
            );
            await kanbanRepository.updateEntity(kanbanInAnomaly);
          }
        },
        index: 7,
      });
      if (canViewKanbanDetails) {
        actionDescs.push({
          id: 'showDetails',
          icon: 'info',
          tooltip: t('implantation.postActions.showDetails'),
          disabled: !isTruthy(actualKanban),
          action: ({ navigate }): void => {
            navigate(computeKanbanDetailsPath(nonnull(actualKanban).id));
          },
          index: 8,
        });
      }
      if (canShowEstimateFromWorkshop) {
        actionDescs.push({
          id: 'showEstimate',
          icon: 'print',
          tooltip: t('custom:documentGeneration.estimate.actions.show'),
          disabled: !isTruthy(actualKanban),
          action: async ({ actionDispatch }): Promise<void> => {
            actionDispatch.setProperty('selectedKanbanForEstimate', actualKanban);
            if (actualKanban) {
              await actionDispatch.execCallback(
                initializeSelectedPurchaseOrderTabActionCallback,
                actualKanban.purchaseOrders
              );
            }
          },
          index: 9,
        });
      }

      return actionDescs;
    },
    [
      implantation,
      noActions,
      openKanbanSelectionWizardActionCallback,
      openMoveForwardModalActionCallback,
      openOperationsRedispatchModalActionCallback,
      openPostEjectionModalActionCallback,
      openSendKanbanToAnomalyModalActionCallback,
      standId,
      t,
      user,
      canDispatchOperations,
      canViewKanbanDetails,
      canShowEstimateFromWorkshop,
      initializeSelectedPurchaseOrderTabActionCallback,
    ]
  );
}

export function useCreateQueueActionsProvider(
  t: TFunction,
  standId: string | undefined,
  implantation: WorkshopStandImplantation | undefined,
  user: string,
  noActions: boolean,
  $: StoreStateSelector<Store, WorkshopImplantationViewState>,
  $gs: GlobalStoreStateSelector<Store>
): WorkshopActionProviderType {
  const openOperationsRedispatchModalActionCallback = useActionCallback(
    async (
      { actionDispatch },
      kanban: Kanban | undefined,
      currentPostCategory: string | undefined,
      postName: string | undefined
    ) => {
      await actionDispatch.exec(
        openOperationsRedispatchModal,
        kanban,
        currentPostCategory,
        nonnull(standId),
        nonnull(implantation),
        [user],
        postName
      );
    },
    [implantation, standId, user],
    $
  );

  const openPostEjectionModalActionCallback = useActionCallback(
    async ({ actionDispatch }, kanban: Kanban, qualifiedCurrentPostId: string) => {
      await actionDispatch.exec(openPostEjectionModal, kanban, qualifiedCurrentPostId);
    },
    [],
    $
  );

  const openMoveForwardModalActionCallback = useActionCallback(
    async (
      { actionDispatch },
      kanban: Kanban | undefined,
      qualifiedCurrentPostId: string,
      availableCategoriesAndPosts: readonly WorkshopPostCategory[]
    ) => {
      await actionDispatch.exec(
        openMoveForwardModal,
        kanban,
        qualifiedCurrentPostId,
        availableCategoriesAndPosts
      );
    },
    [],
    $
  );

  const initializeSelectedPurchaseOrderTabActionCallback =
    useInitializeSelectedPurchaseOrderTabActionCallback(
      $gs.$detailsView.$desktopState.$estimateView
    );

  const canDispatchOperations = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CAN_DISPATCH_OPERATIONS
  );

  const canViewKanbanDetails = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CAN_ACCESS_KANBAN_DETAILS
  );

  const canShowEstimateFromWorkshop = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CAN_SHOW_ESTIMATE_FROM_WORKSHOP
  );

  return useCallback(
    (
      actualKanbanId,
      allKanbans,
      postCategoryId,
      postName
    ): RawActionButtonDesc<Store, WorkshopImplantationViewState>[] => {
      if (!isTruthy(implantation) || !isTruthyAndNotEmpty(standId) || noActions) {
        return [];
      }
      const actualKanban = allKanbans.find((k) => k.id === actualKanbanId);

      const handledPostQualifiedId = globalHelpers.computeQualifiedWorkshopPostId(
        implantation.id,
        postCategoryId,
        postName
      );

      if (!isTruthy(actualKanban)) {
        return [];
      }

      const actionDescs: RawActionButtonDesc<Store, WorkshopImplantationViewState>[] = [];
      const { categoryId: currentPostCategory } =
        transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(handledPostQualifiedId);

      const nextCategories = transverseHelpers.getFurtherWorkshopCategories(
        implantation,
        postCategoryId,
        true
      );

      const availableCategoriesAndPosts = computeMoveForwardAvailablePostAndCategoriesOrCloseAction(
        nextCategories,
        allKanbans,
        actualKanban,
        nonnull(implantation).id,
        nonnull(standId)
      );

      const isThereAtLeastOneAvailablePost =
        availableCategoriesAndPosts.filter((cat) => cat.posts.length > 0).length > 0;

      actionDescs.push({
        id: 'eject',
        icon: 'eject',
        tooltip: t('implantation.postActions.eject'),
        disabled: !isTruthy(actualKanban),
        action: async ({ actionDispatch }): Promise<void> => {
          await actionDispatch.execCallback(
            openPostEjectionModalActionCallback,
            actualKanban,
            handledPostQualifiedId
          );
        },
        index: 1,
      });

      actionDescs.push({
        id: 'stepForward',
        icon: 'step-forward',
        tooltip: t('implantation.postActions.moveForwardFromQueue'),
        disabled: !isThereAtLeastOneAvailablePost,
        action: async ({ httpClient, kanbanRepository, globalActionDispatch }): Promise<void> => {
          if (
            availableCategoriesAndPosts.length === 1 &&
            availableCategoriesAndPosts[0].posts.length === 1
          ) {
            // if there is only one available post
            const targetQualifiedPostId = globalHelpers.computeQualifiedWorkshopPostId(
              nonnull(implantation).id,
              availableCategoriesAndPosts[0].id,
              workshopHelpers.getImplantationPostName(availableCategoriesAndPosts[0].posts[0])
            );
            await moveKanbanToPost(
              kanbanRepository,
              httpClient,
              actualKanban,
              standId,
              user,
              handledPostQualifiedId,
              targetQualifiedPostId,
              false
            );
          } else {
            await globalActionDispatch.execCallback(
              openMoveForwardModalActionCallback,
              actualKanban,
              handledPostQualifiedId,
              availableCategoriesAndPosts
            );
          }
        },
        index: 2,
      });
      if (canDispatchOperations) {
        actionDescs.push({
          id: 'dispatch',
          icon: 'balance-scale',
          tooltip: t('implantation.postActions.dispatchOperations'),
          disabled: !isTruthy(actualKanban),
          action: async ({ actionDispatch }): Promise<void> => {
            await actionDispatch.execCallback(
              openOperationsRedispatchModalActionCallback,
              actualKanban,
              currentPostCategory,
              postName
            );
          },
          index: 3,
        });
      }
      if (canViewKanbanDetails) {
        actionDescs.push({
          id: 'showDetails',
          icon: 'info',
          tooltip: t('implantation.postActions.showDetails'),
          disabled: !isTruthy(actualKanban),
          action: ({ navigate }): void => {
            navigate(computeKanbanDetailsPath(nonnull(actualKanban).id));
          },
          index: 4,
        });
      }
      if (canShowEstimateFromWorkshop) {
        actionDescs.push({
          id: 'showEstimate',
          icon: 'print',
          tooltip: t('custom:documentGeneration.estimate.actions.show'),
          disabled: !isTruthy(actualKanban),
          action: async ({ actionDispatch }): Promise<void> => {
            actionDispatch.setProperty('selectedKanbanForEstimate', actualKanban);
            await actionDispatch.execCallback(
              initializeSelectedPurchaseOrderTabActionCallback,
              actualKanban.purchaseOrders
            );
          },
          index: 5,
        });
      }

      return actionDescs;
    },
    [
      implantation,
      noActions,
      openMoveForwardModalActionCallback,
      openOperationsRedispatchModalActionCallback,
      openPostEjectionModalActionCallback,
      standId,
      t,
      user,
      canDispatchOperations,
      canViewKanbanDetails,
      canShowEstimateFromWorkshop,
      initializeSelectedPurchaseOrderTabActionCallback,
    ]
  );
}
