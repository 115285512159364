import type { Kanban, RepositoryEntityPayload } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { ActionContext } from '@stimcar/libs-uikernel';
import { computePayload, nonnull } from '@stimcar/libs-kernel';
import type { Store } from '../../../state/typings/store.js';
import type { EditCommentsModalDialogState, PackageDealComments } from '../typings/store.js';

export async function updatePackageDealCommentsAction({
  kanbanRepository,
  getState,
}: ActionContext<Store, EditCommentsModalDialogState>) {
  const { formData, kanbanId, packageDeal } = getState();
  const { commentForStore, commentForWorkshop, commentForCustomer } = formData;

  const targetPackageDeal = nonnull(packageDeal);
  const modifiedPackageDeal: PackageDealComments = {
    ...targetPackageDeal,
    commentForStore,
    comment: commentForWorkshop ?? '',
    estimateComment: commentForCustomer ?? '',
  };
  const changes = computePayload(targetPackageDeal, modifiedPackageDeal);
  if (changes) {
    const payload = toPackageDealChangedPayload(kanbanId, targetPackageDeal.id, changes);
    await kanbanRepository.updateEntityFromPayload(payload);
  }
}

function toPackageDealChangedPayload(
  kanbanId: string,
  packageDealId: string,
  changes: DeepPartial<PackageDealComments>
): RepositoryEntityPayload<Kanban> {
  return {
    entityId: kanbanId,
    payload: {
      packageDeals: [
        {
          id: packageDealId,
          ...changes,
        },
      ],
    },
  };
}
