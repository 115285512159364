/* eslint-disable jsx-a11y/control-has-associated-label */
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  AttributeType,
  Kanban,
  UIContract,
  WorkshopOperation,
  WorkshopPostCategory,
} from '@stimcar/libs-base';
import type { KanbanInfos } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  compareStrings,
  i18nHelpers,
  kanbanHelpers,
  OPERATION_ATTRIBUTES,
  packageDealHelpers,
  transverseHelpers,
  workshopHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ToggleNestedButton } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { SubcontractableInfo } from '../../utils/operationCompletion/SelectSubcontractorFirmModalDialog.js';
import { KanbanIdentityPictureComponent } from '../../../lib/components/attachments/KanbanIdentityPictureComponent.js';
import { KanbanIdentityPictureAndGeneralInformationsComponent } from '../../../lib/components/identityPictureAndGeneralInfoDisplay/KanbanIdentityPictureAndGeneralInformationsComponent.js';
import { DisplayContentOrPlaceholder } from '../../../lib/components/misc/DisplayContentOrPlaceholder.js';
import { toggleCompletedOperationStatusAction } from '../../../lib/components/workshop/implantation/workshopImplantationUtils.js';
import { WorkshopPostOperations } from '../../../lib/components/workshop/post/WorkshopPostOperations.js';
import { AdditionalStepsForOperationCompletionModal } from '../../utils/operationCompletion/AdditionalStepsForOperationCompletionModal.js';
import { useComputeAttachmentUrl } from '../../utils/useComputeAttachmentUrl.js';
import { useGetContractByCode } from '../../utils/useGetContract.js';
import { useGetCurrentWorkshopOperatorImplantation } from '../../utils/useGetCurrentWorkshopOperatorImplantation.js';
import { useGetCurrentWorkshopPostDisplayedLabels } from '../../utils/useGetCurrentWorkshopPostDisplayedLabels.js';
import type { WorkshopPostViewState } from './typings/store.js';
import { FurtherOperations } from './FurtherOperations.js';
import { EMPTY_WORK_ON_FURTHER_OPERATION_MODAL_STATE } from './typings/store.js';

const FULL_DETAILS_SECTION_ID = 'fullDetails';

async function toggleAndon(
  { kanbanRepository, httpClient }: ActionContext<Store, WorkshopPostViewState>,
  kanban: Kanban,
  workshopPostId: string,
  users: readonly string[],
  standId: string
): Promise<void> {
  const isAndonActive = kanbanHelpers.hasOpenAndon(kanban);
  const newKanban = isAndonActive
    ? kanbanHelpers.closeAndonAndOpenNewWorkInterval(
        httpClient.getBrowserSequence(),
        kanban,
        workshopPostId,
        users,
        standId
      )
    : kanbanHelpers.openAndonInterval(
        httpClient.getBrowserSequence(),
        kanban,
        workshopPostId,
        users,
        standId
      );
  await kanbanRepository.updateEntity(newKanban);
}

/**
 * Returns the kanban's non-finished operations that are within the stand defined by standId but not
 * on the workshopPost defined by qualifiedPostId
 * @param kanban the one whose further operations we want.
 * @param standId the stand within which we seek the further operations.
 * @param qualifiedPostId the workshopPost from where we are seeking.
 * @returns found operations are organized in a record of records. Keys of the first record are the post categories
 * where are those operations and within each workshopPostId operations are groupe by their labels (keys of the second record)
 */
function getFurtherOperationsGroupedByWorkshopCategoryThenOpLabel(
  kanban: Kanban,
  standId: string,
  qualifiedCategory: string
): Record<string, Record<string, WorkshopOperation[]>> {
  const groupedFurtherOperations: Record<string, Record<string, WorkshopOperation[]>> = {};
  packageDealHelpers.getAvailablePackageDeals(kanban.packageDeals).forEach((pkg) => {
    const furtherOperations =
      packageDealHelpers.getAllOperationsWithinStandToDoElsewhereThanCurrentCategory(
        pkg,
        standId,
        qualifiedCategory
      );
    furtherOperations.forEach((op) => {
      const categoryId = nonnull(
        transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(
          String(op.attributes[OPERATION_ATTRIBUTES.WORKSHOP_POST])
        ).categoryId
      );
      if (!groupedFurtherOperations[categoryId]) {
        groupedFurtherOperations[categoryId] = {};
      }
      const opLabel = packageDealHelpers.getOperationDisplayedLabel(op, pkg.variables);
      if (!groupedFurtherOperations[categoryId][opLabel]) {
        groupedFurtherOperations[categoryId][opLabel] = [];
      }
      groupedFurtherOperations[categoryId][opLabel].push(
        ...workshopHelpers.convertToWorkshopOperations([op], pkg)
      );
    });
  });
  return groupedFurtherOperations;
}

interface KanbanInfosProps {
  readonly infos: KanbanInfos | undefined;
  readonly contract: UIContract | undefined;
  readonly $expandedSectionIds: StoreStateSelector<Store, readonly string[]>;
}

function KanbanInfosAbstract({
  infos,
  $expandedSectionIds,
}: Omit<KanbanInfosProps, 'contract'>): JSX.Element {
  const [t] = useTranslation('workshop');
  return (
    <table className="table is-fullwidth" style={{ marginBottom: 0 }}>
      <tbody>
        <tr>
          <td>
            <strong>{i18nHelpers.displayStringOrPlaceholder(t, infos?.brand)}</strong>
          </td>
          <td>
            <strong>{infos?.model}</strong>
          </td>
          <td>
            <strong>{infos?.license}</strong>
          </td>
          <td>
            <strong>{infos?.vin}</strong>
          </td>
          <td>
            <strong>{infos?.color}</strong>
          </td>
          <td>
            <strong>
              {t('post.dateOfRegistration', {
                date: infos?.dateOfRegistration,
              })}
            </strong>
          </td>
          <td>
            <strong>{t('post.mileage', { mileage: infos?.mileage })}</strong>
          </td>
          <td className="has-text-right">
            <ToggleNestedButton
              id={FULL_DETAILS_SECTION_ID}
              hasChildren
              $expandedIds={$expandedSectionIds}
              caretDirection="left"
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

interface KanbanFullInfosProps {
  readonly operatedKanban: Kanban | undefined;
  readonly contract: UIContract | undefined;
}

function KanbanFullInfos({ operatedKanban, contract }: KanbanFullInfosProps): JSX.Element {
  return (
    <>
      <table className="table is-fullwidth">
        <tbody>
          <KanbanIdentityPictureAndGeneralInformationsComponent
            kanban={nonnull(operatedKanban)}
            hideTitle
            contract={contract}
          />
        </tbody>
      </table>
    </>
  );
}

const getAttributeAsString = (
  attributes: Record<string, AttributeType>,
  key: string
): string | undefined => {
  if (attributes[key] && isTruthyAndNotEmpty(String(attributes[key]))) {
    return String(attributes[key]);
  }
  return undefined;
};

export function WorkshopPost({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('workshop');
  const { $workshopImplantationView, $workshopPostView: $ } = $gs;
  const computeAttachmentUrlCallback = useComputeAttachmentUrl($gs);
  const implantation = useGetCurrentWorkshopOperatorImplantation($gs);

  const isOnline = useGetState($gs.$session.$isOnline);
  const user = useGetState($gs.$session.$user);

  // Workshop operator posts still have a standId

  const standId = useGetState($gs.$session.$infos.optChaining().$standId) ?? '';
  const qualifiedPostId = useGetState($gs.$session.$infos.optChaining().$label) ?? '';

  const showFurtherOperations = useGetState($.$showFurtherOperations);

  const { postLabel } = useGetCurrentWorkshopPostDisplayedLabels($gs);

  const kanbans = useGetState($workshopImplantationView.$kanbans);

  const operatedKanban = useMemo((): Kanban | undefined => {
    return kanbanHelpers.getKanbanCurrentlyOnWorkshopQualifiedPost(
      kanbans,
      standId,
      qualifiedPostId
    );
  }, [kanbans, qualifiedPostId, standId]);

  const operatedKanbanContract = useGetContractByCode($gs, operatedKanban?.contract.code);

  const furtherCategories = useMemo((): readonly WorkshopPostCategory[] => {
    if (isTruthy(implantation)) {
      const { categoryId } =
        transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(qualifiedPostId);

      return transverseHelpers.getFurtherWorkshopCategories(
        implantation,
        nonnull(categoryId),
        true
      );
    }
    return [];
  }, [implantation, qualifiedPostId]);

  const workshopOperations = useMemo((): Record<string, readonly WorkshopOperation[]> => {
    return workshopHelpers.getWorkshopOperationsGroupedByLabel(
      operatedKanban,
      qualifiedPostId,
      standId,
      implantation
    );
  }, [operatedKanban, qualifiedPostId, standId, implantation]);

  const furtherOperations = useMemo(():
    | Record<string, Record<string, readonly WorkshopOperation[]>>
    | undefined => {
    if (!showFurtherOperations || !isTruthy(operatedKanban) || !isTruthy(qualifiedPostId)) {
      return undefined;
    }
    const qualifiedCategory =
      transverseHelpers.computeQualifiedWorkshopCategoryFromQualifiedWorkshopPostId(
        qualifiedPostId
      );
    return getFurtherOperationsGroupedByWorkshopCategoryThenOpLabel(
      operatedKanban,
      standId,
      nonnull(qualifiedCategory)
    );
  }, [operatedKanban, qualifiedPostId, showFurtherOperations, standId]);

  const furtherOperationsButtonLabel = useMemo((): string => {
    let buttonLabel: string = t('post.lookForFurtherOperations');
    if (isTruthy(furtherOperations)) {
      if (keysOf(furtherOperations).length === 0) {
        buttonLabel = t('post.noFurtherOperationsOnKanban');
      } else {
        buttonLabel = t('post.hideFurtherOperations');
      }
    }
    return buttonLabel;
  }, [furtherOperations, t]);

  const isAndonActive = useMemo((): boolean => {
    return isTruthy(operatedKanban) ? kanbanHelpers.hasOpenAndon(operatedKanban) : false;
  }, [operatedKanban]);

  const toggleCompletedOperationStatusActionCallback = useActionCallback(
    async (
      { actionDispatch },
      operationId: string,
      subcontractorInfo?: SubcontractableInfo,
      selectedFiles?: readonly string[]
    ) => {
      await actionDispatch.exec(
        toggleCompletedOperationStatusAction,
        operatedKanban!.id,
        operationId,
        subcontractorInfo,
        selectedFiles
      );
    },
    [operatedKanban],
    $
  );

  const toggleAndonCallback = useActionCallback(
    async ({ actionDispatch }) => {
      if (isTruthy(operatedKanban)) {
        const users = isTruthy(user) ? [user.login] : [];
        await actionDispatch.exec(toggleAndon, operatedKanban, qualifiedPostId, users, standId);
      }
    },
    [operatedKanban, qualifiedPostId, standId, user],
    $
  );

  const toggleShowFurtherOperationsCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('showFurtherOperations', !showFurtherOperations);
    },
    [showFurtherOperations],
    $
  );

  const workOnFurtherOperationCallback = useActionCallback(
    async ({ kanbanRepository, actionDispatch }, operationId: string) => {
      if (operatedKanban) {
        const qualifiedWorkshopCategory =
          transverseHelpers.computeQualifiedWorkshopCategoryFromQualifiedWorkshopPostId(
            qualifiedPostId
          );
        const updatedKanban = kanbanHelpers.assignOperationToWorkshopCategory(
          nonnull(operatedKanban),
          operationId,
          nonnull(qualifiedWorkshopCategory)
        );
        await kanbanRepository.updateEntity(updatedKanban);
        actionDispatch.reduce((initial): WorkshopPostViewState => {
          return {
            ...initial,
            workOnFurtherOperationModal: EMPTY_WORK_ON_FURTHER_OPERATION_MODAL_STATE,
          };
        });
      }
    },
    [qualifiedPostId, operatedKanban],
    $
  );

  const displayedAttributes = useMemo(() => {
    const attributes: AttributeToDisplay[] = [];
    const attributeDescs =
      operatedKanbanContract?.attributeDescs.filter((a) => a.displayInWorkshopPost) ?? [];
    attributeDescs
      .sort((a, b) => compareStrings(a.id, b.id, 'UP'))
      .forEach((a) => {
        const value = getAttributeAsString(operatedKanban?.attributes ?? {}, a.id);

        if (isTruthyAndNotEmpty(value)) {
          attributes.push({
            label: a.id,
            value,
          });
        }
      });
    return attributes;
  }, [operatedKanban, operatedKanbanContract]);

  const expandedSectionIds = useGetState($.$expandedSectionIds);

  return (
    <>
      <DisplayContentOrPlaceholder
        displayCondition={isTruthy(operatedKanban)}
        placeholder={t('post.noKanban')}
      >
        <div className={`columns ${isAndonActive ? 'action-needed' : ''}`}>
          <div className="column is-10">
            <div className="box header">
              <KanbanInfosAbstract
                infos={operatedKanban?.infos}
                $expandedSectionIds={$.$expandedSectionIds}
              />
              {expandedSectionIds.includes(FULL_DETAILS_SECTION_ID) && (
                <KanbanFullInfos
                  operatedKanban={nonnull(operatedKanban)}
                  contract={operatedKanbanContract}
                />
              )}
            </div>
            <div
              className={
                keysOf(workshopOperations).length === 0 ? ' has-text-centered m-t-xxl' : ''
              }
            >
              <WorkshopPostOperations
                postLabel={nonnull(postLabel)}
                operations={workshopOperations}
                toggleOperationFinished={toggleCompletedOperationStatusActionCallback}
                $expandedOperationIds={$.$expandedSectionIds}
                $gs={$gs}
                kanbanId={operatedKanban?.id ?? ''}
                computeAttachmentUrlCallback={computeAttachmentUrlCallback}
              />
              <div className="has-text-centered" style={{ marginBottom: '1.5em' }}>
                <button
                  type="button"
                  className="button is-primary has-text-centered"
                  onClick={toggleShowFurtherOperationsCallback}
                  disabled={isTruthy(furtherOperations) && keysOf(furtherOperations).length === 0}
                >
                  {furtherOperationsButtonLabel}
                </button>
              </div>
              {showFurtherOperations && furtherOperations && (
                <FurtherOperations
                  tubeStandId={standId}
                  postId={qualifiedPostId}
                  operationsGroupedByWorkshopCategoryThenOpLabel={furtherOperations}
                  workOnFurtherOperationCallback={workOnFurtherOperationCallback}
                  nextLevels={furtherCategories}
                  $gs={$gs}
                />
              )}
            </div>
          </div>
          <div className="column">
            <div className="vertical-grid-column-with-generic-spacing">
              <div style={{ gridColumn: 1, gridRow: 1 }}>
                <KanbanIdentityPictureComponent
                  kanbanId={operatedKanban?.id}
                  computeAttachmentUrl={computeAttachmentUrlCallback}
                  $imageModal={$gs.$imageModal}
                  thumbnailDisplayWidth={234}
                  isOnline={isOnline}
                />
              </div>
              <div style={{ gridColumn: 1, gridRow: 2 }}>
                <span>
                  <button
                    type="button"
                    className={`button is-primary is-fullwidth m-t-ld title is-4 p-xxs${
                      isAndonActive ? ' is-primary' : ''
                    }`}
                    onClick={toggleAndonCallback}
                  >
                    {t(`andon.${isAndonActive ? 'turnOff' : 'turnOn'}`)}
                  </button>
                </span>
              </div>
              <AdditionalOperatorInfos displayedAttributes={displayedAttributes} />
            </div>
          </div>
        </div>
      </DisplayContentOrPlaceholder>
      <AdditionalStepsForOperationCompletionModal
        $={$}
        $gs={$gs}
        toggleOperationActionCallback={toggleCompletedOperationStatusActionCallback}
      />
    </>
  );
}

interface AttributeToDisplay {
  label: string;
  value: string;
}

interface AdditionalOperatorInfosProps {
  readonly displayedAttributes: readonly AttributeToDisplay[];
}

function AdditionalOperatorInfos({
  displayedAttributes,
}: AdditionalOperatorInfosProps): JSX.Element {
  const [t] = useTranslation('workshop');

  return displayedAttributes.length > 0 ? (
    <div style={{ gridColumn: 1 }}>
      <div className="is-size-4 m-t-md m-b-sm">
        <b>{t('post.additionalOperatorInfos.title')}</b>
      </div>
      {displayedAttributes.map(
        (a): JSX.Element => (
          <div className="m-b-sm" key={a.label}>
            <b>
              {t('post.additionalOperatorInfos.displayedAttributeLabel', {
                label: a.label,
              })}
            </b>
            <strong>{a.value}</strong>
          </div>
        )
      )}
    </div>
  ) : (
    <></>
  );
}
