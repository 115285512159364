import type { JSX } from 'react';
import { type TFunction } from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { HorizontalFormFieldProps, MessageDialogState } from '@stimcar/libs-uitoolkit';
import { EMAILS_PER_CUSTOMER_SHEET_ID } from '@stimcar/core-libs-common';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  CalendarFormField,
  CheckboxFormField,
  ModalCardDialog,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { InputFileFormField } from '../../../lib/bulma/form/InputFileFormField.js';
import { downloadAndSaveFromResponse } from '../../../lib/utils/download.js';
import type { AdminInvoicesReminderDialogState } from './typings/store.js';
import { EMPTY_ADMIN_INVOICES_REMINDER_DIALOG_STATE } from './typings/store.js';

export function openInvoicesReminderDialogAction({
  actionDispatch,
}: ActionContext<Store, AdminInvoicesReminderDialogState>) {
  actionDispatch.setValue({
    ...EMPTY_ADMIN_INVOICES_REMINDER_DIALOG_STATE,
    active: true,
    formData: {
      reminderDate: Date.now(),
      sendEmails: false,
      invoicesSummaryFile: null,
      warnings: {},
    },
  });
}

type BlockingResult = {
  readonly code: string;
  readonly reason: string;
  readonly value: readonly string[];
};

function getBlockingResults(response: Response): readonly BlockingResult[] {
  const blockingResultsAsBase64 = response.headers.get('Blocking-Problems');
  if (isTruthy(blockingResultsAsBase64)) {
    const blockingResultsAsString = atob(blockingResultsAsBase64);
    return JSON.parse(blockingResultsAsString) as BlockingResult[];
  }
  return [];
}

function getResultMessage(
  blockingResults: readonly BlockingResult[],
  sendEmails: boolean,
  t: TFunction
): MessageDialogState {
  if (blockingResults.length === 0) {
    if (sendEmails) {
      return {
        type: 'info',
        title: t('success.emailsSentTitle'),
        content: t('success.emailsSent'),
      };
    }
    return {
      type: 'info',
      title: t('success.emailsNotSentTitle'),
      content: t('success.emailsNotSent'),
    };
  }
  const errorLines = blockingResults.map(
    ({ code, reason, value }) => `\n | ${code} | ${reason} | ${value.join(', ')} |`
  );
  const content = `${t('error.prefix')}\n\n${t('error.tableHeader')}${errorLines}`;
  return {
    type: 'warning',
    title: t('error.title'),
    content,
  };
}

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  labelFlexGrow: 2,
  bodyFlexGrow: 3,
};

type AdminInvoicesReminderDialogProps = {
  readonly $: StoreStateSelector<Store, AdminInvoicesReminderDialogState>;
};

export function AdminInvoicesReminderDialog({ $ }: AdminInvoicesReminderDialogProps): JSX.Element {
  const [t] = useTranslation('adminInvoicesReminder');
  const launchProcess = useGetState($.$launchProcess);

  const launchProcessActionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('launchProcess', true);
    },
    [],
    $
  );

  const submitValidDataActionCallback = useActionCallback(
    async ({ actionDispatch, getState, httpClient, globalActionDispatch }) => {
      if (launchProcess) {
        const { formData } = getState();
        const { reminderDate, sendEmails, invoicesSummaryFile } = formData;

        const response = await httpClient.httpRawPostAsFile(
          CoreBackendRoutes.SEND_INVOICES_REMINDER(reminderDate, sendEmails),
          {
            inputFile: nonnull(invoicesSummaryFile),
          },
          'application/zip'
        );

        const blockingResults = getBlockingResults(response);

        actionDispatch.setProperty('launchProcess', false);
        actionDispatch.setProperty('active', false);

        await downloadAndSaveFromResponse(response, 'data.zip');

        globalActionDispatch.setProperty(
          'message',
          getResultMessage(blockingResults, sendEmails, t)
        );
      }
    },
    [launchProcess, t],
    $
  );

  const asyncEffect = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.execCallback(submitValidDataActionCallback);
    },
    [submitValidDataActionCallback],
    $
  );
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    AdminInvoicesReminderDialogState
  >({
    $,
    mandatoryFields: [],
    checkFieldContentActions: {},
    checkFormConsistencyAction: ({ formState }) => {
      const { formData } = formState;
      const { reminderDate, invoicesSummaryFile } = formData;
      if (!isTruthy(reminderDate) || Number.isNaN(reminderDate)) {
        return t('form.errors.reminderDateRequired');
      }
      if (!isTruthy(invoicesSummaryFile)) {
        return t('form.errors.invoicesSummaryFileRequired');
      }
      return undefined;
    },
    submitValidDataAction: launchProcessActionCallback,
    t,
  });

  const formWarning = useGetState($.$formWarning);

  return (
    <ModalCardDialog
      $active={$.$active}
      title={t('modalTitle')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
      isReadonly={launchProcess}
    >
      <CalendarFormField
        label={t('form.reminderDate')}
        horizontal={HORIZONTAL_PROPS}
        noExclamationTriangleIfWarning
        $={$formDataWithChangeTrigger.$reminderDate}
        isDisabled
      />
      <CheckboxFormField
        label={t('form.sendEmails')}
        horizontal={HORIZONTAL_PROPS}
        $={$formDataWithChangeTrigger.$sendEmails}
        disabled={launchProcess}
      />
      <InputFileFormField
        $={$formDataWithChangeTrigger.$invoicesSummaryFile}
        label={t('form.invoicesSummaryFile')}
        buttonLabel={t('selectFileButton')}
        noSelectedFileLabel={t('noSelectedFile')}
        horizontal={HORIZONTAL_PROPS}
        disabled={launchProcess}
      />
      <a
        href={`https://docs.google.com/spreadsheets/d/${EMAILS_PER_CUSTOMER_SHEET_ID}`}
        target="_blank"
        rel="noreferrer"
        className="is-block is-size-7 has-text-centered"
      >
        {t('linkToCustomerConfigurationSheet')}
      </a>
      {launchProcess && (
        <p className="has-text-centered">
          <progress className="progress is-small is-primary" />
          {t('inProgress')}
        </p>
      )}
    </ModalCardDialog>
  );
}
