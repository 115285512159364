import type { JSX } from 'react';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  ActionContext,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { useGetState } from '@stimcar/libs-uikernel';
import {
  ListSelectFormField,
  ModalCardDialog,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type {
  SelectPredefinedCustomerDialogState,
  SelectPredefinedCustomerFormData,
} from '../typings/store.js';
import { EMPTY_SELECT_PREDEFINED_CUSTOMER_DIALOG_SATE } from '../typings/store.js';

interface SelectPredefinedCustomerDialogProps {
  readonly $: StoreStateSelector<Store, SelectPredefinedCustomerDialogState>;
  readonly submitValidDataAction: NoArgActionCallback<Store>;
}

const mandatoryFields: readonly (keyof SelectPredefinedCustomerFormData)[] = ['customerId'];

const SORT_FUNCTION = (a: FormFieldEntry<string>, b: FormFieldEntry<string>): number =>
  a.label.localeCompare(b.label);

export const openSelectPredefinedCustomerDialog = async (
  {
    actionDispatch,
    globalActionDispatch,
    customerRepository,
  }: ActionContext<Store, SelectPredefinedCustomerDialogState>,
  contractCode: string
): Promise<void> => {
  const entities = (await customerRepository.getAllEntities()).filter(
    (e) => e.contract === contractCode
  );
  if (entities.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    actionDispatch.reduce((initial) => {
      return {
        ...EMPTY_SELECT_PREDEFINED_CUSTOMER_DIALOG_SATE,
        active: true,
        customers: entities,
      };
    });
  } else {
    globalActionDispatch.setProperty(
      'message',
      i18next.t('creation:selectPredefinedCustomerDialog.noPredefinedCustomerForThisContract', {
        contract: contractCode,
      })
    );
  }
};

export function SelectPredefinedCustomerDialog({
  $,
  submitValidDataAction,
}: SelectPredefinedCustomerDialogProps): JSX.Element {
  const [t] = useTranslation(['creation', 'globals']);
  const customers = useGetState($.$customers);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    SelectPredefinedCustomerDialogState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  const customerEntries = useMemo(
    (): FormFieldEntry<string>[] =>
      customers.map(({ id, shortName }): FormFieldEntry<string> => {
        return {
          id,
          label: shortName,
        };
      }),
    [customers]
  );
  const formWarning = useGetState($.$formWarning);
  return (
    <ModalCardDialog
      title={t('selectPredefinedCustomerDialog.title')}
      $active={$.$active}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <ListSelectFormField
        label={t('selectPredefinedCustomerDialog.form.customer')}
        entries={customerEntries}
        $={$formDataWithChangeTrigger.$customerId}
        sortingFunction={SORT_FUNCTION}
        hasBorder
      />
    </ModalCardDialog>
  );
}
