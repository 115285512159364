import React, { useEffect, useState } from 'react';

const StandardTheme = React.lazy(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (): Promise<any> => await import('./standard/StandardTheme.js')
);

const ChristmasTheme = React.lazy(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (): Promise<any> => await import('./christmas/ChristmasTheme.js')
);

const computeIsChristmasPeriod = (): boolean => {
  // Disable Christmas theme in development to avoid any confusion between
  // production and developement mode
  if (process.env.NODE_ENV !== 'production') {
    return false;
  }
  const now = new Date();
  const day = now.getDate();
  const month = now.getMonth() + 1;
  return day >= 19 && day <= 31 && month === 12;
};

export const ThemeContext = React.createContext<'standard' | 'christmas'>('standard');

interface ThemeSelectorProps {
  readonly children: React.ReactNode;
}

export const ThemeSelector = ({ children }: ThemeSelectorProps): JSX.Element => {
  const [isChristmasPeriod, setIsChristmasPeriod] = useState(computeIsChristmasPeriod());

  // Compute periodically the showChristmasTree boolean
  useEffect(() => {
    const interval = setInterval(() => {
      const newIsChristmasPeriod = computeIsChristmasPeriod();
      if (newIsChristmasPeriod !== isChristmasPeriod) {
        setIsChristmasPeriod(newIsChristmasPeriod);
      }
    }, 1000 * 60);
    // Return cleanup callback
    return (): void => clearInterval(interval);
  }, [isChristmasPeriod]);
  return (
    <>
      <React.Suspense fallback={<>Loading theme</>}>
        {!isChristmasPeriod ? <StandardTheme /> : <ChristmasTheme />}
      </React.Suspense>
      <ThemeContext.Provider value={isChristmasPeriod ? 'christmas' : 'standard'}>
        {children}
      </ThemeContext.Provider>
    </>
  );
};
