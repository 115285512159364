import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type {
  ActionCallbackFromFunction,
  ActionContext,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  IsTrue,
  ModalCardDialog,
  TextAreaFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type {
  CommentsElementHierarchy,
  CommentsFormData,
  EditCommentsModalDialogState,
} from './typings/store.js';
import { EMPTY_EDIT_COMMENTS_MODAL_DIALOG_STATE } from './typings/store.js';

export function openEditCommentsModalAction(
  { actionDispatch }: ActionContext<Store, EditCommentsModalDialogState>,
  {
    kanbanId,
    packageDeal,
    packageDealId,
    sparePart,
    commentForStore,
    commentForWorkshop,
    commentForCustomer,
  }: CommentsFormData & CommentsElementHierarchy
) {
  actionDispatch.setValue({
    ...EMPTY_EDIT_COMMENTS_MODAL_DIALOG_STATE,
    active: true,
    kanbanId,
    packageDealId,
    packageDeal,
    sparePart,
    formData: {
      ...EMPTY_EDIT_COMMENTS_MODAL_DIALOG_STATE.formData,
      commentForStore,
      commentForWorkshop,
      commentForCustomer,
    },
  });
}

export function closeEditCommentsModalAction({
  actionDispatch,
}: ActionContext<Store, EditCommentsModalDialogState>) {
  actionDispatch.setValue(EMPTY_EDIT_COMMENTS_MODAL_DIALOG_STATE);
}

export type CommentsChangedActionCallback = ActionCallbackFromFunction<
  Store,
  () => Promise<void> | void
>;

type CommentModalProps = {
  readonly $: StoreStateSelector<Store, EditCommentsModalDialogState>;
  readonly onCommentsChangedActionCallback: CommentsChangedActionCallback;
};

export function EditCommentsModalDialogComponent({
  $,
  onCommentsChangedActionCallback,
}: CommentModalProps): JSX.Element {
  const [t] = useTranslation('share');

  const formWarning = useGetState($.$formWarning);

  const submitValidDataActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.execCallback(onCommentsChangedActionCallback);
      await actionDispatch.exec(closeEditCommentsModalAction);
    },
    [onCommentsChangedActionCallback],
    $
  );

  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation({
    $,
    mandatoryFields: [],
    checkFieldContentActions: {},
    checkFormConsistencyAction: () => undefined,
    submitValidDataAction: submitValidDataActionCallback,
    t,
  });

  return (
    <IsTrue $={$.$active}>
      <ModalCardDialog
        $active={$.$active}
        title={t('comments.editionModal.title')}
        onOkClicked={onFormSubmit}
        warning={formWarning}
      >
        <TextAreaFormField
          label={t('comments.editionModal.store')}
          $={$formWithChangeTrigger.$commentForStore}
          rows={3}
        />
        <TextAreaFormField
          label={t('comments.editionModal.workshop')}
          $={$formWithChangeTrigger.$commentForWorkshop}
          rows={3}
        />
        <TextAreaFormField
          label={t('comments.editionModal.customer')}
          $={$formWithChangeTrigger.$commentForCustomer}
          rows={3}
        />
      </ModalCardDialog>
    </IsTrue>
  );
}
