import type { User } from '@stimcar/libs-base';
import type {
  ActionCallbackFromFunction,
  BusinessObjectToFormData,
  StateWarnings,
  WithFormValidationWarnings,
} from '@stimcar/libs-uikernel';
import type { FormWithValidationState, TableState } from '@stimcar/libs-uitoolkit';
import { EMPTY_USER } from '@stimcar/libs-base';
import { TABLE_EMPTY_STATE } from '@stimcar/libs-uitoolkit';
import type { UpdatePasswordDialogState } from '../../../../registeredapp/components/authentication/typings/store.js';
import type { Store } from '../../../state/typings/store.js';
import { EMPTY_EDIT_PASSWORD_DIALOG_STATE } from '../../../../registeredapp/components/authentication/typings/store.js';

// Substitue login by ID (in order to be able to put it in the state : in the state, objects that are in arrays must have an id field by convention)
export type AdminUIUser = Omit<User, 'login'> & {
  readonly id: string;
};

export type OnUserChangeActionCallback = ActionCallbackFromFunction<
  Store,
  (addedOrUpdated: readonly AdminUIUser[], removedIds: readonly string[]) => void
>;

export type CreateUserData = BusinessObjectToFormData<{
  readonly createdUserLogin: string;
  readonly createdUserFirstName: string;
  readonly createdUserLastName: string;
  readonly createdPassword: string;
  readonly createdPinCode: string;
  readonly selectedProfiles: readonly string[];
  readonly active: boolean;
  readonly createdIsSubcontractor: boolean;
  readonly firm: string;
}>;

export const EMPTY_CREATE_USER_DATA: CreateUserData = {
  createdUserLogin: '',
  createdUserFirstName: '',
  createdUserLastName: '',
  createdPassword: '',
  createdPinCode: '',
  selectedProfiles: [],
  active: true,
  createdIsSubcontractor: true,
  firm: '',
};

export type CreateUserDialogState = FormWithValidationState<CreateUserData> & {
  readonly active: boolean;
  readonly subcontractors: readonly string[];
};

export const EMPTY_CREATE_USER_DIALOG_STATE: CreateUserDialogState = {
  active: false,
  subcontractors: [],
  formSubmitted: false,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  formData: {
    ...EMPTY_CREATE_USER_DATA,
    warnings: {},
  },
};

export type EditUserInfosData = BusinessObjectToFormData<{
  readonly login: string;
  readonly editedFirstName: string;
  readonly editedLastName: string;
  readonly editedActive: boolean;
  readonly editedIsSubcontractor: boolean;
  readonly editedFirm: string;
  readonly selectedProfiles: readonly string[];
}>;

export const EMPTY_EDIT_USER_INFOS_DATA: WithFormValidationWarnings<EditUserInfosData> = {
  login: '',
  editedFirstName: '',
  editedLastName: '',
  editedActive: true,
  selectedProfiles: [],
  editedIsSubcontractor: true,
  editedFirm: '',
  warnings: {},
};

export type EditUserInfosDialogState = FormWithValidationState<EditUserInfosData> & {
  readonly active: boolean;
  readonly initialUser: AdminUIUser;
  readonly subcontractors: readonly string[];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { login, ...EMPTY_ADMIN_UI_USER } = EMPTY_USER;
export const EMPTY_EDIT_USER_INFOS_DIALOG_STATE: EditUserInfosDialogState = {
  active: false,
  initialUser: {
    ...EMPTY_ADMIN_UI_USER,
    id: '',
  },
  subcontractors: [],
  formSubmitted: false,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  formData: {
    ...EMPTY_EDIT_USER_INFOS_DATA,
    warnings: {},
  },
};

export type AdminUsersState = TableState<AdminUIUser> & {
  readonly availableProfiles: readonly string[];
  readonly createUserDialogState: CreateUserDialogState;
  readonly updatePasswordDialogState: UpdatePasswordDialogState;
  readonly editUserInfosDialogState: EditUserInfosDialogState;
  readonly warnings: StateWarnings<AdminUsersState>;
};

export const EMPTY_USERS_ADMIN_STATE: AdminUsersState = {
  ...TABLE_EMPTY_STATE,
  availableProfiles: [],
  createUserDialogState: EMPTY_CREATE_USER_DIALOG_STATE,
  updatePasswordDialogState: EMPTY_EDIT_PASSWORD_DIALOG_STATE,
  editUserInfosDialogState: EMPTY_EDIT_USER_INFOS_DIALOG_STATE,
  warnings: {},
};
