import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
  HorizontalFormFieldProps,
} from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, shortDayMonthFullYearDateFormatOptions } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { CalendarFormField, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { downloadAndSaveBlob } from '../../../lib/utils/download.js';
import type {
  AdminExportMarketplaceInvoicingDialogState,
  AdminExportMarketplaceInvoicingFormState,
} from './typings/store.js';
import { EMPTY_ADMIN_EXPORT_MARKETPLACE_INVOICING_DIALOG_STATE } from './typings/store.js';

export function openExportMarketplaceInvoicesDialogAction({
  actionDispatch,
}: ActionContext<Store, AdminExportMarketplaceInvoicingDialogState>) {
  actionDispatch.setValue({
    ...EMPTY_ADMIN_EXPORT_MARKETPLACE_INVOICING_DIALOG_STATE,
    exportType: 'invoices',
    active: true,
  });
  actionDispatch.applyPayload({
    formData: {
      endDate: Date.now(),
    },
  });
}

export function openExportMarketplaceRevenueDialogAction({
  actionDispatch,
}: ActionContext<Store, AdminExportMarketplaceInvoicingDialogState>) {
  actionDispatch.setValue({
    ...EMPTY_ADMIN_EXPORT_MARKETPLACE_INVOICING_DIALOG_STATE,
    exportType: 'revenue',
    active: true,
  });
  actionDispatch.applyPayload({
    formData: {
      endDate: Date.now(),
    },
  });
}

function getExportFileName(startDate: number, endDate: number): string {
  const startYYYYMMDD = new Date(startDate).toLocaleDateString(
    'fr-FR',
    shortDayMonthFullYearDateFormatOptions
  );
  const endYYYYMMDD = new Date(endDate).toLocaleDateString(
    'fr-FR',
    shortDayMonthFullYearDateFormatOptions
  );
  return `export-marketplace-${startYYYYMMDD.replaceAll('/', '')}-${endYYYYMMDD.replaceAll('/', '')}.xlsx`;
}

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  labelFlexGrow: 2,
  bodyFlexGrow: 3,
};

const mandatoryFields: (keyof AdminExportMarketplaceInvoicingFormState)[] = [
  'startDate',
  'endDate',
];

const checkFieldContentActions: CheckFormFieldContentActions<
  Store,
  AdminExportMarketplaceInvoicingDialogState
> = {
  startDate: ({ t, formState }): string | undefined => {
    const { startDate } = formState.formData;
    if (Number.isNaN(startDate)) {
      return t('form.requiredDate');
    }
    return undefined;
  },
  endDate: ({ t, formState }): string | undefined => {
    const { endDate } = formState.formData;
    if (Number.isNaN(endDate)) {
      return t('form.requiredDate');
    }
    return undefined;
  },
};

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  AdminExportMarketplaceInvoicingDialogState,
  []
> = ({ formState, t }): string | undefined => {
  const { startDate, endDate } = formState.formData;
  if (
    !Number.isNaN(startDate) &&
    isTruthy(startDate) &&
    !Number.isNaN(endDate) &&
    isTruthy(endDate) &&
    endDate < startDate
  ) {
    return t('form.startDateMustBeBeforeEndDate');
  }
  return undefined;
};

type ExportMarketplaceInvoicingDialogProps = AppProps<Store>;

export function ExportMarketplaceInvoicingDialog({
  $gs,
}: ExportMarketplaceInvoicingDialogProps): JSX.Element {
  const [t] = useTranslation('exportMarketplaceInvoicing');
  const { $adminExportMarketplaceInvoicing } = $gs.$adminView;
  const formWarning = useGetState($adminExportMarketplaceInvoicing.$formWarning);
  const exportType = useGetState($adminExportMarketplaceInvoicing.$exportType);
  const launchExport = useGetState($adminExportMarketplaceInvoicing.$launchExport);

  const launchExportActionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('launchExport', true);
    },
    [],
    $adminExportMarketplaceInvoicing
  );

  const exportDataActionCallback = useActionCallback(
    async function submitValidDataAction({ actionDispatch, getState, httpClient }) {
      if (launchExport) {
        const { startDate, endDate } = getState().formData;

        const routeToCall =
          exportType === 'invoices'
            ? CoreBackendRoutes.EXPORT_MARKETPLACE_KANBANS_INVOICES(startDate, endDate)
            : CoreBackendRoutes.EXPORT_MARKETPLACE_KANBANS_REVENUE(startDate, endDate);

        const response = await httpClient.httpGet(routeToCall);

        actionDispatch.setProperty('launchExport', false);

        const fileName = getExportFileName(startDate, endDate);
        await downloadAndSaveBlob(await response.blob(), fileName);

        actionDispatch.setProperty('active', false);
      }
    },
    [exportType, launchExport],
    $adminExportMarketplaceInvoicing
  );

  const asyncEffect = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.execCallback(exportDataActionCallback);
    },
    [exportDataActionCallback],
    $adminExportMarketplaceInvoicing
  );
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    AdminExportMarketplaceInvoicingDialogState
  >({
    $: $adminExportMarketplaceInvoicing,
    mandatoryFields,
    checkFieldContentActions,
    checkFormConsistencyAction,
    submitValidDataAction: launchExportActionCallback,
    t,
  });

  return (
    <ModalCardDialog
      size="max-desktop"
      warning={formWarning}
      title={t('modalTitle')}
      onOkClicked={onFormSubmit}
      $active={$adminExportMarketplaceInvoicing.$active}
    >
      <CalendarFormField
        label={t('form.startDate')}
        horizontal={HORIZONTAL_PROPS}
        noExclamationTriangleIfWarning
        $={$formDataWithChangeTrigger.$startDate}
      />
      <CalendarFormField
        label={t('form.endDate')}
        horizontal={HORIZONTAL_PROPS}
        noExclamationTriangleIfWarning
        $={$formDataWithChangeTrigger.$endDate}
      />
      {launchExport && (
        <p className="has-text-centered">
          <progress className="progress is-small is-primary" />
          {t('exportInProgress')}
        </p>
      )}
    </ModalCardDialog>
  );
}
