import React, { useCallback } from 'react';
import type { FaIconSize } from './FaIcon.js';
import { Button } from './Button.js';

export interface AddOnButton {
  readonly id: string;
  readonly iconId?: string;
  readonly label?: string;
  readonly tooltip?: string;
  readonly additionalClass?: string;
  readonly disabled?: boolean;
}

interface AddOnButtonProps extends AddOnButton {
  readonly onClick: (buttonId: string) => Promise<void> | void;
  readonly isRounded?: boolean;
  readonly size?: FaIconSize;
  readonly disabled?: boolean;
  readonly isSmall?: boolean;
}

function AddOnButton({
  id,
  onClick,
  isRounded = false,
  additionalClass,
  disabled,
  iconId,
  label,
  tooltip,
  size,
  isSmall,
}: AddOnButtonProps): JSX.Element {
  const onClickCallback = useCallback(async (): Promise<void> => {
    await onClick(id);
  }, [id, onClick]);
  return (
    <Button
      onClick={onClickCallback}
      additionalClass={`${additionalClass}${isRounded ? ' is-rounded' : ''}${
        isSmall ? ' is-small' : ''
      }`}
      disabled={disabled}
      iconId={iconId}
      label={!label && !iconId ? id : label}
      tooltip={tooltip}
      size={size}
    />
  );
}

export interface AddOnButtonsProps {
  readonly buttons: readonly AddOnButton[];
  readonly onClick: (buttonId: string) => Promise<void> | void;
  readonly size?: FaIconSize;
  readonly disabled?: boolean;
  readonly isRounded?: boolean;
  readonly isSmall?: boolean;
}

export function AddOnButtons({
  onClick,
  buttons,
  size = 'standard',
  disabled = false,
  isRounded = false,
  isSmall = false,
}: AddOnButtonsProps): JSX.Element {
  return (
    <div className="buttons has-addons">
      {buttons.map(
        (button, i): JSX.Element => (
          <AddOnButton
            key={button.id}
            onClick={onClick}
            size={size}
            disabled={disabled || button.disabled}
            isRounded={(isRounded && i === 0) || (isRounded && i === buttons.length - 1)}
            isSmall={isSmall}
            {...button}
          />
        )
      )}
    </div>
  );
}
