/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { RepositoryEntity } from '@stimcar/libs-base';
import type {
  ActionContext,
  ActionDispatch,
  AnyStoreDef,
  ArrayItemStateType,
  NoArgActionCallback,
  ReadOnlyActionContext,
  State,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { isARepositoryEntity } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import { downloadAndSaveBlob } from '../../../../utils/index.js';
import { RepositoryEntityDirtyIcon } from '../../../custom/misc/RepositoryEntityDirtyIcon.js';
import { ToggleNestedButton } from '../../../custom/misc/ToggleNestedButton.js';
import { Input } from '../../form/Input.js';
import { Button } from '../Button.js';
import { FaIcon, FarCheckCircle } from '../FaIcon.js';
import { TruncableTableTd } from '../TruncableTableElements.js';
import type {
  ActionColumnDesc,
  AnyTableStoreDef,
  Column,
  ColumnDesc,
  CommonDisplayColumnDesc,
  DisplayColumnDesc,
  SavedFilter,
  Sort,
  SortsMenuState,
  TableCellIcon,
  TableSerializablePreferences,
  TableState,
} from './typings/store.js';
import { Decorators } from './Decorators.js';
import {
  createLoadAndFilterTableItemsAction,
  getDisplayTypeColumnDescs,
  sortEntities,
} from './filterAndSortUtils.js';
import { FiltersMenu } from './FiltersMenu.js';
import { ScrollableTableComponent } from './ScrollableTableComponent.js';
import { SelectColumnsMenu } from './SelectColumnsMenu.js';
import { SortsMenu } from './SortsMenu.js';

function useGetSortedItems<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
>($: StoreStateSelector<SD, SC>, columnDescs: readonly ColumnDesc<SD, SC, O, SO>[]): readonly O[] {
  const displayTypeColumnDescs = getDisplayTypeColumnDescs(columnDescs);
  const items = useGetState($.$items);
  const sorts = useGetState($.$sortsMenuState.$sorts);
  return useMemo(() => {
    return sortEntities(items, sorts, displayTypeColumnDescs);
  }, [displayTypeColumnDescs, items, sorts]);
}

function loadPrefsAction<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
>(
  { keyValueStorage, actionDispatch }: ActionContext<SD, SC>,
  columnDescs: readonly ColumnDesc<SD, SC, O>[],
  defaultFilters: SavedFilter[] = [],
  localStorageKey?: string
): void {
  let storedPrefs: TableSerializablePreferences | null = null;
  let userColumns: Column[] = [];
  let userSavedFilters: SavedFilter[] = [];
  if (isTruthyAndNotEmpty(localStorageKey) && isTruthy(keyValueStorage)) {
    storedPrefs = keyValueStorage.getObjectItem<TableSerializablePreferences>(localStorageKey);
    if (isTruthy(storedPrefs)) {
      const { columns, filters } = storedPrefs;
      if (columns && columns instanceof Array) {
        const columnDescPerId = new Map<string, ColumnDesc<SD, SC, O>>();
        columnDescs.forEach((cd) => columnDescPerId.set(cd.id, cd));

        userColumns = [...columns]
          .map((c): Column | undefined => {
            const columnDesc = columnDescs.find((cd) => cd.id === c.id);
            if (isTruthy(columnDesc)) {
              columnDescPerId.delete(columnDesc.id);
              return {
                id: c.id,
                label: columnDesc.columnLabel,
                type: columnDesc.columnType,
                isDisplayed: true,
              };
            }
            return undefined;
          })
          .filter((c) => c !== undefined);

        columnDescPerId.forEach((cd) =>
          userColumns.push({
            id: cd.id,
            label: cd.columnLabel,
            type: cd.columnType,
            isDisplayed: cd.isNotHideable || false,
          })
        );
      }
      if (filters && filters instanceof Array) {
        userSavedFilters = [...filters];
      }
    }
  }

  if (!isTruthy(storedPrefs) || !isTruthy(storedPrefs.columns)) {
    columnDescs.forEach((cd) => {
      userColumns.push({
        id: cd.id,
        label: cd.columnLabel,
        type: cd.columnType,
        isDisplayed: cd.isNotHideable || cd.isDisplayedByDefault || false,
      });
    });
  }

  defaultFilters.forEach((df) => {
    if (userSavedFilters.find((f) => f.id === df.id) === undefined) {
      userSavedFilters.push(df);
    }
  });

  actionDispatch.reduce((initial: SC): SC => {
    return {
      ...initial,
      columnSelectionState: {
        ...initial.columnSelectionState,
        columns: userColumns,
      },
      filtersState: {
        ...initial.filtersState,
        savedFilters: userSavedFilters,
      },
    };
  });
}

function showColumn(
  selectedItemId: string | undefined,
  columnsToKeepWhenDetailsIsOpen: number | undefined,
  displayedColumnDescsCount: number,
  columnIndex: number
): boolean {
  const columnToKeep = columnsToKeepWhenDetailsIsOpen || displayedColumnDescsCount;
  if (isTruthyAndNotEmpty(selectedItemId) && columnIndex >= columnToKeep) {
    return false;
  }
  return true;
}

interface THeaderComponentProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
> {
  readonly displayedAndSortedColumnDescs: ColumnDesc<SD, SC, O, SO>[];
  readonly isTruncable?: boolean;
  readonly $sortsMenu: StoreStateSelector<SD, SortsMenuState>;
  readonly showRepositoryStatus: boolean;
  readonly areSortDeactivated: boolean;
}

function THeaderComponent<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
>({
  displayedAndSortedColumnDescs,
  isTruncable,
  $sortsMenu,
  showRepositoryStatus,
  areSortDeactivated,
}: THeaderComponentProps<SD, SC, O, SO>): JSX.Element {
  const [t] = useTranslation('bulma');
  return (
    <thead>
      <tr>
        {showRepositoryStatus && (
          <th style={{ width: '2.25em' }}>
            <FarCheckCircle tooltip={t('synchronizationStatus')} />
          </th>
        )}
        {displayedAndSortedColumnDescs.map((c): JSX.Element => {
          if (areSortDeactivated || c.columnType === 'action' || c.isNotSortable) {
            return (
              <th key={c.id} style={c.columnStyle}>
                <div
                  style={
                    isTruncable
                      ? {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }
                      : {}
                  }
                  className="has-text-centered"
                >
                  {isTruthy(c.columnIcon) ? (
                    <FaIcon
                      id={c.columnIcon.iconId}
                      tooltip={
                        isTruthyAndNotEmpty(c.columnTooltip) ? c.columnTooltip : c.columnLabel
                      }
                      label={c.columnIcon.showText ? c.columnLabel : undefined}
                    />
                  ) : (
                    <span
                      title={isTruthyAndNotEmpty(c.columnTooltip) ? c.columnTooltip : c.columnLabel}
                    >
                      {c.columnLabel}
                    </span>
                  )}
                </div>
              </th>
            );
          }
          return (
            <TableMultipleSortableHeaderComponent
              key={c.id}
              tooltip={c.columnTooltip}
              content={c.columnLabel}
              isTruncable={isTruncable}
              centerLabel={false}
              columnId={c.id}
              style={c.columnStyle}
              $={$sortsMenu}
              columnIcon={c.columnIcon}
            />
          );
        })}
      </tr>
    </thead>
  );
}

export interface TableToolbarConfiguration {
  readonly filters?: {
    readonly show: boolean;
    readonly defaultFilters?: SavedFilter[];
  };
  readonly showColumnSelection?: boolean;
  readonly itemCount?: {
    readonly show: boolean;
    readonly getCustomLabel?: (count: number) => string;
  };
  readonly showSorts?: boolean;
  readonly textualSearch?: {
    readonly show: boolean;
    readonly customPlaceholder?: string;
  };
  readonly csvDownloadBaseFileName?: string;
  readonly localStorageKey?: string;
}

interface TableProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType = O,
> {
  readonly $: StoreStateSelector<SD, SC>;
  readonly preInitActionCallback?: NoArgActionCallback<SD>;
  readonly columnDescs: readonly ColumnDesc<SD, SC, O, SO>[];
  readonly contentProvider: (
    ctx: ReadOnlyActionContext<SD, SC>
  ) => Promise<readonly O[]> | readonly O[];
  readonly isOnline?: boolean;
  readonly showRepositoryStatus?: boolean;
  readonly isScrollable?: boolean;
  readonly width?: string;
  readonly isTruncable?: boolean;
  readonly toolbar?: TableToolbarConfiguration;
  readonly tableClassName?: string;
  readonly isTableInBox?: boolean;
  readonly noContentPlaceholder?: string;
}

export function Table<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
>({
  $,
  isOnline,
  contentProvider,
  preInitActionCallback,
  columnDescs,
  isTruncable,
  toolbar,
  showRepositoryStatus,
  noContentPlaceholder,
  tableClassName,
  width,
  isScrollable,
  isTableInBox,
}: TableProps<SD, SC, O>): JSX.Element {
  return (
    <InternalTable
      $={$}
      preInitActionCallback={preInitActionCallback}
      contentProvider={contentProvider}
      isOnline={isOnline}
      columnDescs={columnDescs}
      showRepositoryStatus={showRepositoryStatus}
      noContentPlaceholder={noContentPlaceholder}
      isTruncable={isTruncable}
      toolbar={toolbar}
      tableClassName={tableClassName}
      isTableInBox={isTableInBox}
      width={width}
      isScrollable={isScrollable}
    />
  );
}

interface TableWithSublinesSpecificProps<
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
> {
  readonly getSubLines: (item: O) => readonly SO[];
}

type TableWithSublinesProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
> = TableProps<SD, SC, O, SO> & TableWithSublinesSpecificProps<O, SO>;

export function TableWithSublines<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
>({
  $,
  isOnline,
  contentProvider,
  preInitActionCallback,
  columnDescs,
  getSubLines,
  isTruncable,
  toolbar,
  showRepositoryStatus,
  noContentPlaceholder,
  tableClassName,
  width,
  isScrollable,
  isTableInBox,
}: TableWithSublinesProps<SD, SC, O, SO>): JSX.Element {
  return (
    <InternalTable
      $={$}
      preInitActionCallback={preInitActionCallback}
      contentProvider={contentProvider}
      isOnline={isOnline}
      columnDescs={columnDescs}
      showRepositoryStatus={showRepositoryStatus}
      noContentPlaceholder={noContentPlaceholder}
      isTruncable={isTruncable}
      toolbar={toolbar}
      tableClassName={tableClassName}
      isTableInBox={isTableInBox}
      width={width}
      isScrollable={isScrollable}
      getSubLines={getSubLines}
    />
  );
}

type BulmaColumnProportions = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface MasterDetailsSpecificProps {
  readonly detailsDrawerProportion: BulmaColumnProportions;
  readonly columnsToKeepWhenDetailsIsOpen: number;
  readonly children: JSX.Element;
}

type MasterDetailsTableProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
> = Omit<TableProps<SD, SC, O>, 'selectedItemIdDispatch'> & MasterDetailsSpecificProps;

export function MasterDetailsTable<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
>({
  $,
  showRepositoryStatus,
  children,
  columnsToKeepWhenDetailsIsOpen,
  detailsDrawerProportion,
  noContentPlaceholder,
  isTableInBox,
  isOnline,
  contentProvider,
  preInitActionCallback,
  columnDescs,
  isTruncable,
  toolbar,
  tableClassName,
  width,
  isScrollable,
}: MasterDetailsTableProps<SD, SC, O>): JSX.Element {
  return (
    <InternalTable
      $={$}
      columnsToKeepWhenDetailsIsOpen={columnsToKeepWhenDetailsIsOpen}
      isOnline={isOnline}
      contentProvider={contentProvider}
      noContentPlaceholder={noContentPlaceholder}
      preInitActionCallback={preInitActionCallback}
      columnDescs={columnDescs}
      isTruncable={isTruncable}
      showRepositoryStatus={showRepositoryStatus}
      toolbar={toolbar}
      tableClassName={tableClassName}
      detailsDrawerProportion={detailsDrawerProportion}
      isTableInBox={isTableInBox}
      width={width}
      isScrollable={isScrollable}
    >
      {children}
    </InternalTable>
  );
}

type InternalTableProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
> = Omit<TableProps<SD, SC, O, SO>, 'selectedItemIdDispatch'> &
  Partial<MasterDetailsSpecificProps> &
  Partial<TableWithSublinesSpecificProps<O, SO>>;

function InternalTable<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
>({
  $,
  children,
  contentProvider,
  preInitActionCallback,
  getSubLines,
  columnsToKeepWhenDetailsIsOpen,
  noContentPlaceholder,
  isOnline = false,
  showRepositoryStatus = false,
  isTableInBox = false,
  detailsDrawerProportion = 0,
  columnDescs,
  isTruncable = false,
  toolbar,
  tableClassName,
  width = '100%',
  isScrollable = false,
}: InternalTableProps<SD, SC, O, SO>): JSX.Element {
  const selectedItemId = useGetState($.$selectedItemId);

  const asyncEffect = useActionCallback(
    async function initializeTableState({ actionDispatch }): Promise<void> {
      if (preInitActionCallback) {
        await actionDispatch.execCallback(preInitActionCallback);
      }
      await actionDispatch.exec(
        loadPrefsAction,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        columnDescs as any,
        toolbar?.filters?.defaultFilters,
        toolbar?.localStorageKey
      );
      const loadAndFilterAction = createLoadAndFilterTableItemsAction<SD, SC, O, SO>(
        contentProvider,
        columnDescs
      );
      // TS is not capable to see that SC and TableState<O> are the same things here
      await actionDispatch.exec(loadAndFilterAction);
    },
    [
      columnDescs,
      contentProvider,
      preInitActionCallback,
      toolbar?.filters?.defaultFilters,
      toolbar?.localStorageKey,
    ],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const { $sortsMenuState } = $;

  const columns = useGetState($.$columnSelectionState.$columns);

  const displayedAndSortedColumnDescs = useMemo((): ColumnDesc<SD, SC, O, SO>[] => {
    const computedValues: ColumnDesc<SD, SC, O, SO>[] = [];
    const actionColumnDescs: ActionColumnDesc<SD, SC, O>[] = [];
    columns.forEach((c) => {
      if (c.isDisplayed) {
        const found = columnDescs.find((cd) => cd.id === c.id);
        if (isTruthy(found)) {
          if (found.columnType === 'action') {
            actionColumnDescs.push(found);
          } else {
            computedValues.push(found);
          }
        }
      }
    });
    // Action columns are always at the end
    computedValues.push(...actionColumnDescs);
    const columnsToDisplayCount = computedValues.length;
    // Remove supernumerary columns in master/details case when a line is selected
    return computedValues.filter((_, i) =>
      showColumn(selectedItemId, columnsToKeepWhenDetailsIsOpen, columnsToDisplayCount, i)
    );
  }, [columnDescs, columns, columnsToKeepWhenDetailsIsOpen, selectedItemId]);

  const masterProportionClass = `is-${12 - detailsDrawerProportion}`;
  const detailsProportionClass = `is-${detailsDrawerProportion}`;

  const items = useGetState($.$items);

  const displayRepositoryColumn = useMemo(() => {
    const item = items[0];
    if (isTruthy(item)) {
      return showRepositoryStatus && isARepositoryEntity(item);
    }
    return showRepositoryStatus;
  }, [showRepositoryStatus, items]);

  const showToolbar =
    toolbar?.filters?.show ||
    toolbar?.showSorts ||
    toolbar?.showColumnSelection ||
    isTruthyAndNotEmpty(toolbar?.csvDownloadBaseFileName) ||
    toolbar?.textualSearch?.show ||
    toolbar?.itemCount?.show;

  return (
    <>
      {showToolbar && (
        <TableToolbar
          $={$}
          toolbar={nonnull(toolbar)}
          columnDescs={columnDescs}
          contentProvider={contentProvider}
        />
      )}
      <div className="columns">
        <div
          className={`column ${
            isTruthyAndNotEmpty(selectedItemId) ? masterProportionClass : 'is-full'
          }`}
        >
          <div className={isTableInBox ? 'box' : ''}>
            <ScrollableOrNotTable
              width={width}
              isScrollable={isScrollable}
              isTruncable={isTruncable}
              tableClassName={tableClassName}
            >
              <THeaderComponent
                isTruncable={isTruncable}
                showRepositoryStatus={displayRepositoryColumn}
                displayedAndSortedColumnDescs={displayedAndSortedColumnDescs}
                $sortsMenu={$sortsMenuState}
                areSortDeactivated={!toolbar?.showSorts}
              />
              <TBodyComponent
                isTruncable={isTruncable}
                showRepositoryStatus={displayRepositoryColumn}
                getSubLines={getSubLines}
                $={$}
                isOnline={isOnline}
                displayedAndSortedColumnDescs={displayedAndSortedColumnDescs}
                noContentPlaceholder={noContentPlaceholder}
              />
            </ScrollableOrNotTable>
          </div>
        </div>
        {isTruthyAndNotEmpty(selectedItemId) && (
          <div className={`column ${detailsProportionClass}`}>{children}</div>
        )}
      </div>
    </>
  );
}

interface ScrollableOrNotTableProps {
  readonly isScrollable: boolean;
  readonly width?: string;
  readonly isTruncable: boolean;
  readonly tableClassName?: string;
  readonly children: JSX.Element[];
}

function ScrollableOrNotTable({
  isScrollable,
  isTruncable,
  tableClassName,
  width,
  children,
}: ScrollableOrNotTableProps) {
  if (isScrollable) {
    return (
      <ScrollableTableComponent
        width={width}
        isTruncable={isTruncable}
        tableClassName={tableClassName}
        customHeader={[THeaderComponent]}
      >
        {children}
      </ScrollableTableComponent>
    );
  }

  return (
    <table
      className={tableClassName || 'table'}
      style={isTruncable ? { width, tableLayout: 'fixed', whiteSpace: 'nowrap' } : {}}
    >
      {children}
    </table>
  );
}

interface TableToolbarProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
> {
  readonly $: StoreStateSelector<SD, SC>;
  readonly toolbar: TableToolbarConfiguration;
  readonly columnDescs: readonly ColumnDesc<SD, SC, O, SO>[];
  readonly contentProvider: (
    ctx: ReadOnlyActionContext<SD, SC>
  ) => Promise<readonly O[]> | readonly O[];
}

export function TableToolbar<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType = O,
>({ $, columnDescs, toolbar, contentProvider }: TableToolbarProps<SD, SC, O, SO>): JSX.Element {
  const [t] = useTranslation('bulma');
  const { $columnSelectionState, $sortsMenuState } = $;

  const loadAndFilterItemsActionCallback: NoArgActionCallback<SD> = useActionCallback(
    async ({ actionDispatch }) => {
      const loadAndFilterAction = createLoadAndFilterTableItemsAction(contentProvider, columnDescs);
      // TS is not capable to see that SD and TableState<O> are the same things here
      await actionDispatch.exec(loadAndFilterAction);
    },
    [columnDescs, contentProvider],
    $
  );

  const $textualFilterWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$textualFilter,
    loadAndFilterItemsActionCallback
  );

  const displayTypeColumnDescs = useMemo(() => {
    return getDisplayTypeColumnDescs(columnDescs);
  }, [columnDescs]);

  const sortedItems = useGetSortedItems($, columnDescs);

  const downloadAsCsv = useActionCallback(
    async ({ getState }): Promise<void> => {
      const { columnSelectionState } = getState();
      const sortedColumns: DisplayColumnDesc<O>[] = [];
      columnSelectionState.columns
        .filter((c) => c.isDisplayed && c.type === 'display' && c.label)
        .forEach((c) => {
          const found = columnDescs.find((cd) => cd.id === c.id);
          if (isTruthy(found) && !found.columnIcon) {
            sortedColumns.push(found as DisplayColumnDesc<O>);
          }
        });

      const csv = new Blob(
        [
          [
            sortedColumns.map((c) => c.columnLabel).join(','),
            ...sortedItems.map((k) =>
              sortedColumns
                .map((c) => `"${String(c.getPropertyValue(k)).replace(/"/g, '""')}"`)
                .join(',')
            ),
          ].join('\n'),
        ],
        {
          type: 'text/csv',
        }
      );
      await downloadAndSaveBlob(csv, `${toolbar?.csvDownloadBaseFileName}.csv`);
    },
    [columnDescs, sortedItems, toolbar?.csvDownloadBaseFileName],
    $
  );

  return (
    <div className="columns is-mobile">
      {toolbar?.showColumnSelection && (
        <div className="column is-narrow">
          <SelectColumnsMenu
            $={$columnSelectionState}
            columnDescs={columnDescs}
            localStorageKey={toolbar?.localStorageKey}
          />
        </div>
      )}
      {toolbar?.showSorts && (
        <div className="column is-narrow">
          <SortsMenu $={$sortsMenuState} columnDescs={displayTypeColumnDescs} />
        </div>
      )}
      {toolbar?.filters?.show && (
        <div className="column is-narrow">
          <FiltersMenu
            $={$.$filtersState}
            loadAndFilterItemsActionCallback={loadAndFilterItemsActionCallback}
            columnDescs={displayTypeColumnDescs}
            localStorageKey={toolbar?.localStorageKey}
          />
        </div>
      )}
      {isTruthyAndNotEmpty(toolbar?.csvDownloadBaseFileName) && (
        <div className="column is-narrow">
          <Button onClick={downloadAsCsv} iconId="file-excel" />
        </div>
      )}
      {toolbar.textualSearch?.show && (
        <div className="column is-narrow">
          <Input
            placeholder={
              isTruthyAndNotEmpty(toolbar.textualSearch.customPlaceholder)
                ? toolbar.textualSearch.customPlaceholder
                : t('textualSearchDefaultPlaceholder')
            }
            $={$textualFilterWithChangeTrigger}
            type="search"
          />
        </div>
      )}
      {toolbar.itemCount?.show && (
        <div className="column is-narrow m-t-sm">
          {isTruthy(toolbar.itemCount.getCustomLabel)
            ? toolbar.itemCount.getCustomLabel(sortedItems.length)
            : t('displayedItemsCount', { count: sortedItems.length })}
        </div>
      )}
    </div>
  );
}

async function onMoveSelectionUpOrDownHandlerAction<
  SD extends AnyStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
>(
  { actionDispatch, getState }: ActionContext<SD, SC>,
  event: React.KeyboardEvent<HTMLDivElement>,
  sortedItems: readonly O[]
): Promise<void> {
  const { selectedItemId } = getState();
  await actionDispatch.exec(
    onGenericMoveSelectionUpOrDownHandlerAction<SD, SC, O>,
    selectedItemId!,
    ({ actionDispatch }: ActionContext<SD, SC>, selectedItemId: string) =>
      actionDispatch.setProperty('selectedItemId', selectedItemId),
    event,
    sortedItems
  );
}

// Exported because it is used in component that don't fully use the Table framework (ex : Expertise)
export async function onGenericMoveSelectionUpOrDownHandlerAction<
  SD extends AnyStoreDef,
  S extends State,
  O extends ArrayItemStateType,
>(
  { actionDispatch }: ActionContext<SD, S>,
  selectedItemId: string,
  setSelectedItemIdAction: (ctx: ActionContext<SD, S>, s: string) => void | Promise<void>,
  event: React.KeyboardEvent<HTMLDivElement>,
  sortedItems: readonly O[]
): Promise<void> {
  event.preventDefault();
  event.stopPropagation();
  if (isTruthyAndNotEmpty(selectedItemId)) {
    const idx = sortedItems.findIndex((i) => i.id === selectedItemId);
    let newIdx = idx;
    switch (event.key) {
      case 'ArrowUp':
        newIdx -= 1;
        break;
      case 'ArrowDown':
        newIdx += 1;
        break;
      default:
    }
    if (newIdx < 0) {
      newIdx = 0;
    } else if (newIdx >= sortedItems.length) {
      newIdx = sortedItems.length - 1;
    }
    const newSelectedItem = sortedItems[newIdx];
    const key = newSelectedItem.id;
    await actionDispatch.exec(setSelectedItemIdAction, key);
  }
}

interface Props<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
> {
  readonly $: StoreStateSelector<SD, SC>;
  readonly isTruncable: boolean;
  readonly displayedAndSortedColumnDescs: ColumnDesc<SD, SC, O, SO>[];
  readonly getSubLines?: (item: O) => readonly SO[];
  readonly showRepositoryStatus: boolean;
  readonly isOnline: boolean;
  readonly noContentPlaceholder?: string;
}

function TBodyComponent<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
>({
  $,
  isTruncable,
  displayedAndSortedColumnDescs,
  getSubLines,
  showRepositoryStatus,
  isOnline,
  noContentPlaceholder,
}: Props<SD, SC, O, SO>): JSX.Element {
  const [t] = useTranslation('bulma');

  const sortedItems = useGetSortedItems($, displayedAndSortedColumnDescs);

  const moveSelectionUpOrDownCallback = useActionCallback(
    async ({ actionDispatch }, event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
      await actionDispatch.exec(onMoveSelectionUpOrDownHandlerAction, event, sortedItems);
    },
    [sortedItems],
    $
  );

  return (
    <>
      {sortedItems.length > 0 ? (
        <>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */}
          <tbody onKeyDown={moveSelectionUpOrDownCallback} tabIndex={0} role="menubar">
            {sortedItems.map((i): JSX.Element => {
              return (
                <TableItem
                  key={i.id}
                  item={i}
                  isOnline={isOnline}
                  getSubLines={getSubLines}
                  isTruncable={isTruncable}
                  showRepositoryStatus={showRepositoryStatus}
                  displayedAndSortedColumnDescs={displayedAndSortedColumnDescs}
                  $={$}
                />
              );
            })}
          </tbody>
        </>
      ) : (
        <>
          <tbody>
            <tr>
              <td colSpan={displayedAndSortedColumnDescs.length} className="has-text-centered">
                {!isTruthy(noContentPlaceholder)
                  ? noContentPlaceholder
                  : t('table.messageToDisplayInsteadOfEmptyBody')}
              </td>
            </tr>
          </tbody>
        </>
      )}
    </>
  );
}

function applyChangeFilterDirection(
  { actionDispatch }: ActionContext<AnyStoreDef, SortsMenuState>,
  columnId: string
): void {
  actionDispatch.reduce((initial: SortsMenuState) => {
    const sort = initial.sorts.find((s) => s.id === columnId);
    let newSorts: Sort[] = [];
    if (isTruthy(sort)) {
      newSorts = initial.sorts.map((s): Sort => {
        if (s.id === columnId) {
          return {
            ...s,
            direction: s.direction === 'UP' ? 'DOWN' : 'UP',
          };
        }
        return s;
      });
    } else {
      newSorts = [
        ...initial.sorts,
        {
          id: columnId,
          direction: 'UP',
        },
      ];
    }
    return {
      ...initial,
      sorts: newSorts,
    };
  });
}

export interface TableMultipleSortableHeaderComponentProps<SD extends AnyStoreDef> {
  readonly content: string;
  readonly columnIcon?: TableCellIcon;
  readonly tooltip?: string;
  readonly centerLabel?: boolean;
  readonly columnId: string;
  readonly $: StoreStateSelector<SD, SortsMenuState>;
  readonly style?: React.CSSProperties;
  readonly className?: string;
  readonly isTruncable?: boolean;
}

export function TableMultipleSortableHeaderComponent<SD extends AnyStoreDef = AnyStoreDef>({
  content,
  tooltip,
  centerLabel = true,
  columnId,
  $,
  style,
  className,
  columnIcon,
  isTruncable = false,
}: TableMultipleSortableHeaderComponentProps<SD>): JSX.Element {
  const sorts = useGetState($.$sorts);
  const [sortBy, sortDirection] = useMemo(() => {
    const sort = sorts.find((s) => s.id === columnId);
    return [sort?.id, sort?.direction];
  }, [sorts, columnId]);

  const computeSortIcons = (): JSX.Element => {
    if (columnId === sortBy) {
      if (sortDirection === 'UP') {
        return <i className="fa fa-sort-up" />;
      }
      if (sortDirection === 'DOWN') {
        return <i className="fa fa-sort-down" />;
      }
    }
    return <i className="fa fa-sort" />;
  };

  const changeFilterDirectionHandler = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(applyChangeFilterDirection, columnId);
    },
    [columnId],
    $
  );

  const keyDownHandler = async (e: React.KeyboardEvent<HTMLSpanElement>): Promise<void> => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      await changeFilterDirectionHandler();
    }
  };

  return (
    <th style={style} className={className}>
      <div
        style={
          isTruncable ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } : {}
        }
        className={centerLabel ? 'has-text-centered' : ''}
      >
        <span
          role="button"
          tabIndex={0}
          className="icon is-small m-r-xs"
          onClick={changeFilterDirectionHandler}
          onKeyDown={keyDownHandler}
        >
          {computeSortIcons()}
        </span>
        {isTruthy(columnIcon) ? (
          <FaIcon
            id={columnIcon.iconId}
            tooltip={isTruthyAndNotEmpty(tooltip) ? tooltip : content}
            label={columnIcon.showText ? content : undefined}
          />
        ) : (
          <span title={isTruthyAndNotEmpty(tooltip) ? tooltip : content}>{content}</span>
        )}
      </div>
    </th>
  );
}

interface TableItemProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
> {
  readonly displayedAndSortedColumnDescs: readonly ColumnDesc<SD, SC, O, SO>[];
  readonly item: O;
  readonly isTruncable: boolean;
  readonly $: StoreStateSelector<SD, SC>;
  readonly showRepositoryStatus: boolean;
  readonly getSubLines?: (item: O) => readonly SO[];
  readonly isOnline: boolean;
}

function TableItem<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
>({
  item,
  displayedAndSortedColumnDescs,
  isTruncable,
  $,
  getSubLines,
  isOnline,
  showRepositoryStatus,
}: TableItemProps<SD, SC, O, SO>): JSX.Element {
  const selectedItemId = useGetState($.$selectedItemId);

  const itemId = item.id;

  const clickHandlerActionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('selectedItemId', itemId);
    },
    [itemId],
    $
  );

  const subLines = useMemo(() => {
    if (isTruthy(getSubLines)) {
      return getSubLines(item);
    }
    return [];
  }, [getSubLines, item]);

  const expandedLinesIds = useGetState($.$expandedLinesIds);

  const isExpanded = useMemo(() => expandedLinesIds.includes(itemId), [expandedLinesIds, itemId]);

  return (
    <>
      <tr
        className={itemId === selectedItemId ? 'is-selected no-border-cells' : ''}
        role="menuitem"
        onClick={clickHandlerActionCallback}
      >
        {showRepositoryStatus && (
          <td>
            <RepositoryEntityDirtyIcon
              isOnline={isOnline}
              entity={item as unknown as RepositoryEntity}
            />
          </td>
        )}
        {displayedAndSortedColumnDescs.map((c, i): JSX.Element => {
          if (c.columnType === 'action') {
            return (
              <BoundTableActionCell
                key={`${itemId}_${c.id}`}
                action={c.action}
                item={item}
                iconId={c.getCellIconId(item)}
                tooltip={c.cellTooltip}
                label={c.cellLabel}
                $={$}
                disabled={isTruthy(c.disabled) ? c.disabled(item) : undefined}
              />
            );
          }

          return (
            <TableDisplayCell
              item={item}
              key={`${itemId}_${c.id}`}
              columnDesc={c}
              isTruncable={isTruncable}
              itemId={itemId}
              $={$}
              hasChildren={subLines.length > 0}
              // Only the first display column will have the child arrow element
              // Since action cell are not movable this is safe to rely on the index
              shouldDisplayChildLinesToggle={isTruthy(getSubLines) && i === 0}
            />
          );
        })}
      </tr>
      {isExpanded &&
        subLines.map((subItem) => (
          <TableItemSubLine
            $={$}
            key={subItem.id}
            subItem={subItem}
            displayedAndSortedColumnDescs={displayedAndSortedColumnDescs}
            isTruncable={isTruncable}
            showRepositoryStatus={showRepositoryStatus}
          />
        ))}
    </>
  );
}

interface TableItemSubLineProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
> {
  readonly $: StoreStateSelector<SD, SC>;
  readonly subItem: SO;
  readonly displayedAndSortedColumnDescs: readonly ColumnDesc<SD, SC, O, SO>[];
  readonly isTruncable: boolean;
  readonly showRepositoryStatus: boolean;
}

function TableItemSubLine<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
>({
  $,
  subItem,
  displayedAndSortedColumnDescs,
  isTruncable,
  showRepositoryStatus,
}: TableItemSubLineProps<SD, SC, O, SO>): JSX.Element {
  const subItemId = subItem.id;
  return (
    <tr className="has-background-light">
      {showRepositoryStatus && <td />}
      {displayedAndSortedColumnDescs.map((c): JSX.Element => {
        if (c.columnType === 'display' && isTruthy(c.subCell)) {
          return (
            <TableDisplayCell
              item={subItem}
              itemId={subItemId}
              key={`${subItemId}_${c.id}`}
              columnDesc={c.subCell}
              isTruncable={isTruncable}
              hasChildren
              $={$}
              shouldDisplayChildLinesToggle={false}
            />
          );
        }
        return <td />;
      })}
    </tr>
  );
}

interface TableDisplayCellProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  T extends ArrayItemStateType,
> {
  readonly item: T;
  readonly $: StoreStateSelector<SD, SC>;
  readonly itemId: string;
  readonly isTruncable: boolean;
  readonly columnDesc: CommonDisplayColumnDesc<T>;
  readonly shouldDisplayChildLinesToggle: boolean;
  readonly hasChildren: boolean;
}

function TableDisplayCell<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType,
  T extends ArrayItemStateType = O | SO,
>({
  item,
  $,
  isTruncable,
  itemId,
  columnDesc,
  shouldDisplayChildLinesToggle,
  hasChildren,
}: TableDisplayCellProps<SD, SC, O, T>): JSX.Element {
  const value = useMemo(() => {
    if (isTruthy(columnDesc.getDisplayedValue)) {
      return columnDesc.getDisplayedValue(item);
    }
    return String(columnDesc.getPropertyValue(item));
  }, [columnDesc, item]);

  let icon: TableCellIcon | undefined;
  if (columnDesc.propertyType === 'boolean') {
    icon = {
      iconId: columnDesc.getPropertyValue(item) ? 'check' : '',
      showText: false,
    };
  }
  icon = isTruthy(columnDesc.getCellIcon) ? columnDesc.getCellIcon(item) : icon;
  let displayedClassName = isTruthy(columnDesc.getDisplayedClassName)
    ? columnDesc.getDisplayedClassName(item)
    : '';

  if (columnDesc.propertyType === 'number') {
    displayedClassName += ' has-text-right';
  } else if (icon && !icon.showText) {
    displayedClassName += ' has-text-centered';
  }

  // FIXME: retrieving react components through a useMemo ?... Strange pattern...
  const cell = useMemo(() => {
    const cellContents: JSX.Element[] = [];
    if (shouldDisplayChildLinesToggle) {
      cellContents.push(
        <ToggleNestedButton
          id={itemId}
          key={`nestedButton_${itemId}`}
          label=""
          hasChildren={hasChildren}
          $expandedIds={$.$expandedLinesIds}
        />
      );
    }
    if (isTruthy(icon) && typeof value === 'string' && value !== '') {
      cellContents.push(
        <FaIcon
          key={`icon_${itemId}`}
          id={icon.iconId}
          tooltip={value}
          label={icon.showText ? value : undefined}
        />
      );
    } else {
      cellContents.push(<React.Fragment key={`value_${itemId}`}>{value}</React.Fragment>);
    }
    return cellContents;
  }, [$.$expandedLinesIds, hasChildren, icon, itemId, shouldDisplayChildLinesToggle, value]);

  const decorators = columnDesc.getDecorators ? columnDesc.getDecorators(item) : undefined;
  if (isTruncable) {
    return (
      <TruncableTableTd className={displayedClassName} style={{ position: 'relative' }}>
        <>
          {decorators && (
            <div style={{ position: 'absolute', top: 0, right: 0, float: 'right' }}>
              <Decorators decorators={decorators} />
            </div>
          )}
          {cell}
        </>
      </TruncableTableTd>
    );
  }
  return (
    <td>
      <>
        {cell}
        {decorators && <Decorators decorators={decorators} />}
      </>
    </td>
  );
}

export interface TableActionCellProps {
  readonly onClick: () => void | Promise<void>;
  readonly iconId: string;
  readonly tooltip: string;
  readonly label?: string;
  readonly disabled?: boolean;
  readonly additionalTdClassName?: string;
  readonly additionalButtonClassName?: string;
}

export function TableActionCell({
  iconId,
  tooltip,
  onClick,
  label,
  disabled,
  additionalTdClassName = '',
  additionalButtonClassName = '',
}: TableActionCellProps): JSX.Element {
  return (
    <td
      className={`has-text-centered ${additionalTdClassName}`}
      style={{ paddingRight: 0, paddingLeft: 0 }}
    >
      <button
        type="button"
        className={`button is-small is-transparent ${additionalButtonClassName}`}
        title="update"
        onClick={onClick}
        disabled={disabled}
      >
        <FaIcon id={iconId} tooltip={tooltip} label={label} />
      </button>
    </td>
  );
}

interface BoundedTableActionCellProps<
  SD extends AnyTableStoreDef,
  S extends State,
  O extends ArrayItemStateType,
> extends Omit<TableActionCellProps, 'onClick'> {
  readonly action: (dispatch: ActionDispatch<SD, S>, item: O) => void | Promise<void>;
  readonly item: O;
  readonly $: StoreStateSelector<SD, S>;
}

export function BoundTableActionCell<
  SD extends AnyTableStoreDef,
  SC extends State,
  O extends ArrayItemStateType,
>({
  item,
  $,
  action,
  iconId,
  tooltip,
  label,
  disabled,
  additionalTdClassName,
  additionalButtonClassName,
}: BoundedTableActionCellProps<SD, SC, O>): JSX.Element {
  const onClick = useActionCallback(
    async ({ actionDispatch }): Promise<void> => await action(actionDispatch, item),
    [action, item],
    $
  );
  return (
    <TableActionCell
      iconId={iconId}
      tooltip={tooltip}
      label={label}
      disabled={disabled}
      onClick={onClick}
      additionalTdClassName={additionalTdClassName}
      additionalButtonClassName={additionalButtonClassName}
    />
  );
}
