import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type {
  StandardPictureCategory,
  StandardPictureConfEntry,
  StandardPicturesConfigurationKey,
} from '@stimcar/libs-kernel';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthyAndNotEmpty, STANDARD_PICTURES_CONFIGURATIONS_KEYS } from '@stimcar/libs-kernel';
import { useArrayItemSelector, useGetState } from '@stimcar/libs-uikernel';
import { Checkbox, ReactSelectFormField } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';

function shouldDisplayCategoryColumn(
  configurations: readonly StandardPictureConfEntry[],
  index: number,
  currentCategory: StandardPictureCategory
): boolean {
  return index === 0 || currentCategory !== configurations[index - 1].category;
}

export interface StandardPicturesTableRowProps {
  readonly $configurationKey: StoreStateSelector<Store, StandardPicturesConfigurationKey>;
  readonly $configuration: StoreStateSelector<Store, readonly StandardPictureConfEntry[]>;
  readonly configurationId: string;
  readonly showCategory: boolean;
}

export function StandardPicturesTableRow({
  $configurationKey,
  $configuration,
  configurationId,
  showCategory,
}: StandardPicturesTableRowProps): JSX.Element {
  const [t] = useTranslation('adminStandardPictures');
  const configurationKey = useGetState($configurationKey);
  const $configurationEntry = useArrayItemSelector($configuration, configurationId);
  const configurationEntry = useGetState($configurationEntry);
  const configurations = useGetState($configuration);
  const configurationsWithSameCategory = configurations.filter(
    ({ category }) => category === configurationEntry.category
  ).length;
  return (
    <tr key={configurationEntry.id}>
      {showCategory && (
        <td rowSpan={configurationsWithSameCategory} className="has-text-weight-bold">
          {t(`categoryValues.${configurationKey}.${configurationEntry.category}`)}
        </td>
      )}
      <td>{t(`viewValues.${configurationKey}.${configurationEntry.standardPictureId}`)}</td>
      <td aria-label="empty">
        <Checkbox $={$configurationEntry.$displayed} />
      </td>
      <td aria-label="empty">
        <Checkbox $={$configurationEntry.$improveImage} />
      </td>
    </tr>
  );
}

export interface StandardPicturesTableProps {
  readonly $configurationKey: StoreStateSelector<Store, StandardPicturesConfigurationKey>;
  readonly $configuration: StoreStateSelector<Store, readonly StandardPictureConfEntry[]>;
}

export function StandardPicturesTable({
  $configurationKey,
  $configuration,
}: StandardPicturesTableProps): JSX.Element {
  const [t] = useTranslation('adminStandardPictures');
  const configurations = useGetState($configuration);
  return (
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          <th>{t('tableHeaders.category')}</th>
          <th>{t('tableHeaders.key')}</th>
          <th>{t('tableHeaders.displayed')}</th>
          <th>{t('tableHeaders.improveImage')}</th>
        </tr>
      </thead>
      <tbody>
        {configurations.map((configuration, index): JSX.Element => {
          const showCategory = shouldDisplayCategoryColumn(
            configurations,
            index,
            configuration.category
          );
          return (
            <StandardPicturesTableRow
              key={configuration.id}
              showCategory={showCategory}
              $configurationKey={$configurationKey}
              $configuration={$configuration}
              configurationId={configuration.id}
            />
          );
        })}
      </tbody>
    </table>
  );
}

export interface StandardPicturesTableFormFieldProps {
  readonly $configurationKey: StoreStateSelector<Store, StandardPicturesConfigurationKey>;
  readonly $configuration: StoreStateSelector<Store, readonly StandardPictureConfEntry[]>;
}

export function StandardPicturesTableFormField({
  $configurationKey,
  $configuration,
}: StandardPicturesTableFormFieldProps): JSX.Element {
  const [t] = useTranslation('adminStandardPictures');
  const configurationKey = useGetState($configurationKey);
  return (
    <>
      <ReactSelectFormField
        label={t('title')}
        suggestions={STANDARD_PICTURES_CONFIGURATIONS_KEYS}
        $={$configurationKey}
        horizontal
      />
      <div className="field">
        {isTruthyAndNotEmpty(configurationKey) && (
          <StandardPicturesTable
            $configurationKey={$configurationKey}
            $configuration={$configuration}
          />
        )}
      </div>
    </>
  );
}
