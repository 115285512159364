import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import type { Customer } from '../../model/typings/customer.js';
import type { SortDirection } from '../../model/typings/general.js';
import type {
  BasePackageDeal,
  Kanban,
  PackageDeal,
  PriceablePackageDeal,
  PurchaseOrder,
} from '../../model/typings/kanban.js';
import type { SpecificFields } from '../../model/typings/repository.js';
import type { PackageDealExpressionComputationResult } from '../../model/typings/share.js';
import type { Sequence } from '../sequence.js';
import { EMPTY_PURCHASE_ORDER } from '../../model/globalConstants.js';
import { compareBooleans, filterReject, nonDeleted } from '../misc.js';
import { trim } from '../stringHelpers.js';
import { kanbanHelpers } from './kanbanHelpers.js';
import { packageDealHelpers } from './packageDealHelpers.js';

export type PackageDealsBySituation = {
  readonly SELECTED: readonly PriceablePackageDeal[];
  readonly DISCARDED: readonly PriceablePackageDeal[];
};

export type PackageDealsWithCost = {
  readonly packageDeals: readonly PackageDeal[];
  readonly cost: number;
};

export type PurchaseOrderNumberAndLabel = Pick<PurchaseOrder, 'purchaseNumber' | 'label'>;

function getSeparatorPosition(line: string, separators: readonly string[]): number {
  return separators.reduce<number>((currentPosition, separator) => {
    const pos = line.indexOf(separator);
    if (pos !== -1 && (pos < currentPosition || currentPosition === -1)) {
      return pos;
    }
    return currentPosition;
  }, -1);
}

const PURCHASE_ORDER_NUMBER_AND_LABEL_SEPARATORS = [':', '-', '/'];

function getPurchaseOrdersNumberAndLabelFromString(
  purchaseOrdersAsString: string
): readonly PurchaseOrderNumberAndLabel[] {
  if (purchaseOrdersAsString) {
    const lines = purchaseOrdersAsString.split(/\r\n|\r|\n/);
    return lines
      .map((line) => {
        const separatorPosition = getSeparatorPosition(
          line,
          PURCHASE_ORDER_NUMBER_AND_LABEL_SEPARATORS
        );

        const purchaseNumber =
          separatorPosition > -1 ? trim(line.substring(0, separatorPosition)) : trim(line);
        const label = separatorPosition > -1 ? trim(line.substring(separatorPosition + 1)) : null;
        if (isTruthyAndNotEmpty(purchaseNumber)) {
          return {
            purchaseNumber,
            label,
          };
        }
        return null;
      })
      .filter(isTruthy);
  }
  return [];
}

function isPurchaseOrderNumberAndLabelPresent(
  purchaseOrders: PurchaseOrder[],
  { purchaseNumber: givenPurchaseNumber, label: givenLabel }: PurchaseOrderNumberAndLabel
): boolean {
  return purchaseOrders.some(
    ({ purchaseNumber, label }) => purchaseNumber === givenPurchaseNumber && label === givenLabel
  );
}

function sortPurchaseOrdersByDeletedState(
  purchaseOrders: readonly PurchaseOrder[],
  sortDirection: SortDirection
): readonly PurchaseOrder[] {
  return [...purchaseOrders].sort((a, b) => compareBooleans(a.deleted, b.deleted, sortDirection));
}

function getUpdatedExistingPurchaseOrders(
  originalPurchaseOrders: readonly PurchaseOrder[],
  newPurchaseOrderNumbersAndLabels: readonly PurchaseOrderNumberAndLabel[]
): readonly PurchaseOrder[] {
  const updatedPurchaseOrders: PurchaseOrder[] = [];
  // Here we want to prioritize the reuse of non deleted purchase orders, so we sort purchase orders
  // to have the deleted ones at the end of the array
  const purchaseOrdersSortedByDeletedProperty = sortPurchaseOrdersByDeletedState(
    originalPurchaseOrders,
    'DOWN'
  );
  purchaseOrdersSortedByDeletedProperty.forEach((originalPurchaseOrder) => {
    // Retrieve existing purchase order with same purchase number
    const matchingNewPurchaseOrder = newPurchaseOrderNumbersAndLabels.find(
      ({ purchaseNumber }) => originalPurchaseOrder.purchaseNumber === purchaseNumber
    );
    if (
      isTruthy(matchingNewPurchaseOrder) &&
      !isPurchaseOrderNumberAndLabelPresent(updatedPurchaseOrders, matchingNewPurchaseOrder)
    ) {
      updatedPurchaseOrders.push({
        ...originalPurchaseOrder,
        purchaseNumber: matchingNewPurchaseOrder.purchaseNumber,
        label: matchingNewPurchaseOrder.label,
        ...(originalPurchaseOrder.deleted ? { deleted: false } : {}), // Add deleted only if needed
      });
    } else {
      updatedPurchaseOrders.push({
        ...originalPurchaseOrder,
        deleted: true,
      });
    }
  });
  return updatedPurchaseOrders;
}

function getNewPurchaseOrders(
  originalPurchaseOrders: readonly PurchaseOrder[],
  newPurchaseOrderNumbersAndLabels: readonly PurchaseOrderNumberAndLabel[],
  sequence: Sequence
): readonly PurchaseOrder[] {
  const originalPurchaseNumbers = originalPurchaseOrders.map(
    ({ purchaseNumber }) => purchaseNumber
  );

  return newPurchaseOrderNumbersAndLabels
    .filter(({ purchaseNumber }) => !originalPurchaseNumbers.includes(purchaseNumber))
    .map(({ purchaseNumber: newPurchaseNumber, label: newLabel }) => {
      return {
        id: sequence.next(),
        purchaseNumber: newPurchaseNumber,
        label: newLabel,
        invoiceInfos: [],
      };
    });
}

/**
 * Expected format is :
 *  - one line per purchase order, purchase orders are separated by carriage return
 *  - purchase order's format is <purchaseNumber>:<label>
 */
function getPurchaseOrdersFromString(
  newPurchaseOrdersAsString: string,
  originalPurchaseOrders: readonly PurchaseOrder[],
  sequence: Sequence
): readonly PurchaseOrder[] {
  const newPurchaseOrderNumbersAndLabels =
    getPurchaseOrdersNumberAndLabelFromString(newPurchaseOrdersAsString);

  // If string is not empty, updates existing purchaseOrders and creates new ones accordingly
  if (newPurchaseOrderNumbersAndLabels.length > 0) {
    const updatedExistingPurchaseOrders = getUpdatedExistingPurchaseOrders(
      originalPurchaseOrders,
      newPurchaseOrderNumbersAndLabels
    );
    const newPurchaseOrders = getNewPurchaseOrders(
      originalPurchaseOrders,
      newPurchaseOrderNumbersAndLabels,
      sequence
    );
    return [...updatedExistingPurchaseOrders, ...newPurchaseOrders];
  }

  // Otherwise, we reuse an existing non-deleted purchase order with no purchase number
  const activeEmptyPurchaseOrder = originalPurchaseOrders
    .filter(nonDeleted)
    .find(({ purchaseNumber }) => purchaseNumber === '');
  if (isTruthy(activeEmptyPurchaseOrder)) {
    return [activeEmptyPurchaseOrder];
  }

  // Otherwise, we reuse an existing deleted purchase order with no purchase number
  const deletedEmptyPurchaseOrder = originalPurchaseOrders.find(
    ({ purchaseNumber }) => purchaseNumber === ''
  );
  if (isTruthy(deletedEmptyPurchaseOrder)) {
    return [
      {
        ...deletedEmptyPurchaseOrder,
        deleted: false,
      },
    ];
  }

  // Or we create a new one
  return [
    {
      ...EMPTY_PURCHASE_ORDER,
      id: sequence.next(),
    },
  ];
}

function getStringFromPurchaseOrders(purchaseOrders: readonly PurchaseOrder[]): string {
  if (isTruthy(purchaseOrders) && purchaseOrders.length > 0) {
    return purchaseOrders
      .filter(nonDeleted)
      .map(({ purchaseNumber, label }) =>
        isTruthyAndNotEmpty(label) ? `${purchaseNumber}:${label}` : `${purchaseNumber}`
      )
      .join('\r\n');
  }
  return '';
}

function getPackageDealsBySituation(
  packageDeals: readonly PriceablePackageDeal[],
  sortFunction: (pd1: BasePackageDeal, pd2: BasePackageDeal) => number
): PackageDealsBySituation {
  const { filtered, rejected } = filterReject(packageDeals, (pd: PriceablePackageDeal): boolean =>
    packageDealHelpers.isPackageDealAvailable(pd)
  );

  const sortedFiltered = filtered.slice().sort(sortFunction);
  const sortedDiscarded = rejected.slice().sort(sortFunction);
  return {
    SELECTED: sortedFiltered,
    DISCARDED: sortedDiscarded,
  };
}

function getPackageDealsWithSituationByPurchaseOrder(
  purchaseOrders: readonly PurchaseOrder[],
  packageDealsBySituation: PackageDealsBySituation,
  displayedLabelAsKey: boolean = true
): Record<string, PackageDealsBySituation> {
  return purchaseOrders.reduce((accumulator, purchaseOrder) => {
    const selectedPackageDealsForPurchaseOrder = packageDealsBySituation.SELECTED.filter(
      ({ purchaseOrderId }) => purchaseOrderId === purchaseOrder.id
    );
    const discardedPackageDealsForPurchaseOrder = packageDealsBySituation.DISCARDED.filter(
      ({ purchaseOrderId }) => purchaseOrderId === purchaseOrder.id
    );
    return {
      ...accumulator,
      [displayedLabelAsKey
        ? getPurchaseOrderDisplayedLabel(purchaseOrder)
        : purchaseOrder.purchaseNumber]: {
        SELECTED: selectedPackageDealsForPurchaseOrder,
        DISCARDED: discardedPackageDealsForPurchaseOrder,
      },
    };
  }, {});
}

function getPackageDealsForPurchaseOrder(
  kanban: SpecificFields<Kanban>,
  selectedPurchaseOrderId: string
): readonly PackageDeal[] {
  const purchaseOrders = kanban.purchaseOrders.filter(nonDeleted);
  const multiplePurchaseOrders = purchaseOrders.length > 1;

  // If 0 or 1 purchase order, return all package deals
  // Else return only the package deals associated with the purchase order
  return kanban.packageDeals
    .filter(packageDealHelpers.buildPackageDealFilter('achievable'))
    .filter(
      ({ purchaseOrderId }) =>
        !multiplePurchaseOrders || purchaseOrderId === selectedPurchaseOrderId
    );
}

function getNotAllocatedPackagesDeals(packageDeals: readonly PriceablePackageDeal[]) {
  return packageDeals.filter(({ purchaseOrderId }) => !isTruthyAndNotEmpty(purchaseOrderId));
}

function getNotAllocatedPackageDealsWithSituation({
  SELECTED,
  DISCARDED,
}: PackageDealsBySituation): PackageDealsBySituation {
  return {
    SELECTED: getNotAllocatedPackagesDeals(SELECTED),
    DISCARDED: getNotAllocatedPackagesDeals(DISCARDED),
  };
}

function getPackageDealsWithCostByPurchaseOrder(
  purchaseOrders: readonly PurchaseOrder[],
  packageDeals: readonly PackageDeal[]
): Record<string, PackageDealsWithCost> {
  const activePurchaseOrders = purchaseOrders.filter(nonDeleted);
  return activePurchaseOrders.reduce((accumulator, purchaseOrder) => {
    const packageDealsForPurchaseOrder = packageDeals.filter(
      ({ purchaseOrderId }) => purchaseOrderId === purchaseOrder.id
    );

    const activeAndAvailablePackageDeals = packageDeals.filter(
      packageDealHelpers.buildPackageDealFilter('available', true)
    );
    return {
      ...accumulator,
      [purchaseOrderHelpers.getPurchaseOrderDisplayedLabel(purchaseOrder)]: {
        packageDeals: packageDealsForPurchaseOrder,
        cost: packageDealHelpers.getPackageDealsPriceWithoutVAT(activeAndAvailablePackageDeals),
      },
    };
  }, {});
}

function getNotAllocatedPackageDealsWithPricesDifferentFromZero(
  packageDeals: readonly PackageDealExpressionComputationResult[]
): readonly PackageDealExpressionComputationResult[] {
  return packageDeals.filter(
    (packageDeal) =>
      packageDeal.status !== 'canceled' &&
      !isTruthyAndNotEmpty(packageDeal.purchaseOrderId) &&
      packageDeal.price !== 0
  );
}

function hasMultiplePurchaseOrders(purchaseOrders: readonly PurchaseOrder[]): boolean {
  return purchaseOrders.filter(nonDeleted).length > 1;
}

function getPurchaseOrderCustomerForPurchaseNumber(
  purchaseOrders: readonly PurchaseOrder[],
  givenPurchaseNumber: string
): SpecificFields<Customer> | undefined {
  const givenPurchaseOrder = purchaseOrders.find(
    ({ purchaseNumber }) => purchaseNumber === givenPurchaseNumber
  );
  return givenPurchaseOrder?.customer ?? undefined;
}

function getPurchaseOrderDisplayedLabel(purchaseOrder: PurchaseOrder): string {
  if (isTruthyAndNotEmpty(purchaseOrder.label)) {
    return `${purchaseOrder.purchaseNumber} - ${purchaseOrder.label}`;
  }
  return purchaseOrder.purchaseNumber;
}

function getPurchaseOrderDisplayedCustomer(customer: SpecificFields<Customer>): string {
  if (isTruthyAndNotEmpty(customer.company)) {
    return customer.company;
  }
  return `${customer.lastName} ${customer.firstName}`;
}

function getTotalForAllInvoiceInfos(purchaseOrder: PurchaseOrder): number {
  return purchaseOrder.invoiceInfos
    .filter(nonDeleted)
    .map(({ amount }) => amount)
    .reduce<number>((sum, value) => sum + (value ?? 0), 0);
}

/**
 * We do not generate an invoice if :
 * - the total price is null or negative,
 * - spare parts are still to be received
 * - invoice has already been generated and not refunded
 *
 * @param purchaseOrder
 * @param filteredPackageDeals
 * @returns
 */
function shouldGenerateInvoice(
  purchaseOrder: PurchaseOrder,
  filteredPackageDeals: readonly PackageDeal[]
): boolean {
  // Calculate sum of invoice info instances
  const invoiceInfosSum = purchaseOrderHelpers.getTotalForAllInvoiceInfos(purchaseOrder);

  // If the sum of all invoice infos is 0 it means :
  // - no invoice was generated
  // - all generated invoices have been refunded
  // Fix : 2024/07/04 if the sum is < 0 we should generate too
  // it just means we have referenced refunds without the corresponding invoice (it happened because of bugs)
  const invoicesSumIsNotPositive = invoiceInfosSum < 0.001;

  // Get total price for invoice
  const totalPriceWithoutVAT =
    packageDealHelpers.getInvoiceablePackageDealsAndSparePartsPriceWithoutVAT(filteredPackageDeals);

  // Check if some spare parts are still to be received
  const notReceivedSpareParts =
    kanbanHelpers.getAllNotReceivedSparePartsForPkgsDeals(filteredPackageDeals);

  if (
    !invoicesSumIsNotPositive ||
    notReceivedSpareParts.length > 0 ||
    totalPriceWithoutVAT < 0.001
  ) {
    // We do not create the invoice here
    return false;
  }
  return true;
}

export const purchaseOrderHelpers = {
  hasMultiplePurchaseOrders,
  getPackageDealsBySituation,
  getPurchaseOrderDisplayedLabel,
  getPurchaseOrderDisplayedCustomer,
  getPackageDealsWithCostByPurchaseOrder,
  getNotAllocatedPackageDealsWithSituation,
  getPackageDealsWithSituationByPurchaseOrder,
  getNotAllocatedPackageDealsWithPricesDifferentFromZero,
  getPurchaseOrdersFromString,
  getPurchaseOrdersNumberAndLabelFromString,
  getStringFromPurchaseOrders,
  getPackageDealsForPurchaseOrder,
  getTotalForAllInvoiceInfos,
  shouldGenerateInvoice,
  getPurchaseOrderCustomerForPurchaseNumber,
};
