import React from 'react';
import { useWindowSizeTracker } from '@stimcar/libs-uikernel';
import type { BaseStoreDef } from '../../state/typings/store.js';
import type { AppComponent, AppProps } from '../../typings/app.js';
import { ImageModalDialog } from '../../../react/bulma/dialogs/ImageModalDialog.js';
import { IsTrue } from '../../../react/custom/misc/IsTrue.js';
import { MessageDialog } from '../../../react/custom/misc/MessageDialog.js';

interface BaseStoreAppWrapperProps<SD extends BaseStoreDef> extends AppProps<SD> {
  readonly appComponent: AppComponent<SD>;
}

/**
 * This component is the first component to use the store.
 *
 * It concentrates all generic things that are factorized using
 * the store :
 * * message dialog
 * * window size management
 * * image modale
 */
export function BaseStoreAppWrapper<SD extends BaseStoreDef>({
  $gs,
  appComponent: App,
}: BaseStoreAppWrapperProps<SD>) {
  // Track window size
  useWindowSizeTracker($gs);
  return (
    <>
      <MessageDialog $={$gs} />
      <IsTrue $={$gs.$imageModal.$active}>
        <ImageModalDialog
          $={$gs.$imageModal}
          size="fullscreen"
          zIndex={Number.MAX_SAFE_INTEGER - 10}
        />
      </IsTrue>
      <App $gs={$gs} />
    </>
  );
}
