import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban } from '@stimcar/libs-base';
import { locationHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';

interface Props {
  readonly kanban: Kanban;
  readonly onlyIcon?: boolean;
}

export function KanbanCurrentLocationStatusIconComponent({
  kanban,
  onlyIcon = false,
}: Props): JSX.Element {
  const [t] = useTranslation('selectKanban');

  const currentLocation = locationHelpers.getCurrentLocation(kanban);
  if (!isTruthy(currentLocation)) {
    return (
      <FaIcon
        id="r/times-circle"
        tooltip={t('globals:positionnoLocation')}
        label={onlyIcon ? undefined : t('globals:position.noLocation')}
      />
    );
  }
  if (currentLocation === 'workshop') {
    return (
      <FaIcon
        id="wrench"
        tooltip={t('tooltips.inWorkshop')}
        label={onlyIcon ? undefined : t('globals:position.workshopLocation')}
      />
    );
  }
  return (
    <FaIcon
      id="parking"
      tooltip={t('selectKanban:tooltips.inParking', { location: currentLocation })}
      label={onlyIcon ? undefined : currentLocation}
    />
  );
}
