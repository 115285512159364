import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from '../FormField.js';
import { FormField } from '../FormField.js';
import type { MultiListSelectProps } from './MultiListSelect.js';
import { MultiListSelect } from './MultiListSelect.js';

export interface MultiListSelectFormFieldProps<SD extends AnyStoreDef>
  extends MultiListSelectProps<SD>,
    Omit<FormFieldProps, 'children' | 'warning'> {}

export function MultiListSelectFormField<SD extends AnyStoreDef>({
  label,
  horizontal,
  $selection,
  noExclamationTriangleIfWarning,
  ...props
}: MultiListSelectFormFieldProps<SD>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($selection);
  return (
    <FormField
      label={label}
      warning={dispatchWarning}
      horizontal={horizontal}
      noExclamationTriangleIfWarning={noExclamationTriangleIfWarning}
    >
      <MultiListSelect $selection={$selection} {...props} />
    </FormField>
  );
}
