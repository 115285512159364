import type { SortDirection } from '@stimcar/libs-base';
import type { Defect, DefectHighlighter, PositionOnCar } from '@stimcar/libs-kernel';
import { nonDeleted, sortingHelpers } from '@stimcar/libs-base';
import {
  CAR_INTERIOR_PICTURE_ID,
  CAR_INTERIOR_PICTURE_TO_DISPLAY,
  isTruthy,
  MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
} from '@stimcar/libs-kernel';
import type { ComputeAttachmentUrlCallback } from '../../../../lib/components/attachments/typings/attachment.js';

const CAR_INTERIOR_ICON = 'car-interior-icon.webp';

export function getPictureIconPath(id: string): string {
  if (id === CAR_INTERIOR_PICTURE_ID) {
    return `img/${CAR_INTERIOR_ICON}`;
  }
  return `img/car-${id}`;
}

export function getPictureSrcFromPictureIdForSelector(
  kanbanId: string,
  pictureId: string,
  computeUrlCallback: ComputeAttachmentUrlCallback
): string {
  if (pictureId === CAR_INTERIOR_PICTURE_ID) {
    return `img/${CAR_INTERIOR_PICTURE_TO_DISPLAY}`;
  }
  return computeUrlCallback(
    'kanban',
    MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
    pictureId,
    kanbanId,
    {
      mode: 'cover',
      size: '640x480',
    }
  );
}

export function getDefectPictureName(defectId: string): string {
  return `defect-${defectId}.webp`;
}

export function isValidDefectHighlighter({ x, y, width, height }: DefectHighlighter): boolean {
  return x !== 0 && y !== 0 && width !== 0 && height !== 0;
}

export function getDefectHighlighterStyle(highlighter: DefectHighlighter) {
  return {
    top: `${highlighter.y}%`,
    left: `${highlighter.x}%`,
    width: `${highlighter.width}%`,
    height: `${highlighter.height}%`,
  };
}

export type DefectWithIndex = Defect & {
  index: number;
};

function compareByPosition(
  { positionOnCar: position1 }: Defect,
  { positionOnCar: position2 }: Defect
): number {
  if (!isTruthy(position2)) {
    return -1;
  }
  if (!isTruthy(position1)) {
    return 1;
  }
  const xDelta = (position1.x ?? 0) - (position2.x ?? 0);
  if (xDelta !== 0) {
    return xDelta;
  }
  return (position1.y ?? 0) - (position2.y ?? 0);
}

export function isPositionCarOnPicture(
  positionOnCar: PositionOnCar | null,
  pictureId: string
): boolean {
  return isTruthy(positionOnCar) && positionOnCar.pictureId === pictureId;
}

function getSortFunction(
  sortBy: string | undefined,
  sortDirection: SortDirection
): ((d1: DefectWithIndex, d2: DefectWithIndex) => number) | undefined {
  if (sortBy === 'type') {
    return sortingHelpers.createSortByStringField<Defect>(sortDirection, 'type');
  }
  return sortingHelpers.createSortByNumericField<DefectWithIndex>('DOWN', 'index');
}

export function getDefectsToDisplay(
  defects: readonly Defect[],
  selectedPictureId: string,
  sortBy: string | undefined,
  sortDirection: SortDirection
): readonly DefectWithIndex[] {
  const sortFunction = getSortFunction(sortBy, sortDirection);

  let currentIndex = 1;
  const filteredDefects = defects
    .filter(nonDeleted)
    .filter(({ positionOnCar }) => isPositionCarOnPicture(positionOnCar, selectedPictureId))
    .sort(compareByPosition)
    .map((defect) => {
      const defectWithIndex = {
        ...defect,
        index: currentIndex,
      };
      currentIndex += 1;
      return defectWithIndex;
    });

  return [...filteredDefects].sort(sortFunction);
}
