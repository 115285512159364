import { Logger } from '@stimcar/libs-kernel';
import type { ProgressMonitorStoreState } from './typings/store.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export const EMPTY_PROGRESS_MONITOR_STORE_STATE: ProgressMonitorStoreState = {
  label: undefined,
  maxValue: -1,
  value: undefined,
  appIsInitialized: false,
};
