import React from 'react';
import type { TruncableProps } from './TruncableP.js';

export function TruncableSpan({
  className,
  style,
  tooltip,
  children,
}: TruncableProps): JSX.Element {
  return (
    <span
      className={className}
      style={{
        width: '12.5rem',
        ...style,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      title={tooltip}
    >
      {children}
    </span>
  );
}
