import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  CoreFields,
  Kanban,
  KanbanColorationCharter,
  KanbanSummary,
} from '@stimcar/libs-base';
import type {
  AnyStoreDef,
  GlobalStoreStateSelector,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { kanbanHelpers } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import type { ComputeAttachmentUrlCallback, ImageModalState } from '../attachments/index.js';
import type { RawActionButtonDesc } from '../misc/index.js';
import { kanbanPriorityLevelHelpers } from '../../../utils/index.js';
import { FaIcon, ScrollableContainer } from '../../bulma/index.js';
import { computeGeneralInformationsDatas } from '../displayInformations/displayInformationsUtils.js';
import { TruncableP, useActionButtonDescs } from '../misc/index.js';
import type { ExpandedKanbanStatuses, SelectableKanbanListItemProps } from './KanbanListItem.js';
import { KanbanListItem, SelectableKanbanListItem } from './KanbanListItem.js';

export type KanbanListProps = Pick<
  SelectableKanbanListItemProps,
  'selectKanban' | 'disableSelection' | 'kanbanColorationCharter'
> & {
  readonly kanbans: readonly KanbanSummary[];
  readonly selectedKanbanId: string | undefined;
};

export function KanbanList({
  kanbans,
  selectKanban,
  disableSelection,
  kanbanColorationCharter,
  selectedKanbanId,
}: KanbanListProps): JSX.Element {
  const [t] = useTranslation('custom');

  return (
    <>
      {kanbans.length > 0 ? (
        <ScrollableContainer>
          <>
            {kanbans.map(
              (k, i): JSX.Element => (
                <div
                  key={k.id}
                  className={`${i !== 0 ? 'm-t-md ' : 'm-t-xxs '}m-b-md m-l-xs m-r-xs`}
                >
                  <SelectableKanbanListItem
                    kanban={k}
                    isSelected={selectedKanbanId === k.id}
                    selectKanban={selectKanban}
                    disableSelection={disableSelection}
                    kanbanColorationCharter={kanbanColorationCharter}
                  />
                </div>
              )
            )}
          </>
        </ScrollableContainer>
      ) : (
        <div className="m-t-lg">
          <p className="has-text-centered">{t('kanbanList.noResult')}</p>
        </div>
      )}
    </>
  );
}

export interface KanbanListWithActionsProps<SD extends AnyStoreDef> {
  readonly kanbans: readonly Kanban[];
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly $gs: GlobalStoreStateSelector<SD>;
  readonly $expandedKanbanStatuses: StoreStateSelector<SD, ExpandedKanbanStatuses>;
  readonly actionDescsProvider?: (
    kanban: Kanban
  ) => readonly RawActionButtonDesc<SD, SD['globalState']>[];
  readonly kanbanColorationCharter: KanbanColorationCharter | undefined;
  readonly attachmentUrl: ComputeAttachmentUrlCallback;
  readonly getKanbanPositionIconId: (k: CoreFields<Kanban> | undefined) => string | undefined;
}

export function KanbanListWithActions<SD extends AnyStoreDef>({
  kanbans,
  $imageModal,
  $gs,
  $expandedKanbanStatuses,
  actionDescsProvider,
  kanbanColorationCharter,
  attachmentUrl,
  getKanbanPositionIconId,
}: KanbanListWithActionsProps<SD>): JSX.Element {
  const [t] = useTranslation('custom');
  return (
    <>
      {kanbans.length > 0 ? (
        <ScrollableContainer>
          <>
            {kanbans.map(
              (k, i): JSX.Element => (
                <div
                  key={k.id}
                  className={`${i !== 0 ? 'm-t-md ' : 'm-t-xxs '}m-b-md m-l-xs m-r-xs`}
                >
                  <CoreKanbanListItem<SD>
                    kanban={k}
                    kanbanColorationCharter={kanbanColorationCharter}
                    getKanbanPositionIconId={getKanbanPositionIconId}
                    actionDescsProvider={actionDescsProvider}
                    attachmentUrl={attachmentUrl}
                    $imageModal={$imageModal}
                    $gs={$gs}
                    $expandedKanbanStatuses={$expandedKanbanStatuses}
                  />
                </div>
              )
            )}
          </>
        </ScrollableContainer>
      ) : (
        <div className="m-t-lg">
          <p className="has-text-centered">{t('kanbanList.noResult')}</p>
        </div>
      )}
    </>
  );
}

interface CoreKanbanListItemProps<SD extends AnyStoreDef> {
  readonly kanban: Kanban;
  readonly $gs: GlobalStoreStateSelector<SD>;
  readonly $expandedKanbanStatuses: StoreStateSelector<SD, ExpandedKanbanStatuses>;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly attachmentUrl: ComputeAttachmentUrlCallback;
  readonly getKanbanPositionIconId: (k: CoreFields<Kanban> | undefined) => string | undefined;
  readonly kanbanColorationCharter: KanbanColorationCharter | undefined;
  readonly actionDescsProvider?: (
    kanban: Kanban
  ) => readonly RawActionButtonDesc<SD, SD['globalState']>[];
}

function CoreKanbanListItem<SD extends AnyStoreDef>({
  kanban,
  $imageModal,
  $gs,
  attachmentUrl,
  getKanbanPositionIconId,
  kanbanColorationCharter,
  actionDescsProvider,
  $expandedKanbanStatuses,
}: CoreKanbanListItemProps<SD>): JSX.Element {
  const [hasAMessage, requestMessageClassName] = useMemo(() => {
    let message = kanban.messages.find((m) => m.type === 'request' && m.status === 'pending');
    if (!message) {
      message = kanban.messages.find((m) => m.type === 'request' && m.status === 'rejected');
    }
    return [
      isTruthy(message),
      isTruthy(message) ? kanbanPriorityLevelHelpers.getKanbanMessageColorClassName(message) : '',
    ];
  }, [kanban]);

  const positionIconId = useMemo(
    () => getKanbanPositionIconId(kanban),
    [kanban, getKanbanPositionIconId]
  );

  const cssId = kanbanPriorityLevelHelpers.getCssIdForPriorityLevelFromKanban(
    kanban,
    kanbanColorationCharter
  );

  const actionDescs = useActionButtonDescs(
    () => (isTruthy(actionDescsProvider) ? actionDescsProvider(kanban) : []),
    [actionDescsProvider, kanban],
    $gs
  );

  return (
    <KanbanListItem
      kanbanId={kanban.id}
      infos={kanban.infos}
      hasAMessage={hasAMessage}
      requestMessageClassName={requestMessageClassName}
      $imageModal={$imageModal}
      attachmentUrl={attachmentUrl}
      kanbanPositionIconId={positionIconId}
      kanbanColorationClassName={cssId}
      foldable={{ content: <FoldableDataToDisplay kanban={kanban} />, foldedByDefault: true }}
      unfoldable={<UnfoldableDataToDisplay kanban={kanban} />}
      actionDescs={actionDescs}
      $expandedKanbanStatuses={$expandedKanbanStatuses}
    />
  );
}

interface UnfoldableDataToDisplayProps {
  readonly kanban: Kanban;
}

function UnfoldableDataToDisplay({ kanban }: UnfoldableDataToDisplayProps): JSX.Element {
  const [t] = useTranslation('custom');

  const { contract, customer, status } = kanban;

  const { isRevoked, price } = useMemo(() => kanbanHelpers.convertToSummary(kanban)[0], [kanban]);

  const location = useMemo(() => {
    const loc = kanbanHelpers.getCurrentLocation(kanban);
    if (isTruthyAndNotEmpty(loc)) {
      return loc;
    }
    return t('kanbanListItem.unknownLocation');
  }, [kanban, t]);

  const customerLabel = kanbanHelpers.getCustomerLabel(customer, t);
  return (
    <h6 className="is-size-6 m-b-sm">
      <>
        <TruncableP style={{ width: undefined, minWidth: 0 }}>
          <>
            <FaIcon id="r/id-badge" tooltip={contract.code} size="small" />
            {` ${contract.code}`}
            <FaIcon
              id={customer.individual ? 'male' : 'building'}
              tooltip={customerLabel}
              size="small"
            />
            {customerLabel}
          </>
        </TruncableP>
        <TruncableP style={{ width: undefined, minWidth: 0 }}>
          <>
            <FaIcon
              id={status === 'open' ? 'map-marker-alt' : 'database'}
              tooltip={status === 'open' ? location : t('kanbanListItem.archived')}
              size="small"
            />
            {` ${status === 'open' ? location : t('kanbanListItem.archived')}`}
            {isRevoked && (
              <>
                <FaIcon id="times-circle" />
                {t('kanbanListItem.revoked')}
              </>
            )}
            {!isRevoked && (
              <>
                <FaIcon id="euro-sign" />
                {`${price.toFixed(2)} €`}
              </>
            )}
          </>
        </TruncableP>
      </>
    </h6>
  );
}

interface FoldableDataToDisplayProps {
  readonly kanban: Kanban;
}

function FoldableDataToDisplay({ kanban }: FoldableDataToDisplayProps): JSX.Element {
  const [t] = useTranslation('custom');

  const foldedDataToDisplay = useMemo(() => {
    return computeGeneralInformationsDatas(t, kanban, {});
  }, [kanban, t]);

  return (
    <div>
      {foldedDataToDisplay.map((data): JSX.Element => {
        return (
          <p key={data.key}>
            <strong>{`${data.label}: `}</strong>
            {data.value}
          </p>
        );
      })}
    </div>
  );
}
