import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { AvailablePermissionPaths, Role, userHelpers } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { PageLoader, ProtectedRoute } from '@stimcar/libs-uitoolkit';
import { useHasAccessPermission } from '../registeredapp/permissionHooks.js';
import { SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH } from '../subcontractor/subcontractorConstants.js';
import { userAvailableAccessPaths } from '../utils/generalUtils.js';
import type { Store } from './state/typings/store.js';
import { AdminCarElements } from './admin/carElement/AdminCarElements.js';
import { AdminContracts } from './admin/contract/AdminContracts.js';
import { AdminCustomers } from './admin/customer/AdminCustomers.js';
import { AdminKanbansImport } from './admin/kanbansImport/AdminKanbansImport.js';
import { AdminPackageDealDescs } from './admin/packageDealDesc/AdminPackageDealDescs.js';
import { AdminScheduledTasks } from './admin/scheduledTasks/AdminScheduledTasks.js';
import { AdminSparePartsProviders } from './admin/sparePartsProvider/AdminSparePartsProvider.js';
import { UsersAdmin } from './admin/users/UsersAdmin.js';
import { AdminWorkflow } from './admin/workflow/AdminWorkflow.js';
import { AdminWorkshopImplantation } from './admin/workshopImplantation/AdminWorkshopImplantation.js';
import { SearchArchivesView } from './archives/SearchArchivesView.js';
import {
  ADMIN_CAR_ELEMENTS_RELATIVE_PATH,
  ADMIN_CONTRACTS_RELATIVE_PATH,
  ADMIN_CREATE_KANBAN_FROM_RELATIVE_PATH,
  ADMIN_CREATE_KANBAN_RELATIVE_PATH,
  ADMIN_CUSTOMERS_RELATIVE_PATH,
  ADMIN_KANBAN_IMPORT_RELATIVE_PATH,
  ADMIN_PACKAGE_DEALS_RELATIVE_PATH,
  ADMIN_SCHEDULED_TASKS_RELATIVE_PATH,
  ADMIN_SPAREPARTS_PROVIDERS_RELATIVE_PATH,
  ADMIN_WORKFLOWS_RELATIVE_PATH,
  ARCHIVE_DETAILS_RELATIVE_PATH,
  ARCHIVE_RELATIVE_PATH,
  DETAILS_ROUTE_CONFIGURATION_RELATIVE_PATH,
  DISPLAY_FULL_PATH,
  DISPLAY_RELATIVE_PATH,
  OPERATE_ROUTE_CONFIGURATION_RELATIVE_PATH,
  SELECT_KANBAN_RELATIVE_PATH,
  USERS_ADMIN_RELATIVE_PATH,
  WORKSHOP_DASHBOARD_RELATIVE_PATH,
  WORKSHOP_OPERATOR_DASHBOARD_RELATIVE_PATH,
  WORKSHOP_OPERATOR_FULL_PATH,
  WORKSHOP_OPERATOR_RELATIVE_PATH,
} from './coreConstants.js';
import { CreateKanban } from './creation/CreateKanban.js';
import { KanbanDetails } from './details/KanbanDetails.js';
import { Display } from './display/Display.js';
import { StartKanbanHandlingWarningModal } from './globalModals/StartKanbanHandlingWarningModal.js';
import { NavBar } from './NavBar.js';
import { NotificationMessages } from './Notifications.js';
import { OperatorView } from './operators/OperatorView.js';
import { SelectKanban } from './selectKanban/SelectKanban.js';
import { WorkshopView } from './workshop/implantation/WorkshopImplantationView.js';
import { WorkshopPost } from './workshop/post/WorkshopPost.js';

// The beforeUnload listener have to be declared outside of react because as it is used
// to register and unregister the listener based on some criteria not related to React lifecycle
// and the reference of the function should be stable.
// If the function is declared in the react component, it will be a different function on each
// render
function beforeUnloadListener(e: BeforeUnloadEvent): void {
  // Returning empty string because the content of the modal is browser
  // dependant and cannot be customized (style and content)
  e.returnValue = '';
}

export function App({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation(['refitit', 'globals']);
  const browserInfos = useGetState($gs.$session.$infos);
  const workflows = useGetState($gs.$siteConfiguration.$workflows);
  const preventNavigation = useGetState($gs.$preventNavigation);

  const isWorkshopOperator = useMemo(
    (): boolean => userHelpers.isWorkshopPostOperator(browserInfos?.role, browserInfos?.label),
    [browserInfos]
  );

  const asyncEffect = useActionCallback(
    async ({ navigate, httpClient, getGlobalState }) => {
      const { user } = getGlobalState().session;
      if (!user) {
        return;
      }
      const { hasSubcontractorAccess, hasCorporateAccess } = userAvailableAccessPaths(user);

      // User can't access to corporate but can access to subcontractor, redirect
      if (hasSubcontractorAccess && !hasCorporateAccess) {
        navigate(SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH);
        return;
      }
      // User can't access to subcontractors and corporate, logout
      if (!hasSubcontractorAccess && !hasCorporateAccess) {
        await httpClient.logout();
      }
    },
    [],
    $gs
  );

  useEffect(() => {
    if (preventNavigation) {
      window.addEventListener('beforeunload', beforeUnloadListener);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    }
  }, [preventNavigation]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const user = useGetState($gs.$session.$user);
  return (
    <PageLoader
      loadingStatus={
        /* wait until site configuration is pushed by the server */
        workflows.length === 0 ? t('refitit:waitingForSiteConfiguration') : undefined
      }
    >
      <StartKanbanHandlingWarningModal $gs={$gs} />
      <NavBar $gs={$gs} />
      <div className="container is-fluid app">
        <NotificationMessages $gs={$gs} />
        <Routes>
          <Route path={`${DISPLAY_RELATIVE_PATH}/*`} element={<Display $gs={$gs} />} />
          <Route
            path="*"
            element={
              <ProtectedRoute
                redirectTo={DISPLAY_FULL_PATH}
                giveWay={
                  user !== undefined &&
                  browserInfos !== undefined &&
                  browserInfos.role !== Role.Display
                }
              >
                <>
                  {isWorkshopOperator ? (
                    <WorkshopOperatorRoutesSwitch $gs={$gs} />
                  ) : (
                    <OperatorRoutesSwitch $gs={$gs} />
                  )}
                </>
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </PageLoader>
  );
}

function WorkshopOperatorRoutesSwitch({ $gs }: AppProps<Store>): JSX.Element {
  return (
    <Routes>
      <Route path={ARCHIVE_RELATIVE_PATH} element={<SearchArchivesView $gs={$gs} />} />
      <Route path={ARCHIVE_DETAILS_RELATIVE_PATH} element={<SearchArchivesView $gs={$gs} />} />
      <Route
        path={DETAILS_ROUTE_CONFIGURATION_RELATIVE_PATH}
        element={<KanbanDetails $={$gs.$detailsView} $gs={$gs} addKanbanInNavbar />}
      />
      <Route
        path={WORKSHOP_OPERATOR_DASHBOARD_RELATIVE_PATH}
        element={<WorkshopView $gs={$gs} />}
      />
      <Route path={WORKSHOP_OPERATOR_RELATIVE_PATH} element={<WorkshopPost $gs={$gs} />} />
      <Route path="*" element={<Navigate to={WORKSHOP_OPERATOR_FULL_PATH} />} />
    </Routes>
  );
}

function OperatorRoutesSwitch({ $gs }: AppProps<Store>): JSX.Element {
  const canAccessAdminWorkflow = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.WORKFLOW_VIEW
  );
  const canAccessAdminCustomers = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.REPOSITORY_ACCESS_PERMISSION('customer')
  );
  const canAccessAdminCarElement = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.REPOSITORY_ACCESS_PERMISSION('carElement')
  );
  const canAccessAdminPackageDealDescs = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.REPOSITORY_ACCESS_PERMISSION('packageDealDesc')
  );
  const canAccessAdminUsers = useHasAccessPermission($gs, AvailablePermissionPaths.USERS_VIEW);
  const canAccessAdminScheduledTasks = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.SCHEDULED_TASKS_VIEW
  );
  const canAccessAdminContracts = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.CONTRACTS_VIEW
  );
  const canAccessAdminSparepartsProviders = useHasAccessPermission(
    $gs,
    AvailablePermissionPaths.SPAREPARTS_PROVIDERS_VIEW
  );

  return (
    <Routes>
      <Route path={SELECT_KANBAN_RELATIVE_PATH} element={<SelectKanban $gs={$gs} />} />
      <Route path={ARCHIVE_RELATIVE_PATH} element={<SearchArchivesView $gs={$gs} />} />
      <Route path={ARCHIVE_DETAILS_RELATIVE_PATH} element={<SearchArchivesView $gs={$gs} />} />
      <Route path={ADMIN_CREATE_KANBAN_RELATIVE_PATH} element={<CreateKanban $gs={$gs} />} />
      <Route path={ADMIN_CREATE_KANBAN_FROM_RELATIVE_PATH} element={<CreateKanban $gs={$gs} />} />
      <Route path={ADMIN_KANBAN_IMPORT_RELATIVE_PATH} element={<AdminKanbansImport $gs={$gs} />} />
      <Route
        path={OPERATE_ROUTE_CONFIGURATION_RELATIVE_PATH}
        element={<OperatorView $gs={$gs} />}
      />
      <Route
        path={DETAILS_ROUTE_CONFIGURATION_RELATIVE_PATH}
        element={<KanbanDetails $={$gs.$detailsView} $gs={$gs} addKanbanInNavbar />}
      />
      <Route
        path={WORKSHOP_DASHBOARD_RELATIVE_PATH}
        element={<AdminWorkshopImplantation $gs={$gs} />}
      />
      <Route
        path={USERS_ADMIN_RELATIVE_PATH}
        element={
          <ProtectedRoute redirectTo={DISPLAY_FULL_PATH} giveWay={canAccessAdminUsers}>
            <UsersAdmin $gs={$gs} />
          </ProtectedRoute>
        }
      />
      <Route
        path={ADMIN_CONTRACTS_RELATIVE_PATH}
        element={
          <ProtectedRoute redirectTo={DISPLAY_FULL_PATH} giveWay={canAccessAdminContracts}>
            <AdminContracts $gs={$gs} />
          </ProtectedRoute>
        }
      />
      <Route
        path={ADMIN_SCHEDULED_TASKS_RELATIVE_PATH}
        element={
          <ProtectedRoute redirectTo={DISPLAY_FULL_PATH} giveWay={canAccessAdminScheduledTasks}>
            <AdminScheduledTasks $gs={$gs} />
          </ProtectedRoute>
        }
      />
      <Route
        path={ADMIN_CAR_ELEMENTS_RELATIVE_PATH}
        element={
          <ProtectedRoute redirectTo={DISPLAY_FULL_PATH} giveWay={canAccessAdminCarElement}>
            <AdminCarElements $gs={$gs} />
          </ProtectedRoute>
        }
      />
      <Route
        path={ADMIN_CUSTOMERS_RELATIVE_PATH}
        element={
          <ProtectedRoute redirectTo={DISPLAY_FULL_PATH} giveWay={canAccessAdminCustomers}>
            <AdminCustomers $gs={$gs} />
          </ProtectedRoute>
        }
      />
      <Route
        path={ADMIN_WORKFLOWS_RELATIVE_PATH}
        element={
          <ProtectedRoute redirectTo={DISPLAY_FULL_PATH} giveWay={canAccessAdminWorkflow}>
            <AdminWorkflow $gs={$gs} />
          </ProtectedRoute>
        }
      />
      <Route
        path={ADMIN_PACKAGE_DEALS_RELATIVE_PATH}
        element={
          <ProtectedRoute redirectTo={DISPLAY_FULL_PATH} giveWay={canAccessAdminPackageDealDescs}>
            <AdminPackageDealDescs $gs={$gs} />
          </ProtectedRoute>
        }
      />
      <Route
        path={ADMIN_SPAREPARTS_PROVIDERS_RELATIVE_PATH}
        element={
          <ProtectedRoute
            redirectTo={DISPLAY_FULL_PATH}
            giveWay={canAccessAdminSparepartsProviders}
          >
            <AdminSparePartsProviders $gs={$gs} />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to={DISPLAY_FULL_PATH} />} />
    </Routes>
  );
}
