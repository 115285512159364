import { useMemo } from 'react';
import type { Kanban } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { kanbanHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../state/typings/store.js';

export function useGetComputedIconFromKanban(
  $gs: GlobalStoreStateSelector<Store>,
  kanban: Kanban | undefined
): string | undefined {
  const siteComputeIconFunction = useGetState($gs.$siteConfiguration.$computeIconFunction);
  return useMemo(() => {
    const computeIconFunction =
      kanbanHelpers.getComputeIconFromKanbanFunction(siteComputeIconFunction);
    return isTruthy(computeIconFunction) ? computeIconFunction(kanban) : undefined;
  }, [kanban, siteComputeIconFunction]);
}
