import type {
  CarElement,
  Kanban,
  PackageDeal,
  PackageDealDesc,
  Sequence,
} from '@stimcar/libs-base';
import {
  packageDealCreation,
  packageDealDescValidation,
  packageDealHelpers,
} from '@stimcar/libs-base';
import { applyPayload, isTruthy } from '@stimcar/libs-kernel';
import type { PurchaseOrdersChanges } from './purchaseOrderChanges.js';

function isAllocatedToReactivatedPurchaseOrder(
  packageDeal: PackageDeal,
  purchaseOrderToCreateIds: readonly string[]
): boolean {
  return (
    isTruthy(packageDeal.purchaseOrderId) &&
    purchaseOrderToCreateIds.includes(packageDeal.purchaseOrderId) &&
    isTruthy(packageDeal.deleted) &&
    packageDeal.deleted
  );
}

function isAllocatedToDeletedPurchaseOrder(
  packageDeal: PackageDeal,
  purchaseOrderToDeleteIds: readonly string[]
): boolean {
  return (
    isTruthy(packageDeal.purchaseOrderId) &&
    purchaseOrderToDeleteIds.includes(packageDeal.purchaseOrderId)
  );
}

export function updatePkgDealsForPurchaseOrder(
  originalKanban: Kanban,
  { creations, updates, deletions }: PurchaseOrdersChanges,
  linearizedWorkflow: readonly string[],
  lastValidationStandPosition: number,
  pkgDealDescsByCode: Map<string, PackageDealDesc>,
  carElementById: Map<string, CarElement>,
  sequence: Sequence
): Kanban {
  const purchaseOrderIdsToUpdate = updates.map(({ id }) => id);
  const purchaseOrderIdsToDelete = deletions.map(({ id }) => id);
  const updatedOriginalPackageDeals = originalKanban.packageDeals.map((packageDeal) => {
    if (isAllocatedToReactivatedPurchaseOrder(packageDeal, purchaseOrderIdsToUpdate)) {
      return {
        ...packageDeal,
        deleted: false,
      };
    }
    if (isAllocatedToDeletedPurchaseOrder(packageDeal, purchaseOrderIdsToDelete)) {
      return {
        ...packageDeal,
        deleted: true,
      };
    }
    return packageDeal;
  });

  const { pkgDealDescCodesForPurchaseOrder, sparePartManagementType, roundPriceTo } =
    originalKanban.contract.configuration;

  const { createdPackageDeals, issues } = packageDealCreation.createNonExistingPackageDeals(
    pkgDealDescCodesForPurchaseOrder[originalKanban.workflowId],
    updatedOriginalPackageDeals,
    sequence,
    pkgDealDescsByCode,
    carElementById,
    sparePartManagementType,
    linearizedWorkflow,
    lastValidationStandPosition,
    roundPriceTo,
    creations
  );
  packageDealDescValidation.throwErrorIfPackageDealDescValidationIssues(issues);
  const createdPackageDealsWithExpressionComputation =
    packageDealHelpers.updateAllPackageDealsExpressionComputations(
      {
        ...originalKanban,
        packageDeals: createdPackageDeals,
      },
      true
    );

  const updatedPackageDeals = [
    ...updatedOriginalPackageDeals,
    ...createdPackageDealsWithExpressionComputation,
  ];

  return {
    ...originalKanban,
    packageDeals: updatedPackageDeals,
  };
}

export function updatePurchaseOrders(
  originalKanban: Kanban,
  purchaseOrdersChanges: PurchaseOrdersChanges
): Kanban {
  if (isTruthy(purchaseOrdersChanges.minimalPayload)) {
    return applyPayload(originalKanban, {
      purchaseOrders: purchaseOrdersChanges.minimalPayload,
    });
  }
  return originalKanban;
}
