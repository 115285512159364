import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, StandAchievements, WithProgress } from '@stimcar/libs-base';
import { enumerate } from '@stimcar/libs-base';
import { FaIcon } from '@stimcar/libs-uitoolkit';

interface StandAchievementsProps {
  readonly standId: string;
  readonly achievements: StandAchievements;
  readonly size: number;
  readonly kanbans: readonly WithProgress<Kanban>[];
  readonly showTotalEngagedRevenueAndRemainingWorkload?: boolean;
}

export function StandAchievementsTable({
  standId,
  achievements,
  size,
  kanbans,
  showTotalEngagedRevenueAndRemainingWorkload = false,
}: StandAchievementsProps): JSX.Element {
  const [t] = useTranslation(['display']);
  const toReworkList = enumerate(achievements.kanbansToRework.map((k) => k.license));
  const toReworkCount = achievements.kanbansToRework.length;
  const { operationDoneWorkload, kanbanProgressesSum } = achievements;
  const doneList = enumerate(achievements.kanbansDone.map((k) => k.license));
  const doneCount = achievements.kanbansDone.length;
  const totalEngagedRevenue =
    kanbans.reduce<number>((p, c) => p + c.globalProgress.operationDoneRevenue, 0) / 1000;

  const workloadDonePercentageOrBrutValue: string = useMemo(() => {
    if (Number.isNaN(operationDoneWorkload)) {
      return t('standAchievementsTable.NaNMsg');
    }
    return operationDoneWorkload.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    });
  }, [operationDoneWorkload, t]);

  const totalRevenuColor = useMemo(() => {
    if (totalEngagedRevenue > 20) {
      return 'has-text-danger';
    }
    if (totalEngagedRevenue > 10) {
      return 'has-text-warning';
    }
    return '';
  }, [totalEngagedRevenue]);

  const remainingWorkload = useMemo(
    () =>
      kanbans.reduce((p, c) => {
        const standProgress = c.progressByStand[standId];
        const { operationTotalWorkload, operationDoneWorkload } = standProgress;
        return p + operationTotalWorkload - operationDoneWorkload;
      }, 0),
    [kanbans, standId]
  );

  return (
    <>
      <div className="has-text-centered">
        {toReworkCount > 0 && (
          <>
            <span
              className="has-text-danger"
              title={toReworkList}
              style={{ fontSize: `${40 * size}px`, lineHeight: '1em' }}
            >
              {toReworkCount}
            </span>
            <FaIcon
              id="undo-alt"
              additionalClass="has-text-danger"
              tooltip={toReworkList}
              size={(40 * size) / 1.5}
            />
          </>
        )}
        <span
          className={doneCount > 0 ? 'has-text-success' : 'has-text-grey'}
          title={doneList}
          style={{ fontSize: `${120 * size}px`, lineHeight: '1em' }}
        >
          {doneCount}
        </span>
        <FaIcon
          id="arrow-circle-right"
          additionalClass={doneCount > 0 ? ' has-text-success' : ' has-text-grey'}
          tooltip={doneList}
          size={(120 * size) / 2}
        />
      </div>
      <div className="has-text-centered">
        <span
          className="has-text-grey"
          style={{ fontSize: `${90 * size}px`, lineHeight: '1em', marginRight: `${20 * size}px` }}
        >
          {Number.isNaN(kanbanProgressesSum)
            ? t('standAchievementsTable.NaNMsg')
            : kanbanProgressesSum.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              })}
        </span>
        <span
          className="has-text-grey-dark"
          style={{ fontSize: `${60 * size}px`, lineHeight: '1em' }}
        >
          {workloadDonePercentageOrBrutValue}
        </span>
      </div>
      {showTotalEngagedRevenueAndRemainingWorkload && (
        <>
          <div className="has-text-centered">
            <span
              className="has-text-gray"
              style={{ fontSize: `${60 * size}px`, lineHeight: '1em' }}
            >
              {`${remainingWorkload.toFixed(1)}h`}
            </span>
            <FaIcon id="weight-hanging" size={60 * size} additionalClass="m-l-xs" />
          </div>
          <div className={`has-text-centered${totalRevenuColor ? ` ${totalRevenuColor}` : ''}`}>
            <span
              className="has-text-gray"
              style={{ fontSize: `${80 * size}px`, lineHeight: '1em' }}
            >
              {Number.isNaN(totalEngagedRevenue)
                ? t('standAchievementsTable.NaNMsg')
                : totalEngagedRevenue.toFixed(1)}
            </span>
            <FaIcon id="balance-scale-right" size={60 * size} additionalClass="m-l-xs" />
          </div>
        </>
      )}
    </>
  );
}
