import type { i18n } from 'i18next';
import { i18nHelpers } from '@stimcar/libs-base';
import bulmaFR from './bulma/i18n/bulma.fr.json';
import customFR from './custom/i18n/custom.fr.json';
import reactGlobalsFR from './i18n/reactglobals.fr.json';

export const loadUIToolkitResourceBundles = (i18n: i18n) => {
  i18n.addResourceBundle('fr', 'reactglobals', reactGlobalsFR);
  i18n.addResourceBundle('fr', 'bulma', bulmaFR);
  i18n.addResourceBundle('fr', 'custom', customFR);
  i18nHelpers.loadCommonI18NResourcesBundle(i18n);
};
