import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';

export type AdminExportMarketplaceInvoicingFormState = {
  readonly startDate: number;
  readonly endDate: number;
};

export type AdminExportMarketplaceInvoicingDialogState =
  FormWithValidationState<AdminExportMarketplaceInvoicingFormState> & {
    readonly active: boolean;
  };

export const EMPTY_ADMIN_EXPORT_MARKETPLACE_INVOICING_DIALOG_STATE: AdminExportMarketplaceInvoicingDialogState =
  {
    active: false,
    formData: {
      startDate: NaN,
      endDate: NaN,
      warnings: {},
    },
    formSubmitClickedOnce: false,
    formSubmitted: false,
  };
