import type { JSX } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ProdStatsRanges } from '@stimcar/core-libs-common';
import type { PackageDealCategory } from '@stimcar/libs-base';
import type {
  ActionContext,
  GlobalStoreStateSelector,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { CoreBackendRoutes, globalHelpers } from '@stimcar/libs-base';
import { Logger } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useArrayItemSelector,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import { Button, FaIcon } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { RadioButtonsFormField } from '../../../lib/bulma/form/RadioButtonsFormField.js';
import { SwitchFormField } from '../../../lib/bulma/form/SwitchFormField.js';
import type { ShiftProgressDisplayState, ShiftProgressRange } from './typings/store.js';
import { AheadBehindTargetDisplayMode } from './AheadBehindTargetDisplayMode.js';
import { ProdAchievementsDisplayMode } from './ProdAchievementsDisplayMode.js';
import {
  EMPTY_SHIFT_PROGRESS_DISPLAY_STATE,
  SHIFT_PROGRESS_DIPLAY_MODES,
} from './typings/store.js';

const log: Logger = Logger.new(import.meta.url);

const EXP_CATEGORY: PackageDealCategory = 'EXP';

const MECA_CARRO_AUTRE_CATEGORIES: readonly PackageDealCategory[] = ['CARRO', 'MECA', 'AUTRE'];

const STATS_MODES = ['EXP', 'TUBE'];

const SIZES = [1, 2, 3, 4, 5, 6, 7, 8, 9];

async function updateShiftProgressStatsAction({
  actionDispatch,
  httpClient,
  getState,
  getGlobalState,
  loggerClient,
}: ActionContext<Store, ShiftProgressDisplayState>) {
  // Only refresh the stats if the session is online
  if (getGlobalState().session.isOnline) {
    const { statsMode, includeSubcontractors } = getState();

    // Select the right shift configuration
    const { startHour, startMinute, collaboratorsCount, expectedWorkloadByCollaborator } =
      statsMode === 'EXP'
        ? getGlobalState().siteConfiguration.shiftsConfiguration.expertise
        : getGlobalState().siteConfiguration.shiftsConfiguration.workshop;

    try {
      const [shift1Start, shift2Start, shift3Start] = globalHelpers.computeShiftHours(startHour);
      const lastShiftStart = globalHelpers.computePreviousShiftStart(startHour, startMinute);
      let shiftCollaboratorsCount = 0;
      switch (lastShiftStart.getHours()) {
        case shift1Start:
          shiftCollaboratorsCount = collaboratorsCount.shift1;
          break;
        case shift2Start:
          shiftCollaboratorsCount = collaboratorsCount.shift2;
          break;
        case shift3Start:
          shiftCollaboratorsCount = collaboratorsCount.shift3;
          break;
        default:
          loggerClient.error(
            'ShiftProgress',
            'Last shift does not correspond to any shift hour',
            undefined,
            [
              {
                key: 'lastShiftStart',
                value: lastShiftStart.toLocaleString(),
              },
              {
                key: 'shift1Start',
                value: shift1Start.toLocaleString(),
              },
              {
                key: 'shift2Start',
                value: shift2Start.toLocaleString(),
              },
              {
                key: 'shift3Start',
                value: shift3Start.toLocaleString(),
              },
              {
                key: 'shiftsConfiguration',
                value: JSON.stringify(
                  getGlobalState().siteConfiguration.shiftsConfiguration,
                  null,
                  2
                ),
              },
            ]
          );
          log.error('lastShiftStart:', lastShiftStart);
          log.error('shiftStarts:', [shift1Start, shift2Start, shift3Start]);
          throw new Error('Last shift does not correspond to any shift hour');
      }
      // Move back one day
      const from0 = new Date(lastShiftStart.getTime());
      from0.setDate(from0.getDate() - 1);
      // Move forward 8H (following shift)
      const from1 = new Date(from0);
      from1.setHours(from1.getHours() + 8);
      // Move forward 8H (following shift)
      const from2 = new Date(from1);
      from2.setHours(from2.getHours() + 8);
      const froms = [from0 /* -24H */, from1 /* -16H */, from2 /* -8H */, lastShiftStart /* 0H */];

      // Compute selected categories
      const categories: readonly PackageDealCategory[] =
        statsMode === 'EXP' ? [EXP_CATEGORY] : MECA_CARRO_AUTRE_CATEGORIES;

      // If no category is selected, simply reset the state
      if (categories.length === 0) {
        actionDispatch.applyPayload({
          lastShifts: EMPTY_SHIFT_PROGRESS_DISPLAY_STATE.lastShifts,
          dayProgressesSum: 0,
          dayKanbansDone: 0,
          dayWorkload: 0,
          expectedCurrentShiftWorkload: 0,
        });
      } else {
        // Otherwise query elastic
        const { ranges } = await httpClient.httpGetAsJson<ProdStatsRanges>(
          `${CoreBackendRoutes.SHIFT_PROGRESS_BI_STATS}?${categories.map((cat) => `category=${cat}`).join('&')}&includeSubcontractors=${includeSubcontractors}${froms.map((from) => `&from=${from.getTime()}`).join('&')}`
        );

        // Save shift ranges
        actionDispatch.setProperty(
          'lastShifts',
          ranges.map(({ from, workload, to, kanbansDone, progressesSum }, index) => ({
            id: String(index),
            from,
            to,
            workload,
            kanbansDone,
            progressesSum,
          })) as [ShiftProgressRange, ShiftProgressRange, ShiftProgressRange, ShiftProgressRange]
        );

        // Compute expected workload
        const totalExpectedShiftWorkload = shiftCollaboratorsCount * expectedWorkloadByCollaborator;
        const dayProgress = (Date.now() - lastShiftStart.getTime()) / (8 * 60 * 60 * 1000);
        actionDispatch.applyPayload({
          expectedCurrentShiftWorkload: totalExpectedShiftWorkload * dayProgress,
        });

        // Compute day totals
        const today = new Date().getDate();
        const todayRanges = ranges.filter(({ from }) => new Date(from).getDate() === today);
        actionDispatch.applyPayload({
          dayKanbansDone: todayRanges.reduce((p, c) => p + c.kanbansDone, 0),
          dayProgressesSum: todayRanges.reduce((p, c) => p + c.progressesSum, 0),
          dayWorkload: todayRanges.reduce((p, c) => p + c.workload, 0),
        });
      }
    } catch (ignored) {
      // Don't fail if an HTTP error occurs
      log.error('Unexpected error during stats refresh', ignored);
    }
  }
}

async function persistStateActionReloadStats({
  getState,
  actionDispatch,
}: ActionContext<Store, ShiftProgressDisplayState>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { statsMode, displayMode, size, includeSubcontractors, active } = getState();
  localStorage.setItem(
    LocalStorageKeys.SHIFT_PROGRESS_DISPLAY,
    JSON.stringify({ statsMode, displayMode, size, includeSubcontractors, active })
  );
  await actionDispatch.exec(updateShiftProgressStatsAction);
}

interface Props {
  readonly $gs: GlobalStoreStateSelector<Store>;
}

export function ShiftProgressDisplay({ $gs }: Props): JSX.Element {
  const [t] = useTranslation(['display']);
  const { $shiftProgressDisplay } = $gs.$displayView;

  const loadInitialStateActionCallback = useActionCallback(
    function loadInitialStateAction({ actionDispatch }) {
      const persistedState = localStorage.getItem(LocalStorageKeys.SHIFT_PROGRESS_DISPLAY);
      if (persistedState) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        actionDispatch.applyPayload({
          ...JSON.parse(persistedState),
        });
      }
    },
    [],
    $shiftProgressDisplay
  );

  useEffect((): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadInitialStateActionCallback();
  }, [loadInitialStateActionCallback]);

  const updateShiftProgressStatsActionCallback = useActionCallback(
    updateShiftProgressStatsAction,
    [],
    $shiftProgressDisplay
  );

  const siteConfiguration = useGetState($gs.$siteConfiguration);

  useEffect(() => {
    // Update the stats if the site configuration changes
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateShiftProgressStatsActionCallback();
  }, [siteConfiguration, updateShiftProgressStatsActionCallback]);

  useEffect((): (() => void) => {
    // Update stats once (otherwise, the interval wil only schedule the first
    // execution in a few seconds)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateShiftProgressStatsActionCallback();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const intervalId = setInterval(updateShiftProgressStatsActionCallback, 15000);
    return () => {
      clearInterval(intervalId);
    };
  }, [updateShiftProgressStatsActionCallback]);

  const $shift0 = useArrayItemSelector($shiftProgressDisplay.$lastShifts, '0');
  const $shift1 = useArrayItemSelector($shiftProgressDisplay.$lastShifts, '1');
  const $shift2 = useArrayItemSelector($shiftProgressDisplay.$lastShifts, '2');
  const $currentShift = useArrayItemSelector($shiftProgressDisplay.$lastShifts, '3');

  const size = useGetState($shiftProgressDisplay.$size);

  const displayMode = useGetState($shiftProgressDisplay.$displayMode);

  const persistStateActionCallback = useActionCallback(
    persistStateActionReloadStats,
    [],
    $shiftProgressDisplay
  );

  const $shiftProgressDisplayWithChangeTrigger = useSelectorWithChangeTrigger(
    $shiftProgressDisplay,
    persistStateActionCallback
  );

  const shiftProgressDisplayModeEntries = useMemo(() => {
    return SHIFT_PROGRESS_DIPLAY_MODES.map(
      (id): FormFieldEntry<string> => ({
        id,
        label: t(`shiftProgressDisplay.displayModes.${id}`),
      })
    );
  }, [t]);

  const onToggleShowHideActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      const active = getState();
      actionDispatch.setValue(!active);
    },
    [],
    $shiftProgressDisplayWithChangeTrigger.$active
  );

  const active = useGetState($shiftProgressDisplayWithChangeTrigger.$active);

  return (
    <>
      <div className="columns">
        <div className="column is-narrow">
          <Button onClick={onToggleShowHideActionCallback} iconId="gear" size="small" />
          {active && (
            <>
              <RadioButtonsFormField
                label={t('shiftProgressDisplay.size')}
                $={$shiftProgressDisplayWithChangeTrigger.$size}
                id="size"
                entries={SIZES}
                radioGroupLayout="vertical"
              />
              <RadioButtonsFormField
                label={t('shiftProgressDisplay.statsMode')}
                $={$shiftProgressDisplayWithChangeTrigger.$statsMode}
                id="statsMode"
                entries={STATS_MODES}
                radioGroupLayout="vertical"
              />
              <RadioButtonsFormField
                label={t('shiftProgressDisplay.displayMode')}
                $={$shiftProgressDisplayWithChangeTrigger.$displayMode}
                id="displayMode"
                entries={shiftProgressDisplayModeEntries}
                radioGroupLayout="vertical"
              />
              <SwitchFormField
                label={t('shiftProgressDisplay.includeSubcontractors')}
                $={$shiftProgressDisplayWithChangeTrigger.$includeSubcontractors}
                switchId="includeSubcontractors"
              />
            </>
          )}
        </div>
        <div className="column has-text-centered">
          {displayMode === 'aheadBehindTarget' ? (
            <AheadBehindTargetDisplayMode $={$shiftProgressDisplay} />
          ) : (
            <ProdAchievementsDisplayMode $={$shiftProgressDisplay} />
          )}
        </div>
        <div className="column is-narrow has-text-centered">
          <table className="has-text-centered">
            <LastShiftProgressStat size={size} $={$currentShift} displayMode={displayMode} />
            <LastShiftProgressStat size={size} $={$shift2} displayMode={displayMode} />
            <LastShiftProgressStat size={size} $={$shift1} displayMode={displayMode} />
            <LastShiftProgressStat size={size} $={$shift0} displayMode={displayMode} />
          </table>
        </div>
      </div>
    </>
  );
}

function toTimeString(date: Date): string {
  return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
}
type LastShiftProgressStatProps = Pick<ShiftProgressDisplayState, 'displayMode'> & {
  readonly $: StoreStateSelector<Store, ShiftProgressRange>;
  readonly size: number;
};

function LastShiftProgressStat({ $, size, displayMode }: LastShiftProgressStatProps): JSX.Element {
  const fromTimestamp = useGetState($.$from);
  const toTimestamp = useGetState($.$to);
  const shiftTime = useMemo(() => {
    const from = new Date(fromTimestamp);
    const to = new Date(toTimestamp!);
    return `${toTimeString(from)}➡️${toTimeString(to)}`;
  }, [fromTimestamp, toTimestamp]);
  const shiftWorkload = useGetState($.$workload);
  const shiftKanbansDone = useGetState($.$kanbansDone);

  return (
    <>
      <tr>
        <td>
          <span style={{ fontSize: `${7 * size}px`, lineHeight: '1em' }}>{shiftTime}</span>
        </td>
      </tr>
      <tr>
        <td align="right">
          <span
            className={shiftKanbansDone > 0 ? 'has-text-success' : 'has-text-grey'}
            style={{ fontSize: `${45 * size}px`, lineHeight: '1em' }}
          >
            {displayMode === 'achievements' ? (
              <>
                {shiftKanbansDone}
                <FaIcon id="arrow-circle-right" size={15 * size} />
              </>
            ) : (
              <>{globalHelpers.roundTo(shiftWorkload, 0)}</>
            )}
          </span>
        </td>
      </tr>
    </>
  );
}
