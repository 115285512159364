/* eslint-disable jsx-a11y/control-has-associated-label */
import type { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDeal, SparePart } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  carElementHelpers,
  enumerate,
  kanbanHelpers,
  nonDeleted,
  packageDealHelpers,
  sortingHelpers,
} from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  ColumnedCarviewAndAttachmentThumbnailsDisplayer,
  MultilineValueTableCellContent,
  PackageDealRecommendationLogo,
  ScrollableTableComponent,
  TableActionHeaderCell,
  ToggleNestedButton,
  TruncableTableTd,
  TruncableTableTh,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { OperatorViewState } from '../../typings/store.js';
import { TableSortableHeaderComponent } from '../../../../lib/components/TableSortableHeaderComponent.js';
import { useGetCarViewCategoryLabel } from '../../../../utils/carViewCategoriesTranslationHooks.js';
import { useComputeAttachmentUrl } from '../../../utils/useComputeAttachmentUrl.js';

function togglePackageDealStatusAction(
  { actionDispatch, getState }: ActionContext<Store, OperatorViewState>,
  id: string
): void {
  const { operatedKanban } = getState();
  const { packageDealsWorkingCopy } = getState().validateExpertiseViewState;

  const newPackageDeals = kanbanHelpers.togglePackageDealAvailability(
    { ...nonnull(operatedKanban), packageDeals: packageDealsWorkingCopy },
    id
  );

  actionDispatch.scopeProperty('validateExpertiseViewState').reduce((initial) => {
    return {
      ...initial,
      packageDealsWorkingCopy: newPackageDeals,
    };
  });
}

const allPackageDealsAreSelected = (packageDeals: readonly PackageDeal[]): boolean => {
  return (
    packageDeals.filter(nonDeleted).find((pkg) => packageDealHelpers.isPackageDealCanceled(pkg)) ===
    undefined
  );
};

function toggleAllPackageDealsStatusAction({
  actionDispatch,
  getState,
}: ActionContext<Store, OperatorViewState>): void {
  const { operatedKanban } = getState();
  const { packageDealsWorkingCopy } = getState().validateExpertiseViewState;

  const areAllPackagedealsSelected = allPackageDealsAreSelected(packageDealsWorkingCopy);

  const newPackageDeals = kanbanHelpers.setAllPackageDealsAvailabilityTo(
    { ...nonnull(operatedKanban), packageDeals: packageDealsWorkingCopy },
    areAllPackagedealsSelected ? 'canceled' : 'available'
  );

  actionDispatch.scopeProperty('validateExpertiseViewState').reduce((initial) => {
    return {
      ...initial,
      packageDealsWorkingCopy: newPackageDeals,
    };
  });
}

interface ValidationPackageDealListItemProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, OperatorViewState>;
  readonly packageDeal: PackageDeal;
  readonly t: TFunction;
  readonly kanbanId: string;
  readonly disable: boolean;
}

function ValidationPackageDealListItem({
  $,
  $gs,
  packageDeal,
  t,
  kanbanId,
  disable,
}: ValidationPackageDealListItemProps): JSX.Element {
  const { id, carElement, estimateComment, tags, price } = packageDeal;
  const togglePackageDealStatusActionCallback = useActionCallback(
    async ({ actionDispatch }) => actionDispatch.exec(togglePackageDealStatusAction, id),
    [id],
    $
  );

  const expertiseCategoryLabel = useGetCarViewCategoryLabel(carElement?.category ?? 'MISC');

  const computeAttachmentUrlCallback = useComputeAttachmentUrl($gs);

  const isChecked = useMemo((): boolean => {
    return packageDealHelpers.isPackageDealAvailable(packageDeal);
  }, [packageDeal]);

  const isStatusToggleForbidden = useMemo((): boolean => {
    return (
      disable || (isChecked && packageDealHelpers.isPackageDealStartedOrUnremovable(packageDeal))
    );
  }, [disable, isChecked, packageDeal]);

  const expandedPackageDealIds = useGetState($.$validateExpertiseViewState.$expandedPackageDealIds);

  return (
    <>
      <tr className={packageDeal.status === 'available' ? 'has-text-weight-bold' : ''}>
        <td>
          <input
            type="checkbox"
            onChange={togglePackageDealStatusActionCallback}
            checked={isChecked}
            className="m-l-xs"
            title={
              isChecked
                ? t('expertiseValidation.packageDealList.tooltip.unCheck')
                : t('expertiseValidation.packageDealList.tooltip.check')
            }
            disabled={isStatusToggleForbidden}
          />
        </td>
        <TruncableTableTd className="has-text-centered">
          {carElementHelpers.getCarElementIndexToDisplayWithPadding(carElement)}
        </TruncableTableTd>
        <TruncableTableTd>{expertiseCategoryLabel}</TruncableTableTd>
        <TruncableTableTd>
          <ToggleNestedButton
            id={id}
            hasChildren={packageDeal.attachments ? packageDeal.attachments.length > 0 : false}
            $expandedIds={$.$validateExpertiseViewState.$expandedPackageDealIds}
            label={
              carElement?.label ??
              t('expertiseValidation.packageDealList.miscPackageDealCarElementPlaceholder')
            }
          />
        </TruncableTableTd>
        <td>
          <PackageDealRecommendationLogo recommendedByExpert={packageDeal.recommendedByExpert} />
        </td>
        <TruncableTableTd>
          {packageDealHelpers.getPackageDealDisplayedLabel(packageDeal)}
        </TruncableTableTd>
        <TruncableTableTd>{enumerate(tags)}</TruncableTableTd>
        <td />
        <TruncableTableTd>
          <MultilineValueTableCellContent value={estimateComment} />
        </TruncableTableTd>
        <TruncableTableTd className="has-text-right">
          {t('expertiseValidation.packageDealList.price', { amount: price.toFixed(2) })}
        </TruncableTableTd>
      </tr>
      {expandedPackageDealIds.includes(packageDeal.id) && (
        <tr>
          <td colSpan={10}>
            <ColumnedCarviewAndAttachmentThumbnailsDisplayer
              category={carElement?.category ?? 'MISC'}
              selectedShapes={carElement?.shapes ? carElement.shapes : []}
              attachments={packageDeal.attachments ?? []}
              computeAttachmentUrl={computeAttachmentUrlCallback}
              $imageModal={$gs.$imageModal}
              kanbanId={kanbanId}
              photoHeight={125}
            />
          </td>
        </tr>
      )}
    </>
  );
}

interface SparePartItemProps {
  readonly sparePart: SparePart;
  readonly packageDeal: PackageDeal;
  readonly t: TFunction;
}

function SparePartItem({ sparePart, packageDeal, t }: SparePartItemProps): JSX.Element {
  const computedLabel = useMemo(() => {
    return packageDealHelpers.getSparePartDisplayedLabel(sparePart, packageDeal);
  }, [sparePart, packageDeal]);
  return (
    <tr>
      <td />
      <td />
      <td />
      <td />
      <td />
      <TruncableTableTd>{`- ${computedLabel}`}</TruncableTableTd>
      <td />
      <TruncableTableTd className="has-text-right">{sparePart.quantity}</TruncableTableTd>
      <TruncableTableTd>
        <MultilineValueTableCellContent value={sparePart.commentForCustomer} />
      </TruncableTableTd>
      <TruncableTableTd className="has-text-right">
        {t('expertiseValidation.packageDealList.price', {
          amount: sparePart.price.toFixed(2),
        })}
      </TruncableTableTd>
    </tr>
  );
}

export function ValidationPackageDealListComponent({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('operators');
  const { $operatorView } = $gs;
  const { $validateExpertiseViewState } = $operatorView;

  const workStatus = useGetState($operatorView.$workStatus);
  const operatedKanban = useGetState($operatorView.$operatedKanban);

  const sortBy = useGetState($validateExpertiseViewState.$sort.$by);
  const sortDirection = useGetState($validateExpertiseViewState.$sort.$direction);
  const packageDealsWorkingCopy = useGetState($validateExpertiseViewState.$packageDealsWorkingCopy);

  const sortedPackageDeals = useMemo((): PackageDeal[] => {
    const packageDeals = nonnull(packageDealsWorkingCopy).filter(nonDeleted).slice();
    let sortFunction: (o1: PackageDeal, o2: PackageDeal) => number;
    switch (sortBy) {
      case 'carElementIndex':
        sortFunction =
          sortingHelpers.createSortPackageDealByCarElementIndexThenLabelFunction(sortDirection);
        break;
      case 'carElement':
        sortFunction =
          sortingHelpers.createSortPackageDealsByCarElementLabelFunction(sortDirection);
        break;
      case 'category':
        sortFunction = sortingHelpers.createSortPackageDealsByCarElementCategoryFunction(
          sortDirection,
          t
        );
        break;
      case 'label':
        sortFunction = sortingHelpers.createSortByStringField<PackageDeal>(sortDirection, sortBy);
        break;
      case 'price':
        sortFunction = sortingHelpers.createSortPackageDealsByPriceFunction(sortDirection);
        break;
      default:
        return packageDeals;
    }
    return packageDeals.sort(sortFunction);
  }, [sortBy, sortDirection, packageDealsWorkingCopy, t]);

  const toggleAllPackageDealsStatusActionCallback = useActionCallback(
    async ({ actionDispatch }) => actionDispatch.exec(toggleAllPackageDealsStatusAction),
    [],
    $operatorView
  );

  const allPackageDealsAreAvailable = useMemo((): boolean => {
    return allPackageDealsAreSelected(packageDealsWorkingCopy);
  }, [packageDealsWorkingCopy]);

  return (
    <ScrollableTableComponent
      isTruncable
      tableClassName="table is-bordered is-striped is-narrow is-fullwidth is-hoverable"
    >
      <thead>
        <tr>
          <th style={{ width: '2.25em', textAlign: 'right' }}>
            <input
              type="checkbox"
              onChange={toggleAllPackageDealsStatusActionCallback}
              checked={allPackageDealsAreAvailable}
              title={
                allPackageDealsAreAvailable
                  ? t('expertiseView.packageDealTable.tooltip.unCheckAll')
                  : t('expertiseView.packageDealTable.tooltip.checkAll')
              }
            />
          </th>
          <TableSortableHeaderComponent
            style={{ width: '5%' }}
            content={t('expertiseValidation.packageDealList.carElementIndexTitle')}
            tooltip={t('expertiseValidation.packageDealList.carElementIndexTitleTooltip')}
            isTruncable
            centerLabel={false}
            sortedField="carElementIndex"
            $sort={$validateExpertiseViewState.$sort}
          />
          <TableSortableHeaderComponent
            style={{ width: '7%' }}
            content={t('expertiseValidation.packageDealList.category')}
            tooltip={t('expertiseValidation.packageDealList.categoryTooltip')}
            isTruncable
            centerLabel={false}
            sortedField="category"
            $sort={$validateExpertiseViewState.$sort}
          />
          <TableSortableHeaderComponent
            content={t('expertiseValidation.packageDealList.carElement')}
            style={{ width: '15%' }}
            isTruncable
            centerLabel={false}
            sortedField="carElement"
            $sort={$validateExpertiseViewState.$sort}
          />
          <TableActionHeaderCell />
          <TableSortableHeaderComponent
            content={t('expertiseValidation.packageDealList.label')}
            isTruncable
            centerLabel={false}
            sortedField="label"
            $sort={$validateExpertiseViewState.$sort}
          />
          <TableSortableHeaderComponent
            content={t('expertiseValidation.packageDealList.tags')}
            style={{ width: '10%' }}
            isTruncable
            centerLabel={false}
            sortedField="tag"
            $sort={$validateExpertiseViewState.$sort}
          />
          <TruncableTableTh style={{ width: '6%' }}>
            {t('expertiseValidation.packageDealList.quantity')}
          </TruncableTableTh>
          <TruncableTableTh>
            {t('expertiseValidation.packageDealList.estimateComment')}
          </TruncableTableTh>
          <TableSortableHeaderComponent
            style={{ width: '7%' }}
            content={t('expertiseValidation.packageDealList.priceTitle')}
            isTruncable
            centerLabel={false}
            sortedField="price"
            $sort={$validateExpertiseViewState.$sort}
          />
        </tr>
      </thead>
      <tbody>
        {sortedPackageDeals.map(
          (packageDeal: PackageDeal): JSX.Element => (
            <React.Fragment key={packageDeal.id}>
              <ValidationPackageDealListItem
                key={packageDeal.id}
                $={$operatorView}
                $gs={$gs}
                packageDeal={packageDeal}
                t={t}
                kanbanId={operatedKanban ? operatedKanban.id : ''}
                disable={workStatus !== 'started'}
              />
              {packageDeal.spareParts.filter(nonDeleted).map((sp): JSX.Element => {
                return <SparePartItem sparePart={sp} t={t} key={sp.id} packageDeal={packageDeal} />;
              })}
            </React.Fragment>
          )
        )}
      </tbody>
    </ScrollableTableComponent>
  );
}
