import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment, AttachmentFolder } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, ImageModalState } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { Button } from '@stimcar/libs-uitoolkit';
import type { ComputeAttachmentUrlCallback } from '../../lib/components/attachments/typings/attachment.js';
import type { ShareStore } from '../state/typings/store.js';
import { AttachmentThumbnail } from '../../lib/components/attachments/AttachmentThumbnail.js';
import { DisplayContentOrPlaceholder } from '../../lib/components/misc/DisplayContentOrPlaceholder.js';
import { downloadAndSave } from '../../lib/utils/download.js';

const PHOTO_HEIGHT = 200;

interface Props extends AppProps<ShareStore> {
  readonly shareId: string;
  readonly attachments: readonly Attachment[];
  readonly $imageModal: StoreStateSelector<ShareStore, ImageModalState>;
  readonly computeAttachmentUrlCallback: ComputeAttachmentUrlCallback;
  readonly kanbanId: string;
  readonly attachmentFolder: AttachmentFolder;
}

export function KanbanShareAttachmentsTab({
  $gs,
  shareId,
  attachments,
  $imageModal,
  computeAttachmentUrlCallback,
  kanbanId,
  attachmentFolder,
}: Props): JSX.Element {
  const [t] = useTranslation('share');

  const folderLabel = useMemo(() => {
    return attachmentFolder.label ?? t(`tabs.${attachmentFolder.id}`);
  }, [attachmentFolder, t]);

  const downloadAttachmentsZipCallback = useActionCallback(
    async ({ httpClient }) =>
      await downloadAndSave(
        httpClient,
        CoreBackendRoutes.SHARED_ZIPPED_ATTACHMENT_FOLDER(shareId, 'kanban', attachmentFolder.id),
        `${attachmentFolder.label}.zip`
      ),
    [shareId, attachmentFolder],
    $gs
  );

  return (
    <DisplayContentOrPlaceholder
      displayCondition={attachments.length > 0}
      placeholder={t('attachmentsTab.warning.noAttachment', { folderLabel })}
    >
      <>
        <div className="columns is-multiline no-printing is-vcentered">
          <div className="column is-narrow">
            <div className="buttons">
              <Button
                iconId="download"
                label={t('attachmentsTab.downloadButton')}
                onClick={downloadAttachmentsZipCallback}
              />
            </div>
          </div>
        </div>
        <div className="columns is-multiline">
          {attachments.map(
            (att, index): JSX.Element => (
              <div className="column is-narrow" key={att.id}>
                <AttachmentThumbnail
                  category="kanban"
                  objectId={kanbanId}
                  computeAttachmentUrl={computeAttachmentUrlCallback}
                  size={PHOTO_HEIGHT}
                  attachments={attachments}
                  $imageModal={$imageModal}
                  attachmentIndex={index}
                />
              </div>
            )
          )}
        </div>
      </>
    </DisplayContentOrPlaceholder>
  );
}
