import { isTruthy } from '@stimcar/libs-kernel';
import type { SparePartManagementType } from '../../model/typings/general.js';
import type { PackageDeal, PriceableSparePart, SparePart } from '../../model/typings/kanban.js';
import type { SparePartDesc } from '../../model/typings/packageDealDesc.js';
import {
  EMPTY_SPARE_PART_MANAGED_BY_CUSTOMER,
  EMPTY_SPARE_PART_MANAGED_BY_US,
  EMPTY_SPARE_PART_ORDERED_BY_STIMCAR_FROM_CUSTOMERS_CATALOG,
  SPARE_PARTS_DEFAULT_STAND_ID,
} from '../../model/globalConstants.js';
import { filterReject, nonDeleted } from '../misc.js';

export type SparePartsStatus = 'all' | 'received';

interface ActiveAndDeletedSpareParts {
  readonly activeSpareParts: readonly SparePart[];
  readonly deletedSpareParts: readonly SparePart[];
}

function getActiveAndDeletedSparePartsFromPackageDeals(
  packageDeals: readonly PackageDeal[]
): ActiveAndDeletedSpareParts {
  const activeSpareParts: SparePart[] = [];
  const deletedSpareParts: SparePart[] = [];
  packageDeals.forEach((packageDeal) => {
    const { filtered, rejected } = filterReject(packageDeal.spareParts, nonDeleted);
    activeSpareParts.push(...filtered);
    deletedSpareParts.push(...rejected);
  });
  return { activeSpareParts, deletedSpareParts };
}

function getPackageDealThatHasGivenSparePart(
  packageDeals: readonly PackageDeal[],
  sparePartId: string
): PackageDeal | undefined {
  for (const p of packageDeals) {
    if (isTruthy(p.spareParts)) {
      const givenSparePart = p.spareParts.find((s) => s.id === sparePartId);
      if (isTruthy(givenSparePart)) {
        return p;
      }
    }
  }
  return undefined;
}

function convertSparePartToPriceableSparePart(sparePart: SparePart): PriceableSparePart {
  const {
    id,
    label,
    price,
    deleted,
    managementType,
    quantity,
    provider,
    standId,
    dateOfReception,
    commentForCustomer,
  } = sparePart;
  return {
    id,
    label,
    price,
    deleted,
    managementType,
    commentForCustomer,
    quantity,
    provider,
    dateOfReception,
    standId,
  };
}

function convertSparePartsToPriceableSpareParts(
  spareParts: readonly SparePart[]
): readonly PriceableSparePart[] {
  return spareParts.map((sp): PriceableSparePart => convertSparePartToPriceableSparePart(sp));
}

function getFullyManagedActiveSpareParts<S extends PriceableSparePart>(
  spareParts: readonly S[]
): readonly S[] {
  if (spareParts === undefined) {
    return [];
  }
  return spareParts
    .filter(nonDeleted)
    .filter((sp) => sp.managementType === 'fullyManagedByStimcar');
}

/**
 * Returns for a spare part the price that is invoiced to customer
 * @param providerUnitPrice unit price at which we buy a single spare part from the provider.
 * @param quantity quantity of spare parts.
 * @param marginPercentage the margin percentage to apply on the price (i.e. 0.3)
 * @returns providerUnitPrice multiplied by quantity, with our margin added, and then rounded to the nearest integer multiple of quantity
 */
function computeInvoicedPrice(
  providerUnitPrice: number,
  quantity: number,
  marginPercentage: number
): number {
  // rawPriceWithMargin is rounded to the nearest integer multiple of quantity in order to have an integer for individual invoiced price
  // more specifically: when creating invoices, we have to call the external service (facturation.pro) with the result of this method divided
  // by quantity
  const rawPriceWithMargin = providerUnitPrice * quantity * (1 + marginPercentage);
  return Math.ceil(rawPriceWithMargin / quantity) * quantity;
}

function sumPrice(spareParts: readonly PriceableSparePart[]): number {
  return spareParts.filter(nonDeleted).reduce((acc, current) => acc + current.price, 0);
}

function isReferenced(sparePart: SparePart): boolean {
  return isTruthy(sparePart.dateOfReference);
}

function mandatoryReferenceNotDone(sparePart: SparePart): boolean {
  return (
    !sparePart.deleted &&
    sparePart.managementType !== 'fullyManagedByCustomer' &&
    !isReferenced(sparePart)
  );
}

function getProviderDisplayedLabel(
  provider: string | null,
  managementType: SparePartManagementType,
  labelForCustomerAsProvider: string
): string {
  if (!isTruthy(provider)) {
    if (managementType === 'orderedByStimcarFromCustomersCatalog') {
      return labelForCustomerAsProvider;
    }
    return '';
  }
  return provider;
}

function convertSparePartDescToSparePart(
  sparePartDesc: SparePartDesc,
  sparePartManagementType: SparePartManagementType
): SparePart {
  let emptySparePart: SparePart = EMPTY_SPARE_PART_MANAGED_BY_US;
  if (sparePartManagementType === 'fullyManagedByStimcar') {
    emptySparePart = EMPTY_SPARE_PART_MANAGED_BY_US;
  } else if (sparePartManagementType === 'fullyManagedByCustomer') {
    emptySparePart = EMPTY_SPARE_PART_MANAGED_BY_CUSTOMER;
  } else if (sparePartManagementType === 'orderedByStimcarFromCustomersCatalog') {
    emptySparePart = EMPTY_SPARE_PART_ORDERED_BY_STIMCAR_FROM_CUSTOMERS_CATALOG;
  }

  return {
    ...emptySparePart,
    label: sparePartDesc.label,
  };
}

function hasReceivedAllSpareParts(spareParts: readonly SparePart[]): boolean {
  return spareParts
    .filter(({ standId }) => standId === SPARE_PARTS_DEFAULT_STAND_ID)
    .every(({ dateOfReception }) => isTruthy(dateOfReception));
}

function hasNoRemainingSpareParts(spareParts: readonly SparePart[]): boolean {
  return spareParts.length === 0 || hasReceivedAllSpareParts(spareParts);
}

function hasRemainingMandatoryReferenceToDo(spareParts: readonly SparePart[]): boolean {
  return spareParts.filter(nonDeleted).some((sparePart) => mandatoryReferenceNotDone(sparePart));
}

export const sparePartHelpers = {
  computeInvoicedPrice,
  convertSparePartsToPriceableSpareParts,
  convertSparePartToPriceableSparePart,
  convertSparePartDescToSparePart,
  getActiveAndDeletedSparePartsFromPackageDeals,
  getFullyManagedActiveSpareParts,
  getPackageDealThatHasGivenSparePart,
  getProviderDisplayedLabel,
  hasReceivedAllSpareParts,
  hasNoRemainingSpareParts,
  hasRemainingMandatoryReferenceToDo,
  mandatoryReferenceNotDone,
  sumPrice,
};
