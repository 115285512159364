import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment, AttachmentMetadata, Kanban, UIContract } from '@stimcar/libs-base';
import type {
  StandardPictureConfEntry,
  StandardPictureInfo,
  StandardPictureKey,
} from '@stimcar/libs-kernel';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, ConfirmAttachmentDialogState } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
  sortingHelpers,
} from '@stimcar/libs-base';
import {
  EXTERNAL_STANDARD_PICTURES,
  INTERNAL_STANDARD_PICTURES,
  nonnull,
} from '@stimcar/libs-kernel';
import {
  IconButton,
  useActionCallback,
  useArrayItemSelector,
  useGetState,
} from '@stimcar/libs-uikernel';
import {
  ConfirmAttachmentRemovalDialog,
  downloadAndSave,
  EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE,
} from '@stimcar/libs-uitoolkit';
import type { PictureInputState } from '../../lib/components/typings/store.js';
import type { Store } from '../state/typings/store.js';
import { StandardPictureInput } from '../../lib/components/pictureInput/StandardPictureInput.js';
import { ShowHideBoxContainer } from '../../lib/components/ShowHideContainer.js';
import { appendRegisteredBrowserSessionToken } from '../utils/security.js';
import { useComputeStandardPictureUrl } from '../utils/useComputeAttachmentUrl.js';
import type { MobileDetailsSubPartState, StandardPicturesState } from './typings/store.js';
import { shouldAllowAttachmentDeletion } from './kanbanDetailsUtils.js';

type PictureEntry = [key: string, value: StandardPictureInfo];

function getSortedStandardPicturesToDisplay(
  defaultStandardPictures: Record<StandardPictureKey, StandardPictureInfo>,
  standardPicturesConfiguration: readonly StandardPictureConfEntry[]
): readonly PictureEntry[] {
  const standardPictureKeysToDisplay = standardPicturesConfiguration.map(({ key }) => key);
  return Object.entries(defaultStandardPictures)
    .filter(([key]) => standardPictureKeysToDisplay.includes(key))
    .sort(([, { index: firstIndex }], [, { index: secondIndex }]) =>
      sortingHelpers.compareNumbers(firstIndex, secondIndex, 'DOWN')
    );
}

interface StandardPictureCellProps extends AppProps<Store> {
  readonly kanbanId: string;
  readonly pictureId: string;
  readonly isEditable: boolean;
  readonly translationKey: string;
  readonly $: StoreStateSelector<Store, readonly PictureInputState[]>;
  readonly $confirmAttachmentRemovalDialog: StoreStateSelector<Store, ConfirmAttachmentDialogState>;
}

function StandardPictureCell({
  $,
  $gs,
  kanbanId,
  pictureId,
  isEditable,
  translationKey,
  $confirmAttachmentRemovalDialog,
}: StandardPictureCellProps): JSX.Element {
  const [tStandardPictures] = useTranslation('adminStandardPictures');

  const computeStandardPictureUrlCallback = useComputeStandardPictureUrl($gs);
  const $pictureStateSelector = useArrayItemSelector($, pictureId);
  const placeholderPicturePath = useMemo(() => `img/standard-pictures/${pictureId}`, [pictureId]);
  return (
    <div className="cell is-col-span-3">
      <StandardPictureInput
        $={$pictureStateSelector}
        computeAttachmentUrlCallback={computeStandardPictureUrlCallback}
        kanbanId={kanbanId}
        label={tStandardPictures(`viewValues.${translationKey}`)}
        filename={pictureId}
        isEditable={isEditable}
        placeholderPicturePath={placeholderPicturePath}
        $confirmAttachmentRemovalDialog={$confirmAttachmentRemovalDialog}
        notSetPictureAdditionalLayer="img/standard-pictures/set-picture-layer.webp"
      />
    </div>
  );
}

interface StandardPicturesForCategoryProps extends AppProps<Store> {
  readonly kanbanId: string;
  readonly isEditable: boolean;
  readonly categoryLabel: string;
  readonly picturesToDisplay: readonly PictureEntry[];
  readonly $: StoreStateSelector<Store, StandardPicturesState>;
}

function StandardPicturesForCategory({
  $gs,
  $,
  kanbanId,
  picturesToDisplay,
  isEditable,
  categoryLabel,
}: StandardPicturesForCategoryProps) {
  return (
    <>
      <h4 className="title is-4 mt-2 mb-3">{categoryLabel}</h4>
      <div className="fixed-grid has-12-cols">
        <div className="grid">
          {picturesToDisplay.map(([key, { id }]) => (
            <StandardPictureCell
              key={key}
              $gs={$gs}
              pictureId={id}
              translationKey={key}
              isEditable={isEditable}
              kanbanId={kanbanId}
              $={$.$pictures}
              $confirmAttachmentRemovalDialog={$.$confirmAttachmentRemovalDialog}
            />
          ))}
        </div>
      </div>
    </>
  );
}

interface PictureStandardProps extends AppProps<Store> {
  readonly isEditable: boolean;
  readonly contract: UIContract;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
  readonly $: StoreStateSelector<Store, StandardPicturesState>;
}

export function StandardPictures({
  $,
  $gs,
  $selectedKanban,
  contract,
  isEditable,
}: PictureStandardProps): JSX.Element {
  const [t] = useTranslation('details');
  const selectedKanban = useGetState($selectedKanban);
  const computeStandardPictureUrl = useComputeStandardPictureUrl($gs);

  const externalStandardPicturesToDisplay: readonly PictureEntry[] = useMemo(
    () =>
      getSortedStandardPicturesToDisplay(
        EXTERNAL_STANDARD_PICTURES,
        contract.standardPicturesConfiguration ?? []
      ),
    [contract]
  );

  const internalStandardPicturesToDisplay: readonly PictureEntry[] = useMemo(
    () =>
      getSortedStandardPicturesToDisplay(
        INTERNAL_STANDARD_PICTURES,
        contract.standardPicturesConfiguration ?? []
      ),
    [contract]
  );

  const shouldRestrictAttachmentRemoval = useCallback(
    (attachment: Attachment, metadata: AttachmentMetadata | undefined): boolean => {
      return !shouldAllowAttachmentDeletion(selectedKanban?.status, attachment, metadata);
    },
    [selectedKanban]
  );

  const onRemoveActionCallback = useActionCallback(
    async ({ httpClient, getState, actionDispatch, getGlobalState }) => {
      const filename = getState().confirmAttachmentRemovalDialog.name;
      await httpClient.httpGet(
        appendRegisteredBrowserSessionToken(
          CoreBackendRoutes.ATTACHMENT_STANDARD_PICTURE(
            selectedKanban.id,
            MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
            filename
          ),
          nonnull(getGlobalState().session.infos).sessionToken
        ),
        'DELETE'
      );
      actionDispatch.scopeProperty('pictures').scopeArrayItem(filename).setProperty('isSet', false);
      actionDispatch
        .scopeProperty('confirmAttachmentRemovalDialog')
        .setValue(EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE);
    },
    [selectedKanban.id],
    $
  );

  const downloadStandardPicturesActionCallback = useActionCallback(
    async ({ httpClient }) =>
      await downloadAndSave(
        httpClient,
        CoreBackendRoutes.ZIPPED_ATTACHMENT_STANDARD_PICTURE_FOLDER(
          nonnull(selectedKanban).id,
          MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID
        ),
        'download.zip'
      ),
    [selectedKanban],
    $
  );

  return (
    <div>
      <IconButton
        icon="download"
        ariaLabel="download"
        label={t('tabs.pictureStandard.download')}
        onClickCallback={downloadStandardPicturesActionCallback}
      />
      {externalStandardPicturesToDisplay.length > 0 && (
        <StandardPicturesForCategory
          $={$}
          $gs={$gs}
          isEditable={isEditable}
          kanbanId={selectedKanban.id}
          picturesToDisplay={externalStandardPicturesToDisplay}
          categoryLabel={t('tabs.pictureStandard.external')}
        />
      )}
      {internalStandardPicturesToDisplay.length > 0 && (
        <StandardPicturesForCategory
          $={$}
          $gs={$gs}
          isEditable={isEditable}
          kanbanId={selectedKanban.id}
          categoryLabel={t('tabs.pictureStandard.internal')}
          picturesToDisplay={internalStandardPicturesToDisplay}
        />
      )}
      <ConfirmAttachmentRemovalDialog
        category="kanban"
        objectId={selectedKanban.id}
        onOkClicked={onRemoveActionCallback}
        $={$.$confirmAttachmentRemovalDialog}
        computeAttachmentUrl={computeStandardPictureUrl}
        okLabel={t('confirmAttachmentRemovalDialog.okLabel')}
        shouldRestrictRemove={shouldRestrictAttachmentRemoval}
      >
        <p>{t('confirmAttachmentRemovalDialog.message')}</p>
      </ConfirmAttachmentRemovalDialog>
    </div>
  );
}

interface MobileStandardPicturesProps extends AppProps<Store> {
  readonly isEditable: boolean;
  readonly contract: UIContract;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
  readonly $: StoreStateSelector<Store, MobileDetailsSubPartState<StandardPicturesState>>;
}

export function MobileStandardPictures({
  $,
  $gs,
  contract,
  isEditable,
  $selectedKanban,
}: MobileStandardPicturesProps): JSX.Element {
  const [t] = useTranslation('details');

  return (
    <ShowHideBoxContainer isMobile $={$.$isUnfolded} title={t('tabs.pictureStandard.title')}>
      <StandardPictures
        $gs={$gs}
        contract={contract}
        isEditable={isEditable}
        $selectedKanban={$selectedKanban}
        $={$.$showDetailsFor.asDefined()}
      />
    </ShowHideBoxContainer>
  );
}
