import React from 'react';
import type { Attachment } from '@stimcar/libs-base';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import type { ImageModalState } from '../attachments/index.js';
import type { ComputeAttachmentUrlCallback } from '../attachments/typings/attachment.js';
import { AttachmentThumbnail } from '../attachments/AttachmentThumbnail.js';
import { CarViewsSwitch } from '../carviews/CarViewsSwitch.js';

interface Props<SD extends AnyStoreDef> {
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly selectedShapes: readonly string[];
  readonly category?: string;
  readonly attachments: readonly Attachment[];
  readonly kanbanId: string;
  readonly photoHeight: number;
}

export function ColumnedCarviewAndAttachmentThumbnailsDisplayer<SD extends AnyStoreDef>({
  computeAttachmentUrl,
  $imageModal,
  selectedShapes,
  category,
  attachments,
  kanbanId,
  photoHeight,
}: Props<SD>): JSX.Element {
  return (
    <div className="columns is-multiline p-sm is-mobile">
      {isTruthyAndNotEmpty(category) && (
        <div className="column is-narrow">
          <div className="card">
            <div
              style={{
                width: photoHeight * (200 / 225),
                height: photoHeight,
              }}
            >
              <CarViewsSwitch category={category} selectedShapes={selectedShapes} />
            </div>
          </div>
        </div>
      )}
      {attachments.map(
        ({ id }, index): JSX.Element => (
          <div className="column is-narrow" key={id}>
            <AttachmentThumbnail
              category="kanban"
              objectId={kanbanId}
              computeAttachmentUrl={computeAttachmentUrl}
              size={photoHeight}
              attachments={attachments}
              $imageModal={$imageModal}
              attachmentIndex={index}
            />
          </div>
        )
      )}
    </div>
  );
}
