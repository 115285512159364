import React from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { AvailablePermissionPaths, kanbanHelpers, Role } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import { Button, ShowDeliveryButton, ShowEstimateButton } from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import type { ShareKanbanModalState } from '../utils/typings/store.js';
import {
  useHasAccessPermission,
  useHasModifyPermission,
} from '../../registeredapp/permissionHooks.js';
import { computeCreateFormPath } from '../coreConstants.js';
import { HandleKanbanSplitButtonDropdown } from '../utils/HandleKanbanSplitButtonDropdown.js';
import { KanbanCancellationButton } from '../utils/KanbanCancellationButton.js';
import { RevokeKanbanButton } from '../utils/RevokeKanbanButton.js';
import { ShareKanbanButton } from '../utils/ShareKanbanButton.js';
import './KanbanDetailsActionsComponent.scss';
import type { KanbanDetailsState } from './typings/store.js';

interface Props extends AppProps<Store> {
  readonly $gsKanbanModalState: StoreStateSelector<Store, ShareKanbanModalState>;
  readonly $: StoreStateSelector<Store, KanbanDetailsState>;
  readonly isStandHandlingDisabled: boolean;
  readonly role: string;
}

export function KanbanDetailsActionsComponent({
  $gsKanbanModalState,
  $,
  $gs,
  isStandHandlingDisabled,
  role,
}: Props): JSX.Element {
  const [t] = useTranslation('details');
  const { $desktopState } = $;

  const selectedKanban = nonnull(useGetState($.$selectedKanban));

  const createFromActionCallback = useActionCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async ({ navigate }): Promise<void> => {
      navigate(computeCreateFormPath(selectedKanban.id));
    },
    [selectedKanban],
    $
  );

  const isWorkshopOperator = role === Role.WorkshopOperator;

  const canRevokeKanban = useHasAccessPermission($gs, AvailablePermissionPaths.CAN_REVOKE_KANBAN);

  const canForceRevocation = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.CAN_FORCE_KANBAN_REVOCATION
  );

  const canForceMarketplaceRevocation = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.CAN_FORCE_MARKETPLACE_KANBAN_REVOCATION
  );

  const isRevokeButtonDisplayed =
    canRevokeKanban ||
    canForceRevocation ||
    (canForceMarketplaceRevocation && kanbanHelpers.isMarketplaceKanban(selectedKanban));

  return (
    <>
      <div className="columns is-gap-medium is-multiline">
        {!isWorkshopOperator && (
          <div className="column is-full">
            <HandleKanbanSplitButtonDropdown
              isFullwidth
              kanban={selectedKanban}
              isDisabled={isStandHandlingDisabled}
              $gs={$gs}
              $active={$.$handleKanbanMenuActive}
            />
          </div>
        )}
        <div className="column is-half">
          <ShowEstimateButton
            isFullWidth
            onClick={useSetCallback($desktopState.$viewToShow, 'estimate')}
          />
        </div>
        <div className="column is-half">
          <ShowDeliveryButton
            isFullWidth
            onClick={useSetCallback($desktopState.$viewToShow, 'delivery')}
          />
        </div>
        <div className="column is-half">
          <ShareKanbanButton $={$gsKanbanModalState} kanbanId={selectedKanban.id} isFullWidth />
        </div>
        <div className="column is-half">
          <Button
            isFullWidth
            label={t('actions.cloneFrom')}
            iconId="copy"
            onClick={createFromActionCallback}
            disabled={isWorkshopOperator}
          />
        </div>
        {isRevokeButtonDisplayed && (
          <>
            <div className="column is-half">
              <RevokeKanbanButton
                $={$.$revokeKanbanModal}
                $gs={$gs}
                kanban={selectedKanban}
                isFullWidth
              />
            </div>
            <div className="column is-half">
              <KanbanCancellationButton
                isFullWidth
                $gs={$gs}
                kanban={selectedKanban}
                $={$.$kanbanCancellationModal}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
