import { useMemo } from 'react';
import type { FormFieldEntry } from './typings/FormFieldEntry.js';

interface Props<T extends string | number | boolean> {
  readonly entries: readonly (FormFieldEntry<T> | T)[];
  readonly sortEntries: boolean;
}

export function useSortableFormFielEntries<T extends string | number | boolean>({
  entries,
  sortEntries,
}: Props<T>): FormFieldEntry<T>[] {
  const sortedEntries = useMemo((): FormFieldEntry<T>[] => {
    const objectArray = entries.map(
      (e): FormFieldEntry<T> => (typeof e === 'object' ? e : { id: e, label: String(e) })
    );
    if (!sortEntries) {
      return objectArray;
    }
    return objectArray.sort((e1, e2): number => {
      return e1.label.localeCompare(e2.label);
    });
  }, [entries, sortEntries]);
  return sortedEntries;
}
