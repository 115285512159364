import type { Kanban, RepositoryEntityPayload } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { ActionContext } from '@stimcar/libs-uikernel';
import { computePayload, nonnull } from '@stimcar/libs-kernel';
import type { Store } from '../../../state/typings/store.js';
import type { EditCommentsModalDialogState, SparePartComments } from '../typings/store.js';

export async function updateSparePartCommentsAction({
  kanbanRepository,
  getState,
}: ActionContext<Store, EditCommentsModalDialogState>) {
  const { formData, kanbanId, packageDealId, sparePart } = getState();
  const { commentForStore, commentForWorkshop, commentForCustomer } = formData;

  const targetSparePart = nonnull(sparePart);
  const targetPackageDealId = nonnull(packageDealId);
  const modifiedSparePart: SparePartComments = {
    ...targetSparePart,
    comment: commentForStore ?? '',
    commentForWorkshop: commentForWorkshop ?? '',
    commentForCustomer: commentForCustomer ?? '',
  };
  const changes = computePayload(targetSparePart, modifiedSparePart);
  if (changes) {
    const payload = toSparePartChangedPayload(
      kanbanId,
      targetPackageDealId,
      targetSparePart.id,
      changes
    );
    await kanbanRepository.updateEntityFromPayload(payload);
  }
}

function toSparePartChangedPayload(
  kanbanId: string,
  packageDealId: string,
  sparePartId: string,
  changes: DeepPartial<SparePartComments>
): RepositoryEntityPayload<Kanban> {
  return {
    entityId: kanbanId,
    payload: {
      packageDeals: [
        {
          id: packageDealId,
          spareParts: [
            {
              id: sparePartId,
              ...changes,
            },
          ],
        },
      ],
    },
  };
}
