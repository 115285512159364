import type { JSX } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  KanbanColorationCharter,
  KanbansBoardBandwidthConfiguration,
  KanbansBoardConfiguration,
  KanbansBoardLaneConfiguration,
  Stand,
  WORKFLOW_ID_TYPE,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  DEFAULT_KANBAN_COLORATION_CHARTER,
  EMPTY_KANBANS_BOARD_CONFIGURATION,
  groupBy,
  Role,
} from '@stimcar/libs-base';
import { isTruthy, keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { iconHelpers } from '@stimcar/libs-uitoolkit';
import type { Store, StoreState } from '../../state/typings/store.js';
import type { StandDisplayState } from '../typings/store.js';
import { computeKanbanDetailsPath } from '../../coreConstants.js';
import './KanbansBoardComponent.scss';
import type {
  KanbansAllocationByLaneAndBandwidth,
  KanbansBoardCellSlot,
  KanbansBoardDisplayState,
  RepairOrderForKanban,
} from './typings/store.js';
import { KanbansBoardDisplayConfiguration } from './KanbansBoardDisplayConfiguration.js';
import { RepairOrderSlotItemDisplay } from './RepairOrderSlotItemDisplay.js';
import { DECORATOR_BULLET_SIZE, DECORATOR_ICON_SIZE, SlotType } from './typings/store.js';
import { kanbansBoardHelpers } from './utils/kanbansBoardHelpers.js';

const {
  getRepairOrdersByLanesAndBandwidths,
  getKanbansAllocationByLanesAndBandwidths,
  computeKanbansBoardCellData,
  sortSlotsForGridDisplay,
  computeNbRowsForRepairOrdersPerDay,
  computeKanbansBoardCellDimensions,
  grid,
  sizeInPx,
  fontSize,
} = kanbansBoardHelpers;

const LANE_LABEL_SIZE = 7;
const LANE_SEPARATOR_SIZE = 13;
const LANE_HEADER_MARGIN = 3;
const BANDWIDTH_LABEL_SIZE = 6;
const KANBAN_HEIGHT = 7;

function useGetDataForKanbansCell(
  kanbansAllocationByStandAndBandwidth: KanbansAllocationByLaneAndBandwidth,
  maxNbCols: number
): readonly KanbansBoardCellSlot[] {
  return useMemo(() => {
    const { dimensions, slots: unsortedSlots } = computeKanbansBoardCellData(
      kanbansAllocationByStandAndBandwidth,
      maxNbCols
    );
    const sortedSlots = sortSlotsForGridDisplay(
      unsortedSlots,
      dimensions.nbRows,
      dimensions.nbCols
    );
    return sortedSlots;
  }, [kanbansAllocationByStandAndBandwidth, maxNbCols]);
}

type RowsData = {
  readonly startingRow: number;
  readonly nbRows: number;
};

type ColsData = {
  readonly startingCol: number;
  readonly nbCols: number;
};

function getStartingColForLane(
  laneId: string,
  lanesId: readonly string[],
  colsDataByLane: Record<string, ColsData>
): number {
  const laneIndex = lanesId.indexOf(laneId);
  if (laneIndex === -1) {
    return 0;
  }

  // Get the lanes appearing before the given laneId
  const previousLanesIds = lanesId.slice(0, laneIndex);

  return (
    previousLanesIds.reduce((sum, laneId) => {
      const laneData = colsDataByLane[laneId];
      return sum + (laneData?.nbCols ?? 0);
    }, 0) +
    laneIndex +
    1
  );
}

function useGetNbColsAndStartingColByLanes(
  lanes: readonly KanbansBoardLaneConfiguration[],
  kanbansAllocations: Record<string, readonly KanbansAllocationByLaneAndBandwidth[]>
): Record<string, ColsData> {
  return useMemo(() => {
    const lanesIds = lanes.map(({ id }) => id);
    return lanes.reduce<Record<string, ColsData>>((result, { id: laneId }) => {
      const allocationsForStand = kanbansAllocations[laneId];
      const nbColsForAllStands = allocationsForStand.map(({ repairOrdersPerDay, leadTime }) => {
        const { nbCols } = computeKanbansBoardCellDimensions(repairOrdersPerDay, leadTime);
        return nbCols;
      });
      const nbCols = Math.max(...nbColsForAllStands);
      const startingCol = getStartingColForLane(laneId, lanesIds, result);

      return { ...result, [laneId]: { nbCols, startingCol } };
    }, {});
  }, [lanes, kanbansAllocations]);
}

function getNbRowsForPreviousBandwidths(
  bandwidthId: string,
  bandwidthsIds: readonly string[],
  rowsDataByBandwidth: Record<string, RowsData>
): number {
  const bandwidthIndex = bandwidthsIds.indexOf(bandwidthId);
  if (bandwidthIndex === -1) {
    return 0;
  }

  // Get the bandwidths appearing before the given bandwidthId
  const previousBandwidths = bandwidthsIds.slice(0, bandwidthIndex);

  return previousBandwidths.reduce((sum, bandwidth) => {
    const bandwidthData = rowsDataByBandwidth[bandwidth];
    return sum + (bandwidthData?.nbRows ?? 0);
  }, 0);
}

function useGetNbRowsAndStartingRowByBandwidths(
  bandwidthsConfiguration: readonly KanbansBoardBandwidthConfiguration[]
): Record<string, RowsData> {
  return useMemo(() => {
    const bandwidthsIds = bandwidthsConfiguration.map(({ id }) => id);
    return bandwidthsConfiguration.reduce<Record<string, RowsData>>(
      (result, banwidthConfiguration) => {
        const { id: bandwidthId, repairOrdersPerDay } = banwidthConfiguration;
        const nbRows = computeNbRowsForRepairOrdersPerDay(repairOrdersPerDay);

        const startingRow = getNbRowsForPreviousBandwidths(bandwidthId, bandwidthsIds, result);

        return { ...result, [bandwidthId]: { nbRows: nbRows + 1, startingRow } }; // We add 1 row for the bandwidth title
      },
      {}
    );
  }, [bandwidthsConfiguration]);
}

function getRowAndColInCellForSlotIndex(index: number, totalCols: number) {
  const row = Math.floor(index / totalCols);
  const col = index % totalCols;
  return { row, col };
}

interface BulletIconProps {
  readonly size: number;
  readonly icon: string;
}

export function BulletIcon({ size, icon }: BulletIconProps) {
  const { icon: realIcon } = useMemo(
    () => iconHelpers.getFontAwesomeStyleAndIconFromIconId(icon),
    [icon]
  );
  const dimension = sizeInPx(DECORATOR_BULLET_SIZE, size);

  return (
    <div
      className="has-background-white is-flex is-justify-content-center is-align-items-center"
      style={{
        width: dimension,
        height: dimension,
        borderRadius: '50%',
      }}
    >
      <i className={`fas fa-${realIcon}`} style={fontSize(DECORATOR_ICON_SIZE, size)} />
    </div>
  );
}

interface RepairOrderSlotItemProps extends AppProps<Store> {
  readonly repairOrder: RepairOrderForKanban;
  readonly standsIds: readonly string[];
  readonly row: number;
  readonly col: number;
  readonly browseable: boolean;
}

function RepairOrderSlotItem({
  repairOrder,
  standsIds,
  row,
  col,
  $gs,
  browseable,
}: RepairOrderSlotItemProps): JSX.Element {
  const [t] = useTranslation('display');
  const size = useGetState($gs.$displayView.$kanbansBoardDisplay.$size);
  const mode = useGetState($gs.$displayView.$kanbansBoardDisplay.$mode);

  const { id: repairOrderId, standId, license, standIconToDisplay, priorityLevel } = repairOrder;
  const isBeforeLastStand =
    standsIds.includes(standId) && standsIds.indexOf(standId) < standsIds.length - 1;

  const gotoKanbanDetailsCallback = useActionCallback(
    ({ actionDispatch, navigate }: ActionContext<Store, StoreState>): void => {
      if (browseable) {
        navigate(computeKanbanDetailsPath(repairOrderId));
      } else {
        actionDispatch.setProperty('message', {
          type: 'info',
          title: license,
          content: t('kanbansBoard.notLoggedIn'),
        });
      }
    },
    [browseable, license, repairOrderId, t],
    $gs
  );

  return (
    <RepairOrderSlotItemDisplay
      row={row}
      col={col}
      id={repairOrderId}
      license={license}
      standId={standId}
      priorityLevel={priorityLevel}
      isBeforeLastStand={isBeforeLastStand}
      standIconToDisplay={standIconToDisplay}
      mode={mode}
      size={size}
      onClickActionCallback={gotoKanbanDetailsCallback}
    />
  );
}

interface KanbansBoardSlotComponentProps extends AppProps<Store> {
  readonly slot: KanbansBoardCellSlot;
  readonly standsIds: readonly string[];
  readonly row: number;
  readonly col: number;
  readonly browseable: boolean;
}

function KanbansBoardSlotComponent({
  slot,
  standsIds,
  row,
  col,
  browseable,
  $gs,
}: KanbansBoardSlotComponentProps): JSX.Element | null {
  switch (slot.type) {
    case SlotType.repairOrder:
      return (
        <RepairOrderSlotItem
          repairOrder={nonnull(slot.repairOrder)}
          standsIds={standsIds}
          row={row}
          col={col}
          browseable={browseable}
          $gs={$gs}
        />
      );
    case SlotType.available:
      return <div className="cell is-available-slot" style={grid({ row, col })} />;
    case SlotType.disabled:
      return null;
    default:
      return null;
  }
}

interface KanbansBoardCellComponentProps extends AppProps<Store> {
  readonly standsIds: readonly string[];
  readonly kanbansAllocationByLaneAndBandwidth: KanbansAllocationByLaneAndBandwidth;
  readonly startingRow: number;
  readonly startingCol: number;
  readonly nbCols: number;
  readonly browseable: boolean;
}

function KanbansBoardCellComponent({
  standsIds,
  kanbansAllocationByLaneAndBandwidth,
  startingRow,
  startingCol,
  nbCols,
  browseable,
  $gs,
}: KanbansBoardCellComponentProps): JSX.Element {
  const slots = useGetDataForKanbansCell(kanbansAllocationByLaneAndBandwidth, nbCols);

  return (
    <>
      {slots.map((slot: KanbansBoardCellSlot, index): JSX.Element => {
        const { row, col } = getRowAndColInCellForSlotIndex(index, nbCols);
        return (
          <KanbansBoardSlotComponent
            key={slot.key}
            slot={slot}
            standsIds={standsIds}
            row={startingRow + row + 1}
            col={startingCol + col}
            browseable={browseable}
            $gs={$gs}
          />
        );
      })}
    </>
  );
}

interface KanbansBoardColumnComponentProps extends AppProps<Store> {
  readonly laneId: string;
  readonly standsIds: readonly string[];
  readonly kanbansAllocations: readonly KanbansAllocationByLaneAndBandwidth[];
  readonly rowsDataByBandwidth: Record<string, RowsData>;
  readonly nbCols: number;
  readonly startingCol: number;
  readonly browseable: boolean;
}

function KanbansBoardColumnComponent({
  laneId,
  standsIds,
  kanbansAllocations,
  rowsDataByBandwidth,
  nbCols,
  startingCol,
  browseable,
  $gs,
}: KanbansBoardColumnComponentProps): JSX.Element {
  const size = useGetState($gs.$displayView.$kanbansBoardDisplay.$size);
  const totalNbRows = useMemo(
    () => Object.values(rowsDataByBandwidth).reduce<number>((sum, { nbRows }) => sum + nbRows, 0),
    [rowsDataByBandwidth]
  );
  return (
    <>
      <div
        className="box mb-0"
        style={grid({
          row: 0,
          nbRows: totalNbRows + 1,
          col: startingCol,
          nbCols,
        })}
      />
      <div
        className="has-text-centered has-text-black has-text-weight-bold mb-0"
        style={{
          ...grid({ row: 0, col: startingCol, nbCols }),
          ...fontSize(LANE_LABEL_SIZE, size),
        }}
      >
        {laneId}
      </div>
      {kanbansAllocations.map((allocation) => {
        const { startingRow } = rowsDataByBandwidth[allocation.bandwidthId];
        return (
          <KanbansBoardCellComponent
            key={`${allocation.bandwidthId}#${allocation.laneId}`}
            standsIds={standsIds}
            kanbansAllocationByLaneAndBandwidth={allocation}
            startingRow={startingRow + 1}
            startingCol={startingCol}
            nbCols={nbCols}
            browseable={browseable}
            $gs={$gs}
          />
        );
      })}
    </>
  );
}

interface KanbansBoardBandwidthRowHeaderProps {
  readonly bandwidthId: string;
  readonly startingRow: number;
  readonly nbRows: number;
  readonly $kanbansBoardDisplay: StoreStateSelector<Store, KanbansBoardDisplayState>;
}

function KanbansBoardBandwidthRowHeader({
  bandwidthId,
  startingRow,
  nbRows,
  $kanbansBoardDisplay,
}: KanbansBoardBandwidthRowHeaderProps): JSX.Element {
  const size = useGetState($kanbansBoardDisplay.$size);

  return (
    <div className="bandwidth" style={grid({ row: startingRow + 2, nbRows: nbRows - 1, col: 0 })}>
      <div className="bandwidth-label" style={fontSize(BANDWIDTH_LABEL_SIZE, size)}>
        {bandwidthId}
      </div>
    </div>
  );
}

function useGetKanbansAllocationByLanesAndBandwidths(
  standsDisplayStates: Record<WORKFLOW_ID_TYPE, StandDisplayState>,
  kanbansBoardConfiguration: KanbansBoardConfiguration,
  kanbanColorationCharter: KanbanColorationCharter,
  standsDefinition: readonly Stand<'standard'>[]
): Record<string, readonly KanbansAllocationByLaneAndBandwidth[]> {
  return useMemo(() => {
    const repairOrdersByLaneAndCategory = getRepairOrdersByLanesAndBandwidths(
      standsDisplayStates,
      kanbansBoardConfiguration,
      kanbanColorationCharter,
      standsDefinition
    );
    const computedKanbansAllocations = getKanbansAllocationByLanesAndBandwidths(
      kanbansBoardConfiguration,
      repairOrdersByLaneAndCategory
    );
    return groupBy(computedKanbansAllocations, ({ laneId }) => laneId);
  }, [kanbanColorationCharter, kanbansBoardConfiguration, standsDisplayStates, standsDefinition]);
}

interface KanbansBoardContentComponentProps extends AppProps<Store> {
  readonly stands: Record<WORKFLOW_ID_TYPE, StandDisplayState>;
  readonly kanbansBoardConfiguration: KanbansBoardConfiguration;
  readonly kanbanColorationCharter: KanbanColorationCharter;
  readonly standsDefinition: readonly Stand<'standard'>[];
  readonly browseable: boolean;
}

function KanbansBoardsContentComponent({
  stands,
  kanbansBoardConfiguration = EMPTY_KANBANS_BOARD_CONFIGURATION,
  kanbanColorationCharter,
  standsDefinition,
  browseable,
  $gs,
}: KanbansBoardContentComponentProps): JSX.Element {
  const [t] = useTranslation('display');

  const size = useGetState($gs.$displayView.$kanbansBoardDisplay.$size);

  const kanbansAllocationsByLane: Record<string, readonly KanbansAllocationByLaneAndBandwidth[]> =
    useGetKanbansAllocationByLanesAndBandwidths(
      stands,
      kanbansBoardConfiguration,
      kanbanColorationCharter,
      standsDefinition
    );

  const { lanes, bandwidths } = kanbansBoardConfiguration;

  const colsDataByLane = useGetNbColsAndStartingColByLanes(lanes, kanbansAllocationsByLane);

  const gridTemplateColumns = useMemo(() => {
    const repeatInstructions = lanes
      .map(({ id }) => {
        const { nbCols } = colsDataByLane[id];
        return `repeat(${nbCols}, 1fr)`;
      })
      .join(` ${sizeInPx(LANE_SEPARATOR_SIZE, size)} `);
    return `auto ${repeatInstructions} auto`;
  }, [colsDataByLane, lanes, size]);

  const rowsDataByBandwidth: Record<string, RowsData> =
    useGetNbRowsAndStartingRowByBandwidths(bandwidths);

  const totalNbRowsExcludingLaneHeader = useMemo(() => {
    return Object.values(rowsDataByBandwidth).reduce<number>((sum, { nbRows }) => sum + nbRows, 0);
  }, [rowsDataByBandwidth]);

  if (!isTruthy(lanes) || lanes.length === 0 || !isTruthy(bandwidths) || bandwidths.length === 0) {
    return <div>{t('kanbansBoard.emptyConfiguration')}</div>;
  }

  return (
    <div
      className="kanbans-board is-fullwidth"
      style={{
        gridTemplateColumns,
        gridTemplateRows: `auto ${sizeInPx(LANE_HEADER_MARGIN, size)} repeat(${totalNbRowsExcludingLaneHeader - 1}, ${sizeInPx(KANBAN_HEIGHT, size)})`,
      }}
    >
      {bandwidths.map(({ id }) => {
        const { startingRow, nbRows } = rowsDataByBandwidth[id];
        return (
          <KanbansBoardBandwidthRowHeader
            key={id}
            bandwidthId={id}
            startingRow={startingRow}
            nbRows={nbRows}
            $kanbansBoardDisplay={$gs.$displayView.$kanbansBoardDisplay}
          />
        );
      })}
      {lanes.map(({ id: laneId, standsIds }): JSX.Element => {
        const { nbCols, startingCol } = colsDataByLane[laneId];
        return (
          <KanbansBoardColumnComponent
            key={laneId}
            laneId={laneId}
            standsIds={standsIds}
            kanbansAllocations={kanbansAllocationsByLane[laneId]}
            rowsDataByBandwidth={rowsDataByBandwidth}
            nbCols={nbCols}
            startingCol={startingCol}
            browseable={browseable}
            $gs={$gs}
          />
        );
      })}
    </div>
  );
}

export function KanbansBoardComponent({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('display');

  const workflowsStates = useGetState($gs.$displayView.$workflowsStates);
  const user = useGetState($gs.$session.$user);
  const role = useGetState($gs.$session.$infos.optChaining().$role);
  const linksAreBrowseable = useMemo(() => isTruthy(user) && role !== Role.Display, [role, user]);

  const kanbanColorationCharterFromState = useGetState(
    $gs.$siteConfiguration.$displayConfiguration.$kanbanColorationCharter
  );
  const standsDefinition = useGetState($gs.$siteConfiguration.$stands);

  const kanbanColorationCharter = useMemo((): KanbanColorationCharter => {
    return kanbanColorationCharterFromState ?? DEFAULT_KANBAN_COLORATION_CHARTER;
  }, [kanbanColorationCharterFromState]);

  const { $kanbansBoardDisplay } = $gs.$displayView;

  const loadInitialStateActionCallback = useActionCallback(
    function loadInitialStateAction({ actionDispatch }) {
      const persistedState = localStorage.getItem(LocalStorageKeys.KANBANS_BOARD_DISPLAY);
      if (persistedState) {
        actionDispatch.applyPayload({
          ...JSON.parse(persistedState),
        });
      }
    },
    [],
    $kanbansBoardDisplay
  );

  useEffect((): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadInitialStateActionCallback();
  }, [loadInitialStateActionCallback]);

  const kanbansBoardConfiguration = useGetState($gs.$siteConfiguration.$kanbansBoardConfiguration);
  if (!isTruthy(kanbansBoardConfiguration)) {
    return <div>{t('kanbansBoard.emptyConfiguration')}</div>;
  }

  return (
    <div className="columns">
      <div className="column is-narrow">
        <KanbansBoardDisplayConfiguration $kanbansBoardDisplay={$kanbansBoardDisplay} />
      </div>
      <div className="column">
        {keysOf(workflowsStates).map((workflowId) => (
          <KanbansBoardsContentComponent
            key={workflowId}
            stands={workflowsStates[workflowId].stands}
            kanbansBoardConfiguration={kanbansBoardConfiguration}
            kanbanColorationCharter={kanbanColorationCharter}
            standsDefinition={standsDefinition}
            browseable={linksAreBrowseable}
            $gs={$gs}
          />
        ))}
      </div>
    </div>
  );
}
