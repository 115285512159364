import i18next from 'i18next';
import type { FileUploadResult, KnownKeysOf, RepositoryEntities } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import { downloadAndSave } from '@stimcar/libs-uitoolkit';
import type { Store, StoreState } from '../state/typings/store.js';

export async function downloadFileAction(
  { getState, httpClient }: ActionContext<Store, StoreState>,
  entityName: KnownKeysOf<RepositoryEntities>,
  selectedPackageDealDatabase: string
): Promise<void> {
  const { company, site } = nonnull(getState().session.infos);
  const defaultFilename = i18next.t('refititCommonComponents:downloadFile.defaultFilename', {
    companyId: company.id,
    siteId: site.id,
  });
  const endpoint = CoreBackendRoutes.REPOSITORY_DOWNLOAD_EXCEL(
    entityName,
    selectedPackageDealDatabase
  );
  await downloadAndSave(httpClient, endpoint, defaultFilename);
}

function toMarkedDownWarnings(warnings: readonly string[]): string {
  return `${
    warnings.length > 0
      ? `${i18next.t('refititCommonComponents:uploadFile.uploadWarningSubtitle')} :
  ${warnings.map((w) => `* ${w}`).join('\n')}`
      : ''
  }`;
}

function toSuccessfullUploadMarkdownMessageContent(uploadReturnValue: FileUploadResult): string {
  return `${uploadReturnValue.success}\n\n${toMarkedDownWarnings(uploadReturnValue.warnings)}`;
}

function toFailedUploadMarkdownMessageContent(uploadReturnValue: FileUploadResult): string {
  return `${
    uploadReturnValue.errors.length > 0
      ? `${i18next.t('refititCommonComponents:uploadFile.uploadErrorSubtitle')} :
  ${uploadReturnValue.errors.map((e) => `* ${e}`).join('\n')}`
      : ''
  }
  \n\n${toMarkedDownWarnings(uploadReturnValue.warnings)}`;
}

export async function uploadFileAction(
  { httpClient, globalActionDispatch, runWithProgressBar }: ActionContext<Store, StoreState>,
  entityName: KnownKeysOf<RepositoryEntities>,
  files: readonly File[]
) {
  await runWithProgressBar(100, async (monitor) => {
    monitor.setIndeterminateProgress();
    // FIXME strange that the success label is returned from the server. The server should
    // return the imported entities count, and the label should be built on the client
    // side
    const uploadReturnValue = await httpClient.httpPostAsFile<FileUploadResult>(
      CoreBackendRoutes.REPOSITORY_UPLOAD_EXCEL(entityName),
      files[0]
    );
    if (uploadReturnValue.success !== undefined) {
      globalActionDispatch.setProperty('message', {
        type: 'info',
        title: i18next.t('refititCommonComponents:uploadFile.uploadOKTitle'),
        content: toSuccessfullUploadMarkdownMessageContent(uploadReturnValue),
      });
    } else {
      globalActionDispatch.setProperty('message', {
        type: 'warning',
        title: i18next.t('refititCommonComponents:uploadFile.uploadErrorTitle'),
        content: toFailedUploadMarkdownMessageContent(uploadReturnValue),
      });
    }
  });
}
