/* eslint-disable jsx-a11y/control-has-associated-label */
import type { JSX } from 'react';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  KanbanColorationCharter,
  KanbanCustomer,
  KanbanLocationElement,
  KanbanSummary,
  RepositoryEntityStatus,
} from '@stimcar/libs-base';
import type { KanbanInfos } from '@stimcar/libs-kernel';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { ImageModalState } from '@stimcar/libs-uitoolkit';
import {
  compareNumbers,
  kanbanHelpers,
  SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID,
} from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useRecordItemSelector } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { ComputeAttachmentUrlCallback } from '../../../../lib/components/attachments/typings/attachment.js';
import type { ActionButtonDesc } from '../../../../lib/components/misc/ActionButtonComponent.js';
import { ClickableElement } from '../../../../lib/bulma/elements/ClickableElement.js';
import { KanbanIdentityPictureComponent } from '../../../../lib/components/attachments/KanbanIdentityPictureComponent.js';
import { ActionButtonComponent } from '../../../../lib/components/misc/ActionButtonComponent.js';
import { TruncableP } from '../../../../lib/components/misc/TruncableP.js';
import { useHTMLElementSize } from '../../../../lib/hooks/useHTMLElementSize.js';
import { kanbanPriorityLevelHelpers } from '../../../../lib/utils/kanbanPriorityLevelHelpers.js';

export interface SelectableKanbanListItemProps {
  readonly kanban: KanbanSummary;
  readonly selectKanban: (id: string) => Promise<void> | void;
  readonly disableSelection?: boolean;
  readonly kanbanColorationCharter: KanbanColorationCharter | undefined;
  readonly isSelected: boolean;
}

export function SelectableKanbanListItem({
  kanban,
  selectKanban,
  disableSelection = false,
  kanbanColorationCharter,
  isSelected,
}: SelectableKanbanListItemProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const { id, contract, infos, customer, status, isRevoked, price, marketplacePublicPrice } =
    kanban;

  const setSelectedKanban = useCallback(async (): Promise<void> => {
    await selectKanban(id);
  }, [id, selectKanban]);

  const onKeyDownHandler = async (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (event.keyCode === 13) {
      await setSelectedKanban();
    }
  };

  const computeItemStyle = (): string => {
    let className = 'box';
    if (status !== 'open') {
      className += ' has-background-grey-lighter';
    } else {
      const cssId = kanbanPriorityLevelHelpers.getCssIdForPriorityLevelFromKanban(
        kanban,
        kanbanColorationCharter
      );
      className += ` ${cssId}`;
    }
    if (isSelected) {
      className += ' is-active-box';
    }
    return className;
  };

  const boxRef = useRef<HTMLDivElement>(null);
  const [boxWidth] = useHTMLElementSize(boxRef);

  const location = !kanban.location ? t('kanbanListItem.unknownLocation') : kanban.location;

  return (
    <div
      className={computeItemStyle()}
      role="button"
      tabIndex={0}
      onClick={disableSelection ? undefined : setSelectedKanban}
      onKeyDown={disableSelection ? undefined : onKeyDownHandler}
      style={{ cursor: disableSelection ? 'not-allowed' : 'pointer' }}
      ref={boxRef}
    >
      <InternalListItem
        boxWidth={boxWidth}
        contractCode={contract.code}
        infos={infos}
        marketplacePublicPrice={marketplacePublicPrice}
        location={location}
        status={status}
        customer={customer}
        isRevoked={isRevoked}
        price={price}
      />
    </div>
  );
}

interface Props {
  readonly infos: KanbanInfos;
  readonly marketplacePublicPrice?: number;
  readonly contractCode: string;
  readonly customer: KanbanCustomer;
  readonly location: string;
  readonly status: RepositoryEntityStatus;
  readonly boxWidth: number;
  readonly isRevoked: boolean;
  readonly price: number;
}

function InternalListItem({
  status,
  infos,
  marketplacePublicPrice,
  contractCode,
  location,
  boxWidth,
  customer,
  isRevoked,
  price,
}: Props): JSX.Element {
  const [t] = useTranslation('libComponents');
  const customerLabel = kanbanHelpers.getCustomerLabel(customer, t);
  const kanbanTitle = kanbanHelpers.getKanbanTitle(infos, marketplacePublicPrice);

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <div>
      <div
        className="columns is-mobile m-b-xs"
        style={{
          border: '4px solid rgba(0, 0, 0, 0)',
          backgroundClip: 'content-box',
          borderRadius: '10px',
          marginBottom: 0,
        }}
      >
        <div className="column">
          <h6 className="has-text-weight-bold has-text-dark is-size-6">
            <TruncableP style={{ width: boxWidth - 10 }}>{kanbanTitle}</TruncableP>
          </h6>
        </div>
      </div>
      <h6 className="is-size-6 m-b-sm">
        <>
          <TruncableP style={{ width: boxWidth - 10 }}>
            <>
              <FaIcon id="r/id-badge" tooltip={contractCode} size="small" />
              {` ${contractCode}`}
              <FaIcon
                id={customer.individual ? 'male' : 'building'}
                tooltip={customerLabel}
                size="small"
              />
              {customerLabel}
            </>
          </TruncableP>
          <TruncableP style={{ width: boxWidth - 10 }}>
            <>
              <FaIcon
                id={status === 'open' ? 'map-marker-alt' : 'database'}
                tooltip={status === 'open' ? location : t('kanbanListItem.archived')}
                size="small"
              />
              {` ${status === 'open' ? location : t('kanbanListItem.archived')}`}
              {isRevoked && (
                <>
                  <FaIcon id="times-circle" />
                  {t('kanbanListItem.revoked')}
                </>
              )}
              {!isRevoked && (
                <>
                  <FaIcon id="euro-sign" />
                  {`${price.toFixed(2)} €`}
                </>
              )}
            </>
          </TruncableP>
        </>
      </h6>
    </div>
  );
}

export type ExpandedKanbanStatuses = Readonly<Record<string, boolean>>;

interface KanbanListItemProps<SD extends AnyStoreDef> {
  readonly kanbanColorationClassName: string;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly $expandedKanbanStatuses: StoreStateSelector<SD, ExpandedKanbanStatuses>;
  readonly infos: KanbanInfos;
  readonly kanbanId: string;
  readonly contractCode?: string;
  readonly kanbanPositionIconId?: string | null;
  readonly hasAMessage: boolean;
  readonly requestMessageClassName: string;
  readonly attachmentUrl: ComputeAttachmentUrlCallback;
  readonly unfoldable?: JSX.Element;
  readonly foldable?: {
    readonly content: JSX.Element;
    readonly foldedByDefault: boolean;
  };
  readonly actionDescs?: readonly ActionButtonDesc<SD>[];
  readonly locationHistory?: readonly KanbanLocationElement[];
  readonly hasBoxBorder?: boolean;
}

function getLastKnownLocation(
  defaultLocation: string,
  locationHistory?: readonly KanbanLocationElement[]
): string {
  if (isTruthy(locationHistory) && locationHistory.length > 0) {
    const orderedLocations = [...locationHistory].sort((location1, location2) =>
      compareNumbers(location1.date, location2.date, 'UP')
    );
    return orderedLocations[0].location;
  }
  return defaultLocation;
}

export function KanbanListItem<SD extends AnyStoreDef>({
  kanbanColorationClassName,
  foldable,
  unfoldable,
  hasAMessage,
  requestMessageClassName,
  attachmentUrl,
  kanbanPositionIconId,
  $imageModal,
  kanbanId,
  contractCode,
  infos,
  actionDescs,
  locationHistory,
  hasBoxBorder = false,
  $expandedKanbanStatuses,
}: KanbanListItemProps<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');
  const $isExpanded = useRecordItemSelector($expandedKanbanStatuses, kanbanId);
  const isExpandedRawValue = useGetState($isExpanded);
  const isExpanded =
    isExpandedRawValue === undefined ? !foldable?.foldedByDefault : isExpandedRawValue;

  const toggleFoldedActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      const actualValue = getState() === undefined ? !foldable?.foldedByDefault : getState();
      actionDispatch.setValue(!actualValue);
    },
    [foldable?.foldedByDefault],
    $isExpanded
  );

  const lastKnownLocation = getLastKnownLocation(
    t('kanbanListItem.unknownLocation'),
    locationHistory
  );
  const hasMultipleActions = isTruthy(actionDescs) && actionDescs.length > 1;

  return (
    <div
      style={{
        backgroundClip: 'content-box',
        borderRadius: '0.25rem',
        marginBottom: 0,
        overflow: 'hidden',
      }}
      className={`has-background-white bulma-box-shadow ${
        hasBoxBorder ? 'has-primary-color-border' : ''
      }`}
    >
      <div className={kanbanColorationClassName}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <figure className="image m-sm">
            <KanbanIdentityPictureComponent
              isOnline
              thumbnailDisplayWidth={48}
              computeAttachmentUrl={attachmentUrl}
              $imageModal={$imageModal}
              kanbanId={kanbanId}
            />
          </figure>
          <ClickableElement
            additionalStyle={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '0',
              flexGrow: 1,
            }}
            isNotClickable={!isTruthy(foldable)}
            clickHandler={toggleFoldedActionCallback}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              {kanbanPositionIconId && (
                <FaIcon id={kanbanPositionIconId} size="small" additionalClass="m-r-sm" />
              )}
              <h5
                className="has-text-black has-text-weight-bold is-size-5 m-b-none"
                style={{ flexGrow: 1 }}
              >
                {infos.license}
                {contractCode && <span className="subtitle is-6">{` ${contractCode}`}</span>}
              </h5>
              {hasAMessage && (
                <FaIcon
                  additionalClass={`${requestMessageClassName} m-r-sm`}
                  id={SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID}
                />
              )}
              {isTruthy(foldable) && (
                <div>
                  <FaIcon id={`caret-${isExpanded ? 'down' : 'right'}`} />
                </div>
              )}
            </div>
            <TruncableP className="subtitle is-6" style={{ width: undefined, minWidth: 0 }}>
              {infos.model}
            </TruncableP>
          </ClickableElement>
        </div>
      </div>
      {isTruthy(unfoldable) && <div className="m-t-sm m-l-sm m-r-sm">{unfoldable}</div>}
      {isTruthy(foldable) && isExpanded && (
        <div className="m-t-sm m-l-sm m-r-sm">{foldable.content}</div>
      )}
      <div
        className="buttons are-smaller has-text-centered p-b-sm m-t-sm m-l-sm m-r-sm"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: hasMultipleActions ? 'wrap' : 'nowrap',
        }}
      >
        <div
          className="m-r-sm"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {isTruthy(actionDescs) &&
            actionDescs.length > 0 &&
            actionDescs.map(
              (ad): JSX.Element => (
                <ActionButtonComponent key={`${kanbanId}_${ad.id}`} actionDesc={ad} />
              )
            )}
        </div>
        <div className="p-b-sm" style={{ display: 'flex', flexDirection: 'row' }}>
          <FaIcon id="key" size="small-xs" additionalClass="m-r-xs" />
          <TruncableP className="subtitle is-7 mr-2" style={{ width: undefined, minWidth: 0 }}>
            {lastKnownLocation}
          </TruncableP>
        </div>
      </div>
    </div>
  );
}
