import type { EnergyValue, GearboxValue } from '@stimcar/libs-kernel';
import type {
  AttachmentGalleryDialogState,
  FormWithValidationState,
} from '@stimcar/libs-uitoolkit';
import { EMPTY_ATTACHMENT_GALLERY_STATE } from '@stimcar/libs-uitoolkit';

export type MarketplaceMessageDialogState = {
  readonly active: boolean;
};

export type MarketplaceEditMainSpecsForm = {
  readonly energy: EnergyValue;
  readonly gearbox: GearboxValue;
  readonly horsepower: string;
  readonly taxHorsepower: string;
  readonly doors: string;
  readonly seats: string;
  readonly ecoSticker: string;
};

export type MarketplaceEditMainSpecsDialogState =
  FormWithValidationState<MarketplaceEditMainSpecsForm> & {
    readonly active: boolean;
  };

export type MarketplaceEquipmentForm = {
  readonly selectedEquipments: readonly string[];
  readonly additionalEquipments: readonly string[];
  readonly newEquipmentValue: string;
};

export type MarketplaceEditEquipmentsForm = {
  readonly security: MarketplaceEquipmentForm;
  readonly comfort: MarketplaceEquipmentForm;
  readonly multimedia: MarketplaceEquipmentForm;
  readonly others: MarketplaceEquipmentForm;
};

export type MarketplaceEditEquipmentsDialogState =
  FormWithValidationState<MarketplaceEditEquipmentsForm> & {
    readonly active: boolean;
  };

export type MarketplaceOverviewState = {
  readonly inputPrice: string;
  readonly negotiation: string;
  readonly deposit: string;
  readonly canceledPackageDealIds: readonly string[];
  readonly isDropdownButtonActive: boolean;
  readonly overviewButtonDialog: MarketplaceMessageDialogState;
  readonly saveConfirmationDialog: MarketplaceMessageDialogState;
  readonly variableFeeConfirmationDialog: MarketplaceMessageDialogState;
};

export type MarketplaceAdPreviewState = {
  readonly htmlContent: string;
};

export const MKTP_TABS_OVERVIEW = 'overview';
export const MKTP_TABS_SPECS = 'specs';
export const MKTP_TABS_AD_PREVIEW = 'preview';
export const MKTP_TABS_PHOTO_GALLERY = 'photoAttachmentsGallery';
export const MKTP_TABS_TEXT_ATTACHMENT_GALLERY = 'textAttachmentsGallery';
export const MKTP_TABS = [
  MKTP_TABS_OVERVIEW,
  MKTP_TABS_SPECS,
  MKTP_TABS_AD_PREVIEW,
  MKTP_TABS_PHOTO_GALLERY,
  MKTP_TABS_TEXT_ATTACHMENT_GALLERY,
];
export type MarketplaceTabs = (typeof MKTP_TABS)[number];

export type MarketplaceDetailsState = {
  readonly marketplacePreview: MarketplaceAdPreviewState;
  readonly marketplaceOverview: MarketplaceOverviewState;
  readonly editMainSpecsDialog: MarketplaceEditMainSpecsDialogState;
  readonly editEquipmentsDialog: MarketplaceEditEquipmentsDialogState;
  readonly attachmentGalleryDialogModal: AttachmentGalleryDialogState;
  readonly selectedTab: MarketplaceTabs;
};

export const EMPTY_MARKETPLACE_EQUIPMENT_CATEGORY: MarketplaceEquipmentForm = {
  selectedEquipments: [],
  additionalEquipments: [],
  newEquipmentValue: '',
};

export const MARKETPLACE_DETAILS_EMPTY_STATE: MarketplaceDetailsState = {
  attachmentGalleryDialogModal: {
    active: false,
    ...EMPTY_ATTACHMENT_GALLERY_STATE,
  },
  selectedTab: 'overview',
  editMainSpecsDialog: {
    active: false,
    formSubmitClickedOnce: false,
    formSubmitted: false,
    formWarning: undefined,
    formData: {
      energy: '',
      gearbox: '',
      horsepower: '',
      taxHorsepower: '',
      doors: '',
      seats: '',
      ecoSticker: '',
      warnings: {},
    },
  },
  editEquipmentsDialog: {
    active: false,
    formSubmitClickedOnce: false,
    formSubmitted: false,
    formWarning: undefined,
    formData: {
      security: EMPTY_MARKETPLACE_EQUIPMENT_CATEGORY,
      comfort: EMPTY_MARKETPLACE_EQUIPMENT_CATEGORY,
      multimedia: EMPTY_MARKETPLACE_EQUIPMENT_CATEGORY,
      others: EMPTY_MARKETPLACE_EQUIPMENT_CATEGORY,
      warnings: {},
    },
  },
  marketplacePreview: {
    htmlContent: '',
  },
  marketplaceOverview: {
    inputPrice: '0',
    negotiation: '0',
    deposit: '0',
    canceledPackageDealIds: [],
    isDropdownButtonActive: false,
    overviewButtonDialog: {
      active: false,
    },
    saveConfirmationDialog: {
      active: false,
    },
    variableFeeConfirmationDialog: {
      active: false,
    },
  },
};
