import React from 'react';
import type { AnyStoreDef, NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { DeleteClickableIcon } from '../elements/DeleteClickableIcon.js';
import { ModalDialogWithoutDispatch } from './ModalDialog.js';

export interface BaseModalMessageProps {
  readonly children: JSX.Element;
  readonly title?: string;
}

export interface ModalMessageProps<SD extends AnyStoreDef> extends BaseModalMessageProps {
  readonly $active: StoreStateSelector<SD, boolean>;
  readonly onClose?: NoArgActionCallback<SD>;
}

export function ModalMessageDialog<SD extends AnyStoreDef>({
  $active,
  onClose,
  children,
  title,
}: ModalMessageProps<SD>): JSX.Element {
  const closeModalHandler = useActionCallback(
    async ({ globalActionDispatch, actionDispatch }): Promise<void> => {
      if (onClose) {
        await globalActionDispatch.execCallback(onClose);
      }
      actionDispatch.setValue(false);
    },
    [onClose],
    $active
  );

  return (
    <ModalMessageDialogWithoutDispatch
      closeHandler={closeModalHandler}
      isActive={useGetState($active)}
      title={title}
    >
      {children}
    </ModalMessageDialogWithoutDispatch>
  );
}

export interface ModalMessageDialogWithoutDispatchProps extends BaseModalMessageProps {
  readonly isActive: boolean;
  readonly closeHandler: () => Promise<void>;
}

export function ModalMessageDialogWithoutDispatch({
  isActive,
  closeHandler,
  children,
  title,
}: ModalMessageDialogWithoutDispatchProps): JSX.Element {
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <ModalDialogWithoutDispatch closeHandler={closeHandler} isActive={isActive}>
      <article className="message">
        {isTruthyAndNotEmpty(title) && (
          <div className="message-header">
            <span>{title}</span>
            <DeleteClickableIcon handler={closeHandler} />
          </div>
        )}
        <div className="message-body">{children}</div>
      </article>
    </ModalDialogWithoutDispatch>
  );
}
