/* eslint-disable jsx-a11y/control-has-associated-label */
import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  CarElement,
  CarViewCategory,
  Memo,
  MemoAutomaticPackageDealCreation,
  MemoDesc,
  MemoDescWithCategory,
  MemoPackageDealCreation,
  MemoType,
  PackageDeal,
  PartialBy,
  SelectMemoDesc,
  SparePartManagementType,
} from '@stimcar/libs-base';
import type {
  ActionCallback,
  ActionContext,
  ActionDispatch,
  NoArgAction,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { AppProps, FormFieldEntry } from '@stimcar/libs-uitoolkit';
import {
  contractHelpers,
  DateExpressionWrapper,
  forEachRecordValues,
  packageDealHelpers,
  transverseHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useRecordItemSelector,
  useSelectorWithChangeTrigger,
  useStateIsDefined,
} from '@stimcar/libs-uikernel';
import {
  BinaryChoiceAddOnButtons,
  Button,
  CalendarInput,
  ClickableIcon,
  Input,
  ModalCardDialog,
  ScrollableContainer,
  Select,
  TextArea,
} from '@stimcar/libs-uitoolkit';
import type { Store, StoreState } from '../../../state/typings/store.js';
import type {
  IncorrectPckDealAutoCreationExpressionModalState,
  MemosState,
  OperatorExpertViewState,
  ResetMemoModalState,
  UpperExpertiseTab,
} from '../../typings/store.js';
import { DisplayContentOrPlaceholder } from '../../../../lib/components/misc/DisplayContentOrPlaceholder.js';
import {
  addPackageDealAndRecomputeExpressions,
  isPackageDealAlreadyPresentInKanban,
  selectPackageDealAction,
  updateStandRelatedPackageDealsAndOperationsAction,
} from '../../expertiseUtils.js';
import { RESET_MEMO_MODAL_EMPTY_STATE } from '../../typings/store.js';
import { openDialogForAddition } from './EditPackageDealModalComponent.js';
import { openDialogForDeletion } from './ExpertPackageDealListComponent.js';

type MemoWithOptionalValue = PartialBy<Memo, 'value'>;

export function convertStringToMemoTypeValue(
  inputValue: string,
  type: MemoType
): string | boolean | number | undefined {
  if (isTruthyAndNotEmpty(inputValue)) {
    try {
      switch (type) {
        case 'boolean':
          if (inputValue === 'true') {
            return true;
          }
          if (inputValue === 'false') {
            return false;
          }
          break;
        case 'date':
        case 'numeric':
          return Number.parseFloat(inputValue);
        case 'select':
        case 'text':
        case 'textarea':
        default:
          return inputValue;
      }
    } catch {
      return undefined;
    }
  }
  return undefined;
}

function getPDCodeAndCarElement(
  memoPd?: string | MemoPackageDealCreation | MemoAutomaticPackageDealCreation
): { pdCode: string | undefined; carElementLabel: string | undefined } {
  let pdCode: string | undefined;
  let carElementLabel: string | undefined;
  if (typeof memoPd === 'string') {
    pdCode = memoPd;
  } else {
    pdCode = memoPd?.code;
    carElementLabel = memoPd?.element;
  }
  return { pdCode, carElementLabel };
}

async function addPackageDealAction(
  {
    getState,
    actionDispatch,
    globalActionDispatch,
    httpClient,
  }: ActionContext<Store, OperatorExpertViewState>,
  category: CarViewCategory,
  memoPackageDealCode: string | MemoPackageDealCreation | MemoAutomaticPackageDealCreation,
  directCreation: boolean,
  sparePartManagementType: SparePartManagementType,
  updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>
): Promise<void> {
  const { allPackageDealDescs, allCarElements, initialKanban, packageDealListComponentState } =
    getState();

  let carElement: CarElement | undefined;

  const { pdCode, carElementLabel } = getPDCodeAndCarElement(memoPackageDealCode);
  if (!isTruthyAndNotEmpty(pdCode)) {
    return;
  }

  const pdd = allPackageDealDescs.find((p) => p.code === pdCode);

  if (!isTruthy(pdd)) {
    globalActionDispatch.reduce((initial) => {
      return {
        ...initial,
        message: {
          type: 'error',
          title: i18next.t('operators:expertiseView.missingPDDCodeModal.title'),
          content: i18next.t('operators:expertiseView.missingPDDCodeModal.content', {
            pdd: pdCode,
          }),
        },
      };
    });
    return;
  }

  if (isTruthyAndNotEmpty(carElementLabel)) {
    carElement = allCarElements.find(
      ({ category: ceCategory, label }) => label === carElementLabel && ceCategory === category
    );
  } else {
    const carElements = allCarElements.filter((ce) => nonnull(pdd).carElementIds.includes(ce.id));
    const carElementFromCorrectCategory = carElements.filter((ce) => ce.category === category);

    if (carElementFromCorrectCategory.length > 1) {
      globalActionDispatch.reduce((initial) => {
        return {
          ...initial,
          message: {
            type: 'error',
            title: i18next.t('operators:expertiseView.nonImplicitCarElementModal.title'),
            content: i18next.t('operators:expertiseView.nonImplicitCarElementModal.content', {
              pdd: pdCode,
            }),
          },
        };
      });
      return;
    }
    if (carElementFromCorrectCategory.length === 0 && carElements.length > 0) {
      globalActionDispatch.reduce((initial) => {
        return {
          ...initial,
          message: {
            type: 'error',
            title: i18next.t('operators:expertiseView.carElementIncorrectCategoryModal.title'),
            content: i18next.t('operators:expertiseView.carElementIncorrectCategoryModal.content', {
              carElementLabel: carElements[0].label ?? '',
              carElementCategory: carElements[0].category ?? '',
              memoCategory: category,
              pdCode,
            }),
          },
        };
      });
      return;
    }
    // eslint-disable-next-line prefer-destructuring
    carElement = carElementFromCorrectCategory[0];
  }

  if (category !== 'MISC' && !carElement) {
    actionDispatch.scopeProperty('memosState').reduce((initial) => {
      return {
        ...initial,
        carElementNotFoundModal: {
          active: true,
          carElementLabel: carElementLabel ?? '',
          category,
          pddCode: pdCode,
        },
      };
    });
    return;
  }

  if (directCreation) {
    const pd = packageDealHelpers.createPackageDealFromPackageDealDesc(
      httpClient.getBrowserSequence(),
      pdd,
      carElement,
      sparePartManagementType
    );

    const isPresent = isPackageDealAlreadyPresentInKanban(
      packageDealListComponentState.packageDeals,
      pd
    );
    if (isPresent) {
      actionDispatch.scopeProperty('memosState').reduce((initial) => {
        return {
          ...initial,
          duplicatedPDDFromMemoModalState: {
            active: true,
            duplicatedPackageDeal: pd,
          },
        };
      });
      return;
    }

    const packageDeals = addPackageDealAndRecomputeExpressions(
      'packageDealDesc',
      initialKanban,
      packageDealListComponentState.packageDeals,
      pd
    );
    actionDispatch.reduce((initial) => {
      return {
        ...initial,
        packageDealListComponentState: {
          ...initial.packageDealListComponentState,
          packageDeals,
        },
      };
    });
    await actionDispatch.execCallback(updateStandRelatedPackageDealsAndOperationsActionCallback);
    await actionDispatch.exec(selectPackageDealAction, pd.id);
  } else {
    await actionDispatch.exec(
      openDialogForAddition,
      nonnull(pdd?.id),
      sparePartManagementType,
      carElement
    );
  }
}

function hasAPckDealAutomaticCreationFunction(memoDesc: MemoDesc): boolean {
  return (
    isTruthy(memoDesc.packageDeal) &&
    typeof memoDesc.packageDeal === 'object' &&
    isTruthy((memoDesc.packageDeal as MemoAutomaticPackageDealCreation).isToCreateExpression)
  );
}

function findPackageDealByCodeAndElementLabel(
  existingPackageDeals: readonly PackageDeal[],
  autoPckCode: string,
  autoPckElement: string
): PackageDeal | undefined {
  return existingPackageDeals.find(
    (pd) =>
      !pd.deleted && pd.code === autoPckCode && (pd.carElement?.label ?? '') === autoPckElement
  );
}

async function removePckOrWarnIfStarted(
  globalActionDispatch: ActionDispatch<Store, StoreState>,
  actionDispatch: ActionDispatch<Store, OperatorExpertViewState>,
  packageDeals: readonly PackageDeal[],
  pckCode: string,
  pckElement: string | undefined
): Promise<void> {
  const pck = findPackageDealByCodeAndElementLabel(packageDeals, pckCode, pckElement ?? '');
  if (isTruthy(pck)) {
    if (packageDealHelpers.getFinishedOperations(pck).length > 0) {
      globalActionDispatch.reduce((initial) => {
        return {
          ...initial,
          message: i18next.t('operators:expertiseView.memo.cannotRemoveStartedPckError', {
            pckCode,
          }),
        };
      });
    } else {
      await actionDispatch.exec(openDialogForDeletion, pck.id);
    }
  }
}

const doAddOrRemovePckDealDependingOnMemoValue = async (
  {
    getState,
    actionDispatch,
    globalActionDispatch,
    getGlobalState,
  }: ActionContext<Store, OperatorExpertViewState>,
  globalVariables: Record<string, number | string>,
  memoDesc: MemoDescWithCategory | undefined,
  origin: 'memo' | 'reset' | 'na',
  updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>
): Promise<void> => {
  const { initialKanban } = getState();

  if (isTruthy(memoDesc)) {
    const { memos } = getState().memosState;
    const memoStringValue = memos[memoDesc.id];
    const memoValue = convertStringToMemoTypeValue(memoStringValue, memoDesc.type);

    let expressionMemoValue: string | boolean | number | undefined | DateExpressionWrapper =
      memoValue;
    // handle automatic package deal creation
    if (hasAPckDealAutomaticCreationFunction(memoDesc)) {
      const { code, element, isToCreateExpression } =
        memoDesc.packageDeal as MemoAutomaticPackageDealCreation;
      if (origin !== 'reset') {
        if (memoDesc.type === 'date') {
          if (origin === 'na') {
            expressionMemoValue = DateExpressionWrapper.createEmptyDateExpressionWrapper();
          } else if (typeof memoValue === 'number') {
            expressionMemoValue =
              DateExpressionWrapper.createDateExpressionWrapperFromTimestamp(memoValue);
          }
        }
        const variables = [];
        const variableValues = [];
        forEachRecordValues(globalVariables, (value, key) => {
          variables.push(key);
          variableValues.push(value);
        });
        variables.push('customer');
        variableValues.push(initialKanban.customer.shortName);
        variables.push('registrationDate');
        variableValues.push(
          DateExpressionWrapper.createDateExpressionWrapperFromTimestamp(
            initialKanban.infos.dateOfRegistration ?? 0
          )
        );
        variables.push('value');
        variableValues.push(expressionMemoValue);

        try {
          // eslint-disable-next-line no-new-func
          const isToCreateFunction = Function(...variables, isToCreateExpression);
          const isToCreate = isToCreateFunction(...variableValues);
          if (typeof isToCreate !== 'boolean' && isToCreate !== undefined) {
            throw Error();
          }
          if (isToCreate !== undefined) {
            if (isToCreate) {
              const { contracts } = getGlobalState();
              const contract = nonnull(
                contractHelpers.findContractByCode(contracts, initialKanban.contract.code)
              );
              await actionDispatch.exec(
                addPackageDealAction,
                memoDesc.category,
                nonnull(memoDesc.packageDeal),
                true,
                contract.sparePartManagementType,
                updateStandRelatedPackageDealsAndOperationsActionCallback
              );
            } else {
              const { packageDeals } = getState().packageDealListComponentState;
              await removePckOrWarnIfStarted(
                globalActionDispatch,
                actionDispatch,
                packageDeals,
                code,
                element
              );
            }
          }
        } catch {
          actionDispatch.reduce((initial) => {
            return {
              ...initial,
              memosState: {
                ...initial.memosState,
                incorrectPckDealAutoCreationExpressionModal: {
                  active: true,
                  pckAutoCreationDefinition:
                    memoDesc.packageDeal as MemoAutomaticPackageDealCreation,
                },
              },
            };
          });
        }
      } else {
        const { packageDeals } = getState().packageDealListComponentState;
        await removePckOrWarnIfStarted(
          globalActionDispatch,
          actionDispatch,
          packageDeals,
          code,
          element
        );
      }
    }

    if (memoValue !== undefined) {
      actionDispatch.scopeProperty('memosState').reduce((initial) => {
        return {
          ...initial,
          userDefinedNullMemos: initial.userDefinedNullMemos.filter(
            (m) => m.id !== memoDesc.id || m.category !== memoDesc.category
          ),
        };
      });
    }
  }
};

const useCreateOnChangeCallback = (
  $: StoreStateSelector<Store, OperatorExpertViewState>,
  globalVariables: Record<string, number | string>,
  memoDesc: MemoDescWithCategory | undefined,
  updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ActionCallback<Store, NoArgAction<Store, any>> => {
  return useActionCallback(
    async (context) => {
      await doAddOrRemovePckDealDependingOnMemoValue(
        context,
        globalVariables,
        memoDesc,
        'memo',
        updateStandRelatedPackageDealsAndOperationsActionCallback
      );
    },
    [globalVariables, memoDesc, updateStandRelatedPackageDealsAndOperationsActionCallback],
    $
  );
};

async function resetMemoAction(
  context: ActionContext<Store, OperatorExpertViewState>,
  globalVariables: Record<string, number | string>,
  memoDescs: Readonly<Record<CarViewCategory, readonly MemoDesc[]>>,
  updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>
): Promise<void> {
  const { getState, actionDispatch } = context;
  const { resetMemoModalState } = getState().memosState;
  const { memoIdToReset, memoToResetCategory } = resetMemoModalState;
  if (isTruthy(memoIdToReset)) {
    actionDispatch.scopeProperty('memosState').reduce((initial) => {
      return {
        ...initial,
        memos: {
          ...initial.memos,
          [memoIdToReset]: '',
        },
        userDefinedNullMemos: initial.userDefinedNullMemos.filter(
          (m) => m.id !== memoIdToReset || m.category !== memoToResetCategory
        ),
        resetMemoModalState: RESET_MEMO_MODAL_EMPTY_STATE,
      };
    });
    const memoDesc = transverseHelpers.getMemoDescWithCategory(memoDescs, memoIdToReset);
    await doAddOrRemovePckDealDependingOnMemoValue(
      context,
      globalVariables,
      memoDesc,
      'reset',
      updateStandRelatedPackageDealsAndOperationsActionCallback
    );
  }
}

interface BaseInputForMemoProps {
  readonly isEditionDisabled: boolean;
  readonly memoDesc: MemoDescWithCategory | undefined;
  readonly globalVariables: Record<string, number | string>;
  readonly updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>;
}

interface InputForMemoProps extends BaseInputForMemoProps {
  readonly $memo: StoreStateSelector<Store, string>;
  readonly $expertView: StoreStateSelector<Store, OperatorExpertViewState>;
}

interface TextNumberInputForMemoProps extends InputForMemoProps {
  readonly type: 'text' | 'numeric';
  readonly updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>;
}

export function InputForMemo({
  $memo,
  $expertView,
  isEditionDisabled,
  type,
  memoDesc,
  globalVariables,
  updateStandRelatedPackageDealsAndOperationsActionCallback,
}: TextNumberInputForMemoProps): JSX.Element {
  const onChangeCallback = useCreateOnChangeCallback(
    $expertView,
    globalVariables,
    memoDesc,
    updateStandRelatedPackageDealsAndOperationsActionCallback
  );
  const $memoStateWithChangeTrigger = useSelectorWithChangeTrigger($memo, onChangeCallback);
  return (
    <Input
      placeholder={$memo.key}
      type={type === 'numeric' ? 'number' : type}
      $={$memoStateWithChangeTrigger}
      style={{ fontSize: '0.75em' }}
      disabled={isEditionDisabled}
    />
  );
}

export function CalendarForMemo({
  $memo,
  $expertView: $expertViewStateSelector,
  isEditionDisabled,
  memoDesc,
  globalVariables,
  updateStandRelatedPackageDealsAndOperationsActionCallback,
}: InputForMemoProps): JSX.Element {
  const onChangeCallback = useCreateOnChangeCallback(
    $expertViewStateSelector,
    globalVariables,
    memoDesc,
    updateStandRelatedPackageDealsAndOperationsActionCallback
  );
  const $memoValueWithChangeTrigger = useSelectorWithChangeTrigger($memo, onChangeCallback);
  return (
    <CalendarInput
      $={$memoValueWithChangeTrigger}
      hideInputClearButton
      className="input"
      isDisabled={isEditionDisabled}
      dateAsTextMode
    />
  );
}

export function TextAreaForMemo({
  $memo,
  $expertView: $expertViewStateSelector,
  isEditionDisabled,
  memoDesc,
  globalVariables,
  updateStandRelatedPackageDealsAndOperationsActionCallback,
}: InputForMemoProps): JSX.Element {
  const onChangeCallback = useCreateOnChangeCallback(
    $expertViewStateSelector,
    globalVariables,
    memoDesc,
    updateStandRelatedPackageDealsAndOperationsActionCallback
  );
  return (
    <TextArea
      placeholder={$memo.key}
      className="input"
      $={useSelectorWithChangeTrigger($memo, onChangeCallback)}
      style={{ fontSize: '0.75em' }}
      disabled={isEditionDisabled}
    />
  );
}

export function ChoiceAddOnButtonsForMemo({
  $memo,
  $expertView: $expertViewStateSelector,
  isEditionDisabled,
  memoDesc,
  globalVariables,
  updateStandRelatedPackageDealsAndOperationsActionCallback,
}: InputForMemoProps): JSX.Element {
  const [t] = useTranslation('libComponents');
  const onChangeCallback = useCreateOnChangeCallback(
    $expertViewStateSelector,
    globalVariables,
    memoDesc,
    updateStandRelatedPackageDealsAndOperationsActionCallback
  );
  const $memoValueWithChangeTrigger = useSelectorWithChangeTrigger($memo, onChangeCallback);
  return (
    <BinaryChoiceAddOnButtons
      $={$memoValueWithChangeTrigger}
      disabled={isEditionDisabled}
      leftButton={{
        id: 'true',
        label: t('memo.booleanTrue'),
        selectedClass: 'is-primary',
      }}
      rightButton={{
        id: 'false',
        label: t('memo.booleanFalse'),
        selectedClass: 'is-primary',
      }}
      size="small"
    />
  );
}

export interface SelectForMemoProps extends InputForMemoProps {
  readonly entries: FormFieldEntry<string>[];
}

export function SelectForMemo({
  $memo,
  $expertView: $expertViewStateSelector,
  entries,
  isEditionDisabled,
  memoDesc,
  globalVariables,
  updateStandRelatedPackageDealsAndOperationsActionCallback,
}: SelectForMemoProps): JSX.Element {
  const onChangeCallback = useCreateOnChangeCallback(
    $expertViewStateSelector,
    globalVariables,
    memoDesc,
    updateStandRelatedPackageDealsAndOperationsActionCallback
  );
  const $memoStateSelectorWithChangeTrigger = useSelectorWithChangeTrigger($memo, onChangeCallback);
  return (
    <Select
      $={$memoStateSelectorWithChangeTrigger}
      disabled={isEditionDisabled}
      isFullWidth
      isEmptyValueAllowed
      entries={entries}
      isSmall
    />
  );
}

function sortMemosByConf(
  memos: Record<string, MemoWithOptionalValue>,
  memoDescs: readonly MemoDesc[]
): Record<string, MemoWithOptionalValue> {
  const sortedMemos: Record<string, MemoWithOptionalValue> = {};

  // memoDescs contains the memos in the correct order
  // we use this order to populate the sorted memos array
  memoDescs.forEach((memoDesc) => {
    const { id } = memoDesc;
    if (memos[id]) {
      sortedMemos[id] = memos[id];
    }
  });

  return sortedMemos;
}

interface ActualInputProps extends BaseInputForMemoProps {
  readonly singleMemo: PartialBy<Memo, 'value'>;
  readonly memoId: string;
  readonly $: StoreStateSelector<Store, OperatorExpertViewState>;
  readonly entriesPerMemoId: Record<string, FormFieldEntry<string>[]>;
}

function ActualInput({
  singleMemo,
  memoId,
  $,
  entriesPerMemoId,
  ...baseProps
}: ActualInputProps): JSX.Element {
  const $memoStateSelector = useRecordItemSelector($.$memosState.$memos, memoId);
  const $expertViewStateSelector = $;

  if (singleMemo.type === 'boolean') {
    return (
      <ChoiceAddOnButtonsForMemo
        $memo={$memoStateSelector}
        $expertView={$expertViewStateSelector}
        {...baseProps}
      />
    );
  }
  if (singleMemo.type === 'select') {
    return (
      <SelectForMemo
        $memo={$memoStateSelector}
        $expertView={$expertViewStateSelector}
        entries={entriesPerMemoId[memoId]}
        {...baseProps}
      />
    );
  }
  if (singleMemo.type === 'textarea') {
    return (
      <TextAreaForMemo
        $memo={$memoStateSelector}
        $expertView={$expertViewStateSelector}
        {...baseProps}
      />
    );
  }
  if (singleMemo.type === 'date') {
    return (
      <CalendarForMemo
        $memo={$memoStateSelector}
        $expertView={$expertViewStateSelector}
        {...baseProps}
      />
    );
  }
  return (
    <InputForMemo
      $memo={$memoStateSelector}
      $expertView={$expertViewStateSelector}
      type={singleMemo.type}
      {...baseProps}
    />
  );
}

interface CommentTableRow {
  readonly t: TFunction<'operators', undefined>;
  readonly disabled: boolean;
  readonly $: StoreStateSelector<Store, string>;
}

function CommentTableRow({ t, disabled, $ }: CommentTableRow) {
  return (
    <tr>
      <td style={{ width: '25%' }}>
        <label className="label">{t('comment')}</label>
      </td>
      <td colSpan={4}>
        <TextArea
          className="input"
          disabled={disabled}
          style={{ fontSize: '0.75em' }}
          placeholder={t('comment')}
          $={$}
        />
      </td>
    </tr>
  );
}

export interface TabledMemoInputProps extends AppProps<Store> {
  readonly memos: Record<string, Memo>;
  readonly memoDescs: Readonly<Record<CarViewCategory, readonly MemoDesc[]>>;
  readonly selectedExpertiseTab: UpperExpertiseTab;
  readonly height?: number;
  readonly isEditionDisabled: boolean;
  readonly globalVariables: Record<string, number | string>;
}

export function TabledMemoInput({
  memos,
  memoDescs,
  selectedExpertiseTab,
  height,
  isEditionDisabled,
  globalVariables,
  $gs,
}: TabledMemoInputProps): JSX.Element {
  const [t] = useTranslation('operators');

  const { $expertOperatorState } = $gs.$operatorView;
  const $ = $expertOperatorState.$memosState;
  const { $duplicatedPDDFromMemoModalState, $carElementNotFoundModal } = $;

  const initialKanban = useGetState($expertOperatorState.$initialKanban);
  const contracts = useGetState($gs.$contracts);

  const contract = useMemo(() => {
    return nonnull(contractHelpers.findContractByCode(contracts, initialKanban.contract.code));
  }, [initialKanban.contract, contracts]);

  const mergedMemos = useMemo((): Record<string, MemoWithOptionalValue> => {
    const kanbansAllMemos: Record<string, MemoWithOptionalValue> = { ...memos };
    keysOf(memoDescs).forEach((category) => {
      const categoryMemoDescs: readonly MemoDesc[] | undefined = memoDescs[category];
      if (isTruthy(categoryMemoDescs)) {
        categoryMemoDescs.forEach((curMemoDesc) => {
          if ((!memos || !memos[curMemoDesc.id]) && category === selectedExpertiseTab) {
            kanbansAllMemos[curMemoDesc.id] = { category, type: curMemoDesc.type };
          }
        });
      }
    });
    const result: Record<string, MemoWithOptionalValue> = {};
    keysOf(kanbansAllMemos).forEach((memoId) => {
      if (kanbansAllMemos[memoId] && kanbansAllMemos[memoId].category === selectedExpertiseTab) {
        result[memoId] = kanbansAllMemos[memoId];
      }
    });
    return result;
  }, [memoDescs, memos, selectedExpertiseTab]);

  const entriesPerMemoId = useMemo((): Record<string, FormFieldEntry<string>[]> => {
    const result: Record<string, FormFieldEntry<string>[]> = {};
    if (mergedMemos) {
      keysOf(mergedMemos).forEach((key) => {
        if (mergedMemos[key].type === 'select') {
          const curMemoEntries: FormFieldEntry<string>[] = [];
          const possibleValues =
            (transverseHelpers.getMemoDescWithCategory(memoDescs, key) as SelectMemoDesc)
              ?.possibleValues ?? [];
          possibleValues.forEach((curValue) => {
            curMemoEntries.push({
              id: curValue,
              label: curValue,
            });
          });
          result[key] = curMemoEntries;
        }
      });
    }
    return result;
  }, [memoDescs, mergedMemos]);

  const { hasAMemoWithHint, hasAMemoWithNAValue } = useMemo(() => {
    let withHint = false;
    let withNAValue = false;
    for (const key of keysOf(memoDescs)) {
      const mds = memoDescs[key];
      if (mds !== undefined) {
        for (const md of mds) {
          if (isTruthyAndNotEmpty(md.hint)) {
            withHint = true;
          }
          if (md.additionalBehavior === 'naAllowed') {
            withNAValue = true;
          }
        }
      }
    }
    return { hasAMemoWithHint: withHint, hasAMemoWithNAValue: withNAValue };
  }, [memoDescs]);

  const selectedCategory: CarViewCategory = selectedExpertiseTab as CarViewCategory;
  const memoDescsForCategory: readonly MemoDesc[] = memoDescs[selectedCategory] || [];
  const isMisc = useMemo(() => selectedCategory === 'MISC', [selectedCategory]);
  const carElementLabelOfModal = useGetState($carElementNotFoundModal.$carElementLabel);
  const categoryOfModal = useGetState($carElementNotFoundModal.$category);
  const pddCodeOfModal = useGetState($carElementNotFoundModal.$pddCode);
  const duplicatedPackageDeal = useGetState(
    $duplicatedPDDFromMemoModalState.$duplicatedPackageDeal
  );

  const updateStandRelatedPackageDealsAndOperationsActionCallback = useActionCallback(
    updateStandRelatedPackageDealsAndOperationsAction,
    [],
    $gs.$operatorView
  );

  return (
    <>
      <DisplayContentOrPlaceholder
        displayCondition={keysOf(mergedMemos).length > 0}
        placeholder={t('expertiseView.memo.emptyPlaceholder')}
        isScrollable
        scrollableProps={{ sizeInPx: height }}
      >
        <ScrollableContainer height={`${height}PX`} className="p-t-sm">
          <table className="table is-striped is-narrow is-fullwidth">
            <tbody>
              {mergedMemos &&
                keysOf(sortMemosByConf(mergedMemos, memoDescsForCategory)).map(
                  (key): JSX.Element => {
                    return (
                      <MemoRow
                        key={key}
                        memoId={key}
                        entriesPerMemoId={entriesPerMemoId}
                        memoDesc={transverseHelpers.getMemoDescWithCategory(memoDescs, key)}
                        memo={mergedMemos[key]}
                        $={$expertOperatorState}
                        isEditionDisabled={isEditionDisabled}
                        globalVariables={globalVariables}
                        hasAMemoWithHint={hasAMemoWithHint}
                        hasAMemoWithNAValue={hasAMemoWithNAValue}
                        sparePartManagementType={contract.sparePartManagementType}
                        updateStandRelatedPackageDealsAndOperationsActionCallback={
                          updateStandRelatedPackageDealsAndOperationsActionCallback
                        }
                      />
                    );
                  }
                )}
              {isMisc && (
                <CommentTableRow
                  t={t}
                  disabled={isEditionDisabled}
                  $={$expertOperatorState.$initialKanban.$estimateComment}
                />
              )}
            </tbody>
          </table>
        </ScrollableContainer>
      </DisplayContentOrPlaceholder>

      <ResetMemoModal
        globalVariables={globalVariables}
        memoDescs={memoDescs}
        $={$expertOperatorState}
        updateStandRelatedPackageDealsAndOperationsActionCallback={
          updateStandRelatedPackageDealsAndOperationsActionCallback
        }
      />
      <ModalCardDialog
        title={t('expertiseView.duplicatedPDDFromMemoModal.title')}
        $active={$duplicatedPDDFromMemoModalState.$active}
      >
        {useStateIsDefined(
          $duplicatedPDDFromMemoModalState.$duplicatedPackageDeal.optChaining().$carElement
        )
          ? t('expertiseView.duplicatedPDDFromMemoModal.contentWithCarElement', {
              pdCode: duplicatedPackageDeal?.code,
              carElement: nonnull(duplicatedPackageDeal?.carElement).label,
            })
          : t('expertiseView.duplicatedPDDFromMemoModal.contentWithoutCarElement', {
              pdCode: duplicatedPackageDeal?.code,
            })}
      </ModalCardDialog>
      <ModalCardDialog
        $active={$carElementNotFoundModal.$active}
        title={t('expertiseView.carElementNotFoundModal.title')}
      >
        {t('expertiseView.carElementNotFoundModal.content', {
          carElementLabel: carElementLabelOfModal,
          category: categoryOfModal,
          pddCode: pddCodeOfModal,
        })}
      </ModalCardDialog>
    </>
  );
}

interface IncorrectPckDealAutoCreationExpressionModalProps {
  readonly $: StoreStateSelector<Store, IncorrectPckDealAutoCreationExpressionModalState>;
}

export function IncorrectPckDealAutoCreationExpressionModal({
  $,
}: IncorrectPckDealAutoCreationExpressionModalProps): JSX.Element {
  const [t] = useTranslation('operators');

  const pckAutoCreationDefinition = useGetState($.$pckAutoCreationDefinition);

  return (
    <ModalCardDialog
      $active={$.$active}
      title={t('expertiseView.incorrectPckDealAutoCreationExpressionModal.title')}
    >
      {isTruthy(pckAutoCreationDefinition?.element)
        ? t('expertiseView.incorrectPckDealAutoCreationExpressionModal.contentWithCarElement', {
            pdCode: pckAutoCreationDefinition?.code,
            carElement: pckAutoCreationDefinition?.element,
          })
        : t('expertiseView.incorrectPckDealAutoCreationExpressionModal.contentWithoutCarElement', {
            pdCode: pckAutoCreationDefinition?.code,
          })}
    </ModalCardDialog>
  );
}

interface ResetMemoModalProps {
  readonly $: StoreStateSelector<Store, OperatorExpertViewState>;
  readonly memoDescs: Readonly<Record<CarViewCategory, readonly MemoDesc[]>>;
  readonly globalVariables: Record<string, number | string>;
  readonly updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>;
}

function ResetMemoModal({
  $,
  memoDescs,
  globalVariables,
  updateStandRelatedPackageDealsAndOperationsActionCallback,
}: ResetMemoModalProps): JSX.Element {
  const [t] = useTranslation('operators');
  const { $memosState } = $;
  const { $resetMemoModalState } = $memosState;

  const packageDeals = useGetState($.$packageDealListComponentState.$packageDeals);
  const memoIdToReset = useGetState($resetMemoModalState.$memoIdToReset);

  const packageDealToRemove = useMemo(() => {
    const memoToReset = transverseHelpers.getMemoDescWithCategory(memoDescs, memoIdToReset);
    const { pdCode, carElementLabel } = getPDCodeAndCarElement(memoToReset?.packageDeal);
    return findPackageDealByCodeAndElementLabel(packageDeals, pdCode ?? '', carElementLabel ?? '');
  }, [memoDescs, packageDeals, memoIdToReset]);

  const resetMemoActionCallback = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(
        resetMemoAction,
        globalVariables,
        memoDescs,
        updateStandRelatedPackageDealsAndOperationsActionCallback
      ),
    [globalVariables, memoDescs, updateStandRelatedPackageDealsAndOperationsActionCallback],
    $
  );

  return (
    <ModalCardDialog
      title={t('expertiseView.resetMemoModal.resetMemoTitle')}
      $active={$resetMemoModalState.$active}
      okLabel={t('expertiseView.resetMemoModal.deleteMemoButton')}
      onOkClicked={resetMemoActionCallback}
    >
      <p>
        {t('expertiseView.resetMemoModal.deleteMemoMsg', {
          memoId: memoIdToReset,
        })}
      </p>
      {isTruthy(packageDealToRemove) && (
        <p>
          {t('expertiseView.resetMemoModal.deletePackageAdditionalMessage', {
            pckCode: packageDealToRemove.code,
          })}
        </p>
      )}
    </ModalCardDialog>
  );
}

interface ResetMemoButtonProps extends Pick<BaseInputForMemoProps, 'isEditionDisabled'> {
  readonly id: string;
  readonly $: StoreStateSelector<Store, OperatorExpertViewState>;
  readonly showResetMemoValueModal: NoArgActionCallback<Store>;
}

function ResetMemoButton({
  $,
  id,
  isEditionDisabled,
  showResetMemoValueModal,
}: ResetMemoButtonProps): JSX.Element {
  const [t] = useTranslation('operators');
  const $memoStateSelector = useRecordItemSelector($.$memosState.$memos, id);
  const memoValue = useGetState($memoStateSelector);
  const isResetButtonDisabled = useMemo((): boolean => {
    let value = false;
    if (isEditionDisabled) {
      value = true;
    } else if (!memoValue || memoValue === '') {
      value = true;
    }
    return value;
  }, [isEditionDisabled, memoValue]);

  return (
    <Button
      additionalClass="is-small is-transparent m-none"
      onClick={showResetMemoValueModal}
      iconId="times"
      disabled={isResetButtonDisabled}
      tooltip={t('expertiseView.memo.clearValue')}
    />
  );
}

interface TableMemoLineProps {
  readonly memoId: string;
  readonly entriesPerMemoId: Record<string, FormFieldEntry<string>[]>;
  readonly memo: PartialBy<Memo, 'value'>;
  readonly memoDesc: MemoDescWithCategory | undefined;
  readonly $: StoreStateSelector<Store, OperatorExpertViewState>;
  readonly isEditionDisabled: boolean;
  readonly globalVariables: Record<string, number | string>;
  readonly hasAMemoWithHint: boolean;
  readonly hasAMemoWithNAValue: boolean;
  readonly sparePartManagementType: SparePartManagementType;
  readonly updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>;
}

function MemoRow({
  memoId,
  entriesPerMemoId,
  memo,
  memoDesc,
  $,
  isEditionDisabled,
  globalVariables,
  hasAMemoWithHint,
  hasAMemoWithNAValue,
  sparePartManagementType,
  updateStandRelatedPackageDealsAndOperationsActionCallback,
}: TableMemoLineProps): JSX.Element {
  const [t] = useTranslation('operators');

  const { $memosState, $packageDealListComponentState } = $;
  const { $resetMemoModalState } = $memosState;

  const addPackageDealCallback = useActionCallback(
    async ({ actionDispatch }) => {
      if (isTruthy(memoDesc) && isTruthy(memoDesc.packageDeal)) {
        await actionDispatch.exec(
          addPackageDealAction,
          memo.category,
          memoDesc.packageDeal,
          false,
          sparePartManagementType,
          updateStandRelatedPackageDealsAndOperationsActionCallback
        );
      }
    },
    [
      memo.category,
      memoDesc,
      sparePartManagementType,
      updateStandRelatedPackageDealsAndOperationsActionCallback,
    ],
    $
  );

  const showResetMemoValueModal = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.reduce(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (initial: ResetMemoModalState): ResetMemoModalState => {
          return {
            ...RESET_MEMO_MODAL_EMPTY_STATE,
            active: true,
            memoIdToReset: memoId,
            memoToResetCategory: memo.category,
          };
        }
      );
    },
    [memo.category, memoId],
    $resetMemoModalState
  );

  const allPackageDealDescs = useGetState($.$allPackageDealDescs);

  const foundPackageDealDesc = useMemo(
    () =>
      allPackageDealDescs.find(
        (p) => p.code === getPDCodeAndCarElement(memoDesc?.packageDeal).pdCode
      ),
    [allPackageDealDescs, memoDesc?.packageDeal]
  );

  const allCarElements = useGetState($.$allCarElements);

  const pdCodesAndCarElements = useMemo(() => {
    const { pdCode, carElementLabel } = getPDCodeAndCarElement(memoDesc?.packageDeal);
    let computedCarElementLabel: string | undefined;
    if (foundPackageDealDesc && !isTruthyAndNotEmpty(carElementLabel)) {
      const carElements = allCarElements
        .filter((ce) => foundPackageDealDesc.carElementIds.includes(ce.id))
        .filter((ce) => ce.category === memo.category);
      computedCarElementLabel = carElements.length > 0 ? carElements[0].label : '';
    }
    return { pdCode, carElementLabel: carElementLabel ?? computedCarElementLabel };
  }, [allCarElements, foundPackageDealDesc, memo.category, memoDesc?.packageDeal]);

  const packageDeals = useGetState($packageDealListComponentState.$packageDeals);

  // FIXME we should not create inner components like this
  const showAddPDDButton = (): JSX.Element => {
    let allPackageDealsAlreadyPresent = true;

    const { pdCode, carElementLabel } = pdCodesAndCarElements;
    if (foundPackageDealDesc) {
      allPackageDealsAlreadyPresent =
        packageDeals
          .filter((pd) => !pd.deleted)
          .find((pd) => {
            return (
              pd.code === pdCode &&
              (pd.carElement?.category ?? 'MISC') === memo.category &&
              (pd.carElement?.label ?? '') === carElementLabel
            );
          }) !== undefined;
    }

    return (
      <ClickableIcon
        clickHandler={addPackageDealCallback}
        id="cart-plus"
        isDisabled={isEditionDisabled || allPackageDealsAlreadyPresent}
        tooltip={
          allPackageDealsAlreadyPresent
            ? t('expertiseView.memo.addPDDButtonDisabledTooltip', {
                value: foundPackageDealDesc?.label,
              })
            : t('expertiseView.memo.addPDDButtonTooltip', { value: foundPackageDealDesc?.label })
        }
      />
    );
  };

  const userDefinedNullMemos = useGetState($memosState.$userDefinedNullMemos);
  const isNAMemo =
    userDefinedNullMemos.find((m) => m.id === memoId && m.category === memo.category) !== undefined;
  const naActionCallback = useActionCallback(
    async (context) => {
      if (isNAMemo) {
        return;
      }
      context.actionDispatch.scopeProperty('memosState').reduce((initial): MemosState => {
        return {
          ...initial,
          memos: {
            ...initial.memos,
            [memoId]: '',
          },
          userDefinedNullMemos: [...initial.userDefinedNullMemos, { ...memo, id: memoId }],
        };
      });
      await doAddOrRemovePckDealDependingOnMemoValue(
        context,
        globalVariables,
        memoDesc,
        'na',
        updateStandRelatedPackageDealsAndOperationsActionCallback
      );
    },
    [
      globalVariables,
      isNAMemo,
      memo,
      memoDesc,
      memoId,
      updateStandRelatedPackageDealsAndOperationsActionCallback,
    ],
    $
  );

  return (
    <tr>
      <td style={{ width: '25%' }}>
        <label className="label">{memoId}</label>
      </td>
      <td>
        <ActualInput
          singleMemo={memo}
          memoId={memoId}
          $={$}
          entriesPerMemoId={entriesPerMemoId}
          isEditionDisabled={isEditionDisabled}
          memoDesc={memoDesc}
          globalVariables={globalVariables}
          updateStandRelatedPackageDealsAndOperationsActionCallback={
            updateStandRelatedPackageDealsAndOperationsActionCallback
          }
        />
      </td>
      {hasAMemoWithHint && <td style={{ width: '35%' }}>{memoDesc?.hint}</td>}
      {hasAMemoWithNAValue && (
        <td style={{ width: '0%' }}>
          {memoDesc?.additionalBehavior === 'naAllowed' && (
            <Button
              onClick={naActionCallback}
              label={t('expertiseView.memo.naMemoLabel')}
              tooltip={t('expertiseView.memo.naMemoTooltip')}
              additionalClass={isNAMemo ? 'is-primary' : ''}
              disabled={isEditionDisabled}
            />
          )}
        </td>
      )}
      <td style={{ width: '0%' }}>
        {memoDesc?.additionalBehavior !== 'naAllowed' && (
          <ResetMemoButton
            $={$}
            id={memoId}
            isEditionDisabled={isEditionDisabled}
            showResetMemoValueModal={showResetMemoValueModal}
          />
        )}
      </td>
      <td style={{ width: '0%' }}>
        {foundPackageDealDesc !== undefined ? showAddPDDButton() : <></>}
      </td>
    </tr>
  );
}
