import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import type {
  KanbanIdentity,
  Mutable,
  WORKFLOW_ID_TYPE,
  WorkflowNode,
  WorkflowsState,
  WorkflowState,
} from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  EMPTY_STAND_PROGRESS,
  mutable,
  packageDealHelpers,
  workflowHelpers,
  workflowProgressHelpers,
} from '@stimcar/libs-base';
import { keysOf, nonnull } from '@stimcar/libs-kernel';
import { useComponentWithMountTracking } from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import {
  DISPLAY_DASHBOARD_FULL_PATH,
  DISPLAY_DASHBOARD_RELATIVE_PATH,
  DISPLAY_SHIFT_PROGRESS_FULL_PATH,
  DISPLAY_SHIFT_PROGRESS_RELATIVE_PATH,
  DISPLAY_STAND_ACHIEVEMENTS_FULL_PATH,
  DISPLAY_STAND_ACHIEVEMENTS_RELATIVE_PATH,
  DISPLAY_WORKSHOP_DASHBOARD_V2_FULL_PATH,
  DISPLAY_WORKSHOP_DASHBOARD_V2_RELATIVE_PATH,
} from '../coreConstants.js';
import type {
  DisplayViewState,
  STAND_ID_TYPE,
  StandAchievements,
  StandDisplayState,
  WorkflowDisplayState,
} from './typings/store.js';
import { KanbanBoardDisplay } from './KanbanBoardDisplay.js';
import { ShiftProgressDisplay } from './ShiftProgressDisplay.js';
import { StandAchievementsDisplay } from './StandAchievementsDisplay.js';
import { EMPTY_DISPLAY_VIEW_STATE } from './typings/store.js';
import { WorkshopImplantationDisplay } from './WorkshopImplantationDisplay.js';

export const KANBAN_WORKFLOWS_STATE_SNAPSHOT_KEY = 'KanbanWorkflowState';

async function initializeDisplay({
  getState,
  actionDispatch,
  kanbanRepository,
  getGlobalState,
  keyValueStorage,
}: ActionContext<Store, DisplayViewState>): Promise<void> {
  const { workflows } = getGlobalState().siteConfiguration;
  // Retrieve state from cache
  const startState = keyValueStorage.getObjectItem<WorkflowsState['state']>(
    KANBAN_WORKFLOWS_STATE_SNAPSHOT_KEY
  );
  if (startState) {
    const workflowsDisplayState: Record<WORKFLOW_ID_TYPE, WorkflowDisplayState> = {};
    await Promise.all(
      workflows.map(async ({ id: workflowId, definition }): Promise<void> => {
        // Retrieve kanbans
        const kanbans = workflowProgressHelpers.sortKanbanAccordingToWorkflow(
          definition,
          (await kanbanRepository.getAllEntities()).filter(
            ({ workflowId: kanbanWorkflowId }) => workflowId === kanbanWorkflowId
          )
        );

        // Retrieve start positions
        const workflowStartState = nonnull(startState)[workflowId];

        // Compute end positions
        const endState = workflowProgressHelpers.computeWorkflowState(kanbans);
        const kanbanIds = [...keysOf(workflowStartState)];
        keysOf(endState).forEach((k) => {
          if (!kanbanIds.includes(k)) {
            kanbanIds.push(k);
          }
        });
        const kanbanIdentities: Record<string, KanbanIdentity> = {};
        kanbanIds.forEach((k) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { expectedStandIds, ...identity } = !workflowStartState[k]
            ? endState[k]
            : workflowStartState[k];
          kanbanIdentities[k] = identity;
        });

        // Handle appearing kanbans
        const firstStandId = typeof definition === 'string' ? definition : definition.id;
        const fixedStartState: WorkflowState = {};
        kanbanIds.forEach((id) => {
          fixedStartState[id] = !workflowStartState[id]
            ? {
                id,
                license: endState[id].license,
                expectedStandIds: [firstStandId],
                progressByStand: {},
                currentStandIds: [],
                globalProgress: EMPTY_STAND_PROGRESS,
              }
            : workflowStartState[id];
        });

        // Prepare result
        const standIds: string[] = workflowHelpers.linearize(definition);
        const dayAchievements: Record<STAND_ID_TYPE, Mutable<StandAchievements>> = {};
        standIds.forEach((standId) => {
          dayAchievements[standId] = {
            kanbansDone: [],
            kanbansToRework: [],
            operationDoneWorkload: 0,
            kanbanProgressesSum: 0,
            operationDoneRevenue: 0,
          };
        });

        kanbanIds.forEach((kanbanId) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { expectedStandIds, ...kanbanIdentity } = fixedStartState[kanbanId];
          const startStandIds = fixedStartState[kanbanId].expectedStandIds ?? [];
          const endKanbanState = endState[kanbanId];
          const endStandIds = endKanbanState?.expectedStandIds ?? [];

          const populateAchievements = (
            workflowNode: WorkflowNode,
            startStandEncountered = false,
            endStandEncountered = false
          ): [boolean /* startStandEncountered */, boolean /* endStandEncountered */] => {
            const standId = typeof workflowNode === 'string' ? workflowNode : workflowNode.id;
            let newStartStandEncountered = startStandEncountered;
            let newEndStandEncountered = endStandEncountered;
            let hasWorkloadOrSparePartsOnStand = false;
            if (endKanbanState) {
              const progress = endKanbanState.progressByStand[standId];
              const startProgress =
                startState[workflowId][kanbanId]?.progressByStand[standId] ?? progress;
              if (progress) {
                hasWorkloadOrSparePartsOnStand =
                  progress.operationCount > 0 ||
                  progress.sparePartReceived < progress.sparePartCount;
                const deltaOperationWorkloadDone =
                  progress.operationDoneWorkload - (startProgress?.operationDoneWorkload ?? 0);
                // Update workload done
                dayAchievements[standId].operationDoneWorkload += deltaOperationWorkloadDone;
                const deltaOperationRevenueDone =
                  progress.operationDoneRevenue - (startProgress?.operationDoneRevenue ?? 0);
                dayAchievements[standId].operationDoneRevenue += deltaOperationRevenueDone;
                // Update kanban progresses sum
                const initialProgress =
                  packageDealHelpers.computePackageDealProgressPercentage(startProgress);
                const finalProgress =
                  packageDealHelpers.computePackageDealProgressPercentage(progress);
                const deltaProgress = finalProgress - initialProgress;
                dayAchievements[standId].kanbanProgressesSum += deltaProgress;
              }
            }

            if (!startStandEncountered && !endStandEncountered) {
              if (startStandIds.includes(standId) && endStandIds.includes(standId)) {
                return [true, true];
              }
              if (startStandIds.includes(standId)) {
                if (hasWorkloadOrSparePartsOnStand) {
                  dayAchievements[standId].kanbansDone.push(kanbanIdentity);
                }
                newStartStandEncountered = true;
              } else if (endStandIds.includes(standId)) {
                if (hasWorkloadOrSparePartsOnStand) {
                  dayAchievements[standId].kanbansToRework.push(kanbanIdentity);
                }
                newEndStandEncountered = true;
              }
            } else if (startStandEncountered && !endStandEncountered) {
              if (endStandIds.includes(standId)) {
                newEndStandEncountered = true;
              } else if (hasWorkloadOrSparePartsOnStand) {
                dayAchievements[standId].kanbansDone.push(kanbanIdentity);
              }
            } else if (!startStandEncountered && endStandEncountered) {
              if (startStandIds.includes(standId)) {
                newStartStandEncountered = true;
              } else if (hasWorkloadOrSparePartsOnStand) {
                dayAchievements[standId].kanbansToRework.push(kanbanIdentity);
              }
            }
            if (typeof workflowNode !== 'string') {
              if (workflowNode.fork) {
                const results = workflowNode.fork.map((node): [boolean, boolean] => {
                  return populateAchievements(
                    node,
                    newStartStandEncountered,
                    newEndStandEncountered
                  );
                });
                results.forEach(([forkStartEncountered, forkEndEncountered]) => {
                  newStartStandEncountered = newStartStandEncountered || forkStartEncountered;
                  newEndStandEncountered = newEndStandEncountered || forkEndEncountered;
                });
              }
              if (workflowNode.join) {
                const [joinStartEncountered, joinEndEncountered] = populateAchievements(
                  workflowNode.join,
                  newStartStandEncountered,
                  newEndStandEncountered
                );
                newStartStandEncountered = newStartStandEncountered || joinStartEncountered;
                newEndStandEncountered = newEndStandEncountered || joinEndEncountered;
              }
            }
            return [newStartStandEncountered, newEndStandEncountered];
          };
          populateAchievements(definition);
        });

        // Compute columns
        const stands: Record<string, Mutable<StandDisplayState>> = {};
        standIds.forEach((standId) => {
          stands[standId] = {
            achievements: dayAchievements[standId],
            kanbans: [],
          };
        });
        let kanbansCount = 0;
        kanbans.forEach((kanban) => {
          const { expectedStandIds } = kanban;
          // Count kanbans
          if (expectedStandIds.length > 0) {
            kanbansCount += 1;
          }
          // And spread kanbans on expected stands
          standIds.forEach((standId) => {
            if (expectedStandIds.includes(standId)) {
              stands[standId].kanbans.push(mutable(kanban));
            }
          });
        });

        workflowsDisplayState[workflowId] = {
          stands,
          kanbansCount,
        };
      })
    );
    actionDispatch.setProperty('workflowsStates', workflowsDisplayState);
    if (getState().standDisplay.workflowId === '' && workflows.length > 0) {
      const firstStand = workflows[0].definition;
      actionDispatch.setProperty('standDisplay', {
        workflowId: workflows[0].id,
        standId: typeof firstStand === 'string' ? firstStand : firstStand.id,
        size: 2,
      });
    }
  }
}

export async function updateDisplayViewStateFromSSEAction({
  actionDispatch,
  getState,
}: ActionContext<Store, DisplayViewState>): Promise<void> {
  // Only update the state if the component is mounted
  if (!getState().componentIsMounted) {
    return;
  }
  if (keysOf(getState().workflowsStates).length > 0) {
    await actionDispatch.exec(initializeDisplay);
  }
}

const AVAILABLE_DISPLAY_FULL_PATHS = [
  DISPLAY_DASHBOARD_FULL_PATH,
  DISPLAY_STAND_ACHIEVEMENTS_FULL_PATH,
  DISPLAY_WORKSHOP_DASHBOARD_V2_FULL_PATH,
  DISPLAY_SHIFT_PROGRESS_FULL_PATH,
];

export function Display({ $gs }: AppProps<Store>): JSX.Element {
  // Allows to track if the view is mounted (through a boolean in the state)
  useComponentWithMountTracking($gs.$displayView, initializeDisplay, EMPTY_DISPLAY_VIEW_STATE);

  const lastDisplayPath =
    localStorage.getItem(LocalStorageKeys.LAST_DISPLAY_PATH) ?? DISPLAY_DASHBOARD_FULL_PATH;

  return (
    <Routes>
      <Route path={DISPLAY_DASHBOARD_RELATIVE_PATH} element={<KanbanBoardDisplay $gs={$gs} />} />
      <Route
        path={DISPLAY_STAND_ACHIEVEMENTS_RELATIVE_PATH}
        element={<StandAchievementsDisplay $gs={$gs} />}
      />
      <Route
        path={DISPLAY_WORKSHOP_DASHBOARD_V2_RELATIVE_PATH}
        element={<WorkshopImplantationDisplay $gs={$gs} />}
      />
      <Route
        path={DISPLAY_SHIFT_PROGRESS_RELATIVE_PATH}
        element={<ShiftProgressDisplay $gs={$gs} />}
      />
      <Route
        path="*"
        element={
          <Navigate
            to={
              AVAILABLE_DISPLAY_FULL_PATHS.includes(lastDisplayPath)
                ? lastDisplayPath
                : DISPLAY_DASHBOARD_FULL_PATH
            }
          />
        }
      />
    </Routes>
  );
}
