/* eslint-disable class-methods-use-this */
import type { PollerErrorHandler } from '@stimcar/libs-base';
import type { KeyValueStorage } from '@stimcar/libs-kernel';
import type { DatabaseFactory } from '../../database/index.js';
import type { RepositoryHTTPClient } from '../../httpclient/typings/RepositoryHTTPClient.js';
import type { DatabaseIndex } from '../../repository/dao/typings/repository-dao.js';
import type { RepositoryExtender } from '../../repository/typings/repository.js';
import { newExtendedRepository } from '../../repository/RepositoryImpl.js';
import { AbstractRepositoryExtender } from '../AbstractRepositoryExtender.js';
import type { KanbanExtender, KanbanRepository } from './typings/kanbanExtender.js';

// export const BY_KANBAN_DESTINATION_INDEX = 'byKanbanDestination';

class KanbanExtenderImpl
  extends AbstractRepositoryExtender
  implements RepositoryExtender<'kanban', KanbanExtender>
{
  public getDataBaseIndexesToCreateOrDelete(oldVersion: number): DatabaseIndex[] {
    const indexes: DatabaseIndex[] = [];
    /* eslint-disable no-fallthrough */
    switch (oldVersion) {
      default:
      /* eslint-enable no-fallthrough */
    }
    return indexes;
  }
}

/**
 * Creates a kanban extender instance.
 * @param httpClient the HTTP Client.
 */
export async function newKanbanRepository(
  keyValueStorage: KeyValueStorage,
  httpClient: RepositoryHTTPClient,
  pollerErrorHandler: PollerErrorHandler,
  dbFactory: DatabaseFactory
): Promise<KanbanRepository> {
  return newExtendedRepository<'kanban', KanbanExtender>(
    'kanban',
    keyValueStorage,
    httpClient,
    pollerErrorHandler,
    dbFactory,
    new KanbanExtenderImpl(0)
  );
}
