import type { JSX } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, Workflow } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type {
  ActionContext,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type {
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
  ImageModalState,
} from '@stimcar/libs-uitoolkit';
import { computePurchaseOrderChanges } from '@stimcar/core-libs-common';
import { kanbanHelpers, locationHelpers, purchaseOrderHelpers } from '@stimcar/libs-base';
import { computePayload, isTruthy, MKTP_MANDATE_BUY } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { CreateKanbanViewState } from '../../creation/typings/store.js';
import type { Store } from '../../state/typings/store.js';
import type {
  EditGeneralInformationsDialogState,
  GeneralInformationsForm,
  GeneralInformationsFormData,
} from './typings/store.js';
import {
  checkEditGeneralInformationsFormFieldContentActions,
  EditGeneralInformationsForm,
  editGeneralInformationsFormMandatoryFields,
} from './EditGeneralInformationsForm.js';
import { EMPTY_EDIT_GENERAL_INFORMATIONS_DIALOG_STATE } from './typings/store.js';

export function convertKanbanToFormData(kanban: Kanban): GeneralInformationsForm {
  const currentCarLocation = locationHelpers.getCurrentLocation(kanban) ?? '';
  const purchaseOrders = purchaseOrderHelpers.getStringFromPurchaseOrders(kanban.purchaseOrders);
  return {
    kanbanType: kanbanHelpers.isMarketplaceKanban(kanban) ? 'marketplace' : 'standard',
    marketplaceMandate: kanban.marketplaceInfos?.mandate ?? MKTP_MANDATE_BUY,
    contractCode: kanban.contract.code,
    workflowId: kanban.workflowId,
    brand: kanban.infos.brand,
    color: kanban.infos.color,
    dateOfRegistration: isTruthy(kanban.infos.dateOfRegistration)
      ? kanban.infos.dateOfRegistration
      : NaN,
    dueDate: isTruthy(kanban.dueDate) ? kanban.dueDate : NaN,
    refitEndDate: isTruthy(kanban.refitEndDate) ? kanban.refitEndDate : NaN,
    license: kanban.infos.license,
    mileage: String(kanban.infos.mileage),
    model: kanban.infos.model,
    motor: kanban.infos.motor,
    vin: kanban.infos.vin,
    carLocation: currentCarLocation,
    purchaseOrders,
    warnings: {},
  };
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function openEditGeneralInformationsDialogAction(
  { actionDispatch }: ActionContext<Store, EditGeneralInformationsDialogState>,
  initialKanban: Kanban
): Promise<void> {
  actionDispatch.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial: EditGeneralInformationsDialogState): EditGeneralInformationsDialogState => {
      return {
        ...initial, // important to keep parent additions (the state may be a subclass)
        ...EMPTY_EDIT_GENERAL_INFORMATIONS_DIALOG_STATE,
        active: true,
        initialKanban,
        formData: convertKanbanToFormData(initialKanban),
      };
    }
  );
}

export function computeGeneralInformationsFormDataPayload(
  initialKanban: Kanban | undefined,
  generalInformations: GeneralInformationsForm
): undefined | DeepPartial<GeneralInformationsForm> {
  return initialKanban === undefined
    ? generalInformations
    : computePayload(convertKanbanToFormData(initialKanban), generalInformations);
}

const checkFormConsistencyAction: CheckFormConsistencyAction<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  EditGeneralInformationsDialogState
> = ({ httpClient, formState, t }): string | undefined => {
  const { initialKanban, formData } = formState;
  const { contract } = initialKanban;
  const sequence = httpClient.getBrowserSequence();

  const formDataPayload = computeGeneralInformationsFormDataPayload(initialKanban, formData);
  if (formDataPayload === undefined) {
    return t('editGeneralInformationsDialog.warnings.noChange');
  }
  if (
    isTruthy(formState.initialKanban) &&
    isTruthy(contract.configuration.pkgDealDescCodesForPurchaseOrder)
  ) {
    const formDataPurchaseOrders = purchaseOrderHelpers.getPurchaseOrdersFromString(
      formState.formData.purchaseOrders,
      initialKanban.purchaseOrders,
      sequence
    );
    const { forbiddenChanges } = computePurchaseOrderChanges(
      formState.initialKanban,
      formDataPurchaseOrders
    );

    if (forbiddenChanges.length > 0) {
      const purchaseOrderIdsAsString = forbiddenChanges
        .map((forbiddenChange) => forbiddenChange.purchaseOrderId)
        .join(' / ');
      return t(
        'refititCommonComponents:editGeneralInformationsForm.warnings.failedPurchaseOrderUpdate',
        {
          purchaseOrders: purchaseOrderIdsAsString,
          count: forbiddenChanges.length,
        }
      );
    }
  }
  return undefined;
};

export interface EditGeneralInformationsDialogProps {
  readonly $: StoreStateSelector<Store, EditGeneralInformationsDialogState>;
  readonly $creationView: StoreStateSelector<Store, CreateKanbanViewState>;
  readonly $imageModal: StoreStateSelector<Store, ImageModalState>;
  readonly $sessionIsOnline: StoreStateSelector<Store, boolean>;
  readonly $sessionToken: StoreStateSelector<Store, string>;
  readonly $windowWidth: StoreStateSelector<Store, number>;
  readonly checkFieldContentActions?: CheckFormFieldContentActions<
    Store,
    EditGeneralInformationsDialogState
  >;
  readonly submitValidDataAction: NoArgActionCallback<Store>;
  readonly contractCodes: readonly string[];
  readonly workflows: readonly Workflow[];
  readonly hideTypeWorkflowContractPhotoFields?: boolean;
}

export function EditGeneralInformationsDialog({
  $,
  $creationView,
  $imageModal,
  $sessionIsOnline,
  $sessionToken,
  $windowWidth,
  checkFieldContentActions: providedCheckFieldContentActions,
  submitValidDataAction,
  contractCodes,
  workflows,
  hideTypeWorkflowContractPhotoFields = false,
}: EditGeneralInformationsDialogProps): JSX.Element {
  const [t] = useTranslation(['refititCommonComponents']);

  // Aggregate check field content actions
  const checkFieldContentActions = useMemo((): CheckFormFieldContentActions<
    Store,
    EditGeneralInformationsDialogState
  > => {
    return {
      ...(!providedCheckFieldContentActions ? {} : providedCheckFieldContentActions),
      ...checkEditGeneralInformationsFormFieldContentActions,
    };
  }, [providedCheckFieldContentActions]);

  const mandatoryFields = useCallback(
    (formData: GeneralInformationsForm): readonly (keyof GeneralInformationsFormData)[] => {
      return [...editGeneralInformationsFormMandatoryFields(formData, false)];
    },
    []
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditGeneralInformationsDialogState,
    [boolean]
  >(
    {
      $,
      mandatoryFields,
      checkFieldContentActions,
      checkFormConsistencyAction,
      submitValidDataAction,
      t,
    },
    hideTypeWorkflowContractPhotoFields
  );

  const formWarning = useGetState($.$formWarning);
  return (
    <ModalCardDialog
      title={t(`editGeneralInformationsDialog.title`)}
      $active={$.$active}
      okLabel={t(`editGeneralInformationsDialog.buttons.validate`)}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <div className="columns">
        <div className="column">
          <EditGeneralInformationsForm
            $creationView={$creationView}
            $formData={$formDataWithChangeTrigger}
            $imageModal={$imageModal}
            $sessionIsOnline={$sessionIsOnline}
            $sessionToken={$sessionToken}
            $windowWidth={$windowWidth}
            contractCodes={contractCodes}
            workflows={workflows}
            hideTypeWorkflowContractPhotoFields={hideTypeWorkflowContractPhotoFields}
            hidePurchaseOrdersField
          />
        </div>
      </div>
    </ModalCardDialog>
  );
}
