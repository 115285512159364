import type { JSX } from 'react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import type { Mutable, WORKFLOW_ID_TYPE, WorkflowsState } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { mutable, workflowHelpers, workflowProgressHelpers } from '@stimcar/libs-base';
import { keysOf, nonnull } from '@stimcar/libs-kernel';
import type { Store } from '../state/typings/store.js';
import { useComponentWithMountTracking } from '../../lib/hooks/useComponentWithMountTracking.js';
import {
  DISPLAY_DASHBOARD_FULL_PATH,
  DISPLAY_DASHBOARD_RELATIVE_PATH,
  DISPLAY_KANBANS_BOARD_FULL_PATH,
  DISPLAY_KANBANS_BOARD_RELATIVE_PATH,
  DISPLAY_SHIFT_PROGRESS_FULL_PATH,
  DISPLAY_SHIFT_PROGRESS_RELATIVE_PATH,
  DISPLAY_STAND_ACHIEVEMENTS_FULL_PATH,
  DISPLAY_STAND_ACHIEVEMENTS_RELATIVE_PATH,
  DISPLAY_WORKSHOP_DASHBOARD_V2_FULL_PATH,
  DISPLAY_WORKSHOP_DASHBOARD_V2_RELATIVE_PATH,
} from '../coreConstants.js';
import type { DisplayViewState, StandDisplayState, WorkflowDisplayState } from './typings/store.js';
import { KanbanBoardDisplay } from './KanbanBoardDisplay.js';
import { KanbansBoardComponent } from './kanbansBoard/KanbansBoardComponent.js';
import { ShiftProgressDisplay } from './shift/ShiftProgressDisplay.js';
import { StandAchievementsDisplay } from './StandAchievementsDisplay.js';
import { EMPTY_DISPLAY_VIEW_STATE } from './typings/store.js';
import { WorkshopImplantationDisplay } from './WorkshopImplantationDisplay.js';

export const KANBAN_WORKFLOWS_STATE_SNAPSHOT_KEY = 'KanbanWorkflowState';

async function initializeDisplay({
  getState,
  actionDispatch,
  kanbanRepository,
  getGlobalState,
  keyValueStorage,
}: ActionContext<Store, DisplayViewState>): Promise<void> {
  const { workflows } = getGlobalState().siteConfiguration;
  // Retrieve state from cache
  const startState = keyValueStorage.getObjectItem<WorkflowsState['state']>(
    KANBAN_WORKFLOWS_STATE_SNAPSHOT_KEY
  );
  if (startState) {
    const workflowsDisplayState: Record<WORKFLOW_ID_TYPE, WorkflowDisplayState> = {};
    await Promise.all(
      workflows.map(async ({ id: workflowId, definition }): Promise<void> => {
        // Retrieve kanbans
        const kanbans = workflowProgressHelpers.sortKanbanAccordingToWorkflow(
          definition,
          (await kanbanRepository.getAllEntities()).filter(
            ({ workflowId: kanbanWorkflowId }) => workflowId === kanbanWorkflowId
          )
        );

        // Retrieve start positions
        const workflowStartState = nonnull(startState)[workflowId];

        // Compute end positions
        const workflowEndState = workflowProgressHelpers.computeWorkflowState(kanbans);

        // Compute day achievements
        const dayAchievements = workflowProgressHelpers.computeWorkflowAchievementsBetween(
          definition,
          workflowStartState,
          workflowEndState
        );

        // Compute columns
        const stands: Record<string, Mutable<StandDisplayState>> = {};
        const standIds: string[] = workflowHelpers.linearize(definition);
        standIds.forEach((standId) => {
          stands[standId] = {
            achievements: mutable(dayAchievements[standId]),
            kanbans: [],
          };
        });
        let kanbansCount = 0;
        kanbans.forEach((kanban) => {
          const { expectedStandIds } = kanban;
          // Count kanbans
          if (expectedStandIds.length > 0) {
            kanbansCount += 1;
          }
          // And spread kanbans on expected stands
          standIds.forEach((standId) => {
            if (expectedStandIds.includes(standId)) {
              stands[standId].kanbans.push(mutable(kanban));
            }
          });
        });
        workflowsDisplayState[workflowId] = {
          stands,
          kanbansCount,
        };
      })
    );
    actionDispatch.setProperty('workflowsStates', workflowsDisplayState);
    if (getState().standDisplay.workflowId === '' && workflows.length > 0) {
      const firstStand = workflows[0].definition;
      actionDispatch.setProperty('standDisplay', {
        workflowId: workflows[0].id,
        standId: typeof firstStand === 'string' ? firstStand : firstStand.id,
        size: 2,
        active: true,
      });
    }
  }
}

export async function updateDisplayViewStateFromSSEAction({
  actionDispatch,
  getState,
}: ActionContext<Store, DisplayViewState>): Promise<void> {
  // Only update the state if the component is mounted
  if (!getState().componentIsMounted) {
    return;
  }
  if (keysOf(getState().workflowsStates).length > 0) {
    await actionDispatch.exec(initializeDisplay);
  }
}

const AVAILABLE_DISPLAY_FULL_PATHS = [
  DISPLAY_KANBANS_BOARD_FULL_PATH,
  DISPLAY_DASHBOARD_FULL_PATH,
  DISPLAY_STAND_ACHIEVEMENTS_FULL_PATH,
  DISPLAY_WORKSHOP_DASHBOARD_V2_FULL_PATH,
  DISPLAY_SHIFT_PROGRESS_FULL_PATH,
];

export function Display({ $gs }: AppProps<Store>): JSX.Element {
  // Allows to track if the view is mounted (through a boolean in the state)
  useComponentWithMountTracking($gs.$displayView, initializeDisplay, EMPTY_DISPLAY_VIEW_STATE);

  const lastDisplayPath =
    localStorage.getItem(LocalStorageKeys.LAST_DISPLAY_PATH) ?? DISPLAY_DASHBOARD_FULL_PATH;

  return (
    <Routes>
      <Route path={DISPLAY_DASHBOARD_RELATIVE_PATH} element={<KanbanBoardDisplay $gs={$gs} />} />
      <Route
        path={DISPLAY_KANBANS_BOARD_RELATIVE_PATH}
        element={<KanbansBoardComponent $gs={$gs} />}
      />
      <Route
        path={DISPLAY_STAND_ACHIEVEMENTS_RELATIVE_PATH}
        element={<StandAchievementsDisplay $gs={$gs} />}
      />
      <Route
        path={DISPLAY_WORKSHOP_DASHBOARD_V2_RELATIVE_PATH}
        element={<WorkshopImplantationDisplay $gs={$gs} />}
      />
      <Route
        path={DISPLAY_SHIFT_PROGRESS_RELATIVE_PATH}
        element={<ShiftProgressDisplay $gs={$gs} />}
      />
      <Route
        path="*"
        element={
          <Navigate
            to={
              AVAILABLE_DISPLAY_FULL_PATHS.includes(lastDisplayPath)
                ? lastDisplayPath
                : DISPLAY_DASHBOARD_FULL_PATH
            }
          />
        }
      />
    </Routes>
  );
}
