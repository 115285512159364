import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { ScrollableContainerProps } from '../../container/ScrollableContainer.js';
import { useRemainingVerticalSpace } from '../../../hooks/useRemainingVerticalSpace.js';
import { TableContainer } from './container/TableContainer.js';

interface ScrollbarStyle {
  background: React.CSSProperties;
  backgroundFocus: React.CSSProperties;
  foreground: React.CSSProperties;
  foregroundFocus: React.CSSProperties;
}

interface Props extends ScrollableContainerProps {
  readonly width?: string;
  readonly scrollbarStyle?: ScrollbarStyle;
  readonly isTruncable?: boolean;
  readonly tableClassName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly customHeader?: Array<React.ComponentClass<any> | React.FunctionComponent<any>>;
  readonly recomputeSizeHint?: number; // a change in this number indicates that the size should be recomputed
}

const defaultScrollbarStyle: ScrollbarStyle = {
  // How the container of the scrollbar should look like
  background: {
    backgroundColor: 'transparent',
  },
  // How the container should look like on mouse over
  backgroundFocus: {
    backgroundColor: '#f0f0f0',
  },
  // How the scrollbar should look like
  foreground: {
    backgroundColor: '#D3D3D3',
  },
  // How it should look like on mouse over
  foregroundFocus: {
    backgroundColor: '#C0C0C0',
  },
};

export function ScrollableTableComponent({
  children,
  height,
  width = '100%',
  scrollbarStyle = defaultScrollbarStyle,
  isTruncable = false,
  tableClassName,
  bottomPlaceholderSize,
  customHeader,
  recomputeSizeHint,
}: Props): JSX.Element {
  const [listSize, measuredRef] = useRemainingVerticalSpace(
    bottomPlaceholderSize,
    recomputeSizeHint
  );

  const computedSize = useMemo(() => {
    if (height === undefined) {
      return `${listSize}PX`;
    }
    return height;
  }, [height, listSize]);

  const computeTableStyle = (): React.CSSProperties => {
    let style = {};
    if (isTruncable) {
      style = { ...style, width, tableLayout: 'fixed', whiteSpace: 'nowrap' };
    }
    return style;
  };

  return (
    <div ref={measuredRef}>
      {/* @ts-ignore */}
      <TableContainer
        width={width}
        height={computedSize}
        scrollbarStyle={scrollbarStyle}
        customHeader={customHeader}
      >
        <table className={tableClassName || 'table'} style={computeTableStyle()}>
          {children}
        </table>
      </TableContainer>
    </div>
  );
}
