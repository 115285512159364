import type { KanbanPriorityLevel } from '@stimcar/libs-base';

export const REPAIR_ORDER_LABEL_SIZE = 3;
export const DECORATOR_BULLET_SIZE = 6;
export const DECORATOR_ICON_SIZE = 3;

export type GridData = {
  readonly row: number;
  readonly nbRows?: number;
  readonly col: number;
  readonly nbCols?: number;
};

export type RepairOrderForKanban = {
  readonly id: string;
  readonly standId: string;
  readonly license: string;
  readonly contract: string;
  readonly priorityLevel: KanbanPriorityLevel;
  readonly standIconToDisplay?: string;
};

export type RepairOrdersByLaneAndBandwidth = {
  readonly laneId: string;
  readonly bandwidthId: string;
  readonly repairOrders: readonly RepairOrderForKanban[];
};

export type KanbansAllocation = {
  readonly allocatedRepairOrders: readonly RepairOrderForKanban[];
  readonly availableKanbansCount: number;
  readonly nonAllocatedRepairOrders: readonly RepairOrderForKanban[];
};

export type KanbansAllocationByLaneAndBandwidth = {
  readonly laneId: string;
  readonly bandwidthId: string;
  readonly repairOrdersPerDay: number;
  readonly leadTime: number;
} & KanbansAllocation;

export type KanbansBoardCellDimensions = {
  readonly nbRows: number;
  readonly nbCols: number;
};

export enum SlotType {
  repairOrder,
  available,
  disabled,
}

export type KanbansBoardCellSlot = {
  readonly type: SlotType;
  readonly key: string;
  readonly repairOrder?: RepairOrderForKanban;
};

export type KanbansBoardCellData = {
  readonly dimensions: KanbansBoardCellDimensions;
  readonly slots: readonly KanbansBoardCellSlot[];
};

export const KANBANS_BOARD_DISPLAY_MODES = ['unicolor', 'priority'] as const;

export type KanbansBoardDisplayMode = (typeof KANBANS_BOARD_DISPLAY_MODES)[number];

export type KanbansBoardDisplayState = {
  readonly size: number;
  readonly mode: KanbansBoardDisplayMode;
  readonly active: boolean;
};

export const KANBAN_HEIGHT = 7;

export const KANBANS_BOARD_DEFAULT_SIZE = 4;
export const KANBANS_BOARD_REPAIR_ORDER_BG_CLASS = 'has-background-yellow';
export const KANBANS_BOARD_REPAIR_ORDER_BG_CLASS_DUE_DATE_IS_PAST = 'has-background-yellow';
export const KANBANS_BOARD_REPAIR_ORDER_BG_CLASS_DUE_DATE_WITHIN_THRESHOLD =
  'has-background-yellow';
export const KANBANS_BOARD_REPAIR_ORDER_BG_CLASS_DEFAULT = 'has-background-kanban-age-9';

export const EMPTY_KANBANS_BOARD_DISPLAY_STATE: KanbansBoardDisplayState = {
  size: KANBANS_BOARD_DEFAULT_SIZE,
  mode: 'unicolor',
  active: false,
};
