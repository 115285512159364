import type { TFunction } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { Kanban } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  AvailablePermissionPaths,
  kanbanHelpers,
  packageDealHelpers,
  workflowHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { useComponentWithMountTracking } from '@stimcar/libs-uitoolkit';
import type { Store, StoreState } from '../state/typings/store.js';
import { useHasModifyPermission } from '../../registeredapp/permissionHooks.js';
import { computeOperatePath, SELECT_KANBAN_FULL_PATH } from '../coreConstants.js';
import { computeWorkStatus } from '../utils/operatorUtils.js';
import type { OperatorViewState } from './typings/store.js';
import { ExpertComponent } from './components/expert/ExpertComponent.js';
import { updateExpertiseViewAction } from './components/expert/ExpertComponentView.js';
import { updateExpertiseValidationAction } from './components/expertiseValidation/ValidateExpertiseComponent.js';
import {
  OperatorGeneralComponent,
  updateGeneralOperatorViewAction,
} from './components/OperatorGeneralComponent.js';
import { logErrorIfMultipleOpenedHandlings } from './operatorLogUtils.js';
import { EMPTY_OPERATOR_VIEW_STATE } from './typings/store.js';

export const startKanbanHandlingAction = async (
  { kanbanRepository, httpClient, getGlobalState, loggerClient }: ActionContext<Store, StoreState>,
  kanban: Kanban,
  standId: string
): Promise<void> => {
  const { user, infos } = getGlobalState().session;
  if (user && infos) {
    const unclosedHandlings = kanban.handlings.filter((h) => !isTruthy(h.endDate));
    if (unclosedHandlings.length > 0) {
      unclosedHandlings.forEach((h) => h.intervals);
    }

    const newKanban = kanbanHelpers.openNewHandlingWithInterval(
      httpClient.getBrowserSequence(),
      kanban,
      standId,
      infos.id,
      [user.login],
      'work'
    );

    logErrorIfMultipleOpenedHandlings(
      getGlobalState().session.infos!.company.id, // session.infos is always present when registered
      newKanban.id,
      newKanban.infos.license,
      newKanban.handlings,
      loggerClient
    );

    await kanbanRepository.updateEntity(newKanban);
  }
};

export async function updateOperatorViewStateFromSSEAction(
  {
    actionDispatch,
    getGlobalState,
    getState,
    globalActionDispatch,
  }: ActionContext<Store, OperatorViewState>,
  kanban: Kanban
): Promise<void> {
  const { operatedKanban, componentIsMounted } = getState();
  // Only update the state if the component is mounted
  if (!componentIsMounted) {
    return;
  }
  if (isTruthy(operatedKanban) && operatedKanban.id === kanban.id) {
    const { session } = getGlobalState();

    const { id: postId } = nonnull(nonnull(session.infos));
    const openHandle = kanbanHelpers.getOpenOperatorHandleForPost(kanban, postId);
    if (!isTruthy(openHandle)) {
      actionDispatch.reduce(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (initial: OperatorViewState): OperatorViewState => {
          return EMPTY_OPERATOR_VIEW_STATE;
        }
      );
      return;
    }
    const workStatus = computeWorkStatus(globalActionDispatch, openHandle, operatedKanban);
    const standRelatedOperationIds = packageDealHelpers
      .getAllOperationsForStandId(kanban.packageDeals, nonnull(openHandle).standId)
      .map((o) => o.id);
    actionDispatch.reduce((initial: OperatorViewState): OperatorViewState => {
      return {
        ...initial,
        workStatus,
        operatedKanban: kanban,
        operationsToDoIds: standRelatedOperationIds,
      };
    });
    await actionDispatch
      .scopeProperty('expertOperatorState')
      .exec(updateExpertiseViewAction, kanban);
    await actionDispatch
      .scopeProperty('generalOperatorState')
      .exec(updateGeneralOperatorViewAction, kanban, nonnull(openHandle).standId);
    await actionDispatch
      .scopeProperty('validateExpertiseViewState')
      .exec(updateExpertiseValidationAction, kanban);
  }
}

export async function initOperatorViewState(
  {
    actionDispatch,
    getGlobalState,
    getState,
    kanbanRepository,
    globalActionDispatch,
    navigate,
  }: ActionContext<Store, OperatorViewState>,
  t: TFunction,
  kanbanId: string,
  standId: string
): Promise<void> {
  let errorMessage: string | undefined;
  const { handledKanbanId, siteConfiguration, contracts } = getGlobalState();

  if (isTruthyAndNotEmpty(handledKanbanId) && handledKanbanId !== kanbanId) {
    globalActionDispatch.reduce((initial: StoreState): StoreState => {
      return {
        ...initial,
        message: t('operators:errors.incorrectHandling'),
      };
    });
    navigate(SELECT_KANBAN_FULL_PATH);
    return;
  }

  const kanban = await kanbanRepository.getEntity(kanbanId);

  if (!isTruthy(kanban.infos.dateOfRegistration)) {
    globalActionDispatch.reduce((initial: StoreState): StoreState => {
      return {
        ...initial,
        message: t('operators:errors.incorrectFirstRegistration'),
      };
    });
    navigate(SELECT_KANBAN_FULL_PATH);
    return;
  }

  const contract = contracts.find((c) => c.code === kanban.contract.code);
  if (kanban.origin.source !== 'delegation') {
    if (!isTruthy(contract)) {
      globalActionDispatch.reduce((initial: StoreState): StoreState => {
        return {
          ...initial,
          message: t('operators:errors.contractNotFound'),
        };
      });
      navigate(SELECT_KANBAN_FULL_PATH);
      return;
    }
    if (!siteConfiguration.packageDealDatabases.includes(contract.packageDealDatabase)) {
      globalActionDispatch.reduce((initial: StoreState): StoreState => {
        return {
          ...initial,
          message: t('operators:errors.packageDealDatabaseNotFound', {
            packageDealDatabase: contract.packageDealDatabase,
          }),
        };
      });
      navigate(SELECT_KANBAN_FULL_PATH);
      return;
    }
  }

  globalActionDispatch.reduce((initial) => {
    return {
      ...initial,
      handledKanbanId: kanbanId,
    };
  });

  const { id: postId } = nonnull(nonnull(getGlobalState().session.infos));
  const { startHandling } = getState();

  const openHandledOnPost = kanbanHelpers.getOpenOperatorHandleForPost(kanban, postId);
  if (openHandledOnPost && openHandledOnPost.standId !== standId) {
    globalActionDispatch.reduce((initial) => {
      return {
        ...initial,
        message: t('errors.alreadyHandledOnThisPostAndOnAnotherStand', {
          standId: openHandledOnPost.standId,
        }),
      };
    });
    navigate(computeOperatePath(kanbanId, openHandledOnPost.standId));
  }

  if (!openHandledOnPost && startHandling) {
    await globalActionDispatch.exec(startKanbanHandlingAction, kanban, standId);
  }

  const standRelatedOperationIds = packageDealHelpers
    .getAllOperationsForStandId(kanban.packageDeals, standId)
    .map((o) => o.id);

  // Compute total time available for all the operations on this stand
  const totalTimeInSeconds =
    packageDealHelpers.getWorkloadForStand(kanban.packageDeals, standId) * 3600;

  // Compute all the time already spent for operations on this stand (for all handlings and intervals)
  const handlingsForStand = kanban.handlings.filter((h) => h.postId === postId);
  const timeSpentInClosedHandlings = handlingsForStand
    .filter((h) => isTruthy(h.endDate))
    .reduce((acc, h) => {
      const intervalSec = (nonnull(h.endDate) - h.startDate) / 1000;
      return acc + Math.floor(intervalSec);
    }, 0);

  const openHandle = kanbanHelpers.getOpenOperatorHandleForPost(kanban, postId);

  let timeSpentInClosedIntervals = 0;
  let timeSpentInUnclosedInterval = 0;
  if (openHandle) {
    timeSpentInClosedIntervals = openHandle.intervals
      .filter((i) => isTruthy(i.endDate))
      .reduce((acc, i) => {
        const intervalSec = (nonnull(i.endDate) - i.startDate) / 1000;
        return acc + Math.floor(intervalSec);
      }, 0);

    const unclosedIntervals = openHandle.intervals.filter((i) => !isTruthy(i.endDate));
    if (unclosedIntervals.length > 1) {
      errorMessage = 'operators:errors.forbiddenMultipleIntervals';
    }
    if (unclosedIntervals.length === 1) {
      timeSpentInUnclosedInterval = Math.floor(
        (Date.now() - unclosedIntervals[0].startDate) / 1000
      );
    }
  }

  const consumedTimeInSeconds = Math.ceil(
    timeSpentInClosedHandlings + timeSpentInClosedIntervals + timeSpentInUnclosedInterval
  );

  const workStatus = computeWorkStatus(globalActionDispatch, openHandle, kanban);
  if (isTruthyAndNotEmpty(errorMessage)) {
    globalActionDispatch.setProperty('message', errorMessage);
  } else {
    actionDispatch.reduce((initial: OperatorViewState): OperatorViewState => {
      return {
        ...initial,
        operatedKanban: kanban,
        operationsToDoIds: standRelatedOperationIds,
        workStatus,
        consumedTimeInSeconds,
        totalTimeInSeconds,
      };
    });
  }
}

export function OperatorView({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('operators');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { id, standId } = useParams<any>();
  const { $operatorView } = $gs;

  // Allows to track if the view is mounted (through a boolean in the state)
  useComponentWithMountTracking(
    $operatorView,
    undefined /* not used, as the initializer depends on the standId */,
    EMPTY_OPERATOR_VIEW_STATE
  );

  const asyncEffect = useActionCallback(
    async ({ actionDispatch, getState, getGlobalState }): Promise<void> => {
      const { stands } = getGlobalState().siteConfiguration;
      const { operatedKanban } = getState();
      if (isTruthyAndNotEmpty(id) && (!isTruthy(operatedKanban?.id) || id !== operatedKanban?.id)) {
        if (stands.find((s) => s.id === standId) === undefined) {
          throw Error('Unknown standId');
        }
        await actionDispatch.exec(initOperatorViewState, t, id, nonnull(standId));
      }
    },
    [id, standId, t],
    $operatorView
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const stands = useGetState($gs.$siteConfiguration.$stands);

  const isExpertise = useMemo(() => {
    const stand = stands.find(({ id }) => id === standId);
    return isTruthy(stand) && workflowHelpers.isExpertiseStand(stand);
  }, [stands, standId]);

  const hasAccess = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.STAND_ACCESS_PERMISSION(standId ?? '')
  );

  const operatedKanban = useGetState($operatorView.$operatedKanban);
  if (!isTruthy(operatedKanban)) {
    return <p className="is-italic has-text-centered">{t('noKanbanSelected')}</p>;
  }

  if (!hasAccess) {
    return <p className="is-italic has-text-centered">{t('userHasNoPermissionToAccessStand')}</p>;
  }

  if (isExpertise) {
    return <ExpertComponent standId={nonnull(standId)} $gs={$gs} />;
  }

  return <OperatorGeneralComponent standId={nonnull(standId)} $gs={$gs} />;
}
