import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionCallbackFromFunction } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../state/typings/store.js';
import { KanbanDetails } from '../../details/KanbanDetails.js';
import { selectKanbanInArchiveViewAction } from './actions.js';
import { KanbanListWithSearch } from './kanbanList/KanbanListWithSearch.js';
import { useSearchArchivesWarning } from './useSearchArchivesWarning.js';

interface Props extends AppProps<Store> {
  readonly runSearchActionCallback: ActionCallbackFromFunction<
    Store,
    (page: number, autoSelectIfOneResult?: boolean) => Promise<void>
  >;
}

export function DesktopArchivesViewComponent({ $gs, runSearchActionCallback }: Props): JSX.Element {
  const [t] = useTranslation('archives');
  const $ = $gs.$archivesView;

  const setSelectedKanban = useActionCallback(selectKanbanInArchiveViewAction, [], $gs);

  const isOnline = useGetState($gs.$session.$isOnline);
  const kanbanColorationCharter = useGetState(
    $gs.$siteConfiguration.$displayConfiguration.$kanbanColorationCharter
  );
  const filteredKanbans = useGetState($gs.$archivesView.$filteredKanbans);
  const searchText = useGetState($gs.$archivesView.$searchText);
  const searchShouldBeRerun = useGetState($gs.$archivesView.$searchShouldBeRerun);
  const pageCount = useGetState($gs.$archivesView.$pageCount);
  const totalFound = useGetState($gs.$archivesView.$totalFound);
  const activePage = useGetState($gs.$archivesView.$activePage);
  const warning = useSearchArchivesWarning(t, searchText, isOnline);
  const selectedKanbanId = useGetState(
    $gs.$archivesView.$detailsState.$selectedKanban.optChaining().$id
  );

  return (
    <div className="columns">
      <div className="column is-one-quarter no-printing">
        <KanbanListWithSearch
          kanbans={filteredKanbans}
          $={$}
          runSearchActionCallback={runSearchActionCallback}
          searchShouldBeRerun={searchShouldBeRerun}
          selectKanban={setSelectedKanban}
          pageCount={pageCount}
          totalFound={totalFound}
          activePage={activePage}
          maxPageButtons={7}
          warning={warning}
          kanbanColorationCharter={kanbanColorationCharter}
          selectedKanbanId={selectedKanbanId}
        />
      </div>
      <div className="column">
        <KanbanDetails $={$gs.$archivesView.$detailsState} $gs={$gs} addKanbanInNavbar={false} />
      </div>
    </div>
  );
}
