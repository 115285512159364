import type { JSX } from 'react';
import { t } from 'i18next';
import React, { useCallback, useRef } from 'react';
import type { LicenseRecognitionResult } from '@stimcar/core-libs-common';
import type { ActionCallbackFromFunction, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { ProgressBarMonitor } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import type { KanbanListWithSearchBaseStoreDef } from './KanbanListWithSearch.js';
import type { KanbanListWithSearchState } from './typings/store.js';

export async function resize(imageFile: File, maxImageSize: number): Promise<Blob> {
  const image = new Image();
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      image.src = e.target!.result! as string;
    };

    image.onload = () => {
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;

        const imageSize = Math.max(image.width, image.height);
        let scale = 1;
        if (imageSize > maxImageSize) {
          if (image.width >= image.height) {
            scale = maxImageSize / image.width;
          } else {
            scale = maxImageSize / image.height;
          }
        }
        // Compute canvas size
        canvas.width = image.width * scale;
        canvas.height = image.height * scale;
        // Draw image
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        // Retrieve it as blob
        canvas.toBlob(
          (blob) => {
            resolve(blob!);
          },
          'image/jpeg',
          0.8
        );
      } catch (e) {
        reject(e as Error);
      }
    };
    reader.readAsDataURL(imageFile);
  });
}

type Props<SD extends KanbanListWithSearchBaseStoreDef> = {
  readonly $: StoreStateSelector<SD, KanbanListWithSearchState>;
  readonly runSearchActionCallback: ActionCallbackFromFunction<
    SD,
    (page: number, autoSelectIfOneResult?: boolean) => Promise<void>
  >;
};

export function ImageBasedSearchButton<SD extends KanbanListWithSearchBaseStoreDef>({
  $,
  runSearchActionCallback,
}: Props<SD>): JSX.Element {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onClick = useCallback(() => hiddenFileInput.current?.click(), []);

  const onChangeActionCallback = useActionCallback(
    async (
      { actionDispatch, httpClient, runWithProgressBar },
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      await runWithProgressBar(100, async (monitor: ProgressBarMonitor): Promise<void> => {
        const fullResolutionImage = [...nonnull(nonnull(event.target).files)][0];
        monitor.incrementProgress(25);
        const resizedImage = await resize(fullResolutionImage, 1200);
        monitor.incrementProgress(25);
        const recognitionResult = await httpClient.httpPostAsFile<LicenseRecognitionResult>(
          CoreBackendRoutes.RECOGNIZE_PLATES_FROM_IMAGE,
          {
            image: resizedImage,
          }
        );
        monitor.incrementProgress(25);
        actionDispatch.applyPayload({
          searchText: keysOf(recognitionResult.licenses).join(','),
          searchByImage: {
            ...recognitionResult,
            imageBlobUrl: URL.createObjectURL(resizedImage),
          },
        });
        await actionDispatch.execCallback(
          runSearchActionCallback,
          1,
          // If there are several licenses detected, don't automatically
          // open the detail if only one license is valid (so that the
          // user can see the rejected licenses on the picture)
          keysOf(recognitionResult.licenses).length === 1
        );
      });
    },
    [runSearchActionCallback],
    $
  );

  return (
    <>
      <input
        type="file"
        accept="image/*"
        capture="environment"
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        onChange={onChangeActionCallback}
      />
      <button
        type="button"
        className="button is-primary"
        onClick={onClick}
        aria-label={t('search.label')}
      >
        <span className="icon is-small">
          <i className="fas fa-camera" />
        </span>
      </button>
    </>
  );
}
