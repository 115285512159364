import { isTruthy, isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import type {
  Stand,
  WorkshopPost,
  WorkshopPostCategory,
  WorkshopPostLevel,
  WorkshopStandImplantation,
} from '../../model/typings/configuration.js';
import type { Kanban, Operation, PackageDeal } from '../../model/typings/kanban.js';
import type { WorkshopOperation } from '../../model/typings/workshop.js';
import { packageDealHelpers } from './packageDealHelpers.js';
import { transverseHelpers } from './transverseHelpers.js';

const convertToWorkshopOperation = (
  op: Operation,
  pkg: PackageDeal,
  carElementLabel?: string
): WorkshopOperation => {
  const { received, missing } = packageDealHelpers.getReceivedAndMissingSpareParts(pkg);
  return {
    packageDealLabel: packageDealHelpers.getPackageDealDisplayedLabel(pkg),
    packageDealComment: pkg.comment,
    id: op.id,
    label: packageDealHelpers.getOperationDisplayedLabel(op, pkg.variables),
    completionDate: op.completionDate,
    receivedSpareParts: received,
    missingSpareParts: missing,
    workload: op.workload,
    packageDealAttachments: pkg.attachments ?? [],
    type: op.type,
    isSubcontractable: pkg.isSubcontractable === true,
    subcontractor: isTruthy(op.subcontractor) ? op.subcontractor : null,
    carElement: {
      category: pkg.carElement?.category ?? 'MISC',
      label: carElementLabel,
      shapes: pkg.carElement?.shapes ?? [],
    },
  };
};

function convertToWorkshopOperations(
  operations: readonly Operation[],
  packageDeal: PackageDeal
): readonly WorkshopOperation[] {
  return operations.map((op): WorkshopOperation => {
    return convertToWorkshopOperation(op, packageDeal, packageDeal.carElement?.label);
  });
}

function getAllUnfinishedOperationsOnPrecedentOrUnknownCategories(
  packageDeals: readonly PackageDeal[],
  qualifiedPostId: string,
  standId: string,
  implantation: WorkshopStandImplantation | undefined
): readonly Operation[] {
  const operationItems: Operation[] = [];
  if (packageDeals.length > 0) {
    const selfExcludedEarlierQualifiedWorkshopCategories: string[] = [];
    const { categoryId } =
      transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(qualifiedPostId);
    if (isTruthy(implantation) && isTruthyAndNotEmpty(categoryId)) {
      selfExcludedEarlierQualifiedWorkshopCategories.push(
        ...transverseHelpers.getEarlierQualifiedWorkshopCategories(implantation, categoryId)
      );
    }
    const allQualifiedCategories =
      transverseHelpers.getAllQualifiedWorkshopCategories(implantation);
    packageDealHelpers.getAvailablePackageDeals(packageDeals, true).forEach((pd) => {
      const unfinishedOperationsOnUnknownPosts =
        packageDealHelpers.getUnfinishedOperationsOnPrecedentOrUnknownCategories(
          pd,
          standId,
          selfExcludedEarlierQualifiedWorkshopCategories,
          allQualifiedCategories
        );
      operationItems.push(...unfinishedOperationsOnUnknownPosts);
    });
  }
  return operationItems;
}

function getAllUnfinishedWorkshopOperationsOnPrecedentOrUnknownCategories(
  packageDeals: readonly PackageDeal[],
  qualifiedPostId: string,
  standId: string,
  implantation: WorkshopStandImplantation | undefined
): readonly WorkshopOperation[] {
  const operationItems: WorkshopOperation[] = [];
  if (packageDeals.length > 0) {
    const selfExcludedEarlierQualifiedWorkshopCategories: string[] = [];
    const { categoryId } =
      transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(qualifiedPostId);
    if (isTruthy(implantation) && isTruthyAndNotEmpty(categoryId)) {
      selfExcludedEarlierQualifiedWorkshopCategories.push(
        ...transverseHelpers.getEarlierQualifiedWorkshopCategories(implantation, categoryId)
      );
    }
    const allQualifiedCategories =
      transverseHelpers.getAllQualifiedWorkshopCategories(implantation);
    packageDealHelpers.getAvailablePackageDeals(packageDeals, true).forEach((pd) => {
      const unfinishedOperationsOnUnknownPosts =
        packageDealHelpers.getUnfinishedOperationsOnPrecedentOrUnknownCategories(
          pd,
          standId,
          selfExcludedEarlierQualifiedWorkshopCategories,
          allQualifiedCategories
        );
      operationItems.push(...convertToWorkshopOperations(unfinishedOperationsOnUnknownPosts, pd));
    });
  }
  return operationItems;
}

function getWorkshopOperationsGroupedByLabel(
  kanban: Kanban | undefined,
  qualifiedPostId: string,
  standId: string,
  implantation: WorkshopStandImplantation | undefined
): Record<string, readonly WorkshopOperation[]> {
  const operationsThatShouldAppearOnCurrentPost: Record<string, WorkshopOperation[]> = {};
  const operationItems: WorkshopOperation[] = [];
  if (kanban) {
    const availablePackageDeals = packageDealHelpers.getAvailablePackageDeals(
      kanban.packageDeals,
      true
    );
    availablePackageDeals.forEach((pd) => {
      // operation that are really "owned" by the current post, finished or not
      const ops = packageDealHelpers.getOperationsForWorkshopQualifiedPostCategoryInStand(
        pd,
        qualifiedPostId,
        standId
      );
      operationItems.push(...convertToWorkshopOperations(ops, pd));
    });
    const unfinishedOperationsThatShouldBeAssignedToCurrentPost =
      getAllUnfinishedWorkshopOperationsOnPrecedentOrUnknownCategories(
        availablePackageDeals,
        qualifiedPostId,
        standId,
        implantation
      );
    operationItems.push(...unfinishedOperationsThatShouldBeAssignedToCurrentPost);
    operationItems.forEach((oi) => {
      if (operationsThatShouldAppearOnCurrentPost[oi.label] === undefined) {
        operationsThatShouldAppearOnCurrentPost[oi.label] = [];
      }
      operationsThatShouldAppearOnCurrentPost[oi.label] = [
        ...operationsThatShouldAppearOnCurrentPost[oi.label],
        oi,
      ];
    });
  }
  return operationsThatShouldAppearOnCurrentPost;
}

function getAllUnfinishedOperationsSoFar(
  packageDeals: readonly PackageDeal[],
  standId: string,
  implantation: WorkshopStandImplantation,
  qualifiedPostIdSoFar: string
): readonly Operation[] {
  const { categoryId } =
    transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(qualifiedPostIdSoFar);
  const unfinishedOperationsOnCurrentCategory =
    packageDealHelpers.getAllUnfinishedOperationsForWorkshopPostCategoryInStand(
      packageDealHelpers.getAvailablePackageDeals(packageDeals, true),
      implantation.id,
      nonnull(categoryId),
      standId
    );
  const unfinishedOperationsElseWhere = getAllUnfinishedOperationsOnPrecedentOrUnknownCategories(
    packageDealHelpers.getAvailablePackageDeals(packageDeals, true),
    qualifiedPostIdSoFar,
    standId,
    implantation
  );
  return [...unfinishedOperationsOnCurrentCategory, ...unfinishedOperationsElseWhere];
}

function getMaxPostsNumberFromSelfAndAncestors(level: WorkshopPostLevel): number {
  const currentMax = level.posts.length;
  let ancestorsMax = 0;
  if (isTruthy(level.ancestors)) {
    level.ancestors.forEach((ancestor) => {
      ancestorsMax += getMaxPostsNumberFromSelfAndAncestors(ancestor);
    });
  }
  return Math.max(currentMax, ancestorsMax);
}

function getImplantationPostName(post: string | WorkshopPost): string {
  if (typeof post === 'string') {
    return post;
  }
  return post.id;
}

function getImplantationsFromStands(stands: readonly Stand[]): Record<string, string> {
  const implIds: Record<string, string> = {};
  const standsWithImplantations = stands.filter((stand) => stand.implantations !== undefined);
  standsWithImplantations.forEach((stand) => {
    nonnull(stand.implantations).forEach((impl) => {
      implIds[impl.id] = impl.id;
    });
  });

  // Reverse order so that the main implantation is displayed as first (quick and dirty workaround)
  const reversedImplIds: Record<string, string> = {};
  keysOf(implIds)
    .slice()
    .reverse()
    .forEach((key) => {
      reversedImplIds[key] = implIds[key];
    });

  return reversedImplIds;
}

function findCategory(
  levels: readonly WorkshopPostLevel[],
  categoryId: string
): WorkshopPostCategory | undefined {
  for (const level of levels) {
    const { ancestors, ...category } = level;
    if (category.id === categoryId) {
      return category;
    }
    if (ancestors) {
      const result = findCategory(ancestors, categoryId);
      if (result) {
        return result;
      }
    }
  }

  return undefined;
}

function getPostsForImplantationCategory(
  implantation: WorkshopStandImplantation,
  categoryId: string,
  includeOptional: boolean
): readonly string[] {
  const category = findCategory(implantation.definition, categoryId);
  let thePosts = category?.posts ?? [];
  if (!includeOptional) {
    thePosts = thePosts.filter((p) => {
      if (typeof p !== 'string') {
        return !p.isOptional;
      }
      return true;
    });
  }
  return thePosts.map((p) => (typeof p === 'string' ? p : p.id));
}

function getAllBreadthFirstImplantationCategoryIds(
  implantation: WorkshopStandImplantation
): readonly string[] {
  const result: string[] = [];

  const queue = [...implantation.definition];
  while (queue.length > 0) {
    const currentNode = queue.shift();
    result.push(nonnull(currentNode).id);

    (currentNode?.ancestors ?? []).forEach((a) => queue.push(a));
  }

  return result.reverse();
}

export const workshopHelpers = {
  getImplantationPostName,
  convertToWorkshopOperation,
  convertToWorkshopOperations,
  getWorkshopOperationsGroupedByLabel,
  getPostsForImplantationCategory,
  getAllBreadthFirstImplantationCategoryIds,
  getMaxPostsNumberFromSelfAndAncestors,
  getAllUnfinishedOperationsSoFar,
  getImplantationsFromStands,
};
